import {
  Auth,
  createUserWithEmailAndPassword,
  getAuth,
  GoogleAuthProvider,
  OAuthProvider,
  onAuthStateChanged,
  signInWithEmailAndPassword,
  signInWithPopup,
  signInWithRedirect,
  signOut,
  User,
} from "@firebase/auth";
import { LOCAL_STORAGE_KEYS } from "@frec-js/common";
import {
  deleteIsKnownUserCookie,
  setIsKnownUserCookie,
} from "@frec-js/common-web";
import { useLocalStorage, useMediaQuery } from "@mantine/hooks";
import { getApp, getApps, initializeApp } from "firebase/app";
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";

const firebaseConfig = {
  apiKey: process.env.NEXT_PUBLIC_REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.NEXT_PUBLIC_REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.NEXT_PUBLIC_REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.NEXT_PUBLIC_REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId:
    process.env.NEXT_PUBLIC_REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.NEXT_PUBLIC_REACT_APP_FIREBASE_APP_ID,
};

// Ensure we don't initialize firebase more than once
export const firebaseApp =
  getApps().length === 0 ? initializeApp(firebaseConfig) : getApp();

export enum SupportedOauthProvider {
  Google = "google.com",
  Apple = "apple.com",
}

export interface FirebaseAuthContextProps {
  logIn: (email: string, password: string) => Promise<void>;
  signUp: (email: string, password: string) => Promise<void>;
  logOut: () => Promise<void>;

  isLoading: boolean;
  getFirebaseUser: () => User | null; // used for password reset
  firebaseUid: string; // used as a login check
  getAuthToken: () => Promise<string | null>; // used by apollo provider for authed requests
  token: string | undefined;
  getAuthTime: () => Promise<number | null>; // used by logoutprovider to check for login expiry
  loginWithOauth: (type: SupportedOauthProvider) => Promise<void>;
  lastLoginMethod?: string;

  isLoggedIn: boolean;
  auth?: ReturnType<typeof getAuth>;
}

export const emptyFirebaseAuthContextProps: FirebaseAuthContextProps = {
  isLoading: false,
  logIn: async () => undefined,
  signUp: async () => undefined,
  logOut: async () => undefined,
  getFirebaseUser: () => null,
  firebaseUid: "",
  getAuthToken: () => Promise.reject("not initialized"),
  getAuthTime: () => Promise.resolve(null),
  loginWithOauth: () => Promise.resolve(),
  isLoggedIn: false,
  token: undefined,
};

export const FirebaseAuthContext = createContext<FirebaseAuthContextProps>(
  emptyFirebaseAuthContextProps
);

export const useFirebaseAuthCheck = () => useContext(FirebaseAuthContext);

export const FirebaseAuthContextProvider: React.FC = (props) => {
  const [lastLoginMethod, setLastLoginMethod] = useLocalStorage({
    key: LOCAL_STORAGE_KEYS.LAST_LOGIN_METHOD_KEY,
  });

  const [isLoading, setIsLoading] = useState(true);
  const [firebaseUid, setFirebaseUid] = useState("");
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const isMobile = useMediaQuery("(min-width: 768px)");

  const [auth, setAuth] = useState<Auth | undefined>();
  useEffect(() => {
    setAuth(getAuth(firebaseApp));
  }, []);

  useEffect(() => {
    const unsubscribe =
      auth &&
      onAuthStateChanged(auth, async (u) => {
        setFirebaseUid(u?.uid ?? "");
        setIsLoading(false);
        setIsLoggedIn(!!u);
      });
    return () => unsubscribe?.();
  }, [auth]);

  useEffect(() => {
    const timer = setTimeout(() => {
      // if we haven't heard back from firebase after 10 seconds, clear loading state
      setIsLoading(false);
    }, 10_000);
    return () => clearTimeout(timer);
  }, []);

  const logIn = useCallback(
    async (email: string, password: string) => {
      setIsLoading(true);
      try {
        await signInWithEmailAndPassword(getAuth(), email, password);
        setLastLoginMethod("password");
        setIsKnownUserCookie();
      } catch (e) {
        setIsLoading(false);
        throw e;
      }
    },
    [setLastLoginMethod]
  );

  const signUp = useCallback(
    async (email: string, password: string) => {
      setIsLoading(true);
      try {
        await createUserWithEmailAndPassword(getAuth(), email, password);
        setLastLoginMethod("password");
        setIsKnownUserCookie();
      } catch (e) {
        setIsLoading(false);
        throw e;
      }
    },
    [setLastLoginMethod]
  );

  const logOut = useCallback(async () => {
    setIsLoading(true);
    await signOut(getAuth());
    deleteIsKnownUserCookie();
  }, []);

  const getAuthToken = useCallback(async (): Promise<string | null> => {
    return auth?.currentUser?.getIdToken() ?? null;
  }, [auth?.currentUser]);

  const getAuthTime = useCallback(async () => {
    const authTimeStr =
      (await getAuth().currentUser?.getIdTokenResult())?.authTime ?? null;
    const authTime = authTimeStr ? new Date(authTimeStr).getTime() : null;
    return authTime;
  }, []);

  const getFirebaseUser = useCallback(() => getAuth().currentUser, []);

  const loginWithOauth = useCallback(
    (type: SupportedOauthProvider) => {
      setIsLoading(true);
      const provider =
        type === SupportedOauthProvider.Apple
          ? new OAuthProvider("apple.com")
          : new GoogleAuthProvider();
      const auth = getAuth();
      const doSignin = isMobile ? signInWithRedirect : signInWithPopup;
      return doSignin(auth, provider)
        .then(() => {
          setLastLoginMethod(type);
          setIsKnownUserCookie();
        })
        .catch((error) => {
          setIsLoading(false);
          throw error;
        });
    },
    [isMobile, setLastLoginMethod]
  );

  const [token, setToken] = useState<string | null>(null);
  useEffect(() => {
    getAuthToken().then((t) => {
      if (t) {
        setToken(t);
      }
    });
  }, [getAuthToken]);

  return (
    <FirebaseAuthContext.Provider
      value={{
        logIn,
        logOut,
        signUp,
        getAuthToken,
        isLoading,
        getFirebaseUser,
        firebaseUid,
        getAuthTime,
        loginWithOauth,
        lastLoginMethod,
        isLoggedIn,
        auth,
        token: token ?? undefined,
      }}
    >
      {props.children}
    </FirebaseAuthContext.Provider>
  );
};
