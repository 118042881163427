import { MoneyMovementSourceType } from "../../generated/graphql";

export const TRANSFER_DEPOSIT_FROM_ACCOUNT_TYPES = [
  MoneyMovementSourceType.Ach,
  MoneyMovementSourceType.Treasury,
  MoneyMovementSourceType.FrecCash,
];

export const TRANSFER_DEPOSIT_TO_ACCOUNT_TYPES = [
  MoneyMovementSourceType.DirectIndex,
  MoneyMovementSourceType.Treasury,
  MoneyMovementSourceType.FrecCash,
];
