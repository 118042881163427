import { LDContext } from "launchdarkly-js-client-sdk";

/**
 * Used for anonymous (non logged-in/unidentifiable) users
 */
export const FeatureFlagAnonymousUser: LDContext = {
  kind: "user",
  anonymous: true,
};

/**
 * Using `anonymous: true` on the context object counts towards MAU for LaunchDarkly.
 * Using the same key for the context object reduces MAU usage.
 */
export const FeatureFlagAnonymousKey = "ANONYMOUS_USER";
