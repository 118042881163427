import Decimal from "decimal.js";

import { RealizedIndicatorEnum, WashSaleIndicatorEnum } from "../../common";
import { BUSINESS_TIMEZONE, DateOnly } from "../../date_utils";
import { geCandidatesWithRealizableLoss } from "../../direct_index_utils";
import {
  DirectIndexOptimizeResultDO,
  DirectIndexStockInfoDO,
} from "../../direct_index_utils/directIndexModel";
import { OrderPositionType, OrderSide } from "../../generated/graphql";
import { _findWashSaleCandidates } from "../../portfolio_utils";
import { get, Percentage, ZERO } from "../../utils";
import {
  DirectIndexSolverInterface,
  SimulationSolverArgs,
  TargetWeight,
} from "./DirectIndexSimulator";

export const enum SolverVersion {
  Default,
  WithFees,
}

export const targetWeighEtf = (
  etfOnlyHarvestingSymbol: string
): ((d: DateOnly) => Promise<TargetWeight[]>) => {
  return async () => {
    return get(ETF_HARVESTING_LOOKUP.get(etfOnlyHarvestingSymbol)).map(
      (etf) => ({
        symbol: etf.symbol,
        weight: new Decimal(etf.weight),
      })
    );
  };
};

export const DEFAULT_TAX_LOSS_HARVESTING_THRESHOLD = new Decimal(3);
export const ETF_HARVESTING_LOOKUP = new Map([
  [
    "SPY",
    [
      { symbol: "SPY", weight: 1.0 },
      { symbol: "IWM", weight: 0.5 }, // prefer Russel 2k to 1k
    ],
  ],
  [
    "IWB",
    [
      { symbol: "IWM", weight: 1.0 },
      { symbol: "SPY", weight: 0.5 },
    ],
  ],
  [
    "IVV",
    [
      { symbol: "SPY", weight: 0.5 },
      { symbol: "IVV", weight: 1.0 },
    ],
  ],
  [
    "VTI",
    [
      { symbol: "VTI", weight: 1.0 },
      // { symbol: "SCHB", weight: 0.9 }, limited data on SCHB
      { symbol: "ITOT", weight: 0.8 },
    ],
  ],
  [
    "SCHB",
    [
      { symbol: "SCHB", weight: 1.0 },
      { symbol: "ITOT", weight: 0.9 },
      { symbol: "VTI", weight: 0.8 },
    ],
  ],
  [
    "ITOT",
    [
      { symbol: "ITOT", weight: 1.0 },
      { symbol: "VTI", weight: 0.9 },
      // { symbol: "SCHB", weight: 0.8 },
    ],
  ],
  [
    "QQQ",
    [
      { symbol: "QQQ", weight: 1.0 },
      { symbol: "ONEQ", weight: 0.9 },
    ],
  ],
  [
    "ONEQ",
    [
      { symbol: "ONEQ", weight: 1.0 },
      { symbol: "QQQ", weight: 0.9 },
    ],
  ],
]);

type SellCandidate = DirectIndexStockInfoDO & {
  totalCost: Decimal;
  totalSaleValue: Decimal;
  realizableLoss: Decimal;
  totalQty: Decimal;
};

export class EtfHarvestingSolver implements DirectIndexSolverInterface {
  constructor(
    private lossHarvestingFactor?: Decimal,
    private versionOverride?: SolverVersion
  ) {}
  /**
   * We're eligible to buy only if
   * - buyQty > 0
   * - it doesn't lead to wash sale
   */
  _isEligibleForBuy<
    T extends {
      securityId: string;
      quantity: Decimal;
      realizedIndicator: RealizedIndicatorEnum;
      washSalesIndicator: WashSaleIndicatorEnum;
      realizedGainLoss: Decimal;
      taxLotOpenBuyDate?: DateOnly;
      taxLotCloseSellDate?: DateOnly;
      eventTime: Date;
    }
  >(
    securityId: string,
    qtyToBuy: Decimal,
    date: DateOnly,
    taxLots: T[]
  ): boolean {
    return (
      qtyToBuy.greaterThan(0) &&
      _findWashSaleCandidates(
        {
          securityId: securityId,
          side: OrderSide.Buy,
          positionType: OrderPositionType.Long,
          quantity: qtyToBuy,
          eventTime: date.toDateStartOfDay(BUSINESS_TIMEZONE),
        },
        taxLots
      ).length === 0
    );
  }

  /**
   * Given the current sell candidates, see if we can find a suitable buy candidate that is not being sold
   */
  _getBuyCandidate(
    sellCandidates: SellCandidate[],
    diCash: Decimal,
    date: DateOnly,
    buyCandidates: DirectIndexStockInfoDO[]
  ): DirectIndexStockInfoDO | undefined {
    const leftOverCash = diCash.add(
      sellCandidates.reduce((acc, t) => acc.add(t.totalSaleValue), ZERO)
    );
    const candidateToSkip = new Set(sellCandidates.map((s) => s.symbol));

    return buyCandidates.find(
      (s) =>
        !candidateToSkip.has(s.symbol) &&
        this._isEligibleForBuy(
          s.symbol,
          leftOverCash.div(s.price).toDP(5, Decimal.ROUND_DOWN),
          date,
          s.taxLots
        )
    );
  }

  /**
   * Basic solver for ETF to ETF harvesting. Preference is given to use up any cash at hand
   *  over collecting losses.
   * We only collect losses if we can use up that cash to buy something as part of the same run.
   */
  async solve(
    params: SimulationSolverArgs
  ): Promise<DirectIndexOptimizeResultDO> {
    // First check if we can tax loss harvest any unrealized lot

    const taxLossCandidates = this.getSellCandidateByLoss(
      params,
      this.lossHarvestingFactor ?? DEFAULT_TAX_LOSS_HARVESTING_THRESHOLD,
      true
    );

    const leftOverCash = params.diCash.add(
      taxLossCandidates.reduce((acc, t) => acc.add(t.totalSaleValue), ZERO)
    );

    if (leftOverCash.gte(0) && leftOverCash.lessThan(0.01)) {
      return {
        cashDelta: ZERO,
        quantityDelta: [],
      };
    }

    if (this.versionOverride === SolverVersion.WithFees) {
      return this.solveWithFees(params, taxLossCandidates, leftOverCash);
    }

    return this.solveDefault(params, taxLossCandidates, leftOverCash);
  }

  private solveDefault(
    params: SimulationSolverArgs,
    taxLossCandidates: SellCandidate[],
    leftOverCash: Decimal //fees not handled
  ): DirectIndexOptimizeResultDO {
    // Finding candidate to buy
    const originalCandidates = params.stockInfo.sort((s1, s2) =>
      s2.targetWeight.cmp(s1.targetWeight)
    ); // by highest target weight

    let currentSellCandidates = [...taxLossCandidates];
    let candidateToBuy = this._getBuyCandidate(
      currentSellCandidates,
      params.diCash,
      params.date,
      originalCandidates
    );

    while (candidateToBuy === undefined && currentSellCandidates.length > 0) {
      currentSellCandidates = currentSellCandidates.slice(0, -1);
      candidateToBuy = this._getBuyCandidate(
        currentSellCandidates,
        params.diCash,
        params.date,
        originalCandidates
      );
    }

    const qtyDelta = new Map<string, Decimal>();
    let cashToBuy = params.diCash;
    currentSellCandidates.forEach((s) => {
      qtyDelta.set(s.symbol, s.totalQty.neg());
      cashToBuy = cashToBuy.add(s.totalSaleValue);
    });
    // We only need a single buy candidate that hasn't been sold
    if (candidateToBuy) {
      qtyDelta.set(
        candidateToBuy.symbol,
        cashToBuy.div(candidateToBuy.price).toDP(5, Decimal.ROUND_DOWN)
      );
    } else if (cashToBuy.greaterThan(0)) {
      // Since we prioritize utilizing cash, we will never unless we can safely buy the other index,
      // so it will never happen that we have cash, but can't purchase.
      throw new Error("EtfHarvestingSolver invalid state");
    }
    const cashDelta = params.diCash.greaterThan(0) ? params.diCash.neg() : ZERO; // use all cash

    return {
      cashDelta,
      quantityDelta: Array.from(qtyDelta.entries()).map(([symbol, qty]) => ({
        symbol: symbol,
        delta: qty,
      })),
    };
  }

  private solveWithFees(
    params: SimulationSolverArgs,
    taxLossCandidates: SellCandidate[],
    leftOverCash: Decimal
  ): DirectIndexOptimizeResultDO {
    // if leftOverCash is -ve, we have to force sell
    if (leftOverCash.lt(0)) {
      // First check if we can sell at any loss (avoiding wash sale)
      const liquidateCandidates = this.getSellCandidateByLoss(
        params,
        ZERO,
        true
      );
      const liquidateCandidate = liquidateCandidates.find((s) =>
        s.totalSaleValue.gte(leftOverCash.neg())
      );

      if (liquidateCandidate) {
        const qtyToSell = liquidateCandidate.totalQty
          .mul(leftOverCash)
          .div(liquidateCandidate.totalSaleValue)
          .toDP(5);
        return {
          cashDelta: ZERO,
          quantityDelta: [
            {
              symbol: liquidateCandidate.symbol,
              delta: qtyToSell,
            },
          ],
        };
      }
      // Now, we check if we can sell with any loss with wash sale or with gains
      const candidatesWithWashSaleOrGains = this.getSellCandidateByLoss(
        params,
        new Decimal(1000).neg(),
        false
      );
      const candidateWithWashSaleOrGains = candidatesWithWashSaleOrGains.find(
        (s) => s.totalSaleValue.gte(leftOverCash.neg())
      );

      if (candidateWithWashSaleOrGains) {
        const qtyToSell = candidateWithWashSaleOrGains.totalQty
          .mul(leftOverCash)
          .div(candidateWithWashSaleOrGains.totalSaleValue)
          .toDP(5);

        return {
          cashDelta: ZERO,
          quantityDelta: [
            {
              symbol: candidateWithWashSaleOrGains.symbol,
              delta: qtyToSell,
            },
          ],
        };
      }

      // Shouldn't be possible to reach here
      throw new Error("EtfHarvestingSolver invalid state to liquidate");
    } else {
      return this.handleBuy(params, leftOverCash, taxLossCandidates);
    }
  }

  private getSellCandidateByLoss(
    params: SimulationSolverArgs,
    lossFactor: Decimal,
    avoidWashSale: boolean
  ): SellCandidate[] {
    return params.stockInfo
      .map((s) => {
        const sellCandidates = geCandidatesWithRealizableLoss(
          s.symbol,
          s.price,
          params.date,
          s.taxLots,
          new Percentage({
            percent: lossFactor,
          }),
          avoidWashSale
        );

        const totalQty = sellCandidates.reduce(
          (acc, t) => acc.add(t.quantity),
          ZERO
        );
        const totalCost = sellCandidates.reduce(
          (acc, t) => acc.add(t.cost),
          ZERO
        );
        const totalSaleValue = totalQty.mul(s.price).toDP(2);
        const realizableLoss = totalSaleValue.minus(totalCost);

        return {
          ...s,
          taxLots: sellCandidates,
          totalCost,
          totalSaleValue,
          realizableLoss,
          totalQty,
        };
      })
      .filter((s) => s.taxLots.length > 0 && s.totalQty.greaterThan(0))
      .sort((c1, c2) => c1.realizableLoss.cmp(c2.realizableLoss)); // by highest loss (-ve)
  }

  private handleBuy(
    params: SimulationSolverArgs,
    leftOverCash: Decimal,
    sellCandidates: SellCandidate[]
  ): DirectIndexOptimizeResultDO {
    // Finding candidate to buy
    const originalCandidates = params.stockInfo.sort((s1, s2) =>
      s2.targetWeight.cmp(s1.targetWeight)
    ); // by highest target weight

    let currentSellCandidates = [...sellCandidates];
    let candidateToBuy = this._getBuyCandidate(
      currentSellCandidates,
      leftOverCash,
      params.date,
      originalCandidates
    );

    while (candidateToBuy === undefined && currentSellCandidates.length > 0) {
      currentSellCandidates = currentSellCandidates.slice(0, -1);
      candidateToBuy = this._getBuyCandidate(
        currentSellCandidates,
        leftOverCash,
        params.date,
        originalCandidates
      );
    }

    const qtyDelta = new Map<string, Decimal>();
    let cashToBuy = params.diCash.gt(0) ? params.diCash : ZERO;
    currentSellCandidates.forEach((s) => {
      qtyDelta.set(s.symbol, s.totalQty.neg());
      cashToBuy = cashToBuy.add(s.totalSaleValue);
    });
    // We only need a single buy candidate that hasn't been sold
    if (candidateToBuy) {
      qtyDelta.set(
        candidateToBuy.symbol,
        cashToBuy.div(candidateToBuy.price).toDP(5, Decimal.ROUND_DOWN)
      );
    } else if (cashToBuy.greaterThan(0)) {
      // Since we prioritize utilizing cash, we will never unless we can safely buy the other index,
      // so it will never happen that we have cash, but can't purchase.

      return {
        cashDelta: ZERO,
        quantityDelta: Array.from(qtyDelta.entries()).map(([symbol, qty]) => ({
          symbol: symbol,
          delta: qty,
        })),
      };
    }
    const cashDelta = params.diCash.greaterThan(0) ? params.diCash.neg() : ZERO; // use all cash

    return {
      cashDelta,
      quantityDelta: Array.from(qtyDelta.entries()).map(([symbol, qty]) => ({
        symbol: symbol,
        delta: qty,
      })),
    };
  }
}
