import Decimal from "decimal.js";

import { ValidationResultLevel } from "../common";
import {
  EDITABLE_NUMBER_OF_SECTORS,
  EDITABLE_NUMBER_OF_STOCKS,
} from "../constants";
import {
  DirectIndexType,
  GicsCode,
  MoneyMovementSourceType,
} from "../generated/graphql";

export enum SubAccountCashTransferValidationResult {
  InsufficientCash = "InsufficientCash",
  AmountShouldBeGreaterThanZero = "AmountShouldBeGreaterThanZero",
}

export const SubAccountCashTransferValidationResultLevelMap: Record<
  SubAccountCashTransferValidationResult,
  ValidationResultLevel
> = {
  InsufficientCash: ValidationResultLevel.Error,
  AmountShouldBeGreaterThanZero: ValidationResultLevel.Error,
};

export type SubAccountCashTransferArgs = {
  amount: Decimal;
  cashAvailable: Decimal;
};

export const validateSubAccountCashTransfer = (
  args: SubAccountCashTransferArgs
) => {
  const results = new Set<SubAccountCashTransferValidationResult>();

  if (args.amount.lessThanOrEqualTo(0)) {
    results.add(
      SubAccountCashTransferValidationResult.AmountShouldBeGreaterThanZero
    );
  }

  if (args.amount.greaterThan(args.cashAvailable)) {
    results.add(SubAccountCashTransferValidationResult.InsufficientCash);
  }

  return Array.from(results);
};

export enum LiquidatePortfolioValidationResult {
  InsufficientPortfolioValue = "InsufficientPortfolioValue",
  AmountShouldBeGreaterThanZero = "AmountShouldBeGreaterThanZero",
  InvalidLiquidationAmount = "InvalidLiquidationAmount",
  InvalidLiquidationDestination = "InvalidLiquidationDestination",
}

export const LiquidatePortfolioValidationResultLevelMap: Record<
  LiquidatePortfolioValidationResult,
  ValidationResultLevel
> = {
  InsufficientPortfolioValue: ValidationResultLevel.Error,
  AmountShouldBeGreaterThanZero: ValidationResultLevel.Error,
  InvalidLiquidationAmount: ValidationResultLevel.Error,
  InvalidLiquidationDestination: ValidationResultLevel.Error,
};

export type LiquidateDirectIndexPortfolioArgs = {
  liquidateAmount?: Decimal;
  isFullLiquidation?: boolean;
  portfolioValue: Decimal;
  destinationId?: string;
  destinationType?: MoneyMovementSourceType;
};

export enum DirectIndexCustomizationValidationResult {
  ExceedsEditableStocksLimit = "ExceedsEditableStocksLimit",
  ExceedsEditableSectorsLimit = "ExceedsEditableSectorsLimit",
  CannotRemoveSectorsFromSPInfoTech = "CannotRemoveSectorsFromSPInfoTech",
}

export type DirectIndexCustomizationArgs = {
  directIndexType: DirectIndexType;
  removeGICSSectorIds: GicsCode[];
  addSecuritySymbols: string[];
  removeSecuritySymbols: string[];
};

// Only one not supported is treasury
export const DI_SUPPORTED_LIQUIDATION_DESTINATIONS = new Set([
  MoneyMovementSourceType.FrecCash,
  MoneyMovementSourceType.Ach,
  MoneyMovementSourceType.Wire,
]);

export const validateDirectIndexPortfolioLiquidate = (
  args: LiquidateDirectIndexPortfolioArgs
) => {
  const results = new Set<LiquidatePortfolioValidationResult>();

  // one of isFullLiquidation and liquidateAmount needs to be set
  if (
    (args.isFullLiquidation && args.liquidateAmount) ||
    (!args.liquidateAmount && !args.isFullLiquidation)
  ) {
    results.add(LiquidatePortfolioValidationResult.InvalidLiquidationAmount);
  }

  if (args.liquidateAmount?.lessThanOrEqualTo(0)) {
    results.add(
      LiquidatePortfolioValidationResult.AmountShouldBeGreaterThanZero
    );
  }

  if (args.liquidateAmount?.greaterThan(args.portfolioValue)) {
    results.add(LiquidatePortfolioValidationResult.InsufficientPortfolioValue);
  }

  if (
    (args.destinationId && !args.destinationType) ||
    (!args.destinationId && args.destinationType)
  ) {
    results.add(
      LiquidatePortfolioValidationResult.InvalidLiquidationDestination
    );
  }

  if (
    args.destinationType &&
    !DI_SUPPORTED_LIQUIDATION_DESTINATIONS.has(args.destinationType)
  ) {
    results.add(
      LiquidatePortfolioValidationResult.InvalidLiquidationDestination
    );
  }
  return Array.from(results);
};

export const validateDirectIndexCustomization = (
  args: DirectIndexCustomizationArgs
) => {
  const results = new Set<DirectIndexCustomizationValidationResult>();

  // check number of sectors edited
  if (args.removeGICSSectorIds.length > EDITABLE_NUMBER_OF_SECTORS) {
    results.add(
      DirectIndexCustomizationValidationResult.ExceedsEditableSectorsLimit
    );
  }

  // check number of stocks edited
  if (
    args.addSecuritySymbols.length + args.removeSecuritySymbols.length >
    EDITABLE_NUMBER_OF_STOCKS
  ) {
    results.add(
      DirectIndexCustomizationValidationResult.ExceedsEditableStocksLimit
    );
  }

  // cannot remove sectors from info tech
  if (
    args.directIndexType === DirectIndexType.SpInfoTech &&
    args.removeGICSSectorIds.length > 0
  ) {
    results.add(
      DirectIndexCustomizationValidationResult.CannotRemoveSectorsFromSPInfoTech
    );
  }

  return Array.from(results);
};
