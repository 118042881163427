import { z } from "zod";

export const EMAIL_SCHEMA = z.object({
  email: z
    .string()
    .email({ message: "Please provide a valid email" })
    .max(320, { message: "Please provide a valid email" }),
});

export type EMAIL_SCHEMA_TYPE = z.infer<typeof EMAIL_SCHEMA>;

export const PASSWORD_SCHEMA = z.object({
  password: z
    .string()
    .min(8, { message: "Password should be longer than 8 characters" })
    .regex(/[A-Z]/, {
      message: "Password should contain at least an uppercase letter",
    })
    .regex(/[0-9]/, {
      message: "Password should contain at least one digit",
    }),
});
export type PASSWORD_SCHEMA_TYPE = z.infer<typeof PASSWORD_SCHEMA>;

export const SIGNIN_SCHEMA = EMAIL_SCHEMA.merge(
  z.object({
    password: z.string({ required_error: "Please provide a password" }),
  })
);
export type SIGNIN_SCHEMA_TYPE = z.infer<typeof SIGNIN_SCHEMA>;

export const UPDATE_PASSWORD_SCHEMA = PASSWORD_SCHEMA.extend({
  confirmPassword: z.string({ required_error: "Please confirm your password" }),
}).refine((data) => data.password === data.confirmPassword, {
  message: "Passwords don't match",
  path: ["confirmPassword"], // path of error
});
export type UPDATE_PASSWORD_SCHEMA_TYPE = z.infer<
  typeof UPDATE_PASSWORD_SCHEMA
>;

export const SIGNUP_SCHEMA = EMAIL_SCHEMA.merge(PASSWORD_SCHEMA);
export type SIGNUP_SCHEMA_TYPE = z.infer<typeof SIGNUP_SCHEMA>;
