import { ValidationResultLevel } from "../common/enums";
import { fullBrokerageList } from "../common/fullBrokerageList";
import { DateOnly } from "../date_utils";
import {
  AcatsCreateTransferInput,
  AcatsTransferType,
  LinkedBrokerageAccount,
  NotificationEvent,
  StatusEnum,
} from "../generated/graphql";

export enum AcatsValidationResult {
  AcatsInNotAllowed = "AcatsInNotAllowed",
  NsccNotSupported = "NsccNotSupported",
  MultipleCashEntriesNotAllowed = "MultipleCashEntriesNotAllowed",
  EmptyAssetsNotAllowed = "EmptyAssetsNotAllowed",
}

export const AcatsValidationResultLevelMap: Record<
  AcatsValidationResult,
  ValidationResultLevel
> = {
  AcatsInNotAllowed: ValidationResultLevel.Error,
  NsccNotSupported: ValidationResultLevel.Error,
  MultipleCashEntriesNotAllowed: ValidationResultLevel.Error,
  EmptyAssetsNotAllowed: ValidationResultLevel.Error,
};

export type ValidateAcatsArgs = {
  input: AcatsCreateTransferInput;
  linkedBrokerageAccount: LinkedBrokerageAccount;
  plaidInstitutionId?: string;
  isAcatsInAllowed: boolean;
};

export function validateAcats(
  args: ValidateAcatsArgs
): AcatsValidationResult[] {
  const results = new Set<AcatsValidationResult>();

  if (!args.isAcatsInAllowed)
    results.add(AcatsValidationResult.AcatsInNotAllowed);

  const nsccParticipantNo =
    args.linkedBrokerageAccount.nsccNumber ||
    fullBrokerageList.find(
      (inst) => inst.plaidInstitutionId === args.plaidInstitutionId
    )?.nsccParticipantNo;
  if (!nsccParticipantNo) {
    results.add(AcatsValidationResult.NsccNotSupported);
  }

  if (
    args.input.transferType === AcatsTransferType.Partial &&
    args.input.assets.length === 0 &&
    args.input.cash.length === 0
  ) {
    results.add(AcatsValidationResult.EmptyAssetsNotAllowed);
  }
  if (args.input.cash.length > 1) {
    results.add(AcatsValidationResult.MultipleCashEntriesNotAllowed);
  }

  return Array.from(results);
}

export function getAcatsEstimatedDate(
  fromDateString: string,
  days: number,
  timezone: string
): DateOnly {
  const baseDate = DateOnly.fromISO8601FormatStringToTz(
    fromDateString,
    timezone
  );
  const result = baseDate.addBusinessDays(days);

  // if the resulting estimate date is in the past, add a business day
  return Date.now() - result.toDateEndOfDay(timezone).valueOf() > 0
    ? DateOnly.now(timezone).addBusinessDays(1)
    : result;
}

export const getAcatsDaysToArrival = (
  type: StatusEnum | NotificationEvent
): number => {
  switch (type) {
    case StatusEnum.Initiated:
    case StatusEnum.Pending:
    case NotificationEvent.AcatsTransferRequestSubmitted:
    case NotificationEvent.AcatsTransferRequestSubmittedFirstTime:
      return 5;
    case StatusEnum.Review:
      return 3;
    case StatusEnum.SettleClose:
    case NotificationEvent.AcatsTransferReviewApproved:
    case NotificationEvent.LineOfCreditAlmostReady:
      return 2;
    default:
      return 0;
  }
};
