import { withFeatureFlagProvider } from "@frec-js/common-web";

const ldClientSideID =
  process.env.NEXT_PUBLIC_REACT_APP_LAUNCH_DARKLY_CLIENT_SIDE_ID;

export const FeatureFlagProvider = withFeatureFlagProvider(
  {
    debug(): void {
      // meh
    },
    error(): void {
      // meh
    },
    info(): void {
      // meh
    },
    warn(): void {
      // meh
    },
  },
  ldClientSideID
);
