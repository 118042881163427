import { LDLogger } from "launchdarkly-js-client-sdk";
import { withLDProvider } from "launchdarkly-react-client-sdk";
import React from "react";

import { FeatureFlagAnonymousKey } from "../constants";
import { FeatureFlagLogger } from "../hooks";

const RenderChildren: React.FC = ({ children }) => {
  return <>{children}</>;
};

const toLDLogger: (l: FeatureFlagLogger) => LDLogger = (
  l: FeatureFlagLogger
) => ({
  debug: (msg) => l.debug(msg),
  error: (msg) => l.error(msg),
  info: (msg) => l.info(msg),
  warn: (msg) => l.warn(msg),
});

/**
 * Wrapper around LaunchDarkly's withLDProvider function
 * @param logger a logger
 * @param clientSideID the api key for the feature flag client
 * @returns a feature flag provider (a React.Context provider)
 */
export const withFeatureFlagProvider = (
  logger: FeatureFlagLogger,
  clientSideID?: string
) =>
  clientSideID
    ? withLDProvider({
        clientSideID,
        // Initiating the client with an anonymous user key to not blow up our MCI usage
        // We can call .identify later to change the context once we know who the user is
        context: {
          kind: "user",
          key: FeatureFlagAnonymousKey,
        },
        options: {
          logger: toLDLogger(logger),

          /**
           * Configure LaunchDarkly client to stream flag updates.
           * If not enabled, client values will only update on refresh
           */
          streaming: true,
        },
      })(RenderChildren)
    : (() => {
        logger.info(
          "Skipping initialization of LaunchDarkly due to missing env"
        );
        return RenderChildren;
      })();
