/**
 * Whenever internal systems must reason about business rules such as
 * "trades occurring on the same day", "SOD files processed on the same day",
 * and so on, the question is raised as to what timezones should be used for
 * such logical statements.
 *
 * The answer is to use `BUSINESS_TIMEZONE` as defined below.
 */
export const BUSINESS_TIMEZONE = "America/New_York";
export const EXCHANGE_TIMEZONE = "America/New_York";

export const DATE_FORMAT = "yyyy-MM-dd";
