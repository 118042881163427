import "@mantine/core/styles.css";
import "@mantine/carousel/styles.css";
import "../styles/globals.css";

import {
  ApolloClient,
  ApolloProvider as ApolloProviderUnderlying,
  from,
  HttpLink,
} from "@apollo/client";
import { ContextSetter, setContext } from "@apollo/client/link/context";
import { datadogLogs } from "@datadog/browser-logs";
import { PromiseExtensions } from "@frec-js/common";
import {
  amplitude,
  decimalParserLink,
  polyfillArrayAt,
  recordUtmParams,
} from "@frec-js/common-web";
import { createTheme, MantineProvider } from "@mantine/core";
import type { AppProps } from "next/app";
import { FC, useCallback, useEffect, useMemo } from "react";

import { FacebookPixel } from "../components/FacebookPixel";
import { RootErrorBoundary } from "../components/RootErrorBoundary";
import ScrollToHash from "../components/ScrollToHash";
import {
  FirebaseAuthContextProvider,
  useFirebaseAuthCheck,
} from "../hooks/useFirebaseAuth";
import * as datadogLogging from "../logging/datadog";
import { ColorSchemeContextProvider } from "../providers/ColorSchemeProvider";
import { FeatureFlagProvider } from "../providers/FeatureFlagProvider";
import { UserTrackingProvider } from "../providers/UserTrackingProvider";
import { apolloCache } from "../utils/apolloCache";

const uri = process.env.NEXT_PUBLIC_GRAPHQL_URL;
const amplitudeApiKey = process.env.NEXT_PUBLIC_AMPLITUDE_API_KEY;

const theme = createTheme({
  black: "#111",
  primaryColor: "dark",
  components: {
    Select: {
      classNames: {
        label: "text-frecXDarkGray text-base mb-1",
        input: "rounded-xl",
      },
    },
  },
  fontFamily:
    '"abcWhyte", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif',
});

const ApolloProvider: FC = ({ children }) => {
  const customFetch: WindowOrWorkerGlobalScope["fetch"] = (
    uri,
    options: RequestInit
  ) => {
    const { operationName } =
      typeof options.body === "string"
        ? JSON.parse(options.body)
        : { operationName: "unknown_operation" };
    return fetch(`${uri}/frec-web/${operationName}`, options);
  };

  const { getAuthToken } = useFirebaseAuthCheck();
  const getAuthHeader = useCallback<ContextSetter>(
    async ({}, { headers }) => {
      // Token call will fail if not authenticated, ensure the promise is always successful
      const maybeToken = await PromiseExtensions.lifted(getAuthToken());

      const authorization =
        (maybeToken?.success && maybeToken?.result) || undefined;

      const authHeader = authorization ? { authorization } : {};

      return {
        headers: {
          ...headers,
          ...authHeader,
        },
      };
    },
    [getAuthToken]
  );

  const apolloClient = useMemo(() => {
    return new ApolloClient({
      defaultOptions: {
        query: {
          notifyOnNetworkStatusChange: true,
          fetchPolicy: "cache-first",
        },
      },
      cache: apolloCache,
      link: from([
        decimalParserLink,
        setContext(getAuthHeader),
        new HttpLink({ uri, fetch: customFetch }),
      ]),
    });
  }, [getAuthHeader]);

  return (
    <ApolloProviderUnderlying client={apolloClient}>
      {children}
    </ApolloProviderUnderlying>
  );
};

function MyApp({ Component, pageProps }: AppProps) {
  // Perform initialization only once for 3rd party libraries
  useEffect(() => {
    polyfillArrayAt();
    datadogLogging.initialize();
    amplitudeApiKey &&
      amplitude.init(amplitudeApiKey, {
        loggerProvider: amplitude.makeAmplitudeLogger(
          datadogLogs.createLogger("amplitude")
        ),
      });
    recordUtmParams();
  }, []);

  return (
    <RootErrorBoundary>
      <FacebookPixel />
      <FeatureFlagProvider>
        <MantineProvider forceColorScheme="light" theme={theme}>
          <ColorSchemeContextProvider>
            <FirebaseAuthContextProvider>
              <ApolloProvider>
                <UserTrackingProvider>
                  <ScrollToHash />
                  <Component {...pageProps} />
                </UserTrackingProvider>
              </ApolloProvider>
            </FirebaseAuthContextProvider>
          </ColorSchemeContextProvider>
        </MantineProvider>
      </FeatureFlagProvider>
    </RootErrorBoundary>
  );
}

export default MyApp;
