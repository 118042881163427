import {
  ActivityListItemTypeKey,
  CreditType,
  IntraAccountCashTransferDirection,
  IntraAccountStockTransferDirection,
  LoanType,
  SubAccountType,
} from "../generated/graphql";

const activityListItemTypes: Record<
  Exclude<ActivityListItemTypeKey, ActivityListItemTypeKey.Credit>,
  string
> = {
  [ActivityListItemTypeKey.AcatsIn]: "Stock transfer",
  [ActivityListItemTypeKey.AcatsOut]: "Outgoing stock transfer",
  [ActivityListItemTypeKey.Buy]: "Buy",
  [ActivityListItemTypeKey.CashSettlement]: "Cash settlement",
  [ActivityListItemTypeKey.CreditCardPayment]: "Credit card payment",
  [ActivityListItemTypeKey.Deposit]: "Deposit",
  [ActivityListItemTypeKey.DividendPayment]: "Dividend payment",
  [ActivityListItemTypeKey.DividendReinvestment]: "Dividend reinvestment",
  [ActivityListItemTypeKey.InterestCharge]: "Interest charge",
  [ActivityListItemTypeKey.InterestPayment]: "Interest payment",
  [ActivityListItemTypeKey.Loan]: "Loan",
  [ActivityListItemTypeKey.LoanPayment]: "Loan payment",
  [ActivityListItemTypeKey.PendingLoan]: "Pending loan",
  [ActivityListItemTypeKey.PendingLoanPayment]: "Loan payment",
  [ActivityListItemTypeKey.Sell]: "Sell",
  [ActivityListItemTypeKey.Withdrawal]: "Withdrawal",
  [ActivityListItemTypeKey.IntraAccountCashTransfer]: "Internal transfer",
  [ActivityListItemTypeKey.IntraAccountStockTransfer]:
    "Internal stock transfer",
  [ActivityListItemTypeKey.LiquidateDirectIndexRequest]:
    "Liquidate direct index",
  [ActivityListItemTypeKey.DirectIndexingTradeExecution]:
    "Direct index trade execution",
  [ActivityListItemTypeKey.DirectIndexingFeeCharge]: "Direct index fee charge",
};
export const getCreditActivityListItemTitle = (key: CreditType) => {
  switch (key) {
    case CreditType.PromoCredit:
      return "Referral bonus deposit";

    case CreditType.Courtesy:
    case CreditType.FeeReversal:
    case CreditType.Rebate:
    case CreditType.WriteOff:
    case CreditType.ZeroingAccount:
      return "Credit";
  }
};

export const getActivityListItemTitle = (
  key: keyof typeof activityListItemTypes
) => {
  return activityListItemTypes[key];
};

export const subAccountName: Record<SubAccountType, string> = {
  [SubAccountType.DirectIndex]: "Direct Indexing",
  [SubAccountType.Treasury]: "Treasury",
  [SubAccountType.Primary]: "Self-managed portfolio",
};

export const cashTransferDirectionMap = {
  [IntraAccountCashTransferDirection.Treasury]: "Treasury",
  [IntraAccountCashTransferDirection.DirectIndex]: "Direct Index Portfolio",
  [IntraAccountCashTransferDirection.FrecCash]: "Cash",
};

export const stockTransferDirectionMap = {
  [IntraAccountStockTransferDirection.DirectIndex]: "Direct Index Portfolio",
  [IntraAccountStockTransferDirection.Primary]: "Self-managed portfolio",
};

export const DISPLAY_LOAN_TYPES = new Set([
  LoanType.AcatsTransferredLoan,
  LoanType.LoanRepayment,
  LoanType.UserInitiated,
]);
