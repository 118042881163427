import Decimal from "decimal.js";
import { DateOnly } from "@frec-js/common";
import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
export type MakeEmpty<
  T extends { [key: string]: unknown },
  K extends keyof T
> = { [_ in K]?: never };
export type Incremental<T> =
  | T
  | {
      [P in keyof T]?: P extends " $fragmentName" | "__typename" ? T[P] : never;
    };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
  /** A big int */
  BigInt: { input: string; output: string };
  /** A string that's of the form yyyy-mm-dd */
  Date: { input: DateOnly; output: DateOnly };
  /** A string that's an ISO 8601 representation of a date and time */
  DateTime: { input: Date; output: Date };
  /** Decimal for accurate arithmetic operations */
  Decimal: { input: Decimal; output: Decimal };
  /** JSON blob, useful for pass-through REST apis */
  JSON: { input: object; output: object };
};

export type AcatsAssetInput = {
  quantity: Scalars["Int"]["input"];
  securityId: Scalars["ID"]["input"];
};

export enum AcatsAssetStoreCashType {
  Credit = "CREDIT",
  Debit = "DEBIT",
}

export enum AcatsAssetType {
  Cash = "CASH",
  Cins = "CINS",
  Cusip = "CUSIP",
  Isin = "ISIN",
  Sedol = "SEDOL",
  Symbol = "SYMBOL",
}

export type AcatsCreateTransferInput = {
  assets: Array<AcatsAssetInput>;
  cash: Array<Scalars["Decimal"]["input"]>;
  fromAccountId: Scalars["ID"]["input"];
  stockTransferServiceType?: InputMaybe<StockTransferServiceType>;
  toAccountId: Scalars["ID"]["input"];
  transferType: AcatsTransferType;
};

export type AcatsCreateTransferResponse = {
  __typename?: "AcatsCreateTransferResponse";
  acatsTransferId?: Maybe<Scalars["String"]["output"]>;
  errors?: Maybe<Array<AcatsError>>;
};

export type AcatsError = {
  __typename?: "AcatsError";
  attemptedValue?: Maybe<Scalars["String"]["output"]>;
  errorCode?: Maybe<Scalars["String"]["output"]>;
  fieldName?: Maybe<Scalars["String"]["output"]>;
  message?: Maybe<Scalars["String"]["output"]>;
};

export type AcatsStatusHistory = {
  __typename?: "AcatsStatusHistory";
  acatsTransferId: Scalars["String"]["output"];
  clientReferenceId?: Maybe<Scalars["String"]["output"]>;
  errors?: Maybe<Array<AcatsError>>;
  eventTime: Scalars["DateTime"]["output"];
  frecStatus: AcatsTransferStatus;
  id: Scalars["ID"]["output"];
};

export type AcatsTransfer = {
  __typename?: "AcatsTransfer";
  acatsControlNumber?: Maybe<Scalars["BigInt"]["output"]>;
  accountConnectionType: LinkedBrokerageAccountType;
  accountInstitutionName: Scalars["String"]["output"];
  accountMask?: Maybe<Scalars["String"]["output"]>;
  accountOfficialName: Scalars["String"]["output"];
  assets?: Maybe<Array<AcatsTransferAsset>>;
  associatedAcatsId?: Maybe<Scalars["ID"]["output"]>;
  clearingAccount?: Maybe<ClearingAccount>;
  clearingAccountId: Scalars["ID"]["output"];
  contraPartyComment?: Maybe<Scalars["String"]["output"]>;
  currentStatus: AcatsTransferStatus;
  direction: AcatsTransferDirection;
  errors?: Maybe<Array<AcatsError>>;
  eventTime: Scalars["DateTime"]["output"];
  fee?: Maybe<Array<AcatsTransferFee>>;
  id: Scalars["ID"]["output"];
  linkedBrokerageAccount?: Maybe<LinkedBrokerageAccount>;
  referenceId?: Maybe<Scalars["String"]["output"]>;
  statusHistory?: Maybe<Array<AcatsStatusHistory>>;
  transferType: AcatsTransferType;
  user?: Maybe<User>;
  userId: Scalars["ID"]["output"];
};

export type AcatsTransferAsset = {
  __typename?: "AcatsTransferAsset";
  cash?: Maybe<Scalars["Decimal"]["output"]>;
  cashType?: Maybe<Scalars["String"]["output"]>;
  quantity?: Maybe<Scalars["Decimal"]["output"]>;
  securityId?: Maybe<Scalars["String"]["output"]>;
  symbol?: Maybe<Scalars["String"]["output"]>;
};

export enum AcatsTransferDirection {
  Internal = "INTERNAL",
  Joining = "JOINING",
  Leaving = "LEAVING",
  Undefined = "UNDEFINED",
}

export type AcatsTransferFee = {
  __typename?: "AcatsTransferFee";
  amount: Scalars["Decimal"]["output"];
  id: Scalars["ID"]["output"];
  type: AcatsTransferFeeType;
};

export enum AcatsTransferFeeType {
  Operating = "OPERATING",
}

export enum AcatsTransferStatus {
  AdminInitiated = "ADMIN_INITIATED",
  Complete = "COMPLETE",
  Initiated = "INITIATED",
  OpsRejected = "OPS_REJECTED",
  OpsRetry = "OPS_RETRY",
  OpsSmaCheck = "OPS_SMA_CHECK",
  Purge = "PURGE",
  RequestError = "REQUEST_ERROR",
  Review = "REVIEW",
  SettleClose = "SETTLE_CLOSE",
  Unknown = "UNKNOWN",
}

export enum AcatsTransferType {
  Full = "FULL",
  Partial = "PARTIAL",
  ResidualCredit = "RESIDUAL_CREDIT",
}

export enum AccountHolderType {
  Employee = "EMPLOYEE",
  Error = "ERROR",
  Foreign = "FOREIGN",
  Individual = "INDIVIDUAL",
  Institutional = "INSTITUTIONAL",
  Unspecified = "UNSPECIFIED",
}

export enum AccountType {
  Cash = "CASH",
  Margin = "MARGIN",
  Option = "OPTION",
  Unspecified = "UNSPECIFIED",
}

export enum ActionContext {
  AcatsIn = "ACATS_IN",
  AcatsOut = "ACATS_OUT",
  AccountDocuments = "ACCOUNT_DOCUMENTS",
  AchIn = "ACH_IN",
  AchOut = "ACH_OUT",
  AddExternalBanks = "ADD_EXTERNAL_BANKS",
  AddExternalBrokerages = "ADD_EXTERNAL_BROKERAGES",
  AddExternalCreditCard = "ADD_EXTERNAL_CREDIT_CARD",
  Borrow = "BORROW",
  Buy = "BUY",
  CheckOut = "CHECK_OUT",
  CloseAccount = "CLOSE_ACCOUNT",
  DeleteExternalBanks = "DELETE_EXTERNAL_BANKS",
  DeleteExternalBrokerages = "DELETE_EXTERNAL_BROKERAGES",
  DeleteExternalCreditCard = "DELETE_EXTERNAL_CREDIT_CARD",
  EditProfile = "EDIT_PROFILE",
  None = "NONE",
  PayBack = "PAY_BACK",
  Sell = "SELL",
  WireIn = "WIRE_IN",
  WireOut = "WIRE_OUT",
}

export type ActionContextStatus = {
  __typename?: "ActionContextStatus";
  actionContext: ActionContext;
  isAllowed: Scalars["Boolean"]["output"];
};

export type Activity = {
  __typename?: "Activity";
  acatsTransfers?: Maybe<Array<AcatsTransfer>>;
  /** @deprecated Field no longer supported */
  cashDividends?: Maybe<Array<CashDividend>>;
  cashTransfers?: Maybe<Array<CashTransfer>>;
  creditCardPayments?: Maybe<Array<PaymentExecution>>;
  credits?: Maybe<Array<Credit>>;
  dailyCashDividends?: Maybe<Array<DailyCashDividend>>;
  directIndexFeeCharges?: Maybe<Array<DirectIndexFeeCharge>>;
  directIndexTradeExecutions?: Maybe<Array<DirectIndexTradeExecutionSummary>>;
  intraAccountCashTransfers?: Maybe<Array<IntraAccountCashTransfer>>;
  intraAccountStockTransfers?: Maybe<Array<IntraAccountStockTransfer>>;
  liquidateDirectIndexRequests?: Maybe<
    Array<LiquidateDirectIndexPortfolioRequest>
  >;
  loans?: Maybe<Array<LoanEvent>>;
  marginInterestCharges?: Maybe<Array<MarginInterestCharge>>;
  orders?: Maybe<Array<Order>>;
  pendingLoans?: Maybe<Array<PendingLoan>>;
  stockAdjustments?: Maybe<Array<StockAdjustmentDetails>>;
};

export enum ActivityFilterType {
  Buy = "BUY",
  CashSettlement = "CASH_SETTLEMENT",
  Credit = "CREDIT",
  CreditCardPayment = "CREDIT_CARD_PAYMENT",
  Deposit = "DEPOSIT",
  DirectIndexFee = "DIRECT_INDEX_FEE",
  DirectIndexLiquidationRequest = "DIRECT_INDEX_LIQUIDATION_REQUEST",
  DirectIndexTradeExecutions = "DIRECT_INDEX_TRADE_EXECUTIONS",
  Dividends = "DIVIDENDS",
  IncludeDirectIndexOrders = "INCLUDE_DIRECT_INDEX_ORDERS",
  InterestCharge = "INTEREST_CHARGE",
  InterestPayment = "INTEREST_PAYMENT",
  IntraAccountCashTransfer = "INTRA_ACCOUNT_CASH_TRANSFER",
  IntraAccountStockTransfer = "INTRA_ACCOUNT_STOCK_TRANSFER",
  Loan = "LOAN",
  LoanPayment = "LOAN_PAYMENT",
  Sell = "SELL",
  StockTransfer = "STOCK_TRANSFER",
  Withdrawal = "WITHDRAWAL",
}

export type ActivityInput = {
  filter?: InputMaybe<Array<ActivityFilterType>>;
  nextPageToken?: InputMaybe<Scalars["String"]["input"]>;
  securityId?: InputMaybe<Scalars["ID"]["input"]>;
  size?: InputMaybe<Scalars["Int"]["input"]>;
  status: ActivityStatus;
  subAccountId?: InputMaybe<Scalars["ID"]["input"]>;
};

export enum ActivityListItemTypeKey {
  AcatsIn = "ACATS_IN",
  AcatsOut = "ACATS_OUT",
  Buy = "BUY",
  CashSettlement = "CASH_SETTLEMENT",
  Credit = "CREDIT",
  CreditCardPayment = "CREDIT_CARD_PAYMENT",
  Deposit = "DEPOSIT",
  DirectIndexingFeeCharge = "DIRECT_INDEXING_FEE_CHARGE",
  DirectIndexingTradeExecution = "DIRECT_INDEXING_TRADE_EXECUTION",
  DividendPayment = "DIVIDEND_PAYMENT",
  DividendReinvestment = "DIVIDEND_REINVESTMENT",
  InterestCharge = "INTEREST_CHARGE",
  InterestPayment = "INTEREST_PAYMENT",
  IntraAccountCashTransfer = "INTRA_ACCOUNT_CASH_TRANSFER",
  IntraAccountStockTransfer = "INTRA_ACCOUNT_STOCK_TRANSFER",
  LiquidateDirectIndexRequest = "LIQUIDATE_DIRECT_INDEX_REQUEST",
  Loan = "LOAN",
  LoanPayment = "LOAN_PAYMENT",
  PendingLoan = "PENDING_LOAN",
  PendingLoanPayment = "PENDING_LOAN_PAYMENT",
  Sell = "SELL",
  Withdrawal = "WITHDRAWAL",
}

export enum ActivityStatus {
  Complete = "COMPLETE",
  Pending = "PENDING",
}

export enum ActivityType {
  AcatsTransfer = "ACATS_TRANSFER",
  /** @deprecated Field no longer supported */
  CashDividend = "CASH_DIVIDEND",
  CashTransfer = "CASH_TRANSFER",
  Credit = "CREDIT",
  CreditCardPayment = "CREDIT_CARD_PAYMENT",
  DailyCashDividends = "DAILY_CASH_DIVIDENDS",
  DirectIndexFeeCharge = "DIRECT_INDEX_FEE_CHARGE",
  DirectIndexTradeExecutions = "DIRECT_INDEX_TRADE_EXECUTIONS",
  IntraAccountCashTransfer = "INTRA_ACCOUNT_CASH_TRANSFER",
  IntraAccountStockTransfer = "INTRA_ACCOUNT_STOCK_TRANSFER",
  LiquidateDirectIndexRequest = "LIQUIDATE_DIRECT_INDEX_REQUEST",
  Loan = "LOAN",
  MarginInterestCharge = "MARGIN_INTEREST_CHARGE",
  Order = "ORDER",
  PendingLoan = "PENDING_LOAN",
  PendingLoanPayment = "PENDING_LOAN_PAYMENT",
  StockAdjustment = "STOCK_ADJUSTMENT",
}

export enum AddressType {
  Business = "BUSINESS",
  Default = "DEFAULT",
  Mailing = "MAILING",
  PoBox = "PO_BOX",
  Residential = "RESIDENTIAL",
}

export enum AdjustmentMethod {
  All = "All",
  None = "None",
  PriceReturn = "PriceReturn",
}

export enum AdjustmentSource {
  AdminInitiated = "ADMIN_INITIATED",
  AutoTlh = "AUTO_TLH",
  CrspProcessing = "CRSP_PROCESSING",
  DividendSweep = "DIVIDEND_SWEEP",
  DiLiquidation = "DI_LIQUIDATION",
  DiRecurringDeposit = "DI_RECURRING_DEPOSIT",
  Manual = "MANUAL",
  MarketVectorProcessing = "MARKET_VECTOR_PROCESSING",
  RecurringDeposit = "RECURRING_DEPOSIT",
  RefreshSymbols = "REFRESH_SYMBOLS",
  RussellDataProcessing = "RUSSELL_DATA_PROCESSING",
  SodExt_235 = "SOD_EXT_235",
  SodExt_869 = "SOD_EXT_869",
  SodExt_870 = "SOD_EXT_870",
  SodExt_1027 = "SOD_EXT_1027",
  SpDataProcessing = "SP_DATA_PROCESSING",
  Treasury = "TREASURY",
  UserInitiated = "USER_INITIATED",
}

export enum AdjustmentType {
  AccountTransfer = "ACCOUNT_TRANSFER",
  Acquisition = "ACQUISITION",
  AdrFee = "ADR_FEE",
  CashAdjustment = "CASH_ADJUSTMENT",
  DividendReinvestment = "DIVIDEND_REINVESTMENT",
  Fpsl = "FPSL",
  IntraAccountStockTransfer = "INTRA_ACCOUNT_STOCK_TRANSFER",
  OpsRelated = "OPS_RELATED",
  Spinoff = "SPINOFF",
  StockMerger = "STOCK_MERGER",
  StockSplit = "STOCK_SPLIT",
}

export type AggregatedMarginInterestFee = {
  __typename?: "AggregatedMarginInterestFee";
  amount: Scalars["Decimal"]["output"];
  clearingAccountId: Scalars["ID"]["output"];
};

export enum AllocationConfigType {
  OneTime = "ONE_TIME",
  PublicApi = "PUBLIC_API",
  SplitDeposit = "SPLIT_DEPOSIT",
}

export type AnnualizedReturn = {
  __typename?: "AnnualizedReturn";
  annualizedReturn: Scalars["Float"]["output"];
  endConstituentCount: Scalars["Int"]["output"];
  endDate: Scalars["Date"]["output"];
  endPrice: Scalars["Float"]["output"];
  gicsCode?: Maybe<GicsCode>;
  startConstituentCount: Scalars["Int"]["output"];
  startDate: Scalars["Date"]["output"];
  startPrice: Scalars["Float"]["output"];
  years: Scalars["Int"]["output"];
};

export type AnnualizedReturnResponse = {
  __typename?: "AnnualizedReturnResponse";
  annualizedReturns: Array<AnnualizedReturn>;
  index: StockIndex;
};

export type ApexAcatsAnonymousStatus = {
  __typename?: "ApexAcatsAnonymousStatus";
  created: Scalars["String"]["output"];
  errorText?: Maybe<Scalars["String"]["output"]>;
  expectedSettleDate: Scalars["String"]["output"];
  lastUpdated: Scalars["String"]["output"];
  nsccStatus: Scalars["String"]["output"];
  publicStatus: Scalars["String"]["output"];
};

export type ApexAcatsAsset = {
  __typename?: "ApexAcatsAsset";
  amount?: Maybe<Scalars["Decimal"]["output"]>;
  assetErrors?: Maybe<Array<ApexAcatsAssetError>>;
  comment?: Maybe<Scalars["String"]["output"]>;
  currency?: Maybe<ApexAcatsAssetCurrency>;
  description?: Maybe<Scalars["String"]["output"]>;
  identifiers?: Maybe<Array<ApexAcatsAssetIdentifier>>;
  longShort?: Maybe<Scalars["String"]["output"]>;
  quantity?: Maybe<Scalars["Decimal"]["output"]>;
  settlingLocation?: Maybe<Scalars["String"]["output"]>;
};

export type ApexAcatsAssetCurrency = {
  __typename?: "ApexAcatsAssetCurrency";
  isoCode: Scalars["String"]["output"];
  rate: Scalars["Float"]["output"];
  symbol: Scalars["String"]["output"];
};

export type ApexAcatsAssetError = {
  __typename?: "ApexAcatsAssetError";
  propertyIdentifier: Scalars["String"]["output"];
  state: Scalars["String"]["output"];
  stateId: Scalars["String"]["output"];
  value: Scalars["String"]["output"];
};

export type ApexAcatsAssetIdentifier = {
  __typename?: "ApexAcatsAssetIdentifier";
  type: Scalars["String"]["output"];
  value: Scalars["String"]["output"];
};

export type ApexAcatsCaseStates = {
  __typename?: "ApexAcatsCaseStates";
  comment?: Maybe<Scalars["String"]["output"]>;
  cycleDate?: Maybe<Scalars["String"]["output"]>;
  override?: Maybe<Scalars["String"]["output"]>;
  reason?: Maybe<Scalars["String"]["output"]>;
  recommendation: Scalars["String"]["output"];
  scheduledAt?: Maybe<Scalars["String"]["output"]>;
  scheduledBy?: Maybe<Scalars["String"]["output"]>;
  scores?: Maybe<Array<Maybe<ApexAcatsScore>>>;
  stateType: Scalars["String"]["output"];
  submittedAt?: Maybe<Scalars["String"]["output"]>;
};

export type ApexAcatsGetTransferDetailsResponse = {
  __typename?: "ApexAcatsGetTransferDetailsResponse";
  acatsControlNumber?: Maybe<Scalars["BigInt"]["output"]>;
  anonymousKey: Scalars["String"]["output"];
  anonymousStatus: ApexAcatsAnonymousStatus;
  caseStates?: Maybe<Array<ApexAcatsCaseStates>>;
  created: Scalars["String"]["output"];
  deliverer: ApexAcatsPartyDetails;
  deliveringAssets?: Maybe<Array<ApexAcatsAsset>>;
  direction: AcatsTransferDirection;
  errors?: Maybe<Array<AcatsError>>;
  nextCycle: Scalars["String"]["output"];
  nonDeliverableAssets?: Maybe<Array<ApexAcatsAsset>>;
  previousCycle: Scalars["String"]["output"];
  processingCaseId: Scalars["BigInt"]["output"];
  receiver: ApexAcatsPartyDetails;
  requestedAssets?: Maybe<Array<ApexAcatsAsset>>;
  status: ApexAcatsTransferStatus;
  tifId?: Maybe<Scalars["String"]["output"]>;
  transferType: Scalars["String"]["output"];
};

export type ApexAcatsPartyDetails = {
  __typename?: "ApexAcatsPartyDetails";
  accountNumber: Scalars["String"]["output"];
  accountTitle?: Maybe<Scalars["String"]["output"]>;
  accountTypes?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  assets?: Maybe<Array<ApexAcatsAsset>>;
  correspondentName?: Maybe<Scalars["String"]["output"]>;
  interestedPartySsnOrTaxId?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  isMarginAccount?: Maybe<Scalars["Boolean"]["output"]>;
  participantName: Scalars["String"]["output"];
  participantNumber: Scalars["Int"]["output"];
  primarySsnOrTaxId?: Maybe<Scalars["String"]["output"]>;
  secondarySsnOrTaxId?: Maybe<Scalars["String"]["output"]>;
};

export type ApexAcatsScore = {
  __typename?: "ApexAcatsScore";
  notes?: Maybe<Scalars["String"]["output"]>;
  result?: Maybe<Scalars["String"]["output"]>;
  scoreType?: Maybe<Scalars["String"]["output"]>;
};

export type ApexAcatsSimulatorConfig = {
  apiSimulated?: InputMaybe<Scalars["Boolean"]["input"]>;
  regionSimulated?: InputMaybe<Scalars["Boolean"]["input"]>;
  reviewAction?: InputMaybe<ApexAcatsSimulatorConfigReviewAction>;
  tifRejectReason?: InputMaybe<ApexAcatsSimulatorConfigTifRejectReason>;
};

export enum ApexAcatsSimulatorConfigReviewAction {
  Accelerate = "ACCELERATE",
  Accept = "ACCEPT",
  Reject = "REJECT",
}

export enum ApexAcatsSimulatorConfigTifRejectReason {
  AccountFlat = "ACCOUNT_FLAT",
  AccountInTransfer = "ACCOUNT_IN_TRANSFER",
  AccountTitleMismatch = "ACCOUNT_TITLE_MISMATCH",
  ClientRescinded = "CLIENT_RESCINDED",
  DocumentationNeeded = "DOCUMENTATION_NEEDED",
  Duplicate = "DUPLICATE",
  InvalidAccountNumber = "INVALID_ACCOUNT_NUMBER",
  MissingAuthorizationSignature = "MISSING_AUTHORIZATION_SIGNATURE",
  SsnMismatch = "SSN_MISMATCH",
}

export type ApexAcatsTransferStatus = {
  __typename?: "ApexAcatsTransferStatus";
  action?: Maybe<Scalars["String"]["output"]>;
  alpsActionReason?: Maybe<Scalars["String"]["output"]>;
  alpsRecommendedAction?: Maybe<Scalars["String"]["output"]>;
  isScheduled: Scalars["Boolean"]["output"];
  isSubmitted: Scalars["Boolean"]["output"];
  notes?: Maybe<Scalars["String"]["output"]>;
  nsccState: Scalars["String"]["output"];
  nsccSubState?: Maybe<Scalars["String"]["output"]>;
  rejectReason?: Maybe<Scalars["String"]["output"]>;
  state: Scalars["String"]["output"];
};

export type ApexRelationship = {
  __typename?: "ApexRelationship";
  depositAccountId: Scalars["String"]["output"];
  externalStatus: Scalars["String"]["output"];
  externalStatusReason?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["ID"]["output"];
  rejectionReasons?: Maybe<Scalars["String"]["output"]>;
  relationshipId: Scalars["String"]["output"];
};

export enum ApexServiceType {
  ApexDefault = "APEX_DEFAULT",
  FrecApexSimulator = "FREC_APEX_SIMULATOR",
}

export type ApexSimulationEvent = {
  __typename?: "ApexSimulationEvent";
  eventTime: Scalars["DateTime"]["output"];
  eventType: EventType;
  externalId: Scalars["BigInt"]["output"];
  id: Scalars["String"]["output"];
  note?: Maybe<Scalars["String"]["output"]>;
  payload: Scalars["String"]["output"];
  success: Scalars["Boolean"]["output"];
  topic: Scalars["String"]["output"];
};

export type ApexSimulationEventInput = {
  eventTime: Scalars["DateTime"]["input"];
  eventType: EventType;
  externalId: Scalars["BigInt"]["input"];
  id: Scalars["String"]["input"];
  note?: InputMaybe<Scalars["String"]["input"]>;
  payload: Scalars["String"]["input"];
  success: Scalars["Boolean"]["input"];
  topic: Scalars["String"]["input"];
};

export enum ApplicationLockName {
  EventsAcatsTransfers = "EVENTS_ACATS_TRANSFERS",
  EventsAccounts = "EVENTS_ACCOUNTS",
  EventsAchRelationships = "EVENTS_ACH_RELATIONSHIPS",
  EventsBasketOrdersAccountAllocation = "EVENTS_BASKET_ORDERS_ACCOUNT_ALLOCATION",
  EventsBasketOrdersMf = "EVENTS_BASKET_ORDERS_MF",
  EventsBasketOrdersSubmission = "EVENTS_BASKET_ORDERS_SUBMISSION",
  EventsCashTransfers = "EVENTS_CASH_TRANSFERS",
  EventsMutualFundsOrders = "EVENTS_MUTUAL_FUNDS_ORDERS",
  EventsMutualFundsOrdersRiskSummary = "EVENTS_MUTUAL_FUNDS_ORDERS_RISK_SUMMARY",
  EventsOrders = "EVENTS_ORDERS",
  EventsSketchInvestigationStatus = "EVENTS_SKETCH_INVESTIGATION_STATUS",
  EventsWireDeposits = "EVENTS_WIRE_DEPOSITS",
  ExecuteQueuedOrders = "EXECUTE_QUEUED_ORDERS",
  GenerateAutoTlhCandidates = "GENERATE_AUTO_TLH_CANDIDATES",
  GenerateDiCandidates = "GENERATE_DI_CANDIDATES",
  MonitorDiExecutionStatus = "MONITOR_DI_EXECUTION_STATUS",
  QueueApprovedDiOrders = "QUEUE_APPROVED_DI_ORDERS",
  UpdateDiExecutionStatus = "UPDATE_DI_EXECUTION_STATUS",
}

export enum AvailabilityLevel {
  AvailableAssets = "AVAILABLE_ASSETS",
  LastSettled = "LAST_SETTLED",
}

export type BackfillIterableUserFieldsInput = {
  fields: Array<Scalars["String"]["input"]>;
  userIds?: InputMaybe<Array<Scalars["ID"]["input"]>>;
};

export type BackupSodFilesInput = {
  date?: InputMaybe<Scalars["Date"]["input"]>;
  dryRun?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type BackupSodFilesResponse = {
  __typename?: "BackupSodFilesResponse";
  files: Array<Scalars["String"]["output"]>;
  status: Scalars["Boolean"]["output"];
};

export type BarInput = {
  endTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  interval: TimeInterval;
  intervalMultiple: Scalars["Int"]["input"];
  startTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  symbol: Scalars["String"]["input"];
};

export type BarOutput = {
  __typename?: "BarOutput";
  bars: Array<BarOutputData>;
  delay: Scalars["Float"]["output"];
  outcome: Scalars["String"]["output"];
};

export type BarOutputData = {
  __typename?: "BarOutputData";
  TWAP: Scalars["Float"]["output"];
  VWAP: Scalars["Float"]["output"];
  close: Scalars["Float"]["output"];
  endTime: Scalars["DateTime"]["output"];
  high: Scalars["Float"]["output"];
  low: Scalars["Float"]["output"];
  open: Scalars["Float"]["output"];
  startTime: Scalars["DateTime"]["output"];
  trades: Scalars["Float"]["output"];
  volume: Scalars["Float"]["output"];
};

export type BatchOrderSingleInput = {
  limitPrice?: InputMaybe<Scalars["Decimal"]["input"]>;
  lotMatchingInstructions?: InputMaybe<Array<LotMatchingInstructionInput>>;
  maxSellQuantity?: InputMaybe<Scalars["Decimal"]["input"]>;
  orderType: OrderType;
  quantity: Scalars["Decimal"]["input"];
  quantityType: QuantityType;
  side: OrderSide;
  symbol: Scalars["String"]["input"];
};

export enum BatchOrderSource {
  AdminInitiated = "ADMIN_INITIATED",
  AutoTlh = "AUTO_TLH",
  DirectIndex = "DIRECT_INDEX",
  MarginResolver = "MARGIN_RESOLVER",
  Payments = "PAYMENTS",
  PublicApi = "PUBLIC_API",
  Treasury = "TREASURY",
  TreasuryFees = "TREASURY_FEES",
  UserInitiated = "USER_INITIATED",
}

export type BatchTradeOrder = {
  __typename?: "BatchTradeOrder";
  batchOrderMetadataId: Scalars["ID"]["output"];
  orders: Array<Order>;
};

export type BetaValueInput = {
  endDate?: InputMaybe<Scalars["Date"]["input"]>;
  interval?: InputMaybe<DateInterval>;
  seedSymbol: Scalars["String"]["input"];
  startDate: Scalars["Date"]["input"];
  symbols: Array<Scalars["String"]["input"]>;
};

export type BillingDatesResponse = {
  __typename?: "BillingDatesResponse";
  endDate: Scalars["Date"]["output"];
  startDate: Scalars["Date"]["output"];
};

export type BusinessEntity = {
  __typename?: "BusinessEntity";
  address: GenericAddress;
  bankingInstitutionNames?: Maybe<Array<Scalars["String"]["output"]>>;
  businessScope: BusinessEntityScope;
  corporationSecretaryName?: Maybe<Scalars["String"]["output"]>;
  entityIndividuals?: Maybe<Array<BusinessEntityIndividual>>;
  entityInvestmentProfile?: Maybe<BusinessEntityInvestmentProfile>;
  entityType: BusinessEntityType;
  faxNumber?: Maybe<Scalars["String"]["output"]>;
  foreignBankUSAgent?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["String"]["output"];
  industry: BusinessEntityIndustry;
  largeTraderIds: Array<Scalars["String"]["output"]>;
  mailingAddress: GenericAddress;
  multiplePartnersOrManagers?: Maybe<Scalars["Boolean"]["output"]>;
  name: Scalars["String"]["output"];
  phoneNumber: Scalars["String"]["output"];
  primaryFundingSource: BusinessEntityFundingSource;
  stateOfIncorporation: Scalars["String"]["output"];
  taxId: Scalars["String"]["output"];
  userTitle: Scalars["String"]["output"];
};

export type BusinessEntityAuthorizedUser = {
  __typename?: "BusinessEntityAuthorizedUser";
  user?: Maybe<User>;
  userId: Scalars["ID"]["output"];
  userTitle: Scalars["String"]["output"];
};

export type BusinessEntityDetails = {
  __typename?: "BusinessEntityDetails";
  address?: Maybe<Array<GenericAddress>>;
  id: Scalars["ID"]["output"];
  industry: BusinessEntityIndustry;
  name: Scalars["String"]["output"];
  phoneNumber: Scalars["String"]["output"];
};

export enum BusinessEntityFundingSource {
  CorporateIncome = "CORPORATE_INCOME",
  InvestmentCapital = "INVESTMENT_CAPITAL",
  Other = "OTHER",
}

export type BusinessEntityIndividual = {
  __typename?: "BusinessEntityIndividual";
  address?: Maybe<Array<GenericAddress>>;
  dateOfBirth: Scalars["Date"]["output"];
  disclosure: IndividualDisclosure;
  familyName: Scalars["String"]["output"];
  givenName: Scalars["String"]["output"];
  id: Scalars["ID"]["output"];
  individualType: BusinessEntityIndividualType;
  ssn: Scalars["String"]["output"];
};

export enum BusinessEntityIndividualType {
  BeneficialOwner = "BENEFICIAL_OWNER",
  CompanyOfficer = "COMPANY_OFFICER",
}

export enum BusinessEntityIndustry {
  AgricultureForestryFishing = "AGRICULTURE_FORESTRY_FISHING",
  Construction = "CONSTRUCTION",
  FinanceInsuranceRealEstate = "FINANCE_INSURANCE_REAL_ESTATE",
  Manufacturing = "MANUFACTURING",
  Mining = "MINING",
  PublicAdministration = "PUBLIC_ADMINISTRATION",
  RetailTrade = "RETAIL_TRADE",
  Services = "SERVICES",
  TransportationCommunicationsElectricGasSanitaryServices = "TRANSPORTATION_COMMUNICATIONS_ELECTRIC_GAS_SANITARY_SERVICES",
  WholesaleTrade = "WHOLESALE_TRADE",
}

export type BusinessEntityInfo = {
  __typename?: "BusinessEntityInfo";
  entityAuthorizedUser: BusinessEntityAuthorizedUser;
  entityDetails: BusinessEntityDetails;
  entityIndividuals: Array<BusinessEntityIndividual>;
};

export enum BusinessEntityInvestmentObjective {
  CapitalPreservation = "CAPITAL_PRESERVATION",
  Growth = "GROWTH",
  GrowthIncome = "GROWTH_INCOME",
  Income = "INCOME",
  Speculation = "SPECULATION",
}

export enum BusinessEntityInvestmentPlan {
  ActiveTrading = "ACTIVE_TRADING",
  LongTermInvesting = "LONG_TERM_INVESTING",
  ShortTermInvesting = "SHORT_TERM_INVESTING",
}

export type BusinessEntityInvestmentProfile = {
  __typename?: "BusinessEntityInvestmentProfile";
  annualIncomeMax: Scalars["Int"]["output"];
  annualIncomeMin: Scalars["Int"]["output"];
  depositSource: Scalars["String"]["output"];
  id: Scalars["ID"]["output"];
  initialDepositAmount: Scalars["Int"]["output"];
  investmentExperience: InvestmentExperience;
  investmentObjective: BusinessEntityInvestmentObjective;
  investmentPlan: BusinessEntityInvestmentPlan;
  liquidityNeeds: LiquidityNeeds;
  liquidityTimeHorizon: TimeHorizon;
  netWorthMax: Scalars["Int"]["output"];
  netWorthMin: Scalars["Int"]["output"];
  riskTolerance: RiskTolerance;
  withdrawFrequency: BusinessEntityWithdrawFrequency;
};

export enum BusinessEntityMailingPreference {
  Business = "BUSINESS",
  Mailing = "MAILING",
  PoBox = "PO_BOX",
}

export enum BusinessEntityScope {
  CommercialRetail = "COMMERCIAL_RETAIL",
  FinancialServices = "FINANCIAL_SERVICES",
  Other = "OTHER",
}

export enum BusinessEntityType {
  CCorporation = "C_CORPORATION",
  LlcCCorporation = "LLC_C_CORPORATION",
  LlcSCorporation = "LLC_S_CORPORATION",
  Lp = "LP",
  StatutoryBusinessTrust = "STATUTORY_BUSINESS_TRUST",
  SCorporation = "S_CORPORATION",
}

export enum BusinessEntityWithdrawFrequency {
  Frequent = "FREQUENT",
  Occasional = "OCCASIONAL",
  Rare = "RARE",
}

export enum BuyBackIndicatorEnumGql {
  None = "None",
  ReplacementShares = "ReplacementShares",
}

export enum CipCategory {
  Address = "ADDRESS",
  Dob = "DOB",
  Name = "NAME",
  TaxId = "TAX_ID",
}

export type CipInvestigationCategory = {
  __typename?: "CIPInvestigationCategory";
  name: CipCategory;
  requestedDocuments?: Maybe<Array<CipInvestigationCategoryRequestDocument>>;
};

export type CipInvestigationCategoryRequestDocument = {
  __typename?: "CIPInvestigationCategoryRequestDocument";
  description: Scalars["String"]["output"];
  name: Scalars["String"]["output"];
};

export type CipInvestigationResult = {
  __typename?: "CIPInvestigationResult";
  categories: Array<CipInvestigationCategory>;
  status: CipInvestigationStatus;
};

export enum CipInvestigationStatus {
  Accepted = "ACCEPTED",
  Appealed = "APPEALED",
  Error = "ERROR",
  Indeterminate = "INDETERMINATE",
  Pending = "PENDING",
  Rejected = "REJECTED",
}

export type CacheBetaValueInput = {
  endDate?: InputMaybe<Scalars["Date"]["input"]>;
  interval?: InputMaybe<DateInterval>;
  seedSymbol: Scalars["String"]["input"];
  startDate: Scalars["Date"]["input"];
  symbols?: InputMaybe<Array<Scalars["String"]["input"]>>;
};

export type CancelAchDepositInput = {
  transferId: Scalars["ID"]["input"];
};

export type CancelAchDepositResponse = {
  __typename?: "CancelAchDepositResponse";
  status: CashTransferStatus;
};

export type CancelAchWithdrawalInput = {
  transferId: Scalars["ID"]["input"];
};

export type CancelAchWithdrawalResponse = {
  __typename?: "CancelAchWithdrawalResponse";
  status: CashTransferStatus;
};

export type CancelCashTransferInput = {
  transferId: Scalars["ID"]["input"];
};

export type CancelCashTransferResponse = {
  __typename?: "CancelCashTransferResponse";
  status: CashTransferStatus;
};

export type CancelCheckWithdrawalInput = {
  transferId: Scalars["ID"]["input"];
};

export type CancelCheckWithdrawalResponse = {
  __typename?: "CancelCheckWithdrawalResponse";
  cashTransfer: CashTransfer;
};

export type CancelLiquidateDirectIndexPortfolioInput = {
  liquidateDirectIndexPortfolioRequestId: Scalars["ID"]["input"];
};

export type CancelOrderInput = {
  orderId: Scalars["ID"]["input"];
};

export type CancelOrderResponse = {
  __typename?: "CancelOrderResponse";
  order: Order;
};

export type CancelWireWithdrawalInput = {
  clearingAccountId: Scalars["ID"]["input"];
  transferId: Scalars["ID"]["input"];
};

export type CancelWireWithdrawalResponse = {
  __typename?: "CancelWireWithdrawalResponse";
  cashTransfer: CashTransfer;
};

export type CashDividend = {
  __typename?: "CashDividend";
  amount: Scalars["Decimal"]["output"];
  cashDividendFee: Array<CashDividendFee>;
  clearingAccountId: Scalars["ID"]["output"];
  description?: Maybe<Scalars["String"]["output"]>;
  eventTime: Scalars["DateTime"]["output"];
  id: Scalars["ID"]["output"];
  security?: Maybe<Security>;
  securityId: Scalars["ID"]["output"];
  subAccountId: Scalars["ID"]["output"];
};

export type CashDividendFee = {
  __typename?: "CashDividendFee";
  amount: Scalars["Decimal"]["output"];
  cashDividendId: Scalars["ID"]["output"];
  eventTime: Scalars["DateTime"]["output"];
  id: Scalars["ID"]["output"];
  type: CashDividendFeeType;
};

export enum CashDividendFeeType {
  AgencyProcessingFee = "AGENCY_PROCESSING_FEE",
  MiscellaneousFee = "MISCELLANEOUS_FEE",
}

export type CashRewardType = {
  __typename?: "CashRewardType";
  amount: Scalars["Decimal"]["output"];
};

export type CashTransfer = {
  __typename?: "CashTransfer";
  amount: Scalars["Decimal"]["output"];
  automated: Scalars["Boolean"]["output"];
  cashTransferFee?: Maybe<Array<CashTransferFee>>;
  clearingAccount?: Maybe<ClearingAccount>;
  clearingAccountId: Scalars["ID"]["output"];
  destinationDepositAccount?: Maybe<DepositAccount>;
  direction: CashTransferDirection;
  eventTime: Scalars["DateTime"]["output"];
  id: Scalars["ID"]["output"];
  method: CashTransferMethod;
  referenceId?: Maybe<Scalars["String"]["output"]>;
  sourceDepositAccount?: Maybe<DepositAccount>;
  status: CashTransferStatus;
  statusEventTime: Scalars["DateTime"]["output"];
  statusHistory?: Maybe<Array<CashTransferStatusHistory>>;
  subAccountId: Scalars["ID"]["output"];
  user?: Maybe<User>;
  userId: Scalars["ID"]["output"];
};

export enum CashTransferDirection {
  Deposit = "DEPOSIT",
  Withdrawal = "WITHDRAWAL",
}

export type CashTransferFee = {
  __typename?: "CashTransferFee";
  clearingHouseCredit?: Maybe<Scalars["Decimal"]["output"]>;
  correspondentNetAmount?: Maybe<Scalars["Decimal"]["output"]>;
  customerDebit?: Maybe<Scalars["Decimal"]["output"]>;
  id: Scalars["ID"]["output"];
  type: CashTransferFeeType;
};

export enum CashTransferFeeType {
  Operating = "OPERATING",
  Return = "RETURN",
  Unknown = "UNKNOWN",
}

export enum CashTransferMethod {
  Ach = "ACH",
  Check = "CHECK",
  Wire = "WIRE",
}

export enum CashTransferStatus {
  Canceled = "CANCELED",
  CancelPending = "CANCEL_PENDING",
  CancelRejected = "CANCEL_REJECTED",
  CancelRequested = "CANCEL_REQUESTED",
  Completed = "COMPLETED",
  FailedAtBank = "FAILED_AT_BANK",
  FundsPosted = "FUNDS_POSTED",
  OpsApproved = "OPS_APPROVED",
  OpsRejected = "OPS_REJECTED",
  OpsReviewRequired = "OPS_REVIEW_REQUIRED",
  Pending = "PENDING",
  Rejected = "REJECTED",
  Returned = "RETURNED",
  Undefined = "UNDEFINED",
  UserInitiated = "USER_INITIATED",
}

export type CashTransferStatusHistory = {
  __typename?: "CashTransferStatusHistory";
  eventTime: Scalars["DateTime"]["output"];
  externalStatus?: Maybe<Scalars["String"]["output"]>;
  externalStatusReason?: Maybe<Scalars["String"]["output"]>;
  externalStatusTime?: Maybe<Scalars["DateTime"]["output"]>;
  id: Scalars["ID"]["output"];
  reason?: Maybe<Scalars["String"]["output"]>;
  reasonDesc?: Maybe<Scalars["String"]["output"]>;
  reasonDetail?: Maybe<Scalars["String"]["output"]>;
  reasonRecommendation?: Maybe<Scalars["String"]["output"]>;
  status: Scalars["String"]["output"];
};

export type CashTransferToDirectIndexPortfolioInput = {
  amount: Scalars["Decimal"]["input"];
  clearingAccountId: Scalars["ID"]["input"];
  /** @deprecated use sourceId instead */
  depositPlaidAccountId?: InputMaybe<Scalars["ID"]["input"]>;
  source: MoneyMovementSourceType;
  sourceId?: InputMaybe<Scalars["ID"]["input"]>;
  subAccountId: Scalars["ID"]["input"];
};

export enum CategorizationType {
  Custodian = "CUSTODIAN",
  CCorporation = "C_CORPORATION",
  FirmAccount = "FIRM_ACCOUNT",
  Individual = "INDIVIDUAL",
  Ira = "IRA",
  Joint = "JOINT",
  LimitedLiabilityCompanyCCorporation = "LIMITED_LIABILITY_COMPANY_C_CORPORATION",
  LimitedLiabilityCompanyPartnership = "LIMITED_LIABILITY_COMPANY_PARTNERSHIP",
  LimitedLiabilityCompanySCorporation = "LIMITED_LIABILITY_COMPANY_S_CORPORATION",
  Solo_401K = "SOLO_401K",
  StatutoryBusinessTrust = "STATUTORY_BUSINESS_TRUST",
  SCorporation = "S_CORPORATION",
  Unspecified = "UNSPECIFIED",
}

export type CheckDayTradeInput = {
  clearingAccountId: Scalars["ID"]["input"];
  currentSellOrder?: InputMaybe<SellOrder>;
  range?: InputMaybe<DateRange>;
};

export type ClearingAccount = {
  __typename?: "ClearingAccount";
  accountType?: Maybe<AccountType>;
  accountUser: Array<ClearingAccountUser>;
  actionContexts?: Maybe<Array<ActionContextStatus>>;
  activePaymentConfigs?: Maybe<Array<PaymentConfig>>;
  activeScheduledDepositConfigs?: Maybe<Array<ScheduledDepositConfig>>;
  activity?: Maybe<PaginatedActivity>;
  cashDividend?: Maybe<CashDividend>;
  cashDividendsForHolding?: Maybe<Array<CashDividend>>;
  cashTransfer?: Maybe<CashTransfer>;
  catAccountHolderType?: Maybe<AccountHolderType>;
  categorizationType?: Maybe<CategorizationType>;
  cipInvestigationResult?: Maybe<CipInvestigationResult>;
  dailyCashDividend?: Maybe<DailyCashDividend>;
  demoActiveScheduledDepositConfigs?: Maybe<Array<ScheduledDepositConfig>>;
  demoCashTransfer?: Maybe<CashTransfer>;
  demoDailyCashDividend?: Maybe<DailyCashDividend>;
  demoIntraAccountCashTransfer?: Maybe<IntraAccountCashTransfer>;
  demoIntraAccountStockTransfer?: Maybe<IntraAccountStockTransfer>;
  demoSelfManagedTaxLossConfig?: Maybe<SelfManagedTaxLossConfig>;
  demoSimpleActivity?: Maybe<PaginatedSimpleActivity>;
  depositAccounts?: Maybe<Array<DepositAccount>>;
  dividendPreference?: Maybe<Array<DividendPreference>>;
  dividendSummary?: Maybe<DividendSummary>;
  entity?: Maybe<ClearingAccountEntity>;
  eventTime?: Maybe<Scalars["DateTime"]["output"]>;
  externalClearingAccountId: Scalars["ID"]["output"];
  fdid: Scalars["ID"]["output"];
  getAccountAcatsTransfers?: Maybe<PaginatedAcatsTransferResponse>;
  id: Scalars["ID"]["output"];
  intraAccountCashTransfer?: Maybe<IntraAccountCashTransfer>;
  intraAccountStockTransfer?: Maybe<IntraAccountStockTransfer>;
  intraDayMarginCallData?: Maybe<IntraDayMarginCallData>;
  isClientAccount?: Maybe<Scalars["Boolean"]["output"]>;
  isPatternDayTrader?: Maybe<Scalars["Boolean"]["output"]>;
  isTestAccount?: Maybe<Scalars["Boolean"]["output"]>;
  marginCallData?: Maybe<MarginCallData>;
  marginInterestCharge?: Maybe<MarginInterestCharge>;
  order?: Maybe<Order>;
  paymentExecution?: Maybe<PaymentExecution>;
  primarySubAccountId: Scalars["String"]["output"];
  primaryUser?: Maybe<User>;
  realizedLotsSummary?: Maybe<RealizedLotsSummary>;
  recentDirectIndexDeposits?: Maybe<Array<ScheduledDepositExecution>>;
  recentPayments?: Maybe<Array<PaymentExecution>>;
  restrictionHistory?: Maybe<Array<ClearingAccountRestriction>>;
  restrictions?: Maybe<Array<ClearingAccountRestriction>>;
  scheduledRewards?: Maybe<Array<ScheduledReward>>;
  selfManagedTaxLossConfig?: Maybe<SelfManagedTaxLossConfig>;
  simpleActivity?: Maybe<PaginatedSimpleActivity>;
  simpleActivityById?: Maybe<SimpleActivity>;
  stockAdjustment?: Maybe<StockAdjustmentDetails>;
  stockAdjustmentsForHolding?: Maybe<Array<StockAdjustmentDetails>>;
  subAccounts: Array<SubAccount>;
  taxLotEntries?: Maybe<Array<TaxLotEntry>>;
  treasury?: Maybe<TreasuryConfig>;
  userInvestorDocuments?: Maybe<GetUserInvestorDocumentsResponse>;
};

export type ClearingAccountActiveScheduledDepositConfigsArgs = {
  subAccountId?: InputMaybe<Scalars["ID"]["input"]>;
};

export type ClearingAccountActivityArgs = {
  input: ActivityInput;
};

export type ClearingAccountCashDividendArgs = {
  id: Scalars["ID"]["input"];
};

export type ClearingAccountCashDividendsForHoldingArgs = {
  after?: InputMaybe<Scalars["DateTime"]["input"]>;
  securityId: Scalars["ID"]["input"];
};

export type ClearingAccountCashTransferArgs = {
  id: Scalars["ID"]["input"];
};

export type ClearingAccountDailyCashDividendArgs = {
  id: Scalars["ID"]["input"];
};

export type ClearingAccountDemoActiveScheduledDepositConfigsArgs = {
  subAccountId?: InputMaybe<Scalars["ID"]["input"]>;
};

export type ClearingAccountDemoCashTransferArgs = {
  id: Scalars["ID"]["input"];
};

export type ClearingAccountDemoDailyCashDividendArgs = {
  id: Scalars["ID"]["input"];
};

export type ClearingAccountDemoIntraAccountCashTransferArgs = {
  id: Scalars["ID"]["input"];
};

export type ClearingAccountDemoIntraAccountStockTransferArgs = {
  id: Scalars["ID"]["input"];
};

export type ClearingAccountDemoSimpleActivityArgs = {
  input: ActivityInput;
};

export type ClearingAccountDividendSummaryArgs = {
  input: DividendSummaryInput;
};

export type ClearingAccountGetAccountAcatsTransfersArgs = {
  nextPageToken?: InputMaybe<Scalars["String"]["input"]>;
  size?: InputMaybe<Scalars["Int"]["input"]>;
};

export type ClearingAccountIntraAccountCashTransferArgs = {
  id: Scalars["ID"]["input"];
};

export type ClearingAccountIntraAccountStockTransferArgs = {
  id: Scalars["ID"]["input"];
};

export type ClearingAccountMarginInterestChargeArgs = {
  id: Scalars["ID"]["input"];
};

export type ClearingAccountOrderArgs = {
  id: Scalars["ID"]["input"];
};

export type ClearingAccountPaymentExecutionArgs = {
  id: Scalars["ID"]["input"];
};

export type ClearingAccountRealizedLotsSummaryArgs = {
  input?: InputMaybe<RealizedLotsSummaryInput>;
};

export type ClearingAccountSimpleActivityArgs = {
  input: ActivityInput;
};

export type ClearingAccountSimpleActivityByIdArgs = {
  id: Scalars["ID"]["input"];
  type: ActivityType;
};

export type ClearingAccountStockAdjustmentArgs = {
  id: Scalars["ID"]["input"];
};

export type ClearingAccountStockAdjustmentsForHoldingArgs = {
  adjustmentType?: InputMaybe<AdjustmentType>;
  after?: InputMaybe<Scalars["DateTime"]["input"]>;
  securityId: Scalars["ID"]["input"];
};

export type ClearingAccountTaxLotEntriesArgs = {
  input?: InputMaybe<TaxLotInput>;
};

export type ClearingAccountUserInvestorDocumentsArgs = {
  category: InvestorDocumentCategory;
  nextPageToken?: InputMaybe<Scalars["String"]["input"]>;
  startDate?: InputMaybe<Scalars["String"]["input"]>;
};

export type ClearingAccountEntity = BusinessEntityInfo | TrustEntity;

export enum ClearingAccountIdType {
  ExternalId = "EXTERNAL_ID",
  Id = "ID",
}

export type ClearingAccountInput = {
  id: Scalars["ID"]["input"];
  type: ClearingAccountIdType;
};

export enum ClearingAccountMetadataRequestType {
  AccountClose = "ACCOUNT_CLOSE",
  AccountCreate = "ACCOUNT_CREATE",
  AccountReopen = "ACCOUNT_REOPEN",
  AccountUpdate = "ACCOUNT_UPDATE",
}

export type ClearingAccountQaDataInput = {
  id?: InputMaybe<Scalars["String"]["input"]>;
  primaryUserId: Scalars["String"]["input"];
};

export enum ClearingAccountRequestStatus {
  RequestActionRequired = "REQUEST_ACTION_REQUIRED",
  RequestCanceled = "REQUEST_CANCELED",
  RequestComplete = "REQUEST_COMPLETE",
  RequestError = "REQUEST_ERROR",
  RequestNew = "REQUEST_NEW",
  RequestOpsRejected = "REQUEST_OPS_REJECTED",
  RequestOpsRetry = "REQUEST_OPS_RETRY",
  RequestPending = "REQUEST_PENDING",
  RequestRejected = "REQUEST_REJECTED",
  RequestUserInitiated = "REQUEST_USER_INITIATED",
}

export type ClearingAccountRestriction = {
  __typename?: "ClearingAccountRestriction";
  active: Scalars["Boolean"]["output"];
  eventTime?: Maybe<Scalars["DateTime"]["output"]>;
  restrictionType: ClearingAccountRestrictionType;
};

export enum ClearingAccountRestrictionType {
  CashTransferReview = "CASH_TRANSFER_REVIEW",
  Closed = "CLOSED",
  Closing = "CLOSING",
  ForcedLiquidation = "FORCED_LIQUIDATION",
  Frozen = "FROZEN",
  MarginCall = "MARGIN_CALL",
  PatternDayTrade = "PATTERN_DAY_TRADE",
  Pending = "PENDING",
  RebalanceCall = "REBALANCE_CALL",
  ReconBreak = "RECON_BREAK",
  Rejected = "REJECTED",
  RestrictTransfersOut = "RESTRICT_TRANSFERS_OUT",
}

export type ClearingAccountUser = {
  __typename?: "ClearingAccountUser";
  type: ClearingAccountUserType;
  user?: Maybe<User>;
  userId: Scalars["ID"]["output"];
};

export enum ClearingAccountUserType {
  Primary = "PRIMARY",
  Secondary = "SECONDARY",
}

export enum ClearingHouseId {
  Apex = "APEX",
  Drivewealth = "DRIVEWEALTH",
}

export type ComparisonPortfolioSnapshot = {
  __typename?: "ComparisonPortfolioSnapshot";
  clearingAccountId: Scalars["ID"]["output"];
  date: Scalars["DateTime"]["output"];
  snapshotHoldingClosingValue: Scalars["Decimal"]["output"];
  subAccountId: Scalars["ID"]["output"];
};

export type ComparisonPortfolioSnapshotHolding = {
  __typename?: "ComparisonPortfolioSnapshotHolding";
  closingPrice: Scalars["Decimal"]["output"];
  quantity: Scalars["Decimal"]["output"];
  symbol: Scalars["String"]["output"];
};

export type CompletedInviteStatusEntry = {
  __typename?: "CompletedInviteStatusEntry";
  eventTime: Scalars["DateTime"]["output"];
  inviteCodeApplicationId: Scalars["String"]["output"];
  redactedRefereeFullName: Scalars["String"]["output"];
  refereeUserId?: Maybe<Scalars["String"]["output"]>;
  referrerUserId?: Maybe<Scalars["String"]["output"]>;
  rewards: Array<ReferralRewardDetails>;
  userReferralDeal?: Maybe<UserReferralDeal>;
};

export type CompletedInvitesForUserInput = {
  nextPageToken?: InputMaybe<Scalars["String"]["input"]>;
  pageSize?: InputMaybe<Scalars["Int"]["input"]>;
};

export type ConstantMmfData = {
  __typename?: "ConstantMMFData";
  cusip: Scalars["String"]["output"];
  fundLink: Scalars["String"]["output"];
  inceptionDate: Scalars["DateTime"]["output"];
  minInvestment: Scalars["Int"]["output"];
  symbol: Scalars["String"]["output"];
};

export enum CoreExchangeAccountType {
  Deposit = "DEPOSIT",
  Investment = "INVESTMENT",
  Loan = "LOAN",
  Loc = "LOC",
}

export type CreateDirectIndexSubAccountInput = {
  amount: Scalars["Decimal"]["input"];
  clearingAccountId: Scalars["ID"]["input"];
  customization?: InputMaybe<DirectIndexOnCreateCustomizationInput>;
  /** @deprecated use sourceId instead */
  depositPlaidAccountId?: InputMaybe<Scalars["ID"]["input"]>;
  lotsToTransferBySymbol?: InputMaybe<Array<LotsToTransferBySymbolInput>>;
  source?: InputMaybe<MoneyMovementSourceType>;
  sourceId?: InputMaybe<Scalars["ID"]["input"]>;
  type: DirectIndexType;
};

export type CreateLinkedBrokerageAccountInput = {
  accountNumber: Scalars["String"]["input"];
  name: Scalars["String"]["input"];
  nsccNumber: Scalars["String"]["input"];
};

export type CreateLoanForInterestInput = {
  billingPeriodStr?: InputMaybe<Scalars["String"]["input"]>;
  dryRun?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type CreatePendingLoanInput = {
  amount?: InputMaybe<Scalars["Decimal"]["input"]>;
  cashTransferMethod?: InputMaybe<CashTransferMethod>;
  clearingAccountId: Scalars["ID"]["input"];
  depositAccountId?: InputMaybe<Scalars["ID"]["input"]>;
  loanType?: InputMaybe<LoanType>;
  pendingAcatsIds?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  pendingBatchOrderMetadataID?: InputMaybe<Scalars["ID"]["input"]>;
  pendingCashTransferID?: InputMaybe<Scalars["ID"]["input"]>;
};

export type Credit = {
  __typename?: "Credit";
  amount: Scalars["Decimal"]["output"];
  clearingAccountId: Scalars["ID"]["output"];
  contraAccount: Scalars["String"]["output"];
  eventTime: Scalars["DateTime"]["output"];
  id: Scalars["ID"]["output"];
  source?: Maybe<CreditSource>;
  status: CreditStatus;
  subAccountId: Scalars["ID"]["output"];
  type: CreditType;
};

export enum CreditSource {
  DiReferral = "DI_REFERRAL",
}

export enum CreditStatus {
  Canceled = "CANCELED",
  Completed = "COMPLETED",
  Created = "CREATED",
  Rejected = "REJECTED",
  Requested = "REQUESTED",
}

export enum CreditType {
  Courtesy = "COURTESY",
  FeeReversal = "FEE_REVERSAL",
  PromoCredit = "PROMO_CREDIT",
  Rebate = "REBATE",
  WriteOff = "WRITE_OFF",
  ZeroingAccount = "ZEROING_ACCOUNT",
}

export type CumulativeNetDeposit = {
  __typename?: "CumulativeNetDeposit";
  date: Scalars["Date"]["output"];
  value: Scalars["Decimal"]["output"];
};

export type DailyCashDividend = {
  __typename?: "DailyCashDividend";
  amount: Scalars["Decimal"]["output"];
  clearingAccountId: Scalars["ID"]["output"];
  dividends: Array<DividendSecurity>;
  eventTime: Scalars["DateTime"]["output"];
  fees: Array<DividendSecurityFee>;
  id: Scalars["ID"]["output"];
  processDate: Scalars["Date"]["output"];
  subAccountId: Scalars["ID"]["output"];
};

export type DailyMarginInterestCharge = {
  __typename?: "DailyMarginInterestCharge";
  date: Scalars["Date"]["output"];
  loan: Scalars["Decimal"]["output"];
  marginInterest: Scalars["Decimal"]["output"];
  marginInterestCharge: Scalars["Decimal"]["output"];
};

export enum DateInterval {
  Day = "DAY",
  Month = "MONTH",
  Quarter = "QUARTER",
  Week = "WEEK",
  Year = "YEAR",
}

export type DatePeriod = {
  __typename?: "DatePeriod";
  endDate: Scalars["Date"]["output"];
  startDate: Scalars["Date"]["output"];
};

export type DatePeriodArgs = {
  endDate: Scalars["Date"]["input"];
  startDate: Scalars["Date"]["input"];
};

export type DateRange = {
  endDate: Scalars["Date"]["input"];
  startDate: Scalars["Date"]["input"];
};

export type DayTradeResponse = {
  __typename?: "DayTradeResponse";
  currentTradeFlagged: Scalars["Boolean"]["output"];
  dayRange: Scalars["Int"]["output"];
  dayTrades: Scalars["Int"]["output"];
  totalTrades: Scalars["Int"]["output"];
};

export type DeleteBusinessEntityIndividualInput = {
  id: Scalars["ID"]["input"];
};

export type DepositAccount = {
  __typename?: "DepositAccount";
  accountNumber?: Maybe<Scalars["String"]["output"]>;
  apexRelationshipId?: Maybe<Scalars["String"]["output"]>;
  apexRelationshipStatus?: Maybe<Scalars["String"]["output"]>;
  clearingAccountId?: Maybe<Scalars["ID"]["output"]>;
  deleted?: Maybe<Scalars["Boolean"]["output"]>;
  id: Scalars["ID"]["output"];
  name?: Maybe<Scalars["String"]["output"]>;
  officialName?: Maybe<Scalars["String"]["output"]>;
  plaidAccountId?: Maybe<Scalars["ID"]["output"]>;
  routingNumber?: Maybe<Scalars["String"]["output"]>;
  subType: Scalars["String"]["output"];
  wireRoutingNumber?: Maybe<Scalars["String"]["output"]>;
};

export type DepositAccountInput = {
  accountNumber?: InputMaybe<Scalars["String"]["input"]>;
  clearingAccountId?: InputMaybe<Scalars["ID"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  officialName?: InputMaybe<Scalars["String"]["input"]>;
  plaidAccountId?: InputMaybe<Scalars["ID"]["input"]>;
  routingNumber?: InputMaybe<Scalars["String"]["input"]>;
  subType: Scalars["String"]["input"];
  wireRoutingNumber?: InputMaybe<Scalars["String"]["input"]>;
};

export enum DepositAccountStatus {
  Active = "ACTIVE",
  Pending = "PENDING",
  Rejected = "REJECTED",
}

export type DetailedQuote = {
  __typename?: "DetailedQuote";
  ask?: Maybe<Scalars["Float"]["output"]>;
  askMarketIdentificationCode?: Maybe<Scalars["String"]["output"]>;
  askSize?: Maybe<Scalars["Int"]["output"]>;
  askTime?: Maybe<Scalars["DateTime"]["output"]>;
  bid?: Maybe<Scalars["Float"]["output"]>;
  bidMarketIdentificationCode?: Maybe<Scalars["String"]["output"]>;
  bidSize?: Maybe<Scalars["Int"]["output"]>;
  bidTime?: Maybe<Scalars["DateTime"]["output"]>;
  changeFromPreviousClose: Scalars["Float"]["output"];
  close: Scalars["Float"]["output"];
  currency: Scalars["String"]["output"];
  delay: Scalars["Float"]["output"];
  high: Scalars["Float"]["output"];
  high52Weeks: Scalars["Float"]["output"];
  identifier: Scalars["String"]["output"];
  identifierType: Scalars["String"]["output"];
  last: Scalars["Float"]["output"];
  lastMarketIdentificationCode?: Maybe<Scalars["String"]["output"]>;
  lastSize: Scalars["Int"]["output"];
  low: Scalars["Float"]["output"];
  low52Weeks: Scalars["Float"]["output"];
  message: Scalars["String"]["output"];
  open: Scalars["Float"]["output"];
  outcome: Scalars["String"]["output"];
  percentChangeFromPreviousClose: Scalars["Float"]["output"];
  previousClose: Scalars["Float"]["output"];
  previousCloseDate: Scalars["Date"]["output"];
  security: DetailedSecurity;
  time: Scalars["DateTime"]["output"];
  tradingHalted: Scalars["Boolean"]["output"];
  volume: Scalars["Int"]["output"];
};

export type DetailedQuoteInput = {
  clearingAccountIdForDisplay?: InputMaybe<Scalars["String"]["input"]>;
  realTime: Scalars["Boolean"]["input"];
  symbols: Array<Scalars["String"]["input"]>;
};

export type DetailedSecurity = {
  __typename?: "DetailedSecurity";
  CIK?: Maybe<Scalars["String"]["output"]>;
  CUSIP?: Maybe<Scalars["String"]["output"]>;
  ISIN?: Maybe<Scalars["String"]["output"]>;
  categoryOrIndustry?: Maybe<Scalars["String"]["output"]>;
  market: Scalars["String"]["output"];
  marketIdentificationCode: Scalars["String"]["output"];
  mostLiquidExchange: Scalars["Boolean"]["output"];
  name: Scalars["String"]["output"];
  symbol: Scalars["String"]["output"];
  valoren?: Maybe<Scalars["String"]["output"]>;
};

export type DirectIndexAccountInfo = {
  __typename?: "DirectIndexAccountInfo";
  cantBuy: Array<Scalars["String"]["output"]>;
  cantSell: Array<Scalars["String"]["output"]>;
  diCash: Scalars["Decimal"]["output"];
  stockInfo: Array<DirectIndexStockInfo>;
};

export type DirectIndexAccountInfoInput = {
  cantBuy: Array<Scalars["String"]["input"]>;
  cantSell: Array<Scalars["String"]["input"]>;
  diCash: Scalars["Decimal"]["input"];
  stockInfo: Array<DirectIndexStockInfoInput>;
  subAccountId: Scalars["ID"]["input"];
};

export type DirectIndexAccountMetadata = {
  __typename?: "DirectIndexAccountMetadata";
  customization?: Maybe<DirectIndexCustomization>;
  etfSymbol?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["ID"]["output"];
  lhfOverride?: Maybe<Scalars["Decimal"]["output"]>;
  signedAt?: Maybe<Scalars["DateTime"]["output"]>;
  status?: Maybe<DirectIndexStatus>;
  subAccountId: Scalars["ID"]["output"];
  type: DirectIndexType;
};

export type DirectIndexAccountWeights = {
  __typename?: "DirectIndexAccountWeights";
  subAccountId: Scalars["ID"]["output"];
  weights: Array<DirectIndexWeight>;
};

export enum DirectIndexAttribute {
  AddSecurityId = "ADD_SECURITY_ID",
  RemoveGicsSector = "REMOVE_GICS_SECTOR",
  RemoveSecurityId = "REMOVE_SECURITY_ID",
}

export enum DirectIndexCandidateGenerationRunMode {
  AllowWashSales = "ALLOW_WASH_SALES",
  Default = "DEFAULT",
  Rebalance = "REBALANCE",
}

export enum DirectIndexCandidateSkipReason {
  ClearingAccountRestricted = "CLEARING_ACCOUNT_RESTRICTED",
  DiAccountClosed = "DI_ACCOUNT_CLOSED",
  DiAccountNotReady = "DI_ACCOUNT_NOT_READY",
  DiAccountPaused = "DI_ACCOUNT_PAUSED",
  ErrorFetchingStockInfo = "ERROR_FETCHING_STOCK_INFO",
  ErrorRunningOptimization = "ERROR_RUNNING_OPTIMIZATION",
  HoldingsTaxLotMismatch = "HOLDINGS_TAX_LOT_MISMATCH",
  InvalidLiquidateResponse = "INVALID_LIQUIDATE_RESPONSE",
  MultipleLiquidationRequestsFound = "MULTIPLE_LIQUIDATION_REQUESTS_FOUND",
  MultiIndexConflict = "MULTI_INDEX_CONFLICT",
  NoCandidatesGenerated = "NO_CANDIDATES_GENERATED",
  SuccessfulDiRunFoundForToday = "SUCCESSFUL_DI_RUN_FOUND_FOR_TODAY",
  UnhandledError = "UNHANDLED_ERROR",
}

export type DirectIndexCustomization = {
  __typename?: "DirectIndexCustomization";
  addSecurityIds: Array<IndexCustomizationSecurity>;
  id: Scalars["ID"]["output"];
  removeGICSSectorIds: Array<GicsCode>;
  removeSecurityIds: Array<IndexCustomizationSecurity>;
};

export type DirectIndexCustomizationInput = {
  addSecuritySymbols: Array<Scalars["String"]["input"]>;
  removeGICSSectorIds: Array<GicsCode>;
  removeSecuritySymbols: Array<Scalars["String"]["input"]>;
  subAccountId: Scalars["ID"]["input"];
};

export enum DirectIndexFeeBilledTo {
  DirectIndexAssets = "DIRECT_INDEX_ASSETS",
  DirectIndexCash = "DIRECT_INDEX_CASH",
  Loan = "LOAN",
}

export type DirectIndexFeeCharge = {
  __typename?: "DirectIndexFeeCharge";
  amount: Scalars["Decimal"]["output"];
  billedTo: DirectIndexFeeBilledTo;
  billingEndDate: Scalars["DateTime"]["output"];
  billingStartDate: Scalars["DateTime"]["output"];
  eventTime: Scalars["DateTime"]["output"];
  id: Scalars["ID"]["output"];
};

export enum DirectIndexFeeStatus {
  Canceled = "CANCELED",
  Completed = "COMPLETED",
  FundsPosted = "FUNDS_POSTED",
  Initiated = "INITIATED",
  Invalidated = "INVALIDATED",
  Pending = "PENDING",
  Rejected = "REJECTED",
  Returned = "RETURNED",
}

export type DirectIndexOnCreateCustomizationInput = {
  addSecuritySymbols: Array<Scalars["String"]["input"]>;
  removeGICSSectorIds: Array<GicsCode>;
  removeSecuritySymbols: Array<Scalars["String"]["input"]>;
};

export type DirectIndexOptimizationRunData = {
  __typename?: "DirectIndexOptimizationRunData";
  dayTradeCantSell: Array<Scalars["String"]["output"]>;
  lossHarvestCantBuy: Array<Scalars["String"]["output"]>;
  missingCostCantSell: Array<Scalars["String"]["output"]>;
  postProcessedResponse: DirectIndexOptimizeResult;
  rawResponse: DirectIndexOptimizeResult;
  valueCantBuy: Array<Scalars["String"]["output"]>;
  washSalesCantBuy: Array<Scalars["String"]["output"]>;
  washSalesCantSell: Array<Scalars["String"]["output"]>;
};

export type DirectIndexOptimizeResult = {
  __typename?: "DirectIndexOptimizeResult";
  cashDelta: Scalars["Decimal"]["output"];
  quantityDelta: Array<DirectIndexQuantityDelta>;
};

export type DirectIndexOptions = {
  allowGains?: InputMaybe<Scalars["Boolean"]["input"]>;
  allowSelling?: InputMaybe<Scalars["Boolean"]["input"]>;
  avoidBuyingSymbolsWithLoss?: InputMaybe<Scalars["Boolean"]["input"]>;
  avoidDayTrades?: InputMaybe<Scalars["Boolean"]["input"]>;
  avoidSmallWeightPositions?: InputMaybe<Scalars["Boolean"]["input"]>;
  avoidWashSales?: InputMaybe<Scalars["Boolean"]["input"]>;
  diFeeBPS?: InputMaybe<Scalars["Decimal"]["input"]>;
  lossHarvestingFactor?: InputMaybe<Scalars["Decimal"]["input"]>;
  maxPercentageToPreventBuying?: InputMaybe<Scalars["Decimal"]["input"]>;
  minCashBuffer?: InputMaybe<Scalars["Decimal"]["input"]>;
  minimumTradeAmount?: InputMaybe<Scalars["Decimal"]["input"]>;
  minimumValueForInvestment?: InputMaybe<Scalars["Decimal"]["input"]>;
  transactionCosts?: InputMaybe<Scalars["Decimal"]["input"]>;
  useCovarianceMatrix?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type DirectIndexOverlap = {
  __typename?: "DirectIndexOverlap";
  directIndexType: DirectIndexType;
  overlapPercent: Scalars["Decimal"]["output"];
};

export type DirectIndexOverrideInput = {
  overrides: Array<DirectIndexOverrideSecurityInput>;
  subAccountId: Scalars["ID"]["input"];
};

export type DirectIndexOverrideSecurityInput = {
  canLiquidate: Scalars["Boolean"]["input"];
  canPurchase: Scalars["Boolean"]["input"];
  symbol: Scalars["String"]["input"];
  weight: Scalars["Decimal"]["input"];
};

export type DirectIndexPerformanceData = {
  __typename?: "DirectIndexPerformanceData";
  comparisonPortfolioHistory?: Maybe<Array<ComparisonPortfolioSnapshot>>;
  directIndexHistory: Array<PortfolioSnapshot>;
  periods: Array<DirectIndexPerformanceDataPeriod>;
};

export type DirectIndexPerformanceDataPeriod = {
  __typename?: "DirectIndexPerformanceDataPeriod";
  cumulativeNetDeposits: Array<CumulativeNetDeposit>;
  estimatedTaxSavings?: Maybe<Scalars["Decimal"]["output"]>;
  longTermGains?: Maybe<Scalars["Decimal"]["output"]>;
  longTermLosses?: Maybe<Scalars["Decimal"]["output"]>;
  longWashSalesDisallowed?: Maybe<Scalars["Decimal"]["output"]>;
  moneyWeightedReturnPercent?: Maybe<Scalars["Decimal"]["output"]>;
  netDeposits?: Maybe<Scalars["Decimal"]["output"]>;
  netLongTermLosses?: Maybe<Scalars["Decimal"]["output"]>;
  netShortTermLosses?: Maybe<Scalars["Decimal"]["output"]>;
  period: Scalars["String"]["output"];
  shortTermGains?: Maybe<Scalars["Decimal"]["output"]>;
  shortTermLosses?: Maybe<Scalars["Decimal"]["output"]>;
  shortWashSalesDisallowed?: Maybe<Scalars["Decimal"]["output"]>;
  simpleReturnPercent?: Maybe<Scalars["Decimal"]["output"]>;
  taxLossesHarvested?: Maybe<Scalars["Decimal"]["output"]>;
  timeWeightedReturnPercent?: Maybe<Scalars["Decimal"]["output"]>;
};

export type DirectIndexQuantityDelta = {
  __typename?: "DirectIndexQuantityDelta";
  delta: Scalars["Decimal"]["output"];
  symbol: Scalars["String"]["output"];
};

export type DirectIndexSearchResult = {
  __typename?: "DirectIndexSearchResult";
  indexName: Scalars["String"]["output"];
  indexType: DirectIndexType;
};

export enum DirectIndexSecurityOverrideSource {
  Admin = "ADMIN",
  CorpAction = "CORP_ACTION",
}

export type DirectIndexSimulationAction = {
  __typename?: "DirectIndexSimulationAction";
  direction: Scalars["String"]["output"];
  longTermGains: Scalars["Decimal"]["output"];
  price: Scalars["Decimal"]["output"];
  proceeds: Scalars["Decimal"]["output"];
  quantity: Scalars["Decimal"]["output"];
  shortTermGains: Scalars["Decimal"]["output"];
  symbol: Scalars["String"]["output"];
};

export type DirectIndexSimulationHolding = {
  __typename?: "DirectIndexSimulationHolding";
  cost: Scalars["Decimal"]["output"];
  price: Scalars["Decimal"]["output"];
  quantity: Scalars["Decimal"]["output"];
  symbol: Scalars["String"]["output"];
  targetWeight: Scalars["Decimal"]["output"];
};

export type DirectIndexSimulationOutputOptions = {
  summarize: Scalars["Boolean"]["input"];
  trackHistory: Scalars["Boolean"]["input"];
};

export type DirectIndexSimulationRunInfo = {
  __typename?: "DirectIndexSimulationRunInfo";
  date: Scalars["DateTime"]["output"];
  id: Scalars["String"]["output"];
  multiIndexSimulationId?: Maybe<Scalars["String"]["output"]>;
  serializedArgs: Scalars["String"]["output"];
  status: DirectIndexSimulationStatus;
};

export type DirectIndexSimulationRunInfoById = {
  __typename?: "DirectIndexSimulationRunInfoById";
  info: DirectIndexSimulationRunInfo;
  serializedResult?: Maybe<Scalars["String"]["output"]>;
};

export type DirectIndexSimulationState = {
  __typename?: "DirectIndexSimulationState";
  cash: Scalars["Decimal"]["output"];
  date: Scalars["Date"]["output"];
  holdings: Array<DirectIndexSimulationHolding>;
  logData: DirectIndexSimulationStateLogData;
};

export type DirectIndexSimulationStateLogData = {
  __typename?: "DirectIndexSimulationStateLogData";
  deposits: Scalars["Decimal"]["output"];
  longTermGains: Scalars["Decimal"]["output"];
  shortTermGains: Scalars["Decimal"]["output"];
  stockValue: Scalars["Decimal"]["output"];
};

export enum DirectIndexSimulationStatus {
  Cancelled = "CANCELLED",
  Cancelling = "CANCELLING",
  Completed = "COMPLETED",
  Failed = "FAILED",
  Running = "RUNNING",
  Submitted = "SUBMITTED",
}

export enum DirectIndexSolver {
  Barra = "barra",
  BnbCvxopt = "bnb_cvxopt",
  BnbOsqp = "bnb_osqp",
  BnbProxqp = "bnb_proxqp",
  BnbPyomoMosek = "bnb_pyomo_mosek",
  MiqpCplexDirect = "miqp_cplex_direct",
  MiqpGurobi = "miqp_gurobi",
  MiqpMosek = "miqp_mosek",
}

export type DirectIndexSolverOptions = {
  forceLiquidateZeroTargets?: InputMaybe<Scalars["Boolean"]["input"]>;
  solver?: InputMaybe<DirectIndexSolver>;
  timeoutMs?: InputMaybe<Scalars["Int"]["input"]>;
};

export enum DirectIndexStatus {
  Closed = "CLOSED",
  Init = "INIT",
  InitialFundingFailed = "INITIAL_FUNDING_FAILED",
  InitialFundingPending = "INITIAL_FUNDING_PENDING",
  Paused = "PAUSED",
  Running = "RUNNING",
}

export type DirectIndexStockInfo = {
  __typename?: "DirectIndexStockInfo";
  barraId?: Maybe<Scalars["String"]["output"]>;
  price: Scalars["Decimal"]["output"];
  secondaryTaxLots?: Maybe<Array<DirectIndexTaxLot>>;
  symbol: Scalars["String"]["output"];
  targetWeight: Scalars["Decimal"]["output"];
  taxLots: Array<DirectIndexTaxLot>;
};

export type DirectIndexStockInfoInput = {
  barraId?: InputMaybe<Scalars["String"]["input"]>;
  price: Scalars["Decimal"]["input"];
  symbol: Scalars["String"]["input"];
  targetWeight: Scalars["Decimal"]["input"];
  taxLots: Array<DirectIndexTaxLotInput>;
};

export type DirectIndexTaxLot = {
  __typename?: "DirectIndexTaxLot";
  buyBackIndicator: BuyBackIndicatorEnumGql;
  closedLotId?: Maybe<Scalars["String"]["output"]>;
  closedTransactionId?: Maybe<Scalars["String"]["output"]>;
  cost: Scalars["Decimal"]["output"];
  eventTime: Scalars["DateTime"]["output"];
  id: Scalars["ID"]["output"];
  longTermShortTermIndicator: LongTermShortTermIndicatorEnumGql;
  netProceed: Scalars["Decimal"]["output"];
  openBuyCostAmount: Scalars["Decimal"]["output"];
  openBuyPrice: Scalars["Decimal"]["output"];
  openLotId: Scalars["String"]["output"];
  openTransactionId: Scalars["String"]["output"];
  quantity: Scalars["Decimal"]["output"];
  realizedGainLoss: Scalars["Decimal"]["output"];
  realizedIndicator: RealizedIndicatorEnumGql;
  securityId: Scalars["String"]["output"];
  settlementDate?: Maybe<Scalars["Date"]["output"]>;
  subAccountId: Scalars["String"]["output"];
  taxLotCloseSellDate?: Maybe<Scalars["Date"]["output"]>;
  taxLotOpenBuyDate?: Maybe<Scalars["Date"]["output"]>;
  washSaleAdjustmentAmount: Scalars["Decimal"]["output"];
  washSalesDisallowed: Scalars["Decimal"]["output"];
  washSalesIndicator: WashSaleIndicatorEnumGql;
};

export type DirectIndexTaxLotInput = {
  buyBackIndicator: BuyBackIndicatorEnumGql;
  closedLotId?: InputMaybe<Scalars["String"]["input"]>;
  closedTransactionId?: InputMaybe<Scalars["String"]["input"]>;
  cost: Scalars["Decimal"]["input"];
  eventTime: Scalars["DateTime"]["input"];
  id: Scalars["ID"]["input"];
  longTermShortTermIndicator: LongTermShortTermIndicatorEnumGql;
  netProceed: Scalars["Decimal"]["input"];
  openBuyCostAmount: Scalars["Decimal"]["input"];
  openBuyPrice: Scalars["Decimal"]["input"];
  openLotId: Scalars["String"]["input"];
  openTransactionId: Scalars["String"]["input"];
  quantity: Scalars["Decimal"]["input"];
  realizedGainLoss: Scalars["Decimal"]["input"];
  realizedIndicator: RealizedIndicatorEnumGql;
  securityId: Scalars["String"]["input"];
  settlementDate?: InputMaybe<Scalars["Date"]["input"]>;
  subAccountId: Scalars["String"]["input"];
  taxLotCloseSellDate?: InputMaybe<Scalars["Date"]["input"]>;
  taxLotOpenBuyDate?: InputMaybe<Scalars["Date"]["input"]>;
  washSaleAdjustmentAmount: Scalars["Decimal"]["input"];
  washSalesDisallowed: Scalars["Decimal"]["input"];
  washSalesIndicator: WashSaleIndicatorEnumGql;
};

export type DirectIndexTradeExecution = {
  __typename?: "DirectIndexTradeExecution";
  batchOrderMetadataId?: Maybe<Scalars["ID"]["output"]>;
  cashLeftover?: Maybe<Scalars["Decimal"]["output"]>;
  clearingAccount?: Maybe<ClearingAccount>;
  estimatedGainLoss?: Maybe<Scalars["Decimal"]["output"]>;
  eventTime: Scalars["DateTime"]["output"];
  id: Scalars["ID"]["output"];
  isFullLiquidation: Scalars["Boolean"]["output"];
  liquidateAmount?: Maybe<Scalars["Decimal"]["output"]>;
  metadata: Array<DirectIndexTradeExecutionMetadata>;
  status: TradeExecutionStatus;
  statusHistory?: Maybe<Array<DirectIndexTradeExecutionStatusHistory>>;
  subAccountId: Scalars["ID"]["output"];
  type?: Maybe<TradeExecutionType>;
  weights?: Maybe<Array<DirectIndexWeight>>;
};

export type DirectIndexTradeExecutionMetadata = {
  __typename?: "DirectIndexTradeExecutionMetadata";
  deleted: Scalars["Boolean"]["output"];
  id: Scalars["ID"]["output"];
  orderStatus?: Maybe<OrderStatus>;
  price: Scalars["Decimal"]["output"];
  quantity: Scalars["Decimal"]["output"];
  security?: Maybe<Security>;
  securityId: Scalars["ID"]["output"];
};

export type DirectIndexTradeExecutionStatusHistory = {
  __typename?: "DirectIndexTradeExecutionStatusHistory";
  directIndexTradeExecutionId: Scalars["ID"]["output"];
  eventTime: Scalars["DateTime"]["output"];
  id: Scalars["ID"]["output"];
  status: TradeExecutionStatus;
};

export type DirectIndexTradeExecutionSummary = {
  __typename?: "DirectIndexTradeExecutionSummary";
  cashLeftover?: Maybe<Scalars["Decimal"]["output"]>;
  clearingAccount?: Maybe<ClearingAccount>;
  clearingAccountId: Scalars["ID"]["output"];
  eventTime: Scalars["DateTime"]["output"];
  id: Scalars["ID"]["output"];
  isLiquidationRequest: Scalars["Boolean"]["output"];
  latestStatusTime?: Maybe<Scalars["DateTime"]["output"]>;
  orders: Array<TradeExecutionOrders>;
  status: TradeExecutionStatus;
  subAccountId: Scalars["ID"]["output"];
};

export enum DirectIndexType {
  CrspIssLargeCapEsg = "CRSP_ISS_LARGE_CAP_ESG",
  CrspLargeCap = "CRSP_LARGE_CAP",
  CrspMidCap = "CRSP_MID_CAP",
  CrspSmallCap = "CRSP_SMALL_CAP",
  CustomFixedWeight = "CUSTOM_FIXED_WEIGHT",
  CustomMarketCap = "CUSTOM_MARKET_CAP",
  EtfBased = "ETF_BASED",
  Russell_1000 = "RUSSELL_1000",
  Russell_2000 = "RUSSELL_2000",
  Russell_3000 = "RUSSELL_3000",
  Smh = "SMH",
  Sp500 = "SP500",
  SpAdrDm = "SP_ADR_DM",
  SpInfoTech = "SP_INFO_TECH",
  SpShariah = "SP_SHARIAH",
}

export type DirectIndexWeight = {
  __typename?: "DirectIndexWeight";
  canDisplayWeight: Scalars["Boolean"]["output"];
  canLiquidate: Scalars["Boolean"]["output"];
  canPurchase: Scalars["Boolean"]["output"];
  computedAt?: Maybe<Scalars["DateTime"]["output"]>;
  security?: Maybe<Security>;
  securityId: Scalars["ID"]["output"];
  weight: Scalars["Decimal"]["output"];
};

export type DirectIndexWeightInput = {
  canLiquidate: Scalars["Boolean"]["input"];
  canPurchase: Scalars["Boolean"]["input"];
  securityId: Scalars["ID"]["input"];
  weight: Scalars["Decimal"]["input"];
};

export type DirectIndexWeightsInput = {
  fromDateTime?: InputMaybe<Scalars["Date"]["input"]>;
  subAccountId: Scalars["ID"]["input"];
  toDateTime?: InputMaybe<Scalars["Date"]["input"]>;
};

export type DividendPreference = {
  __typename?: "DividendPreference";
  clearingAccountId: Scalars["ID"]["output"];
  effectiveTime: Scalars["DateTime"]["output"];
  id: Scalars["ID"]["output"];
  preferenceType: DividendPreferenceType;
  subAccountType: SubAccountType;
};

export type DividendPreferenceInput = {
  clearingAccountId: Scalars["ID"]["input"];
  effectiveTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  preferenceType: DividendPreferenceType;
  subAccountType: SubAccountType;
};

export enum DividendPreferenceType {
  MoveToDirectIndexing = "MOVE_TO_DIRECT_INDEXING",
  MoveToFrecCash = "MOVE_TO_FREC_CASH",
  MoveToTreasury = "MOVE_TO_TREASURY",
  PayLoanAndFrecCash = "PAY_LOAN_AND_FREC_CASH",
  Reinvest = "REINVEST",
}

export enum DividendReinvestment {
  DoNotReinvest = "DO_NOT_REINVEST",
  Reinvest = "REINVEST",
}

export type DividendSecurity = {
  __typename?: "DividendSecurity";
  amount: Scalars["Decimal"]["output"];
  security?: Maybe<Security>;
  securityId: Scalars["ID"]["output"];
};

export type DividendSecurityFee = {
  __typename?: "DividendSecurityFee";
  amount: Scalars["Decimal"]["output"];
  security?: Maybe<Security>;
  securityId: Scalars["ID"]["output"];
  type: CashDividendFeeType;
};

export type DividendSummary = {
  __typename?: "DividendSummary";
  stockAdjustmentAmounts?: Maybe<Array<StockAdjustmentAmountByType>>;
  totalDividends: Scalars["Decimal"]["output"];
};

export type DividendSummaryInput = {
  fromDate?: InputMaybe<Scalars["Date"]["input"]>;
  subAccountId?: InputMaybe<Scalars["ID"]["input"]>;
  toDate?: InputMaybe<Scalars["Date"]["input"]>;
};

export type EmailCaptureInput = {
  email: Scalars["String"]["input"];
  emailCaptureDate?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export enum EmailType {
  Primary = "PRIMARY",
  Secondary = "SECONDARY",
}

export enum EmploymentStatus {
  Employed = "EMPLOYED",
  Retired = "RETIRED",
  Student = "STUDENT",
  Unemployed = "UNEMPLOYED",
  Unspecified = "UNSPECIFIED",
}

export type EntityAccountRequestStatus = {
  __typename?: "EntityAccountRequestStatus";
  asOfDate: Scalars["DateTime"]["output"];
  state: EntityAccountRequestStatusState;
};

export enum EntityAccountRequestStatusState {
  AccountRequestCompleted = "ACCOUNT_REQUEST_COMPLETED",
  AdminCanceled = "ADMIN_CANCELED",
  BusinessAccountInitiated = "BUSINESS_ACCOUNT_INITIATED",
  PendingBusinessAccount = "PENDING_BUSINESS_ACCOUNT",
  PendingTrustAccount = "PENDING_TRUST_ACCOUNT",
  TrustAccountInitiated = "TRUST_ACCOUNT_INITIATED",
}

export enum ErrorType {
  ApiSchemaFailure = "API_SCHEMA_FAILURE",
  AuthenticationFailure = "AUTHENTICATION_FAILURE",
  AuthorizationFailure = "AUTHORIZATION_FAILURE",
  Conflict = "CONFLICT",
  MfaChallenge = "MFA_CHALLENGE",
  NetworkError = "NETWORK_ERROR",
  UnexpectedError = "UNEXPECTED_ERROR",
  UnexpectedNumberOfPrimaryUsers = "UNEXPECTED_NUMBER_OF_PRIMARY_USERS",
  UserLoginConflict = "USER_LOGIN_CONFLICT",
  ValidationFailure = "VALIDATION_FAILURE",
}

export type EstimateDirectIndexGainLossInput = {
  clearingAccountId: Scalars["ID"]["input"];
  customization?: InputMaybe<DirectIndexOnCreateCustomizationInput>;
  diCash?: InputMaybe<Scalars["Decimal"]["input"]>;
  directIndexType: DirectIndexType;
  isFullLiquidation?: InputMaybe<Scalars["Boolean"]["input"]>;
  liquidateAmount?: InputMaybe<Scalars["Decimal"]["input"]>;
  subAccountId?: InputMaybe<Scalars["ID"]["input"]>;
  transferStockFromDIArgs?: InputMaybe<TransferStockFromDiInput>;
  transferStockSubAccountArgs?: InputMaybe<TransferStockSubAccountInput>;
};

export type EstimateDirectIndexGainLossResponse = {
  __typename?: "EstimateDirectIndexGainLossResponse";
  lossHarvestPotential: Scalars["Decimal"]["output"];
  netLongTermLossesFromSubAccountsYTD: Scalars["Decimal"]["output"];
  netShortTermLossesFromSubAccountsYTD: Scalars["Decimal"]["output"];
  securityGainLoss: Array<SecurityGainLoss>;
  totalLongTermGainsWithoutWashSales: Scalars["Decimal"]["output"];
  totalLongTermWashSales: Scalars["Decimal"]["output"];
  totalShortTermGainsWithoutWashSales: Scalars["Decimal"]["output"];
  totalShortTermWashSales: Scalars["Decimal"]["output"];
};

export type EventHistory = {
  __typename?: "EventHistory";
  processedEvents?: Maybe<Array<ApexSimulationEvent>>;
  simulationEvents?: Maybe<Array<ApexSimulationEvent>>;
};

export enum EventType {
  AcatsTransfers = "ACATS_TRANSFERS",
  Accounts = "ACCOUNTS",
  AchRelationships = "ACH_RELATIONSHIPS",
  BasketOrdersAccountAllocation = "BASKET_ORDERS_ACCOUNT_ALLOCATION",
  BasketOrdersMf = "BASKET_ORDERS_MF",
  BasketOrdersSubmission = "BASKET_ORDERS_SUBMISSION",
  CashTransfers = "CASH_TRANSFERS",
  MutualFundsOrders = "MUTUAL_FUNDS_ORDERS",
  MutualFundsOrdersRiskSummary = "MUTUAL_FUNDS_ORDERS_RISK_SUMMARY",
  Orders = "ORDERS",
  SketchInvestigationStatus = "SKETCH_INVESTIGATION_STATUS",
  WireDeposits = "WIRE_DEPOSITS",
}

export type ExternalAccountHoldings = {
  __typename?: "ExternalAccountHoldings";
  holdings: Array<Holding>;
  id: Scalars["ID"]["output"];
};

export type ExternalAccountHoldingsInput = {
  holdings: Array<HoldingInput>;
  id: Scalars["ID"]["input"];
};

export enum ExtractNumber {
  Ext001 = "EXT001",
  Ext235 = "EXT235",
  Ext236 = "EXT236",
  Ext250 = "EXT250",
  Ext538 = "EXT538",
  Ext590 = "EXT590",
  Ext596 = "EXT596",
  Ext747 = "EXT747",
  Ext765 = "EXT765",
  Ext869 = "EXT869",
  Ext870 = "EXT870",
  Ext871 = "EXT871",
  Ext872 = "EXT872",
  Ext901 = "EXT901",
  Ext902 = "EXT902",
  Ext922 = "EXT922",
  Ext981 = "EXT981",
  Ext982 = "EXT982",
  Ext983 = "EXT983",
  Ext986 = "EXT986",
  Ext989 = "EXT989",
  Ext997 = "EXT997",
  Ext1027 = "EXT1027",
}

export enum FdxConnectionAggregator {
  Plaid = "PLAID",
  Yodlee = "YODLEE",
}

export type FactInput = {
  actionContext: ActionContext;
  clearingAccountId: Scalars["String"]["input"];
  clearingAccountRestriction?: InputMaybe<ClearingAccountRestrictionType>;
  quantityType?: InputMaybe<Scalars["String"]["input"]>;
  subAccountId?: InputMaybe<Scalars["String"]["input"]>;
  symbol?: InputMaybe<Scalars["String"]["input"]>;
};

export enum FeeType {
  AdminFee = "ADMIN_FEE",
  BasketTrade = "BASKET_TRADE",
  DirectIndex = "DIRECT_INDEX",
  MarginInterest = "MARGIN_INTEREST",
  Treasury = "TREASURY",
  WireIn = "WIRE_IN",
}

export enum FrecAccountType {
  Business = "BUSINESS",
  Individual = "INDIVIDUAL",
  Trust = "TRUST",
}

export type Fundamentals = {
  __typename?: "Fundamentals";
  bookValue?: Maybe<Scalars["Decimal"]["output"]>;
  bookValuePerShare?: Maybe<Scalars["Decimal"]["output"]>;
  currency: Scalars["String"]["output"];
  date: Scalars["DateTime"]["output"];
  dividendExDate?: Maybe<Scalars["String"]["output"]>;
  dividendYieldSecurity?: Maybe<Scalars["Decimal"]["output"]>;
  ebitda?: Maybe<Scalars["Decimal"]["output"]>;
  marketCapitalization?: Maybe<Scalars["Decimal"]["output"]>;
  marketCapitalizationExcludingNonTradedIssuerLevel?: Maybe<
    Scalars["Decimal"]["output"]
  >;
  netMargin?: Maybe<Scalars["Decimal"]["output"]>;
  priceToBook?: Maybe<Scalars["Decimal"]["output"]>;
  sector?: Maybe<Scalars["String"]["output"]>;
  sectorName?: Maybe<Scalars["String"]["output"]>;
};

export type FundamentalsInput = {
  endDate?: InputMaybe<Scalars["Date"]["input"]>;
  startDate: Scalars["Date"]["input"];
  symbols: Array<Scalars["String"]["input"]>;
};

export type FundamentalsSet = {
  __typename?: "FundamentalsSet";
  fundamentals: Array<Fundamentals>;
  symbol: Scalars["String"]["output"];
};

export enum GicsCode {
  Gics10 = "GICS10",
  Gics15 = "GICS15",
  Gics20 = "GICS20",
  Gics25 = "GICS25",
  Gics30 = "GICS30",
  Gics35 = "GICS35",
  Gics40 = "GICS40",
  Gics45 = "GICS45",
  Gics50 = "GICS50",
  Gics55 = "GICS55",
  Gics60 = "GICS60",
}

export type GenerateAutoTlhCandidatesResponse = {
  __typename?: "GenerateAutoTLHCandidatesResponse";
  execution?: Maybe<TradeExecution>;
  reason?: Maybe<Scalars["String"]["output"]>;
  subAccountId: Scalars["ID"]["output"];
};

export type GenerateCandidatesInput = {
  asOfDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  autoApprove?: InputMaybe<Scalars["Boolean"]["input"]>;
  dryRun?: InputMaybe<Scalars["Boolean"]["input"]>;
  forceRebalance?: InputMaybe<Scalars["Boolean"]["input"]>;
  runForSubAccountIds?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  runMode?: InputMaybe<DirectIndexCandidateGenerationRunMode>;
  skipLogs?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type GenerateCandidatesResponse = {
  __typename?: "GenerateCandidatesResponse";
  execution?: Maybe<DirectIndexTradeExecution>;
  reason?: Maybe<Scalars["String"]["output"]>;
  subAccountId: Scalars["ID"]["output"];
};

export type GenerateReportInput = {
  date?: InputMaybe<Scalars["Date"]["input"]>;
  encrypt?: InputMaybe<Scalars["Boolean"]["input"]>;
  endDate?: InputMaybe<Scalars["Date"]["input"]>;
  forceGenerate?: InputMaybe<Scalars["Boolean"]["input"]>;
  reports: Array<ReportType>;
};

export type GenerateReportResponse = {
  __typename?: "GenerateReportResponse";
  date: Scalars["Date"]["output"];
  endDate?: Maybe<Scalars["Date"]["output"]>;
  reportType: ReportType;
  status: StatusCode;
};

export type GenerateTlhCandidatesInput = {
  asOfDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  autoApprove?: InputMaybe<Scalars["Boolean"]["input"]>;
  dryRun?: InputMaybe<Scalars["Boolean"]["input"]>;
  runForClearingAccountIds?: InputMaybe<Array<Scalars["ID"]["input"]>>;
};

export type GenerateUserCodeInput = {
  deviceId?: InputMaybe<Scalars["String"]["input"]>;
  type: MfaType;
};

export type GenerateUserCodeResponse = {
  __typename?: "GenerateUserCodeResponse";
  code: Scalars["String"]["output"];
};

export type GenericAddress = {
  __typename?: "GenericAddress";
  addressLine1: Scalars["String"]["output"];
  addressLine2?: Maybe<Scalars["String"]["output"]>;
  addressType: AddressType;
  city: Scalars["String"]["output"];
  country: Scalars["String"]["output"];
  id: Scalars["ID"]["output"];
  state: Scalars["String"]["output"];
  zipCode: Scalars["String"]["output"];
};

export type GenericAddressInput = {
  addressLine1: Scalars["String"]["input"];
  addressLine2?: InputMaybe<Scalars["String"]["input"]>;
  addressType: AddressType;
  city: Scalars["String"]["input"];
  country: Scalars["String"]["input"];
  id?: InputMaybe<Scalars["ID"]["input"]>;
  state: Scalars["String"]["input"];
  zipCode: Scalars["String"]["input"];
};

export type GetCashTransfersArgs = {
  after?: InputMaybe<Scalars["DateTime"]["input"]>;
  before?: InputMaybe<Scalars["DateTime"]["input"]>;
  clearingAccountIds?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  directions?: InputMaybe<Array<CashTransferDirection>>;
  methods?: InputMaybe<Array<CashTransferMethod>>;
  nextPageToken?: InputMaybe<Scalars["String"]["input"]>;
  size?: InputMaybe<Scalars["Int"]["input"]>;
  statuses?: InputMaybe<Array<CashTransferStatus>>;
  subAccountIds?: InputMaybe<Array<Scalars["ID"]["input"]>>;
};

export type GetDirectIndexPerformanceDataInput = {
  performanceDataPeriod?: InputMaybe<PerformanceDataPeriod>;
  subAccountId: Scalars["ID"]["input"];
};

export type GetDirectIndexTradeExecutionsInput = {
  fromDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  status: Array<TradeExecutionStatus>;
  subAccountId: Scalars["ID"]["input"];
  toDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  type?: InputMaybe<TradeExecutionType>;
};

export type GetDividendsArgs = {
  after?: InputMaybe<Scalars["DateTime"]["input"]>;
  before?: InputMaybe<Scalars["DateTime"]["input"]>;
  clearingAccountId: Scalars["ID"]["input"];
  nextPageToken?: InputMaybe<Scalars["String"]["input"]>;
  size?: InputMaybe<Scalars["Int"]["input"]>;
};

export type GetHistoricalIndexPerformanceDataInput = {
  period: PerformanceDataPeriod;
  type: DirectIndexType;
};

export type GetIndexDataInput = {
  types: Array<DirectIndexType>;
};

export type GetIndexForSecurityInput = {
  asOfDate?: InputMaybe<Scalars["Date"]["input"]>;
  symbol: Array<Scalars["String"]["input"]>;
};

export type GetIndexForSecurityResponse = {
  __typename?: "GetIndexForSecurityResponse";
  indexResults: Array<Maybe<IndexResultForSymbol>>;
};

export type GetPendingLoansInput = {
  clearingAccountId: Scalars["ID"]["input"];
  pendingLoanStatuses: Array<PendingLoanStatus>;
};

export type GetPendingLoansResponse = {
  __typename?: "GetPendingLoansResponse";
  data: Array<PendingLoan>;
  nextPageToken?: Maybe<Scalars["String"]["output"]>;
};

export type GetStockAdjustmentArgs = {
  after?: InputMaybe<Scalars["DateTime"]["input"]>;
  before?: InputMaybe<Scalars["DateTime"]["input"]>;
  clearingAccountId: Scalars["ID"]["input"];
  nextPageToken?: InputMaybe<Scalars["String"]["input"]>;
  size?: InputMaybe<Scalars["Int"]["input"]>;
};

export type GetUserInvestorDocumentsInput = {
  category: InvestorDocumentCategory;
  clearingAccountId: Scalars["ID"]["input"];
  nextPageToken?: InputMaybe<Scalars["String"]["input"]>;
  startDate?: InputMaybe<Scalars["Date"]["input"]>;
};

export type GetUserInvestorDocumentsResponse = {
  __typename?: "GetUserInvestorDocumentsResponse";
  documents: Array<UserInvestorDocument>;
  nextPageToken?: Maybe<Scalars["String"]["output"]>;
};

export type HistoricalDelta = {
  __typename?: "HistoricalDelta";
  deltas: Array<StockHistory>;
  prices: Array<StockHistory>;
  symbol: Scalars["String"]["output"];
};

export type HistoricalDeltaInput = {
  endDate?: InputMaybe<Scalars["Date"]["input"]>;
  interval?: InputMaybe<DateInterval>;
  startDate: Scalars["Date"]["input"];
  symbols: Array<Scalars["String"]["input"]>;
};

export type HistoricalDividendPayments = {
  __typename?: "HistoricalDividendPayments";
  adjDividend: Scalars["Decimal"]["output"];
  date: Scalars["Date"]["output"];
  declarationDate?: Maybe<Scalars["Date"]["output"]>;
  dividend: Scalars["Decimal"]["output"];
  paymentDate?: Maybe<Scalars["Date"]["output"]>;
  recordDate?: Maybe<Scalars["Date"]["output"]>;
};

export type HistoricalInput = {
  endDate?: InputMaybe<Scalars["Date"]["input"]>;
  interval: DateInterval;
  startDate?: InputMaybe<Scalars["Date"]["input"]>;
  symbols: Array<Scalars["String"]["input"]>;
};

export type HistoricalMarketCap = {
  __typename?: "HistoricalMarketCap";
  date: Scalars["Date"]["output"];
  marketCap: Scalars["Decimal"]["output"];
  symbol: Scalars["String"]["output"];
};

export type HistoricalMarketCapInput = {
  asOfDate: Scalars["Date"]["input"];
  symbols: Array<Scalars["String"]["input"]>;
};

export type HistoricalQuote = {
  __typename?: "HistoricalQuote";
  TWAP?: Maybe<Scalars["Float"]["output"]>;
  VWAP?: Maybe<Scalars["Float"]["output"]>;
  adjustmentMethodUsed?: Maybe<Scalars["String"]["output"]>;
  change: Scalars["Float"]["output"];
  close: Scalars["Float"]["output"];
  cumulativeAdjustmentFactor?: Maybe<Scalars["Float"]["output"]>;
  currency?: Maybe<Scalars["String"]["output"]>;
  date?: Maybe<Scalars["Date"]["output"]>;
  high?: Maybe<Scalars["Float"]["output"]>;
  low?: Maybe<Scalars["Float"]["output"]>;
  open: Scalars["Float"]["output"];
  percentChange: Scalars["Float"]["output"];
  previousClose: Scalars["Float"]["output"];
  previousCloseDate?: Maybe<Scalars["Date"]["output"]>;
  volume: Scalars["Float"]["output"];
};

export type HistoricalQuoteInput = {
  __typename?: "HistoricalQuoteInput";
  adjustmentMethod: AdjustmentMethod;
  asOfDate: Scalars["Date"]["output"];
  symbols: Array<Scalars["String"]["output"]>;
};

export type HistoricalQuoteResponse = {
  __typename?: "HistoricalQuoteResponse";
  asOfDate: Scalars["Date"]["output"];
  delay?: Maybe<Scalars["Float"]["output"]>;
  historicalQuote: HistoricalQuote;
  identifier?: Maybe<Scalars["String"]["output"]>;
  identifierType?: Maybe<Scalars["String"]["output"]>;
  identity?: Maybe<Scalars["String"]["output"]>;
  message?: Maybe<Scalars["String"]["output"]>;
  outcome: Scalars["String"]["output"];
  security: HistoricalSecurity;
};

export type HistoricalSecurity = {
  __typename?: "HistoricalSecurity";
  CIK?: Maybe<Scalars["String"]["output"]>;
  CUSIP?: Maybe<Scalars["String"]["output"]>;
  FIGI?: Maybe<Scalars["String"]["output"]>;
  ISIN?: Maybe<Scalars["String"]["output"]>;
  SEDOL?: Maybe<Scalars["String"]["output"]>;
  compositeFIGI?: Maybe<Scalars["String"]["output"]>;
  industry?: Maybe<Scalars["String"]["output"]>;
  instrumentClass?: Maybe<Scalars["String"]["output"]>;
  market?: Maybe<Scalars["String"]["output"]>;
  marketIdentificationCode?: Maybe<Scalars["String"]["output"]>;
  name?: Maybe<Scalars["String"]["output"]>;
  sector?: Maybe<Scalars["String"]["output"]>;
  symbol: Scalars["String"]["output"];
  valoren?: Maybe<Scalars["String"]["output"]>;
};

export type Holding = {
  __typename?: "Holding";
  marginRequirement: Scalars["Decimal"]["output"];
  price: Scalars["Decimal"]["output"];
  quantity: Scalars["Decimal"]["output"];
  symbol: Scalars["String"]["output"];
};

export type HoldingInput = {
  marginRequirement: Scalars["Decimal"]["input"];
  price: Scalars["Decimal"]["input"];
  quantity: Scalars["Decimal"]["input"];
  subType: SecuritySubType;
  symbol: Scalars["String"]["input"];
  type: SecurityType;
};

export type IndexConstituent = {
  __typename?: "IndexConstituent";
  canDisplayWeight: Scalars["Boolean"]["output"];
  effectiveDate: Scalars["Date"]["output"];
  fractionalSupported: Scalars["Boolean"]["output"];
  gicsCode?: Maybe<GicsCode>;
  indexMarketCap: Scalars["Float"]["output"];
  marketCap: Scalars["Float"]["output"];
  name: Scalars["String"]["output"];
  price: Scalars["Float"]["output"];
  symbol: Scalars["String"]["output"];
  weight: Scalars["Float"]["output"];
};

export type IndexConstituentData = {
  __typename?: "IndexConstituentData";
  constituents: Array<IndexConstituent>;
  index: StockIndex;
};

export type IndexCountryData = {
  __typename?: "IndexCountryData";
  countryMarketCapPercent: Scalars["Float"]["output"];
  countryName: Scalars["String"]["output"];
};

export type IndexCustomizationSecurity = {
  __typename?: "IndexCustomizationSecurity";
  security?: Maybe<Security>;
  securityId: Scalars["ID"]["output"];
};

export type IndexDetails = {
  __typename?: "IndexDetails";
  allowedSectorCustomizations?: Maybe<Scalars["Int"]["output"]>;
  allowedStockCustomizations: Scalars["Int"]["output"];
  canCustomizeSectors: Scalars["Boolean"]["output"];
  comparisonEtf: Scalars["String"]["output"];
  comparisonEtfExpenseRatio: Scalars["Decimal"]["output"];
  comparisonEtfName: Scalars["String"]["output"];
  constituentData: IndexExplorerConstituentData;
  feePercent: Scalars["Decimal"]["output"];
  historicalPerformanceData: IndexHistoricalPerformanceData;
  indexDescription: Scalars["String"]["output"];
  indexDisplayCategory: IndexDisplayCategory;
  indexName: Scalars["String"]["output"];
  indexSubText: Scalars["String"]["output"];
  indexType: DirectIndexType;
  investmentMinimum: Scalars["Decimal"]["output"];
  learnMoreLink: Scalars["String"]["output"];
  numberOfPositions: Scalars["Int"]["output"];
  stockIndex: StockIndex;
  ytdReturn: Scalars["Decimal"]["output"];
};

export enum IndexDisplayCategory {
  MostPopular = "MOST_POPULAR",
  Regular = "REGULAR",
}

export type IndexExplorerConstituentData = {
  __typename?: "IndexExplorerConstituentData";
  asOfDate?: Maybe<Scalars["Date"]["output"]>;
  constituents: Array<IndexConstituent>;
  countryData: Array<IndexCountryData>;
  sectorData: Array<IndexSectorData>;
  totalMarketCap: Scalars["Float"]["output"];
};

export type IndexHistorical = {
  __typename?: "IndexHistorical";
  GICSCode?: Maybe<GicsCode>;
  ISOCode?: Maybe<Scalars["String"]["output"]>;
  adjCount?: Maybe<Scalars["Float"]["output"]>;
  adjDivisor?: Maybe<Scalars["Float"]["output"]>;
  adjMarketCap?: Maybe<Scalars["Float"]["output"]>;
  closeCount: Scalars["Float"]["output"];
  closeDivisor?: Maybe<Scalars["Float"]["output"]>;
  closeMarketCap?: Maybe<Scalars["Float"]["output"]>;
  dailyReturn?: Maybe<Scalars["Float"]["output"]>;
  date: Scalars["Date"]["output"];
  indexCode?: Maybe<Scalars["String"]["output"]>;
  indexDividend?: Maybe<Scalars["Float"]["output"]>;
  indexKey: Scalars["String"]["output"];
  indexName: Scalars["String"]["output"];
  indexValue?: Maybe<Scalars["Float"]["output"]>;
};

export type IndexHistoricalData = {
  __typename?: "IndexHistoricalData";
  data?: Maybe<IndexHistorical>;
  index: StockIndex;
};

export type IndexHistoricalPerformanceData = {
  __typename?: "IndexHistoricalPerformanceData";
  period: PerformanceDataPeriod;
  returnForPeriod: Scalars["Decimal"]["output"];
  timeseriesData: Array<IndexPerformancePoint>;
};

export type IndexPerformancePoint = {
  __typename?: "IndexPerformancePoint";
  date: Scalars["Date"]["output"];
  value: Scalars["Decimal"]["output"];
};

export type IndexResultForSymbol = {
  __typename?: "IndexResultForSymbol";
  index?: Maybe<Array<Maybe<StockIndex>>>;
  symbol: Scalars["String"]["output"];
};

export type IndexSectorData = {
  __typename?: "IndexSectorData";
  gicsCode: GicsCode;
  sectorMarketCapPercent: Scalars["Float"]["output"];
  sectorName: Scalars["String"]["output"];
  sectorPositionsCount: Scalars["Int"]["output"];
};

export type IndividualDisclosure = {
  __typename?: "IndividualDisclosure";
  affiliatedExchangeOrFINRA: Scalars["Boolean"]["output"];
  companySymbols?: Maybe<Scalars["String"]["output"]>;
  controlPerson: Scalars["Boolean"]["output"];
  firmName?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["ID"]["output"];
  immediateFamilyMembers?: Maybe<Array<Scalars["String"]["output"]>>;
  politicalOrganization?: Maybe<Scalars["String"]["output"]>;
  politicalTitle?: Maybe<Scalars["String"]["output"]>;
  politicallyExposed: Scalars["Boolean"]["output"];
};

export type InitiateAchDepositInput = {
  amount: Scalars["Decimal"]["input"];
  clearingAccountId: Scalars["ID"]["input"];
  depositAccountId: Scalars["ID"]["input"];
  subAccountId?: InputMaybe<Scalars["ID"]["input"]>;
};

export type InitiateAchDepositResponse = {
  __typename?: "InitiateAchDepositResponse";
  id: Scalars["ID"]["output"];
  status: Scalars["String"]["output"];
};

export type InitiateAchWithdrawalInput = {
  amount: Scalars["Decimal"]["input"];
  clearingAccountId: Scalars["ID"]["input"];
  depositAccountId: Scalars["ID"]["input"];
};

export type InitiateAchWithdrawalResponse = {
  __typename?: "InitiateAchWithdrawalResponse";
  id: Scalars["ID"]["output"];
  status: Scalars["String"]["output"];
};

export type InitiateCashTransferInput = {
  amount: Scalars["Decimal"]["input"];
  clearingAccountId: Scalars["ID"]["input"];
  fromId: Scalars["ID"]["input"];
  fromType: MoneyMovementSourceType;
  isFullLiquidation?: InputMaybe<Scalars["Boolean"]["input"]>;
  toId: Scalars["ID"]["input"];
  toType: MoneyMovementSourceType;
};

export type InitiateCashTransferResponse = {
  __typename?: "InitiateCashTransferResponse";
  status: Scalars["Boolean"]["output"];
  transferId?: Maybe<Scalars["ID"]["output"]>;
  transferIdType?: Maybe<InitiateCashTransferResultIdType>;
};

export enum InitiateCashTransferResultIdType {
  CashTransfer = "CASH_TRANSFER",
  DiExecution = "DI_EXECUTION",
  TreasuryExecution = "TREASURY_EXECUTION",
}

export type InitiateCheckWithdrawalInput = {
  amount: Scalars["Decimal"]["input"];
  clearingAccountId: Scalars["ID"]["input"];
  depositAccountId: Scalars["ID"]["input"];
  subAccountId?: InputMaybe<Scalars["ID"]["input"]>;
};

export type InitiateCheckWithdrawalResponse = {
  __typename?: "InitiateCheckWithdrawalResponse";
  cashTransfer: CashTransfer;
};

export type InitiateLoanInput = {
  amount: Scalars["Decimal"]["input"];
  clearingAccountId: Scalars["ID"]["input"];
};

export type InitiateWireDepositInput = {
  amount: Scalars["Decimal"]["input"];
  clearingAccountId: Scalars["ID"]["input"];
  subAccountId: Scalars["ID"]["input"];
};

export type InitiateWireDepositResponse = {
  __typename?: "InitiateWireDepositResponse";
  id: Scalars["ID"]["output"];
  status: Scalars["String"]["output"];
};

export type InitiateWireWithdrawalInput = {
  amount: Scalars["Decimal"]["input"];
  clearingAccountId: Scalars["ID"]["input"];
  depositAccountId: Scalars["ID"]["input"];
};

export type InitiateWireWithdrawalResponse = {
  __typename?: "InitiateWireWithdrawalResponse";
  cashTransfer: CashTransfer;
};

export type IntervalBuildAndPersistPortfolioSnapshotsForAllActiveArgs = {
  date: Scalars["Date"]["input"];
  dryRun?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type IntervalExecuteExistingBatchOrderInput = {
  batchOrderMetadataId: Scalars["ID"]["input"];
};

export type IntervalExecuteExistingBatchOrderResponse = {
  __typename?: "IntervalExecuteExistingBatchOrderResponse";
  status: Scalars["String"]["output"];
  submissionId?: Maybe<Scalars["ID"]["output"]>;
};

export type IntervalExecuteQueuedCashTransfersInput = {
  asOfDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  cashTransferIds?: InputMaybe<Array<Scalars["ID"]["input"]>>;
};

export type IntervalPopulateSecurityIndexCacheInput = {
  asOfDate?: InputMaybe<Scalars["Date"]["input"]>;
};

export type IntervalSubmitBatchTradeOrderInput = {
  orders: Array<BatchOrderSingleInput>;
  subAccountId: Scalars["ID"]["input"];
};

export type IntervalSubmitBatchTradeOrderResponse = {
  __typename?: "IntervalSubmitBatchTradeOrderResponse";
  batchOrderMetadataId?: Maybe<Scalars["ID"]["output"]>;
};

export type IntraAccountCashTransfer = {
  __typename?: "IntraAccountCashTransfer";
  amount: Scalars["Decimal"]["output"];
  eventTime: Scalars["DateTime"]["output"];
  from: IntraAccountCashTransferDirection;
  id: Scalars["ID"]["output"];
  source: AdjustmentSource;
  subAccountId: Scalars["ID"]["output"];
  to: IntraAccountCashTransferDirection;
};

export enum IntraAccountCashTransferDirection {
  DirectIndex = "DIRECT_INDEX",
  FrecCash = "FREC_CASH",
  Treasury = "TREASURY",
}

export type IntraAccountStockTransfer = {
  __typename?: "IntraAccountStockTransfer";
  adjustmentsGroupId: Scalars["ID"]["output"];
  eventTime: Scalars["DateTime"]["output"];
  from: IntraAccountStockTransferDirection;
  source: AdjustmentSource;
  stocks: Array<IntraAccountStockTransferSecurity>;
  subAccountId: Scalars["ID"]["output"];
  to: IntraAccountStockTransferDirection;
};

export enum IntraAccountStockTransferDirection {
  DirectIndex = "DIRECT_INDEX",
  Primary = "PRIMARY",
}

export type IntraAccountStockTransferSecurity = {
  __typename?: "IntraAccountStockTransferSecurity";
  id: Scalars["ID"]["output"];
  quantity: Scalars["Decimal"]["output"];
  securityPriceAtTransfer: Scalars["Decimal"]["output"];
  symbol: Scalars["String"]["output"];
};

export type IntraDayAccountValues = {
  __typename?: "IntraDayAccountValues";
  amountAvailableToWithdraw?: Maybe<Scalars["Float"]["output"]>;
};

export type IntraDayMarginCallData = {
  __typename?: "IntraDayMarginCallData";
  accountState: MarginAccountState;
  callAmount?: Maybe<Scalars["Decimal"]["output"]>;
};

export enum InvestmentExperience {
  Extensive = "EXTENSIVE",
  Good = "GOOD",
  Limited = "LIMITED",
  None = "NONE",
  Unspecified = "UNSPECIFIED",
}

export enum InvestmentObjective {
  Balanced = "BALANCED",
  GrowthIncome = "GROWTH_INCOME",
  MaximumGrowth = "MAXIMUM_GROWTH",
  Other = "OTHER",
  Unspecified = "UNSPECIFIED",
}

export type InvestmentProfileRangeInput = {
  max: Scalars["Int"]["input"];
  min: Scalars["Int"]["input"];
};

export type InvestmentSearchResult = {
  __typename?: "InvestmentSearchResult";
  directIndices: Array<DirectIndexSearchResult>;
  securities: Array<SecuritySearchResult>;
};

export enum InvestorDocumentCategory {
  Statement = "STATEMENT",
  TaxForm = "TAX_FORM",
  TradeConfirmation = "TRADE_CONFIRMATION",
}

export type InviteCodeApplication = {
  __typename?: "InviteCodeApplication";
  appliedOn: Scalars["DateTime"]["output"];
  code: Scalars["String"]["output"];
  deal?: Maybe<UserReferralDeal>;
  id: Scalars["ID"]["output"];
  rewards: Array<ReferralRewardDetails>;
};

export type JobConfig = {
  __typename?: "JobConfig";
  createdBy: Scalars["String"]["output"];
  firstRunTime: Scalars["DateTime"]["output"];
  id: Scalars["ID"]["output"];
  interval: Scalars["Int"]["output"];
  intervalType: JobConfigIntervalType;
  name: Scalars["String"]["output"];
  status: JobConfigStatus;
  type: JobConfigType;
};

export enum JobConfigIntervalType {
  Days = "DAYS",
  Hours = "HOURS",
  Minutes = "MINUTES",
}

export enum JobConfigStatus {
  Active = "ACTIVE",
  Deleted = "DELETED",
  Paused = "PAUSED",
}

export enum JobConfigType {
  DirectIndexing = "DIRECT_INDEXING",
  EodProcessing = "EOD_PROCESSING",
  Iterable = "ITERABLE",
  Referral = "REFERRAL",
  Reporting = "REPORTING",
  Snapshot = "SNAPSHOT",
  SodPostProcessing = "SOD_POST_PROCESSING",
  SodProcessing = "SOD_PROCESSING",
  StateProcessor = "STATE_PROCESSOR",
}

export enum JobName {
  AutoTlhCandidateGeneration = "AUTO_TLH_CANDIDATE_GENERATION",
  BorrowingPowerSnapshot = "BORROWING_POWER_SNAPSHOT",
  CaisCustomerReport = "CAIS_CUSTOMER_REPORT",
  CrspIndexDataProcessing = "CRSP_INDEX_DATA_PROCESSING",
  DirectIndexCandidateGeneration = "DIRECT_INDEX_CANDIDATE_GENERATION",
  DirectIndexCandidateGenerationRun2 = "DIRECT_INDEX_CANDIDATE_GENERATION_RUN2",
  DirectIndexFee = "DIRECT_INDEX_FEE",
  DirectIndexWeights = "DIRECT_INDEX_WEIGHTS",
  EndOfDayHealthCheck = "END_OF_DAY_HEALTH_CHECK",
  GrantReferralRewardJob = "GRANT_REFERRAL_REWARD_JOB",
  IterableBatchUserUpdate = "ITERABLE_BATCH_USER_UPDATE",
  LiquidationStateTransitionJob = "LIQUIDATION_STATE_TRANSITION_JOB",
  MarginCallProcessing = "MARGIN_CALL_PROCESSING",
  MarketVectorDataProcessing = "MARKET_VECTOR_DATA_PROCESSING",
  PatternDayTradeStatus = "PATTERN_DAY_TRADE_STATUS",
  PortfolioSnapshot = "PORTFOLIO_SNAPSHOT",
  RussellDataProcessing = "RUSSELL_DATA_PROCESSING",
  SodProcessingExt1027 = "SOD_PROCESSING_EXT1027",
  SplitTaxLotProcessing = "SPLIT_TAX_LOT_PROCESSING",
  SpIndexDataProcessing = "SP_INDEX_DATA_PROCESSING",
  StartOfDayHealthCheck = "START_OF_DAY_HEALTH_CHECK",
  TaxLotReconciliation = "TAX_LOT_RECONCILIATION",
  TaxLotTransactionGeneration = "TAX_LOT_TRANSACTION_GENERATION",
  TreasuryExecution = "TREASURY_EXECUTION",
  TreasuryFee = "TREASURY_FEE",
}

export type JobRun = {
  __typename?: "JobRun";
  endDate: Scalars["DateTime"]["output"];
  eventTime: Scalars["DateTime"]["output"];
  id: Scalars["ID"]["output"];
  jobId: Scalars["ID"]["output"];
  notes?: Maybe<Scalars["String"]["output"]>;
  startDate: Scalars["DateTime"]["output"];
  status: JobRunStatus;
};

export enum JobRunStatus {
  Failed = "FAILED",
  Invalidated = "INVALIDATED",
  NotStarted = "NOT_STARTED",
  Running = "RUNNING",
  Skipped = "SKIPPED",
  Success = "SUCCESS",
}

export type KeyValueDeleteInput = {
  keys: Array<Scalars["String"]["input"]>;
  namespace: Scalars["String"]["input"];
};

export type KeyValueGetInput = {
  keys: Array<Scalars["String"]["input"]>;
  namespace: Scalars["String"]["input"];
};

export type KeyValuePair = {
  __typename?: "KeyValuePair";
  expiresAt?: Maybe<Scalars["DateTime"]["output"]>;
  id: Scalars["ID"]["output"];
  key: Scalars["String"]["output"];
  namespace: Scalars["String"]["output"];
  value: Scalars["JSON"]["output"];
};

export type KeyValuePairInput = {
  expiresAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  key: Scalars["String"]["input"];
  value: Scalars["JSON"]["input"];
};

export type KeyValueSetInput = {
  namespace: Scalars["String"]["input"];
  records: Array<KeyValuePairInput>;
};

export type LinkPlaidInput = {
  plaidPublicToken: Scalars["String"]["input"];
  type: PlaidAccountType;
};

export type LinkPlaidResult = {
  __typename?: "LinkPlaidResult";
  accountName: Scalars["String"]["output"];
  id: Scalars["ID"]["output"];
  institution: Scalars["String"]["output"];
  mask?: Maybe<Scalars["String"]["output"]>;
  ownerName?: Maybe<Scalars["String"]["output"]>;
  plaidAccountId: Scalars["String"]["output"];
  status: PlaidAccountStatus;
};

export type LinkedBrokerageAccount = {
  __typename?: "LinkedBrokerageAccount";
  acatsTransfers?: Maybe<Array<AcatsTransfer>>;
  id: Scalars["ID"]["output"];
  institutionName: Scalars["String"]["output"];
  isPartialTransferDisabled: Scalars["Boolean"]["output"];
  mask: Scalars["String"]["output"];
  name: Scalars["String"]["output"];
  nsccNumber?: Maybe<Scalars["String"]["output"]>;
  plaidAccount?: Maybe<PlaidAccount>;
  plaidAccountId?: Maybe<Scalars["ID"]["output"]>;
};

export enum LinkedBrokerageAccountType {
  Manual = "MANUAL",
  Plaid = "PLAID",
}

export type LinkedBrokerageAccountsInput = {
  ids?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  includeDTC?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type LiquidateDirectIndexPortfolioRequest = {
  __typename?: "LiquidateDirectIndexPortfolioRequest";
  amount?: Maybe<Scalars["Decimal"]["output"]>;
  eventTime: Scalars["DateTime"]["output"];
  id: Scalars["ID"]["output"];
  isFullLiquidation?: Maybe<Scalars["Boolean"]["output"]>;
  status: LiquidateDirectIndexPortfolioStatus;
  subAccountId: Scalars["ID"]["output"];
};

export type LiquidateDirectIndexPortfolioResponse = {
  __typename?: "LiquidateDirectIndexPortfolioResponse";
  id?: Maybe<Scalars["ID"]["output"]>;
  status: LiquidateDirectIndexPortfolioStatus;
};

export enum LiquidateDirectIndexPortfolioStatus {
  Canceled = "CANCELED",
  Completed = "COMPLETED",
  Pending = "PENDING",
  Requested = "REQUESTED",
}

export enum LiquidationState {
  Complete = "COMPLETE",
  Failed = "FAILED",
  Init = "INIT",
  WireInitiated = "WIRE_INITIATED",
  WirePending = "WIRE_PENDING",
}

export enum LiquidityNeeds {
  NotImportant = "NOT_IMPORTANT",
  SomewhatImportant = "SOMEWHAT_IMPORTANT",
  Unspecified = "UNSPECIFIED",
  VeryImportant = "VERY_IMPORTANT",
}

export type LiveCashHolding = {
  __typename?: "LiveCashHolding";
  subAccountId: Scalars["ID"]["output"];
  subAccountType: SubAccountType;
  type: LiveCashHoldingType;
  value: Scalars["Decimal"]["output"];
};

export enum LiveCashHoldingType {
  CashBalance = "CASH_BALANCE",
  Fee = "FEE",
  Loan = "LOAN",
}

export type LiveSecurityHolding = {
  __typename?: "LiveSecurityHolding";
  avgCostBasis?: Maybe<Scalars["Decimal"]["output"]>;
  clearingAccountId: Scalars["ID"]["output"];
  dividendYtd?: Maybe<Scalars["Decimal"]["output"]>;
  quantity: Scalars["Decimal"]["output"];
  security?: Maybe<Security>;
  securityId: Scalars["ID"]["output"];
  subAccountId: Scalars["ID"]["output"];
  subAccountType: SubAccountType;
};

export type LoanError = {
  __typename?: "LoanError";
  errorCode?: Maybe<Scalars["String"]["output"]>;
  message?: Maybe<Scalars["String"]["output"]>;
};

export type LoanEvent = {
  __typename?: "LoanEvent";
  acatsTransferId?: Maybe<Scalars["ID"]["output"]>;
  amount: Scalars["Decimal"]["output"];
  clearingAccountId: Scalars["ID"]["output"];
  eventTime: Scalars["DateTime"]["output"];
  id: Scalars["ID"]["output"];
  type: LoanType;
};

export type LoanResponse = {
  __typename?: "LoanResponse";
  errors?: Maybe<Array<Maybe<LoanError>>>;
  loanId?: Maybe<Scalars["ID"]["output"]>;
};

export enum LoanType {
  AcatsOutFee = "ACATS_OUT_FEE",
  AcatsTransferredLoan = "ACATS_TRANSFERRED_LOAN",
  AdrFee = "ADR_FEE",
  Cancellation = "CANCELLATION",
  CashTransferReturnFee = "CASH_TRANSFER_RETURN_FEE",
  InterestFeePayment = "INTEREST_FEE_PAYMENT",
  LoanRepayment = "LOAN_REPAYMENT",
  UserInitiated = "USER_INITIATED",
  WireWithdrawalFee = "WIRE_WITHDRAWAL_FEE",
}

export type LongTermMarginInterestForecast = {
  __typename?: "LongTermMarginInterestForecast";
  forecastDate?: Maybe<Scalars["Date"]["output"]>;
  rate?: Maybe<Scalars["Decimal"]["output"]>;
};

export enum LongTermShortTermIndicatorEnumGql {
  Long = "Long",
  Short = "Short",
}

export type LotMatchingInstruction = {
  __typename?: "LotMatchingInstruction";
  cost?: Maybe<Scalars["Decimal"]["output"]>;
  longTermShortTermIndicator?: Maybe<LongTermShortTermIndicatorEnumGql>;
  price?: Maybe<Scalars["Decimal"]["output"]>;
  quantity: Scalars["Decimal"]["output"];
  realizedGainLoss?: Maybe<Scalars["Decimal"]["output"]>;
  taxLotEntryId: Scalars["ID"]["output"];
  tradeDate?: Maybe<Scalars["Date"]["output"]>;
};

export type LotMatchingInstructionInput = {
  quantity: Scalars["Decimal"]["input"];
  taxLotEntryId: Scalars["ID"]["input"];
  tradeDate?: InputMaybe<Scalars["Date"]["input"]>;
};

export type LotsToTransferBySymbolInput = {
  lotsToTransferIds: Array<Scalars["String"]["input"]>;
  securityId: Scalars["ID"]["input"];
  symbol: Scalars["String"]["input"];
};

export enum MmfDividendScheduleFrequency {
  Annually = "Annually",
  Daily = "Daily",
  Monthly = "Monthly",
  Unspecified = "Unspecified",
  Weekly = "Weekly",
}

export type MmfMetadata = {
  __typename?: "MMFMetadata";
  cusip: Scalars["String"]["output"];
  dividendSchedule: MmfDividendScheduleFrequency;
  expenseRatio: Scalars["Float"]["output"];
  inceptionDate: Scalars["DateTime"]["output"];
  lastUpdated?: Maybe<Scalars["DateTime"]["output"]>;
  link: Scalars["String"]["output"];
  minInvestment: Scalars["Int"]["output"];
  name: Scalars["String"]["output"];
  nav: Scalars["Float"]["output"];
  netAssets: Scalars["Float"]["output"];
  secYield7Day: Scalars["Float"]["output"];
  symbol: Scalars["String"]["output"];
  ytdYield: Scalars["Float"]["output"];
};

export type MmfMetadataInput = {
  symbols?: InputMaybe<Array<Scalars["String"]["input"]>>;
};

export enum ManualReviewStatus {
  Fail = "FAIL",
  Pass = "PASS",
  Retry = "RETRY",
}

export enum MarginAccountState {
  GoodStanding = "GOOD_STANDING",
  MarginCall = "MARGIN_CALL",
  RebalanceCall = "REBALANCE_CALL",
}

export type MarginCallData = {
  __typename?: "MarginCallData";
  clearingAccountId: Scalars["String"]["output"];
  dueDate: Scalars["DateTime"]["output"];
  eventTime: Scalars["DateTime"]["output"];
  id: Scalars["String"]["output"];
  statuses: Array<MarginCallDataStatus>;
};

export type MarginCallDataStatus = {
  __typename?: "MarginCallDataStatus";
  amount: Scalars["Decimal"]["output"];
  eventTime: Scalars["DateTime"]["output"];
  id: Scalars["String"]["output"];
  marginCallId: Scalars["String"]["output"];
  state: MarginCallState;
};

export enum MarginCallState {
  CallCleared = "CALL_CLEARED",
  CallIssued = "CALL_ISSUED",
}

export type MarginInterestCharge = {
  __typename?: "MarginInterestCharge";
  amount: Scalars["Decimal"]["output"];
  billedTo: MarginInterestFeeBilledTo;
  billingEndDate: Scalars["DateTime"]["output"];
  billingStartDate: Scalars["DateTime"]["output"];
  eventTime: Scalars["DateTime"]["output"];
  id: Scalars["ID"]["output"];
};

export type MarginInterestChargeInput = {
  clearingAccountId: Scalars["ID"]["input"];
  endDate?: InputMaybe<Scalars["Date"]["input"]>;
  startDate?: InputMaybe<Scalars["Date"]["input"]>;
};

export enum MarginInterestFeeBilledTo {
  FrecCash = "FREC_CASH",
  Loan = "LOAN",
}

export enum MarginInterestFeeStatus {
  Canceled = "CANCELED",
  Completed = "COMPLETED",
  FundsPosted = "FUNDS_POSTED",
  Initiated = "INITIATED",
  Invalidated = "INVALIDATED",
  Pending = "PENDING",
  Rejected = "REJECTED",
  Returned = "RETURNED",
}

export type MarginInterestForecast = {
  __typename?: "MarginInterestForecast";
  fromDate?: Maybe<Scalars["Date"]["output"]>;
  rate?: Maybe<Scalars["Decimal"]["output"]>;
};

export type MarginInterestForecastResponse = {
  __typename?: "MarginInterestForecastResponse";
  fomcMeetingNotesUrl?: Maybe<Scalars["String"]["output"]>;
  forecasts: Array<MarginInterestForecast>;
  longTermForecast: LongTermMarginInterestForecast;
};

export type MarginInterestResponse = {
  __typename?: "MarginInterestResponse";
  effectiveDate: Scalars["DateTime"]["output"];
  id: Scalars["String"]["output"];
  note?: Maybe<Scalars["String"]["output"]>;
  value: Scalars["Decimal"]["output"];
};

export type MarginRequirement = {
  __typename?: "MarginRequirement";
  maintenanceRequirement: Scalars["Decimal"]["output"];
  symbol: Scalars["String"]["output"];
};

export enum MaritalStatus {
  Divorced = "DIVORCED",
  Married = "MARRIED",
  Single = "SINGLE",
  Widowed = "WIDOWED",
}

export type MarketDataSimulationArgs = {
  query: Scalars["String"]["input"];
  response: Scalars["String"]["input"];
  simulationId: Scalars["Int"]["input"];
  symbol?: InputMaybe<Scalars["String"]["input"]>;
};

export type MarketDataSimulationEntry = {
  __typename?: "MarketDataSimulationEntry";
  id: Scalars["String"]["output"];
  query: Scalars["String"]["output"];
  response: Scalars["String"]["output"];
  simulationId: Scalars["Int"]["output"];
  symbol?: Maybe<Scalars["String"]["output"]>;
};

export type MarketHours = {
  __typename?: "MarketHours";
  closingHour: Scalars["DateTime"]["output"];
  isTheStockMarketOpen: Scalars["Boolean"]["output"];
  openingHour: Scalars["DateTime"]["output"];
  previousMarketClose: Scalars["DateTime"]["output"];
  previousMarketOpen: Scalars["DateTime"]["output"];
  stockExchangeName: Scalars["String"]["output"];
};

export type MfaChallengeResponse = {
  __typename?: "MfaChallengeResponse";
  status?: Maybe<Scalars["Boolean"]["output"]>;
};

export enum MfaType {
  App = "APP",
  BackupCode = "BACKUP_CODE",
  DeviceToken = "DEVICE_TOKEN",
  Phone = "PHONE",
}

export enum MoneyMovementSourceType {
  Ach = "ACH",
  Check = "CHECK",
  DirectIndex = "DIRECT_INDEX",
  FrecCash = "FREC_CASH",
  Treasury = "TREASURY",
  Wire = "WIRE",
}

export enum MoneyMovementSourceTypeGroup {
  DepositAccount = "DEPOSIT_ACCOUNT",
  SubAccount = "SUB_ACCOUNT",
}

export type MonitorTradeExecutionStatusInput = {
  executionIds?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  updateOrderStatusOnly?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export enum MultiIndexConflictType {
  Buy = "BUY",
  BuySell = "BUY_SELL",
  None = "NONE",
}

export enum MultiIndexResolutionType {
  LastRun = "LAST_RUN",
}

export enum MultiIndexRunType {
  V1 = "V1",
  V2 = "V2",
  /** @deprecated Field no longer supported */
  V3 = "V3",
  V4 = "V4",
}

export type MultiIndexSimulateInput = {
  date?: InputMaybe<Scalars["DateTime"]["input"]>;
  resolutionType: MultiIndexResolutionType;
  simulationRunIds: Array<Scalars["ID"]["input"]>;
};

export type Mutation = {
  __typename?: "Mutation";
  acatsCreateTransfer: AcatsCreateTransferResponse;
  addManualAccountNumberToPlaidAccount?: Maybe<PlaidAccount>;
  addTreasuryConfig: TreasuryConfig;
  addUserAddress?: Maybe<GenericAddress>;
  addUserEmploymentDetails?: Maybe<UserEmploymentDetails>;
  addUserMfaPreference: UserMfaPreferenceWithQrCode;
  addUserPersonalDetails?: Maybe<UserPersonalDetails>;
  addUserTrustedContact?: Maybe<UserTrustedContact>;
  adminBackfillUserFields: Scalars["Boolean"]["output"];
  applyInviteCode: Scalars["Boolean"]["output"];
  /** @deprecated use cancelCashTransfer instead */
  cancelAchDeposit: CancelAchDepositResponse;
  /** @deprecated use cancelCashTransfer instead */
  cancelAchWithdrawal: CancelAchWithdrawalResponse;
  cancelCashTransfer: CancelCashTransferResponse;
  cancelDirectIndexSimulation: Scalars["Boolean"]["output"];
  cancelLiquidateDirectIndexPortfolio: LiquidateDirectIndexPortfolioRequest;
  cancelPayment: Scalars["Boolean"]["output"];
  cancelPendingLoan: PendingLoan;
  cancelTradeOrder: CancelOrderResponse;
  /** @deprecated use cancelCashTransfer instead */
  cancelWireWithdrawal: CancelWireWithdrawalResponse;
  cashTransferToDirectIndexPortfolio: Scalars["Boolean"]["output"];
  createClearingHouseAccountForIndividual: ClearingAccount;
  createClearingHouseAccountForTrust: ClearingAccount;
  createDirectIndexSubAccount: ClearingAccount;
  createDividendPreference: DividendPreference;
  createLinkedBrokerageAccount?: Maybe<Scalars["Boolean"]["output"]>;
  createLinkedBrokerageAccountFromPlaidAccount?: Maybe<
    Scalars["Boolean"]["output"]
  >;
  createPaymentConfig: PaymentConfig;
  createPendingLoan: PendingLoan;
  createScheduledDepositConfig: ScheduledDepositConfig;
  deleteBusinessEntityIndividual: Scalars["Boolean"]["output"];
  deleteDepositAccount?: Maybe<DepositAccount>;
  deleteDirectIndexSimulation: Scalars["Boolean"]["output"];
  deleteDocument: Scalars["Boolean"]["output"];
  deleteLinkedBrokerageAccount?: Maybe<Scalars["Boolean"]["output"]>;
  deletePaymentConfig: Scalars["Boolean"]["output"];
  deletePlaidAccount?: Maybe<Scalars["Boolean"]["output"]>;
  deletePlaidConnectionById?: Maybe<Scalars["Boolean"]["output"]>;
  deleteScheduledDepositConfig: Scalars["Boolean"]["output"];
  deleteUserTrustedContact: Scalars["Boolean"]["output"];
  disableUserMfaPreference: Scalars["Boolean"]["output"];
  /** @deprecated Debug only */
  expirePlaidAccount?: Maybe<PlaidAccount>;
  generateUserCode: GenerateUserCodeResponse;
  handleEmailCapture: Scalars["Boolean"]["output"];
  /** @deprecated use initiateCashTransfer instead */
  initiateAchDeposit: InitiateAchDepositResponse;
  /** @deprecated use initiateCashTransfer instead */
  initiateAchWithdrawal: InitiateAchWithdrawalResponse;
  initiateCashTransfer: InitiateCashTransferResponse;
  initiateLoan: LoanResponse;
  initiateTreasuryTransfer: TreasuryExecution;
  /** @deprecated use initiateCashTransfer instead */
  initiateWireDeposit: InitiateWireDepositResponse;
  /** @deprecated use initiateCashTransfer instead */
  initiateWireWithdrawal: InitiateWireWithdrawalResponse;
  intervalAssessTreasuryFees: Scalars["Int"]["output"];
  intervalBackupSodFiles: BackupSodFilesResponse;
  intervalBuildAndPersistComparisonPortfolioSnapshots?: Maybe<
    Array<Maybe<Scalars["String"]["output"]>>
  >;
  intervalBuildAndPersistPortfolioSnapshots?: Maybe<
    Array<Maybe<Scalars["String"]["output"]>>
  >;
  intervalBuildAndPersistPortfolioSnapshotsForAllActive?: Maybe<
    Array<Maybe<Scalars["String"]["output"]>>
  >;
  intervalCacheBetaValues: Scalars["Boolean"]["output"];
  intervalComputeMarginInterestForAll: Array<AggregatedMarginInterestFee>;
  intervalExecuteBatchUpdateUsers: Scalars["Boolean"]["output"];
  intervalExecuteDirectIndexDepositConfigs: Scalars["Int"]["output"];
  intervalExecutePaymentConfigs: Scalars["Int"]["output"];
  intervalExecuteQueuedCashTransfers: Scalars["Boolean"]["output"];
  intervalExecuteQueuedOrders: Scalars["Int"]["output"];
  intervalExecuteTreasuryConfigs: Scalars["Int"]["output"];
  intervalExecuteTreasuryExecutions: Scalars["Int"]["output"];
  intervalGenerateAutoTLHCandidates: Array<GenerateAutoTlhCandidatesResponse>;
  intervalGenerateBorrowingPowerSnapshots: Scalars["Int"]["output"];
  intervalGenerateDirectIndexingCandidates: Array<GenerateCandidatesResponse>;
  intervalGenerateReport: Array<GenerateReportResponse>;
  intervalMonitorDirectIndexTradeExecutionStatus: Scalars["Boolean"]["output"];
  intervalPopulateSecurityIndexCache: Scalars["Boolean"]["output"];
  intervalPostProcessSodFiles: Array<Maybe<PostProcessSodFilesResponse>>;
  intervalProcessDelayedEvents: Scalars["Boolean"]["output"];
  intervalProcessMarginCalls: ProcessMarginCallResponse;
  intervalProcessPendingLoans: Array<PendingLoan>;
  intervalProcessSodFiles: Array<ProcessSodResponse>;
  intervalQueueApprovedDirectIndexTradeExecution: Scalars["Int"]["output"];
  intervalRefreshActiveSymbols: Array<Security>;
  intervalRefreshStatuses: Scalars["Boolean"]["output"];
  intervalReprocessFailedStatuses: Scalars["Boolean"]["output"];
  intervalRunActiveJobs: Array<RunJobsResponse>;
  intervalRunJob: RunJobResponse;
  intervalValidateSodFiles: Array<ProcessSodResponse>;
  intraAccountStockTransferFromDI: Scalars["Boolean"]["output"];
  intraAccountStockTransferToDI: Scalars["Boolean"]["output"];
  keyValueDelete: Scalars["Boolean"]["output"];
  keyValueSet: Array<KeyValuePair>;
  linkPlaid: Array<PlaidAccount>;
  linkPlaidDepositAccountToClearingAccount: DepositAccount;
  markUserNotificationAsRead: Scalars["Boolean"]["output"];
  multiIndexSimulate: Scalars["Boolean"]["output"];
  placeBatchTradeOrder: PlaceBatchTradeOrderResponse;
  plaidCreateLinkToken: PlaidLinkToken;
  plaidVerifyMicrodepositLinkToken: PlaidLinkToken;
  refreshRuleEngineConfigs?: Maybe<Array<RuleEngineConfig>>;
  repayLoan: LoanResponse;
  requestClearingHouseAccountClose: UpdateClearingHouseAccountStateResponse;
  requestMfaPhoneChallenge: RequestMfaPhoneChallengeResponse;
  requestPasswordReset: Scalars["Boolean"]["output"];
  requestVerificationCode: RequestVerificationCodeResponse;
  revokeOidcConnection?: Maybe<Scalars["Boolean"]["output"]>;
  sendEmailNotification?: Maybe<Scalars["Boolean"]["output"]>;
  sendInviteViaEmail: Scalars["Boolean"]["output"];
  setDirectIndexSecurityOverrides: DirectIndexAccountWeights;
  setProgressFlag?: Maybe<Array<Maybe<UserProgressFlag>>>;
  setSelfManagedTaxLossConfig: SelfManagedTaxLossConfig;
  setSelfManagedTaxLossSecurities: SelfManagedTaxLossConfig;
  setUserSource?: Maybe<UserSource>;
  simulationAcatsTransferOpsAction: Scalars["Boolean"]["output"];
  simulationAddSimulationEvent: Scalars["Boolean"]["output"];
  simulationAddSimulationResponses: Scalars["Int"]["output"];
  simulationAddTestClearingAccount?: Maybe<Scalars["String"]["output"]>;
  simulationAddTestUser?: Maybe<Scalars["String"]["output"]>;
  simulationDeleteSimulationResponses: Scalars["Int"]["output"];
  simulationDeleteSimulationResponsesForSimulationId: Scalars["Int"]["output"];
  simulationDeleteTestClearingAccount: Scalars["Boolean"]["output"];
  simulationDeleteTestUser: Scalars["Boolean"]["output"];
  simulationDirectIndexExecution: Scalars["Boolean"]["output"];
  submitVerificationCode: Scalars["Boolean"]["output"];
  updateDividendPreference: DividendPreference;
  updateEntityAccountRequestStatus: User;
  updatePaymentConfig: PaymentConfig;
  updateScheduledDepositConfig: ScheduledDepositConfig;
  updateUserAddress?: Maybe<GenericAddress>;
  updateUserEmploymentDetails?: Maybe<UserEmploymentDetails>;
  updateUserPersonalDetails?: Maybe<UserPersonalDetails>;
  updateUserProfile?: Maybe<UpdateUserProfileResponse>;
  updateUserTrustedContact?: Maybe<UserTrustedContact>;
  uploadDocument: UploadedDocument;
  upsertBusinessEntity: BusinessEntity;
  upsertBusinessEntityIndividual: Array<BusinessEntityIndividual>;
  upsertBusinessEntityInvestmentProfile: BusinessEntityInvestmentProfile;
  upsertDirectIndexCustomization: DirectIndexCustomization;
  upsertTrustEntity: TrustEntity;
  upsertTrustEntityIndividual: Array<Trustee>;
  upsertUserAddress?: Maybe<GenericAddress>;
  upsertUserDisclosures?: Maybe<UserDisclosures>;
  upsertUserIdentityDetails?: Maybe<UserIdentityDetails>;
  upsertUserInvestmentProfile?: Maybe<UserInvestmentProfile>;
  upsertUserPersonalDetails?: Maybe<UserPersonalDetails>;
  upsertUserServiceProfile?: Maybe<UserServiceProfile>;
  upsertUserTaxRates?: Maybe<UserTaxRates>;
  validateMfaChallenge: ValidateMfaPhoneChallengeResponse;
  validateVerificationCode: Scalars["Boolean"]["output"];
};

export type MutationAcatsCreateTransferArgs = {
  input: AcatsCreateTransferInput;
  simulatorConfig?: InputMaybe<ApexAcatsSimulatorConfig>;
};

export type MutationAddManualAccountNumberToPlaidAccountArgs = {
  checkPaymentsAuthorized?: InputMaybe<Scalars["Boolean"]["input"]>;
  manualAccountNumber: Scalars["String"]["input"];
  plaidAccountId: Scalars["ID"]["input"];
};

export type MutationAddTreasuryConfigArgs = {
  input: TreasuryConfigInput;
};

export type MutationAddUserAddressArgs = {
  userAddressInput: GenericAddressInput;
};

export type MutationAddUserEmploymentDetailsArgs = {
  userEmploymentDetailsInput: UserEmploymentDetailsInput;
};

export type MutationAddUserMfaPreferenceArgs = {
  userMfaPreferenceInput: UserMfaPreferenceInput;
};

export type MutationAddUserPersonalDetailsArgs = {
  userPersonalDetailsInput: UserPersonalDetailsInput;
};

export type MutationAddUserTrustedContactArgs = {
  userTrustedContactInput: UserTrustedContactInput;
};

export type MutationAdminBackfillUserFieldsArgs = {
  input: BackfillIterableUserFieldsInput;
};

export type MutationApplyInviteCodeArgs = {
  inviteCode: Scalars["String"]["input"];
};

export type MutationCancelAchDepositArgs = {
  cancelAchDepositInput: CancelAchDepositInput;
};

export type MutationCancelAchWithdrawalArgs = {
  cancelAchWithdrawalInput: CancelAchWithdrawalInput;
};

export type MutationCancelCashTransferArgs = {
  input: CancelCashTransferInput;
};

export type MutationCancelDirectIndexSimulationArgs = {
  id: Scalars["ID"]["input"];
};

export type MutationCancelLiquidateDirectIndexPortfolioArgs = {
  input: CancelLiquidateDirectIndexPortfolioInput;
};

export type MutationCancelPaymentArgs = {
  id: Scalars["ID"]["input"];
};

export type MutationCancelPendingLoanArgs = {
  id: Scalars["ID"]["input"];
};

export type MutationCancelTradeOrderArgs = {
  cancelOrderInput: CancelOrderInput;
};

export type MutationCancelWireWithdrawalArgs = {
  cancelWireWithdrawalInput: CancelWireWithdrawalInput;
};

export type MutationCashTransferToDirectIndexPortfolioArgs = {
  input: CashTransferToDirectIndexPortfolioInput;
};

export type MutationCreateClearingHouseAccountForIndividualArgs = {
  userId: Scalars["ID"]["input"];
};

export type MutationCreateClearingHouseAccountForTrustArgs = {
  userId: Scalars["ID"]["input"];
};

export type MutationCreateDirectIndexSubAccountArgs = {
  input: CreateDirectIndexSubAccountInput;
};

export type MutationCreateDividendPreferenceArgs = {
  input: DividendPreferenceInput;
};

export type MutationCreateLinkedBrokerageAccountArgs = {
  input: CreateLinkedBrokerageAccountInput;
};

export type MutationCreateLinkedBrokerageAccountFromPlaidAccountArgs = {
  accountNumber: Scalars["String"]["input"];
  plaidAccountId: Scalars["ID"]["input"];
};

export type MutationCreatePaymentConfigArgs = {
  clearingAccountId: Scalars["ID"]["input"];
  input: PaymentConfigInput;
};

export type MutationCreatePendingLoanArgs = {
  input: CreatePendingLoanInput;
};

export type MutationCreateScheduledDepositConfigArgs = {
  clearingAccountId: Scalars["ID"]["input"];
  input: ScheduledDepositConfigInput;
  subAccountId: Scalars["ID"]["input"];
};

export type MutationDeleteBusinessEntityIndividualArgs = {
  input: DeleteBusinessEntityIndividualInput;
};

export type MutationDeleteDepositAccountArgs = {
  depositAccountId: Scalars["ID"]["input"];
};

export type MutationDeleteDirectIndexSimulationArgs = {
  id: Scalars["ID"]["input"];
};

export type MutationDeleteDocumentArgs = {
  id: Scalars["ID"]["input"];
};

export type MutationDeleteLinkedBrokerageAccountArgs = {
  linkedBrokerageAccountId: Scalars["ID"]["input"];
};

export type MutationDeletePaymentConfigArgs = {
  id: Scalars["ID"]["input"];
};

export type MutationDeletePlaidAccountArgs = {
  plaidAccountId: Scalars["ID"]["input"];
};

export type MutationDeletePlaidConnectionByIdArgs = {
  id: Scalars["ID"]["input"];
};

export type MutationDeleteScheduledDepositConfigArgs = {
  id: Scalars["ID"]["input"];
};

export type MutationDeleteUserTrustedContactArgs = {
  userTrustedContactId: Scalars["ID"]["input"];
};

export type MutationDisableUserMfaPreferenceArgs = {
  preferenceId: Scalars["ID"]["input"];
};

export type MutationExpirePlaidAccountArgs = {
  id: Scalars["ID"]["input"];
};

export type MutationGenerateUserCodeArgs = {
  input: GenerateUserCodeInput;
};

export type MutationHandleEmailCaptureArgs = {
  input: EmailCaptureInput;
};

export type MutationInitiateAchDepositArgs = {
  initiateAchDepositInput: InitiateAchDepositInput;
};

export type MutationInitiateAchWithdrawalArgs = {
  initiateAchWithdrawalInput: InitiateAchWithdrawalInput;
};

export type MutationInitiateCashTransferArgs = {
  input: InitiateCashTransferInput;
};

export type MutationInitiateLoanArgs = {
  input: InitiateLoanInput;
};

export type MutationInitiateTreasuryTransferArgs = {
  input: TreasuryTransferInput;
};

export type MutationInitiateWireDepositArgs = {
  initiateWireDepositInput: InitiateWireDepositInput;
};

export type MutationInitiateWireWithdrawalArgs = {
  initiateWireWithdrawalInput: InitiateWireWithdrawalInput;
};

export type MutationIntervalBackupSodFilesArgs = {
  input: BackupSodFilesInput;
};

export type MutationIntervalBuildAndPersistComparisonPortfolioSnapshotsArgs = {
  date: Scalars["Date"]["input"];
  dryRun?: InputMaybe<Scalars["Boolean"]["input"]>;
  subAccountIds?: InputMaybe<Array<Scalars["String"]["input"]>>;
};

export type MutationIntervalBuildAndPersistPortfolioSnapshotsArgs = {
  date: Scalars["Date"]["input"];
  subAccountIds: Array<Scalars["String"]["input"]>;
};

export type MutationIntervalBuildAndPersistPortfolioSnapshotsForAllActiveArgs =
  {
    input: IntervalBuildAndPersistPortfolioSnapshotsForAllActiveArgs;
  };

export type MutationIntervalCacheBetaValuesArgs = {
  input: CacheBetaValueInput;
};

export type MutationIntervalComputeMarginInterestForAllArgs = {
  billingPeriodStr?: InputMaybe<Scalars["String"]["input"]>;
};

export type MutationIntervalExecuteQueuedCashTransfersArgs = {
  input?: InputMaybe<IntervalExecuteQueuedCashTransfersInput>;
};

export type MutationIntervalGenerateAutoTlhCandidatesArgs = {
  input: GenerateTlhCandidatesInput;
};

export type MutationIntervalGenerateBorrowingPowerSnapshotsArgs = {
  date: Scalars["Date"]["input"];
};

export type MutationIntervalGenerateDirectIndexingCandidatesArgs = {
  input: GenerateCandidatesInput;
};

export type MutationIntervalGenerateReportArgs = {
  input: GenerateReportInput;
};

export type MutationIntervalMonitorDirectIndexTradeExecutionStatusArgs = {
  input: MonitorTradeExecutionStatusInput;
};

export type MutationIntervalPopulateSecurityIndexCacheArgs = {
  input: IntervalPopulateSecurityIndexCacheInput;
};

export type MutationIntervalPostProcessSodFilesArgs = {
  input: PostProcessSodFilesInput;
};

export type MutationIntervalProcessDelayedEventsArgs = {
  eventType: EventType;
};

export type MutationIntervalProcessMarginCallsArgs = {
  date: Scalars["Date"]["input"];
};

export type MutationIntervalProcessSodFilesArgs = {
  files: Array<ExtractNumber>;
  input: ProcessSodFilesInputArgs;
};

export type MutationIntervalQueueApprovedDirectIndexTradeExecutionArgs = {
  input: QueueApprovedDirectIndexTradeExecutionInput;
};

export type MutationIntervalRefreshStatusesArgs = {
  eventType: EventType;
};

export type MutationIntervalReprocessFailedStatusesArgs = {
  eventType: EventType;
};

export type MutationIntervalRunJobArgs = {
  input: RunJobInput;
};

export type MutationIntervalValidateSodFilesArgs = {
  files: Array<ExtractNumber>;
  input: ValidateSodFilesInputArgs;
};

export type MutationIntraAccountStockTransferFromDiArgs = {
  input: TransferStockFromDiInput;
};

export type MutationIntraAccountStockTransferToDiArgs = {
  input: TransferStockSubAccountInput;
};

export type MutationKeyValueDeleteArgs = {
  input: KeyValueDeleteInput;
};

export type MutationKeyValueSetArgs = {
  input: KeyValueSetInput;
};

export type MutationLinkPlaidArgs = {
  input: LinkPlaidInput;
};

export type MutationLinkPlaidDepositAccountToClearingAccountArgs = {
  clearingAccountId: Scalars["ID"]["input"];
  plaidAccountId: Scalars["ID"]["input"];
};

export type MutationMarkUserNotificationAsReadArgs = {
  notificationId: Scalars["ID"]["input"];
};

export type MutationMultiIndexSimulateArgs = {
  input: MultiIndexSimulateInput;
};

export type MutationPlaceBatchTradeOrderArgs = {
  input: PlaceBatchTradeOrderInput;
};

export type MutationPlaidCreateLinkTokenArgs = {
  type: PlaidAccountType;
  updateAccountId?: InputMaybe<Scalars["String"]["input"]>;
};

export type MutationPlaidVerifyMicrodepositLinkTokenArgs = {
  plaidAccountId: Scalars["String"]["input"];
  type: PlaidAccountType;
};

export type MutationRepayLoanArgs = {
  input: RepayLoanInput;
};

export type MutationRequestClearingHouseAccountCloseArgs = {
  accountId: Scalars["ID"]["input"];
  undo?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type MutationRequestMfaPhoneChallengeArgs = {
  input: RequestMfaPhoneChallengeInput;
};

export type MutationRequestPasswordResetArgs = {
  email: Scalars["String"]["input"];
};

export type MutationRequestVerificationCodeArgs = {
  channel: PhoneVerificationChannel;
  phone: Scalars["String"]["input"];
};

export type MutationRevokeOidcConnectionArgs = {
  input: RevokeOidcConnectionInput;
};

export type MutationSendEmailNotificationArgs = {
  clearingAccountId?: InputMaybe<Scalars["ID"]["input"]>;
  event: NotificationEvent;
};

export type MutationSendInviteViaEmailArgs = {
  email: Scalars["String"]["input"];
  name?: InputMaybe<Scalars["String"]["input"]>;
};

export type MutationSetDirectIndexSecurityOverridesArgs = {
  input: DirectIndexOverrideInput;
};

export type MutationSetProgressFlagArgs = {
  input: SetProgressFlagInput;
};

export type MutationSetSelfManagedTaxLossConfigArgs = {
  input: SelfManagedTaxLossConfigInput;
};

export type MutationSetSelfManagedTaxLossSecuritiesArgs = {
  input: SelfManagedTaxLossSecuritiesInput;
};

export type MutationSetUserSourceArgs = {
  input: UserSourceInput;
};

export type MutationSimulationAcatsTransferOpsActionArgs = {
  input: OpsAcatsTransferInput;
};

export type MutationSimulationAddSimulationEventArgs = {
  event: ApexSimulationEventInput;
};

export type MutationSimulationAddSimulationResponsesArgs = {
  input: Array<MarketDataSimulationArgs>;
};

export type MutationSimulationAddTestClearingAccountArgs = {
  data: ClearingAccountQaDataInput;
};

export type MutationSimulationAddTestUserArgs = {
  data: UserQaDataInput;
};

export type MutationSimulationDeleteSimulationResponsesArgs = {
  responseIds: Array<Scalars["String"]["input"]>;
};

export type MutationSimulationDeleteSimulationResponsesForSimulationIdArgs = {
  simulationId: Scalars["Int"]["input"];
};

export type MutationSimulationDeleteTestClearingAccountArgs = {
  clearingAccountId: Scalars["String"]["input"];
};

export type MutationSimulationDeleteTestUserArgs = {
  userId: Scalars["String"]["input"];
};

export type MutationSimulationDirectIndexExecutionArgs = {
  input: SimulationDirectIndexExecutionInput;
};

export type MutationSubmitVerificationCodeArgs = {
  code: Scalars["String"]["input"];
  phone: Scalars["String"]["input"];
};

export type MutationUpdateDividendPreferenceArgs = {
  id: Scalars["ID"]["input"];
  input: DividendPreferenceInput;
};

export type MutationUpdateEntityAccountRequestStatusArgs = {
  input: UpdateUserEntityAccountRequestStatusInput;
};

export type MutationUpdatePaymentConfigArgs = {
  id: Scalars["ID"]["input"];
  input: PaymentConfigInput;
};

export type MutationUpdateScheduledDepositConfigArgs = {
  id: Scalars["ID"]["input"];
  input: ScheduledDepositConfigInput;
};

export type MutationUpdateUserAddressArgs = {
  userAddressId: Scalars["ID"]["input"];
  userAddressInput: GenericAddressInput;
};

export type MutationUpdateUserEmploymentDetailsArgs = {
  userEmploymentDetailsInput: UserEmploymentDetailsInput;
  userEmploymentId: Scalars["ID"]["input"];
};

export type MutationUpdateUserPersonalDetailsArgs = {
  userPersonalDetailsId: Scalars["ID"]["input"];
  userPersonalDetailsInput: UserPersonalDetailsInput;
};

export type MutationUpdateUserProfileArgs = {
  clearingAccountId: Scalars["ID"]["input"];
  input: UpdateUserProfileInput;
};

export type MutationUpdateUserTrustedContactArgs = {
  userTrustedContactId: Scalars["ID"]["input"];
  userTrustedContactInput: UserTrustedContactInput;
};

export type MutationUploadDocumentArgs = {
  input: UploadDocumentInput;
};

export type MutationUpsertBusinessEntityArgs = {
  input: UpsertBusinessEntityInput;
};

export type MutationUpsertBusinessEntityIndividualArgs = {
  input: Array<UpsertBusinessEntityIndividualInput>;
};

export type MutationUpsertBusinessEntityInvestmentProfileArgs = {
  input: UpsertBusinessEntityInvestmentProfileInput;
};

export type MutationUpsertDirectIndexCustomizationArgs = {
  input: DirectIndexCustomizationInput;
};

export type MutationUpsertTrustEntityArgs = {
  input: UpsertTrustEntityInput;
};

export type MutationUpsertTrustEntityIndividualArgs = {
  input: UpsertTrustEntityIndividualInput;
};

export type MutationUpsertUserAddressArgs = {
  userAddressInput: GenericAddressInput;
};

export type MutationUpsertUserDisclosuresArgs = {
  userDisclosuresInput: UserDisclosuresInput;
};

export type MutationUpsertUserIdentityDetailsArgs = {
  userIdentityDetailsInput: UserIdentityDetailsInput;
};

export type MutationUpsertUserInvestmentProfileArgs = {
  userInvestmentProfileInput: UserInvestmentProfileInput;
};

export type MutationUpsertUserPersonalDetailsArgs = {
  userPersonalDetailsInput: UserPersonalDetailsInput;
};

export type MutationUpsertUserServiceProfileArgs = {
  userServiceProfileInput: UserServiceProfileInput;
};

export type MutationUpsertUserTaxRatesArgs = {
  userTaxRatesInput: UserTaxRatesInput;
};

export type MutationValidateMfaChallengeArgs = {
  input: ValidateMfaPhoneChallengeInput;
};

export type MutationValidateVerificationCodeArgs = {
  input: VerificationCodeInput;
};

export enum NotificationCategory {
  AcatsTransfer = "ACATS_TRANSFER",
  AccountManagement = "ACCOUNT_MANAGEMENT",
  CashTransfer = "CASH_TRANSFER",
  DirectIndex = "DIRECT_INDEX",
  Disclosure = "DISCLOSURE",
  EntityAccountRequest = "ENTITY_ACCOUNT_REQUEST",
  Invitation = "INVITATION",
  Loan = "LOAN",
  MarginCall = "MARGIN_CALL",
  MarginInterest = "MARGIN_INTEREST",
  Order = "ORDER",
  Payment = "PAYMENT",
  Referral = "REFERRAL",
  Treasury = "TREASURY",
}

export enum NotificationChannel {
  Email = "EMAIL",
  InApp = "IN_APP",
  Sms = "SMS",
}

export type NotificationCta = {
  __typename?: "NotificationCta";
  id?: Maybe<Scalars["String"]["output"]>;
  link?: Maybe<Scalars["String"]["output"]>;
};

export enum NotificationEvent {
  AcatsTransferFailure = "ACATS_TRANSFER_FAILURE",
  AcatsTransferOutFullRequestReceived = "ACATS_TRANSFER_OUT_FULL_REQUEST_RECEIVED",
  AcatsTransferOutPartialRequestReceived = "ACATS_TRANSFER_OUT_PARTIAL_REQUEST_RECEIVED",
  AcatsTransferRequestSubmitted = "ACATS_TRANSFER_REQUEST_SUBMITTED",
  AcatsTransferRequestSubmittedFirstTime = "ACATS_TRANSFER_REQUEST_SUBMITTED_FIRST_TIME",
  AcatsTransferReviewApproved = "ACATS_TRANSFER_REVIEW_APPROVED",
  AcatsTransferSuccess = "ACATS_TRANSFER_SUCCESS",
  AccountPending = "ACCOUNT_PENDING",
  Activation = "ACTIVATION",
  AnnualDisclosure = "ANNUAL_DISCLOSURE",
  CashTransferFailure = "CASH_TRANSFER_FAILURE",
  CashTransferRequestSubmitted = "CASH_TRANSFER_REQUEST_SUBMITTED",
  CashTransferSuccess = "CASH_TRANSFER_SUCCESS",
  DirectIndexPortfolioReady = "DIRECT_INDEX_PORTFOLIO_READY",
  DocumentsNeeded = "DOCUMENTS_NEEDED",
  EmailVerification = "EMAIL_VERIFICATION",
  EntityAccountReady = "ENTITY_ACCOUNT_READY",
  FormCrsUpdate = "FORM_CRS_UPDATE",
  FormCrsUpdateWithTreasury = "FORM_CRS_UPDATE_WITH_TREASURY",
  Invite = "INVITE",
  LineOfCreditAlmostReady = "LINE_OF_CREDIT_ALMOST_READY",
  LineOfCreditReady = "LINE_OF_CREDIT_READY",
  LoanSuccess = "LOAN_SUCCESS",
  LoanSuccessCashTransferFailure = "LOAN_SUCCESS_CASH_TRANSFER_FAILURE",
  LoanSuccessCashTransferInitiated = "LOAN_SUCCESS_CASH_TRANSFER_INITIATED",
  MarginCallReminder = "MARGIN_CALL_REMINDER",
  MarginCallResolved = "MARGIN_CALL_RESOLVED",
  MarginCallWarning = "MARGIN_CALL_WARNING",
  MarginInterestIncrease = "MARGIN_INTEREST_INCREASE",
  OrderCompleted = "ORDER_COMPLETED",
  OrderInitiated = "ORDER_INITIATED",
  PasswordReset = "PASSWORD_RESET",
  PasswordUpdateSuccess = "PASSWORD_UPDATE_SUCCESS",
  PaymentFailed = "PAYMENT_FAILED",
  PaymentLowBalanceAlert = "PAYMENT_LOW_BALANCE_ALERT",
  PaymentOneTimeInitiated = "PAYMENT_ONE_TIME_INITIATED",
  PaymentRelinkCreditCard = "PAYMENT_RELINK_CREDIT_CARD",
  PlaidConnectionApproved = "PLAID_CONNECTION_APPROVED",
  ReferralBonusEarned = "REFERRAL_BONUS_EARNED",
  ReferralCodeUsedEarnedDelayedCashReward = "REFERRAL_CODE_USED_EARNED_DELAYED_CASH_REWARD",
  TreasuryDepositInitiated = "TREASURY_DEPOSIT_INITIATED",
  TreasuryDepositSuccess = "TREASURY_DEPOSIT_SUCCESS",
  TreasuryRelinkBankAccount = "TREASURY_RELINK_BANK_ACCOUNT",
  TreasuryWithdrawalInitiated = "TREASURY_WITHDRAWAL_INITIATED",
  TreasuryYieldLanded = "TREASURY_YIELD_LANDED",
  Welcome = "WELCOME",
}

export type NotificationLiveTemplate = {
  __typename?: "NotificationLiveTemplate";
  category: NotificationCategory;
  channel: NotificationChannel;
  event: NotificationEvent;
  id: Scalars["ID"]["output"];
  version: Scalars["Int"]["output"];
};

export enum NotificationStatus {
  Failure = "FAILURE",
  Queued = "QUEUED",
  Read = "READ",
  Sent = "SENT",
}

export type NotificationTemplate = {
  __typename?: "NotificationTemplate";
  category: NotificationCategory;
  channel: NotificationChannel;
  createdBy?: Maybe<Scalars["String"]["output"]>;
  event: NotificationEvent;
  id: Scalars["ID"]["output"];
  live: Scalars["Boolean"]["output"];
  name: Scalars["String"]["output"];
  subject?: Maybe<Scalars["String"]["output"]>;
  template: Scalars["String"]["output"];
  type: NotificationType;
  version: Scalars["Int"]["output"];
};

export enum NotificationType {
  Marketing = "MARKETING",
  Transactional = "TRANSACTIONAL",
}

export type OidcClientApplicationDetails = {
  __typename?: "OidcClientApplicationDetails";
  aggregator?: Maybe<FdxConnectionAggregator>;
  applicationName?: Maybe<Scalars["String"]["output"]>;
  lastSyncTime?: Maybe<Scalars["DateTime"]["output"]>;
  logoUrl?: Maybe<Scalars["String"]["output"]>;
};

export type OidcClientApplicationDetailsInput = {
  recipientId: Scalars["String"]["input"];
};

export type OidcGrantMetadata = {
  __typename?: "OidcGrantMetadata";
  connectionDetails?: Maybe<OidcClientApplicationDetails>;
  id: Scalars["ID"]["output"];
};

export type OpsAcatsTransferInput = {
  acatsTransferId: Scalars["ID"]["input"];
  eventTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  notes?: InputMaybe<Scalars["String"]["input"]>;
  status: ManualReviewStatus;
};

export type Order = {
  __typename?: "Order";
  adminComments?: Maybe<Array<Scalars["String"]["output"]>>;
  clearingAccount?: Maybe<ClearingAccount>;
  clearingAccountId: Scalars["ID"]["output"];
  eventTime: Scalars["DateTime"]["output"];
  executedTime?: Maybe<Scalars["DateTime"]["output"]>;
  id: Scalars["ID"]["output"];
  limitDuration?: Maybe<Scalars["DateTime"]["output"]>;
  limitPrice?: Maybe<Scalars["Decimal"]["output"]>;
  longTermGain?: Maybe<Scalars["Decimal"]["output"]>;
  longTermLoss?: Maybe<Scalars["Decimal"]["output"]>;
  lotMatchingInstructions?: Maybe<Array<LotMatchingInstruction>>;
  maxSellQuantity?: Maybe<Scalars["Decimal"]["output"]>;
  opsInitiated: Scalars["Boolean"]["output"];
  orderFees: Array<OrderFee>;
  orderPrice?: Maybe<Scalars["Decimal"]["output"]>;
  orderStatus: OrderStatus;
  orderType: OrderType;
  quantity: Scalars["Decimal"]["output"];
  quantityType?: Maybe<QuantityType>;
  security?: Maybe<Security>;
  securityId: Scalars["ID"]["output"];
  shortTermGain?: Maybe<Scalars["Decimal"]["output"]>;
  shortTermLoss?: Maybe<Scalars["Decimal"]["output"]>;
  side: OrderSide;
  source?: Maybe<BatchOrderSource>;
  statusHistory?: Maybe<Array<OrderStatusHistory>>;
  subAccountId: Scalars["ID"]["output"];
  user?: Maybe<User>;
  userId: Scalars["ID"]["output"];
};

export type OrderDetailsInput = {
  clearingAccountId: Scalars["ID"]["input"];
  limitPrice?: InputMaybe<Scalars["Decimal"]["input"]>;
  maxSellQuantity?: InputMaybe<Scalars["Decimal"]["input"]>;
  orderPrice?: InputMaybe<Scalars["Decimal"]["input"]>;
  orderType: OrderType;
  quantity: Scalars["Decimal"]["input"];
  quantityType: QuantityType;
  side: OrderSide;
  symbol: Scalars["String"]["input"];
};

export type OrderFee = {
  __typename?: "OrderFee";
  amount: Scalars["Decimal"]["output"];
  eventTime: Scalars["DateTime"]["output"];
  id: Scalars["ID"]["output"];
  type: OrderFeeType;
};

export enum OrderFeeType {
  MmfTrade = "MMF_TRADE",
  Sec = "SEC",
  Taf = "TAF",
}

export enum OrderPositionType {
  Long = "LONG",
  Short = "SHORT",
}

export enum OrderSide {
  Buy = "BUY",
  Sell = "SELL",
}

export enum OrderStatus {
  AdminInitiated = "ADMIN_INITIATED",
  Canceled = "CANCELED",
  CancelAcknowledged = "CANCEL_ACKNOWLEDGED",
  CancelPending = "CANCEL_PENDING",
  CancelRejected = "CANCEL_REJECTED",
  CancelRequested = "CANCEL_REQUESTED",
  Executed = "EXECUTED",
  Filled = "FILLED",
  Invalidated = "INVALIDATED",
  NotFilled = "NOT_FILLED",
  Pending = "PENDING",
  Queued = "QUEUED",
  Rejected = "REJECTED",
  UserInitiated = "USER_INITIATED",
  UserQueued = "USER_QUEUED",
}

export type OrderStatusHistory = {
  __typename?: "OrderStatusHistory";
  eventTime: Scalars["DateTime"]["output"];
  externalStatus?: Maybe<Scalars["String"]["output"]>;
  externalStatusCode?: Maybe<Scalars["String"]["output"]>;
  externalStatusReason?: Maybe<Scalars["String"]["output"]>;
  fillAvgPrice?: Maybe<Scalars["Decimal"]["output"]>;
  fillNotional?: Maybe<Scalars["Decimal"]["output"]>;
  fillShares?: Maybe<Scalars["Decimal"]["output"]>;
  id: Scalars["String"]["output"];
  orderId: Scalars["String"]["output"];
  orderStatus: OrderStatus;
};

export enum OrderSubmissionMode {
  Default = "DEFAULT",
  ExecuteQueued = "EXECUTE_QUEUED",
  Queue = "QUEUE",
}

export enum OrderType {
  Limit = "LIMIT",
  Market = "MARKET",
}

export type PaginatedAcatsTransferResponse = {
  __typename?: "PaginatedAcatsTransferResponse";
  data?: Maybe<Array<AcatsTransfer>>;
  nextPageToken?: Maybe<Scalars["String"]["output"]>;
};

export type PaginatedActivity = {
  __typename?: "PaginatedActivity";
  data: Activity;
  nextPageToken?: Maybe<Scalars["String"]["output"]>;
};

export type PaginatedCashDividendResponse = {
  __typename?: "PaginatedCashDividendResponse";
  data?: Maybe<Array<CashDividend>>;
  nextPageToken?: Maybe<Scalars["String"]["output"]>;
};

export type PaginatedCashTransferResponse = {
  __typename?: "PaginatedCashTransferResponse";
  data?: Maybe<Array<CashTransfer>>;
  nextPageToken?: Maybe<Scalars["String"]["output"]>;
};

export type PaginatedCompletedInviteStatusEntry = {
  __typename?: "PaginatedCompletedInviteStatusEntry";
  entries: Array<CompletedInviteStatusEntry>;
  nextPageToken?: Maybe<Scalars["String"]["output"]>;
};

export type PaginatedDailyCashDividendResponse = {
  __typename?: "PaginatedDailyCashDividendResponse";
  data?: Maybe<Array<DailyCashDividend>>;
  nextPageToken?: Maybe<Scalars["String"]["output"]>;
};

export type PaginatedLoanEventResponse = {
  __typename?: "PaginatedLoanEventResponse";
  data?: Maybe<Array<LoanEvent>>;
  nextPageToken?: Maybe<Scalars["String"]["output"]>;
};

export type PaginatedMarginInterestChargesResponse = {
  __typename?: "PaginatedMarginInterestChargesResponse";
  data?: Maybe<Array<MarginInterestCharge>>;
  nextPageToken?: Maybe<Scalars["String"]["output"]>;
};

export type PaginatedOrderResponse = {
  __typename?: "PaginatedOrderResponse";
  data?: Maybe<Array<Order>>;
  nextPageToken?: Maybe<Scalars["String"]["output"]>;
};

export type PaginatedPendingInviteStatusEntry = {
  __typename?: "PaginatedPendingInviteStatusEntry";
  entries: Array<PendingInviteStatusEntry>;
  nextPageToken?: Maybe<Scalars["String"]["output"]>;
};

export type PaginatedSimpleActivity = {
  __typename?: "PaginatedSimpleActivity";
  data: Array<SimpleActivity>;
  nextPageToken?: Maybe<Scalars["String"]["output"]>;
};

export type PaginatedStockAdjustmentResponse = {
  __typename?: "PaginatedStockAdjustmentResponse";
  data?: Maybe<Array<StockAdjustmentDetails>>;
  nextPageToken?: Maybe<Scalars["String"]["output"]>;
};

export enum PaymentAmountType {
  FixedAmount = "FIXED_AMOUNT",
  StatementBalance = "STATEMENT_BALANCE",
}

export type PaymentConfig = {
  __typename?: "PaymentConfig";
  amount?: Maybe<Scalars["Decimal"]["output"]>;
  amountType: PaymentAmountType;
  cardName?: Maybe<Scalars["String"]["output"]>;
  dayOfMonth?: Maybe<Scalars["Int"]["output"]>;
  destinationAccount?: Maybe<PlaidAccount>;
  destinationAccountId: Scalars["ID"]["output"];
  destinationType: PaymentDestinationType;
  id: Scalars["ID"]["output"];
  periodType?: Maybe<PaymentPeriodType>;
  sourceSecurity?: Maybe<Security>;
  sourceSecurityId?: Maybe<Scalars["ID"]["output"]>;
  sourceType: PaymentSourceType;
  startAt?: Maybe<Scalars["DateTime"]["output"]>;
  type: PaymentType;
};

export type PaymentConfigInput = {
  amount?: InputMaybe<Scalars["Decimal"]["input"]>;
  amountType: PaymentAmountType;
  dayOfMonth?: InputMaybe<Scalars["Int"]["input"]>;
  destinationAccountId: Scalars["ID"]["input"];
  destinationType: PaymentDestinationType;
  periodType?: InputMaybe<PaymentPeriodType>;
  sourceSecurityId?: InputMaybe<Scalars["ID"]["input"]>;
  sourceType: PaymentSourceType;
  startAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  type: PaymentType;
};

export enum PaymentDestinationType {
  CreditCard = "CREDIT_CARD",
}

export type PaymentExecution = {
  __typename?: "PaymentExecution";
  amount: Scalars["Decimal"]["output"];
  eventTime: Scalars["DateTime"]["output"];
  failureReason?: Maybe<PaymentFailureReason>;
  id: Scalars["ID"]["output"];
  paymentConfig?: Maybe<PaymentConfig>;
  paymentConfigId: Scalars["ID"]["output"];
  status: PaymentExecutionStatus;
};

export enum PaymentExecutionStatus {
  Failure = "FAILURE",
  Initiated = "INITIATED",
  LoanApproved = "LOAN_APPROVED",
  LoanInitiated = "LOAN_INITIATED",
  MmfSaleCompleted = "MMF_SALE_COMPLETED",
  MmfSaleInitiated = "MMF_SALE_INITIATED",
  Terminated = "TERMINATED",
  WithdrawalCompleted = "WITHDRAWAL_COMPLETED",
  WithdrawalInitiated = "WITHDRAWAL_INITIATED",
  WithdrawalRetryableFailure = "WITHDRAWAL_RETRYABLE_FAILURE",
}

export enum PaymentFailureReason {
  AdminCancelled = "ADMIN_CANCELLED",
  LineOfCreditFailure = "LINE_OF_CREDIT_FAILURE",
  MmfFailure = "MMF_FAILURE",
  UserCancelled = "USER_CANCELLED",
  WithdrawalFailure = "WITHDRAWAL_FAILURE",
}

export enum PaymentPeriodType {
  DayOfMonth = "DAY_OF_MONTH",
  StatementDate = "STATEMENT_DATE",
}

export enum PaymentSourceType {
  FrecCash = "FREC_CASH",
  LineOfCredit = "LINE_OF_CREDIT",
  MoneyMarketFund = "MONEY_MARKET_FUND",
  Treasury = "TREASURY",
}

export enum PaymentType {
  PeriodicPayment = "PERIODIC_PAYMENT",
  SinglePayment = "SINGLE_PAYMENT",
}

export type PendingInviteStatusEntry = {
  __typename?: "PendingInviteStatusEntry";
  refereeEmail: Scalars["String"]["output"];
  referredOn: Scalars["DateTime"]["output"];
};

export type PendingInvitesForUserInput = {
  nextPageToken?: InputMaybe<Scalars["String"]["input"]>;
  pageSize?: InputMaybe<Scalars["Int"]["input"]>;
};

export type PendingLoan = {
  __typename?: "PendingLoan";
  amount: Scalars["Decimal"]["output"];
  cashTransferMethod?: Maybe<CashTransferMethod>;
  clearingAccount?: Maybe<ClearingAccount>;
  clearingAccountId: Scalars["ID"]["output"];
  completedLoanId?: Maybe<Scalars["ID"]["output"]>;
  depositAccountId?: Maybe<Scalars["ID"]["output"]>;
  depositAccountTransferId?: Maybe<Scalars["ID"]["output"]>;
  eventTime: Scalars["DateTime"]["output"];
  id: Scalars["ID"]["output"];
  status: PendingLoanStatus;
  userId: Scalars["ID"]["output"];
};

export enum PendingLoanStatus {
  Cancelled = "CANCELLED",
  Complete = "COMPLETE",
  Pending = "PENDING",
  Rejected = "REJECTED",
}

export enum PerformanceDataPeriod {
  All = "ALL",
  M1 = "M1",
  M3 = "M3",
  M6 = "M6",
  Y1 = "Y1",
  Y3 = "Y3",
  Y5 = "Y5",
  Y10 = "Y10",
  Ytd = "YTD",
}

export type PersistEvent = {
  __typename?: "PersistEvent";
  path?: Maybe<Scalars["String"]["output"]>;
  timestamp?: Maybe<Scalars["DateTime"]["output"]>;
  type?: Maybe<PersistEventType>;
};

export enum PersistEventType {
  Db = "DB",
  LocalFs = "LOCAL_FS",
  S3 = "S3",
}

export enum PhoneNumberType {
  Fax = "FAX",
  Home = "HOME",
  Mobile = "MOBILE",
  Other = "OTHER",
  Unspecified = "UNSPECIFIED",
  Work = "WORK",
}

export enum PhoneVerificationChannel {
  Call = "call",
  Sms = "sms",
}

export enum PiiType {
  ApiPayload = "API_PAYLOAD",
  PlaidAccountNumber = "PLAID_ACCOUNT_NUMBER",
  Ssn = "SSN",
  TaxId = "TAX_ID",
}

export type PlaceBatchTradeOrderInput = {
  clearingAccountId: Scalars["ID"]["input"];
  isUserQueuedOrder?: InputMaybe<Scalars["Boolean"]["input"]>;
  orders: Array<BatchOrderSingleInput>;
};

export type PlaceBatchTradeOrderResponse = {
  __typename?: "PlaceBatchTradeOrderResponse";
  batchTradeOrder?: Maybe<BatchTradeOrder>;
  failures: Array<PlaceOrderFailure>;
};

export type PlaceOrderFailure = {
  __typename?: "PlaceOrderFailure";
  message: Scalars["String"]["output"];
};

export type PlaidAccount = {
  __typename?: "PlaidAccount";
  accountNumber?: Maybe<Scalars["String"]["output"]>;
  apexProcessorToken?: Maybe<Scalars["String"]["output"]>;
  connectionId: Scalars["ID"]["output"];
  creditDetails?: Maybe<PlaidAccountCreditDetails>;
  deleted?: Maybe<Scalars["Boolean"]["output"]>;
  /** @deprecated Field no longer supported */
  depositAccountBalance?: Maybe<Scalars["Decimal"]["output"]>;
  error?: Maybe<PlaidAccountError>;
  frecNames?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  hasMissingAccountNumber: Scalars["Boolean"]["output"];
  id: Scalars["ID"]["output"];
  identityInfo?: Maybe<Scalars["String"]["output"]>;
  institutionName: Scalars["String"]["output"];
  logo?: Maybe<Scalars["String"]["output"]>;
  mask?: Maybe<Scalars["String"]["output"]>;
  name: Scalars["String"]["output"];
  nameMatchScore?: Maybe<Scalars["Float"]["output"]>;
  officialName?: Maybe<Scalars["String"]["output"]>;
  owners?: Maybe<Scalars["String"]["output"]>;
  plaidInstitutionId?: Maybe<Scalars["String"]["output"]>;
  portfolio?: Maybe<PlaidAccountPortfolio>;
  routingNumber?: Maybe<Scalars["String"]["output"]>;
  status: PlaidAccountStatus;
  statusHistory: Array<Maybe<PlaidStatusHistory>>;
  subType: PlaidAccountSubType;
  type: PlaidAccountType;
  userId: Scalars["String"]["output"];
  verificationStatus?: Maybe<Scalars["String"]["output"]>;
  wireRoutingNumber?: Maybe<Scalars["String"]["output"]>;
};

export type PlaidAccountAcatsTransfer = {
  __typename?: "PlaidAccountAcatsTransfer";
  status?: Maybe<Scalars["String"]["output"]>;
};

export type PlaidAccountCreditDetails = {
  __typename?: "PlaidAccountCreditDetails";
  accountAvailableBalance?: Maybe<Scalars["Decimal"]["output"]>;
  accountCurrentBalance?: Maybe<Scalars["Decimal"]["output"]>;
  accountLimit?: Maybe<Scalars["Decimal"]["output"]>;
  error?: Maybe<PlaidAccountError>;
  isOverdue?: Maybe<Scalars["Boolean"]["output"]>;
  lastPaymentAmount?: Maybe<Scalars["Decimal"]["output"]>;
  lastPaymentDate?: Maybe<Scalars["String"]["output"]>;
  lastStatementBalance?: Maybe<Scalars["Decimal"]["output"]>;
  lastStatementIssueDate?: Maybe<Scalars["String"]["output"]>;
  minimumPaymentAmount?: Maybe<Scalars["Decimal"]["output"]>;
  nextPaymentDueDate?: Maybe<Scalars["String"]["output"]>;
  remainingStatementBalance?: Maybe<Scalars["Decimal"]["output"]>;
};

export enum PlaidAccountError {
  ItemLoginRequired = "ITEM_LOGIN_REQUIRED",
  None = "NONE",
  Other = "OTHER",
}

export type PlaidAccountPortfolio = {
  __typename?: "PlaidAccountPortfolio";
  /** @deprecated Use cashBalance instead */
  balance: Scalars["Decimal"]["output"];
  cashBalance: Scalars["Decimal"]["output"];
  error?: Maybe<PlaidAccountError>;
  holdings: Array<PlaidAccountPortfolioHolding>;
  totalBalance?: Maybe<Scalars["Decimal"]["output"]>;
};

export type PlaidAccountPortfolioHolding = {
  __typename?: "PlaidAccountPortfolioHolding";
  closingPrice?: Maybe<Scalars["Float"]["output"]>;
  costBasis: Scalars["Float"]["output"];
  currency: Scalars["String"]["output"];
  cusip?: Maybe<Scalars["String"]["output"]>;
  name: Scalars["String"]["output"];
  plaidAccountId: Scalars["ID"]["output"];
  quantity: Scalars["Float"]["output"];
  security?: Maybe<Security>;
  securityId?: Maybe<Scalars["ID"]["output"]>;
  supported: Scalars["Boolean"]["output"];
  symbol: Scalars["String"]["output"];
};

export enum PlaidAccountStatus {
  OpsGranted = "OPS_GRANTED",
  OpsRejected = "OPS_REJECTED",
  SystemGranted = "SYSTEM_GRANTED",
  SystemPending = "SYSTEM_PENDING",
  /** @deprecated Use SYSTEM_PENDING and then OPS_REJECTED */
  SystemRejected = "SYSTEM_REJECTED",
  UserDeleted = "USER_DELETED",
}

export enum PlaidAccountSubType {
  Brokerage = "BROKERAGE",
  Checking = "CHECKING",
  CreditCard = "CREDIT_CARD",
  Other = "OTHER",
  Savings = "SAVINGS",
}

export enum PlaidAccountType {
  Credit = "CREDIT",
  Depository = "DEPOSITORY",
  Investment = "INVESTMENT",
  Loan = "LOAN",
  Other = "OTHER",
}

export type PlaidAccountsInput = {
  hasLinkedBrokerageAccount?: InputMaybe<Scalars["Boolean"]["input"]>;
  includeDeleted?: InputMaybe<Scalars["Boolean"]["input"]>;
  type?: InputMaybe<PlaidAccountType>;
};

export type PlaidConnection = {
  __typename?: "PlaidConnection";
  consentExpirationTime?: Maybe<Scalars["DateTime"]["output"]>;
  id: Scalars["ID"]["output"];
  plaidAccounts: Array<PlaidAccount>;
  plaidInstitutionId?: Maybe<Scalars["String"]["output"]>;
};

export type PlaidConnectionPlaidAccountsArgs = {
  input?: InputMaybe<PlaidAccountsInput>;
};

export type PlaidLinkToken = {
  __typename?: "PlaidLinkToken";
  expiresAt: Scalars["DateTime"]["output"];
  token: Scalars["String"]["output"];
};

export type PlaidStatusHistory = {
  __typename?: "PlaidStatusHistory";
  comment?: Maybe<Scalars["String"]["output"]>;
  eventTime: Scalars["DateTime"]["output"];
  id: Scalars["String"]["output"];
  status: PlaidAccountStatus;
};

export type Portfolio = {
  __typename?: "Portfolio";
  asOfDate?: Maybe<Scalars["DateTime"]["output"]>;
  availability: AvailabilityLevel;
  cash?: Maybe<Array<LiveCashHolding>>;
  clearingAccountId: Scalars["ID"]["output"];
  securities?: Maybe<Array<LiveSecurityHolding>>;
};

export type PortfolioAggregateValues = {
  __typename?: "PortfolioAggregateValues";
  cashValue: Scalars["Decimal"]["output"];
  dailyChange: Scalars["Decimal"]["output"];
  loanValue?: Maybe<Scalars["Decimal"]["output"]>;
  mmfDividendYTD: Scalars["Decimal"]["output"];
  mmfHoldingValue: Scalars["Decimal"]["output"];
  securityValue: Scalars["Decimal"]["output"];
};

export type PortfolioBorrowingPower = {
  __typename?: "PortfolioBorrowingPower";
  asOfDate: Scalars["DateTime"]["output"];
  borrowingPower: Scalars["Decimal"]["output"];
  clearingAccountId: Scalars["ID"]["output"];
  currentMaxBorrow: Scalars["Decimal"]["output"];
  frecBorrowingPower: Scalars["Decimal"]["output"];
  smaMaxBorrow: Scalars["Decimal"]["output"];
  sodMaxBorrow: Scalars["Decimal"]["output"];
};

export type PortfolioInput = {
  asOfDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  availability: AvailabilityLevel;
  basePortfolio?: InputMaybe<Scalars["Boolean"]["input"]>;
  clearingAccountId: Scalars["ID"]["input"];
  subAccountId?: InputMaybe<Scalars["ID"]["input"]>;
  useCache?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type PortfolioSnapshot = {
  __typename?: "PortfolioSnapshot";
  cashBalance: Scalars["Decimal"]["output"];
  clearingAccountId: Scalars["ID"]["output"];
  date: Scalars["DateTime"]["output"];
  fees: Scalars["Decimal"]["output"];
  loan: Scalars["Decimal"]["output"];
  portfolioSnapshotHoldingClosingValue: Scalars["Decimal"]["output"];
};

export type PortfolioSnapshotHolding = {
  __typename?: "PortfolioSnapshotHolding";
  closingPrice: Scalars["Decimal"]["output"];
  maintenanceRequirement: Scalars["Decimal"]["output"];
  quantity: Scalars["Decimal"]["output"];
  securityId: Scalars["String"]["output"];
};

export type PortfolioStartOfDayValues = {
  __typename?: "PortfolioStartOfDayValues";
  SMABalance: Scalars["Decimal"]["output"];
  asOfDate: Scalars["DateTime"]["output"];
  availableToWithdraw: Scalars["Decimal"]["output"];
  clearingAccountId: Scalars["ID"]["output"];
  impliedSMA: Scalars["Decimal"]["output"];
  maintenanceRequirement: Scalars["Decimal"]["output"];
  marginExcessEquity: Scalars["Decimal"]["output"];
  marginRequirementWithConcentration: Scalars["Decimal"]["output"];
  netLoan: Scalars["Decimal"]["output"];
  positionMarketValue: Scalars["Decimal"]["output"];
  totalEquity: Scalars["Decimal"]["output"];
};

export type PortfolioValuesInput = {
  asOfDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  clearingAccountId: Scalars["ID"]["input"];
};

export type PostProcessEvent = {
  __typename?: "PostProcessEvent";
  apexSodFileId?: Maybe<Scalars["String"]["output"]>;
  eventTime: Scalars["DateTime"]["output"];
  id?: Maybe<Scalars["String"]["output"]>;
  processName: SodPostProcess;
  status: PostProcessStatus;
};

export type PostProcessSodFilesInput = {
  date?: InputMaybe<Scalars["Date"]["input"]>;
  dryRun?: InputMaybe<Scalars["Boolean"]["input"]>;
  filterByApexAccounts?: InputMaybe<Array<Scalars["String"]["input"]>>;
  filterByRecordId?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  processNames: Array<SodPostProcess>;
};

export type PostProcessSodFilesResponse = {
  __typename?: "PostProcessSodFilesResponse";
  count: Scalars["Int"]["output"];
  date: Scalars["Date"]["output"];
  process: SodPostProcess;
  status: Scalars["Boolean"]["output"];
};

export enum PostProcessStatus {
  Fail = "FAIL",
  Invalidated = "INVALIDATED",
  ReviewRequired = "REVIEW_REQUIRED",
  Running = "RUNNING",
  Skip = "SKIP",
  Success = "SUCCESS",
}

export type ProcessMarginCallResponse = {
  __typename?: "ProcessMarginCallResponse";
  errorMessage?: Maybe<Scalars["String"]["output"]>;
  newMarginCalls: Scalars["Int"]["output"];
  openMarginCalls: Scalars["Int"]["output"];
  resolvedMarginCalls: Scalars["Int"]["output"];
  result: ProcessMarginCallResult;
};

export enum ProcessMarginCallResult {
  Error = "ERROR",
  NotBusinessDay = "NOT_BUSINESS_DAY",
  Success = "SUCCESS",
  TooEarlyToRun = "TOO_EARLY_TO_RUN",
}

export type ProcessSodFilesInputArgs = {
  date?: InputMaybe<Scalars["Date"]["input"]>;
  skipMissing?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type ProcessSodResponse = {
  __typename?: "ProcessSodResponse";
  date?: Maybe<Scalars["Date"]["output"]>;
  extractNumber: ExtractNumber;
  status: StatusCode;
};

export enum ProgressFlagCategory {
  FirstLoan = "FIRST_LOAN",
  Onboarding = "ONBOARDING",
}

export enum ProgressFlagStatus {
  Completed = "COMPLETED",
  New = "NEW",
}

export enum QuantityType {
  Fractional = "FRACTIONAL",
  Notional = "NOTIONAL",
}

export type Query = {
  __typename?: "Query";
  betaValues: Array<StockBeta>;
  businessEntity?: Maybe<BusinessEntity>;
  clearingAccount?: Maybe<ClearingAccount>;
  comparisonPortfolioHistory: Array<ComparisonPortfolioSnapshot>;
  currentInterestRate: Scalars["Decimal"]["output"];
  demoClearingAccount?: Maybe<ClearingAccount>;
  demoComparisonPortfolioHistory: Array<ComparisonPortfolioSnapshot>;
  demoGetDirectIndexFeeCharge?: Maybe<DirectIndexFeeCharge>;
  demoGetDirectIndexFeeCharges?: Maybe<Array<DirectIndexFeeCharge>>;
  /** @deprecated Field no longer supported */
  demoGetDirectIndexPerformanceData: DirectIndexPerformanceData;
  demoGetDirectIndexPerformanceDataV2: DirectIndexPerformanceData;
  demoGetDirectIndexTradeExecutionSummary?: Maybe<DirectIndexTradeExecutionSummary>;
  demoGetDirectIndexWeightsForAccount: DirectIndexAccountWeights;
  demoGetIndexConstituents: IndexConstituentData;
  demoGetLiquidateDirectIndexPortfolioRequest?: Maybe<LiquidateDirectIndexPortfolioRequest>;
  demoGetStockLotTransferDetail: StockLotTransferDetailResponse;
  demoPortfolio?: Maybe<Portfolio>;
  demoSubAccountPortfolioAggregates: Array<SubAccountPortfolioAggregate>;
  detailedQuote: Array<DetailedQuote>;
  directIndexSimulate: DirectIndexSimulationStatus;
  directIndexWeights: Array<DirectIndexWeight>;
  employeeReadOnlyClearingAccountAccess: Array<ReadOnlyClearingAccountResponse>;
  estimateDirectIndexGainLoss: EstimateDirectIndexGainLossResponse;
  fetchDirectIndexStockInfoForSubAccount?: Maybe<DirectIndexAccountInfo>;
  fundamentals: Array<FundamentalsSet>;
  getAccountCashTransfers: PaginatedCashTransferResponse;
  getAccountLoan?: Maybe<LoanEvent>;
  getAccountLoans: PaginatedLoanEventResponse;
  getAccountOrders: PaginatedOrderResponse;
  getActiveTreasuryExecutions: Array<Maybe<TreasuryExecution>>;
  getAllMmfMetadata: Array<MmfMetadata>;
  getAllSimulationsRunInfo: Array<DirectIndexSimulationRunInfo>;
  getAnnualizedReturns: Array<AnnualizedReturnResponse>;
  getAvailableIndexTypes: Array<DirectIndexType>;
  getBars: BarOutput;
  getBillingDates?: Maybe<BillingDatesResponse>;
  getCashDividendsForAccount: PaginatedCashDividendResponse;
  getCashTransfers: PaginatedCashTransferResponse;
  getCurrentMarginInterest?: Maybe<MarginInterestResponse>;
  getDailyCashDividendsForAccount: PaginatedDailyCashDividendResponse;
  getDailyMarginInterestChargeForCurrentBillingPeriod?: Maybe<
    Array<DailyMarginInterestCharge>
  >;
  getDailyMarginInterestChargeForPeriod?: Maybe<
    Array<DailyMarginInterestCharge>
  >;
  getDayTradeCount: DayTradeResponse;
  getDirectIndexFeeCharge?: Maybe<DirectIndexFeeCharge>;
  getDirectIndexFeeCharges?: Maybe<Array<DirectIndexFeeCharge>>;
  /** @deprecated Field no longer supported */
  getDirectIndexPerformanceData: DirectIndexPerformanceData;
  getDirectIndexPerformanceDataV2: DirectIndexPerformanceData;
  getDirectIndexSecurityOverrides: DirectIndexAccountWeights;
  getDirectIndexTradeExecutionSummary?: Maybe<DirectIndexTradeExecutionSummary>;
  getDirectIndexTradeExecutions: Array<DirectIndexTradeExecution>;
  getDirectIndexWeightsForAccount: DirectIndexAccountWeights;
  getHistoricalPerformanceData: IndexHistoricalPerformanceData;
  getIndexConstituents: IndexConstituentData;
  getIndexForSecurity: GetIndexForSecurityResponse;
  getIndexHistoricalData: Array<IndexHistoricalData>;
  getIndexMetadata?: Maybe<Array<IndexDetails>>;
  getLiquidateDirectIndexPortfolioRequest?: Maybe<LiquidateDirectIndexPortfolioRequest>;
  getMarginInterestCharges: PaginatedMarginInterestChargesResponse;
  getMarginInterestForecast: MarginInterestForecastResponse;
  getOverlappingDirectIndices: Array<DirectIndexType>;
  getPendingLoans: GetPendingLoansResponse;
  getSimulationRunInfoById?: Maybe<DirectIndexSimulationRunInfoById>;
  getStockAdjustmentForAccount: PaginatedStockAdjustmentResponse;
  getStockLotTransferDetail: StockLotTransferDetailResponse;
  getSubAccountTransferValues: Array<SubAccountTransferValue>;
  getTreasuryRates: TreasuryYield;
  getTreasuryYield: TreasuryYield;
  historicalDeltas: Array<HistoricalDelta>;
  historicalDividendPayments?: Maybe<Array<HistoricalDividendPayments>>;
  historicalFederalRates: Array<Maybe<RateData>>;
  historicalMarketCap: Array<HistoricalMarketCap>;
  historicalPrices: Array<StockData>;
  intraDayAccountValues?: Maybe<IntraDayAccountValues>;
  investmentSearch: InvestmentSearchResult;
  keyValueGet: Array<KeyValuePair>;
  maintenanceRequirements: Array<MarginRequirement>;
  marketHours: MarketHours;
  mfa: UserMfa;
  mmfMetadata: Array<MmfMetadata>;
  oidcClientApplicationDetails?: Maybe<OidcClientApplicationDetails>;
  paginatedCompletedInvitesForUser: PaginatedCompletedInviteStatusEntry;
  paginatedPendingInvitesForUser: PaginatedPendingInviteStatusEntry;
  plaidDepositAccountBalance?: Maybe<Scalars["Decimal"]["output"]>;
  portfolio?: Maybe<Portfolio>;
  portfolioBorrowingPower?: Maybe<PortfolioBorrowingPower>;
  portfolioHistory: Array<PortfolioSnapshot>;
  portfolioStartOfDayValues?: Maybe<PortfolioStartOfDayValues>;
  quote: Array<StockQuote>;
  referralCodeInfo?: Maybe<ReferralCodeInfoResponse>;
  runDirectIndexOptimization?: Maybe<DirectIndexOptimizationRunData>;
  runRuleEngine?: Maybe<Array<RuleEngineResult>>;
  sectors: Array<Sector>;
  securitiesBySymbol?: Maybe<Array<Security>>;
  securityBySymbol?: Maybe<Security>;
  simulationGetEventHistory: EventHistory;
  simulationGetSimulationEventTemplate?: Maybe<Array<ApexSimulationEvent>>;
  simulationGetSimulationIds: Array<Scalars["Int"]["output"]>;
  simulationGetSimulationsEntriesForSimulationId: Array<MarketDataSimulationEntry>;
  simulationGetTestClearingAccounts: Array<Scalars["String"]["output"]>;
  simulationGetTestUsers: Array<Scalars["String"]["output"]>;
  simulationGetUserTemplate: UserQaData;
  sparkline: SparklineOutput;
  subAccountPortfolioAggregates: Array<SubAccountPortfolioAggregate>;
  suggestCollateral: Array<ExternalAccountHoldings>;
  symbolSearch: Array<StockInfo>;
  trustEntity?: Maybe<TrustEntity>;
  user?: Maybe<User>;
  validateInviteCode: Scalars["Boolean"]["output"];
};

export type QueryBetaValuesArgs = {
  input: BetaValueInput;
};

export type QueryClearingAccountArgs = {
  input: ClearingAccountInput;
};

export type QueryComparisonPortfolioHistoryArgs = {
  clearingAccountId: Scalars["ID"]["input"];
  endDate?: InputMaybe<Scalars["Date"]["input"]>;
  startDate: Scalars["Date"]["input"];
  subAccountID: Scalars["ID"]["input"];
};

export type QueryDemoClearingAccountArgs = {
  input: ClearingAccountInput;
};

export type QueryDemoComparisonPortfolioHistoryArgs = {
  clearingAccountId: Scalars["ID"]["input"];
  endDate?: InputMaybe<Scalars["Date"]["input"]>;
  startDate: Scalars["Date"]["input"];
  subAccountID: Scalars["ID"]["input"];
};

export type QueryDemoGetDirectIndexFeeChargeArgs = {
  clearingAccountId: Scalars["ID"]["input"];
  id: Scalars["ID"]["input"];
};

export type QueryDemoGetDirectIndexFeeChargesArgs = {
  clearingAccountId: Scalars["ID"]["input"];
  subAccountId: Scalars["ID"]["input"];
};

export type QueryDemoGetDirectIndexPerformanceDataArgs = {
  subAccountId: Scalars["ID"]["input"];
};

export type QueryDemoGetDirectIndexPerformanceDataV2Args = {
  input: GetDirectIndexPerformanceDataInput;
};

export type QueryDemoGetDirectIndexTradeExecutionSummaryArgs = {
  clearingAccountId: Scalars["ID"]["input"];
  id: Scalars["ID"]["input"];
};

export type QueryDemoGetDirectIndexWeightsForAccountArgs = {
  subAccountId: Scalars["ID"]["input"];
};

export type QueryDemoGetIndexConstituentsArgs = {
  asOfDate?: InputMaybe<Scalars["Date"]["input"]>;
  gicsCode?: InputMaybe<GicsCode>;
  index: StockIndex;
};

export type QueryDemoGetLiquidateDirectIndexPortfolioRequestArgs = {
  clearingAccountId: Scalars["ID"]["input"];
  id: Scalars["ID"]["input"];
};

export type QueryDemoGetStockLotTransferDetailArgs = {
  input: StockLotTransferDetailInput;
};

export type QueryDemoPortfolioArgs = {
  input: PortfolioInput;
};

export type QueryDemoSubAccountPortfolioAggregatesArgs = {
  input: SubAccountPortfolioAggregatesInput;
};

export type QueryDetailedQuoteArgs = {
  input: DetailedQuoteInput;
};

export type QueryDirectIndexSimulateArgs = {
  input: Scalars["String"]["input"];
  submitOnly?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type QueryDirectIndexWeightsArgs = {
  input: DirectIndexWeightsInput;
};

export type QueryEstimateDirectIndexGainLossArgs = {
  input: EstimateDirectIndexGainLossInput;
};

export type QueryFetchDirectIndexStockInfoForSubAccountArgs = {
  asOfDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  subAccountId: Scalars["ID"]["input"];
};

export type QueryFundamentalsArgs = {
  input: FundamentalsInput;
};

export type QueryGetAccountCashTransfersArgs = {
  clearingAccountId: Scalars["ID"]["input"];
  nextPageToken?: InputMaybe<Scalars["String"]["input"]>;
  size?: InputMaybe<Scalars["Int"]["input"]>;
};

export type QueryGetAccountLoanArgs = {
  clearingAccountId: Scalars["ID"]["input"];
  loanId: Scalars["ID"]["input"];
};

export type QueryGetAccountLoansArgs = {
  clearingAccountId: Scalars["ID"]["input"];
  nextPageToken?: InputMaybe<Scalars["String"]["input"]>;
  size?: InputMaybe<Scalars["Int"]["input"]>;
};

export type QueryGetAccountOrdersArgs = {
  clearingAccountId: Scalars["ID"]["input"];
  nextPageToken?: InputMaybe<Scalars["String"]["input"]>;
  size?: InputMaybe<Scalars["Int"]["input"]>;
};

export type QueryGetActiveTreasuryExecutionsArgs = {
  clearingAccountId: Scalars["ID"]["input"];
};

export type QueryGetAnnualizedReturnsArgs = {
  asOfDate?: InputMaybe<Scalars["Date"]["input"]>;
  indices: Array<StockIndex>;
};

export type QueryGetBarsArgs = {
  input: BarInput;
};

export type QueryGetCashDividendsForAccountArgs = {
  input: GetDividendsArgs;
};

export type QueryGetCashTransfersArgs = {
  args?: InputMaybe<GetCashTransfersArgs>;
};

export type QueryGetDailyCashDividendsForAccountArgs = {
  input: GetDividendsArgs;
};

export type QueryGetDailyMarginInterestChargeForCurrentBillingPeriodArgs = {
  clearingAccountId: Scalars["ID"]["input"];
  endDate?: InputMaybe<Scalars["Date"]["input"]>;
  startDate?: InputMaybe<Scalars["Date"]["input"]>;
};

export type QueryGetDailyMarginInterestChargeForPeriodArgs = {
  clearingAccountId: Scalars["ID"]["input"];
  period: DatePeriodArgs;
};

export type QueryGetDayTradeCountArgs = {
  input: CheckDayTradeInput;
};

export type QueryGetDirectIndexFeeChargeArgs = {
  clearingAccountId: Scalars["ID"]["input"];
  id: Scalars["ID"]["input"];
};

export type QueryGetDirectIndexFeeChargesArgs = {
  clearingAccountId: Scalars["ID"]["input"];
  subAccountId: Scalars["ID"]["input"];
};

export type QueryGetDirectIndexPerformanceDataArgs = {
  subAccountId: Scalars["ID"]["input"];
};

export type QueryGetDirectIndexPerformanceDataV2Args = {
  input: GetDirectIndexPerformanceDataInput;
};

export type QueryGetDirectIndexSecurityOverridesArgs = {
  subAccountId: Scalars["ID"]["input"];
};

export type QueryGetDirectIndexTradeExecutionSummaryArgs = {
  clearingAccountId: Scalars["ID"]["input"];
  id: Scalars["ID"]["input"];
};

export type QueryGetDirectIndexTradeExecutionsArgs = {
  input: GetDirectIndexTradeExecutionsInput;
};

export type QueryGetDirectIndexWeightsForAccountArgs = {
  subAccountId: Scalars["ID"]["input"];
};

export type QueryGetHistoricalPerformanceDataArgs = {
  args: GetHistoricalIndexPerformanceDataInput;
};

export type QueryGetIndexConstituentsArgs = {
  asOfDate?: InputMaybe<Scalars["Date"]["input"]>;
  gicsCode?: InputMaybe<GicsCode>;
  index: StockIndex;
};

export type QueryGetIndexForSecurityArgs = {
  input: GetIndexForSecurityInput;
};

export type QueryGetIndexHistoricalDataArgs = {
  asOfDate?: InputMaybe<Scalars["Date"]["input"]>;
  indices: Array<StockIndex>;
};

export type QueryGetIndexMetadataArgs = {
  args?: InputMaybe<GetIndexDataInput>;
};

export type QueryGetLiquidateDirectIndexPortfolioRequestArgs = {
  clearingAccountId: Scalars["ID"]["input"];
  id: Scalars["ID"]["input"];
};

export type QueryGetMarginInterestChargesArgs = {
  input: MarginInterestChargeInput;
  nextPageToken?: InputMaybe<Scalars["String"]["input"]>;
  size?: InputMaybe<Scalars["Int"]["input"]>;
};

export type QueryGetOverlappingDirectIndicesArgs = {
  clearingAccountId: Scalars["ID"]["input"];
  directIndexType: DirectIndexType;
};

export type QueryGetPendingLoansArgs = {
  input: GetPendingLoansInput;
  nextPageToken?: InputMaybe<Scalars["String"]["input"]>;
  size?: InputMaybe<Scalars["Int"]["input"]>;
};

export type QueryGetSimulationRunInfoByIdArgs = {
  id: Scalars["ID"]["input"];
};

export type QueryGetStockAdjustmentForAccountArgs = {
  input: GetStockAdjustmentArgs;
};

export type QueryGetStockLotTransferDetailArgs = {
  input: StockLotTransferDetailInput;
};

export type QueryGetSubAccountTransferValuesArgs = {
  input: SubAccountTransferValueInput;
};

export type QueryGetTreasuryYieldArgs = {
  clearingAccountId: Scalars["ID"]["input"];
};

export type QueryHistoricalDeltasArgs = {
  input: HistoricalDeltaInput;
};

export type QueryHistoricalDividendPaymentsArgs = {
  date: Scalars["Date"]["input"];
  symbol: Scalars["String"]["input"];
};

export type QueryHistoricalMarketCapArgs = {
  input: HistoricalMarketCapInput;
};

export type QueryHistoricalPricesArgs = {
  input: HistoricalInput;
};

export type QueryIntraDayAccountValuesArgs = {
  input: ClearingAccountInput;
};

export type QueryInvestmentSearchArgs = {
  query: Scalars["String"]["input"];
};

export type QueryKeyValueGetArgs = {
  input: KeyValueGetInput;
};

export type QueryMaintenanceRequirementsArgs = {
  symbols: Array<Scalars["String"]["input"]>;
};

export type QueryMmfMetadataArgs = {
  input: MmfMetadataInput;
};

export type QueryOidcClientApplicationDetailsArgs = {
  input: OidcClientApplicationDetailsInput;
};

export type QueryPaginatedCompletedInvitesForUserArgs = {
  input: CompletedInvitesForUserInput;
};

export type QueryPaginatedPendingInvitesForUserArgs = {
  input: PendingInvitesForUserInput;
};

export type QueryPlaidDepositAccountBalanceArgs = {
  plaidAccountId: Scalars["ID"]["input"];
};

export type QueryPortfolioArgs = {
  input: PortfolioInput;
};

export type QueryPortfolioBorrowingPowerArgs = {
  input: PortfolioValuesInput;
};

export type QueryPortfolioHistoryArgs = {
  clearingAccountId: Scalars["ID"]["input"];
  endDate?: InputMaybe<Scalars["Date"]["input"]>;
  startDate: Scalars["Date"]["input"];
  subAccountID?: InputMaybe<Scalars["ID"]["input"]>;
};

export type QueryPortfolioStartOfDayValuesArgs = {
  input: PortfolioValuesInput;
};

export type QueryQuoteArgs = {
  input: QuoteInput;
};

export type QueryReferralCodeInfoArgs = {
  inviteCode: Scalars["String"]["input"];
};

export type QueryRunDirectIndexOptimizationArgs = {
  input: RunDirectIndexOptimizationInput;
};

export type QueryRunRuleEngineArgs = {
  input: FactInput;
  ruleGroup: RuleGroup;
};

export type QuerySecuritiesBySymbolArgs = {
  symbols: Array<Scalars["String"]["input"]>;
};

export type QuerySecurityBySymbolArgs = {
  symbol: Scalars["String"]["input"];
};

export type QuerySimulationGetEventHistoryArgs = {
  eventId: Scalars["String"]["input"];
  eventType: EventType;
};

export type QuerySimulationGetSimulationEventTemplateArgs = {
  eventId: Scalars["String"]["input"];
  eventType: EventType;
};

export type QuerySimulationGetSimulationsEntriesForSimulationIdArgs = {
  simulationId: Scalars["Int"]["input"];
};

export type QuerySparklineArgs = {
  input: SparklineInput;
};

export type QuerySubAccountPortfolioAggregatesArgs = {
  input: SubAccountPortfolioAggregatesInput;
};

export type QuerySuggestCollateralArgs = {
  input: SuggestCollateralInput;
};

export type QuerySymbolSearchArgs = {
  options?: InputMaybe<SymbolSearchInput>;
  query: Scalars["String"]["input"];
};

export type QueryValidateInviteCodeArgs = {
  inviteCode: Scalars["String"]["input"];
};

export type QueueApprovedDirectIndexTradeExecutionInput = {
  executionIds?: InputMaybe<Array<Scalars["ID"]["input"]>>;
};

export type QuoteInput = {
  symbols: Array<Scalars["String"]["input"]>;
};

export type RateData = {
  __typename?: "RateData";
  date: Scalars["Date"]["output"];
  rate: Scalars["Decimal"]["output"];
};

export type ReadOnlyClearingAccountResponse = {
  __typename?: "ReadOnlyClearingAccountResponse";
  businessReason: Scalars["String"]["output"];
  clearingAccountId: Scalars["ID"]["output"];
  expiresAt: Scalars["DateTime"]["output"];
};

export enum RealizedIndicatorEnumGql {
  Realized = "Realized",
  Unrealized = "Unrealized",
}

export type RealizedLotsSummary = {
  __typename?: "RealizedLotsSummary";
  longCostBasis: Scalars["Decimal"]["output"];
  longNetProceeds: Scalars["Decimal"]["output"];
  longTermGains: Scalars["Decimal"]["output"];
  longTermLosses: Scalars["Decimal"]["output"];
  longWashSalesDisallowed: Scalars["Decimal"]["output"];
  shortCostBasis: Scalars["Decimal"]["output"];
  shortNetProceeds: Scalars["Decimal"]["output"];
  shortTermGains: Scalars["Decimal"]["output"];
  shortTermLosses: Scalars["Decimal"]["output"];
  shortWashSalesDisallowed: Scalars["Decimal"]["output"];
};

export type RealizedLotsSummaryInput = {
  asOfDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  fromDate?: InputMaybe<Scalars["Date"]["input"]>;
  securityId?: InputMaybe<Scalars["ID"]["input"]>;
  /** @deprecated Use fromDate instead */
  startDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  subAccountId?: InputMaybe<Scalars["ID"]["input"]>;
  toDate?: InputMaybe<Scalars["Date"]["input"]>;
};

export enum RecipientBankType {
  Aba = "ABA",
  Bic = "BIC",
}

export type ReferralCodeInfoResponse = {
  __typename?: "ReferralCodeInfoResponse";
  dealType: UserReferralDeal;
  invitedBy?: Maybe<Scalars["String"]["output"]>;
};

export type ReferralRewardDetails = {
  __typename?: "ReferralRewardDetails";
  /** @deprecated Present for backwards compatibility, use grantedOn instead */
  completedOn: Scalars["DateTime"]["output"];
  grantedOn?: Maybe<Scalars["DateTime"]["output"]>;
  kind: RewardKind;
  referralRewardType: ReferralRewardType;
  /** @deprecated This information is simply referralRewardType rendered as a string */
  rewardReceived: Scalars["String"]["output"];
  /** @deprecated This string will be constructed on the client by looking up */
  rewardReceivedAdditionalInfo?: Maybe<Scalars["String"]["output"]>;
};

export enum ReferralRewardSide {
  InvitedUser = "INVITED_USER",
  ReferringUser = "REFERRING_USER",
}

export type ReferralRewardType = CashRewardType | TreasuryRewardType;

export enum RemoteConfigGroup {
  AutoTlhAccountSkipList = "AUTO_TLH_ACCOUNT_SKIP_LIST",
  CashTransferReviewAccountSkipList = "CASH_TRANSFER_REVIEW_ACCOUNT_SKIP_LIST",
  Default = "DEFAULT",
  DiAvailableIndices = "DI_AVAILABLE_INDICES",
  DiSubAccountSkipAutoApprove = "DI_SUB_ACCOUNT_SKIP_AUTO_APPROVE",
  DiSubAccountSkipList = "DI_SUB_ACCOUNT_SKIP_LIST",
  EventTypeBatchSize = "EVENT_TYPE_BATCH_SIZE",
  MarginCallNotifyAccountSkipList = "MARGIN_CALL_NOTIFY_ACCOUNT_SKIP_LIST",
  MfaUserAllowList = "MFA_USER_ALLOW_LIST",
  SplitLotClearingAccountList = "SPLIT_LOT_CLEARING_ACCOUNT_LIST",
}

export type RepayLoanInput = {
  amount: Scalars["Decimal"]["input"];
  clearingAccountId: Scalars["ID"]["input"];
  marginInterestFeeAmount?: InputMaybe<Scalars["Decimal"]["input"]>;
};

export enum ReportType {
  CaisCustomer = "CAIS_CUSTOMER",
  CaisFdid = "CAIS_FDID",
  CaisTransformedId = "CAIS_TRANSFORMED_ID",
  CatOrders = "CAT_ORDERS",
  FinraAccountBalance = "FINRA_ACCOUNT_BALANCE",
  FinraAccountInfo = "FINRA_ACCOUNT_INFO",
  FinraOrders = "FINRA_ORDERS",
  Mecr = "MECR",
  Meno = "MENO",
  Meoc = "MEOC",
  Meor = "MEOR",
}

export type RequestMfaPhoneChallengeInput = {
  channel: PhoneVerificationChannel;
};

export type RequestMfaPhoneChallengeResponse = {
  __typename?: "RequestMfaPhoneChallengeResponse";
  phone: Scalars["String"]["output"];
  status: Scalars["Boolean"]["output"];
};

export type RequestVerificationCodeResponse = {
  __typename?: "RequestVerificationCodeResponse";
  codeSentAt?: Maybe<Scalars["String"]["output"]>;
  isCodeSent: Scalars["Boolean"]["output"];
  message?: Maybe<Scalars["String"]["output"]>;
};

export enum ReviewStatus {
  Reviewed = "REVIEWED",
  ReviewRequired = "REVIEW_REQUIRED",
}

export enum ReviewType {
  AcatsInMismatchReview = "ACATS_IN_MISMATCH_REVIEW",
  AcatsOutReview = "ACATS_OUT_REVIEW",
  AccountNotFound = "ACCOUNT_NOT_FOUND",
  ActiveAccountMissingPositions = "ACTIVE_ACCOUNT_MISSING_POSITIONS",
  CashBalanceMismatch = "CASH_BALANCE_MISMATCH",
  CashRebalanceRequired = "CASH_REBALANCE_REQUIRED",
  ClosedAccount = "CLOSED_ACCOUNT",
  CostBasisInfoMissing = "COST_BASIS_INFO_MISSING",
  CostBasisMismatch = "COST_BASIS_MISMATCH",
  DebitCashTransfer = "DEBIT_CASH_TRANSFER",
  IncomingAssetsWithMultipleAcats = "INCOMING_ASSETS_WITH_MULTIPLE_ACATS",
  IncomingAssetsWithNoAcats = "INCOMING_ASSETS_WITH_NO_ACATS",
  IncorrectDividendProcessing = "INCORRECT_DIVIDEND_PROCESSING",
  InvalidAccountType = "INVALID_ACCOUNT_TYPE",
  InvalidCorporateAction = "INVALID_CORPORATE_ACTION",
  InvalidCostBasisInfo = "INVALID_COST_BASIS_INFO",
  InvalidData = "INVALID_DATA",
  InvalidDrip = "INVALID_DRIP",
  InvalidQuantity = "INVALID_QUANTITY",
  MarginCall = "MARGIN_CALL",
  PortfolioSymbolQuantityMismatch = "PORTFOLIO_SYMBOL_QUANTITY_MISMATCH",
  ResidualCreditReview = "RESIDUAL_CREDIT_REVIEW",
  SymbolChange = "SYMBOL_CHANGE",
  SymbolNotFound = "SYMBOL_NOT_FOUND",
  UnsupportedCorporateAction = "UNSUPPORTED_CORPORATE_ACTION",
  WireReversalFeeApplied = "WIRE_REVERSAL_FEE_APPLIED",
}

export enum ReviewerType {
  Engineering = "ENGINEERING",
  Ops = "OPS",
}

export type RevokeOidcConnectionInput = {
  recipientId: Scalars["String"]["input"];
};

export enum RewardKind {
  Granted = "GRANTED",
  Pending = "PENDING",
}

export enum RiskTolerance {
  High = "HIGH",
  Low = "LOW",
  Medium = "MEDIUM",
  Unspecified = "UNSPECIFIED",
}

export type RuleEngineConfig = {
  __typename?: "RuleEngineConfig";
  active: Scalars["Boolean"]["output"];
  allowAction: Scalars["Boolean"]["output"];
  description?: Maybe<Scalars["String"]["output"]>;
  group: RuleGroup;
  id: Scalars["String"]["output"];
  name: Scalars["String"]["output"];
  priority: Scalars["Int"]["output"];
  rule: Scalars["String"]["output"];
};

export type RuleEngineConfigInput = {
  active: Scalars["Boolean"]["input"];
  allowAction: Scalars["Boolean"]["input"];
  description?: InputMaybe<Scalars["String"]["input"]>;
  group: RuleGroup;
  id?: InputMaybe<Scalars["String"]["input"]>;
  name: Scalars["String"]["input"];
  priority?: InputMaybe<Scalars["Int"]["input"]>;
  rule: Scalars["String"]["input"];
};

export type RuleEngineResult = {
  __typename?: "RuleEngineResult";
  actionContext: ActionContext;
  allowAction: Scalars["Boolean"]["output"];
  description: Scalars["String"]["output"];
  name: Scalars["String"]["output"];
};

export enum RuleGroup {
  ActionContext = "ACTION_CONTEXT",
  Trading = "TRADING",
}

export type RunDirectIndexOptimizationInput = {
  accountInfo: DirectIndexAccountInfoInput;
  asOfDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  directIndexOptions?: InputMaybe<DirectIndexOptions>;
  directIndexSolverOptions?: InputMaybe<DirectIndexSolverOptions>;
  forceRebalance?: InputMaybe<Scalars["Boolean"]["input"]>;
  liquidateAmount?: InputMaybe<Scalars["Decimal"]["input"]>;
  runMode?: InputMaybe<DirectIndexCandidateGenerationRunMode>;
};

export type RunJobInput = {
  forceRun?: InputMaybe<Scalars["Boolean"]["input"]>;
  jobName: JobName;
};

export type RunJobResponse = {
  __typename?: "RunJobResponse";
  status: RunJobResponseStatus;
};

export enum RunJobResponseStatus {
  JobAlreadyRunning = "JOB_ALREADY_RUNNING",
  JobFailed = "JOB_FAILED",
  JobNotActive = "JOB_NOT_ACTIVE",
  JobNotFound = "JOB_NOT_FOUND",
  JobNotRegistered = "JOB_NOT_REGISTERED",
  JobSkipped = "JOB_SKIPPED",
  JobStartedSuccessfully = "JOB_STARTED_SUCCESSFULLY",
  JobSuccess = "JOB_SUCCESS",
  JobTooEarlyToStart = "JOB_TOO_EARLY_TO_START",
  JobWaitingOnDependency = "JOB_WAITING_ON_DEPENDENCY",
}

export type RunJobsResponse = {
  __typename?: "RunJobsResponse";
  jobName: Scalars["String"]["output"];
  status: RunJobResponseStatus;
};

export type ScheduledDepositConfig = {
  __typename?: "ScheduledDepositConfig";
  amount: Scalars["Decimal"]["output"];
  clearingAccountId: Scalars["ID"]["output"];
  dayOfPeriod?: Maybe<Scalars["Int"]["output"]>;
  id: Scalars["ID"]["output"];
  periodType: ScheduledDepositPeriodType;
  primaryAccountId?: Maybe<Scalars["ID"]["output"]>;
  secondaryDayOfPeriod?: Maybe<Scalars["Int"]["output"]>;
  sourceSubAccountId?: Maybe<Scalars["ID"]["output"]>;
  sourceType: MoneyMovementSourceType;
  startAt: Scalars["DateTime"]["output"];
  subAccountId: Scalars["ID"]["output"];
  type: ScheduledDepositType;
};

export type ScheduledDepositConfigInput = {
  amount: Scalars["Decimal"]["input"];
  dayOfPeriod?: InputMaybe<Scalars["Int"]["input"]>;
  periodType: ScheduledDepositPeriodType;
  primaryAccountId?: InputMaybe<Scalars["ID"]["input"]>;
  secondaryDayOfPeriod?: InputMaybe<Scalars["Int"]["input"]>;
  sourceSubAccountId?: InputMaybe<Scalars["ID"]["input"]>;
  sourceType: MoneyMovementSourceType;
  startAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  type: ScheduledDepositType;
};

export type ScheduledDepositExecution = {
  __typename?: "ScheduledDepositExecution";
  amount: Scalars["Decimal"]["output"];
  eventTime: Scalars["DateTime"]["output"];
  failureReason?: Maybe<ScheduledDepositFailureReason>;
  id: Scalars["ID"]["output"];
  scheduledDepositConfig?: Maybe<ScheduledDepositConfig>;
  scheduledDepositConfigId: Scalars["ID"]["output"];
  status: ScheduledDepositExecutionStatus;
};

export enum ScheduledDepositExecutionStatus {
  Cancelled = "CANCELLED",
  Completed = "COMPLETED",
  DepositInitiated = "DEPOSIT_INITIATED",
  Failure = "FAILURE",
  Initiated = "INITIATED",
  Terminated = "TERMINATED",
}

export enum ScheduledDepositFailureReason {
  AdminCancelled = "ADMIN_CANCELLED",
  DepositFailure = "DEPOSIT_FAILURE",
  UserCancelled = "USER_CANCELLED",
}

export enum ScheduledDepositPeriodType {
  BiWeekly = "BI_WEEKLY",
  Monthly = "MONTHLY",
  Quarterly = "QUARTERLY",
  Weekly = "WEEKLY",
}

export enum ScheduledDepositType {
  PeriodicDeposit = "PERIODIC_DEPOSIT",
  SingleDeposit = "SINGLE_DEPOSIT",
}

export type ScheduledReward = {
  __typename?: "ScheduledReward";
  createdAt: Scalars["DateTime"]["output"];
  creditAmount: Scalars["Decimal"]["output"];
  creditDueDate: Scalars["DateTime"]["output"];
  creditId?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["String"]["output"];
  promotionKey: Scalars["String"]["output"];
  promotionName: Scalars["String"]["output"];
  promotionTerms: Scalars["String"]["output"];
  subAccount?: Maybe<SubAccount>;
  subAccountId: Scalars["String"]["output"];
  updatedAt: Scalars["DateTime"]["output"];
};

export type Sector = {
  __typename?: "Sector";
  industries: Array<Scalars["String"]["output"]>;
  name: Scalars["String"]["output"];
};

export type SectorMapping = {
  __typename?: "SectorMapping";
  gicsCode: GicsCode;
  id: Scalars["ID"]["output"];
  index?: Maybe<StockIndex>;
  name: Scalars["String"]["output"];
};

export type Security = {
  __typename?: "Security";
  asOfDate?: Maybe<Scalars["DateTime"]["output"]>;
  buyStatus: SecurityTradeStatus;
  cusip?: Maybe<Scalars["String"]["output"]>;
  deleted?: Maybe<Scalars["Boolean"]["output"]>;
  id: Scalars["ID"]["output"];
  isin?: Maybe<Scalars["String"]["output"]>;
  mmfMetadata?: Maybe<MmfMetadata>;
  name: Scalars["String"]["output"];
  sellStatus: SecurityTradeStatus;
  stockQuote?: Maybe<StockQuote>;
  subType: SecuritySubType;
  symbol: Scalars["String"]["output"];
  symbolAlias?: Maybe<Array<Maybe<SymbolAlias>>>;
  type: SecurityType;
};

export enum SecurityAliasContext {
  Acats = "ACATS",
  Default = "DEFAULT",
  None = "NONE",
  Plaid = "PLAID",
  Reporting = "REPORTING",
  SodFiles = "SOD_FILES",
}

export type SecurityGainLoss = {
  __typename?: "SecurityGainLoss";
  longTermGains: Scalars["Decimal"]["output"];
  longTermWashSales: Scalars["Decimal"]["output"];
  securityId: Scalars["ID"]["output"];
  shortTermGains: Scalars["Decimal"]["output"];
  shortTermWashSales: Scalars["Decimal"]["output"];
};

export type SecuritySearchResult = {
  __typename?: "SecuritySearchResult";
  name: Scalars["String"]["output"];
  symbol: Scalars["String"]["output"];
};

export enum SecuritySubType {
  Call = "CALL",
  CommonStock = "COMMON_STOCK",
  CorporateBond = "CORPORATE_BOND",
  Etf = "ETF",
  MiscStock = "MISC_STOCK",
  MoneyMarketFund = "MONEY_MARKET_FUND",
  MutualFund = "MUTUAL_FUND",
  PreferredStock = "PREFERRED_STOCK",
  Put = "PUT",
  Warrant = "WARRANT",
}

export enum SecurityTradeStatus {
  Fractional = "FRACTIONAL",
  None = "NONE",
  WholeShares = "WHOLE_SHARES",
}

export enum SecurityType {
  Bond = "BOND",
  Equity = "EQUITY",
  Future = "FUTURE",
  Option = "OPTION",
}

export type SelfManagedTaxLossConfig = {
  __typename?: "SelfManagedTaxLossConfig";
  clearingAccountId: Scalars["String"]["output"];
  id: Scalars["String"]["output"];
  securities: Array<Scalars["ID"]["output"]>;
  sweepAll: Scalars["Boolean"]["output"];
  sweepToSubAccountId: Scalars["String"]["output"];
};

export type SelfManagedTaxLossConfigInput = {
  clearingAccountId: Scalars["ID"]["input"];
  sweepAll: Scalars["Boolean"]["input"];
  sweepToSubAccountId: Scalars["ID"]["input"];
};

export type SelfManagedTaxLossSecuritiesInput = {
  clearingAccountId: Scalars["ID"]["input"];
  securities: Array<Scalars["ID"]["input"]>;
};

export type SellOrder = {
  date: Scalars["DateTime"]["input"];
  symbol: Scalars["String"]["input"];
};

export type SetProgressFlagInput = {
  category: ProgressFlagCategory;
  status: ProgressFlagStatus;
};

export type SimpleActivity = {
  __typename?: "SimpleActivity";
  activityType: ActivityType;
  automated?: Maybe<Scalars["Boolean"]["output"]>;
  date?: Maybe<Scalars["DateTime"]["output"]>;
  description?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["String"]["output"];
  numOfShares?: Maybe<Scalars["Decimal"]["output"]>;
  status: StatusEnum;
  statusText?: Maybe<Scalars["String"]["output"]>;
  title: Scalars["String"]["output"];
  /** @deprecated Field no longer supported */
  type?: Maybe<ActivityListItemTypeKey>;
  value?: Maybe<Scalars["Decimal"]["output"]>;
};

export type SimulationDirectIndexExecutionInput = {
  directIndexTradeExecutionId: Scalars["ID"]["input"];
};

export enum SimulationType {
  CustomSymbols = "CUSTOM_SYMBOLS",
  DirectIndex = "DIRECT_INDEX",
  DiSnapshotBased = "DI_SNAPSHOT_BASED",
  EtfBased = "ETF_BASED",
  IndexOnly = "INDEX_ONLY",
}

export enum SodPostProcess {
  CashActivity = "CASH_ACTIVITY",
  CorporateAction = "CORPORATE_ACTION",
  CostBasis = "COST_BASIS",
  Dividend = "DIVIDEND",
  DividendSweep = "DIVIDEND_SWEEP",
  SecurityMaster = "SECURITY_MASTER",
  StockActivity = "STOCK_ACTIVITY",
  TaxLotEntries = "TAX_LOT_ENTRIES",
  TaxLotReconciled = "TAX_LOT_RECONCILED",
}

export type SparklineInput = {
  symbol: Scalars["String"]["input"];
};

export type SparklineOutput = {
  __typename?: "SparklineOutput";
  prevClose?: Maybe<Scalars["Float"]["output"]>;
  prices: Array<Scalars["Float"]["output"]>;
};

export enum StatusCode {
  ExternalFileMissing = "EXTERNAL_FILE_MISSING",
  FailedToConnectToExternalServer = "FAILED_TO_CONNECT_TO_EXTERNAL_SERVER",
  FailedToPersistFile = "FAILED_TO_PERSIST_FILE",
  FailedToValidateFile = "FAILED_TO_VALIDATE_FILE",
  FileAlreadyFetchedAndPersisted = "FILE_ALREADY_FETCHED_AND_PERSISTED",
  FileAlreadyValidated = "FILE_ALREADY_VALIDATED",
  FileNotProcessed = "FILE_NOT_PROCESSED",
  SuccessfulDownloadAndPersist = "SUCCESSFUL_DOWNLOAD_AND_PERSIST",
  SuccessfulPersist = "SUCCESSFUL_PERSIST",
  SuccessfulValidation = "SUCCESSFUL_VALIDATION",
  UnsupportedExtractNumber = "UNSUPPORTED_EXTRACT_NUMBER",
  UnsupportedReportType = "UNSUPPORTED_REPORT_TYPE",
}

export enum StatusEnum {
  Canceled = "CANCELED",
  Completed = "COMPLETED",
  Executed = "EXECUTED",
  Failed = "FAILED",
  Initiated = "INITIATED",
  Pending = "PENDING",
  PendingCancellation = "PENDING_CANCELLATION",
  Queued = "QUEUED",
  Returned = "RETURNED",
  Review = "REVIEW",
  SettleClose = "SETTLE_CLOSE",
  Unknown = "UNKNOWN",
}

export type StockAdjustmentAmountByType = {
  __typename?: "StockAdjustmentAmountByType";
  adjustmentType: AdjustmentType;
  amount: Scalars["Decimal"]["output"];
};

export type StockAdjustmentDetails = {
  __typename?: "StockAdjustmentDetails";
  adjustmentAmount?: Maybe<Scalars["Decimal"]["output"]>;
  adjustmentFactor: Scalars["Decimal"]["output"];
  adjustmentType: AdjustmentType;
  clearingAccount?: Maybe<ClearingAccount>;
  clearingAccountId: Scalars["ID"]["output"];
  /** @deprecated Field no longer supported */
  effectiveDate?: Maybe<Scalars["Date"]["output"]>;
  effectiveTime: Scalars["DateTime"]["output"];
  eventTime: Scalars["DateTime"]["output"];
  id: Scalars["ID"]["output"];
  security?: Maybe<Security>;
  securityId: Scalars["ID"]["output"];
  securityPrice?: Maybe<Scalars["Decimal"]["output"]>;
  subAccountId: Scalars["ID"]["output"];
  treasuryFees?: Maybe<Array<TreasuryFee>>;
};

export type StockBeta = {
  __typename?: "StockBeta";
  beta: Scalars["Float"]["output"];
  symbol: Scalars["String"]["output"];
};

export type StockData = {
  __typename?: "StockData";
  history?: Maybe<Array<StockHistory>>;
  symbol: Scalars["String"]["output"];
};

export type StockHistory = {
  __typename?: "StockHistory";
  close: Scalars["Decimal"]["output"];
  date: Scalars["Date"]["output"];
};

export enum StockIndex {
  CrspIssLargeCapEsg = "CRSP_ISS_LARGE_CAP_ESG",
  CrspLargeCap = "CRSP_LARGE_CAP",
  CrspMidCap = "CRSP_MID_CAP",
  CrspSmallCap = "CRSP_SMALL_CAP",
  Russell_1000 = "RUSSELL_1000",
  Russell_2000 = "RUSSELL_2000",
  Russell_3000 = "RUSSELL_3000",
  Smh = "SMH",
  Sp500 = "SP500",
  Sp500_10 = "SP500_10",
  Sp500_15 = "SP500_15",
  Sp500_20 = "SP500_20",
  Sp500_25 = "SP500_25",
  Sp500_30 = "SP500_30",
  Sp500_35 = "SP500_35",
  Sp500_40 = "SP500_40",
  Sp500_45 = "SP500_45",
  Sp500_50 = "SP500_50",
  Sp500_55 = "SP500_55",
  Sp500_60 = "SP500_60",
  SpAdrDm = "SP_ADR_DM",
  SpShariah = "SP_SHARIAH",
}

export type StockInfo = {
  __typename?: "StockInfo";
  exchangeShortName?: Maybe<Scalars["String"]["output"]>;
  name?: Maybe<Scalars["String"]["output"]>;
  security?: Maybe<Security>;
  securityId?: Maybe<Scalars["ID"]["output"]>;
  symbol: Scalars["String"]["output"];
};

export type StockLotTransferDetailForSymbol = {
  __typename?: "StockLotTransferDetailForSymbol";
  canShowTargetAllocation: Scalars["Boolean"]["output"];
  lots?: Maybe<Array<StockLotTransferLotDetail>>;
  name: Scalars["String"]["output"];
  securityId: Scalars["ID"]["output"];
  stockPriceUsed: Scalars["Decimal"]["output"];
  symbol: Scalars["String"]["output"];
  targetAllocation?: Maybe<Scalars["Decimal"]["output"]>;
  withinIndex: Scalars["Boolean"]["output"];
};

export type StockLotTransferDetailInput = {
  clearingAccountId: Scalars["ID"]["input"];
  customization?: InputMaybe<DirectIndexOnCreateCustomizationInput>;
  directIndexType: DirectIndexType;
};

export enum StockLotTransferDetailInvalidReason {
  MissingCostBasis = "MISSING_COST_BASIS",
  MultipleLotsPerTransaction = "MULTIPLE_LOTS_PER_TRANSACTION",
  NotFractionallyTradeable = "NOT_FRACTIONALLY_TRADEABLE",
  TaxLotHoldingQtyMismatch = "TAX_LOT_HOLDING_QTY_MISMATCH",
}

export type StockLotTransferDetailInvalidStateBySymbol = {
  __typename?: "StockLotTransferDetailInvalidStateBySymbol";
  name: Scalars["String"]["output"];
  reason: StockLotTransferDetailInvalidReason;
  symbol: Scalars["String"]["output"];
};

export type StockLotTransferDetailResponse = {
  __typename?: "StockLotTransferDetailResponse";
  invalidStatesBySymbol?: Maybe<
    Array<StockLotTransferDetailInvalidStateBySymbol>
  >;
  lotsBySymbol?: Maybe<Array<StockLotTransferDetailForSymbol>>;
};

export type StockLotTransferLotDetail = {
  __typename?: "StockLotTransferLotDetail";
  id: Scalars["ID"]["output"];
  longTermShortTermIndicator: Scalars["String"]["output"];
  openBuyPrice: Scalars["Decimal"]["output"];
  quantity: Scalars["Decimal"]["output"];
  recomputedRealizedGainLoss: Scalars["Decimal"]["output"];
  taxLotOpenBuyDate: Scalars["Date"]["output"];
  washSalesIndicator: Scalars["String"]["output"];
};

export type StockQuote = {
  __typename?: "StockQuote";
  change?: Maybe<Scalars["Decimal"]["output"]>;
  changesPercentage?: Maybe<Scalars["Decimal"]["output"]>;
  eventTime?: Maybe<Scalars["DateTime"]["output"]>;
  marginRequirement?: Maybe<MarginRequirement>;
  name?: Maybe<Scalars["String"]["output"]>;
  open?: Maybe<Scalars["Decimal"]["output"]>;
  previousClose?: Maybe<Scalars["Decimal"]["output"]>;
  price: Scalars["Decimal"]["output"];
  symbol: Scalars["String"]["output"];
  volume?: Maybe<Scalars["Decimal"]["output"]>;
};

export enum StockTransferServiceType {
  Acats = "ACATS",
  Dtc = "DTC",
}

export type SubAccount = {
  __typename?: "SubAccount";
  directIndexAccountMetadata?: Maybe<DirectIndexAccountMetadata>;
  eventTime: Scalars["DateTime"]["output"];
  id: Scalars["ID"]["output"];
  pricing?: Maybe<Array<SubAccountPricing>>;
  type: SubAccountType;
};

export enum SubAccountAssetType {
  Cash = "CASH",
  Security = "SECURITY",
}

export type SubAccountPortfolioAggregate = {
  __typename?: "SubAccountPortfolioAggregate";
  aggregates: PortfolioAggregateValues;
  subAccount?: Maybe<SubAccount>;
  subAccountId: Scalars["ID"]["output"];
};

export type SubAccountPortfolioAggregatesInput = {
  availability: AvailabilityLevel;
  clearingAccountId: Scalars["ID"]["input"];
};

export type SubAccountPricing = {
  __typename?: "SubAccountPricing";
  amount: Scalars["Decimal"]["output"];
  id: Scalars["ID"]["output"];
  unit: SubAccountPricingUnit;
  validFrom?: Maybe<Scalars["DateTime"]["output"]>;
  validTo?: Maybe<Scalars["DateTime"]["output"]>;
};

export enum SubAccountPricingUnit {
  BasisPoints = "BASIS_POINTS",
  Percentage = "PERCENTAGE",
}

export type SubAccountTransferValue = {
  __typename?: "SubAccountTransferValue";
  assetType: SubAccountAssetType;
  date: Scalars["DateTime"]["output"];
  value: Scalars["Decimal"]["output"];
};

export type SubAccountTransferValueInput = {
  endDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  startDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  subAccountId: Scalars["ID"]["input"];
};

export enum SubAccountType {
  DirectIndex = "DIRECT_INDEX",
  Primary = "PRIMARY",
  Treasury = "TREASURY",
}

export type SuggestCollateralInput = {
  currentHoldings: Array<HoldingInput>;
  currentLoanAmount: Scalars["Decimal"]["input"];
  externalAccounts: Array<ExternalAccountHoldingsInput>;
  fixedMarginRequirement?: InputMaybe<Scalars["Decimal"]["input"]>;
  targetLoanAmount: Scalars["Decimal"]["input"];
};

export enum SweepInstruction {
  NoSweep = "NO_SWEEP",
  Sweep = "SWEEP",
}

export type SymbolAlias = {
  __typename?: "SymbolAlias";
  context: Array<Maybe<SecurityAliasContext>>;
  symbol: Scalars["String"]["output"];
};

export type SymbolSearchInput = {
  securitySubTypes?: InputMaybe<Array<SecuritySubType>>;
  securityTypes?: InputMaybe<Array<SecurityType>>;
};

export enum TaxFilingStatus {
  Married = "Married",
  Single = "Single",
}

export enum TaxIdType {
  Ein = "EIN",
  Ssn = "SSN",
}

export type TaxLotEntry = {
  __typename?: "TaxLotEntry";
  buyBackIndicator: Scalars["String"]["output"];
  clearingAccountId: Scalars["ID"]["output"];
  closedLotId: Scalars["String"]["output"];
  cost: Scalars["Decimal"]["output"];
  eventTime: Scalars["DateTime"]["output"];
  id?: Maybe<Scalars["ID"]["output"]>;
  longTermShortTermIndicator: Scalars["String"]["output"];
  netProceed: Scalars["Decimal"]["output"];
  openBuyCostAmount: Scalars["Decimal"]["output"];
  openBuyPrice: Scalars["Decimal"]["output"];
  openLotId: Scalars["String"]["output"];
  quantity: Scalars["Decimal"]["output"];
  realizedGainLoss: Scalars["Decimal"]["output"];
  realizedIndicator: Scalars["String"]["output"];
  securityId: Scalars["ID"]["output"];
  settlementDate?: Maybe<Scalars["Date"]["output"]>;
  subAccountId: Scalars["ID"]["output"];
  symbol: Scalars["String"]["output"];
  taxLotCloseSellDate?: Maybe<Scalars["Date"]["output"]>;
  taxLotOpenBuyDate?: Maybe<Scalars["Date"]["output"]>;
  washSaleAdjustmentAmount: Scalars["Decimal"]["output"];
  washSalesDisallowed: Scalars["Decimal"]["output"];
  washSalesIndicator: Scalars["String"]["output"];
};

export type TaxLotInput = {
  asOfDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  fromDate?: InputMaybe<Scalars["Date"]["input"]>;
  realizedIndicator?: InputMaybe<RealizedIndicatorEnumGql>;
  subAccountId?: InputMaybe<Scalars["ID"]["input"]>;
  toDate?: InputMaybe<Scalars["Date"]["input"]>;
};

export type TaxLotSimulation = {
  __typename?: "TaxLotSimulation";
  symbol: Scalars["String"]["output"];
};

export enum TaxLotTransactionSource {
  Acats = "ACATS",
  IntraAccount = "INTRA_ACCOUNT",
  Order = "ORDER",
  StockAdjustment = "STOCK_ADJUSTMENT",
}

export enum TimeHorizon {
  Average = "AVERAGE",
  Longest = "LONGEST",
  Short = "SHORT",
  Unspecified = "UNSPECIFIED",
}

export enum TimeInterval {
  Hour = "HOUR",
  Minute = "MINUTE",
  Second = "SECOND",
}

export type TradeExecution = {
  __typename?: "TradeExecution";
  batchOrderMetadataId?: Maybe<Scalars["ID"]["output"]>;
  cashLeftover?: Maybe<Scalars["Decimal"]["output"]>;
  clearingAccount?: Maybe<ClearingAccount>;
  eventTime: Scalars["DateTime"]["output"];
  id: Scalars["ID"]["output"];
  liquidateAmount?: Maybe<Scalars["Decimal"]["output"]>;
  metadata: Array<DirectIndexTradeExecutionMetadata>;
  status: TradeExecutionStatus;
  statusHistory?: Maybe<Array<DirectIndexTradeExecutionStatusHistory>>;
  subAccountId: Scalars["ID"]["output"];
  type?: Maybe<TradeExecutionType>;
};

export type TradeExecutionOrders = {
  __typename?: "TradeExecutionOrders";
  /** @deprecated Field no longer supported */
  isLongTerm?: Maybe<Scalars["Boolean"]["output"]>;
  longTermGain?: Maybe<Scalars["Decimal"]["output"]>;
  longTermLoss?: Maybe<Scalars["Decimal"]["output"]>;
  notional: Scalars["Decimal"]["output"];
  orderSide: OrderSide;
  quantity: Scalars["Decimal"]["output"];
  /** @deprecated Field no longer supported */
  realizedGainLoss?: Maybe<Scalars["Decimal"]["output"]>;
  security?: Maybe<Security>;
  securityId: Scalars["ID"]["output"];
  shortTermGain?: Maybe<Scalars["Decimal"]["output"]>;
  shortTermLoss?: Maybe<Scalars["Decimal"]["output"]>;
};

export enum TradeExecutionStatus {
  AutoApproved = "AUTO_APPROVED",
  Canceled = "CANCELED",
  Completed = "COMPLETED",
  Error = "ERROR",
  Failed = "FAILED",
  Invalidated = "INVALIDATED",
  NoOp = "NO_OP",
  OpsApproved = "OPS_APPROVED",
  OpsRejected = "OPS_REJECTED",
  OpsReview = "OPS_REVIEW",
  PartiallyCompleted = "PARTIALLY_COMPLETED",
  PreProcessPending = "PRE_PROCESS_PENDING",
  Queued = "QUEUED",
  UserInitiated = "USER_INITIATED",
}

export enum TradeExecutionType {
  DiRun = "DI_RUN",
  SelfManagedAutoTlh = "SELF_MANAGED_AUTO_TLH",
}

export enum TransactionType {
  Credit = "CREDIT",
  Debit = "DEBIT",
}

export enum TransferScheduleType {
  Custom = "CUSTOM",
  LastDayOfMonth = "LAST_DAY_OF_MONTH",
}

export type TransferStockFromDiInput = {
  clearingAccountId: Scalars["String"]["input"];
  excludeFromDi: Scalars["Boolean"]["input"];
  fromSubAccountId: Scalars["String"]["input"];
  symbol: Scalars["String"]["input"];
  toSubAccountId: Scalars["String"]["input"];
};

export type TransferStockSubAccountInput = {
  clearingAccountId: Scalars["String"]["input"];
  fromSubAccountId: Scalars["String"]["input"];
  lotsToTransferBySymbol: Array<LotsToTransferBySymbolInput>;
  toSubAccountId: Scalars["String"]["input"];
};

export type TreasuryConfig = {
  __typename?: "TreasuryConfig";
  clearingAccount?: Maybe<ClearingAccount>;
  clearingAccountId: Scalars["ID"]["output"];
  eventTime: Scalars["DateTime"]["output"];
  subAccountId: Scalars["ID"]["output"];
};

export type TreasuryConfigInput = {
  clearingAccountId: Scalars["ID"]["input"];
};

export enum TreasuryConfigStatus {
  Active = "ACTIVE",
  Paused = "PAUSED",
}

export type TreasuryConfigStatusHistory = {
  __typename?: "TreasuryConfigStatusHistory";
  eventTime: Scalars["DateTime"]["output"];
  id: Scalars["ID"]["output"];
  reason?: Maybe<Scalars["String"]["output"]>;
  status: TreasuryConfigStatus;
  treasuryConfigId: Scalars["ID"]["output"];
};

export type TreasuryExecution = {
  __typename?: "TreasuryExecution";
  amount?: Maybe<Scalars["Decimal"]["output"]>;
  cashTransfer?: Maybe<TreasuryExecutionCashTransfer>;
  direction: TreasuryExecutionDirection;
  eventTime: Scalars["DateTime"]["output"];
  id: Scalars["ID"]["output"];
  moneyMovementMethod: TreasuryExecutionMoneyMovementMethod;
  order?: Maybe<TreasuryExecutionOrder>;
  status: TreasuryExecutionStatus;
  treasuryConfigId: Scalars["ID"]["output"];
};

export type TreasuryExecutionCashTransfer = {
  __typename?: "TreasuryExecutionCashTransfer";
  cashTransferId: Scalars["ID"]["output"];
  eventTime: Scalars["DateTime"]["output"];
  id: Scalars["ID"]["output"];
  treasuryExecutionId: Scalars["ID"]["output"];
};

export enum TreasuryExecutionDirection {
  Deposit = "DEPOSIT",
  Withdrawal = "WITHDRAWAL",
}

export enum TreasuryExecutionInitiationType {
  Automated = "AUTOMATED",
  CashSweep = "CASH_SWEEP",
  FeeJobInitiated = "FEE_JOB_INITIATED",
  OpsInitiated = "OPS_INITIATED",
  ScheduledDeposit = "SCHEDULED_DEPOSIT",
  UserInitiated = "USER_INITIATED",
}

export enum TreasuryExecutionMoneyMovementMethod {
  Ach = "ACH",
  FeeCharge = "FEE_CHARGE",
  /** @deprecated Use INTRA_ACCOUNT instead */
  FrecCash = "FREC_CASH",
  IntraAccount = "INTRA_ACCOUNT",
  Wire = "WIRE",
}

export type TreasuryExecutionOrder = {
  __typename?: "TreasuryExecutionOrder";
  batchOrderMetadataId: Scalars["ID"]["output"];
  eventTime: Scalars["DateTime"]["output"];
  id: Scalars["ID"]["output"];
  treasuryExecutionId: Scalars["ID"]["output"];
};

export enum TreasuryExecutionStatus {
  BuyOrderCompleted = "BUY_ORDER_COMPLETED",
  BuyOrderInitiated = "BUY_ORDER_INITIATED",
  Canceled = "CANCELED",
  CanceledByOps = "CANCELED_BY_OPS",
  Completed = "COMPLETED",
  DepositCompleted = "DEPOSIT_COMPLETED",
  DepositInitiated = "DEPOSIT_INITIATED",
  DepositRequested = "DEPOSIT_REQUESTED",
  Failure = "FAILURE",
  FeeChargeCompleted = "FEE_CHARGE_COMPLETED",
  FeeChargeInitiated = "FEE_CHARGE_INITIATED",
  FeeChargeRejected = "FEE_CHARGE_REJECTED",
  Initiated = "INITIATED",
  NoOp = "NO_OP",
  SellOrderCompleted = "SELL_ORDER_COMPLETED",
  SellOrderInitiated = "SELL_ORDER_INITIATED",
  SellOrderRequested = "SELL_ORDER_REQUESTED",
  WithdrawalCompleted = "WITHDRAWAL_COMPLETED",
  WithdrawalInitiated = "WITHDRAWAL_INITIATED",
}

export type TreasuryFee = {
  __typename?: "TreasuryFee";
  amount: Scalars["Decimal"]["output"];
  eventTime: Scalars["DateTime"]["output"];
  feeRate: Scalars["Decimal"]["output"];
  id: Scalars["ID"]["output"];
  status: TreasuryFeeStatus;
};

export enum TreasuryFeeBilledTo {
  FrecCash = "FREC_CASH",
  Loan = "LOAN",
  TreasuryAssets = "TREASURY_ASSETS",
}

export enum TreasuryFeeStatus {
  Accumulating = "ACCUMULATING",
  Canceled = "CANCELED",
  Completed = "COMPLETED",
  FundsPosted = "FUNDS_POSTED",
  Initiated = "INITIATED",
  Invalidated = "INVALIDATED",
  Pending = "PENDING",
  Rejected = "REJECTED",
  Returned = "RETURNED",
}

export type TreasuryRewardType = {
  __typename?: "TreasuryRewardType";
  basisPoints: Scalars["Decimal"]["output"];
};

export type TreasuryTransferInput = {
  amount: Scalars["Decimal"]["input"];
  clearingAccountId: Scalars["ID"]["input"];
  depositAccountId?: InputMaybe<Scalars["ID"]["input"]>;
  direction?: InputMaybe<TreasuryExecutionDirection>;
  initiationType: TreasuryExecutionInitiationType;
  moneyMovementMethod?: InputMaybe<TreasuryExecutionMoneyMovementMethod>;
  status?: InputMaybe<TreasuryExecutionStatus>;
  transferType: TreasuryTransferType;
};

export enum TreasuryTransferType {
  DepositFromBank = "DEPOSIT_FROM_BANK",
  DepositFromFrecCash = "DEPOSIT_FROM_FREC_CASH",
  DepositFromWire = "DEPOSIT_FROM_WIRE",
  FeeCharge = "FEE_CHARGE",
  WithdrawalToBank = "WITHDRAWAL_TO_BANK",
  WithdrawalToFrecCash = "WITHDRAWAL_TO_FREC_CASH",
}

export type TreasuryYield = {
  __typename?: "TreasuryYield";
  allTimeNetYieldUSD?: Maybe<Scalars["Decimal"]["output"]>;
  annualFeePercentage: Scalars["Decimal"]["output"];
  blendedSevenDayYieldPercentage?: Maybe<Scalars["Decimal"]["output"]>;
  lastMonthNetYieldUSD?: Maybe<Scalars["Decimal"]["output"]>;
  maxSevenDayYieldPercentage: Scalars["Decimal"]["output"];
  supportedFundSymbols: Array<Scalars["String"]["output"]>;
  ytdNetYieldUSD?: Maybe<Scalars["Decimal"]["output"]>;
};

export type TrustEntity = {
  __typename?: "TrustEntity";
  additionalTrustees: Array<Trustee>;
  address?: Maybe<GenericAddress>;
  approvers: TrustEntityApprovers;
  entityDetails: TrustEntityDetails;
  id: Scalars["ID"]["output"];
  primaryTrustee?: Maybe<User>;
  primaryTrusteeId: Scalars["ID"]["output"];
};

export type TrustEntityApprovers = {
  __typename?: "TrustEntityApprovers";
  principalApprover: Scalars["String"]["output"];
  registeredRepresentativeApprover: Scalars["String"]["output"];
};

export type TrustEntityDetails = {
  __typename?: "TrustEntityDetails";
  address?: Maybe<GenericAddress>;
  dateOfCreation: Scalars["Date"]["output"];
  id: Scalars["ID"]["output"];
  name: Scalars["String"]["output"];
  revocability?: Maybe<TrustRevocability>;
  state: Scalars["String"]["output"];
  taxId: Scalars["String"]["output"];
  taxIdType?: Maybe<TaxIdType>;
  trustAgreementAllowsMarginsAndShortPositions?: Maybe<
    Scalars["Boolean"]["output"]
  >;
  trustAgreementAllowsOptions?: Maybe<Scalars["Boolean"]["output"]>;
};

export type TrustEntityIndividualInput = {
  address: GenericAddressInput;
  affiliatedExchangeOrFINRA: Scalars["Boolean"]["input"];
  companySymbols?: InputMaybe<Array<Scalars["String"]["input"]>>;
  controlPerson: Scalars["Boolean"]["input"];
  dateOfBirth: Scalars["Date"]["input"];
  familyName: Scalars["String"]["input"];
  firmName?: InputMaybe<Scalars["String"]["input"]>;
  givenName: Scalars["String"]["input"];
  governmentIdNumber: Scalars["String"]["input"];
  idExpirationDate: Scalars["Date"]["input"];
  idIssuingStateOrCountry: Scalars["String"]["input"];
  idType: TrusteeIdType;
  immediateFamilyMembers?: InputMaybe<Array<Scalars["String"]["input"]>>;
  politicalOrganization?: InputMaybe<Scalars["String"]["input"]>;
  politicalTitle?: InputMaybe<Scalars["String"]["input"]>;
  politicallyExposed: Scalars["Boolean"]["input"];
  ssn: Scalars["String"]["input"];
};

export enum TrustRevocability {
  Irrevocable = "IRREVOCABLE",
  Revocable = "REVOCABLE",
}

export type Trustee = {
  __typename?: "Trustee";
  address: GenericAddress;
  dateOfBirth: Scalars["Date"]["output"];
  disclosure?: Maybe<IndividualDisclosure>;
  familyName?: Maybe<Scalars["String"]["output"]>;
  givenName?: Maybe<Scalars["String"]["output"]>;
  governmentIdNumber?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["ID"]["output"];
  idExpirationDate?: Maybe<Scalars["Date"]["output"]>;
  idIssuingStateOrCountry?: Maybe<Scalars["String"]["output"]>;
  idType?: Maybe<TrusteeIdType>;
  name: Scalars["String"]["output"];
};

export enum TrusteeIdType {
  DriverLicense = "DRIVER_LICENSE",
  Passport = "PASSPORT",
  StateId = "STATE_ID",
}

export type UpdateClearingHouseAccountStateResponse = {
  __typename?: "UpdateClearingHouseAccountStateResponse";
  status: Scalars["String"]["output"];
};

export type UpdateUserEntityAccountRequestStatusInput = {
  state: EntityAccountRequestStatusState;
};

export type UpdateUserProfileInput = {
  contactEmail?: InputMaybe<UserContactEmailInput>;
  contactEmailId?: InputMaybe<Scalars["ID"]["input"]>;
  contactPhone?: InputMaybe<UserContactPhoneInput>;
  contactPhoneId?: InputMaybe<Scalars["ID"]["input"]>;
  homeAddress?: InputMaybe<GenericAddressInput>;
  homeAddressId?: InputMaybe<Scalars["ID"]["input"]>;
  investmentProfile?: InputMaybe<UserInvestmentProfileInput>;
  investmentProfileId?: InputMaybe<Scalars["ID"]["input"]>;
  serviceProfile?: InputMaybe<UserServiceProfileInput>;
  serviceProfileId?: InputMaybe<Scalars["ID"]["input"]>;
};

export type UpdateUserProfileResponse = {
  __typename?: "UpdateUserProfileResponse";
  requestQueued: Scalars["Boolean"]["output"];
  user?: Maybe<User>;
};

export enum UploadDocumentForward {
  Apex = "APEX",
}

export type UploadDocumentInput = {
  clearingAccountId?: InputMaybe<Scalars["String"]["input"]>;
  contentType?: InputMaybe<Scalars["String"]["input"]>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  document: Scalars["String"]["input"];
  name: Scalars["String"]["input"];
  type: UploadDocumentType;
};

export enum UploadDocumentScanResult {
  Clean = "CLEAN",
  NotSafe = "NOT_SAFE",
  NotScanned = "NOT_SCANNED",
  Unknown = "UNKNOWN",
}

export enum UploadDocumentSource {
  Apex = "APEX",
  Customer = "CUSTOMER",
  Ops = "OPS",
}

export enum UploadDocumentType {
  AccountTransferForm = "ACCOUNT_TRANSFER_FORM",
  AffiliatedApproval = "AFFILIATED_APPROVAL",
  ArticlesOfIncorporation = "ARTICLES_OF_INCORPORATION",
  CertificateOfTrust = "CERTIFICATE_OF_TRUST",
  CipInvestigationAddress = "CIP_INVESTIGATION_ADDRESS",
  CipInvestigationDob = "CIP_INVESTIGATION_DOB",
  CipInvestigationName = "CIP_INVESTIGATION_NAME",
  CipInvestigationTaxId = "CIP_INVESTIGATION_TAX_ID",
  CorporationAccountForm = "CORPORATION_ACCOUNT_FORM",
  DepositExplanation = "DEPOSIT_EXPLANATION",
  DriversLicense = "DRIVERS_LICENSE",
  EntityFormationDocument = "ENTITY_FORMATION_DOCUMENT",
  GeneralPartnersAgreement = "GENERAL_PARTNERS_AGREEMENT",
  IdentificationCard = "IDENTIFICATION_CARD",
  IraDepositSlip = "IRA_DEPOSIT_SLIP",
  LimitedMarginAgreement = "LIMITED_MARGIN_AGREEMENT",
  ManagersAgreement = "MANAGERS_AGREEMENT",
  Other = "OTHER",
  PartnershipAgreement = "PARTNERSHIP_AGREEMENT",
  Passport = "PASSPORT",
  SignatureImage = "SIGNATURE_IMAGE",
  SocialSecurityCard = "SOCIAL_SECURITY_CARD",
  TransferOnDeathAgreement = "TRANSFER_ON_DEATH_AGREEMENT",
  W9Form = "W9_FORM",
}

export enum UploadDocumentVerificationStatus {
  ApexVerified = "APEX_VERIFIED",
  NotVerified = "NOT_VERIFIED",
  OpsVerified = "OPS_VERIFIED",
}

export enum UploadSinkType {
  S3 = "S3",
}

export type UploadedDocument = {
  __typename?: "UploadedDocument";
  clearingAccountId?: Maybe<Scalars["String"]["output"]>;
  deleted: Scalars["Boolean"]["output"];
  description?: Maybe<Scalars["String"]["output"]>;
  forwardTo?: Maybe<UploadDocumentForward>;
  id: Scalars["ID"]["output"];
  location: Scalars["String"]["output"];
  name: Scalars["String"]["output"];
  originalName: Scalars["String"]["output"];
  scanResult: UploadDocumentScanResult;
  source: UploadDocumentSource;
  type: UploadDocumentType;
  userId: Scalars["String"]["output"];
  verificationStatus: UploadDocumentVerificationStatus;
};

export type UpsertBusinessEntityIndividualInput = {
  address: GenericAddressInput;
  affiliatedExchangeOrFINRA: Scalars["Boolean"]["input"];
  companySymbols?: InputMaybe<Array<Scalars["String"]["input"]>>;
  controlPerson: Scalars["Boolean"]["input"];
  dateOfBirth: Scalars["Date"]["input"];
  entityId: Scalars["ID"]["input"];
  familyName: Scalars["String"]["input"];
  firmName: Scalars["String"]["input"];
  givenName: Scalars["String"]["input"];
  immediateFamilyMembers?: InputMaybe<Array<Scalars["String"]["input"]>>;
  individualType: BusinessEntityIndividualType;
  politicalOrganization?: InputMaybe<Scalars["String"]["input"]>;
  politicalTitle?: InputMaybe<Scalars["String"]["input"]>;
  politicallyExposed: Scalars["Boolean"]["input"];
  ssn: Scalars["String"]["input"];
};

export type UpsertBusinessEntityInput = {
  address: GenericAddressInput;
  bankingInstitutionNames?: InputMaybe<Array<Scalars["String"]["input"]>>;
  businessScope: BusinessEntityScope;
  corporationSecretaryName?: InputMaybe<Scalars["String"]["input"]>;
  entityType: BusinessEntityType;
  faxNumber?: InputMaybe<Scalars["String"]["input"]>;
  foreignBankUSAgent?: InputMaybe<Scalars["String"]["input"]>;
  industry: BusinessEntityIndustry;
  largeTraderId?: InputMaybe<Scalars["String"]["input"]>;
  mailingAddress: GenericAddressInput;
  multiplePartnersOrManagers?: InputMaybe<Scalars["Boolean"]["input"]>;
  name: Scalars["String"]["input"];
  phoneNumber: Scalars["String"]["input"];
  primaryFundingSource: BusinessEntityFundingSource;
  stateOfIncorporation: Scalars["String"]["input"];
  taxId: Scalars["String"]["input"];
  userTitle: Scalars["String"]["input"];
};

export type UpsertBusinessEntityInvestmentProfileInput = {
  annualIncomeMax: Scalars["Int"]["input"];
  annualIncomeMin: Scalars["Int"]["input"];
  depositSource: Scalars["String"]["input"];
  initialDepositAmount: Scalars["Int"]["input"];
  investmentExperience: InvestmentExperience;
  investmentObjective: BusinessEntityInvestmentObjective;
  investmentPlan: BusinessEntityInvestmentPlan;
  liquidityNeeds: LiquidityNeeds;
  liquidityTimeHorizon: TimeHorizon;
  netWorthMax: Scalars["Int"]["input"];
  netWorthMin: Scalars["Int"]["input"];
  riskTolerance: RiskTolerance;
  withdrawFrequency: BusinessEntityWithdrawFrequency;
};

export type UpsertTrustEntityIndividualInput = {
  entityId: Scalars["ID"]["input"];
  upsertTrustEntityIndividualInput: Array<TrustEntityIndividualInput>;
};

export type UpsertTrustEntityInput = {
  address?: InputMaybe<GenericAddressInput>;
  name: Scalars["String"]["input"];
  revocability: TrustRevocability;
  taxId: Scalars["String"]["input"];
  taxIdType: TaxIdType;
  trustAgreementAllowsMarginsAndShortPositions: Scalars["Boolean"]["input"];
  trustAgreementAllowsOptions?: InputMaybe<Scalars["Boolean"]["input"]>;
  trustDateOfCreation: Scalars["Date"]["input"];
  trustState: Scalars["String"]["input"];
};

export type User = {
  __typename?: "User";
  clearingAccounts?: Maybe<Array<ClearingAccount>>;
  entityAccountRequestStatus?: Maybe<EntityAccountRequestStatus>;
  eventTime?: Maybe<Scalars["DateTime"]["output"]>;
  id: Scalars["ID"]["output"];
  inviteCodeApplication?: Maybe<InviteCodeApplication>;
  inviteCodes?: Maybe<Array<Scalars["String"]["output"]>>;
  isEmployee: Scalars["Boolean"]["output"];
  linkedBrokerageAccounts?: Maybe<Array<LinkedBrokerageAccount>>;
  oidcConnections?: Maybe<Array<OidcGrantMetadata>>;
  plaidAccounts?: Maybe<Array<PlaidAccount>>;
  plaidConnections?: Maybe<Array<PlaidConnection>>;
  referralPromo?: Maybe<UserReferralPromotion>;
  uploadedDocuments?: Maybe<Array<UploadedDocument>>;
  userAddresses?: Maybe<Array<GenericAddress>>;
  userContactEmail?: Maybe<UserContactEmail>;
  userContactPhone?: Maybe<UserContactPhone>;
  userDisclosures?: Maybe<UserDisclosures>;
  userEmploymentDetails?: Maybe<Array<UserEmploymentDetails>>;
  userIdentityDetails?: Maybe<UserIdentityDetails>;
  userInvestmentProfile?: Maybe<UserInvestmentProfile>;
  userMfaPreferences?: Maybe<Array<UserMfaPreference>>;
  userMfaStatus?: Maybe<UserMfaStatus>;
  userNotifications?: Maybe<Array<UserNotification>>;
  userPersonalDetails?: Maybe<UserPersonalDetails>;
  userProgressFlags?: Maybe<Array<Maybe<UserProgressFlag>>>;
  userServiceProfile?: Maybe<UserServiceProfile>;
  userSessions?: Maybe<Array<UserSession>>;
  userSource?: Maybe<UserSource>;
  userTaxRates?: Maybe<UserTaxRates>;
  userTrustedContact?: Maybe<UserTrustedContact>;
};

export type UserLinkedBrokerageAccountsArgs = {
  input?: InputMaybe<LinkedBrokerageAccountsInput>;
};

export type UserPlaidAccountsArgs = {
  input?: InputMaybe<PlaidAccountsInput>;
};

export type UserUserNotificationsArgs = {
  channel?: InputMaybe<NotificationChannel>;
};

export type UserContactEmail = {
  __typename?: "UserContactEmail";
  email: Scalars["String"]["output"];
  emailHash?: Maybe<Scalars["String"]["output"]>;
  emailType: EmailType;
  id: Scalars["ID"]["output"];
  verified: Scalars["Boolean"]["output"];
};

export type UserContactEmailInput = {
  email: Scalars["String"]["input"];
  emailType: EmailType;
};

export type UserContactPhone = {
  __typename?: "UserContactPhone";
  id: Scalars["ID"]["output"];
  phoneNumber: Scalars["String"]["output"];
  phoneNumberType: PhoneNumberType;
  phoneNumberVerified: Scalars["Boolean"]["output"];
};

export type UserContactPhoneInput = {
  phoneNumber: Scalars["String"]["input"];
  phoneNumberType: PhoneNumberType;
};

export type UserDisclosures = {
  __typename?: "UserDisclosures";
  affiliatedExchangeOrFINRA: Scalars["Boolean"]["output"];
  affiliatedExchangeOrFINRAEntityName?: Maybe<Scalars["String"]["output"]>;
  companySymbols?: Maybe<Scalars["String"]["output"]>;
  controlPerson: Scalars["Boolean"]["output"];
  firmName?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["ID"]["output"];
  immediateFamily?: Maybe<Scalars["String"]["output"]>;
  politicalOrganization?: Maybe<Scalars["String"]["output"]>;
  politicallyExposed: Scalars["Boolean"]["output"];
};

export type UserDisclosuresInput = {
  affiliatedExchangeOrFINRA: Scalars["Boolean"]["input"];
  affiliatedExchangeOrFINRAEntityName?: InputMaybe<Scalars["String"]["input"]>;
  companySymbols?: InputMaybe<Scalars["String"]["input"]>;
  controlPerson: Scalars["Boolean"]["input"];
  firmName?: InputMaybe<Scalars["String"]["input"]>;
  immediateFamily?: InputMaybe<Scalars["String"]["input"]>;
  politicalOrganization?: InputMaybe<Scalars["String"]["input"]>;
  politicallyExposed: Scalars["Boolean"]["input"];
};

export type UserEmploymentDetails = {
  __typename?: "UserEmploymentDetails";
  addressLine1?: Maybe<Scalars["String"]["output"]>;
  addressLine2?: Maybe<Scalars["String"]["output"]>;
  city?: Maybe<Scalars["String"]["output"]>;
  country?: Maybe<Scalars["String"]["output"]>;
  employerName?: Maybe<Scalars["String"]["output"]>;
  employmentStatus: EmploymentStatus;
  id: Scalars["ID"]["output"];
  occupation?: Maybe<Scalars["String"]["output"]>;
  state?: Maybe<Scalars["String"]["output"]>;
  yearsEmployed?: Maybe<Scalars["Float"]["output"]>;
  zipCode?: Maybe<Scalars["String"]["output"]>;
};

export type UserEmploymentDetailsInput = {
  addressLine1?: InputMaybe<Scalars["String"]["input"]>;
  addressLine2?: InputMaybe<Scalars["String"]["input"]>;
  city?: InputMaybe<Scalars["String"]["input"]>;
  country?: InputMaybe<Scalars["String"]["input"]>;
  employerName?: InputMaybe<Scalars["String"]["input"]>;
  employmentStatus: EmploymentStatus;
  occupation?: InputMaybe<Scalars["String"]["input"]>;
  state?: InputMaybe<Scalars["String"]["input"]>;
  yearsEmployed?: InputMaybe<Scalars["Float"]["input"]>;
  zipCode?: InputMaybe<Scalars["String"]["input"]>;
};

export type UserIdentityDetails = {
  __typename?: "UserIdentityDetails";
  citizenshipCountry: Scalars["String"]["output"];
  dateOfBirth: Scalars["Date"]["output"];
  governmentIdNumber?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["ID"]["output"];
  idExpirationDate?: Maybe<Scalars["Date"]["output"]>;
  idIssuingStateOrCountry?: Maybe<Scalars["String"]["output"]>;
  idType?: Maybe<TrusteeIdType>;
  maritalStatus?: Maybe<MaritalStatus>;
  numberOfDependents?: Maybe<Scalars["Int"]["output"]>;
  permanentResident?: Maybe<Scalars["Boolean"]["output"]>;
  ssn: Scalars["String"]["output"];
  visaExpiration?: Maybe<Scalars["Date"]["output"]>;
  visaStatus?: Maybe<VisaType>;
};

export type UserIdentityDetailsInput = {
  accountType: FrecAccountType;
  citizenshipCountry: Scalars["String"]["input"];
  dateOfBirth: Scalars["Date"]["input"];
  governmentIdNumber?: InputMaybe<Scalars["String"]["input"]>;
  idExpirationDate?: InputMaybe<Scalars["Date"]["input"]>;
  idIssuingStateOrCountry?: InputMaybe<Scalars["String"]["input"]>;
  idType?: InputMaybe<TrusteeIdType>;
  maritalStatus?: InputMaybe<MaritalStatus>;
  numberOfDependents?: InputMaybe<Scalars["Int"]["input"]>;
  permanentResident?: InputMaybe<Scalars["Boolean"]["input"]>;
  ssn: Scalars["String"]["input"];
  visaExpiration?: InputMaybe<Scalars["Date"]["input"]>;
  visaStatus?: InputMaybe<VisaType>;
};

export type UserInvestmentProfile = {
  __typename?: "UserInvestmentProfile";
  annualIncomeMax: Scalars["Int"]["output"];
  annualIncomeMin: Scalars["Int"]["output"];
  id: Scalars["ID"]["output"];
  investmentExperience: InvestmentExperience;
  investmentObjective: InvestmentObjective;
  liquidityNeeds: LiquidityNeeds;
  liquidityTimeHorizon: TimeHorizon;
  netWorthMax: Scalars["Int"]["output"];
  netWorthMin: Scalars["Int"]["output"];
  riskTolerance: RiskTolerance;
};

export type UserInvestmentProfileInput = {
  annualIncomeMax: Scalars["Int"]["input"];
  annualIncomeMin: Scalars["Int"]["input"];
  investmentExperience: InvestmentExperience;
  investmentObjective: InvestmentObjective;
  liquidityNeeds: LiquidityNeeds;
  liquidityTimeHorizon: TimeHorizon;
  netWorthMax: Scalars["Int"]["input"];
  netWorthMin: Scalars["Int"]["input"];
  riskTolerance: RiskTolerance;
};

export type UserInvestorDocument = {
  __typename?: "UserInvestorDocument";
  category: Scalars["String"]["output"];
  date: Scalars["String"]["output"];
  documentType: Scalars["String"]["output"];
  name: Scalars["String"]["output"];
  url: Scalars["String"]["output"];
};

export type UserMfa = {
  __typename?: "UserMFA";
  id: Scalars["ID"]["output"];
  phoneLast4?: Maybe<Scalars["String"]["output"]>;
  userMfaPreferences?: Maybe<Array<UserMfaPreference>>;
  userMfaStatus?: Maybe<UserMfaStatus>;
};

export type UserMfaPreference = {
  __typename?: "UserMfaPreference";
  eventTime: Scalars["DateTime"]["output"];
  id: Scalars["ID"]["output"];
  type: MfaType;
};

export type UserMfaPreferenceInput = {
  type: MfaType;
};

export type UserMfaPreferenceWithQrCode = {
  __typename?: "UserMfaPreferenceWithQrCode";
  qrCode?: Maybe<Scalars["String"]["output"]>;
  setupKey?: Maybe<Scalars["String"]["output"]>;
  userMfaPreference: UserMfaPreference;
};

export type UserMfaStatus = {
  __typename?: "UserMfaStatus";
  isActive: Scalars["Boolean"]["output"];
  lastLoginTime?: Maybe<Scalars["DateTime"]["output"]>;
  lastMfaChallengeTime?: Maybe<Scalars["DateTime"]["output"]>;
};

export type UserNotification = {
  __typename?: "UserNotification";
  category: NotificationCategory;
  channel: NotificationChannel;
  content: Scalars["String"]["output"];
  cta?: Maybe<NotificationCta>;
  event: NotificationEvent;
  eventTime: Scalars["DateTime"]["output"];
  id: Scalars["ID"]["output"];
  referenceId?: Maybe<Scalars["String"]["output"]>;
  status?: Maybe<NotificationStatus>;
  subject: Scalars["String"]["output"];
  type: NotificationType;
};

export type UserPersonalDetails = {
  __typename?: "UserPersonalDetails";
  firstName: Scalars["String"]["output"];
  fullName: Scalars["String"]["output"];
  id: Scalars["ID"]["output"];
  lastName: Scalars["String"]["output"];
  middleName?: Maybe<Scalars["String"]["output"]>;
};

export type UserPersonalDetailsInput = {
  firstName: Scalars["String"]["input"];
  lastName: Scalars["String"]["input"];
  middleName?: InputMaybe<Scalars["String"]["input"]>;
};

export type UserProgressFlag = {
  __typename?: "UserProgressFlag";
  category?: Maybe<Scalars["String"]["output"]>;
  eventTime?: Maybe<Scalars["DateTime"]["output"]>;
  id: Scalars["ID"]["output"];
  status?: Maybe<ProgressFlagStatus>;
};

export type UserQaData = {
  __typename?: "UserQaData";
  addressLine1: Scalars["String"]["output"];
  addressType: AddressType;
  affiliatedExchangeOrFINRA: Scalars["Boolean"]["output"];
  annualIncomeMax: Scalars["Float"]["output"];
  annualIncomeMin: Scalars["Float"]["output"];
  citizenshipCountry: Scalars["String"]["output"];
  city: Scalars["String"]["output"];
  controlPerson: Scalars["Boolean"]["output"];
  country: Scalars["String"]["output"];
  dateOfBirth: Scalars["String"]["output"];
  dividendReinvestment: DividendReinvestment;
  email: Scalars["String"]["output"];
  emailType: EmailType;
  employmentStatus: EmploymentStatus;
  firstName: Scalars["String"]["output"];
  id?: Maybe<Scalars["String"]["output"]>;
  investmentExperience: InvestmentExperience;
  investmentObjective: InvestmentObjective;
  isEmployee: Scalars["Boolean"]["output"];
  lastName: Scalars["String"]["output"];
  liquidityNeeds: LiquidityNeeds;
  liquidityTimeHorizon: TimeHorizon;
  middleName: Scalars["String"]["output"];
  netWorthMax: Scalars["Float"]["output"];
  netWorthMin: Scalars["Float"]["output"];
  permanentResident?: Maybe<Scalars["Boolean"]["output"]>;
  phoneNumber: Scalars["String"]["output"];
  phoneNumberType: PhoneNumberType;
  phoneNumberVerified: Scalars["Boolean"]["output"];
  politicallyExposed: Scalars["Boolean"]["output"];
  riskTolerance: RiskTolerance;
  ssn: Scalars["String"]["output"];
  state: Scalars["String"]["output"];
  sweepInstruction: SweepInstruction;
  visaExpiration?: Maybe<Scalars["String"]["output"]>;
  visaStatus?: Maybe<VisaType>;
  zipCode: Scalars["String"]["output"];
};

export type UserQaDataInput = {
  addressLine1: Scalars["String"]["input"];
  addressType: AddressType;
  affiliatedExchangeOrFINRA: Scalars["Boolean"]["input"];
  affiliatedExchangeOrFINRAEntityName: Scalars["String"]["input"];
  annualIncomeMax: Scalars["Float"]["input"];
  annualIncomeMin: Scalars["Float"]["input"];
  citizenshipCountry: Scalars["String"]["input"];
  city: Scalars["String"]["input"];
  controlPerson: Scalars["Boolean"]["input"];
  country: Scalars["String"]["input"];
  dateOfBirth: Scalars["String"]["input"];
  dividendReinvestment: DividendReinvestment;
  email: Scalars["String"]["input"];
  emailType: EmailType;
  employmentStatus: EmploymentStatus;
  firstName: Scalars["String"]["input"];
  id?: InputMaybe<Scalars["String"]["input"]>;
  investmentExperience: InvestmentExperience;
  investmentObjective: InvestmentObjective;
  isEmployee: Scalars["Boolean"]["input"];
  lastName: Scalars["String"]["input"];
  liquidityNeeds: LiquidityNeeds;
  liquidityTimeHorizon: TimeHorizon;
  middleName: Scalars["String"]["input"];
  netWorthMax: Scalars["Float"]["input"];
  netWorthMin: Scalars["Float"]["input"];
  permanentResident?: InputMaybe<Scalars["Boolean"]["input"]>;
  phoneNumber: Scalars["String"]["input"];
  phoneNumberType: PhoneNumberType;
  phoneNumberVerified: Scalars["Boolean"]["input"];
  politicallyExposed: Scalars["Boolean"]["input"];
  riskTolerance: RiskTolerance;
  ssn: Scalars["String"]["input"];
  state: Scalars["String"]["input"];
  sweepInstruction: SweepInstruction;
  visaExpiration?: InputMaybe<Scalars["String"]["input"]>;
  visaStatus?: InputMaybe<VisaType>;
  zipCode: Scalars["String"]["input"];
};

export enum UserReferralDeal {
  DiOneHundredDollarsTreasuryTwentyBps = "DI_ONE_HUNDRED_DOLLARS_TREASURY_TWENTY_BPS",
  DiTwoHundredFiftyDollarsEachTreasuryTwentyBps = "DI_TWO_HUNDRED_FIFTY_DOLLARS_EACH_TREASURY_TWENTY_BPS",
  FrecNewYearPromo_2024 = "FREC_NEW_YEAR_PROMO_2024",
  HamptonMemberDiTwoHundredFiftyDollars = "HAMPTON_MEMBER_DI_TWO_HUNDRED_FIFTY_DOLLARS",
  HnwDiFiveHundredDollars = "HNW_DI_FIVE_HUNDRED_DOLLARS",
  InfluencerDiTwoHundredFiftyDollars = "INFLUENCER_DI_TWO_HUNDRED_FIFTY_DOLLARS",
  LegacySignup = "LEGACY_SIGNUP",
}

export type UserReferralPromotion = {
  __typename?: "UserReferralPromotion";
  code: Scalars["String"]["output"];
  dealType: UserReferralDeal;
};

export type UserServiceProfile = {
  __typename?: "UserServiceProfile";
  dividendReinvestment: DividendReinvestment;
  id: Scalars["ID"]["output"];
  sweepInstruction: SweepInstruction;
};

export type UserServiceProfileInput = {
  dividendReinvestment: DividendReinvestment;
  sweepInstruction: SweepInstruction;
};

export type UserSession = {
  __typename?: "UserSession";
  browserName?: Maybe<Scalars["String"]["output"]>;
  browserVersion?: Maybe<Scalars["String"]["output"]>;
  deviceModel?: Maybe<Scalars["String"]["output"]>;
  deviceVendor?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["ID"]["output"];
  loginTime: Scalars["DateTime"]["output"];
  osName?: Maybe<Scalars["String"]["output"]>;
  osVersion?: Maybe<Scalars["String"]["output"]>;
};

export type UserSource = {
  __typename?: "UserSource";
  utmCampaign?: Maybe<Scalars["String"]["output"]>;
  utmMedium?: Maybe<Scalars["String"]["output"]>;
  utmSource?: Maybe<Scalars["String"]["output"]>;
};

export type UserSourceInput = {
  utmCampaign?: InputMaybe<Scalars["String"]["input"]>;
  utmMedium?: InputMaybe<Scalars["String"]["input"]>;
  utmSource?: InputMaybe<Scalars["String"]["input"]>;
};

export enum UserStatus {
  Active = "ACTIVE",
  Suspended = "SUSPENDED",
}

export type UserTaxRates = {
  __typename?: "UserTaxRates";
  federalCapitalGainsTaxRate: Scalars["Decimal"]["output"];
  federalIncomeTaxRate: Scalars["Decimal"]["output"];
  id: Scalars["ID"]["output"];
  stateCapitalGainsTaxRate: Scalars["Decimal"]["output"];
  stateIncomeTaxRate?: Maybe<Scalars["Decimal"]["output"]>;
  taxFilingStatus?: Maybe<TaxFilingStatus>;
};

export type UserTaxRatesInput = {
  federalCapitalGainsTaxRate: Scalars["Decimal"]["input"];
  federalIncomeTaxRate: Scalars["Decimal"]["input"];
  stateCapitalGainsTaxRate: Scalars["Decimal"]["input"];
  stateIncomeTaxRate?: InputMaybe<Scalars["Decimal"]["input"]>;
  taxFilingStatus?: InputMaybe<TaxFilingStatus>;
};

export type UserTrustedContact = {
  __typename?: "UserTrustedContact";
  addressLine1?: Maybe<Scalars["String"]["output"]>;
  addressLine2?: Maybe<Scalars["String"]["output"]>;
  city?: Maybe<Scalars["String"]["output"]>;
  country?: Maybe<Scalars["String"]["output"]>;
  email: Scalars["String"]["output"];
  firstName: Scalars["String"]["output"];
  id: Scalars["ID"]["output"];
  lastName: Scalars["String"]["output"];
  middleName?: Maybe<Scalars["String"]["output"]>;
  phoneNumber: Scalars["String"]["output"];
  phoneNumberType: PhoneNumberType;
  relationship: Scalars["String"]["output"];
  state?: Maybe<Scalars["String"]["output"]>;
  zipCode?: Maybe<Scalars["String"]["output"]>;
};

export type UserTrustedContactInput = {
  addressLine1?: InputMaybe<Scalars["String"]["input"]>;
  addressLine2?: InputMaybe<Scalars["String"]["input"]>;
  city?: InputMaybe<Scalars["String"]["input"]>;
  country?: InputMaybe<Scalars["String"]["input"]>;
  email: Scalars["String"]["input"];
  firstName: Scalars["String"]["input"];
  lastName: Scalars["String"]["input"];
  middleName?: InputMaybe<Scalars["String"]["input"]>;
  phoneNumber: Scalars["String"]["input"];
  phoneNumberType: PhoneNumberType;
  relationship: Scalars["String"]["input"];
  state?: InputMaybe<Scalars["String"]["input"]>;
  zipCode?: InputMaybe<Scalars["String"]["input"]>;
};

export type UserWithProfile = {
  __typename?: "UserWithProfile";
  addresses: Array<GenericAddress>;
  contactEmail: UserContactEmail;
  contactPhone: UserContactPhone;
  disclosures: UserDisclosures;
  employmentDetails: Array<UserEmploymentDetails>;
  id: Scalars["ID"]["output"];
  identityDetails: UserIdentityDetails;
  investmentProfile: UserInvestmentProfile;
  personalDetails: UserPersonalDetails;
  serviceProfile: UserServiceProfile;
};

export type ValidateMfaPhoneChallengeInput = {
  code: Scalars["String"]["input"];
  deviceId?: InputMaybe<Scalars["String"]["input"]>;
  firstTimeChallenge?: InputMaybe<Scalars["Boolean"]["input"]>;
  type: MfaType;
};

export type ValidateMfaPhoneChallengeResponse = {
  __typename?: "ValidateMfaPhoneChallengeResponse";
  status: Scalars["Boolean"]["output"];
};

export type ValidateSodFilesInputArgs = {
  date?: InputMaybe<Scalars["Date"]["input"]>;
  forceValidate?: InputMaybe<Scalars["Boolean"]["input"]>;
  skipMissing?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export enum VerificationCodeCategory {
  EmailVerification = "EMAIL_VERIFICATION",
  MfaApp = "MFA_APP",
  MfaBackupCode = "MFA_BACKUP_CODE",
  MfaDeviceToken = "MFA_DEVICE_TOKEN",
  MfaPhone = "MFA_PHONE",
}

export type VerificationCodeInput = {
  category: VerificationCodeCategory;
  code: Scalars["String"]["input"];
};

export enum VisaType {
  E1 = "E1",
  E2 = "E2",
  E3 = "E3",
  F1 = "F1",
  G4 = "G4",
  H1B = "H1B",
  L1 = "L1",
  O1 = "O1",
  Other = "OTHER",
  Tn1 = "TN1",
  Unspecified = "UNSPECIFIED",
}

export enum WashSaleIndicatorEnumGql {
  None = "None",
  WashSale = "WashSale",
}

export type AcatsTransferActivityFragment = {
  __typename?: "AcatsTransfer";
  id: string;
  userId: string;
  clearingAccountId: string;
  acatsControlNumber?: string | null;
  associatedAcatsId?: string | null;
  transferType: AcatsTransferType;
  eventTime: Date;
  currentStatus: AcatsTransferStatus;
  direction: AcatsTransferDirection;
  accountOfficialName: string;
  accountInstitutionName: string;
  accountMask?: string | null;
  assets?: Array<{
    __typename?: "AcatsTransferAsset";
    symbol?: string | null;
    quantity?: Decimal | null;
    securityId?: string | null;
    cash?: Decimal | null;
    cashType?: string | null;
  }> | null;
  fee?: Array<{
    __typename?: "AcatsTransferFee";
    id: string;
    type: AcatsTransferFeeType;
    amount: Decimal;
  }> | null;
  statusHistory?: Array<{
    __typename?: "AcatsStatusHistory";
    id: string;
    acatsTransferId: string;
    frecStatus: AcatsTransferStatus;
    eventTime: Date;
    errors?: Array<{
      __typename?: "AcatsError";
      errorCode?: string | null;
      fieldName?: string | null;
      message?: string | null;
      attemptedValue?: string | null;
    }> | null;
  }> | null;
};

export type GenericAddressFragment = {
  __typename?: "GenericAddress";
  id: string;
  addressType: AddressType;
  addressLine1: string;
  addressLine2?: string | null;
  city: string;
  state: string;
  zipCode: string;
  country: string;
};

export type AuthUserFragment = {
  __typename?: "User";
  id: string;
  isEmployee: boolean;
  inviteCodes?: Array<string> | null;
  clearingAccounts?: Array<{
    __typename?: "ClearingAccount";
    id: string;
    accountType?: AccountType | null;
    categorizationType?: CategorizationType | null;
    externalClearingAccountId: string;
    eventTime?: Date | null;
    isTestAccount?: boolean | null;
    isPatternDayTrader?: boolean | null;
    isClientAccount?: boolean | null;
    primarySubAccountId: string;
    subAccounts: Array<{
      __typename?: "SubAccount";
      id: string;
      type: SubAccountType;
      eventTime: Date;
      pricing?: Array<{
        __typename?: "SubAccountPricing";
        id: string;
        amount: Decimal;
        unit: SubAccountPricingUnit;
        validFrom?: Date | null;
        validTo?: Date | null;
      }> | null;
      directIndexAccountMetadata?: {
        __typename?: "DirectIndexAccountMetadata";
        id: string;
        type: DirectIndexType;
        etfSymbol?: string | null;
        signedAt?: Date | null;
        lhfOverride?: Decimal | null;
        subAccountId: string;
        status?: DirectIndexStatus | null;
        customization?: {
          __typename?: "DirectIndexCustomization";
          id: string;
          removeGICSSectorIds: Array<GicsCode>;
          addSecurityIds: Array<{
            __typename?: "IndexCustomizationSecurity";
            securityId: string;
            security?: {
              __typename?: "Security";
              id: string;
              asOfDate?: Date | null;
              buyStatus: SecurityTradeStatus;
              sellStatus: SecurityTradeStatus;
              cusip?: string | null;
              isin?: string | null;
              name: string;
              symbol: string;
              type: SecurityType;
              subType: SecuritySubType;
            } | null;
          }>;
          removeSecurityIds: Array<{
            __typename?: "IndexCustomizationSecurity";
            securityId: string;
            security?: {
              __typename?: "Security";
              id: string;
              asOfDate?: Date | null;
              buyStatus: SecurityTradeStatus;
              sellStatus: SecurityTradeStatus;
              cusip?: string | null;
              isin?: string | null;
              name: string;
              symbol: string;
              type: SecurityType;
              subType: SecuritySubType;
            } | null;
          }>;
        } | null;
      } | null;
    }>;
    marginCallData?: {
      __typename?: "MarginCallData";
      id: string;
      dueDate: Date;
      eventTime: Date;
      statuses: Array<{
        __typename?: "MarginCallDataStatus";
        state: MarginCallState;
        amount: Decimal;
        eventTime: Date;
      }>;
    } | null;
    actionContexts?: Array<{
      __typename?: "ActionContextStatus";
      actionContext: ActionContext;
      isAllowed: boolean;
    }> | null;
    restrictions?: Array<{
      __typename?: "ClearingAccountRestriction";
      restrictionType: ClearingAccountRestrictionType;
      active: boolean;
    }> | null;
    entity?:
      | {
          __typename?: "BusinessEntityInfo";
          businessEntityDetails: {
            __typename?: "BusinessEntityDetails";
            id: string;
            name: string;
            phoneNumber: string;
            industry: BusinessEntityIndustry;
            address?: Array<{
              __typename?: "GenericAddress";
              addressType: AddressType;
              addressLine1: string;
              addressLine2?: string | null;
              city: string;
              state: string;
              zipCode: string;
              country: string;
            }> | null;
          };
          entityAuthorizedUser: {
            __typename?: "BusinessEntityAuthorizedUser";
            userTitle: string;
            user?: {
              __typename?: "User";
              userPersonalDetails?: {
                __typename?: "UserPersonalDetails";
                fullName: string;
              } | null;
              userIdentityDetails?: {
                __typename?: "UserIdentityDetails";
                citizenshipCountry: string;
                dateOfBirth: DateOnly;
                ssn: string;
              } | null;
              userContactPhone?: {
                __typename?: "UserContactPhone";
                phoneNumber: string;
              } | null;
              userContactEmail?: {
                __typename?: "UserContactEmail";
                email: string;
              } | null;
            } | null;
          };
          entityIndividuals: Array<{
            __typename?: "BusinessEntityIndividual";
            familyName: string;
            givenName: string;
            dateOfBirth: DateOnly;
            individualType: BusinessEntityIndividualType;
            address?: Array<{
              __typename?: "GenericAddress";
              addressType: AddressType;
              addressLine1: string;
              addressLine2?: string | null;
              city: string;
              state: string;
              zipCode: string;
              country: string;
            }> | null;
          }>;
        }
      | {
          __typename?: "TrustEntity";
          trustEntityDetails: {
            __typename?: "TrustEntityDetails";
            id: string;
            name: string;
            taxId: string;
            taxIdType?: TaxIdType | null;
            dateOfCreation: DateOnly;
            revocability?: TrustRevocability | null;
            state: string;
            trustAgreementAllowsMarginsAndShortPositions?: boolean | null;
            address?: {
              __typename?: "GenericAddress";
              id: string;
              addressType: AddressType;
              addressLine1: string;
              addressLine2?: string | null;
              city: string;
              state: string;
              zipCode: string;
              country: string;
            } | null;
          };
          primaryTrustee?: {
            __typename?: "User";
            userContactEmail?: {
              __typename?: "UserContactEmail";
              email: string;
              emailType: EmailType;
            } | null;
            userEmploymentDetails?: Array<{
              __typename?: "UserEmploymentDetails";
              employerName?: string | null;
              occupation?: string | null;
            }> | null;
            userIdentityDetails?: {
              __typename?: "UserIdentityDetails";
              dateOfBirth: DateOnly;
              citizenshipCountry: string;
            } | null;
            userPersonalDetails?: {
              __typename?: "UserPersonalDetails";
              fullName: string;
            } | null;
            userContactPhone?: {
              __typename?: "UserContactPhone";
              phoneNumber: string;
            } | null;
            userAddresses?: Array<{
              __typename?: "GenericAddress";
              addressType: AddressType;
              addressLine1: string;
              addressLine2?: string | null;
              city: string;
              state: string;
              zipCode: string;
              country: string;
            }> | null;
          } | null;
          additionalTrustees: Array<{
            __typename?: "Trustee";
            id: string;
            name: string;
            familyName?: string | null;
            givenName?: string | null;
            dateOfBirth: DateOnly;
            idType?: TrusteeIdType | null;
            idIssuingStateOrCountry?: string | null;
            idExpirationDate?: DateOnly | null;
            governmentIdNumber?: string | null;
            disclosure?: {
              __typename?: "IndividualDisclosure";
              id: string;
              affiliatedExchangeOrFINRA: boolean;
              firmName?: string | null;
              controlPerson: boolean;
              companySymbols?: string | null;
              politicallyExposed: boolean;
              politicalOrganization?: string | null;
              politicalTitle?: string | null;
              immediateFamilyMembers?: Array<string> | null;
            } | null;
            address: {
              __typename?: "GenericAddress";
              id: string;
              addressType: AddressType;
              addressLine1: string;
              addressLine2?: string | null;
              city: string;
              state: string;
              zipCode: string;
              country: string;
            };
          }>;
        }
      | null;
    treasury?: {
      __typename?: "TreasuryConfig";
      subAccountId: string;
      eventTime: Date;
    } | null;
    dividendPreference?: Array<{
      __typename?: "DividendPreference";
      id: string;
      clearingAccountId: string;
      subAccountType: SubAccountType;
      preferenceType: DividendPreferenceType;
    }> | null;
  }> | null;
  userContactEmail?: {
    __typename?: "UserContactEmail";
    id: string;
    email: string;
    emailType: EmailType;
    emailHash?: string | null;
    verified: boolean;
  } | null;
  userContactPhone?: {
    __typename?: "UserContactPhone";
    id: string;
    phoneNumber: string;
    phoneNumberVerified: boolean;
    phoneNumberType: PhoneNumberType;
  } | null;
  userPersonalDetails?: {
    __typename?: "UserPersonalDetails";
    id: string;
    firstName: string;
    lastName: string;
    middleName?: string | null;
    fullName: string;
  } | null;
  userIdentityDetails?: {
    __typename?: "UserIdentityDetails";
    id: string;
    ssn: string;
    dateOfBirth: DateOnly;
    citizenshipCountry: string;
    permanentResident?: boolean | null;
    visaStatus?: VisaType | null;
    visaExpiration?: DateOnly | null;
    maritalStatus?: MaritalStatus | null;
    numberOfDependents?: number | null;
    idType?: TrusteeIdType | null;
    idIssuingStateOrCountry?: string | null;
    idExpirationDate?: DateOnly | null;
    governmentIdNumber?: string | null;
  } | null;
  userAddresses?: Array<{
    __typename?: "GenericAddress";
    id: string;
    addressType: AddressType;
    addressLine1: string;
    addressLine2?: string | null;
    city: string;
    state: string;
    zipCode: string;
    country: string;
  }> | null;
  userInvestmentProfile?: {
    __typename?: "UserInvestmentProfile";
    id: string;
    netWorthMin: number;
    netWorthMax: number;
    annualIncomeMin: number;
    annualIncomeMax: number;
    investmentExperience: InvestmentExperience;
    riskTolerance: RiskTolerance;
    liquidityTimeHorizon: TimeHorizon;
    liquidityNeeds: LiquidityNeeds;
  } | null;
  userServiceProfile?: {
    __typename?: "UserServiceProfile";
    id: string;
    sweepInstruction: SweepInstruction;
    dividendReinvestment: DividendReinvestment;
  } | null;
  userTaxRates?: {
    __typename?: "UserTaxRates";
    id: string;
    taxFilingStatus?: TaxFilingStatus | null;
    federalIncomeTaxRate: Decimal;
    stateIncomeTaxRate?: Decimal | null;
    federalCapitalGainsTaxRate: Decimal;
    stateCapitalGainsTaxRate: Decimal;
  } | null;
  userEmploymentDetails?: Array<{
    __typename?: "UserEmploymentDetails";
    id: string;
    employerName?: string | null;
    employmentStatus: EmploymentStatus;
    yearsEmployed?: number | null;
    occupation?: string | null;
    addressLine1?: string | null;
    addressLine2?: string | null;
    city?: string | null;
    state?: string | null;
    zipCode?: string | null;
    country?: string | null;
  }> | null;
  userTrustedContact?: {
    __typename?: "UserTrustedContact";
    id: string;
    relationship: string;
    firstName: string;
    lastName: string;
    middleName?: string | null;
    email: string;
    phoneNumber: string;
    phoneNumberType: PhoneNumberType;
    addressLine1?: string | null;
    addressLine2?: string | null;
    city?: string | null;
    state?: string | null;
    zipCode?: string | null;
    country?: string | null;
  } | null;
  userDisclosures?: {
    __typename?: "UserDisclosures";
    id: string;
    affiliatedExchangeOrFINRA: boolean;
    affiliatedExchangeOrFINRAEntityName?: string | null;
    firmName?: string | null;
    controlPerson: boolean;
    companySymbols?: string | null;
    politicallyExposed: boolean;
    politicalOrganization?: string | null;
    immediateFamily?: string | null;
  } | null;
  userProgressFlags?: Array<{
    __typename?: "UserProgressFlag";
    status?: ProgressFlagStatus | null;
    category?: string | null;
  } | null> | null;
  entityAccountRequestStatus?: {
    __typename?: "EntityAccountRequestStatus";
    state: EntityAccountRequestStatusState;
    asOfDate: Date;
  } | null;
  uploadedDocuments?: Array<{
    __typename?: "UploadedDocument";
    id: string;
    name: string;
    originalName: string;
    description?: string | null;
    type: UploadDocumentType;
    location: string;
  }> | null;
  referralPromo?: {
    __typename?: "UserReferralPromotion";
    code: string;
    dealType: UserReferralDeal;
  } | null;
};

export type CashDividendItemFragment = {
  __typename?: "CashDividend";
  id: string;
  clearingAccountId: string;
  subAccountId: string;
  amount: Decimal;
  eventTime: Date;
  description?: string | null;
  security?: {
    __typename?: "Security";
    id: string;
    asOfDate?: Date | null;
    buyStatus: SecurityTradeStatus;
    sellStatus: SecurityTradeStatus;
    cusip?: string | null;
    isin?: string | null;
    name: string;
    symbol: string;
    type: SecurityType;
    subType: SecuritySubType;
  } | null;
  cashDividendFee: Array<{
    __typename?: "CashDividendFee";
    id: string;
    type: CashDividendFeeType;
    amount: Decimal;
  }>;
};

export type CashTransferFragment = {
  __typename?: "CashTransfer";
  id: string;
  userId: string;
  clearingAccountId: string;
  subAccountId: string;
  amount: Decimal;
  method: CashTransferMethod;
  direction: CashTransferDirection;
  status: CashTransferStatus;
  eventTime: Date;
  statusHistory?: Array<{
    __typename?: "CashTransferStatusHistory";
    id: string;
    status: string;
    externalStatus?: string | null;
    externalStatusTime?: Date | null;
    reason?: string | null;
    reasonDesc?: string | null;
    reasonDetail?: string | null;
    reasonRecommendation?: string | null;
    eventTime: Date;
  }> | null;
  cashTransferFee?: Array<{
    __typename?: "CashTransferFee";
    id: string;
    type: CashTransferFeeType;
    customerDebit?: Decimal | null;
    clearingHouseCredit?: Decimal | null;
    correspondentNetAmount?: Decimal | null;
  }> | null;
  sourceDepositAccount?: {
    __typename?: "DepositAccount";
    id: string;
    clearingAccountId?: string | null;
    accountNumber?: string | null;
    name?: string | null;
    officialName?: string | null;
    subType: string;
  } | null;
  destinationDepositAccount?: {
    __typename?: "DepositAccount";
    id: string;
    clearingAccountId?: string | null;
    accountNumber?: string | null;
    name?: string | null;
    officialName?: string | null;
    subType: string;
  } | null;
};

export type CashTransferListItemFragment = {
  __typename?: "CashTransfer";
  id: string;
  amount: Decimal;
  method: CashTransferMethod;
  direction: CashTransferDirection;
  status: CashTransferStatus;
  eventTime: Date;
};

export type ClearingAccountFragment = {
  __typename?: "ClearingAccount";
  id: string;
  accountType?: AccountType | null;
  categorizationType?: CategorizationType | null;
  externalClearingAccountId: string;
  eventTime?: Date | null;
  isTestAccount?: boolean | null;
  isPatternDayTrader?: boolean | null;
  isClientAccount?: boolean | null;
  primarySubAccountId: string;
  subAccounts: Array<{
    __typename?: "SubAccount";
    id: string;
    type: SubAccountType;
    eventTime: Date;
    pricing?: Array<{
      __typename?: "SubAccountPricing";
      id: string;
      amount: Decimal;
      unit: SubAccountPricingUnit;
      validFrom?: Date | null;
      validTo?: Date | null;
    }> | null;
    directIndexAccountMetadata?: {
      __typename?: "DirectIndexAccountMetadata";
      id: string;
      type: DirectIndexType;
      etfSymbol?: string | null;
      signedAt?: Date | null;
      lhfOverride?: Decimal | null;
      subAccountId: string;
      status?: DirectIndexStatus | null;
      customization?: {
        __typename?: "DirectIndexCustomization";
        id: string;
        removeGICSSectorIds: Array<GicsCode>;
        addSecurityIds: Array<{
          __typename?: "IndexCustomizationSecurity";
          securityId: string;
          security?: {
            __typename?: "Security";
            id: string;
            asOfDate?: Date | null;
            buyStatus: SecurityTradeStatus;
            sellStatus: SecurityTradeStatus;
            cusip?: string | null;
            isin?: string | null;
            name: string;
            symbol: string;
            type: SecurityType;
            subType: SecuritySubType;
          } | null;
        }>;
        removeSecurityIds: Array<{
          __typename?: "IndexCustomizationSecurity";
          securityId: string;
          security?: {
            __typename?: "Security";
            id: string;
            asOfDate?: Date | null;
            buyStatus: SecurityTradeStatus;
            sellStatus: SecurityTradeStatus;
            cusip?: string | null;
            isin?: string | null;
            name: string;
            symbol: string;
            type: SecurityType;
            subType: SecuritySubType;
          } | null;
        }>;
      } | null;
    } | null;
  }>;
  marginCallData?: {
    __typename?: "MarginCallData";
    id: string;
    dueDate: Date;
    eventTime: Date;
    statuses: Array<{
      __typename?: "MarginCallDataStatus";
      state: MarginCallState;
      amount: Decimal;
      eventTime: Date;
    }>;
  } | null;
  actionContexts?: Array<{
    __typename?: "ActionContextStatus";
    actionContext: ActionContext;
    isAllowed: boolean;
  }> | null;
  restrictions?: Array<{
    __typename?: "ClearingAccountRestriction";
    restrictionType: ClearingAccountRestrictionType;
    active: boolean;
  }> | null;
  entity?:
    | {
        __typename?: "BusinessEntityInfo";
        businessEntityDetails: {
          __typename?: "BusinessEntityDetails";
          id: string;
          name: string;
          phoneNumber: string;
          industry: BusinessEntityIndustry;
          address?: Array<{
            __typename?: "GenericAddress";
            addressType: AddressType;
            addressLine1: string;
            addressLine2?: string | null;
            city: string;
            state: string;
            zipCode: string;
            country: string;
          }> | null;
        };
        entityAuthorizedUser: {
          __typename?: "BusinessEntityAuthorizedUser";
          userTitle: string;
          user?: {
            __typename?: "User";
            userPersonalDetails?: {
              __typename?: "UserPersonalDetails";
              fullName: string;
            } | null;
            userIdentityDetails?: {
              __typename?: "UserIdentityDetails";
              citizenshipCountry: string;
              dateOfBirth: DateOnly;
              ssn: string;
            } | null;
            userContactPhone?: {
              __typename?: "UserContactPhone";
              phoneNumber: string;
            } | null;
            userContactEmail?: {
              __typename?: "UserContactEmail";
              email: string;
            } | null;
          } | null;
        };
        entityIndividuals: Array<{
          __typename?: "BusinessEntityIndividual";
          familyName: string;
          givenName: string;
          dateOfBirth: DateOnly;
          individualType: BusinessEntityIndividualType;
          address?: Array<{
            __typename?: "GenericAddress";
            addressType: AddressType;
            addressLine1: string;
            addressLine2?: string | null;
            city: string;
            state: string;
            zipCode: string;
            country: string;
          }> | null;
        }>;
      }
    | {
        __typename?: "TrustEntity";
        trustEntityDetails: {
          __typename?: "TrustEntityDetails";
          id: string;
          name: string;
          taxId: string;
          taxIdType?: TaxIdType | null;
          dateOfCreation: DateOnly;
          revocability?: TrustRevocability | null;
          state: string;
          trustAgreementAllowsMarginsAndShortPositions?: boolean | null;
          address?: {
            __typename?: "GenericAddress";
            id: string;
            addressType: AddressType;
            addressLine1: string;
            addressLine2?: string | null;
            city: string;
            state: string;
            zipCode: string;
            country: string;
          } | null;
        };
        primaryTrustee?: {
          __typename?: "User";
          userContactEmail?: {
            __typename?: "UserContactEmail";
            email: string;
            emailType: EmailType;
          } | null;
          userEmploymentDetails?: Array<{
            __typename?: "UserEmploymentDetails";
            employerName?: string | null;
            occupation?: string | null;
          }> | null;
          userIdentityDetails?: {
            __typename?: "UserIdentityDetails";
            dateOfBirth: DateOnly;
            citizenshipCountry: string;
          } | null;
          userPersonalDetails?: {
            __typename?: "UserPersonalDetails";
            fullName: string;
          } | null;
          userContactPhone?: {
            __typename?: "UserContactPhone";
            phoneNumber: string;
          } | null;
          userAddresses?: Array<{
            __typename?: "GenericAddress";
            addressType: AddressType;
            addressLine1: string;
            addressLine2?: string | null;
            city: string;
            state: string;
            zipCode: string;
            country: string;
          }> | null;
        } | null;
        additionalTrustees: Array<{
          __typename?: "Trustee";
          id: string;
          name: string;
          familyName?: string | null;
          givenName?: string | null;
          dateOfBirth: DateOnly;
          idType?: TrusteeIdType | null;
          idIssuingStateOrCountry?: string | null;
          idExpirationDate?: DateOnly | null;
          governmentIdNumber?: string | null;
          disclosure?: {
            __typename?: "IndividualDisclosure";
            id: string;
            affiliatedExchangeOrFINRA: boolean;
            firmName?: string | null;
            controlPerson: boolean;
            companySymbols?: string | null;
            politicallyExposed: boolean;
            politicalOrganization?: string | null;
            politicalTitle?: string | null;
            immediateFamilyMembers?: Array<string> | null;
          } | null;
          address: {
            __typename?: "GenericAddress";
            id: string;
            addressType: AddressType;
            addressLine1: string;
            addressLine2?: string | null;
            city: string;
            state: string;
            zipCode: string;
            country: string;
          };
        }>;
      }
    | null;
  treasury?: {
    __typename?: "TreasuryConfig";
    subAccountId: string;
    eventTime: Date;
  } | null;
  dividendPreference?: Array<{
    __typename?: "DividendPreference";
    id: string;
    clearingAccountId: string;
    subAccountType: SubAccountType;
    preferenceType: DividendPreferenceType;
  }> | null;
};

export type DailyCashDividendItemFragment = {
  __typename?: "DailyCashDividend";
  id: string;
  clearingAccountId: string;
  subAccountId: string;
  amount: Decimal;
  eventTime: Date;
  dividends: Array<{
    __typename?: "DividendSecurity";
    amount: Decimal;
    security?: {
      __typename?: "Security";
      id: string;
      asOfDate?: Date | null;
      buyStatus: SecurityTradeStatus;
      sellStatus: SecurityTradeStatus;
      cusip?: string | null;
      isin?: string | null;
      name: string;
      symbol: string;
      type: SecurityType;
      subType: SecuritySubType;
    } | null;
  }>;
  fees: Array<{
    __typename?: "DividendSecurityFee";
    amount: Decimal;
    type: CashDividendFeeType;
    security?: {
      __typename?: "Security";
      id: string;
      asOfDate?: Date | null;
      buyStatus: SecurityTradeStatus;
      sellStatus: SecurityTradeStatus;
      cusip?: string | null;
      isin?: string | null;
      name: string;
      symbol: string;
      type: SecurityType;
      subType: SecuritySubType;
    } | null;
  }>;
};

export type DepositAccountFragment = {
  __typename?: "DepositAccount";
  id: string;
  plaidAccountId?: string | null;
  routingNumber?: string | null;
  accountNumber?: string | null;
  wireRoutingNumber?: string | null;
  name?: string | null;
  officialName?: string | null;
  subType: string;
  apexRelationshipStatus?: string | null;
};

export type DetailedQuoteFragment = {
  __typename?: "DetailedQuote";
  ask?: number | null;
  askMarketIdentificationCode?: string | null;
  askSize?: number | null;
  askTime?: Date | null;
  bid?: number | null;
  bidMarketIdentificationCode?: string | null;
  bidSize?: number | null;
  bidTime?: Date | null;
  changeFromPreviousClose: number;
  close: number;
  currency: string;
  delay: number;
  high: number;
  high52Weeks: number;
  identifier: string;
  identifierType: string;
  last: number;
  lastMarketIdentificationCode?: string | null;
  lastSize: number;
  low: number;
  low52Weeks: number;
  message: string;
  open: number;
  outcome: string;
  percentChangeFromPreviousClose: number;
  previousClose: number;
  previousCloseDate: DateOnly;
  time: Date;
  tradingHalted: boolean;
  volume: number;
  security: {
    __typename?: "DetailedSecurity";
    mostLiquidExchange: boolean;
    categoryOrIndustry?: string | null;
    marketIdentificationCode: string;
    market: string;
    name: string;
    valoren?: string | null;
    ISIN?: string | null;
    symbol: string;
    CUSIP?: string | null;
    CIK?: string | null;
  };
};

export type DetailedSecurityFragment = {
  __typename?: "DetailedSecurity";
  mostLiquidExchange: boolean;
  categoryOrIndustry?: string | null;
  marketIdentificationCode: string;
  market: string;
  name: string;
  valoren?: string | null;
  ISIN?: string | null;
  symbol: string;
  CUSIP?: string | null;
  CIK?: string | null;
};

export type DirectIndexFeeChargeFragment = {
  __typename?: "DirectIndexFeeCharge";
  id: string;
  amount: Decimal;
  eventTime: Date;
  billingStartDate: Date;
  billingEndDate: Date;
  billedTo: DirectIndexFeeBilledTo;
};

export type DirectIndexTradeExecutionSummaryFragment = {
  __typename?: "DirectIndexTradeExecutionSummary";
  id: string;
  subAccountId: string;
  clearingAccountId: string;
  status: TradeExecutionStatus;
  eventTime: Date;
  latestStatusTime?: Date | null;
  cashLeftover?: Decimal | null;
  isLiquidationRequest: boolean;
  orders: Array<{
    __typename?: "TradeExecutionOrders";
    securityId: string;
    quantity: Decimal;
    shortTermGain?: Decimal | null;
    shortTermLoss?: Decimal | null;
    longTermGain?: Decimal | null;
    longTermLoss?: Decimal | null;
    notional: Decimal;
    orderSide: OrderSide;
    security?: {
      __typename?: "Security";
      id: string;
      asOfDate?: Date | null;
      buyStatus: SecurityTradeStatus;
      sellStatus: SecurityTradeStatus;
      cusip?: string | null;
      isin?: string | null;
      name: string;
      symbol: string;
      type: SecurityType;
      subType: SecuritySubType;
    } | null;
  }>;
};

export type DirectIndexWeightFragment = {
  __typename?: "DirectIndexWeight";
  securityId: string;
  weight: Decimal;
  canLiquidate: boolean;
  canPurchase: boolean;
  canDisplayWeight: boolean;
  security?: {
    __typename?: "Security";
    id: string;
    asOfDate?: Date | null;
    buyStatus: SecurityTradeStatus;
    sellStatus: SecurityTradeStatus;
    cusip?: string | null;
    isin?: string | null;
    name: string;
    symbol: string;
    type: SecurityType;
    subType: SecuritySubType;
  } | null;
};

export type DividendPreferenceFragment = {
  __typename?: "DividendPreference";
  id: string;
  clearingAccountId: string;
  subAccountType: SubAccountType;
  preferenceType: DividendPreferenceType;
};

export type ProfileAddressFragment = {
  __typename?: "GenericAddress";
  addressType: AddressType;
  addressLine1: string;
  addressLine2?: string | null;
  city: string;
  state: string;
  zipCode: string;
  country: string;
};

export type ClearingAccountBusinessEntityFragment = {
  __typename?: "BusinessEntityInfo";
  businessEntityDetails: {
    __typename?: "BusinessEntityDetails";
    id: string;
    name: string;
    phoneNumber: string;
    industry: BusinessEntityIndustry;
    address?: Array<{
      __typename?: "GenericAddress";
      addressType: AddressType;
      addressLine1: string;
      addressLine2?: string | null;
      city: string;
      state: string;
      zipCode: string;
      country: string;
    }> | null;
  };
  entityAuthorizedUser: {
    __typename?: "BusinessEntityAuthorizedUser";
    userTitle: string;
    user?: {
      __typename?: "User";
      userPersonalDetails?: {
        __typename?: "UserPersonalDetails";
        fullName: string;
      } | null;
      userIdentityDetails?: {
        __typename?: "UserIdentityDetails";
        citizenshipCountry: string;
        dateOfBirth: DateOnly;
        ssn: string;
      } | null;
      userContactPhone?: {
        __typename?: "UserContactPhone";
        phoneNumber: string;
      } | null;
      userContactEmail?: {
        __typename?: "UserContactEmail";
        email: string;
      } | null;
    } | null;
  };
  entityIndividuals: Array<{
    __typename?: "BusinessEntityIndividual";
    familyName: string;
    givenName: string;
    dateOfBirth: DateOnly;
    individualType: BusinessEntityIndividualType;
    address?: Array<{
      __typename?: "GenericAddress";
      addressType: AddressType;
      addressLine1: string;
      addressLine2?: string | null;
      city: string;
      state: string;
      zipCode: string;
      country: string;
    }> | null;
  }>;
};

export type TrustEntityDetailsFragment = {
  __typename?: "TrustEntityDetails";
  id: string;
  name: string;
  taxId: string;
  taxIdType?: TaxIdType | null;
  dateOfCreation: DateOnly;
  revocability?: TrustRevocability | null;
  state: string;
  trustAgreementAllowsMarginsAndShortPositions?: boolean | null;
  address?: {
    __typename?: "GenericAddress";
    id: string;
    addressType: AddressType;
    addressLine1: string;
    addressLine2?: string | null;
    city: string;
    state: string;
    zipCode: string;
    country: string;
  } | null;
};

export type TrusteeFragment = {
  __typename?: "Trustee";
  id: string;
  name: string;
  familyName?: string | null;
  givenName?: string | null;
  dateOfBirth: DateOnly;
  idType?: TrusteeIdType | null;
  idIssuingStateOrCountry?: string | null;
  idExpirationDate?: DateOnly | null;
  governmentIdNumber?: string | null;
  disclosure?: {
    __typename?: "IndividualDisclosure";
    id: string;
    affiliatedExchangeOrFINRA: boolean;
    firmName?: string | null;
    controlPerson: boolean;
    companySymbols?: string | null;
    politicallyExposed: boolean;
    politicalOrganization?: string | null;
    politicalTitle?: string | null;
    immediateFamilyMembers?: Array<string> | null;
  } | null;
  address: {
    __typename?: "GenericAddress";
    id: string;
    addressType: AddressType;
    addressLine1: string;
    addressLine2?: string | null;
    city: string;
    state: string;
    zipCode: string;
    country: string;
  };
};

export type ClearingAccountTrustEntityFragment = {
  __typename?: "TrustEntity";
  trustEntityDetails: {
    __typename?: "TrustEntityDetails";
    id: string;
    name: string;
    taxId: string;
    taxIdType?: TaxIdType | null;
    dateOfCreation: DateOnly;
    revocability?: TrustRevocability | null;
    state: string;
    trustAgreementAllowsMarginsAndShortPositions?: boolean | null;
    address?: {
      __typename?: "GenericAddress";
      id: string;
      addressType: AddressType;
      addressLine1: string;
      addressLine2?: string | null;
      city: string;
      state: string;
      zipCode: string;
      country: string;
    } | null;
  };
  primaryTrustee?: {
    __typename?: "User";
    userContactEmail?: {
      __typename?: "UserContactEmail";
      email: string;
      emailType: EmailType;
    } | null;
    userEmploymentDetails?: Array<{
      __typename?: "UserEmploymentDetails";
      employerName?: string | null;
      occupation?: string | null;
    }> | null;
    userIdentityDetails?: {
      __typename?: "UserIdentityDetails";
      dateOfBirth: DateOnly;
      citizenshipCountry: string;
    } | null;
    userPersonalDetails?: {
      __typename?: "UserPersonalDetails";
      fullName: string;
    } | null;
    userContactPhone?: {
      __typename?: "UserContactPhone";
      phoneNumber: string;
    } | null;
    userAddresses?: Array<{
      __typename?: "GenericAddress";
      addressType: AddressType;
      addressLine1: string;
      addressLine2?: string | null;
      city: string;
      state: string;
      zipCode: string;
      country: string;
    }> | null;
  } | null;
  additionalTrustees: Array<{
    __typename?: "Trustee";
    id: string;
    name: string;
    familyName?: string | null;
    givenName?: string | null;
    dateOfBirth: DateOnly;
    idType?: TrusteeIdType | null;
    idIssuingStateOrCountry?: string | null;
    idExpirationDate?: DateOnly | null;
    governmentIdNumber?: string | null;
    disclosure?: {
      __typename?: "IndividualDisclosure";
      id: string;
      affiliatedExchangeOrFINRA: boolean;
      firmName?: string | null;
      controlPerson: boolean;
      companySymbols?: string | null;
      politicallyExposed: boolean;
      politicalOrganization?: string | null;
      politicalTitle?: string | null;
      immediateFamilyMembers?: Array<string> | null;
    } | null;
    address: {
      __typename?: "GenericAddress";
      id: string;
      addressType: AddressType;
      addressLine1: string;
      addressLine2?: string | null;
      city: string;
      state: string;
      zipCode: string;
      country: string;
    };
  }>;
};

export type TrustEntityFragment = {
  __typename?: "TrustEntity";
  id: string;
  primaryTrusteeId: string;
  entityDetails: {
    __typename?: "TrustEntityDetails";
    id: string;
    name: string;
    taxId: string;
    taxIdType?: TaxIdType | null;
    dateOfCreation: DateOnly;
    revocability?: TrustRevocability | null;
    state: string;
    trustAgreementAllowsMarginsAndShortPositions?: boolean | null;
    address?: {
      __typename?: "GenericAddress";
      id: string;
      addressType: AddressType;
      addressLine1: string;
      addressLine2?: string | null;
      city: string;
      state: string;
      zipCode: string;
      country: string;
    } | null;
  };
  additionalTrustees: Array<{
    __typename?: "Trustee";
    id: string;
    name: string;
    familyName?: string | null;
    givenName?: string | null;
    dateOfBirth: DateOnly;
    idType?: TrusteeIdType | null;
    idIssuingStateOrCountry?: string | null;
    idExpirationDate?: DateOnly | null;
    governmentIdNumber?: string | null;
    disclosure?: {
      __typename?: "IndividualDisclosure";
      id: string;
      affiliatedExchangeOrFINRA: boolean;
      firmName?: string | null;
      controlPerson: boolean;
      companySymbols?: string | null;
      politicallyExposed: boolean;
      politicalOrganization?: string | null;
      politicalTitle?: string | null;
      immediateFamilyMembers?: Array<string> | null;
    } | null;
    address: {
      __typename?: "GenericAddress";
      id: string;
      addressType: AddressType;
      addressLine1: string;
      addressLine2?: string | null;
      city: string;
      state: string;
      zipCode: string;
      country: string;
    };
  }>;
  address?: {
    __typename?: "GenericAddress";
    id: string;
    addressType: AddressType;
    addressLine1: string;
    addressLine2?: string | null;
    city: string;
    state: string;
    zipCode: string;
    country: string;
  } | null;
};

export type HoldingFragment = {
  __typename?: "Holding";
  symbol: string;
  quantity: Decimal;
  price: Decimal;
  marginRequirement: Decimal;
};

export type IndexConstituentFragment = {
  __typename?: "IndexConstituent";
  symbol: string;
  name: string;
  weight: number;
  effectiveDate: DateOnly;
  marketCap: number;
  indexMarketCap: number;
  price: number;
  gicsCode?: GicsCode | null;
  fractionalSupported: boolean;
};

export type IndexHistoricalFragment = {
  __typename?: "IndexHistorical";
  date: DateOnly;
  indexName: string;
  indexCode?: string | null;
  indexKey: string;
  GICSCode?: GicsCode | null;
  ISOCode?: string | null;
  indexValue?: number | null;
  closeMarketCap?: number | null;
  closeDivisor?: number | null;
  closeCount: number;
  dailyReturn?: number | null;
  indexDividend?: number | null;
  adjMarketCap?: number | null;
  adjDivisor?: number | null;
  adjCount?: number | null;
};

export type IndexHistoricalPerformanceFragment = {
  __typename?: "IndexHistoricalPerformanceData";
  period: PerformanceDataPeriod;
  returnForPeriod: Decimal;
  timeseriesData: Array<{
    __typename?: "IndexPerformancePoint";
    date: DateOnly;
    value: Decimal;
  }>;
};

export type IndexMetadataFragment = {
  __typename?: "IndexDetails";
  allowedSectorCustomizations?: number | null;
  allowedStockCustomizations: number;
  canCustomizeSectors: boolean;
  comparisonEtf: string;
  comparisonEtfExpenseRatio: Decimal;
  comparisonEtfName: string;
  feePercent: Decimal;
  indexDescription: string;
  indexName: string;
  indexType: DirectIndexType;
  investmentMinimum: Decimal;
  learnMoreLink: string;
  numberOfPositions: number;
  stockIndex: StockIndex;
  constituentData: {
    __typename?: "IndexExplorerConstituentData";
    asOfDate?: DateOnly | null;
    totalMarketCap: number;
    constituents: Array<{
      __typename?: "IndexConstituent";
      symbol: string;
      name: string;
      indexMarketCap: number;
      weight: number;
      fractionalSupported: boolean;
      canDisplayWeight: boolean;
    }>;
    sectorData: Array<{
      __typename?: "IndexSectorData";
      sectorName: string;
      sectorMarketCapPercent: number;
      gicsCode: GicsCode;
      sectorPositionsCount: number;
    }>;
    countryData: Array<{
      __typename?: "IndexCountryData";
      countryName: string;
      countryMarketCapPercent: number;
    }>;
  };
  historicalPerformanceData: {
    __typename?: "IndexHistoricalPerformanceData";
    returnForPeriod: Decimal;
    period: PerformanceDataPeriod;
    timeseriesData: Array<{
      __typename?: "IndexPerformancePoint";
      date: DateOnly;
      value: Decimal;
    }>;
  };
};

export type IndexSectorMetadataFragment = {
  __typename?: "IndexDetails";
  constituentData: {
    __typename?: "IndexExplorerConstituentData";
    sectorData: Array<{
      __typename?: "IndexSectorData";
      gicsCode: GicsCode;
      sectorPositionsCount: number;
    }>;
  };
};

export type IntraAccountCashTransferFragment = {
  __typename?: "IntraAccountCashTransfer";
  id: string;
  subAccountId: string;
  amount: Decimal;
  from: IntraAccountCashTransferDirection;
  to: IntraAccountCashTransferDirection;
  eventTime: Date;
  source: AdjustmentSource;
};

export type IntraAccountStockTransferFragment = {
  __typename?: "IntraAccountStockTransfer";
  adjustmentsGroupId: string;
  subAccountId: string;
  from: IntraAccountStockTransferDirection;
  to: IntraAccountStockTransferDirection;
  eventTime: Date;
  source: AdjustmentSource;
  stocks: Array<{
    __typename?: "IntraAccountStockTransferSecurity";
    symbol: string;
    quantity: Decimal;
  }>;
};

export type InvestIndexDetailsFragment = {
  __typename?: "IndexDetails";
  indexType: DirectIndexType;
  indexName: string;
  indexSubText: string;
  indexDisplayCategory: IndexDisplayCategory;
  numberOfPositions: number;
  ytdReturn: Decimal;
};

export type KeyValuePairFragment = {
  __typename?: "KeyValuePair";
  id: string;
  key: string;
  namespace: string;
  value: object;
  expiresAt?: Date | null;
};

export type LinkedBrokerageAccountFragment = {
  __typename?: "LinkedBrokerageAccount";
  id: string;
  plaidAccountId?: string | null;
  name: string;
  mask: string;
  institutionName: string;
  isPartialTransferDisabled: boolean;
  plaidAccount?: {
    __typename?: "PlaidAccount";
    id: string;
    plaidInstitutionId?: string | null;
    name: string;
    type: PlaidAccountType;
    subType: PlaidAccountSubType;
    officialName?: string | null;
    institutionName: string;
    logo?: string | null;
    mask?: string | null;
    verificationStatus?: string | null;
    wireRoutingNumber?: string | null;
    accountNumber?: string | null;
    hasMissingAccountNumber: boolean;
    status: PlaidAccountStatus;
    owners?: string | null;
    error?: PlaidAccountError | null;
    portfolio?: {
      __typename?: "PlaidAccountPortfolio";
      cashBalance: Decimal;
      totalBalance?: Decimal | null;
      error?: PlaidAccountError | null;
      holdings: Array<{
        __typename?: "PlaidAccountPortfolioHolding";
        securityId?: string | null;
        name: string;
        symbol: string;
        cusip?: string | null;
        quantity: number;
        costBasis: number;
        currency: string;
        closingPrice?: number | null;
        supported: boolean;
        plaidAccountId: string;
        security?: {
          __typename?: "Security";
          id: string;
          asOfDate?: Date | null;
          buyStatus: SecurityTradeStatus;
          sellStatus: SecurityTradeStatus;
          cusip?: string | null;
          isin?: string | null;
          name: string;
          symbol: string;
          type: SecurityType;
          subType: SecuritySubType;
          stockQuote?: {
            __typename?: "StockQuote";
            symbol: string;
            price: Decimal;
            name?: string | null;
            change?: Decimal | null;
            changesPercentage?: Decimal | null;
            open?: Decimal | null;
            previousClose?: Decimal | null;
            volume?: Decimal | null;
            eventTime?: Date | null;
            marginRequirement?: {
              __typename?: "MarginRequirement";
              symbol: string;
              maintenanceRequirement: Decimal;
            } | null;
          } | null;
        } | null;
      }>;
    } | null;
  } | null;
  acatsTransfers?: Array<{
    __typename?: "AcatsTransfer";
    eventTime: Date;
    id: string;
    status: AcatsTransferStatus;
    statusHistory?: Array<{
      __typename?: "AcatsStatusHistory";
      eventTime: Date;
      status: AcatsTransferStatus;
    }> | null;
  }> | null;
};

export type LiquidateDirectIndexPortfolioRequestFragment = {
  __typename?: "LiquidateDirectIndexPortfolioRequest";
  id: string;
  subAccountId: string;
  status: LiquidateDirectIndexPortfolioStatus;
  amount?: Decimal | null;
  isFullLiquidation?: boolean | null;
  eventTime: Date;
};

export type LiveSecurityHoldingFragment = {
  __typename?: "LiveSecurityHolding";
  clearingAccountId: string;
  securityId: string;
  subAccountId: string;
  subAccountType: SubAccountType;
  quantity: Decimal;
  avgCostBasis?: Decimal | null;
  dividendYtd?: Decimal | null;
  security?: {
    __typename?: "Security";
    id: string;
    asOfDate?: Date | null;
    buyStatus: SecurityTradeStatus;
    sellStatus: SecurityTradeStatus;
    cusip?: string | null;
    isin?: string | null;
    name: string;
    symbol: string;
    type: SecurityType;
    subType: SecuritySubType;
    stockQuote?: {
      __typename?: "StockQuote";
      symbol: string;
      price: Decimal;
      name?: string | null;
      change?: Decimal | null;
      changesPercentage?: Decimal | null;
      open?: Decimal | null;
      previousClose?: Decimal | null;
      volume?: Decimal | null;
      eventTime?: Date | null;
      marginRequirement?: {
        __typename?: "MarginRequirement";
        symbol: string;
        maintenanceRequirement: Decimal;
      } | null;
    } | null;
    mmfMetadata?: {
      __typename?: "MMFMetadata";
      name: string;
      cusip: string;
      symbol: string;
      nav: number;
      netAssets: number;
      secYield7Day: number;
      expenseRatio: number;
      inceptionDate: Date;
      minInvestment: number;
      ytdYield: number;
      dividendSchedule: MmfDividendScheduleFrequency;
      lastUpdated?: Date | null;
      link: string;
    } | null;
    symbolAlias?: Array<{
      __typename?: "SymbolAlias";
      symbol: string;
      context: Array<SecurityAliasContext | null>;
    } | null> | null;
  } | null;
};

export type LoanEventDataFragment = {
  __typename?: "LoanEvent";
  id: string;
  type: LoanType;
  amount: Decimal;
  eventTime: Date;
  acatsTransferId?: string | null;
};

export type MmfMetadataFragment = {
  __typename?: "MMFMetadata";
  name: string;
  cusip: string;
  symbol: string;
  nav: number;
  netAssets: number;
  secYield7Day: number;
  expenseRatio: number;
  inceptionDate: Date;
  minInvestment: number;
  ytdYield: number;
  dividendSchedule: MmfDividendScheduleFrequency;
  lastUpdated?: Date | null;
  link: string;
};

export type MarginCallDataFragment = {
  __typename?: "MarginCallData";
  id: string;
  dueDate: Date;
  eventTime: Date;
  statuses: Array<{
    __typename?: "MarginCallDataStatus";
    state: MarginCallState;
    amount: Decimal;
    eventTime: Date;
  }>;
};

export type MarginInterestChargeFragment = {
  __typename?: "MarginInterestCharge";
  id: string;
  amount: Decimal;
  eventTime: Date;
  billingStartDate: Date;
  billingEndDate: Date;
  billedTo: MarginInterestFeeBilledTo;
};

export type OrderFragment = {
  __typename?: "Order";
  id: string;
  userId: string;
  clearingAccountId: string;
  subAccountId: string;
  side: OrderSide;
  orderType: OrderType;
  securityId: string;
  quantityType?: QuantityType | null;
  quantity: Decimal;
  maxSellQuantity?: Decimal | null;
  limitPrice?: Decimal | null;
  limitDuration?: Date | null;
  orderPrice?: Decimal | null;
  eventTime: Date;
  orderStatus: OrderStatus;
  source?: BatchOrderSource | null;
  opsInitiated: boolean;
  shortTermGain?: Decimal | null;
  shortTermLoss?: Decimal | null;
  longTermGain?: Decimal | null;
  longTermLoss?: Decimal | null;
  statusHistory?: Array<{
    __typename?: "OrderStatusHistory";
    id: string;
    orderId: string;
    orderStatus: OrderStatus;
    eventTime: Date;
    fillShares?: Decimal | null;
    fillAvgPrice?: Decimal | null;
    fillNotional?: Decimal | null;
  }> | null;
  orderFees: Array<{
    __typename?: "OrderFee";
    id: string;
    type: OrderFeeType;
    amount: Decimal;
  }>;
  security?: {
    __typename?: "Security";
    id: string;
    asOfDate?: Date | null;
    buyStatus: SecurityTradeStatus;
    sellStatus: SecurityTradeStatus;
    cusip?: string | null;
    isin?: string | null;
    name: string;
    symbol: string;
    type: SecurityType;
    subType: SecuritySubType;
  } | null;
  lotMatchingInstructions?: Array<{
    __typename?: "LotMatchingInstruction";
    taxLotEntryId: string;
    tradeDate?: DateOnly | null;
    quantity: Decimal;
    price?: Decimal | null;
    realizedGainLoss?: Decimal | null;
    cost?: Decimal | null;
    longTermShortTermIndicator?: LongTermShortTermIndicatorEnumGql | null;
  }> | null;
};

export type PaymentConfigFragment = {
  __typename?: "PaymentConfig";
  id: string;
  type: PaymentType;
  sourceType: PaymentSourceType;
  destinationType: PaymentDestinationType;
  destinationAccountId: string;
  amountType: PaymentAmountType;
  amount?: Decimal | null;
  dayOfMonth?: number | null;
  periodType?: PaymentPeriodType | null;
  startAt?: Date | null;
  cardName?: string | null;
  sourceSecurity?: {
    __typename?: "Security";
    id: string;
    asOfDate?: Date | null;
    buyStatus: SecurityTradeStatus;
    sellStatus: SecurityTradeStatus;
    cusip?: string | null;
    isin?: string | null;
    name: string;
    symbol: string;
    type: SecurityType;
    subType: SecuritySubType;
  } | null;
};

export type PaymentExecutionFragment = {
  __typename?: "PaymentExecution";
  id: string;
  status: PaymentExecutionStatus;
  amount: Decimal;
  failureReason?: PaymentFailureReason | null;
  eventTime: Date;
  paymentConfig?: {
    __typename?: "PaymentConfig";
    id: string;
    type: PaymentType;
    sourceType: PaymentSourceType;
    destinationType: PaymentDestinationType;
    destinationAccountId: string;
    amountType: PaymentAmountType;
    amount?: Decimal | null;
    dayOfMonth?: number | null;
    periodType?: PaymentPeriodType | null;
    startAt?: Date | null;
    cardName?: string | null;
    sourceSecurity?: {
      __typename?: "Security";
      id: string;
      asOfDate?: Date | null;
      buyStatus: SecurityTradeStatus;
      sellStatus: SecurityTradeStatus;
      cusip?: string | null;
      isin?: string | null;
      name: string;
      symbol: string;
      type: SecurityType;
      subType: SecuritySubType;
    } | null;
  } | null;
};

export type PendingLoanDataFragment = {
  __typename?: "PendingLoan";
  id: string;
  amount: Decimal;
  status: PendingLoanStatus;
  eventTime: Date;
};

export type PlaidAccountFragment = {
  __typename?: "PlaidAccount";
  id: string;
  plaidInstitutionId?: string | null;
  name: string;
  type: PlaidAccountType;
  subType: PlaidAccountSubType;
  officialName?: string | null;
  institutionName: string;
  logo?: string | null;
  mask?: string | null;
  verificationStatus?: string | null;
  wireRoutingNumber?: string | null;
  accountNumber?: string | null;
  hasMissingAccountNumber: boolean;
  status: PlaidAccountStatus;
  owners?: string | null;
  error?: PlaidAccountError | null;
};

export type PlaidAccountPortfolioHoldingFragment = {
  __typename?: "PlaidAccountPortfolioHolding";
  securityId?: string | null;
  name: string;
  symbol: string;
  cusip?: string | null;
  quantity: number;
  costBasis: number;
  currency: string;
  closingPrice?: number | null;
  supported: boolean;
  plaidAccountId: string;
  security?: {
    __typename?: "Security";
    id: string;
    asOfDate?: Date | null;
    buyStatus: SecurityTradeStatus;
    sellStatus: SecurityTradeStatus;
    cusip?: string | null;
    isin?: string | null;
    name: string;
    symbol: string;
    type: SecurityType;
    subType: SecuritySubType;
    stockQuote?: {
      __typename?: "StockQuote";
      symbol: string;
      price: Decimal;
      name?: string | null;
      change?: Decimal | null;
      changesPercentage?: Decimal | null;
      open?: Decimal | null;
      previousClose?: Decimal | null;
      volume?: Decimal | null;
      eventTime?: Date | null;
      marginRequirement?: {
        __typename?: "MarginRequirement";
        symbol: string;
        maintenanceRequirement: Decimal;
      } | null;
    } | null;
  } | null;
};

export type PlaidAccountWithPortfolioFragment = {
  __typename?: "PlaidAccount";
  id: string;
  plaidInstitutionId?: string | null;
  name: string;
  type: PlaidAccountType;
  subType: PlaidAccountSubType;
  officialName?: string | null;
  institutionName: string;
  logo?: string | null;
  mask?: string | null;
  verificationStatus?: string | null;
  wireRoutingNumber?: string | null;
  accountNumber?: string | null;
  hasMissingAccountNumber: boolean;
  status: PlaidAccountStatus;
  owners?: string | null;
  error?: PlaidAccountError | null;
  portfolio?: {
    __typename?: "PlaidAccountPortfolio";
    cashBalance: Decimal;
    totalBalance?: Decimal | null;
    error?: PlaidAccountError | null;
    holdings: Array<{
      __typename?: "PlaidAccountPortfolioHolding";
      securityId?: string | null;
      name: string;
      symbol: string;
      cusip?: string | null;
      quantity: number;
      costBasis: number;
      currency: string;
      closingPrice?: number | null;
      supported: boolean;
      plaidAccountId: string;
      security?: {
        __typename?: "Security";
        id: string;
        asOfDate?: Date | null;
        buyStatus: SecurityTradeStatus;
        sellStatus: SecurityTradeStatus;
        cusip?: string | null;
        isin?: string | null;
        name: string;
        symbol: string;
        type: SecurityType;
        subType: SecuritySubType;
        stockQuote?: {
          __typename?: "StockQuote";
          symbol: string;
          price: Decimal;
          name?: string | null;
          change?: Decimal | null;
          changesPercentage?: Decimal | null;
          open?: Decimal | null;
          previousClose?: Decimal | null;
          volume?: Decimal | null;
          eventTime?: Date | null;
          marginRequirement?: {
            __typename?: "MarginRequirement";
            symbol: string;
            maintenanceRequirement: Decimal;
          } | null;
        } | null;
      } | null;
    }>;
  } | null;
};

export type PortfolioDataFragment = {
  __typename?: "Portfolio";
  clearingAccountId: string;
  availability: AvailabilityLevel;
  asOfDate?: Date | null;
  securities?: Array<{
    __typename?: "LiveSecurityHolding";
    clearingAccountId: string;
    securityId: string;
    subAccountId: string;
    subAccountType: SubAccountType;
    quantity: Decimal;
    avgCostBasis?: Decimal | null;
    dividendYtd?: Decimal | null;
    security?: {
      __typename?: "Security";
      id: string;
      asOfDate?: Date | null;
      buyStatus: SecurityTradeStatus;
      sellStatus: SecurityTradeStatus;
      cusip?: string | null;
      isin?: string | null;
      name: string;
      symbol: string;
      type: SecurityType;
      subType: SecuritySubType;
      stockQuote?: {
        __typename?: "StockQuote";
        symbol: string;
        price: Decimal;
        name?: string | null;
        change?: Decimal | null;
        changesPercentage?: Decimal | null;
        open?: Decimal | null;
        previousClose?: Decimal | null;
        volume?: Decimal | null;
        eventTime?: Date | null;
        marginRequirement?: {
          __typename?: "MarginRequirement";
          symbol: string;
          maintenanceRequirement: Decimal;
        } | null;
      } | null;
      mmfMetadata?: {
        __typename?: "MMFMetadata";
        name: string;
        cusip: string;
        symbol: string;
        nav: number;
        netAssets: number;
        secYield7Day: number;
        expenseRatio: number;
        inceptionDate: Date;
        minInvestment: number;
        ytdYield: number;
        dividendSchedule: MmfDividendScheduleFrequency;
        lastUpdated?: Date | null;
        link: string;
      } | null;
      symbolAlias?: Array<{
        __typename?: "SymbolAlias";
        symbol: string;
        context: Array<SecurityAliasContext | null>;
      } | null> | null;
    } | null;
  }> | null;
  cash?: Array<{
    __typename?: "LiveCashHolding";
    type: LiveCashHoldingType;
    subAccountId: string;
    subAccountType: SubAccountType;
    value: Decimal;
  }> | null;
};

export type PortfolioSnapshotFragment = {
  __typename?: "PortfolioSnapshot";
  date: Date;
  cashBalance: Decimal;
  loan: Decimal;
  fees: Decimal;
  portfolioSnapshotHoldingClosingValue: Decimal;
};

export type ComparisonPortfolioSnapshotFragment = {
  __typename?: "ComparisonPortfolioSnapshot";
  date: Date;
  snapshotHoldingClosingValue: Decimal;
};

export type ReferralRewardDetailsFragment = {
  __typename?: "ReferralRewardDetails";
  grantedOn?: Date | null;
  rewardReceived: string;
  rewardReceivedAdditionalInfo?: string | null;
  kind: RewardKind;
  referralRewardType:
    | { __typename?: "CashRewardType"; amount: Decimal }
    | { __typename?: "TreasuryRewardType"; basisPoints: Decimal };
};

type ReferralRewardType_CashRewardType_Fragment = {
  __typename?: "CashRewardType";
  amount: Decimal;
};

type ReferralRewardType_TreasuryRewardType_Fragment = {
  __typename?: "TreasuryRewardType";
  basisPoints: Decimal;
};

export type ReferralRewardTypeFragment =
  | ReferralRewardType_CashRewardType_Fragment
  | ReferralRewardType_TreasuryRewardType_Fragment;

export type ScheduledDepositConfigFragment = {
  __typename?: "ScheduledDepositConfig";
  id: string;
  type: ScheduledDepositType;
  sourceType: MoneyMovementSourceType;
  amount: Decimal;
  dayOfPeriod?: number | null;
  secondaryDayOfPeriod?: number | null;
  periodType: ScheduledDepositPeriodType;
  clearingAccountId: string;
  subAccountId: string;
  primaryAccountId?: string | null;
  sourceSubAccountId?: string | null;
  startAt: Date;
};

export type ScheduledRewardFragment = {
  __typename?: "ScheduledReward";
  id: string;
  subAccountId: string;
  promotionKey: string;
  promotionName: string;
  promotionTerms: string;
  creditDueDate: Date;
  creditAmount: Decimal;
  creditId?: string | null;
  createdAt: Date;
  updatedAt: Date;
};

export type SecurityFragment = {
  __typename?: "Security";
  id: string;
  asOfDate?: Date | null;
  buyStatus: SecurityTradeStatus;
  sellStatus: SecurityTradeStatus;
  cusip?: string | null;
  isin?: string | null;
  name: string;
  symbol: string;
  type: SecurityType;
  subType: SecuritySubType;
};

export type SecurityWithQuoteFragment = {
  __typename?: "Security";
  id: string;
  asOfDate?: Date | null;
  buyStatus: SecurityTradeStatus;
  sellStatus: SecurityTradeStatus;
  cusip?: string | null;
  isin?: string | null;
  name: string;
  symbol: string;
  type: SecurityType;
  subType: SecuritySubType;
  stockQuote?: {
    __typename?: "StockQuote";
    symbol: string;
    price: Decimal;
    name?: string | null;
    change?: Decimal | null;
    changesPercentage?: Decimal | null;
    open?: Decimal | null;
    previousClose?: Decimal | null;
    volume?: Decimal | null;
    eventTime?: Date | null;
    marginRequirement?: {
      __typename?: "MarginRequirement";
      symbol: string;
      maintenanceRequirement: Decimal;
    } | null;
  } | null;
  mmfMetadata?: {
    __typename?: "MMFMetadata";
    name: string;
    cusip: string;
    symbol: string;
    nav: number;
    netAssets: number;
    secYield7Day: number;
    expenseRatio: number;
    inceptionDate: Date;
    minInvestment: number;
    ytdYield: number;
    dividendSchedule: MmfDividendScheduleFrequency;
    lastUpdated?: Date | null;
    link: string;
  } | null;
  symbolAlias?: Array<{
    __typename?: "SymbolAlias";
    symbol: string;
    context: Array<SecurityAliasContext | null>;
  } | null> | null;
};

export type SelfManagedTaxLossConfigFragment = {
  __typename?: "SelfManagedTaxLossConfig";
  id: string;
  clearingAccountId: string;
  sweepToSubAccountId: string;
  sweepAll: boolean;
  securities: Array<string>;
};

export type SimpleActivityFragment = {
  __typename?: "SimpleActivity";
  id: string;
  activityType: ActivityType;
  title: string;
  numOfShares?: Decimal | null;
  status: StatusEnum;
  statusText?: string | null;
  date?: Date | null;
  value?: Decimal | null;
  description?: string | null;
  automated?: boolean | null;
};

export type StockAdjustmentDetailsFragment = {
  __typename?: "StockAdjustmentDetails";
  id: string;
  clearingAccountId: string;
  subAccountId: string;
  securityId: string;
  adjustmentAmount?: Decimal | null;
  adjustmentFactor: Decimal;
  adjustmentType: AdjustmentType;
  effectiveTime: Date;
  eventTime: Date;
  securityPrice?: Decimal | null;
  security?: {
    __typename?: "Security";
    id: string;
    asOfDate?: Date | null;
    buyStatus: SecurityTradeStatus;
    sellStatus: SecurityTradeStatus;
    cusip?: string | null;
    isin?: string | null;
    name: string;
    symbol: string;
    type: SecurityType;
    subType: SecuritySubType;
  } | null;
  treasuryFees?: Array<{
    __typename?: "TreasuryFee";
    id: string;
    amount: Decimal;
    status: TreasuryFeeStatus;
  }> | null;
};

export type StockQuoteFragment = {
  __typename?: "StockQuote";
  symbol: string;
  price: Decimal;
  name?: string | null;
  change?: Decimal | null;
  changesPercentage?: Decimal | null;
  open?: Decimal | null;
  previousClose?: Decimal | null;
  volume?: Decimal | null;
  eventTime?: Date | null;
  marginRequirement?: {
    __typename?: "MarginRequirement";
    symbol: string;
    maintenanceRequirement: Decimal;
  } | null;
};

export type SubAccountPortfolioAggregateFragment = {
  __typename?: "SubAccountPortfolioAggregate";
  subAccount?: {
    __typename?: "SubAccount";
    id: string;
    type: SubAccountType;
    directIndexAccountMetadata?: {
      __typename?: "DirectIndexAccountMetadata";
      type: DirectIndexType;
    } | null;
  } | null;
  aggregates: {
    __typename?: "PortfolioAggregateValues";
    securityValue: Decimal;
    mmfHoldingValue: Decimal;
    cashValue: Decimal;
    dailyChange: Decimal;
    mmfDividendYTD: Decimal;
    loanValue?: Decimal | null;
  };
};

export type SubAccountPricingFragment = {
  __typename?: "SubAccountPricing";
  id: string;
  amount: Decimal;
  unit: SubAccountPricingUnit;
  validFrom?: Date | null;
  validTo?: Date | null;
};

export type TaxLotEntryFragment = {
  __typename?: "TaxLotEntry";
  id?: string | null;
  securityId: string;
  subAccountId: string;
  symbol: string;
  eventTime: Date;
  longTermShortTermIndicator: string;
  netProceed: Decimal;
  quantity: Decimal;
  realizedIndicator: string;
  taxLotOpenBuyDate?: DateOnly | null;
  taxLotCloseSellDate?: DateOnly | null;
  realizedGainLoss: Decimal;
  washSalesIndicator: string;
  washSalesDisallowed: Decimal;
  costPerShare: Decimal;
  totalCost: Decimal;
};

export type TreasuryConfigFragment = {
  __typename?: "TreasuryConfig";
  subAccountId: string;
  eventTime: Date;
};

export type TreasuryExecutionFragment = {
  __typename?: "TreasuryExecution";
  id: string;
  treasuryConfigId: string;
  amount?: Decimal | null;
  status: TreasuryExecutionStatus;
  eventTime: Date;
  cashTransfer?: {
    __typename?: "TreasuryExecutionCashTransfer";
    id: string;
    treasuryExecutionId: string;
    cashTransferId: string;
    eventTime: Date;
  } | null;
  order?: {
    __typename?: "TreasuryExecutionOrder";
    id: string;
    treasuryExecutionId: string;
    eventTime: Date;
    batchOrderMetadataId: string;
  } | null;
};

export type TreasuryYieldFragment = {
  __typename?: "TreasuryYield";
  annualFeePercentage: Decimal;
  blendedSevenDayYieldPercentage?: Decimal | null;
  maxSevenDayYieldPercentage: Decimal;
  lastMonthNetYieldUSD?: Decimal | null;
  ytdNetYieldUSD?: Decimal | null;
  allTimeNetYieldUSD?: Decimal | null;
  supportedFundSymbols: Array<string>;
};

export type UserSourceFragment = {
  __typename?: "UserSource";
  utmSource?: string | null;
  utmMedium?: string | null;
  utmCampaign?: string | null;
};

export type GetAccountAcatsTransfersFragment = {
  __typename?: "ClearingAccount";
  getAccountAcatsTransfers?: {
    __typename?: "PaginatedAcatsTransferResponse";
    nextPageToken?: string | null;
    data?: Array<{
      __typename?: "AcatsTransfer";
      id: string;
      userId: string;
      clearingAccountId: string;
      acatsControlNumber?: string | null;
      associatedAcatsId?: string | null;
      transferType: AcatsTransferType;
      eventTime: Date;
      currentStatus: AcatsTransferStatus;
      direction: AcatsTransferDirection;
      accountOfficialName: string;
      accountInstitutionName: string;
      accountMask?: string | null;
      assets?: Array<{
        __typename?: "AcatsTransferAsset";
        symbol?: string | null;
        quantity?: Decimal | null;
        securityId?: string | null;
        cash?: Decimal | null;
        cashType?: string | null;
      }> | null;
      fee?: Array<{
        __typename?: "AcatsTransferFee";
        id: string;
        type: AcatsTransferFeeType;
        amount: Decimal;
      }> | null;
      statusHistory?: Array<{
        __typename?: "AcatsStatusHistory";
        id: string;
        acatsTransferId: string;
        frecStatus: AcatsTransferStatus;
        eventTime: Date;
        errors?: Array<{
          __typename?: "AcatsError";
          errorCode?: string | null;
          fieldName?: string | null;
          message?: string | null;
          attemptedValue?: string | null;
        }> | null;
      }> | null;
    }> | null;
  } | null;
};

export type GetAccountOrdersFragment = {
  __typename?: "Query";
  getAccountOrders: {
    __typename?: "PaginatedOrderResponse";
    nextPageToken?: string | null;
    data?: Array<{
      __typename?: "Order";
      id: string;
      userId: string;
      clearingAccountId: string;
      subAccountId: string;
      side: OrderSide;
      orderType: OrderType;
      securityId: string;
      quantityType?: QuantityType | null;
      quantity: Decimal;
      maxSellQuantity?: Decimal | null;
      limitPrice?: Decimal | null;
      limitDuration?: Date | null;
      orderPrice?: Decimal | null;
      eventTime: Date;
      orderStatus: OrderStatus;
      source?: BatchOrderSource | null;
      opsInitiated: boolean;
      shortTermGain?: Decimal | null;
      shortTermLoss?: Decimal | null;
      longTermGain?: Decimal | null;
      longTermLoss?: Decimal | null;
      statusHistory?: Array<{
        __typename?: "OrderStatusHistory";
        id: string;
        orderId: string;
        orderStatus: OrderStatus;
        eventTime: Date;
        fillShares?: Decimal | null;
        fillAvgPrice?: Decimal | null;
        fillNotional?: Decimal | null;
      }> | null;
      orderFees: Array<{
        __typename?: "OrderFee";
        id: string;
        type: OrderFeeType;
        amount: Decimal;
      }>;
      security?: {
        __typename?: "Security";
        id: string;
        asOfDate?: Date | null;
        buyStatus: SecurityTradeStatus;
        sellStatus: SecurityTradeStatus;
        cusip?: string | null;
        isin?: string | null;
        name: string;
        symbol: string;
        type: SecurityType;
        subType: SecuritySubType;
      } | null;
      lotMatchingInstructions?: Array<{
        __typename?: "LotMatchingInstruction";
        taxLotEntryId: string;
        tradeDate?: DateOnly | null;
        quantity: Decimal;
        price?: Decimal | null;
        realizedGainLoss?: Decimal | null;
        cost?: Decimal | null;
        longTermShortTermIndicator?: LongTermShortTermIndicatorEnumGql | null;
      }> | null;
    }> | null;
  };
};

export type GetMarginInterestChargesFragment = {
  __typename?: "Query";
  getMarginInterestCharges: {
    __typename?: "PaginatedMarginInterestChargesResponse";
    nextPageToken?: string | null;
    data?: Array<{
      __typename?: "MarginInterestCharge";
      id: string;
      amount: Decimal;
      eventTime: Date;
      billingStartDate: Date;
      billingEndDate: Date;
    }> | null;
  };
};

export type InitiateCashTransferMutationVariables = Exact<{
  input: InitiateCashTransferInput;
}>;

export type InitiateCashTransferMutation = {
  __typename?: "Mutation";
  initiateCashTransfer: {
    __typename?: "InitiateCashTransferResponse";
    status: boolean;
    transferId?: string | null;
    transferIdType?: InitiateCashTransferResultIdType | null;
  };
};

export type PlaidVerifyMicrodepositLinkTokenMutationVariables = Exact<{
  type: PlaidAccountType;
  plaidAccountId: Scalars["String"]["input"];
}>;

export type PlaidVerifyMicrodepositLinkTokenMutation = {
  __typename?: "Mutation";
  plaidVerifyMicrodepositLinkToken: {
    __typename?: "PlaidLinkToken";
    token: string;
    expiresAt: Date;
  };
};

export type AddManualAccountNumberToPlaidAccountMutationVariables = Exact<{
  plaidAccountId: Scalars["ID"]["input"];
  manualAccountNumber: Scalars["String"]["input"];
  checkPaymentsAuthorized?: InputMaybe<Scalars["Boolean"]["input"]>;
}>;

export type AddManualAccountNumberToPlaidAccountMutation = {
  __typename?: "Mutation";
  addManualAccountNumberToPlaidAccount?: {
    __typename?: "PlaidAccount";
    id: string;
    plaidInstitutionId?: string | null;
    name: string;
    type: PlaidAccountType;
    subType: PlaidAccountSubType;
    officialName?: string | null;
    institutionName: string;
    logo?: string | null;
    mask?: string | null;
    verificationStatus?: string | null;
    wireRoutingNumber?: string | null;
    accountNumber?: string | null;
    hasMissingAccountNumber: boolean;
    status: PlaidAccountStatus;
    owners?: string | null;
    error?: PlaidAccountError | null;
  } | null;
};

export type AddUserEmploymentDetailsMutationVariables = Exact<{
  data: UserEmploymentDetailsInput;
}>;

export type AddUserEmploymentDetailsMutation = {
  __typename?: "Mutation";
  addUserEmploymentDetails?: {
    __typename?: "UserEmploymentDetails";
    id: string;
    employerName?: string | null;
    employmentStatus: EmploymentStatus;
    yearsEmployed?: number | null;
    occupation?: string | null;
    addressLine1?: string | null;
    addressLine2?: string | null;
    city?: string | null;
    state?: string | null;
    zipCode?: string | null;
    country?: string | null;
  } | null;
};

export type AddUserMfaPreferenceMutationVariables = Exact<{
  input: UserMfaPreferenceInput;
}>;

export type AddUserMfaPreferenceMutation = {
  __typename?: "Mutation";
  addUserMfaPreference: {
    __typename?: "UserMfaPreferenceWithQrCode";
    qrCode?: string | null;
    setupKey?: string | null;
    userMfaPreference: {
      __typename?: "UserMfaPreference";
      id: string;
      type: MfaType;
      eventTime: Date;
    };
  };
};

export type AddUserTrustedContactMutationVariables = Exact<{
  data: UserTrustedContactInput;
}>;

export type AddUserTrustedContactMutation = {
  __typename?: "Mutation";
  addUserTrustedContact?: {
    __typename?: "UserTrustedContact";
    id: string;
    relationship: string;
    firstName: string;
    lastName: string;
    middleName?: string | null;
    email: string;
    phoneNumber: string;
    phoneNumberType: PhoneNumberType;
    addressLine1?: string | null;
    addressLine2?: string | null;
    city?: string | null;
    state?: string | null;
    zipCode?: string | null;
    country?: string | null;
  } | null;
};

export type SimulationAddTestClearingAccountMutationVariables = Exact<{
  data: ClearingAccountQaDataInput;
}>;

export type SimulationAddTestClearingAccountMutation = {
  __typename?: "Mutation";
  simulationAddTestClearingAccount?: string | null;
};

export type SimulationDeleteTestClearingAccountMutationVariables = Exact<{
  clearingAccountId: Scalars["String"]["input"];
}>;

export type SimulationDeleteTestClearingAccountMutation = {
  __typename?: "Mutation";
  simulationDeleteTestClearingAccount: boolean;
};

export type SimulationAddTestUserMutationVariables = Exact<{
  data: UserQaDataInput;
}>;

export type SimulationAddTestUserMutation = {
  __typename?: "Mutation";
  simulationAddTestUser?: string | null;
};

export type SimulationDeleteTestUserMutationVariables = Exact<{
  userId: Scalars["String"]["input"];
}>;

export type SimulationDeleteTestUserMutation = {
  __typename?: "Mutation";
  simulationDeleteTestUser: boolean;
};

export type SimulationAddSimulationEventMutationVariables = Exact<{
  event: ApexSimulationEventInput;
}>;

export type SimulationAddSimulationEventMutation = {
  __typename?: "Mutation";
  simulationAddSimulationEvent: boolean;
};

export type SimulationAcatsTransferOpsActionMutationVariables = Exact<{
  input: OpsAcatsTransferInput;
}>;

export type SimulationAcatsTransferOpsActionMutation = {
  __typename?: "Mutation";
  simulationAcatsTransferOpsAction: boolean;
};

export type IntervalProcessDelayedEventsMutationVariables = Exact<{
  eventType: EventType;
}>;

export type IntervalProcessDelayedEventsMutation = {
  __typename?: "Mutation";
  intervalProcessDelayedEvents: boolean;
};

export type IntervalRefreshStatusesMutationVariables = Exact<{
  eventType: EventType;
}>;

export type IntervalRefreshStatusesMutation = {
  __typename?: "Mutation";
  intervalRefreshStatuses: boolean;
};

export type IntervalProcessMarginCallsMutationVariables = Exact<{
  date: Scalars["Date"]["input"];
}>;

export type IntervalProcessMarginCallsMutation = {
  __typename?: "Mutation";
  intervalProcessMarginCalls: {
    __typename?: "ProcessMarginCallResponse";
    result: ProcessMarginCallResult;
    newMarginCalls: number;
    resolvedMarginCalls: number;
    openMarginCalls: number;
    errorMessage?: string | null;
  };
};

export type IntervalProcessPendingLoansMutationVariables = Exact<{
  [key: string]: never;
}>;

export type IntervalProcessPendingLoansMutation = {
  __typename?: "Mutation";
  intervalProcessPendingLoans: Array<{
    __typename?: "PendingLoan";
    id: string;
  }>;
};

export type IntervalGenerateDirectIndexingCandidatesMutationVariables = Exact<{
  asOfDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  dryRun?: InputMaybe<Scalars["Boolean"]["input"]>;
  forceRebalance?: InputMaybe<Scalars["Boolean"]["input"]>;
  autoApprove?: InputMaybe<Scalars["Boolean"]["input"]>;
  subAccountId: Scalars["ID"]["input"];
}>;

export type IntervalGenerateDirectIndexingCandidatesMutation = {
  __typename?: "Mutation";
  intervalGenerateDirectIndexingCandidates: Array<{
    __typename?: "GenerateCandidatesResponse";
    subAccountId: string;
    reason?: string | null;
    execution?: {
      __typename?: "DirectIndexTradeExecution";
      id: string;
      status: TradeExecutionStatus;
    } | null;
  }>;
};

export type SimulationDirectIndexExecutionMutationVariables = Exact<{
  directIndexTradeExecutionId: Scalars["ID"]["input"];
}>;

export type SimulationDirectIndexExecutionMutation = {
  __typename?: "Mutation";
  simulationDirectIndexExecution: boolean;
};

export type SimulationDeleteSimulationResponsesMutationVariables = Exact<{
  responseIds: Array<Scalars["String"]["input"]> | Scalars["String"]["input"];
}>;

export type SimulationDeleteSimulationResponsesMutation = {
  __typename?: "Mutation";
  simulationDeleteSimulationResponses: number;
};

export type SimulationDeleteSimulationResponsesForSimulationIdMutationVariables =
  Exact<{
    simulationId: Scalars["Int"]["input"];
  }>;

export type SimulationDeleteSimulationResponsesForSimulationIdMutation = {
  __typename?: "Mutation";
  simulationDeleteSimulationResponsesForSimulationId: number;
};

export type SimulationAddSimulationResponsesMutationVariables = Exact<{
  input: Array<MarketDataSimulationArgs> | MarketDataSimulationArgs;
}>;

export type SimulationAddSimulationResponsesMutation = {
  __typename?: "Mutation";
  simulationAddSimulationResponses: number;
};

export type ApplyInviteCodeMutationVariables = Exact<{
  inviteCode: Scalars["String"]["input"];
}>;

export type ApplyInviteCodeMutation = {
  __typename?: "Mutation";
  applyInviteCode: boolean;
};

export type CancelAchDepositMutationVariables = Exact<{
  transferId: Scalars["ID"]["input"];
}>;

export type CancelAchDepositMutation = {
  __typename?: "Mutation";
  cancelAchDeposit: {
    __typename?: "CancelAchDepositResponse";
    status: CashTransferStatus;
  };
};

export type CancelAchWithdrawalMutationVariables = Exact<{
  transferId: Scalars["ID"]["input"];
}>;

export type CancelAchWithdrawalMutation = {
  __typename?: "Mutation";
  cancelAchWithdrawal: {
    __typename?: "CancelAchWithdrawalResponse";
    status: CashTransferStatus;
  };
};

export type CancelLiquidateDirectIndexRequestMutationVariables = Exact<{
  requestId: Scalars["ID"]["input"];
}>;

export type CancelLiquidateDirectIndexRequestMutation = {
  __typename?: "Mutation";
  cancelLiquidateDirectIndexPortfolio: {
    __typename?: "LiquidateDirectIndexPortfolioRequest";
    id: string;
    subAccountId: string;
    status: LiquidateDirectIndexPortfolioStatus;
    amount?: Decimal | null;
    isFullLiquidation?: boolean | null;
    eventTime: Date;
  };
};

export type CancelPendingLoanMutationVariables = Exact<{
  id: Scalars["ID"]["input"];
}>;

export type CancelPendingLoanMutation = {
  __typename?: "Mutation";
  cancelPendingLoan: { __typename?: "PendingLoan"; id: string };
};

export type CancelTradeOrderMutationVariables = Exact<{
  orderId: Scalars["ID"]["input"];
}>;

export type CancelTradeOrderMutation = {
  __typename?: "Mutation";
  cancelTradeOrder: {
    __typename?: "CancelOrderResponse";
    order: {
      __typename?: "Order";
      id: string;
      userId: string;
      clearingAccountId: string;
      side: OrderSide;
      orderType: OrderType;
      securityId: string;
      quantityType?: QuantityType | null;
      quantity: Decimal;
      maxSellQuantity?: Decimal | null;
      limitPrice?: Decimal | null;
      limitDuration?: Date | null;
      orderPrice?: Decimal | null;
      eventTime: Date;
      orderStatus: OrderStatus;
    };
  };
};

export type CancelWireWithdrawalMutationVariables = Exact<{
  transferId: Scalars["ID"]["input"];
  clearingAccountId: Scalars["ID"]["input"];
}>;

export type CancelWireWithdrawalMutation = {
  __typename?: "Mutation";
  cancelWireWithdrawal: {
    __typename?: "CancelWireWithdrawalResponse";
    cashTransfer: { __typename?: "CashTransfer"; status: CashTransferStatus };
  };
};

export type CashTransferToDirectIndexPortfolioMutationVariables = Exact<{
  input: CashTransferToDirectIndexPortfolioInput;
}>;

export type CashTransferToDirectIndexPortfolioMutation = {
  __typename?: "Mutation";
  cashTransferToDirectIndexPortfolio: boolean;
};

export type CloseClearingHouseAccountMutationVariables = Exact<{
  accountId: Scalars["ID"]["input"];
}>;

export type CloseClearingHouseAccountMutation = {
  __typename?: "Mutation";
  requestClearingHouseAccountClose: {
    __typename?: "UpdateClearingHouseAccountStateResponse";
    status: string;
  };
};

export type CreateClearingHouseAccountMutationVariables = Exact<{
  userId: Scalars["ID"]["input"];
}>;

export type CreateClearingHouseAccountMutation = {
  __typename?: "Mutation";
  createClearingHouseAccountForIndividual: {
    __typename?: "ClearingAccount";
    id: string;
  };
};

export type CreateClearingHouseAccountForTrustMutationVariables = Exact<{
  userId: Scalars["ID"]["input"];
}>;

export type CreateClearingHouseAccountForTrustMutation = {
  __typename?: "Mutation";
  createClearingHouseAccountForTrust: {
    __typename?: "ClearingAccount";
    id: string;
  };
};

export type CreateDirectIndexSubAccountMutationVariables = Exact<{
  input: CreateDirectIndexSubAccountInput;
}>;

export type CreateDirectIndexSubAccountMutation = {
  __typename?: "Mutation";
  createDirectIndexSubAccount: {
    __typename?: "ClearingAccount";
    id: string;
    accountType?: AccountType | null;
    categorizationType?: CategorizationType | null;
    externalClearingAccountId: string;
    eventTime?: Date | null;
    isTestAccount?: boolean | null;
    isPatternDayTrader?: boolean | null;
    isClientAccount?: boolean | null;
    primarySubAccountId: string;
    subAccounts: Array<{
      __typename?: "SubAccount";
      id: string;
      type: SubAccountType;
      eventTime: Date;
      pricing?: Array<{
        __typename?: "SubAccountPricing";
        id: string;
        amount: Decimal;
        unit: SubAccountPricingUnit;
        validFrom?: Date | null;
        validTo?: Date | null;
      }> | null;
      directIndexAccountMetadata?: {
        __typename?: "DirectIndexAccountMetadata";
        id: string;
        type: DirectIndexType;
        etfSymbol?: string | null;
        signedAt?: Date | null;
        lhfOverride?: Decimal | null;
        subAccountId: string;
        status?: DirectIndexStatus | null;
        customization?: {
          __typename?: "DirectIndexCustomization";
          id: string;
          removeGICSSectorIds: Array<GicsCode>;
          addSecurityIds: Array<{
            __typename?: "IndexCustomizationSecurity";
            securityId: string;
            security?: {
              __typename?: "Security";
              id: string;
              asOfDate?: Date | null;
              buyStatus: SecurityTradeStatus;
              sellStatus: SecurityTradeStatus;
              cusip?: string | null;
              isin?: string | null;
              name: string;
              symbol: string;
              type: SecurityType;
              subType: SecuritySubType;
            } | null;
          }>;
          removeSecurityIds: Array<{
            __typename?: "IndexCustomizationSecurity";
            securityId: string;
            security?: {
              __typename?: "Security";
              id: string;
              asOfDate?: Date | null;
              buyStatus: SecurityTradeStatus;
              sellStatus: SecurityTradeStatus;
              cusip?: string | null;
              isin?: string | null;
              name: string;
              symbol: string;
              type: SecurityType;
              subType: SecuritySubType;
            } | null;
          }>;
        } | null;
      } | null;
    }>;
    marginCallData?: {
      __typename?: "MarginCallData";
      id: string;
      dueDate: Date;
      eventTime: Date;
      statuses: Array<{
        __typename?: "MarginCallDataStatus";
        state: MarginCallState;
        amount: Decimal;
        eventTime: Date;
      }>;
    } | null;
    actionContexts?: Array<{
      __typename?: "ActionContextStatus";
      actionContext: ActionContext;
      isAllowed: boolean;
    }> | null;
    restrictions?: Array<{
      __typename?: "ClearingAccountRestriction";
      restrictionType: ClearingAccountRestrictionType;
      active: boolean;
    }> | null;
    entity?:
      | {
          __typename?: "BusinessEntityInfo";
          businessEntityDetails: {
            __typename?: "BusinessEntityDetails";
            id: string;
            name: string;
            phoneNumber: string;
            industry: BusinessEntityIndustry;
            address?: Array<{
              __typename?: "GenericAddress";
              addressType: AddressType;
              addressLine1: string;
              addressLine2?: string | null;
              city: string;
              state: string;
              zipCode: string;
              country: string;
            }> | null;
          };
          entityAuthorizedUser: {
            __typename?: "BusinessEntityAuthorizedUser";
            userTitle: string;
            user?: {
              __typename?: "User";
              userPersonalDetails?: {
                __typename?: "UserPersonalDetails";
                fullName: string;
              } | null;
              userIdentityDetails?: {
                __typename?: "UserIdentityDetails";
                citizenshipCountry: string;
                dateOfBirth: DateOnly;
                ssn: string;
              } | null;
              userContactPhone?: {
                __typename?: "UserContactPhone";
                phoneNumber: string;
              } | null;
              userContactEmail?: {
                __typename?: "UserContactEmail";
                email: string;
              } | null;
            } | null;
          };
          entityIndividuals: Array<{
            __typename?: "BusinessEntityIndividual";
            familyName: string;
            givenName: string;
            dateOfBirth: DateOnly;
            individualType: BusinessEntityIndividualType;
            address?: Array<{
              __typename?: "GenericAddress";
              addressType: AddressType;
              addressLine1: string;
              addressLine2?: string | null;
              city: string;
              state: string;
              zipCode: string;
              country: string;
            }> | null;
          }>;
        }
      | {
          __typename?: "TrustEntity";
          trustEntityDetails: {
            __typename?: "TrustEntityDetails";
            id: string;
            name: string;
            taxId: string;
            taxIdType?: TaxIdType | null;
            dateOfCreation: DateOnly;
            revocability?: TrustRevocability | null;
            state: string;
            trustAgreementAllowsMarginsAndShortPositions?: boolean | null;
            address?: {
              __typename?: "GenericAddress";
              id: string;
              addressType: AddressType;
              addressLine1: string;
              addressLine2?: string | null;
              city: string;
              state: string;
              zipCode: string;
              country: string;
            } | null;
          };
          primaryTrustee?: {
            __typename?: "User";
            userContactEmail?: {
              __typename?: "UserContactEmail";
              email: string;
              emailType: EmailType;
            } | null;
            userEmploymentDetails?: Array<{
              __typename?: "UserEmploymentDetails";
              employerName?: string | null;
              occupation?: string | null;
            }> | null;
            userIdentityDetails?: {
              __typename?: "UserIdentityDetails";
              dateOfBirth: DateOnly;
              citizenshipCountry: string;
            } | null;
            userPersonalDetails?: {
              __typename?: "UserPersonalDetails";
              fullName: string;
            } | null;
            userContactPhone?: {
              __typename?: "UserContactPhone";
              phoneNumber: string;
            } | null;
            userAddresses?: Array<{
              __typename?: "GenericAddress";
              addressType: AddressType;
              addressLine1: string;
              addressLine2?: string | null;
              city: string;
              state: string;
              zipCode: string;
              country: string;
            }> | null;
          } | null;
          additionalTrustees: Array<{
            __typename?: "Trustee";
            id: string;
            name: string;
            familyName?: string | null;
            givenName?: string | null;
            dateOfBirth: DateOnly;
            idType?: TrusteeIdType | null;
            idIssuingStateOrCountry?: string | null;
            idExpirationDate?: DateOnly | null;
            governmentIdNumber?: string | null;
            disclosure?: {
              __typename?: "IndividualDisclosure";
              id: string;
              affiliatedExchangeOrFINRA: boolean;
              firmName?: string | null;
              controlPerson: boolean;
              companySymbols?: string | null;
              politicallyExposed: boolean;
              politicalOrganization?: string | null;
              politicalTitle?: string | null;
              immediateFamilyMembers?: Array<string> | null;
            } | null;
            address: {
              __typename?: "GenericAddress";
              id: string;
              addressType: AddressType;
              addressLine1: string;
              addressLine2?: string | null;
              city: string;
              state: string;
              zipCode: string;
              country: string;
            };
          }>;
        }
      | null;
    treasury?: {
      __typename?: "TreasuryConfig";
      subAccountId: string;
      eventTime: Date;
    } | null;
    dividendPreference?: Array<{
      __typename?: "DividendPreference";
      id: string;
      clearingAccountId: string;
      subAccountType: SubAccountType;
      preferenceType: DividendPreferenceType;
    }> | null;
  };
};

export type CreateDividendPreferenceMutationVariables = Exact<{
  input: DividendPreferenceInput;
}>;

export type CreateDividendPreferenceMutation = {
  __typename?: "Mutation";
  createDividendPreference: {
    __typename?: "DividendPreference";
    id: string;
    clearingAccountId: string;
    subAccountType: SubAccountType;
    preferenceType: DividendPreferenceType;
  };
};

export type CreateLinkedBrokerageAccountMutationVariables = Exact<{
  accountNumber: Scalars["String"]["input"];
  name: Scalars["String"]["input"];
  nsccNumber: Scalars["String"]["input"];
}>;

export type CreateLinkedBrokerageAccountMutation = {
  __typename?: "Mutation";
  createLinkedBrokerageAccount?: boolean | null;
};

export type CreateLinkedBrokerageAccountFromPlaidAccountMutationVariables =
  Exact<{
    plaidAccountId: Scalars["ID"]["input"];
    accountNumber: Scalars["String"]["input"];
  }>;

export type CreateLinkedBrokerageAccountFromPlaidAccountMutation = {
  __typename?: "Mutation";
  createLinkedBrokerageAccountFromPlaidAccount?: boolean | null;
};

export type CreatePendingLoanMutationVariables = Exact<{
  input: CreatePendingLoanInput;
}>;

export type CreatePendingLoanMutation = {
  __typename?: "Mutation";
  createPendingLoan: {
    __typename?: "PendingLoan";
    id: string;
    amount: Decimal;
  };
};

export type CreatePersonalDetailsAndAddressMutationVariables = Exact<{
  userDetails: UserPersonalDetailsInput;
  userAddress: GenericAddressInput;
}>;

export type CreatePersonalDetailsAndAddressMutation = {
  __typename?: "Mutation";
  addUserPersonalDetails?: {
    __typename?: "UserPersonalDetails";
    id: string;
    firstName: string;
    lastName: string;
    middleName?: string | null;
  } | null;
  addUserAddress?: {
    __typename?: "GenericAddress";
    id: string;
    addressType: AddressType;
    addressLine1: string;
    addressLine2?: string | null;
    city: string;
    state: string;
    zipCode: string;
    country: string;
  } | null;
};

export type CreateScheduledDepositConfigMutationVariables = Exact<{
  clearingAccountId: Scalars["ID"]["input"];
  subAccountId: Scalars["ID"]["input"];
  input: ScheduledDepositConfigInput;
}>;

export type CreateScheduledDepositConfigMutation = {
  __typename?: "Mutation";
  createScheduledDepositConfig: {
    __typename?: "ScheduledDepositConfig";
    id: string;
    type: ScheduledDepositType;
    sourceType: MoneyMovementSourceType;
    amount: Decimal;
    dayOfPeriod?: number | null;
    secondaryDayOfPeriod?: number | null;
    periodType: ScheduledDepositPeriodType;
    clearingAccountId: string;
    subAccountId: string;
    primaryAccountId?: string | null;
    sourceSubAccountId?: string | null;
    startAt: Date;
  };
};

export type CreateStockTransferMutationVariables = Exact<{
  input: AcatsCreateTransferInput;
  simulatorConfig?: InputMaybe<ApexAcatsSimulatorConfig>;
}>;

export type CreateStockTransferMutation = {
  __typename?: "Mutation";
  acatsCreateTransfer: {
    __typename?: "AcatsCreateTransferResponse";
    acatsTransferId?: string | null;
    errors?: Array<{
      __typename?: "AcatsError";
      errorCode?: string | null;
      message?: string | null;
    }> | null;
  };
};

export type DeleteBusinessEntityIndividualMutationVariables = Exact<{
  id: Scalars["ID"]["input"];
}>;

export type DeleteBusinessEntityIndividualMutation = {
  __typename?: "Mutation";
  deleteBusinessEntityIndividual: boolean;
};

export type DeleteDepositAccountMutationVariables = Exact<{
  id: Scalars["ID"]["input"];
}>;

export type DeleteDepositAccountMutation = {
  __typename?: "Mutation";
  deleteDepositAccount?: { __typename?: "DepositAccount"; id: string } | null;
};

export type DeleteDocumentMutationVariables = Exact<{
  id: Scalars["ID"]["input"];
}>;

export type DeleteDocumentMutation = {
  __typename?: "Mutation";
  deleteDocument: boolean;
};

export type DeleteLinkedBrokerageAccountMutationVariables = Exact<{
  id: Scalars["ID"]["input"];
}>;

export type DeleteLinkedBrokerageAccountMutation = {
  __typename?: "Mutation";
  deleteLinkedBrokerageAccount?: boolean | null;
};

export type DeletePlaidAccountMutationVariables = Exact<{
  id: Scalars["ID"]["input"];
}>;

export type DeletePlaidAccountMutation = {
  __typename?: "Mutation";
  deletePlaidAccount?: boolean | null;
};

export type DeleteScheduledDepositConfigMutationVariables = Exact<{
  id: Scalars["ID"]["input"];
}>;

export type DeleteScheduledDepositConfigMutation = {
  __typename?: "Mutation";
  deleteScheduledDepositConfig: boolean;
};

export type DeleteUserTrustedContactMutationVariables = Exact<{
  trustedContactId: Scalars["ID"]["input"];
}>;

export type DeleteUserTrustedContactMutation = {
  __typename?: "Mutation";
  deleteUserTrustedContact: boolean;
};

export type DisableUserMfaPreferenceMutationVariables = Exact<{
  id: Scalars["ID"]["input"];
}>;

export type DisableUserMfaPreferenceMutation = {
  __typename?: "Mutation";
  disableUserMfaPreference: boolean;
};

export type ExpirePlaidAccountMutationVariables = Exact<{
  id: Scalars["ID"]["input"];
}>;

export type ExpirePlaidAccountMutation = {
  __typename?: "Mutation";
  expirePlaidAccount?: {
    __typename?: "PlaidAccount";
    id: string;
    plaidInstitutionId?: string | null;
    name: string;
    type: PlaidAccountType;
    subType: PlaidAccountSubType;
    officialName?: string | null;
    institutionName: string;
    logo?: string | null;
    mask?: string | null;
    verificationStatus?: string | null;
    wireRoutingNumber?: string | null;
    accountNumber?: string | null;
    hasMissingAccountNumber: boolean;
    status: PlaidAccountStatus;
    owners?: string | null;
    error?: PlaidAccountError | null;
  } | null;
};

export type GenerateUserCodeMutationVariables = Exact<{
  input: GenerateUserCodeInput;
}>;

export type GenerateUserCodeMutation = {
  __typename?: "Mutation";
  generateUserCode: { __typename?: "GenerateUserCodeResponse"; code: string };
};

export type HandleEmailCaptureMutationVariables = Exact<{
  email: Scalars["String"]["input"];
}>;

export type HandleEmailCaptureMutation = {
  __typename?: "Mutation";
  handleEmailCapture: boolean;
};

export type InitiateLoanMutationVariables = Exact<{
  input: InitiateLoanInput;
}>;

export type InitiateLoanMutation = {
  __typename?: "Mutation";
  initiateLoan: {
    __typename?: "LoanResponse";
    loanId?: string | null;
    errors?: Array<{
      __typename?: "LoanError";
      errorCode?: string | null;
      message?: string | null;
    } | null> | null;
  };
};

export type InitiateTreasuryTransferMutationVariables = Exact<{
  input: TreasuryTransferInput;
}>;

export type InitiateTreasuryTransferMutation = {
  __typename?: "Mutation";
  initiateTreasuryTransfer: {
    __typename?: "TreasuryExecution";
    id: string;
    treasuryConfigId: string;
    amount?: Decimal | null;
    status: TreasuryExecutionStatus;
    eventTime: Date;
    cashTransfer?: {
      __typename?: "TreasuryExecutionCashTransfer";
      id: string;
      treasuryExecutionId: string;
      cashTransferId: string;
      eventTime: Date;
    } | null;
    order?: {
      __typename?: "TreasuryExecutionOrder";
      id: string;
      treasuryExecutionId: string;
      eventTime: Date;
      batchOrderMetadataId: string;
    } | null;
  };
};

export type InitiateWireDepositMutationVariables = Exact<{
  input: InitiateWireDepositInput;
}>;

export type InitiateWireDepositMutation = {
  __typename?: "Mutation";
  initiateWireDeposit: {
    __typename?: "InitiateWireDepositResponse";
    id: string;
    status: string;
  };
};

export type InitiateWireWithdrawalMutationVariables = Exact<{
  input: InitiateWireWithdrawalInput;
}>;

export type InitiateWireWithdrawalMutation = {
  __typename?: "Mutation";
  initiateWireWithdrawal: {
    __typename?: "InitiateWireWithdrawalResponse";
    cashTransfer: { __typename?: "CashTransfer"; id: string };
  };
};

export type IntervalExecuteTreasuryConfigsMutationVariables = Exact<{
  [key: string]: never;
}>;

export type IntervalExecuteTreasuryConfigsMutation = {
  __typename?: "Mutation";
  intervalExecuteTreasuryConfigs: number;
};

export type IntervalExecuteTreasuryExecutionsMutationVariables = Exact<{
  [key: string]: never;
}>;

export type IntervalExecuteTreasuryExecutionsMutation = {
  __typename?: "Mutation";
  intervalExecuteTreasuryExecutions: number;
};

export type IntervalExecuteQueuedOrdersMutationVariables = Exact<{
  [key: string]: never;
}>;

export type IntervalExecuteQueuedOrdersMutation = {
  __typename?: "Mutation";
  intervalExecuteQueuedOrders: number;
};

export type IntervalAssessTreasuryFeesMutationVariables = Exact<{
  [key: string]: never;
}>;

export type IntervalAssessTreasuryFeesMutation = {
  __typename?: "Mutation";
  intervalAssessTreasuryFees: number;
};

export type IntervalExecuteBatchUpdateUsersMutationVariables = Exact<{
  [key: string]: never;
}>;

export type IntervalExecuteBatchUpdateUsersMutation = {
  __typename?: "Mutation";
  intervalExecuteBatchUpdateUsers: boolean;
};

export type IntraAccountStockTransferFromDiMutationVariables = Exact<{
  input: TransferStockFromDiInput;
}>;

export type IntraAccountStockTransferFromDiMutation = {
  __typename?: "Mutation";
  intraAccountStockTransferFromDI: boolean;
};

export type IntraAccountStockTransferToDiMutationVariables = Exact<{
  input: TransferStockSubAccountInput;
}>;

export type IntraAccountStockTransferToDiMutation = {
  __typename?: "Mutation";
  intraAccountStockTransferToDI: boolean;
};

export type KeyValueSetMutationVariables = Exact<{
  input: KeyValueSetInput;
}>;

export type KeyValueSetMutation = {
  __typename?: "Mutation";
  keyValueSet: Array<{
    __typename?: "KeyValuePair";
    id: string;
    key: string;
    namespace: string;
    value: object;
    expiresAt?: Date | null;
  }>;
};

export type LinkPlaidAccountMutationVariables = Exact<{
  plaidPublicToken: Scalars["String"]["input"];
  type: PlaidAccountType;
}>;

export type LinkPlaidAccountMutation = {
  __typename?: "Mutation";
  linkPlaid: Array<{
    __typename?: "PlaidAccount";
    id: string;
    plaidInstitutionId?: string | null;
    name: string;
    type: PlaidAccountType;
    subType: PlaidAccountSubType;
    officialName?: string | null;
    institutionName: string;
    logo?: string | null;
    mask?: string | null;
    verificationStatus?: string | null;
    wireRoutingNumber?: string | null;
    accountNumber?: string | null;
    hasMissingAccountNumber: boolean;
    status: PlaidAccountStatus;
    owners?: string | null;
    error?: PlaidAccountError | null;
  }>;
};

export type LinkPlaidAsDepositAccountMutationVariables = Exact<{
  clearingAccountId: Scalars["ID"]["input"];
  plaidAccountId: Scalars["ID"]["input"];
}>;

export type LinkPlaidAsDepositAccountMutation = {
  __typename?: "Mutation";
  linkPlaidDepositAccountToClearingAccount: {
    __typename?: "DepositAccount";
    id: string;
    plaidAccountId?: string | null;
    routingNumber?: string | null;
    accountNumber?: string | null;
    wireRoutingNumber?: string | null;
    name?: string | null;
    officialName?: string | null;
    subType: string;
    apexRelationshipStatus?: string | null;
  };
};

export type PlaceBatchTradeOrderMutationVariables = Exact<{
  input: PlaceBatchTradeOrderInput;
}>;

export type PlaceBatchTradeOrderMutation = {
  __typename?: "Mutation";
  placeBatchTradeOrder: {
    __typename?: "PlaceBatchTradeOrderResponse";
    batchTradeOrder?: {
      __typename?: "BatchTradeOrder";
      batchOrderMetadataId: string;
      orders: Array<{ __typename?: "Order"; id: string }>;
    } | null;
    failures: Array<{ __typename?: "PlaceOrderFailure"; message: string }>;
  };
};

export type PlaidCreateLinkTokenMutationVariables = Exact<{
  type: PlaidAccountType;
  updateAccountId?: InputMaybe<Scalars["String"]["input"]>;
}>;

export type PlaidCreateLinkTokenMutation = {
  __typename?: "Mutation";
  plaidCreateLinkToken: {
    __typename?: "PlaidLinkToken";
    token: string;
    expiresAt: Date;
  };
};

export type RepayLoanMutationVariables = Exact<{
  input: RepayLoanInput;
}>;

export type RepayLoanMutation = {
  __typename?: "Mutation";
  repayLoan: {
    __typename?: "LoanResponse";
    loanId?: string | null;
    errors?: Array<{
      __typename?: "LoanError";
      errorCode?: string | null;
      message?: string | null;
    } | null> | null;
  };
};

export type RequestEmailVerificationMutationVariables = Exact<{
  clearingAccountId?: InputMaybe<Scalars["ID"]["input"]>;
}>;

export type RequestEmailVerificationMutation = {
  __typename?: "Mutation";
  sendEmailNotification?: boolean | null;
};

export type RequestMfaPhoneChallengeMutationVariables = Exact<{
  input: RequestMfaPhoneChallengeInput;
}>;

export type RequestMfaPhoneChallengeMutation = {
  __typename?: "Mutation";
  requestMfaPhoneChallenge: {
    __typename?: "RequestMfaPhoneChallengeResponse";
    status: boolean;
    phone: string;
  };
};

export type RequestPasswordResetEmailMutationVariables = Exact<{
  email: Scalars["String"]["input"];
}>;

export type RequestPasswordResetEmailMutation = {
  __typename?: "Mutation";
  requestPasswordReset: boolean;
};

export type RequestVerificationCodeMutationVariables = Exact<{
  phone: Scalars["String"]["input"];
  channel: PhoneVerificationChannel;
}>;

export type RequestVerificationCodeMutation = {
  __typename?: "Mutation";
  requestVerificationCode: {
    __typename?: "RequestVerificationCodeResponse";
    isCodeSent: boolean;
    codeSentAt?: string | null;
    message?: string | null;
  };
};

export type SendInviteViaEmailMutationVariables = Exact<{
  email: Scalars["String"]["input"];
  name?: InputMaybe<Scalars["String"]["input"]>;
}>;

export type SendInviteViaEmailMutation = {
  __typename?: "Mutation";
  sendInviteViaEmail: boolean;
};

export type SetDirectIndexSecurityOverridesMutationVariables = Exact<{
  input: DirectIndexOverrideInput;
}>;

export type SetDirectIndexSecurityOverridesMutation = {
  __typename?: "Mutation";
  setDirectIndexSecurityOverrides: {
    __typename?: "DirectIndexAccountWeights";
    subAccountId: string;
    weights: Array<{
      __typename?: "DirectIndexWeight";
      securityId: string;
      weight: Decimal;
      canLiquidate: boolean;
      canPurchase: boolean;
      canDisplayWeight: boolean;
      security?: {
        __typename?: "Security";
        id: string;
        asOfDate?: Date | null;
        buyStatus: SecurityTradeStatus;
        sellStatus: SecurityTradeStatus;
        cusip?: string | null;
        isin?: string | null;
        name: string;
        symbol: string;
        type: SecurityType;
        subType: SecuritySubType;
      } | null;
    }>;
  };
};

export type SetSelfManagedTaxLossConfigMutationVariables = Exact<{
  input: SelfManagedTaxLossConfigInput;
}>;

export type SetSelfManagedTaxLossConfigMutation = {
  __typename?: "Mutation";
  setSelfManagedTaxLossConfig: {
    __typename?: "SelfManagedTaxLossConfig";
    id: string;
    clearingAccountId: string;
    sweepToSubAccountId: string;
    sweepAll: boolean;
    securities: Array<string>;
  };
};

export type SetSelfManagedTaxLossSecuritiesMutationVariables = Exact<{
  input: SelfManagedTaxLossSecuritiesInput;
}>;

export type SetSelfManagedTaxLossSecuritiesMutation = {
  __typename?: "Mutation";
  setSelfManagedTaxLossSecurities: {
    __typename?: "SelfManagedTaxLossConfig";
    id: string;
    clearingAccountId: string;
    sweepToSubAccountId: string;
    sweepAll: boolean;
    securities: Array<string>;
  };
};

export type AddTreasuryConfigMutationVariables = Exact<{
  clearingAccountId: Scalars["ID"]["input"];
}>;

export type AddTreasuryConfigMutation = {
  __typename?: "Mutation";
  addTreasuryConfig: {
    __typename?: "TreasuryConfig";
    subAccountId: string;
    eventTime: Date;
  };
};

export type SetUserProgressFlagMutationVariables = Exact<{
  input: SetProgressFlagInput;
}>;

export type SetUserProgressFlagMutation = {
  __typename?: "Mutation";
  setProgressFlag?: Array<{
    __typename?: "UserProgressFlag";
    status?: ProgressFlagStatus | null;
    category?: string | null;
  } | null> | null;
};

export type SetUserSourceMutationVariables = Exact<{
  input: UserSourceInput;
}>;

export type SetUserSourceMutation = {
  __typename?: "Mutation";
  setUserSource?: {
    __typename?: "UserSource";
    utmSource?: string | null;
    utmMedium?: string | null;
    utmCampaign?: string | null;
  } | null;
};

export type SubmitEmailVerificationMutationVariables = Exact<{
  code: Scalars["String"]["input"];
}>;

export type SubmitEmailVerificationMutation = {
  __typename?: "Mutation";
  validateVerificationCode: boolean;
};

export type SubmitVerificationCodeMutationVariables = Exact<{
  phone: Scalars["String"]["input"];
  code: Scalars["String"]["input"];
}>;

export type SubmitVerificationCodeMutation = {
  __typename?: "Mutation";
  submitVerificationCode: boolean;
};

export type UpdateDividendPreferenceMutationVariables = Exact<{
  id: Scalars["ID"]["input"];
  input: DividendPreferenceInput;
}>;

export type UpdateDividendPreferenceMutation = {
  __typename?: "Mutation";
  updateDividendPreference: {
    __typename?: "DividendPreference";
    id: string;
    clearingAccountId: string;
    subAccountType: SubAccountType;
    preferenceType: DividendPreferenceType;
  };
};

export type UpdateUserEntityAccountRequestStatusMutationVariables = Exact<{
  input: UpdateUserEntityAccountRequestStatusInput;
}>;

export type UpdateUserEntityAccountRequestStatusMutation = {
  __typename?: "Mutation";
  updateEntityAccountRequestStatus: {
    __typename?: "User";
    id: string;
    isEmployee: boolean;
    inviteCodes?: Array<string> | null;
    clearingAccounts?: Array<{
      __typename?: "ClearingAccount";
      id: string;
      accountType?: AccountType | null;
      categorizationType?: CategorizationType | null;
      externalClearingAccountId: string;
      eventTime?: Date | null;
      isTestAccount?: boolean | null;
      isPatternDayTrader?: boolean | null;
      isClientAccount?: boolean | null;
      primarySubAccountId: string;
      subAccounts: Array<{
        __typename?: "SubAccount";
        id: string;
        type: SubAccountType;
        eventTime: Date;
        pricing?: Array<{
          __typename?: "SubAccountPricing";
          id: string;
          amount: Decimal;
          unit: SubAccountPricingUnit;
          validFrom?: Date | null;
          validTo?: Date | null;
        }> | null;
        directIndexAccountMetadata?: {
          __typename?: "DirectIndexAccountMetadata";
          id: string;
          type: DirectIndexType;
          etfSymbol?: string | null;
          signedAt?: Date | null;
          lhfOverride?: Decimal | null;
          subAccountId: string;
          status?: DirectIndexStatus | null;
          customization?: {
            __typename?: "DirectIndexCustomization";
            id: string;
            removeGICSSectorIds: Array<GicsCode>;
            addSecurityIds: Array<{
              __typename?: "IndexCustomizationSecurity";
              securityId: string;
              security?: {
                __typename?: "Security";
                id: string;
                asOfDate?: Date | null;
                buyStatus: SecurityTradeStatus;
                sellStatus: SecurityTradeStatus;
                cusip?: string | null;
                isin?: string | null;
                name: string;
                symbol: string;
                type: SecurityType;
                subType: SecuritySubType;
              } | null;
            }>;
            removeSecurityIds: Array<{
              __typename?: "IndexCustomizationSecurity";
              securityId: string;
              security?: {
                __typename?: "Security";
                id: string;
                asOfDate?: Date | null;
                buyStatus: SecurityTradeStatus;
                sellStatus: SecurityTradeStatus;
                cusip?: string | null;
                isin?: string | null;
                name: string;
                symbol: string;
                type: SecurityType;
                subType: SecuritySubType;
              } | null;
            }>;
          } | null;
        } | null;
      }>;
      marginCallData?: {
        __typename?: "MarginCallData";
        id: string;
        dueDate: Date;
        eventTime: Date;
        statuses: Array<{
          __typename?: "MarginCallDataStatus";
          state: MarginCallState;
          amount: Decimal;
          eventTime: Date;
        }>;
      } | null;
      actionContexts?: Array<{
        __typename?: "ActionContextStatus";
        actionContext: ActionContext;
        isAllowed: boolean;
      }> | null;
      restrictions?: Array<{
        __typename?: "ClearingAccountRestriction";
        restrictionType: ClearingAccountRestrictionType;
        active: boolean;
      }> | null;
      entity?:
        | {
            __typename?: "BusinessEntityInfo";
            businessEntityDetails: {
              __typename?: "BusinessEntityDetails";
              id: string;
              name: string;
              phoneNumber: string;
              industry: BusinessEntityIndustry;
              address?: Array<{
                __typename?: "GenericAddress";
                addressType: AddressType;
                addressLine1: string;
                addressLine2?: string | null;
                city: string;
                state: string;
                zipCode: string;
                country: string;
              }> | null;
            };
            entityAuthorizedUser: {
              __typename?: "BusinessEntityAuthorizedUser";
              userTitle: string;
              user?: {
                __typename?: "User";
                userPersonalDetails?: {
                  __typename?: "UserPersonalDetails";
                  fullName: string;
                } | null;
                userIdentityDetails?: {
                  __typename?: "UserIdentityDetails";
                  citizenshipCountry: string;
                  dateOfBirth: DateOnly;
                  ssn: string;
                } | null;
                userContactPhone?: {
                  __typename?: "UserContactPhone";
                  phoneNumber: string;
                } | null;
                userContactEmail?: {
                  __typename?: "UserContactEmail";
                  email: string;
                } | null;
              } | null;
            };
            entityIndividuals: Array<{
              __typename?: "BusinessEntityIndividual";
              familyName: string;
              givenName: string;
              dateOfBirth: DateOnly;
              individualType: BusinessEntityIndividualType;
              address?: Array<{
                __typename?: "GenericAddress";
                addressType: AddressType;
                addressLine1: string;
                addressLine2?: string | null;
                city: string;
                state: string;
                zipCode: string;
                country: string;
              }> | null;
            }>;
          }
        | {
            __typename?: "TrustEntity";
            trustEntityDetails: {
              __typename?: "TrustEntityDetails";
              id: string;
              name: string;
              taxId: string;
              taxIdType?: TaxIdType | null;
              dateOfCreation: DateOnly;
              revocability?: TrustRevocability | null;
              state: string;
              trustAgreementAllowsMarginsAndShortPositions?: boolean | null;
              address?: {
                __typename?: "GenericAddress";
                id: string;
                addressType: AddressType;
                addressLine1: string;
                addressLine2?: string | null;
                city: string;
                state: string;
                zipCode: string;
                country: string;
              } | null;
            };
            primaryTrustee?: {
              __typename?: "User";
              userContactEmail?: {
                __typename?: "UserContactEmail";
                email: string;
                emailType: EmailType;
              } | null;
              userEmploymentDetails?: Array<{
                __typename?: "UserEmploymentDetails";
                employerName?: string | null;
                occupation?: string | null;
              }> | null;
              userIdentityDetails?: {
                __typename?: "UserIdentityDetails";
                dateOfBirth: DateOnly;
                citizenshipCountry: string;
              } | null;
              userPersonalDetails?: {
                __typename?: "UserPersonalDetails";
                fullName: string;
              } | null;
              userContactPhone?: {
                __typename?: "UserContactPhone";
                phoneNumber: string;
              } | null;
              userAddresses?: Array<{
                __typename?: "GenericAddress";
                addressType: AddressType;
                addressLine1: string;
                addressLine2?: string | null;
                city: string;
                state: string;
                zipCode: string;
                country: string;
              }> | null;
            } | null;
            additionalTrustees: Array<{
              __typename?: "Trustee";
              id: string;
              name: string;
              familyName?: string | null;
              givenName?: string | null;
              dateOfBirth: DateOnly;
              idType?: TrusteeIdType | null;
              idIssuingStateOrCountry?: string | null;
              idExpirationDate?: DateOnly | null;
              governmentIdNumber?: string | null;
              disclosure?: {
                __typename?: "IndividualDisclosure";
                id: string;
                affiliatedExchangeOrFINRA: boolean;
                firmName?: string | null;
                controlPerson: boolean;
                companySymbols?: string | null;
                politicallyExposed: boolean;
                politicalOrganization?: string | null;
                politicalTitle?: string | null;
                immediateFamilyMembers?: Array<string> | null;
              } | null;
              address: {
                __typename?: "GenericAddress";
                id: string;
                addressType: AddressType;
                addressLine1: string;
                addressLine2?: string | null;
                city: string;
                state: string;
                zipCode: string;
                country: string;
              };
            }>;
          }
        | null;
      treasury?: {
        __typename?: "TreasuryConfig";
        subAccountId: string;
        eventTime: Date;
      } | null;
      dividendPreference?: Array<{
        __typename?: "DividendPreference";
        id: string;
        clearingAccountId: string;
        subAccountType: SubAccountType;
        preferenceType: DividendPreferenceType;
      }> | null;
    }> | null;
    userContactEmail?: {
      __typename?: "UserContactEmail";
      id: string;
      email: string;
      emailType: EmailType;
      emailHash?: string | null;
      verified: boolean;
    } | null;
    userContactPhone?: {
      __typename?: "UserContactPhone";
      id: string;
      phoneNumber: string;
      phoneNumberVerified: boolean;
      phoneNumberType: PhoneNumberType;
    } | null;
    userPersonalDetails?: {
      __typename?: "UserPersonalDetails";
      id: string;
      firstName: string;
      lastName: string;
      middleName?: string | null;
      fullName: string;
    } | null;
    userIdentityDetails?: {
      __typename?: "UserIdentityDetails";
      id: string;
      ssn: string;
      dateOfBirth: DateOnly;
      citizenshipCountry: string;
      permanentResident?: boolean | null;
      visaStatus?: VisaType | null;
      visaExpiration?: DateOnly | null;
      maritalStatus?: MaritalStatus | null;
      numberOfDependents?: number | null;
      idType?: TrusteeIdType | null;
      idIssuingStateOrCountry?: string | null;
      idExpirationDate?: DateOnly | null;
      governmentIdNumber?: string | null;
    } | null;
    userAddresses?: Array<{
      __typename?: "GenericAddress";
      id: string;
      addressType: AddressType;
      addressLine1: string;
      addressLine2?: string | null;
      city: string;
      state: string;
      zipCode: string;
      country: string;
    }> | null;
    userInvestmentProfile?: {
      __typename?: "UserInvestmentProfile";
      id: string;
      netWorthMin: number;
      netWorthMax: number;
      annualIncomeMin: number;
      annualIncomeMax: number;
      investmentExperience: InvestmentExperience;
      riskTolerance: RiskTolerance;
      liquidityTimeHorizon: TimeHorizon;
      liquidityNeeds: LiquidityNeeds;
    } | null;
    userServiceProfile?: {
      __typename?: "UserServiceProfile";
      id: string;
      sweepInstruction: SweepInstruction;
      dividendReinvestment: DividendReinvestment;
    } | null;
    userTaxRates?: {
      __typename?: "UserTaxRates";
      id: string;
      taxFilingStatus?: TaxFilingStatus | null;
      federalIncomeTaxRate: Decimal;
      stateIncomeTaxRate?: Decimal | null;
      federalCapitalGainsTaxRate: Decimal;
      stateCapitalGainsTaxRate: Decimal;
    } | null;
    userEmploymentDetails?: Array<{
      __typename?: "UserEmploymentDetails";
      id: string;
      employerName?: string | null;
      employmentStatus: EmploymentStatus;
      yearsEmployed?: number | null;
      occupation?: string | null;
      addressLine1?: string | null;
      addressLine2?: string | null;
      city?: string | null;
      state?: string | null;
      zipCode?: string | null;
      country?: string | null;
    }> | null;
    userTrustedContact?: {
      __typename?: "UserTrustedContact";
      id: string;
      relationship: string;
      firstName: string;
      lastName: string;
      middleName?: string | null;
      email: string;
      phoneNumber: string;
      phoneNumberType: PhoneNumberType;
      addressLine1?: string | null;
      addressLine2?: string | null;
      city?: string | null;
      state?: string | null;
      zipCode?: string | null;
      country?: string | null;
    } | null;
    userDisclosures?: {
      __typename?: "UserDisclosures";
      id: string;
      affiliatedExchangeOrFINRA: boolean;
      affiliatedExchangeOrFINRAEntityName?: string | null;
      firmName?: string | null;
      controlPerson: boolean;
      companySymbols?: string | null;
      politicallyExposed: boolean;
      politicalOrganization?: string | null;
      immediateFamily?: string | null;
    } | null;
    userProgressFlags?: Array<{
      __typename?: "UserProgressFlag";
      status?: ProgressFlagStatus | null;
      category?: string | null;
    } | null> | null;
    entityAccountRequestStatus?: {
      __typename?: "EntityAccountRequestStatus";
      state: EntityAccountRequestStatusState;
      asOfDate: Date;
    } | null;
    uploadedDocuments?: Array<{
      __typename?: "UploadedDocument";
      id: string;
      name: string;
      originalName: string;
      description?: string | null;
      type: UploadDocumentType;
      location: string;
    }> | null;
    referralPromo?: {
      __typename?: "UserReferralPromotion";
      code: string;
      dealType: UserReferralDeal;
    } | null;
  };
};

export type UpdatePersonalDetailsAndAddressMutationVariables = Exact<{
  personalDetailsId: Scalars["ID"]["input"];
  addressId: Scalars["ID"]["input"];
  userDetails: UserPersonalDetailsInput;
  userAddress: GenericAddressInput;
}>;

export type UpdatePersonalDetailsAndAddressMutation = {
  __typename?: "Mutation";
  updateUserPersonalDetails?: {
    __typename?: "UserPersonalDetails";
    id: string;
    firstName: string;
    lastName: string;
    middleName?: string | null;
  } | null;
  updateUserAddress?: {
    __typename?: "GenericAddress";
    id: string;
    addressType: AddressType;
    addressLine1: string;
    addressLine2?: string | null;
    city: string;
    state: string;
    zipCode: string;
    country: string;
  } | null;
};

export type UpdateScheduledDepositConfigMutationVariables = Exact<{
  id: Scalars["ID"]["input"];
  input: ScheduledDepositConfigInput;
}>;

export type UpdateScheduledDepositConfigMutation = {
  __typename?: "Mutation";
  updateScheduledDepositConfig: {
    __typename?: "ScheduledDepositConfig";
    id: string;
    type: ScheduledDepositType;
    sourceType: MoneyMovementSourceType;
    amount: Decimal;
    dayOfPeriod?: number | null;
    secondaryDayOfPeriod?: number | null;
    periodType: ScheduledDepositPeriodType;
    clearingAccountId: string;
    subAccountId: string;
    primaryAccountId?: string | null;
    sourceSubAccountId?: string | null;
    startAt: Date;
  };
};

export type UpdateUserEmploymentDetailsMutationVariables = Exact<{
  employmentId: Scalars["ID"]["input"];
  data: UserEmploymentDetailsInput;
}>;

export type UpdateUserEmploymentDetailsMutation = {
  __typename?: "Mutation";
  updateUserEmploymentDetails?: {
    __typename?: "UserEmploymentDetails";
    id: string;
    employerName?: string | null;
    employmentStatus: EmploymentStatus;
    yearsEmployed?: number | null;
    occupation?: string | null;
    addressLine1?: string | null;
    addressLine2?: string | null;
    city?: string | null;
    state?: string | null;
    zipCode?: string | null;
    country?: string | null;
  } | null;
};

export type UpdateUserProfileMutationVariables = Exact<{
  clearingAccountId: Scalars["ID"]["input"];
  input: UpdateUserProfileInput;
}>;

export type UpdateUserProfileMutation = {
  __typename?: "Mutation";
  updateUserProfile?: {
    __typename?: "UpdateUserProfileResponse";
    requestQueued: boolean;
    user?: {
      __typename?: "User";
      id: string;
      isEmployee: boolean;
      inviteCodes?: Array<string> | null;
      clearingAccounts?: Array<{
        __typename?: "ClearingAccount";
        id: string;
        accountType?: AccountType | null;
        categorizationType?: CategorizationType | null;
        externalClearingAccountId: string;
        eventTime?: Date | null;
        isTestAccount?: boolean | null;
        isPatternDayTrader?: boolean | null;
        isClientAccount?: boolean | null;
        primarySubAccountId: string;
        subAccounts: Array<{
          __typename?: "SubAccount";
          id: string;
          type: SubAccountType;
          eventTime: Date;
          pricing?: Array<{
            __typename?: "SubAccountPricing";
            id: string;
            amount: Decimal;
            unit: SubAccountPricingUnit;
            validFrom?: Date | null;
            validTo?: Date | null;
          }> | null;
          directIndexAccountMetadata?: {
            __typename?: "DirectIndexAccountMetadata";
            id: string;
            type: DirectIndexType;
            etfSymbol?: string | null;
            signedAt?: Date | null;
            lhfOverride?: Decimal | null;
            subAccountId: string;
            status?: DirectIndexStatus | null;
            customization?: {
              __typename?: "DirectIndexCustomization";
              id: string;
              removeGICSSectorIds: Array<GicsCode>;
              addSecurityIds: Array<{
                __typename?: "IndexCustomizationSecurity";
                securityId: string;
                security?: {
                  __typename?: "Security";
                  id: string;
                  asOfDate?: Date | null;
                  buyStatus: SecurityTradeStatus;
                  sellStatus: SecurityTradeStatus;
                  cusip?: string | null;
                  isin?: string | null;
                  name: string;
                  symbol: string;
                  type: SecurityType;
                  subType: SecuritySubType;
                } | null;
              }>;
              removeSecurityIds: Array<{
                __typename?: "IndexCustomizationSecurity";
                securityId: string;
                security?: {
                  __typename?: "Security";
                  id: string;
                  asOfDate?: Date | null;
                  buyStatus: SecurityTradeStatus;
                  sellStatus: SecurityTradeStatus;
                  cusip?: string | null;
                  isin?: string | null;
                  name: string;
                  symbol: string;
                  type: SecurityType;
                  subType: SecuritySubType;
                } | null;
              }>;
            } | null;
          } | null;
        }>;
        marginCallData?: {
          __typename?: "MarginCallData";
          id: string;
          dueDate: Date;
          eventTime: Date;
          statuses: Array<{
            __typename?: "MarginCallDataStatus";
            state: MarginCallState;
            amount: Decimal;
            eventTime: Date;
          }>;
        } | null;
        actionContexts?: Array<{
          __typename?: "ActionContextStatus";
          actionContext: ActionContext;
          isAllowed: boolean;
        }> | null;
        restrictions?: Array<{
          __typename?: "ClearingAccountRestriction";
          restrictionType: ClearingAccountRestrictionType;
          active: boolean;
        }> | null;
        entity?:
          | {
              __typename?: "BusinessEntityInfo";
              businessEntityDetails: {
                __typename?: "BusinessEntityDetails";
                id: string;
                name: string;
                phoneNumber: string;
                industry: BusinessEntityIndustry;
                address?: Array<{
                  __typename?: "GenericAddress";
                  addressType: AddressType;
                  addressLine1: string;
                  addressLine2?: string | null;
                  city: string;
                  state: string;
                  zipCode: string;
                  country: string;
                }> | null;
              };
              entityAuthorizedUser: {
                __typename?: "BusinessEntityAuthorizedUser";
                userTitle: string;
                user?: {
                  __typename?: "User";
                  userPersonalDetails?: {
                    __typename?: "UserPersonalDetails";
                    fullName: string;
                  } | null;
                  userIdentityDetails?: {
                    __typename?: "UserIdentityDetails";
                    citizenshipCountry: string;
                    dateOfBirth: DateOnly;
                    ssn: string;
                  } | null;
                  userContactPhone?: {
                    __typename?: "UserContactPhone";
                    phoneNumber: string;
                  } | null;
                  userContactEmail?: {
                    __typename?: "UserContactEmail";
                    email: string;
                  } | null;
                } | null;
              };
              entityIndividuals: Array<{
                __typename?: "BusinessEntityIndividual";
                familyName: string;
                givenName: string;
                dateOfBirth: DateOnly;
                individualType: BusinessEntityIndividualType;
                address?: Array<{
                  __typename?: "GenericAddress";
                  addressType: AddressType;
                  addressLine1: string;
                  addressLine2?: string | null;
                  city: string;
                  state: string;
                  zipCode: string;
                  country: string;
                }> | null;
              }>;
            }
          | {
              __typename?: "TrustEntity";
              trustEntityDetails: {
                __typename?: "TrustEntityDetails";
                id: string;
                name: string;
                taxId: string;
                taxIdType?: TaxIdType | null;
                dateOfCreation: DateOnly;
                revocability?: TrustRevocability | null;
                state: string;
                trustAgreementAllowsMarginsAndShortPositions?: boolean | null;
                address?: {
                  __typename?: "GenericAddress";
                  id: string;
                  addressType: AddressType;
                  addressLine1: string;
                  addressLine2?: string | null;
                  city: string;
                  state: string;
                  zipCode: string;
                  country: string;
                } | null;
              };
              primaryTrustee?: {
                __typename?: "User";
                userContactEmail?: {
                  __typename?: "UserContactEmail";
                  email: string;
                  emailType: EmailType;
                } | null;
                userEmploymentDetails?: Array<{
                  __typename?: "UserEmploymentDetails";
                  employerName?: string | null;
                  occupation?: string | null;
                }> | null;
                userIdentityDetails?: {
                  __typename?: "UserIdentityDetails";
                  dateOfBirth: DateOnly;
                  citizenshipCountry: string;
                } | null;
                userPersonalDetails?: {
                  __typename?: "UserPersonalDetails";
                  fullName: string;
                } | null;
                userContactPhone?: {
                  __typename?: "UserContactPhone";
                  phoneNumber: string;
                } | null;
                userAddresses?: Array<{
                  __typename?: "GenericAddress";
                  addressType: AddressType;
                  addressLine1: string;
                  addressLine2?: string | null;
                  city: string;
                  state: string;
                  zipCode: string;
                  country: string;
                }> | null;
              } | null;
              additionalTrustees: Array<{
                __typename?: "Trustee";
                id: string;
                name: string;
                familyName?: string | null;
                givenName?: string | null;
                dateOfBirth: DateOnly;
                idType?: TrusteeIdType | null;
                idIssuingStateOrCountry?: string | null;
                idExpirationDate?: DateOnly | null;
                governmentIdNumber?: string | null;
                disclosure?: {
                  __typename?: "IndividualDisclosure";
                  id: string;
                  affiliatedExchangeOrFINRA: boolean;
                  firmName?: string | null;
                  controlPerson: boolean;
                  companySymbols?: string | null;
                  politicallyExposed: boolean;
                  politicalOrganization?: string | null;
                  politicalTitle?: string | null;
                  immediateFamilyMembers?: Array<string> | null;
                } | null;
                address: {
                  __typename?: "GenericAddress";
                  id: string;
                  addressType: AddressType;
                  addressLine1: string;
                  addressLine2?: string | null;
                  city: string;
                  state: string;
                  zipCode: string;
                  country: string;
                };
              }>;
            }
          | null;
        treasury?: {
          __typename?: "TreasuryConfig";
          subAccountId: string;
          eventTime: Date;
        } | null;
        dividendPreference?: Array<{
          __typename?: "DividendPreference";
          id: string;
          clearingAccountId: string;
          subAccountType: SubAccountType;
          preferenceType: DividendPreferenceType;
        }> | null;
      }> | null;
      userContactEmail?: {
        __typename?: "UserContactEmail";
        id: string;
        email: string;
        emailType: EmailType;
        emailHash?: string | null;
        verified: boolean;
      } | null;
      userContactPhone?: {
        __typename?: "UserContactPhone";
        id: string;
        phoneNumber: string;
        phoneNumberVerified: boolean;
        phoneNumberType: PhoneNumberType;
      } | null;
      userPersonalDetails?: {
        __typename?: "UserPersonalDetails";
        id: string;
        firstName: string;
        lastName: string;
        middleName?: string | null;
        fullName: string;
      } | null;
      userIdentityDetails?: {
        __typename?: "UserIdentityDetails";
        id: string;
        ssn: string;
        dateOfBirth: DateOnly;
        citizenshipCountry: string;
        permanentResident?: boolean | null;
        visaStatus?: VisaType | null;
        visaExpiration?: DateOnly | null;
        maritalStatus?: MaritalStatus | null;
        numberOfDependents?: number | null;
        idType?: TrusteeIdType | null;
        idIssuingStateOrCountry?: string | null;
        idExpirationDate?: DateOnly | null;
        governmentIdNumber?: string | null;
      } | null;
      userAddresses?: Array<{
        __typename?: "GenericAddress";
        id: string;
        addressType: AddressType;
        addressLine1: string;
        addressLine2?: string | null;
        city: string;
        state: string;
        zipCode: string;
        country: string;
      }> | null;
      userInvestmentProfile?: {
        __typename?: "UserInvestmentProfile";
        id: string;
        netWorthMin: number;
        netWorthMax: number;
        annualIncomeMin: number;
        annualIncomeMax: number;
        investmentExperience: InvestmentExperience;
        riskTolerance: RiskTolerance;
        liquidityTimeHorizon: TimeHorizon;
        liquidityNeeds: LiquidityNeeds;
      } | null;
      userServiceProfile?: {
        __typename?: "UserServiceProfile";
        id: string;
        sweepInstruction: SweepInstruction;
        dividendReinvestment: DividendReinvestment;
      } | null;
      userTaxRates?: {
        __typename?: "UserTaxRates";
        id: string;
        taxFilingStatus?: TaxFilingStatus | null;
        federalIncomeTaxRate: Decimal;
        stateIncomeTaxRate?: Decimal | null;
        federalCapitalGainsTaxRate: Decimal;
        stateCapitalGainsTaxRate: Decimal;
      } | null;
      userEmploymentDetails?: Array<{
        __typename?: "UserEmploymentDetails";
        id: string;
        employerName?: string | null;
        employmentStatus: EmploymentStatus;
        yearsEmployed?: number | null;
        occupation?: string | null;
        addressLine1?: string | null;
        addressLine2?: string | null;
        city?: string | null;
        state?: string | null;
        zipCode?: string | null;
        country?: string | null;
      }> | null;
      userTrustedContact?: {
        __typename?: "UserTrustedContact";
        id: string;
        relationship: string;
        firstName: string;
        lastName: string;
        middleName?: string | null;
        email: string;
        phoneNumber: string;
        phoneNumberType: PhoneNumberType;
        addressLine1?: string | null;
        addressLine2?: string | null;
        city?: string | null;
        state?: string | null;
        zipCode?: string | null;
        country?: string | null;
      } | null;
      userDisclosures?: {
        __typename?: "UserDisclosures";
        id: string;
        affiliatedExchangeOrFINRA: boolean;
        affiliatedExchangeOrFINRAEntityName?: string | null;
        firmName?: string | null;
        controlPerson: boolean;
        companySymbols?: string | null;
        politicallyExposed: boolean;
        politicalOrganization?: string | null;
        immediateFamily?: string | null;
      } | null;
      userProgressFlags?: Array<{
        __typename?: "UserProgressFlag";
        status?: ProgressFlagStatus | null;
        category?: string | null;
      } | null> | null;
      entityAccountRequestStatus?: {
        __typename?: "EntityAccountRequestStatus";
        state: EntityAccountRequestStatusState;
        asOfDate: Date;
      } | null;
      uploadedDocuments?: Array<{
        __typename?: "UploadedDocument";
        id: string;
        name: string;
        originalName: string;
        description?: string | null;
        type: UploadDocumentType;
        location: string;
      }> | null;
      referralPromo?: {
        __typename?: "UserReferralPromotion";
        code: string;
        dealType: UserReferralDeal;
      } | null;
    } | null;
  } | null;
};

export type UpdateUserTrustedContactMutationVariables = Exact<{
  trustedContactId: Scalars["ID"]["input"];
  data: UserTrustedContactInput;
}>;

export type UpdateUserTrustedContactMutation = {
  __typename?: "Mutation";
  updateUserTrustedContact?: {
    __typename?: "UserTrustedContact";
    id: string;
    relationship: string;
    firstName: string;
    lastName: string;
    middleName?: string | null;
    email: string;
    phoneNumber: string;
    phoneNumberType: PhoneNumberType;
    addressLine1?: string | null;
    addressLine2?: string | null;
    city?: string | null;
    state?: string | null;
    zipCode?: string | null;
    country?: string | null;
  } | null;
};

export type UploadDocumentMutationVariables = Exact<{
  input: UploadDocumentInput;
}>;

export type UploadDocumentMutation = {
  __typename?: "Mutation";
  uploadDocument: {
    __typename?: "UploadedDocument";
    id: string;
    userId: string;
    clearingAccountId?: string | null;
    name: string;
    originalName: string;
    description?: string | null;
    type: UploadDocumentType;
    location: string;
  };
};

export type UpsertBusinessEntityMutationVariables = Exact<{
  input: UpsertBusinessEntityInput;
}>;

export type UpsertBusinessEntityMutation = {
  __typename?: "Mutation";
  upsertBusinessEntity: {
    __typename?: "BusinessEntity";
    id: string;
    name: string;
    taxId: string;
    userTitle: string;
    phoneNumber: string;
    faxNumber?: string | null;
    industry: BusinessEntityIndustry;
    entityType: BusinessEntityType;
    corporationSecretaryName?: string | null;
    multiplePartnersOrManagers?: boolean | null;
    foreignBankUSAgent?: string | null;
    largeTraderIds: Array<string>;
    businessScope: BusinessEntityScope;
    primaryFundingSource: BusinessEntityFundingSource;
    bankingInstitutionNames?: Array<string> | null;
    stateOfIncorporation: string;
    address: {
      __typename?: "GenericAddress";
      id: string;
      addressType: AddressType;
      addressLine1: string;
      addressLine2?: string | null;
      city: string;
      state: string;
      zipCode: string;
      country: string;
    };
    mailingAddress: {
      __typename?: "GenericAddress";
      id: string;
      addressType: AddressType;
      addressLine1: string;
      addressLine2?: string | null;
      city: string;
      state: string;
      zipCode: string;
      country: string;
    };
  };
};

export type UpsertBusinessEntityIndividualMutationVariables = Exact<{
  input:
    | Array<UpsertBusinessEntityIndividualInput>
    | UpsertBusinessEntityIndividualInput;
}>;

export type UpsertBusinessEntityIndividualMutation = {
  __typename?: "Mutation";
  upsertBusinessEntityIndividual: Array<{
    __typename?: "BusinessEntityIndividual";
    id: string;
    givenName: string;
    familyName: string;
    individualType: BusinessEntityIndividualType;
    address?: Array<{
      __typename?: "GenericAddress";
      id: string;
      addressType: AddressType;
      addressLine1: string;
      addressLine2?: string | null;
      city: string;
      state: string;
      zipCode: string;
      country: string;
    }> | null;
  }>;
};

export type UpsertBusinessEntityInvestmentProfileMutationVariables = Exact<{
  input: UpsertBusinessEntityInvestmentProfileInput;
}>;

export type UpsertBusinessEntityInvestmentProfileMutation = {
  __typename?: "Mutation";
  upsertBusinessEntityInvestmentProfile: {
    __typename?: "BusinessEntityInvestmentProfile";
    id: string;
    netWorthMin: number;
    netWorthMax: number;
    annualIncomeMin: number;
    annualIncomeMax: number;
    investmentExperience: InvestmentExperience;
    investmentObjective: BusinessEntityInvestmentObjective;
    investmentPlan: BusinessEntityInvestmentPlan;
    riskTolerance: RiskTolerance;
    liquidityTimeHorizon: TimeHorizon;
    liquidityNeeds: LiquidityNeeds;
    withdrawFrequency: BusinessEntityWithdrawFrequency;
    depositSource: string;
    initialDepositAmount: number;
  };
};

export type UpsertDirectIndexCustomizationMutationVariables = Exact<{
  input: DirectIndexCustomizationInput;
}>;

export type UpsertDirectIndexCustomizationMutation = {
  __typename?: "Mutation";
  upsertDirectIndexCustomization: {
    __typename?: "DirectIndexCustomization";
    id: string;
    removeGICSSectorIds: Array<GicsCode>;
    addSecurityIds: Array<{
      __typename?: "IndexCustomizationSecurity";
      securityId: string;
      security?: {
        __typename?: "Security";
        id: string;
        asOfDate?: Date | null;
        buyStatus: SecurityTradeStatus;
        sellStatus: SecurityTradeStatus;
        cusip?: string | null;
        isin?: string | null;
        name: string;
        symbol: string;
        type: SecurityType;
        subType: SecuritySubType;
      } | null;
    }>;
    removeSecurityIds: Array<{
      __typename?: "IndexCustomizationSecurity";
      securityId: string;
      security?: {
        __typename?: "Security";
        id: string;
        asOfDate?: Date | null;
        buyStatus: SecurityTradeStatus;
        sellStatus: SecurityTradeStatus;
        cusip?: string | null;
        isin?: string | null;
        name: string;
        symbol: string;
        type: SecurityType;
        subType: SecuritySubType;
      } | null;
    }>;
  };
};

export type UpsertTrustEntityMutationVariables = Exact<{
  input: UpsertTrustEntityInput;
}>;

export type UpsertTrustEntityMutation = {
  __typename?: "Mutation";
  upsertTrustEntity: {
    __typename?: "TrustEntity";
    id: string;
    primaryTrusteeId: string;
    entityDetails: {
      __typename?: "TrustEntityDetails";
      id: string;
      name: string;
      taxId: string;
      taxIdType?: TaxIdType | null;
      dateOfCreation: DateOnly;
      revocability?: TrustRevocability | null;
      state: string;
      trustAgreementAllowsMarginsAndShortPositions?: boolean | null;
      address?: {
        __typename?: "GenericAddress";
        id: string;
        addressType: AddressType;
        addressLine1: string;
        addressLine2?: string | null;
        city: string;
        state: string;
        zipCode: string;
        country: string;
      } | null;
    };
    additionalTrustees: Array<{
      __typename?: "Trustee";
      id: string;
      name: string;
      familyName?: string | null;
      givenName?: string | null;
      dateOfBirth: DateOnly;
      idType?: TrusteeIdType | null;
      idIssuingStateOrCountry?: string | null;
      idExpirationDate?: DateOnly | null;
      governmentIdNumber?: string | null;
      disclosure?: {
        __typename?: "IndividualDisclosure";
        id: string;
        affiliatedExchangeOrFINRA: boolean;
        firmName?: string | null;
        controlPerson: boolean;
        companySymbols?: string | null;
        politicallyExposed: boolean;
        politicalOrganization?: string | null;
        politicalTitle?: string | null;
        immediateFamilyMembers?: Array<string> | null;
      } | null;
      address: {
        __typename?: "GenericAddress";
        id: string;
        addressType: AddressType;
        addressLine1: string;
        addressLine2?: string | null;
        city: string;
        state: string;
        zipCode: string;
        country: string;
      };
    }>;
    address?: {
      __typename?: "GenericAddress";
      id: string;
      addressType: AddressType;
      addressLine1: string;
      addressLine2?: string | null;
      city: string;
      state: string;
      zipCode: string;
      country: string;
    } | null;
  };
};

export type UpsertTrustTrusteeMutationVariables = Exact<{
  input: UpsertTrustEntityIndividualInput;
}>;

export type UpsertTrustTrusteeMutation = {
  __typename?: "Mutation";
  upsertTrustEntityIndividual: Array<{
    __typename?: "Trustee";
    id: string;
    name: string;
    familyName?: string | null;
    givenName?: string | null;
    dateOfBirth: DateOnly;
    idType?: TrusteeIdType | null;
    idIssuingStateOrCountry?: string | null;
    idExpirationDate?: DateOnly | null;
    governmentIdNumber?: string | null;
    disclosure?: {
      __typename?: "IndividualDisclosure";
      id: string;
      affiliatedExchangeOrFINRA: boolean;
      firmName?: string | null;
      controlPerson: boolean;
      companySymbols?: string | null;
      politicallyExposed: boolean;
      politicalOrganization?: string | null;
      politicalTitle?: string | null;
      immediateFamilyMembers?: Array<string> | null;
    } | null;
    address: {
      __typename?: "GenericAddress";
      id: string;
      addressType: AddressType;
      addressLine1: string;
      addressLine2?: string | null;
      city: string;
      state: string;
      zipCode: string;
      country: string;
    };
  }>;
};

export type UpsertUserAddressMutationVariables = Exact<{
  input: GenericAddressInput;
}>;

export type UpsertUserAddressMutation = {
  __typename?: "Mutation";
  upsertUserAddress?: {
    __typename?: "GenericAddress";
    id: string;
    addressType: AddressType;
    addressLine1: string;
    addressLine2?: string | null;
    city: string;
    state: string;
    zipCode: string;
    country: string;
  } | null;
};

export type UpsertUserDisclosuresMutationVariables = Exact<{
  data: UserDisclosuresInput;
}>;

export type UpsertUserDisclosuresMutation = {
  __typename?: "Mutation";
  upsertUserDisclosures?: {
    __typename?: "UserDisclosures";
    affiliatedExchangeOrFINRA: boolean;
    affiliatedExchangeOrFINRAEntityName?: string | null;
    firmName?: string | null;
    controlPerson: boolean;
    companySymbols?: string | null;
    politicallyExposed: boolean;
    politicalOrganization?: string | null;
    immediateFamily?: string | null;
  } | null;
};

export type UpsertUserIdentityDetailsMutationVariables = Exact<{
  data: UserIdentityDetailsInput;
}>;

export type UpsertUserIdentityDetailsMutation = {
  __typename?: "Mutation";
  upsertUserIdentityDetails?: {
    __typename?: "UserIdentityDetails";
    id: string;
    ssn: string;
    dateOfBirth: DateOnly;
    citizenshipCountry: string;
    permanentResident?: boolean | null;
    visaStatus?: VisaType | null;
    visaExpiration?: DateOnly | null;
    maritalStatus?: MaritalStatus | null;
    numberOfDependents?: number | null;
    governmentIdNumber?: string | null;
    idExpirationDate?: DateOnly | null;
    idIssuingStateOrCountry?: string | null;
    idType?: TrusteeIdType | null;
  } | null;
};

export type UpsertUserInvestmentProfileMutationVariables = Exact<{
  data: UserInvestmentProfileInput;
}>;

export type UpsertUserInvestmentProfileMutation = {
  __typename?: "Mutation";
  upsertUserInvestmentProfile?: {
    __typename?: "UserInvestmentProfile";
    id: string;
    netWorthMin: number;
    netWorthMax: number;
    annualIncomeMin: number;
    annualIncomeMax: number;
    investmentExperience: InvestmentExperience;
    investmentObjective: InvestmentObjective;
    riskTolerance: RiskTolerance;
    liquidityTimeHorizon: TimeHorizon;
    liquidityNeeds: LiquidityNeeds;
  } | null;
};

export type UpsertUserPersonalDetailsMutationVariables = Exact<{
  input: UserPersonalDetailsInput;
}>;

export type UpsertUserPersonalDetailsMutation = {
  __typename?: "Mutation";
  upsertUserPersonalDetails?: {
    __typename?: "UserPersonalDetails";
    id: string;
    firstName: string;
    lastName: string;
    middleName?: string | null;
  } | null;
};

export type UpsertUserServiceProfileMutationVariables = Exact<{
  data: UserServiceProfileInput;
}>;

export type UpsertUserServiceProfileMutation = {
  __typename?: "Mutation";
  upsertUserServiceProfile?: {
    __typename?: "UserServiceProfile";
    id: string;
    sweepInstruction: SweepInstruction;
    dividendReinvestment: DividendReinvestment;
  } | null;
};

export type UpsertUserTaxRatesMutationVariables = Exact<{
  data: UserTaxRatesInput;
}>;

export type UpsertUserTaxRatesMutation = {
  __typename?: "Mutation";
  upsertUserTaxRates?: {
    __typename?: "UserTaxRates";
    id: string;
    taxFilingStatus?: TaxFilingStatus | null;
    federalIncomeTaxRate: Decimal;
    stateIncomeTaxRate?: Decimal | null;
    federalCapitalGainsTaxRate: Decimal;
    stateCapitalGainsTaxRate: Decimal;
  } | null;
};

export type ValidateMfaChallengeMutationVariables = Exact<{
  input: ValidateMfaPhoneChallengeInput;
}>;

export type ValidateMfaChallengeMutation = {
  __typename?: "Mutation";
  validateMfaChallenge: {
    __typename?: "ValidateMfaPhoneChallengeResponse";
    status: boolean;
  };
};

export type DirectIndexPerformanceDataQueryVariables = Exact<{
  subAccountId: Scalars["ID"]["input"];
}>;

export type DirectIndexPerformanceDataQuery = {
  __typename?: "Query";
  getDirectIndexPerformanceData: {
    __typename?: "DirectIndexPerformanceData";
    directIndexHistory: Array<{
      __typename?: "PortfolioSnapshot";
      date: Date;
      cashBalance: Decimal;
      loan: Decimal;
      fees: Decimal;
      portfolioSnapshotHoldingClosingValue: Decimal;
    }>;
    comparisonPortfolioHistory?: Array<{
      __typename?: "ComparisonPortfolioSnapshot";
      date: Date;
      snapshotHoldingClosingValue: Decimal;
    }> | null;
    periods: Array<{
      __typename?: "DirectIndexPerformanceDataPeriod";
      period: string;
      taxLossesHarvested?: Decimal | null;
      netDeposits?: Decimal | null;
      moneyWeightedReturnPercent?: Decimal | null;
      timeWeightedReturnPercent?: Decimal | null;
      simpleReturnPercent?: Decimal | null;
      shortTermLosses?: Decimal | null;
      shortTermGains?: Decimal | null;
      longTermLosses?: Decimal | null;
      longTermGains?: Decimal | null;
      shortWashSalesDisallowed?: Decimal | null;
      longWashSalesDisallowed?: Decimal | null;
      netShortTermLosses?: Decimal | null;
      netLongTermLosses?: Decimal | null;
      estimatedTaxSavings?: Decimal | null;
      cumulativeNetDeposits: Array<{
        __typename?: "CumulativeNetDeposit";
        date: DateOnly;
        value: Decimal;
      }>;
    }>;
  };
};

export type DemoDirectIndexPerformanceDataQueryVariables = Exact<{
  subAccountId: Scalars["ID"]["input"];
}>;

export type DemoDirectIndexPerformanceDataQuery = {
  __typename?: "Query";
  getDirectIndexPerformanceData: {
    __typename?: "DirectIndexPerformanceData";
    directIndexHistory: Array<{
      __typename?: "PortfolioSnapshot";
      date: Date;
      cashBalance: Decimal;
      loan: Decimal;
      fees: Decimal;
      portfolioSnapshotHoldingClosingValue: Decimal;
    }>;
    comparisonPortfolioHistory?: Array<{
      __typename?: "ComparisonPortfolioSnapshot";
      date: Date;
      snapshotHoldingClosingValue: Decimal;
    }> | null;
    periods: Array<{
      __typename?: "DirectIndexPerformanceDataPeriod";
      period: string;
      taxLossesHarvested?: Decimal | null;
      netDeposits?: Decimal | null;
      moneyWeightedReturnPercent?: Decimal | null;
      timeWeightedReturnPercent?: Decimal | null;
      simpleReturnPercent?: Decimal | null;
      shortTermLosses?: Decimal | null;
      shortTermGains?: Decimal | null;
      longTermLosses?: Decimal | null;
      longTermGains?: Decimal | null;
      shortWashSalesDisallowed?: Decimal | null;
      longWashSalesDisallowed?: Decimal | null;
      netShortTermLosses?: Decimal | null;
      netLongTermLosses?: Decimal | null;
      estimatedTaxSavings?: Decimal | null;
      cumulativeNetDeposits: Array<{
        __typename?: "CumulativeNetDeposit";
        date: DateOnly;
        value: Decimal;
      }>;
    }>;
  };
};

export type DirectIndexPerformanceDataV2QueryVariables = Exact<{
  subAccountId: Scalars["ID"]["input"];
  performanceDataPeriod?: InputMaybe<PerformanceDataPeriod>;
}>;

export type DirectIndexPerformanceDataV2Query = {
  __typename?: "Query";
  getDirectIndexPerformanceDataV2: {
    __typename?: "DirectIndexPerformanceData";
    directIndexHistory: Array<{
      __typename?: "PortfolioSnapshot";
      date: Date;
      cashBalance: Decimal;
      loan: Decimal;
      fees: Decimal;
      portfolioSnapshotHoldingClosingValue: Decimal;
    }>;
    comparisonPortfolioHistory?: Array<{
      __typename?: "ComparisonPortfolioSnapshot";
      date: Date;
      snapshotHoldingClosingValue: Decimal;
    }> | null;
    periods: Array<{
      __typename?: "DirectIndexPerformanceDataPeriod";
      period: string;
      taxLossesHarvested?: Decimal | null;
      netDeposits?: Decimal | null;
      moneyWeightedReturnPercent?: Decimal | null;
      timeWeightedReturnPercent?: Decimal | null;
      simpleReturnPercent?: Decimal | null;
      shortTermLosses?: Decimal | null;
      shortTermGains?: Decimal | null;
      longTermLosses?: Decimal | null;
      longTermGains?: Decimal | null;
      shortWashSalesDisallowed?: Decimal | null;
      longWashSalesDisallowed?: Decimal | null;
      netShortTermLosses?: Decimal | null;
      netLongTermLosses?: Decimal | null;
      estimatedTaxSavings?: Decimal | null;
      cumulativeNetDeposits: Array<{
        __typename?: "CumulativeNetDeposit";
        date: DateOnly;
        value: Decimal;
      }>;
    }>;
  };
};

export type DemoDirectIndexPerformanceDataV2QueryVariables = Exact<{
  subAccountId: Scalars["ID"]["input"];
  performanceDataPeriod?: InputMaybe<PerformanceDataPeriod>;
}>;

export type DemoDirectIndexPerformanceDataV2Query = {
  __typename?: "Query";
  getDirectIndexPerformanceDataV2: {
    __typename?: "DirectIndexPerformanceData";
    directIndexHistory: Array<{
      __typename?: "PortfolioSnapshot";
      date: Date;
      cashBalance: Decimal;
      loan: Decimal;
      fees: Decimal;
      portfolioSnapshotHoldingClosingValue: Decimal;
    }>;
    comparisonPortfolioHistory?: Array<{
      __typename?: "ComparisonPortfolioSnapshot";
      date: Date;
      snapshotHoldingClosingValue: Decimal;
    }> | null;
    periods: Array<{
      __typename?: "DirectIndexPerformanceDataPeriod";
      period: string;
      taxLossesHarvested?: Decimal | null;
      netDeposits?: Decimal | null;
      moneyWeightedReturnPercent?: Decimal | null;
      timeWeightedReturnPercent?: Decimal | null;
      simpleReturnPercent?: Decimal | null;
      shortTermLosses?: Decimal | null;
      shortTermGains?: Decimal | null;
      longTermLosses?: Decimal | null;
      longTermGains?: Decimal | null;
      shortWashSalesDisallowed?: Decimal | null;
      longWashSalesDisallowed?: Decimal | null;
      netShortTermLosses?: Decimal | null;
      netLongTermLosses?: Decimal | null;
      estimatedTaxSavings?: Decimal | null;
      cumulativeNetDeposits: Array<{
        __typename?: "CumulativeNetDeposit";
        date: DateOnly;
        value: Decimal;
      }>;
    }>;
  };
};

export type DirectIndexPerformanceDataFragment = {
  __typename?: "DirectIndexPerformanceData";
  directIndexHistory: Array<{
    __typename?: "PortfolioSnapshot";
    date: Date;
    cashBalance: Decimal;
    loan: Decimal;
    fees: Decimal;
    portfolioSnapshotHoldingClosingValue: Decimal;
  }>;
  comparisonPortfolioHistory?: Array<{
    __typename?: "ComparisonPortfolioSnapshot";
    date: Date;
    snapshotHoldingClosingValue: Decimal;
  }> | null;
  periods: Array<{
    __typename?: "DirectIndexPerformanceDataPeriod";
    period: string;
    taxLossesHarvested?: Decimal | null;
    netDeposits?: Decimal | null;
    moneyWeightedReturnPercent?: Decimal | null;
    timeWeightedReturnPercent?: Decimal | null;
    simpleReturnPercent?: Decimal | null;
    shortTermLosses?: Decimal | null;
    shortTermGains?: Decimal | null;
    longTermLosses?: Decimal | null;
    longTermGains?: Decimal | null;
    shortWashSalesDisallowed?: Decimal | null;
    longWashSalesDisallowed?: Decimal | null;
    netShortTermLosses?: Decimal | null;
    netLongTermLosses?: Decimal | null;
    estimatedTaxSavings?: Decimal | null;
    cumulativeNetDeposits: Array<{
      __typename?: "CumulativeNetDeposit";
      date: DateOnly;
      value: Decimal;
    }>;
  }>;
};

export type DirectIndexPerformanceDataPeriodFragment = {
  __typename?: "DirectIndexPerformanceDataPeriod";
  period: string;
  taxLossesHarvested?: Decimal | null;
  netDeposits?: Decimal | null;
  moneyWeightedReturnPercent?: Decimal | null;
  timeWeightedReturnPercent?: Decimal | null;
  simpleReturnPercent?: Decimal | null;
  shortTermLosses?: Decimal | null;
  shortTermGains?: Decimal | null;
  longTermLosses?: Decimal | null;
  longTermGains?: Decimal | null;
  shortWashSalesDisallowed?: Decimal | null;
  longWashSalesDisallowed?: Decimal | null;
  netShortTermLosses?: Decimal | null;
  netLongTermLosses?: Decimal | null;
  estimatedTaxSavings?: Decimal | null;
  cumulativeNetDeposits: Array<{
    __typename?: "CumulativeNetDeposit";
    date: DateOnly;
    value: Decimal;
  }>;
};

export type InterestRateQueryVariables = Exact<{ [key: string]: never }>;

export type InterestRateQuery = {
  __typename?: "Query";
  marginInterest?: {
    __typename?: "MarginInterestResponse";
    id: string;
    value: Decimal;
    effectiveDate: Date;
    note?: string | null;
  } | null;
};

export type InterestRateProjectionQueryVariables = Exact<{
  [key: string]: never;
}>;

export type InterestRateProjectionQuery = {
  __typename?: "Query";
  rates: {
    __typename?: "MarginInterestForecastResponse";
    url?: string | null;
    forecasts: Array<{
      __typename?: "MarginInterestForecast";
      fromDate?: DateOnly | null;
      rate?: Decimal | null;
    }>;
  };
};

export type IntraDayMarginCallDataQueryVariables = Exact<{
  clearingAccountId: Scalars["ID"]["input"];
}>;

export type IntraDayMarginCallDataQuery = {
  __typename?: "Query";
  clearingAccount?: {
    __typename?: "ClearingAccount";
    id: string;
    intraDayMarginCallData?: {
      __typename?: "IntraDayMarginCallData";
      accountState: MarginAccountState;
      callAmount?: Decimal | null;
    } | null;
  } | null;
};

export type OidcClientApplicationDetailsQueryVariables = Exact<{
  input: OidcClientApplicationDetailsInput;
}>;

export type OidcClientApplicationDetailsQuery = {
  __typename?: "Query";
  oidcClientApplicationDetails?: {
    __typename?: "OidcClientApplicationDetails";
    logoUrl?: string | null;
    applicationName?: string | null;
    aggregator?: FdxConnectionAggregator | null;
  } | null;
};

export type HistoricalDeltasQueryVariables = Exact<{
  input: HistoricalDeltaInput;
}>;

export type HistoricalDeltasQuery = {
  __typename?: "Query";
  historicalDeltas: Array<{
    __typename?: "HistoricalDelta";
    symbol: string;
    prices: Array<{
      __typename?: "StockHistory";
      date: DateOnly;
      close: Decimal;
    }>;
    deltas: Array<{
      __typename?: "StockHistory";
      date: DateOnly;
      close: Decimal;
    }>;
  }>;
};

export type BetaValuesQueryVariables = Exact<{
  input: BetaValueInput;
}>;

export type BetaValuesQuery = {
  __typename?: "Query";
  betaValues: Array<{ __typename?: "StockBeta"; symbol: string; beta: number }>;
};

export type QuoteQueryVariables = Exact<{
  symbols: Array<Scalars["String"]["input"]> | Scalars["String"]["input"];
}>;

export type QuoteQuery = {
  __typename?: "Query";
  quote: Array<{
    __typename?: "StockQuote";
    symbol: string;
    price: Decimal;
    marginRequirement?: {
      __typename?: "MarginRequirement";
      maintenanceRequirement: Decimal;
    } | null;
  }>;
};

export type TreasuryRatesQueryVariables = Exact<{ [key: string]: never }>;

export type TreasuryRatesQuery = {
  __typename?: "Query";
  treasuryRates: {
    __typename?: "TreasuryYield";
    annualFeePercentage: Decimal;
    maxSevenDayYieldPercentage: Decimal;
    supportedFundSymbols: Array<string>;
  };
};

export type AcatsQueryVariables = Exact<{
  clearingAccountId: Scalars["ID"]["input"];
  size?: InputMaybe<Scalars["Int"]["input"]>;
  acatsTransfersNextPageToken?: InputMaybe<Scalars["String"]["input"]>;
}>;

export type AcatsQuery = {
  __typename?: "Query";
  clearingAccount?: {
    __typename?: "ClearingAccount";
    id: string;
    getAccountAcatsTransfers?: {
      __typename?: "PaginatedAcatsTransferResponse";
      nextPageToken?: string | null;
      data?: Array<{
        __typename?: "AcatsTransfer";
        id: string;
        userId: string;
        clearingAccountId: string;
        acatsControlNumber?: string | null;
        associatedAcatsId?: string | null;
        transferType: AcatsTransferType;
        eventTime: Date;
        currentStatus: AcatsTransferStatus;
        direction: AcatsTransferDirection;
        accountOfficialName: string;
        accountInstitutionName: string;
        accountMask?: string | null;
        assets?: Array<{
          __typename?: "AcatsTransferAsset";
          symbol?: string | null;
          quantity?: Decimal | null;
          securityId?: string | null;
          cash?: Decimal | null;
          cashType?: string | null;
        }> | null;
        fee?: Array<{
          __typename?: "AcatsTransferFee";
          id: string;
          type: AcatsTransferFeeType;
          amount: Decimal;
        }> | null;
        statusHistory?: Array<{
          __typename?: "AcatsStatusHistory";
          id: string;
          acatsTransferId: string;
          frecStatus: AcatsTransferStatus;
          eventTime: Date;
          errors?: Array<{
            __typename?: "AcatsError";
            errorCode?: string | null;
            fieldName?: string | null;
            message?: string | null;
            attemptedValue?: string | null;
          }> | null;
        }> | null;
      }> | null;
    } | null;
  } | null;
};

export type ActiveScheduledDepositConfigsQueryVariables = Exact<{
  clearingAccountId: Scalars["ID"]["input"];
  subAccountId?: InputMaybe<Scalars["ID"]["input"]>;
}>;

export type ActiveScheduledDepositConfigsQuery = {
  __typename?: "Query";
  clearingAccount?: {
    __typename?: "ClearingAccount";
    id: string;
    activeScheduledDepositConfigs?: Array<{
      __typename?: "ScheduledDepositConfig";
      id: string;
      type: ScheduledDepositType;
      sourceType: MoneyMovementSourceType;
      amount: Decimal;
      dayOfPeriod?: number | null;
      secondaryDayOfPeriod?: number | null;
      periodType: ScheduledDepositPeriodType;
      clearingAccountId: string;
      subAccountId: string;
      primaryAccountId?: string | null;
      sourceSubAccountId?: string | null;
      startAt: Date;
    }> | null;
  } | null;
};

export type DemoActiveScheduledDepositConfigsQueryVariables = Exact<{
  clearingAccountId: Scalars["ID"]["input"];
  subAccountId?: InputMaybe<Scalars["ID"]["input"]>;
}>;

export type DemoActiveScheduledDepositConfigsQuery = {
  __typename?: "Query";
  clearingAccount?: {
    __typename?: "ClearingAccount";
    id: string;
    activeScheduledDepositConfigs?: Array<{
      __typename?: "ScheduledDepositConfig";
      id: string;
      type: ScheduledDepositType;
      sourceType: MoneyMovementSourceType;
      amount: Decimal;
      dayOfPeriod?: number | null;
      secondaryDayOfPeriod?: number | null;
      periodType: ScheduledDepositPeriodType;
      clearingAccountId: string;
      subAccountId: string;
      primaryAccountId?: string | null;
      sourceSubAccountId?: string | null;
      startAt: Date;
    }> | null;
  } | null;
};

export type EmployeeReadOnlyClearingAccountAccessQueryVariables = Exact<{
  [key: string]: never;
}>;

export type EmployeeReadOnlyClearingAccountAccessQuery = {
  __typename?: "Query";
  employeeReadOnlyClearingAccountAccess: Array<{
    __typename?: "ReadOnlyClearingAccountResponse";
    clearingAccountId: string;
    businessReason: string;
    expiresAt: Date;
  }>;
};

export type SimulationGetSimulationEventTemplateQueryVariables = Exact<{
  eventType: EventType;
  eventId: Scalars["String"]["input"];
}>;

export type SimulationGetSimulationEventTemplateQuery = {
  __typename?: "Query";
  simulationGetSimulationEventTemplate?: Array<{
    __typename?: "ApexSimulationEvent";
    id: string;
    externalId: string;
    eventType: EventType;
    topic: string;
    success: boolean;
    payload: string;
    eventTime: Date;
    note?: string | null;
  }> | null;
};

export type SimulationGetTestUsersQueryVariables = Exact<{
  [key: string]: never;
}>;

export type SimulationGetTestUsersQuery = {
  __typename?: "Query";
  simulationGetTestUsers: Array<string>;
};

export type SimulationGetTestClearingAccountsQueryVariables = Exact<{
  [key: string]: never;
}>;

export type SimulationGetTestClearingAccountsQuery = {
  __typename?: "Query";
  simulationGetTestClearingAccounts: Array<string>;
};

export type SimulationGetUserTemplateQueryVariables = Exact<{
  [key: string]: never;
}>;

export type SimulationGetUserTemplateQuery = {
  __typename?: "Query";
  simulationGetUserTemplate: {
    __typename?: "UserQaData";
    id?: string | null;
    isEmployee: boolean;
    email: string;
    emailType: EmailType;
    phoneNumber: string;
    phoneNumberVerified: boolean;
    phoneNumberType: PhoneNumberType;
    firstName: string;
    middleName: string;
    lastName: string;
    citizenshipCountry: string;
    dateOfBirth: string;
    permanentResident?: boolean | null;
    ssn: string;
    visaExpiration?: string | null;
    visaStatus?: VisaType | null;
    employmentStatus: EmploymentStatus;
    controlPerson: boolean;
    affiliatedExchangeOrFINRA: boolean;
    politicallyExposed: boolean;
    addressLine1: string;
    addressType: AddressType;
    city: string;
    country: string;
    state: string;
    zipCode: string;
    annualIncomeMax: number;
    annualIncomeMin: number;
    investmentExperience: InvestmentExperience;
    investmentObjective: InvestmentObjective;
    liquidityNeeds: LiquidityNeeds;
    liquidityTimeHorizon: TimeHorizon;
    netWorthMax: number;
    netWorthMin: number;
    riskTolerance: RiskTolerance;
    sweepInstruction: SweepInstruction;
    dividendReinvestment: DividendReinvestment;
  };
};

export type DirectIndexSimulateQueryVariables = Exact<{
  input: Scalars["String"]["input"];
  submitOnly?: InputMaybe<Scalars["Boolean"]["input"]>;
}>;

export type DirectIndexSimulateQuery = {
  __typename?: "Query";
  directIndexSimulate: DirectIndexSimulationStatus;
};

export type CancelDirectIndexSimulationMutationVariables = Exact<{
  id: Scalars["ID"]["input"];
}>;

export type CancelDirectIndexSimulationMutation = {
  __typename?: "Mutation";
  cancelDirectIndexSimulation: boolean;
};

export type DeleteDirectIndexSimulationMutationVariables = Exact<{
  id: Scalars["ID"]["input"];
}>;

export type DeleteDirectIndexSimulationMutation = {
  __typename?: "Mutation";
  deleteDirectIndexSimulation: boolean;
};

export type GetSubAccountWeightsQueryVariables = Exact<{
  subAccountId: Scalars["ID"]["input"];
  fromDateTime: Scalars["Date"]["input"];
  toDateTime?: InputMaybe<Scalars["Date"]["input"]>;
}>;

export type GetSubAccountWeightsQuery = {
  __typename?: "Query";
  directIndexWeights: Array<{
    __typename?: "DirectIndexWeight";
    securityId: string;
    weight: Decimal;
    computedAt?: Date | null;
    security?: {
      __typename?: "Security";
      id: string;
      asOfDate?: Date | null;
      buyStatus: SecurityTradeStatus;
      sellStatus: SecurityTradeStatus;
      cusip?: string | null;
      isin?: string | null;
      name: string;
      symbol: string;
      type: SecurityType;
      subType: SecuritySubType;
    } | null;
  }>;
};

export type GetAllSimulationsRunInfoQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetAllSimulationsRunInfoQuery = {
  __typename?: "Query";
  getAllSimulationsRunInfo: Array<{
    __typename?: "DirectIndexSimulationRunInfo";
    id: string;
    status: DirectIndexSimulationStatus;
    date: Date;
    serializedArgs: string;
    multiIndexSimulationId?: string | null;
  }>;
};

export type GetSimulationRunInfoByIdQueryVariables = Exact<{
  id: Scalars["ID"]["input"];
}>;

export type GetSimulationRunInfoByIdQuery = {
  __typename?: "Query";
  getSimulationRunInfoById?: {
    __typename?: "DirectIndexSimulationRunInfoById";
    serializedResult?: string | null;
    info: {
      __typename?: "DirectIndexSimulationRunInfo";
      id: string;
      status: DirectIndexSimulationStatus;
      serializedArgs: string;
      multiIndexSimulationId?: string | null;
    };
  } | null;
};

export type RunDirectIndexOptimizationQueryVariables = Exact<{
  input: RunDirectIndexOptimizationInput;
}>;

export type RunDirectIndexOptimizationQuery = {
  __typename?: "Query";
  runDirectIndexOptimization?: {
    __typename?: "DirectIndexOptimizationRunData";
    valueCantBuy: Array<string>;
    lossHarvestCantBuy: Array<string>;
    missingCostCantSell: Array<string>;
    washSalesCantBuy: Array<string>;
    washSalesCantSell: Array<string>;
    dayTradeCantSell: Array<string>;
    rawResponse: {
      __typename?: "DirectIndexOptimizeResult";
      cashDelta: Decimal;
      quantityDelta: Array<{
        __typename?: "DirectIndexQuantityDelta";
        symbol: string;
        delta: Decimal;
      }>;
    };
    postProcessedResponse: {
      __typename?: "DirectIndexOptimizeResult";
      cashDelta: Decimal;
      quantityDelta: Array<{
        __typename?: "DirectIndexQuantityDelta";
        symbol: string;
        delta: Decimal;
      }>;
    };
  } | null;
};

export type DirectIndexOptimizationRunDataFragment = {
  __typename?: "DirectIndexOptimizationRunData";
  valueCantBuy: Array<string>;
  lossHarvestCantBuy: Array<string>;
  missingCostCantSell: Array<string>;
  washSalesCantBuy: Array<string>;
  washSalesCantSell: Array<string>;
  dayTradeCantSell: Array<string>;
  rawResponse: {
    __typename?: "DirectIndexOptimizeResult";
    cashDelta: Decimal;
    quantityDelta: Array<{
      __typename?: "DirectIndexQuantityDelta";
      symbol: string;
      delta: Decimal;
    }>;
  };
  postProcessedResponse: {
    __typename?: "DirectIndexOptimizeResult";
    cashDelta: Decimal;
    quantityDelta: Array<{
      __typename?: "DirectIndexQuantityDelta";
      symbol: string;
      delta: Decimal;
    }>;
  };
};

export type FetchDirectIndexStockInfoForSubAccountQueryVariables = Exact<{
  subAccountId: Scalars["ID"]["input"];
  asOfDate?: InputMaybe<Scalars["DateTime"]["input"]>;
}>;

export type FetchDirectIndexStockInfoForSubAccountQuery = {
  __typename?: "Query";
  fetchDirectIndexStockInfoForSubAccount?: {
    __typename?: "DirectIndexAccountInfo";
    diCash: Decimal;
    stockInfo: Array<{
      __typename?: "DirectIndexStockInfo";
      symbol: string;
      price: Decimal;
      targetWeight: Decimal;
      taxLots: Array<{
        __typename?: "DirectIndexTaxLot";
        id: string;
        securityId: string;
        subAccountId: string;
        eventTime: Date;
        settlementDate?: DateOnly | null;
        taxLotOpenBuyDate?: DateOnly | null;
        taxLotCloseSellDate?: DateOnly | null;
        openBuyPrice: Decimal;
        openBuyCostAmount: Decimal;
        openTransactionId: string;
        openLotId: string;
        closedLotId?: string | null;
        closedTransactionId?: string | null;
        quantity: Decimal;
        cost: Decimal;
        netProceed: Decimal;
        realizedGainLoss: Decimal;
        longTermShortTermIndicator: LongTermShortTermIndicatorEnumGql;
        realizedIndicator: RealizedIndicatorEnumGql;
        washSalesIndicator: WashSaleIndicatorEnumGql;
        washSalesDisallowed: Decimal;
        washSaleAdjustmentAmount: Decimal;
        buyBackIndicator: BuyBackIndicatorEnumGql;
      }>;
    }>;
  } | null;
};

export type DirectIndexTaxLotFragment = {
  __typename?: "DirectIndexTaxLot";
  id: string;
  securityId: string;
  subAccountId: string;
  eventTime: Date;
  settlementDate?: DateOnly | null;
  taxLotOpenBuyDate?: DateOnly | null;
  taxLotCloseSellDate?: DateOnly | null;
  openBuyPrice: Decimal;
  openBuyCostAmount: Decimal;
  openTransactionId: string;
  openLotId: string;
  closedLotId?: string | null;
  closedTransactionId?: string | null;
  quantity: Decimal;
  cost: Decimal;
  netProceed: Decimal;
  realizedGainLoss: Decimal;
  longTermShortTermIndicator: LongTermShortTermIndicatorEnumGql;
  realizedIndicator: RealizedIndicatorEnumGql;
  washSalesIndicator: WashSaleIndicatorEnumGql;
  washSalesDisallowed: Decimal;
  washSaleAdjustmentAmount: Decimal;
  buyBackIndicator: BuyBackIndicatorEnumGql;
};

export type GetDirectIndexTradeExecutionsQueryVariables = Exact<{
  input: GetDirectIndexTradeExecutionsInput;
}>;

export type GetDirectIndexTradeExecutionsQuery = {
  __typename?: "Query";
  getDirectIndexTradeExecutions: Array<{
    __typename?: "DirectIndexTradeExecution";
    id: string;
    subAccountId: string;
    batchOrderMetadataId?: string | null;
    status: TradeExecutionStatus;
    type?: TradeExecutionType | null;
    liquidateAmount?: Decimal | null;
    isFullLiquidation: boolean;
    eventTime: Date;
    cashLeftover?: Decimal | null;
    statusHistory?: Array<{
      __typename?: "DirectIndexTradeExecutionStatusHistory";
      id: string;
      directIndexTradeExecutionId: string;
      status: TradeExecutionStatus;
      eventTime: Date;
    }> | null;
    weights?: Array<{
      __typename?: "DirectIndexWeight";
      securityId: string;
      weight: Decimal;
      canLiquidate: boolean;
      canPurchase: boolean;
      canDisplayWeight: boolean;
      security?: {
        __typename?: "Security";
        id: string;
        asOfDate?: Date | null;
        buyStatus: SecurityTradeStatus;
        sellStatus: SecurityTradeStatus;
        cusip?: string | null;
        isin?: string | null;
        name: string;
        symbol: string;
        type: SecurityType;
        subType: SecuritySubType;
      } | null;
    }> | null;
  }>;
};

export type DirectIndexTradeExecutionFragment = {
  __typename?: "DirectIndexTradeExecution";
  id: string;
  subAccountId: string;
  batchOrderMetadataId?: string | null;
  status: TradeExecutionStatus;
  type?: TradeExecutionType | null;
  liquidateAmount?: Decimal | null;
  isFullLiquidation: boolean;
  eventTime: Date;
  cashLeftover?: Decimal | null;
  statusHistory?: Array<{
    __typename?: "DirectIndexTradeExecutionStatusHistory";
    id: string;
    directIndexTradeExecutionId: string;
    status: TradeExecutionStatus;
    eventTime: Date;
  }> | null;
  weights?: Array<{
    __typename?: "DirectIndexWeight";
    securityId: string;
    weight: Decimal;
    canLiquidate: boolean;
    canPurchase: boolean;
    canDisplayWeight: boolean;
    security?: {
      __typename?: "Security";
      id: string;
      asOfDate?: Date | null;
      buyStatus: SecurityTradeStatus;
      sellStatus: SecurityTradeStatus;
      cusip?: string | null;
      isin?: string | null;
      name: string;
      symbol: string;
      type: SecurityType;
      subType: SecuritySubType;
    } | null;
  }> | null;
};

export type DirectIndexTradeExecutionStatusHistoryFragment = {
  __typename?: "DirectIndexTradeExecutionStatusHistory";
  id: string;
  directIndexTradeExecutionId: string;
  status: TradeExecutionStatus;
  eventTime: Date;
};

export type SimulationGetSimulationsEntriesForSimulationIdQueryVariables =
  Exact<{
    simulationId: Scalars["Int"]["input"];
  }>;

export type SimulationGetSimulationsEntriesForSimulationIdQuery = {
  __typename?: "Query";
  simulationGetSimulationsEntriesForSimulationId: Array<{
    __typename?: "MarketDataSimulationEntry";
    id: string;
    query: string;
    response: string;
    simulationId: number;
    symbol?: string | null;
  }>;
};

export type SimulationGetSimulationIdsQueryVariables = Exact<{
  [key: string]: never;
}>;

export type SimulationGetSimulationIdsQuery = {
  __typename?: "Query";
  simulationGetSimulationIds: Array<number>;
};

export type BusinessEntityQueryVariables = Exact<{ [key: string]: never }>;

export type BusinessEntityQuery = {
  __typename?: "Query";
  businessEntity?: {
    __typename?: "BusinessEntity";
    id: string;
    name: string;
    taxId: string;
    userTitle: string;
    phoneNumber: string;
    faxNumber?: string | null;
    industry: BusinessEntityIndustry;
    entityType: BusinessEntityType;
    corporationSecretaryName?: string | null;
    multiplePartnersOrManagers?: boolean | null;
    foreignBankUSAgent?: string | null;
    largeTraderIds: Array<string>;
    businessScope: BusinessEntityScope;
    primaryFundingSource: BusinessEntityFundingSource;
    bankingInstitutionNames?: Array<string> | null;
    stateOfIncorporation: string;
    address: {
      __typename?: "GenericAddress";
      id: string;
      addressType: AddressType;
      addressLine1: string;
      addressLine2?: string | null;
      city: string;
      state: string;
      zipCode: string;
      country: string;
    };
    mailingAddress: {
      __typename?: "GenericAddress";
      id: string;
      addressType: AddressType;
      addressLine1: string;
      addressLine2?: string | null;
      city: string;
      state: string;
      zipCode: string;
      country: string;
    };
    entityInvestmentProfile?: {
      __typename?: "BusinessEntityInvestmentProfile";
      id: string;
      netWorthMin: number;
      netWorthMax: number;
      annualIncomeMin: number;
      annualIncomeMax: number;
      investmentExperience: InvestmentExperience;
      investmentObjective: BusinessEntityInvestmentObjective;
      investmentPlan: BusinessEntityInvestmentPlan;
      liquidityNeeds: LiquidityNeeds;
      liquidityTimeHorizon: TimeHorizon;
      riskTolerance: RiskTolerance;
      withdrawFrequency: BusinessEntityWithdrawFrequency;
      depositSource: string;
      initialDepositAmount: number;
    } | null;
    entityIndividuals?: Array<{
      __typename?: "BusinessEntityIndividual";
      id: string;
      individualType: BusinessEntityIndividualType;
      givenName: string;
      familyName: string;
      dateOfBirth: DateOnly;
      ssn: string;
      address?: Array<{
        __typename?: "GenericAddress";
        id: string;
        addressType: AddressType;
        addressLine1: string;
        addressLine2?: string | null;
        city: string;
        state: string;
        zipCode: string;
        country: string;
      }> | null;
      disclosure: {
        __typename?: "IndividualDisclosure";
        id: string;
        affiliatedExchangeOrFINRA: boolean;
        firmName?: string | null;
        controlPerson: boolean;
        companySymbols?: string | null;
        politicallyExposed: boolean;
        politicalOrganization?: string | null;
        immediateFamilyMembers?: Array<string> | null;
      };
    }> | null;
  } | null;
};

export type CashDividendQueryVariables = Exact<{
  clearingAccountId: Scalars["ID"]["input"];
  id: Scalars["ID"]["input"];
}>;

export type CashDividendQuery = {
  __typename?: "Query";
  clearingAccount?: {
    __typename?: "ClearingAccount";
    id: string;
    cashDividend?: {
      __typename?: "CashDividend";
      id: string;
      clearingAccountId: string;
      subAccountId: string;
      amount: Decimal;
      eventTime: Date;
      description?: string | null;
      security?: {
        __typename?: "Security";
        id: string;
        asOfDate?: Date | null;
        buyStatus: SecurityTradeStatus;
        sellStatus: SecurityTradeStatus;
        cusip?: string | null;
        isin?: string | null;
        name: string;
        symbol: string;
        type: SecurityType;
        subType: SecuritySubType;
      } | null;
      cashDividendFee: Array<{
        __typename?: "CashDividendFee";
        id: string;
        type: CashDividendFeeType;
        amount: Decimal;
      }>;
    } | null;
  } | null;
};

export type CashTransferQueryVariables = Exact<{
  clearingAccountId: Scalars["ID"]["input"];
  id: Scalars["ID"]["input"];
}>;

export type CashTransferQuery = {
  __typename?: "Query";
  clearingAccount?: {
    __typename?: "ClearingAccount";
    id: string;
    cashTransfer?: {
      __typename?: "CashTransfer";
      id: string;
      userId: string;
      clearingAccountId: string;
      subAccountId: string;
      amount: Decimal;
      method: CashTransferMethod;
      direction: CashTransferDirection;
      status: CashTransferStatus;
      eventTime: Date;
      statusHistory?: Array<{
        __typename?: "CashTransferStatusHistory";
        id: string;
        status: string;
        externalStatus?: string | null;
        externalStatusTime?: Date | null;
        reason?: string | null;
        reasonDesc?: string | null;
        reasonDetail?: string | null;
        reasonRecommendation?: string | null;
        eventTime: Date;
      }> | null;
      cashTransferFee?: Array<{
        __typename?: "CashTransferFee";
        id: string;
        type: CashTransferFeeType;
        customerDebit?: Decimal | null;
        clearingHouseCredit?: Decimal | null;
        correspondentNetAmount?: Decimal | null;
      }> | null;
      sourceDepositAccount?: {
        __typename?: "DepositAccount";
        id: string;
        clearingAccountId?: string | null;
        accountNumber?: string | null;
        name?: string | null;
        officialName?: string | null;
        subType: string;
      } | null;
      destinationDepositAccount?: {
        __typename?: "DepositAccount";
        id: string;
        clearingAccountId?: string | null;
        accountNumber?: string | null;
        name?: string | null;
        officialName?: string | null;
        subType: string;
      } | null;
    } | null;
  } | null;
};

export type DemoCashTransferQueryVariables = Exact<{
  clearingAccountId: Scalars["ID"]["input"];
  id: Scalars["ID"]["input"];
}>;

export type DemoCashTransferQuery = {
  __typename?: "Query";
  clearingAccount?: {
    __typename?: "ClearingAccount";
    id: string;
    cashTransfer?: {
      __typename?: "CashTransfer";
      id: string;
      userId: string;
      clearingAccountId: string;
      subAccountId: string;
      amount: Decimal;
      method: CashTransferMethod;
      direction: CashTransferDirection;
      status: CashTransferStatus;
      eventTime: Date;
      statusHistory?: Array<{
        __typename?: "CashTransferStatusHistory";
        id: string;
        status: string;
        externalStatus?: string | null;
        externalStatusTime?: Date | null;
        reason?: string | null;
        reasonDesc?: string | null;
        reasonDetail?: string | null;
        reasonRecommendation?: string | null;
        eventTime: Date;
      }> | null;
      cashTransferFee?: Array<{
        __typename?: "CashTransferFee";
        id: string;
        type: CashTransferFeeType;
        customerDebit?: Decimal | null;
        clearingHouseCredit?: Decimal | null;
        correspondentNetAmount?: Decimal | null;
      }> | null;
      sourceDepositAccount?: {
        __typename?: "DepositAccount";
        id: string;
        clearingAccountId?: string | null;
        accountNumber?: string | null;
        name?: string | null;
        officialName?: string | null;
        subType: string;
      } | null;
      destinationDepositAccount?: {
        __typename?: "DepositAccount";
        id: string;
        clearingAccountId?: string | null;
        accountNumber?: string | null;
        name?: string | null;
        officialName?: string | null;
        subType: string;
      } | null;
    } | null;
  } | null;
};

export type CashTransfersQueryVariables = Exact<{
  args: GetCashTransfersArgs;
}>;

export type CashTransfersQuery = {
  __typename?: "Query";
  getCashTransfers: {
    __typename?: "PaginatedCashTransferResponse";
    nextPageToken?: string | null;
    data?: Array<{
      __typename?: "CashTransfer";
      id: string;
      amount: Decimal;
      method: CashTransferMethod;
      direction: CashTransferDirection;
      status: CashTransferStatus;
      eventTime: Date;
    }> | null;
  };
};

export type ClearingAccountQueryVariables = Exact<{
  input: ClearingAccountInput;
}>;

export type ClearingAccountQuery = {
  __typename?: "Query";
  clearingAccount?: {
    __typename?: "ClearingAccount";
    id: string;
    accountType?: AccountType | null;
    categorizationType?: CategorizationType | null;
    externalClearingAccountId: string;
    eventTime?: Date | null;
    isTestAccount?: boolean | null;
    isPatternDayTrader?: boolean | null;
    isClientAccount?: boolean | null;
    primarySubAccountId: string;
    subAccounts: Array<{
      __typename?: "SubAccount";
      id: string;
      type: SubAccountType;
      eventTime: Date;
      pricing?: Array<{
        __typename?: "SubAccountPricing";
        id: string;
        amount: Decimal;
        unit: SubAccountPricingUnit;
        validFrom?: Date | null;
        validTo?: Date | null;
      }> | null;
      directIndexAccountMetadata?: {
        __typename?: "DirectIndexAccountMetadata";
        id: string;
        type: DirectIndexType;
        etfSymbol?: string | null;
        signedAt?: Date | null;
        lhfOverride?: Decimal | null;
        subAccountId: string;
        status?: DirectIndexStatus | null;
        customization?: {
          __typename?: "DirectIndexCustomization";
          id: string;
          removeGICSSectorIds: Array<GicsCode>;
          addSecurityIds: Array<{
            __typename?: "IndexCustomizationSecurity";
            securityId: string;
            security?: {
              __typename?: "Security";
              id: string;
              asOfDate?: Date | null;
              buyStatus: SecurityTradeStatus;
              sellStatus: SecurityTradeStatus;
              cusip?: string | null;
              isin?: string | null;
              name: string;
              symbol: string;
              type: SecurityType;
              subType: SecuritySubType;
            } | null;
          }>;
          removeSecurityIds: Array<{
            __typename?: "IndexCustomizationSecurity";
            securityId: string;
            security?: {
              __typename?: "Security";
              id: string;
              asOfDate?: Date | null;
              buyStatus: SecurityTradeStatus;
              sellStatus: SecurityTradeStatus;
              cusip?: string | null;
              isin?: string | null;
              name: string;
              symbol: string;
              type: SecurityType;
              subType: SecuritySubType;
            } | null;
          }>;
        } | null;
      } | null;
    }>;
    marginCallData?: {
      __typename?: "MarginCallData";
      id: string;
      dueDate: Date;
      eventTime: Date;
      statuses: Array<{
        __typename?: "MarginCallDataStatus";
        state: MarginCallState;
        amount: Decimal;
        eventTime: Date;
      }>;
    } | null;
    actionContexts?: Array<{
      __typename?: "ActionContextStatus";
      actionContext: ActionContext;
      isAllowed: boolean;
    }> | null;
    restrictions?: Array<{
      __typename?: "ClearingAccountRestriction";
      restrictionType: ClearingAccountRestrictionType;
      active: boolean;
    }> | null;
    entity?:
      | {
          __typename?: "BusinessEntityInfo";
          businessEntityDetails: {
            __typename?: "BusinessEntityDetails";
            id: string;
            name: string;
            phoneNumber: string;
            industry: BusinessEntityIndustry;
            address?: Array<{
              __typename?: "GenericAddress";
              addressType: AddressType;
              addressLine1: string;
              addressLine2?: string | null;
              city: string;
              state: string;
              zipCode: string;
              country: string;
            }> | null;
          };
          entityAuthorizedUser: {
            __typename?: "BusinessEntityAuthorizedUser";
            userTitle: string;
            user?: {
              __typename?: "User";
              userPersonalDetails?: {
                __typename?: "UserPersonalDetails";
                fullName: string;
              } | null;
              userIdentityDetails?: {
                __typename?: "UserIdentityDetails";
                citizenshipCountry: string;
                dateOfBirth: DateOnly;
                ssn: string;
              } | null;
              userContactPhone?: {
                __typename?: "UserContactPhone";
                phoneNumber: string;
              } | null;
              userContactEmail?: {
                __typename?: "UserContactEmail";
                email: string;
              } | null;
            } | null;
          };
          entityIndividuals: Array<{
            __typename?: "BusinessEntityIndividual";
            familyName: string;
            givenName: string;
            dateOfBirth: DateOnly;
            individualType: BusinessEntityIndividualType;
            address?: Array<{
              __typename?: "GenericAddress";
              addressType: AddressType;
              addressLine1: string;
              addressLine2?: string | null;
              city: string;
              state: string;
              zipCode: string;
              country: string;
            }> | null;
          }>;
        }
      | {
          __typename?: "TrustEntity";
          trustEntityDetails: {
            __typename?: "TrustEntityDetails";
            id: string;
            name: string;
            taxId: string;
            taxIdType?: TaxIdType | null;
            dateOfCreation: DateOnly;
            revocability?: TrustRevocability | null;
            state: string;
            trustAgreementAllowsMarginsAndShortPositions?: boolean | null;
            address?: {
              __typename?: "GenericAddress";
              id: string;
              addressType: AddressType;
              addressLine1: string;
              addressLine2?: string | null;
              city: string;
              state: string;
              zipCode: string;
              country: string;
            } | null;
          };
          primaryTrustee?: {
            __typename?: "User";
            userContactEmail?: {
              __typename?: "UserContactEmail";
              email: string;
              emailType: EmailType;
            } | null;
            userEmploymentDetails?: Array<{
              __typename?: "UserEmploymentDetails";
              employerName?: string | null;
              occupation?: string | null;
            }> | null;
            userIdentityDetails?: {
              __typename?: "UserIdentityDetails";
              dateOfBirth: DateOnly;
              citizenshipCountry: string;
            } | null;
            userPersonalDetails?: {
              __typename?: "UserPersonalDetails";
              fullName: string;
            } | null;
            userContactPhone?: {
              __typename?: "UserContactPhone";
              phoneNumber: string;
            } | null;
            userAddresses?: Array<{
              __typename?: "GenericAddress";
              addressType: AddressType;
              addressLine1: string;
              addressLine2?: string | null;
              city: string;
              state: string;
              zipCode: string;
              country: string;
            }> | null;
          } | null;
          additionalTrustees: Array<{
            __typename?: "Trustee";
            id: string;
            name: string;
            familyName?: string | null;
            givenName?: string | null;
            dateOfBirth: DateOnly;
            idType?: TrusteeIdType | null;
            idIssuingStateOrCountry?: string | null;
            idExpirationDate?: DateOnly | null;
            governmentIdNumber?: string | null;
            disclosure?: {
              __typename?: "IndividualDisclosure";
              id: string;
              affiliatedExchangeOrFINRA: boolean;
              firmName?: string | null;
              controlPerson: boolean;
              companySymbols?: string | null;
              politicallyExposed: boolean;
              politicalOrganization?: string | null;
              politicalTitle?: string | null;
              immediateFamilyMembers?: Array<string> | null;
            } | null;
            address: {
              __typename?: "GenericAddress";
              id: string;
              addressType: AddressType;
              addressLine1: string;
              addressLine2?: string | null;
              city: string;
              state: string;
              zipCode: string;
              country: string;
            };
          }>;
        }
      | null;
    treasury?: {
      __typename?: "TreasuryConfig";
      subAccountId: string;
      eventTime: Date;
    } | null;
    dividendPreference?: Array<{
      __typename?: "DividendPreference";
      id: string;
      clearingAccountId: string;
      subAccountType: SubAccountType;
      preferenceType: DividendPreferenceType;
    }> | null;
  } | null;
};

export type ClearingAccountInvestigationStatusQueryVariables = Exact<{
  input: ClearingAccountInput;
}>;

export type ClearingAccountInvestigationStatusQuery = {
  __typename?: "Query";
  clearingAccount?: {
    __typename?: "ClearingAccount";
    id: string;
    cipInvestigationResult?: {
      __typename?: "CIPInvestigationResult";
      status: CipInvestigationStatus;
      categories: Array<{
        __typename?: "CIPInvestigationCategory";
        name: CipCategory;
        requestedDocuments?: Array<{
          __typename?: "CIPInvestigationCategoryRequestDocument";
          name: string;
          description: string;
        }> | null;
      }>;
    } | null;
  } | null;
};

export type DailyCashDividendQueryVariables = Exact<{
  clearingAccountId: Scalars["ID"]["input"];
  id: Scalars["ID"]["input"];
}>;

export type DailyCashDividendQuery = {
  __typename?: "Query";
  clearingAccount?: {
    __typename?: "ClearingAccount";
    id: string;
    dailyCashDividend?: {
      __typename?: "DailyCashDividend";
      id: string;
      clearingAccountId: string;
      subAccountId: string;
      amount: Decimal;
      eventTime: Date;
      dividends: Array<{
        __typename?: "DividendSecurity";
        amount: Decimal;
        security?: {
          __typename?: "Security";
          id: string;
          asOfDate?: Date | null;
          buyStatus: SecurityTradeStatus;
          sellStatus: SecurityTradeStatus;
          cusip?: string | null;
          isin?: string | null;
          name: string;
          symbol: string;
          type: SecurityType;
          subType: SecuritySubType;
        } | null;
      }>;
      fees: Array<{
        __typename?: "DividendSecurityFee";
        amount: Decimal;
        type: CashDividendFeeType;
        security?: {
          __typename?: "Security";
          id: string;
          asOfDate?: Date | null;
          buyStatus: SecurityTradeStatus;
          sellStatus: SecurityTradeStatus;
          cusip?: string | null;
          isin?: string | null;
          name: string;
          symbol: string;
          type: SecurityType;
          subType: SecuritySubType;
        } | null;
      }>;
    } | null;
  } | null;
};

export type DemoDailyCashDividendQueryVariables = Exact<{
  clearingAccountId: Scalars["ID"]["input"];
  id: Scalars["ID"]["input"];
}>;

export type DemoDailyCashDividendQuery = {
  __typename?: "Query";
  clearingAccount?: {
    __typename?: "ClearingAccount";
    id: string;
    dailyCashDividend?: {
      __typename?: "DailyCashDividend";
      id: string;
      clearingAccountId: string;
      subAccountId: string;
      amount: Decimal;
      eventTime: Date;
      dividends: Array<{
        __typename?: "DividendSecurity";
        amount: Decimal;
        security?: {
          __typename?: "Security";
          id: string;
          asOfDate?: Date | null;
          buyStatus: SecurityTradeStatus;
          sellStatus: SecurityTradeStatus;
          cusip?: string | null;
          isin?: string | null;
          name: string;
          symbol: string;
          type: SecurityType;
          subType: SecuritySubType;
        } | null;
      }>;
      fees: Array<{
        __typename?: "DividendSecurityFee";
        amount: Decimal;
        type: CashDividendFeeType;
        security?: {
          __typename?: "Security";
          id: string;
          asOfDate?: Date | null;
          buyStatus: SecurityTradeStatus;
          sellStatus: SecurityTradeStatus;
          cusip?: string | null;
          isin?: string | null;
          name: string;
          symbol: string;
          type: SecurityType;
          subType: SecuritySubType;
        } | null;
      }>;
    } | null;
  } | null;
};

export type DemoClearingAccountQueryVariables = Exact<{
  clearingAccountId: Scalars["ID"]["input"];
}>;

export type DemoClearingAccountQuery = {
  __typename?: "Query";
  clearingAccount?: {
    __typename?: "ClearingAccount";
    id: string;
    accountType?: AccountType | null;
    categorizationType?: CategorizationType | null;
    externalClearingAccountId: string;
    eventTime?: Date | null;
    isTestAccount?: boolean | null;
    isPatternDayTrader?: boolean | null;
    isClientAccount?: boolean | null;
    primarySubAccountId: string;
    subAccounts: Array<{
      __typename?: "SubAccount";
      id: string;
      type: SubAccountType;
      eventTime: Date;
      pricing?: Array<{
        __typename?: "SubAccountPricing";
        id: string;
        amount: Decimal;
        unit: SubAccountPricingUnit;
        validFrom?: Date | null;
        validTo?: Date | null;
      }> | null;
      directIndexAccountMetadata?: {
        __typename?: "DirectIndexAccountMetadata";
        id: string;
        type: DirectIndexType;
        etfSymbol?: string | null;
        signedAt?: Date | null;
        lhfOverride?: Decimal | null;
        subAccountId: string;
        status?: DirectIndexStatus | null;
        customization?: {
          __typename?: "DirectIndexCustomization";
          id: string;
          removeGICSSectorIds: Array<GicsCode>;
          addSecurityIds: Array<{
            __typename?: "IndexCustomizationSecurity";
            securityId: string;
            security?: {
              __typename?: "Security";
              id: string;
              asOfDate?: Date | null;
              buyStatus: SecurityTradeStatus;
              sellStatus: SecurityTradeStatus;
              cusip?: string | null;
              isin?: string | null;
              name: string;
              symbol: string;
              type: SecurityType;
              subType: SecuritySubType;
            } | null;
          }>;
          removeSecurityIds: Array<{
            __typename?: "IndexCustomizationSecurity";
            securityId: string;
            security?: {
              __typename?: "Security";
              id: string;
              asOfDate?: Date | null;
              buyStatus: SecurityTradeStatus;
              sellStatus: SecurityTradeStatus;
              cusip?: string | null;
              isin?: string | null;
              name: string;
              symbol: string;
              type: SecurityType;
              subType: SecuritySubType;
            } | null;
          }>;
        } | null;
      } | null;
    }>;
    marginCallData?: {
      __typename?: "MarginCallData";
      id: string;
      dueDate: Date;
      eventTime: Date;
      statuses: Array<{
        __typename?: "MarginCallDataStatus";
        state: MarginCallState;
        amount: Decimal;
        eventTime: Date;
      }>;
    } | null;
    actionContexts?: Array<{
      __typename?: "ActionContextStatus";
      actionContext: ActionContext;
      isAllowed: boolean;
    }> | null;
    restrictions?: Array<{
      __typename?: "ClearingAccountRestriction";
      restrictionType: ClearingAccountRestrictionType;
      active: boolean;
    }> | null;
    entity?:
      | {
          __typename?: "BusinessEntityInfo";
          businessEntityDetails: {
            __typename?: "BusinessEntityDetails";
            id: string;
            name: string;
            phoneNumber: string;
            industry: BusinessEntityIndustry;
            address?: Array<{
              __typename?: "GenericAddress";
              addressType: AddressType;
              addressLine1: string;
              addressLine2?: string | null;
              city: string;
              state: string;
              zipCode: string;
              country: string;
            }> | null;
          };
          entityAuthorizedUser: {
            __typename?: "BusinessEntityAuthorizedUser";
            userTitle: string;
            user?: {
              __typename?: "User";
              userPersonalDetails?: {
                __typename?: "UserPersonalDetails";
                fullName: string;
              } | null;
              userIdentityDetails?: {
                __typename?: "UserIdentityDetails";
                citizenshipCountry: string;
                dateOfBirth: DateOnly;
                ssn: string;
              } | null;
              userContactPhone?: {
                __typename?: "UserContactPhone";
                phoneNumber: string;
              } | null;
              userContactEmail?: {
                __typename?: "UserContactEmail";
                email: string;
              } | null;
            } | null;
          };
          entityIndividuals: Array<{
            __typename?: "BusinessEntityIndividual";
            familyName: string;
            givenName: string;
            dateOfBirth: DateOnly;
            individualType: BusinessEntityIndividualType;
            address?: Array<{
              __typename?: "GenericAddress";
              addressType: AddressType;
              addressLine1: string;
              addressLine2?: string | null;
              city: string;
              state: string;
              zipCode: string;
              country: string;
            }> | null;
          }>;
        }
      | {
          __typename?: "TrustEntity";
          trustEntityDetails: {
            __typename?: "TrustEntityDetails";
            id: string;
            name: string;
            taxId: string;
            taxIdType?: TaxIdType | null;
            dateOfCreation: DateOnly;
            revocability?: TrustRevocability | null;
            state: string;
            trustAgreementAllowsMarginsAndShortPositions?: boolean | null;
            address?: {
              __typename?: "GenericAddress";
              id: string;
              addressType: AddressType;
              addressLine1: string;
              addressLine2?: string | null;
              city: string;
              state: string;
              zipCode: string;
              country: string;
            } | null;
          };
          primaryTrustee?: {
            __typename?: "User";
            userContactEmail?: {
              __typename?: "UserContactEmail";
              email: string;
              emailType: EmailType;
            } | null;
            userEmploymentDetails?: Array<{
              __typename?: "UserEmploymentDetails";
              employerName?: string | null;
              occupation?: string | null;
            }> | null;
            userIdentityDetails?: {
              __typename?: "UserIdentityDetails";
              dateOfBirth: DateOnly;
              citizenshipCountry: string;
            } | null;
            userPersonalDetails?: {
              __typename?: "UserPersonalDetails";
              fullName: string;
            } | null;
            userContactPhone?: {
              __typename?: "UserContactPhone";
              phoneNumber: string;
            } | null;
            userAddresses?: Array<{
              __typename?: "GenericAddress";
              addressType: AddressType;
              addressLine1: string;
              addressLine2?: string | null;
              city: string;
              state: string;
              zipCode: string;
              country: string;
            }> | null;
          } | null;
          additionalTrustees: Array<{
            __typename?: "Trustee";
            id: string;
            name: string;
            familyName?: string | null;
            givenName?: string | null;
            dateOfBirth: DateOnly;
            idType?: TrusteeIdType | null;
            idIssuingStateOrCountry?: string | null;
            idExpirationDate?: DateOnly | null;
            governmentIdNumber?: string | null;
            disclosure?: {
              __typename?: "IndividualDisclosure";
              id: string;
              affiliatedExchangeOrFINRA: boolean;
              firmName?: string | null;
              controlPerson: boolean;
              companySymbols?: string | null;
              politicallyExposed: boolean;
              politicalOrganization?: string | null;
              politicalTitle?: string | null;
              immediateFamilyMembers?: Array<string> | null;
            } | null;
            address: {
              __typename?: "GenericAddress";
              id: string;
              addressType: AddressType;
              addressLine1: string;
              addressLine2?: string | null;
              city: string;
              state: string;
              zipCode: string;
              country: string;
            };
          }>;
        }
      | null;
    treasury?: {
      __typename?: "TreasuryConfig";
      subAccountId: string;
      eventTime: Date;
    } | null;
    dividendPreference?: Array<{
      __typename?: "DividendPreference";
      id: string;
      clearingAccountId: string;
      subAccountType: SubAccountType;
      preferenceType: DividendPreferenceType;
    }> | null;
  } | null;
};

export type DetailedQuoteQueryVariables = Exact<{
  input: DetailedQuoteInput;
}>;

export type DetailedQuoteQuery = {
  __typename?: "Query";
  detailedQuote: Array<{
    __typename?: "DetailedQuote";
    ask?: number | null;
    askMarketIdentificationCode?: string | null;
    askSize?: number | null;
    askTime?: Date | null;
    bid?: number | null;
    bidMarketIdentificationCode?: string | null;
    bidSize?: number | null;
    bidTime?: Date | null;
    changeFromPreviousClose: number;
    close: number;
    currency: string;
    delay: number;
    high: number;
    high52Weeks: number;
    identifier: string;
    identifierType: string;
    last: number;
    lastMarketIdentificationCode?: string | null;
    lastSize: number;
    low: number;
    low52Weeks: number;
    message: string;
    open: number;
    outcome: string;
    percentChangeFromPreviousClose: number;
    previousClose: number;
    previousCloseDate: DateOnly;
    time: Date;
    tradingHalted: boolean;
    volume: number;
    security: {
      __typename?: "DetailedSecurity";
      mostLiquidExchange: boolean;
      categoryOrIndustry?: string | null;
      marketIdentificationCode: string;
      market: string;
      name: string;
      valoren?: string | null;
      ISIN?: string | null;
      symbol: string;
      CUSIP?: string | null;
      CIK?: string | null;
    };
  }>;
};

export type DirectIndexFeeChargeQueryVariables = Exact<{
  clearingAccountId: Scalars["ID"]["input"];
  id: Scalars["ID"]["input"];
}>;

export type DirectIndexFeeChargeQuery = {
  __typename?: "Query";
  getDirectIndexFeeCharge?: {
    __typename?: "DirectIndexFeeCharge";
    id: string;
    amount: Decimal;
    eventTime: Date;
    billingStartDate: Date;
    billingEndDate: Date;
    billedTo: DirectIndexFeeBilledTo;
  } | null;
};

export type DemoDirectIndexFeeChargeQueryVariables = Exact<{
  clearingAccountId: Scalars["ID"]["input"];
  id: Scalars["ID"]["input"];
}>;

export type DemoDirectIndexFeeChargeQuery = {
  __typename?: "Query";
  getDirectIndexFeeCharge?: {
    __typename?: "DirectIndexFeeCharge";
    id: string;
    amount: Decimal;
    eventTime: Date;
    billingStartDate: Date;
    billingEndDate: Date;
    billedTo: DirectIndexFeeBilledTo;
  } | null;
};

export type DirectIndexFeeChargesQueryVariables = Exact<{
  clearingAccountId: Scalars["ID"]["input"];
  subAccountId: Scalars["ID"]["input"];
}>;

export type DirectIndexFeeChargesQuery = {
  __typename?: "Query";
  getDirectIndexFeeCharges?: Array<{
    __typename?: "DirectIndexFeeCharge";
    id: string;
    amount: Decimal;
    eventTime: Date;
    billingStartDate: Date;
    billingEndDate: Date;
    billedTo: DirectIndexFeeBilledTo;
  }> | null;
};

export type DemoDirectIndexFeeChargesQueryVariables = Exact<{
  clearingAccountId: Scalars["ID"]["input"];
  subAccountId: Scalars["ID"]["input"];
}>;

export type DemoDirectIndexFeeChargesQuery = {
  __typename?: "Query";
  getDirectIndexFeeCharges?: Array<{
    __typename?: "DirectIndexFeeCharge";
    id: string;
    amount: Decimal;
    eventTime: Date;
    billingStartDate: Date;
    billingEndDate: Date;
    billedTo: DirectIndexFeeBilledTo;
  }> | null;
};

export type DirectIndexTradeExecutionSummaryQueryVariables = Exact<{
  clearingAccountId: Scalars["ID"]["input"];
  id: Scalars["ID"]["input"];
}>;

export type DirectIndexTradeExecutionSummaryQuery = {
  __typename?: "Query";
  getDirectIndexTradeExecutionSummary?: {
    __typename?: "DirectIndexTradeExecutionSummary";
    id: string;
    subAccountId: string;
    clearingAccountId: string;
    status: TradeExecutionStatus;
    eventTime: Date;
    latestStatusTime?: Date | null;
    cashLeftover?: Decimal | null;
    isLiquidationRequest: boolean;
    orders: Array<{
      __typename?: "TradeExecutionOrders";
      securityId: string;
      quantity: Decimal;
      shortTermGain?: Decimal | null;
      shortTermLoss?: Decimal | null;
      longTermGain?: Decimal | null;
      longTermLoss?: Decimal | null;
      notional: Decimal;
      orderSide: OrderSide;
      security?: {
        __typename?: "Security";
        id: string;
        asOfDate?: Date | null;
        buyStatus: SecurityTradeStatus;
        sellStatus: SecurityTradeStatus;
        cusip?: string | null;
        isin?: string | null;
        name: string;
        symbol: string;
        type: SecurityType;
        subType: SecuritySubType;
      } | null;
    }>;
  } | null;
};

export type DemoDirectIndexTradeExecutionSummaryQueryVariables = Exact<{
  clearingAccountId: Scalars["ID"]["input"];
  id: Scalars["ID"]["input"];
}>;

export type DemoDirectIndexTradeExecutionSummaryQuery = {
  __typename?: "Query";
  getDirectIndexTradeExecutionSummary?: {
    __typename?: "DirectIndexTradeExecutionSummary";
    id: string;
    subAccountId: string;
    clearingAccountId: string;
    status: TradeExecutionStatus;
    eventTime: Date;
    latestStatusTime?: Date | null;
    cashLeftover?: Decimal | null;
    isLiquidationRequest: boolean;
    orders: Array<{
      __typename?: "TradeExecutionOrders";
      securityId: string;
      quantity: Decimal;
      shortTermGain?: Decimal | null;
      shortTermLoss?: Decimal | null;
      longTermGain?: Decimal | null;
      longTermLoss?: Decimal | null;
      notional: Decimal;
      orderSide: OrderSide;
      security?: {
        __typename?: "Security";
        id: string;
        asOfDate?: Date | null;
        buyStatus: SecurityTradeStatus;
        sellStatus: SecurityTradeStatus;
        cusip?: string | null;
        isin?: string | null;
        name: string;
        symbol: string;
        type: SecurityType;
        subType: SecuritySubType;
      } | null;
    }>;
  } | null;
};

export type DividendSummaryQueryVariables = Exact<{
  clearingAccountId: Scalars["ID"]["input"];
  subAccountId?: InputMaybe<Scalars["ID"]["input"]>;
  startDate?: InputMaybe<Scalars["Date"]["input"]>;
  endDate?: InputMaybe<Scalars["Date"]["input"]>;
}>;

export type DividendSummaryQuery = {
  __typename?: "Query";
  clearingAccount?: {
    __typename?: "ClearingAccount";
    id: string;
    dividendSummary?: {
      __typename?: "DividendSummary";
      totalDividends: Decimal;
      stockAdjustmentAmounts?: Array<{
        __typename?: "StockAdjustmentAmountByType";
        adjustmentType: AdjustmentType;
        amount: Decimal;
      }> | null;
    } | null;
  } | null;
};

export type DividendsForHoldingQueryVariables = Exact<{
  clearingAccountId: Scalars["ID"]["input"];
  securityId: Scalars["ID"]["input"];
  after?: InputMaybe<Scalars["DateTime"]["input"]>;
}>;

export type DividendsForHoldingQuery = {
  __typename?: "Query";
  clearingAccount?: {
    __typename?: "ClearingAccount";
    id: string;
    cashDividendsForHolding?: Array<{
      __typename?: "CashDividend";
      id: string;
      clearingAccountId: string;
      subAccountId: string;
      amount: Decimal;
      eventTime: Date;
      description?: string | null;
      security?: {
        __typename?: "Security";
        id: string;
        asOfDate?: Date | null;
        buyStatus: SecurityTradeStatus;
        sellStatus: SecurityTradeStatus;
        cusip?: string | null;
        isin?: string | null;
        name: string;
        symbol: string;
        type: SecurityType;
        subType: SecuritySubType;
      } | null;
      cashDividendFee: Array<{
        __typename?: "CashDividendFee";
        id: string;
        type: CashDividendFeeType;
        amount: Decimal;
      }>;
    }> | null;
    stockAdjustmentsForHolding?: Array<{
      __typename?: "StockAdjustmentDetails";
      id: string;
      clearingAccountId: string;
      subAccountId: string;
      securityId: string;
      adjustmentAmount?: Decimal | null;
      adjustmentFactor: Decimal;
      adjustmentType: AdjustmentType;
      effectiveTime: Date;
      eventTime: Date;
      securityPrice?: Decimal | null;
      security?: {
        __typename?: "Security";
        id: string;
        asOfDate?: Date | null;
        buyStatus: SecurityTradeStatus;
        sellStatus: SecurityTradeStatus;
        cusip?: string | null;
        isin?: string | null;
        name: string;
        symbol: string;
        type: SecurityType;
        subType: SecuritySubType;
      } | null;
      treasuryFees?: Array<{
        __typename?: "TreasuryFee";
        id: string;
        amount: Decimal;
        status: TreasuryFeeStatus;
      }> | null;
    }> | null;
  } | null;
};

export type EstimateDirectIndexGainLossQueryVariables = Exact<{
  input: EstimateDirectIndexGainLossInput;
}>;

export type EstimateDirectIndexGainLossQuery = {
  __typename?: "Query";
  estimateDirectIndexGainLoss: {
    __typename?: "EstimateDirectIndexGainLossResponse";
    totalLongTermGainsWithoutWashSales: Decimal;
    totalShortTermGainsWithoutWashSales: Decimal;
    totalLongTermWashSales: Decimal;
    totalShortTermWashSales: Decimal;
    netShortTermLossesFromSubAccountsYTD: Decimal;
    netLongTermLossesFromSubAccountsYTD: Decimal;
    lossHarvestPotential: Decimal;
    securityGainLoss: Array<{
      __typename?: "SecurityGainLoss";
      securityId: string;
      longTermGains: Decimal;
      longTermWashSales: Decimal;
      shortTermGains: Decimal;
      shortTermWashSales: Decimal;
    }>;
  };
};

export type GetAnnualizedReturnsQueryVariables = Exact<{
  indices: Array<StockIndex> | StockIndex;
  asOfDate?: InputMaybe<Scalars["Date"]["input"]>;
}>;

export type GetAnnualizedReturnsQuery = {
  __typename?: "Query";
  getAnnualizedReturns: Array<{
    __typename?: "AnnualizedReturnResponse";
    index: StockIndex;
    annualizedReturns: Array<{
      __typename?: "AnnualizedReturn";
      annualizedReturn: number;
      endDate: DateOnly;
      endPrice: number;
      startDate: DateOnly;
      startConstituentCount: number;
      endConstituentCount: number;
      startPrice: number;
      years: number;
    }>;
  }>;
};

export type GetAvailableIndexTypesQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetAvailableIndexTypesQuery = {
  __typename?: "Query";
  getAvailableIndexTypes: Array<DirectIndexType>;
};

export type GetDayTradeCountQueryVariables = Exact<{
  input: CheckDayTradeInput;
}>;

export type GetDayTradeCountQuery = {
  __typename?: "Query";
  getDayTradeCount: {
    __typename?: "DayTradeResponse";
    dayTrades: number;
    totalTrades: number;
    currentTradeFlagged: boolean;
    dayRange: number;
  };
};

export type GetDirectIndexSecurityOverridesQueryVariables = Exact<{
  subAccountId: Scalars["ID"]["input"];
}>;

export type GetDirectIndexSecurityOverridesQuery = {
  __typename?: "Query";
  getDirectIndexSecurityOverrides: {
    __typename?: "DirectIndexAccountWeights";
    subAccountId: string;
    weights: Array<{
      __typename?: "DirectIndexWeight";
      securityId: string;
      weight: Decimal;
      canLiquidate: boolean;
      canPurchase: boolean;
      canDisplayWeight: boolean;
      security?: {
        __typename?: "Security";
        id: string;
        asOfDate?: Date | null;
        buyStatus: SecurityTradeStatus;
        sellStatus: SecurityTradeStatus;
        cusip?: string | null;
        isin?: string | null;
        name: string;
        symbol: string;
        type: SecurityType;
        subType: SecuritySubType;
      } | null;
    }>;
  };
};

export type GetDirectIndexWeightsForAccountQueryVariables = Exact<{
  subAccountId: Scalars["ID"]["input"];
}>;

export type GetDirectIndexWeightsForAccountQuery = {
  __typename?: "Query";
  getDirectIndexWeightsForAccount: {
    __typename?: "DirectIndexAccountWeights";
    subAccountId: string;
    weights: Array<{
      __typename?: "DirectIndexWeight";
      securityId: string;
      weight: Decimal;
      canLiquidate: boolean;
      canPurchase: boolean;
      canDisplayWeight: boolean;
      security?: {
        __typename?: "Security";
        id: string;
        asOfDate?: Date | null;
        buyStatus: SecurityTradeStatus;
        sellStatus: SecurityTradeStatus;
        cusip?: string | null;
        isin?: string | null;
        name: string;
        symbol: string;
        type: SecurityType;
        subType: SecuritySubType;
      } | null;
    }>;
  };
};

export type DemoGetDirectIndexWeightsForAccountQueryVariables = Exact<{
  subAccountId: Scalars["ID"]["input"];
}>;

export type DemoGetDirectIndexWeightsForAccountQuery = {
  __typename?: "Query";
  getDirectIndexWeightsForAccount: {
    __typename?: "DirectIndexAccountWeights";
    subAccountId: string;
    weights: Array<{
      __typename?: "DirectIndexWeight";
      securityId: string;
      weight: Decimal;
      canLiquidate: boolean;
      canPurchase: boolean;
      canDisplayWeight: boolean;
      security?: {
        __typename?: "Security";
        id: string;
        asOfDate?: Date | null;
        buyStatus: SecurityTradeStatus;
        sellStatus: SecurityTradeStatus;
        cusip?: string | null;
        isin?: string | null;
        name: string;
        symbol: string;
        type: SecurityType;
        subType: SecuritySubType;
      } | null;
    }>;
  };
};

export type GetIndexConstituentsQueryVariables = Exact<{
  index: StockIndex;
  gicsCode?: InputMaybe<GicsCode>;
  asOfDate?: InputMaybe<Scalars["Date"]["input"]>;
}>;

export type GetIndexConstituentsQuery = {
  __typename?: "Query";
  getIndexConstituents: {
    __typename?: "IndexConstituentData";
    index: StockIndex;
    constituents: Array<{
      __typename?: "IndexConstituent";
      symbol: string;
      name: string;
      weight: number;
      effectiveDate: DateOnly;
      marketCap: number;
      indexMarketCap: number;
      price: number;
      gicsCode?: GicsCode | null;
      fractionalSupported: boolean;
    }>;
  };
};

export type DemoGetIndexConstituentsQueryVariables = Exact<{
  index: StockIndex;
  gicsCode?: InputMaybe<GicsCode>;
  asOfDate?: InputMaybe<Scalars["Date"]["input"]>;
}>;

export type DemoGetIndexConstituentsQuery = {
  __typename?: "Query";
  getIndexConstituents: {
    __typename?: "IndexConstituentData";
    index: StockIndex;
    constituents: Array<{
      __typename?: "IndexConstituent";
      symbol: string;
      name: string;
      weight: number;
      effectiveDate: DateOnly;
      marketCap: number;
      indexMarketCap: number;
      price: number;
      gicsCode?: GicsCode | null;
      fractionalSupported: boolean;
    }>;
  };
};

export type GetAllMmfMetadataQueryVariables = Exact<{ [key: string]: never }>;

export type GetAllMmfMetadataQuery = {
  __typename?: "Query";
  mmfMetadata: Array<{
    __typename?: "MMFMetadata";
    name: string;
    cusip: string;
    symbol: string;
    nav: number;
    netAssets: number;
    secYield7Day: number;
    expenseRatio: number;
    inceptionDate: Date;
    minInvestment: number;
    ytdYield: number;
    dividendSchedule: MmfDividendScheduleFrequency;
    lastUpdated?: Date | null;
    link: string;
  }>;
};

export type GetOverlappingDirectIndicesQueryVariables = Exact<{
  clearingAccountId: Scalars["ID"]["input"];
  directIndexType: DirectIndexType;
}>;

export type GetOverlappingDirectIndicesQuery = {
  __typename?: "Query";
  getOverlappingDirectIndices: Array<DirectIndexType>;
};

export type PaginatedPendingInvitesForUserQueryVariables = Exact<{
  input: PendingInvitesForUserInput;
}>;

export type PaginatedPendingInvitesForUserQuery = {
  __typename?: "Query";
  paginatedPendingInvitesForUser: {
    __typename?: "PaginatedPendingInviteStatusEntry";
    nextPageToken?: string | null;
    entries: Array<{
      __typename?: "PendingInviteStatusEntry";
      refereeEmail: string;
      referredOn: Date;
    }>;
  };
};

export type GetStockLotTransferDetailQueryVariables = Exact<{
  input: StockLotTransferDetailInput;
}>;

export type GetStockLotTransferDetailQuery = {
  __typename?: "Query";
  getStockLotTransferDetail: {
    __typename?: "StockLotTransferDetailResponse";
    lotsBySymbol?: Array<{
      __typename?: "StockLotTransferDetailForSymbol";
      securityId: string;
      symbol: string;
      name: string;
      stockPriceUsed: Decimal;
      withinIndex: boolean;
      targetAllocation?: Decimal | null;
      canShowTargetAllocation: boolean;
      lots?: Array<{
        __typename?: "StockLotTransferLotDetail";
        id: string;
        quantity: Decimal;
        taxLotOpenBuyDate: DateOnly;
        longTermShortTermIndicator: string;
        washSalesIndicator: string;
        openBuyPrice: Decimal;
        recomputedRealizedGainLoss: Decimal;
      }> | null;
    }> | null;
    invalidStatesBySymbol?: Array<{
      __typename?: "StockLotTransferDetailInvalidStateBySymbol";
      symbol: string;
      name: string;
      reason: StockLotTransferDetailInvalidReason;
    }> | null;
  };
};

export type DemoGetStockLotTransferDetailQueryVariables = Exact<{
  input: StockLotTransferDetailInput;
}>;

export type DemoGetStockLotTransferDetailQuery = {
  __typename?: "Query";
  getStockLotTransferDetail: {
    __typename?: "StockLotTransferDetailResponse";
    lotsBySymbol?: Array<{
      __typename?: "StockLotTransferDetailForSymbol";
      securityId: string;
      symbol: string;
      name: string;
      stockPriceUsed: Decimal;
      withinIndex: boolean;
      targetAllocation?: Decimal | null;
      canShowTargetAllocation: boolean;
      lots?: Array<{
        __typename?: "StockLotTransferLotDetail";
        id: string;
        quantity: Decimal;
        taxLotOpenBuyDate: DateOnly;
        longTermShortTermIndicator: string;
        washSalesIndicator: string;
        openBuyPrice: Decimal;
        recomputedRealizedGainLoss: Decimal;
      }> | null;
    }> | null;
    invalidStatesBySymbol?: Array<{
      __typename?: "StockLotTransferDetailInvalidStateBySymbol";
      symbol: string;
      name: string;
      reason: StockLotTransferDetailInvalidReason;
    }> | null;
  };
};

export type GetSubAccountTransferValuesQueryVariables = Exact<{
  input: SubAccountTransferValueInput;
}>;

export type GetSubAccountTransferValuesQuery = {
  __typename?: "Query";
  getSubAccountTransferValues: Array<{
    __typename?: "SubAccountTransferValue";
    assetType: SubAccountAssetType;
    date: Date;
    value: Decimal;
  }>;
};

export type GetTreasuryYieldQueryVariables = Exact<{
  clearingAccountId: Scalars["ID"]["input"];
}>;

export type GetTreasuryYieldQuery = {
  __typename?: "Query";
  getTreasuryYield: {
    __typename?: "TreasuryYield";
    annualFeePercentage: Decimal;
    blendedSevenDayYieldPercentage?: Decimal | null;
    maxSevenDayYieldPercentage: Decimal;
    lastMonthNetYieldUSD?: Decimal | null;
    ytdNetYieldUSD?: Decimal | null;
    allTimeNetYieldUSD?: Decimal | null;
    supportedFundSymbols: Array<string>;
  };
};

export type HistoricalStockPricesQueryVariables = Exact<{
  input: HistoricalInput;
}>;

export type HistoricalStockPricesQuery = {
  __typename?: "Query";
  historicalPrices: Array<{
    __typename?: "StockData";
    symbol: string;
    history?: Array<{
      __typename?: "StockHistory";
      date: DateOnly;
      close: Decimal;
    }> | null;
  }>;
};

export type IndexMetadataQueryVariables = Exact<{
  types: Array<DirectIndexType> | DirectIndexType;
}>;

export type IndexMetadataQuery = {
  __typename?: "Query";
  getIndexMetadata?: Array<{
    __typename?: "IndexDetails";
    allowedSectorCustomizations?: number | null;
    allowedStockCustomizations: number;
    canCustomizeSectors: boolean;
    comparisonEtf: string;
    comparisonEtfExpenseRatio: Decimal;
    comparisonEtfName: string;
    feePercent: Decimal;
    indexDescription: string;
    indexName: string;
    indexType: DirectIndexType;
    investmentMinimum: Decimal;
    learnMoreLink: string;
    numberOfPositions: number;
    stockIndex: StockIndex;
    constituentData: {
      __typename?: "IndexExplorerConstituentData";
      asOfDate?: DateOnly | null;
      totalMarketCap: number;
      constituents: Array<{
        __typename?: "IndexConstituent";
        symbol: string;
        name: string;
        indexMarketCap: number;
        weight: number;
        fractionalSupported: boolean;
        canDisplayWeight: boolean;
      }>;
      sectorData: Array<{
        __typename?: "IndexSectorData";
        sectorName: string;
        sectorMarketCapPercent: number;
        gicsCode: GicsCode;
        sectorPositionsCount: number;
      }>;
      countryData: Array<{
        __typename?: "IndexCountryData";
        countryName: string;
        countryMarketCapPercent: number;
      }>;
    };
    historicalPerformanceData: {
      __typename?: "IndexHistoricalPerformanceData";
      returnForPeriod: Decimal;
      period: PerformanceDataPeriod;
      timeseriesData: Array<{
        __typename?: "IndexPerformancePoint";
        date: DateOnly;
        value: Decimal;
      }>;
    };
  }> | null;
};

export type IndexSectorMetadataQueryVariables = Exact<{
  types: Array<DirectIndexType> | DirectIndexType;
}>;

export type IndexSectorMetadataQuery = {
  __typename?: "Query";
  getIndexMetadata?: Array<{
    __typename?: "IndexDetails";
    constituentData: {
      __typename?: "IndexExplorerConstituentData";
      sectorData: Array<{
        __typename?: "IndexSectorData";
        gicsCode: GicsCode;
        sectorPositionsCount: number;
      }>;
    };
  }> | null;
};

export type GetIndexHistoricalPerformanceDataQueryVariables = Exact<{
  args: GetHistoricalIndexPerformanceDataInput;
}>;

export type GetIndexHistoricalPerformanceDataQuery = {
  __typename?: "Query";
  getHistoricalPerformanceData: {
    __typename?: "IndexHistoricalPerformanceData";
    period: PerformanceDataPeriod;
    returnForPeriod: Decimal;
    timeseriesData: Array<{
      __typename?: "IndexPerformancePoint";
      date: DateOnly;
      value: Decimal;
    }>;
  };
};

export type InterestsQueryVariables = Exact<{
  clearingAccountId: Scalars["ID"]["input"];
  yearlyStartDate: Scalars["Date"]["input"];
  yearlyEndDate: Scalars["Date"]["input"];
  startDate?: InputMaybe<Scalars["Date"]["input"]>;
  endDate?: InputMaybe<Scalars["Date"]["input"]>;
}>;

export type InterestsQuery = {
  __typename?: "Query";
  marginInterest?: {
    __typename?: "MarginInterestResponse";
    id: string;
    value: Decimal;
    effectiveDate: Date;
    note?: string | null;
  } | null;
  yearlyInterestCharges: {
    __typename?: "PaginatedMarginInterestChargesResponse";
    data?: Array<{
      __typename?: "MarginInterestCharge";
      amount: Decimal;
      eventTime: Date;
    }> | null;
  };
  currentBillingPeriodInterests?: Array<{
    __typename?: "DailyMarginInterestCharge";
    loan: Decimal;
    marginInterest: Decimal;
    marginInterestCharge: Decimal;
    date: DateOnly;
  }> | null;
  billingDates?: {
    __typename?: "BillingDatesResponse";
    startDate: DateOnly;
    endDate: DateOnly;
  } | null;
};

export type IntraAccountCashTransferQueryVariables = Exact<{
  clearingAccountId: Scalars["ID"]["input"];
  id: Scalars["ID"]["input"];
}>;

export type IntraAccountCashTransferQuery = {
  __typename?: "Query";
  clearingAccount?: {
    __typename?: "ClearingAccount";
    id: string;
    intraAccountCashTransfer?: {
      __typename?: "IntraAccountCashTransfer";
      id: string;
      subAccountId: string;
      amount: Decimal;
      from: IntraAccountCashTransferDirection;
      to: IntraAccountCashTransferDirection;
      eventTime: Date;
      source: AdjustmentSource;
    } | null;
  } | null;
};

export type DemoIntraAccountCashTransferQueryVariables = Exact<{
  clearingAccountId: Scalars["ID"]["input"];
  id: Scalars["ID"]["input"];
}>;

export type DemoIntraAccountCashTransferQuery = {
  __typename?: "Query";
  clearingAccount?: {
    __typename?: "ClearingAccount";
    id: string;
    intraAccountCashTransfer?: {
      __typename?: "IntraAccountCashTransfer";
      id: string;
      subAccountId: string;
      amount: Decimal;
      from: IntraAccountCashTransferDirection;
      to: IntraAccountCashTransferDirection;
      eventTime: Date;
      source: AdjustmentSource;
    } | null;
  } | null;
};

export type IntraAccountStockTransferQueryVariables = Exact<{
  clearingAccountId: Scalars["ID"]["input"];
  id: Scalars["ID"]["input"];
}>;

export type IntraAccountStockTransferQuery = {
  __typename?: "Query";
  clearingAccount?: {
    __typename?: "ClearingAccount";
    id: string;
    intraAccountStockTransfer?: {
      __typename?: "IntraAccountStockTransfer";
      adjustmentsGroupId: string;
      subAccountId: string;
      from: IntraAccountStockTransferDirection;
      to: IntraAccountStockTransferDirection;
      eventTime: Date;
      source: AdjustmentSource;
      stocks: Array<{
        __typename?: "IntraAccountStockTransferSecurity";
        symbol: string;
        quantity: Decimal;
      }>;
    } | null;
  } | null;
};

export type DemoIntraAccountStockTransferQueryVariables = Exact<{
  clearingAccountId: Scalars["ID"]["input"];
  id: Scalars["ID"]["input"];
}>;

export type DemoIntraAccountStockTransferQuery = {
  __typename?: "Query";
  clearingAccount?: {
    __typename?: "ClearingAccount";
    id: string;
    intraAccountStockTransfer?: {
      __typename?: "IntraAccountStockTransfer";
      adjustmentsGroupId: string;
      subAccountId: string;
      from: IntraAccountStockTransferDirection;
      to: IntraAccountStockTransferDirection;
      eventTime: Date;
      source: AdjustmentSource;
      stocks: Array<{
        __typename?: "IntraAccountStockTransferSecurity";
        symbol: string;
        quantity: Decimal;
      }>;
    } | null;
  } | null;
};

export type IntraDayAccountValuesQueryVariables = Exact<{
  input: ClearingAccountInput;
}>;

export type IntraDayAccountValuesQuery = {
  __typename?: "Query";
  intraDayAccountValues?: {
    __typename?: "IntraDayAccountValues";
    amountAvailableToWithdraw?: number | null;
  } | null;
};

export type InvestIndexDetailsQueryVariables = Exact<{
  args?: InputMaybe<GetIndexDataInput>;
}>;

export type InvestIndexDetailsQuery = {
  __typename?: "Query";
  getIndexMetadata?: Array<{
    __typename?: "IndexDetails";
    indexType: DirectIndexType;
    indexName: string;
    indexSubText: string;
    indexDisplayCategory: IndexDisplayCategory;
    numberOfPositions: number;
    ytdReturn: Decimal;
  }> | null;
};

export type InvestmentSearchQueryVariables = Exact<{
  query: Scalars["String"]["input"];
}>;

export type InvestmentSearchQuery = {
  __typename?: "Query";
  investmentSearch: {
    __typename?: "InvestmentSearchResult";
    directIndices: Array<{
      __typename?: "DirectIndexSearchResult";
      indexName: string;
      indexType: DirectIndexType;
    }>;
    securities: Array<{
      __typename?: "SecuritySearchResult";
      symbol: string;
      name: string;
    }>;
  };
};

export type KeyValueGetQueryVariables = Exact<{
  input: KeyValueGetInput;
}>;

export type KeyValueGetQuery = {
  __typename?: "Query";
  keyValueGet: Array<{
    __typename?: "KeyValuePair";
    id: string;
    key: string;
    namespace: string;
    value: object;
    expiresAt?: Date | null;
  }>;
};

export type LinkedBrokerageAccountsQueryVariables = Exact<{
  ids?: InputMaybe<Array<Scalars["ID"]["input"]> | Scalars["ID"]["input"]>;
  includePlaidPortfolio: Scalars["Boolean"]["input"];
}>;

export type LinkedBrokerageAccountsQuery = {
  __typename?: "Query";
  user?: {
    __typename?: "User";
    id: string;
    linkedBrokerageAccounts?: Array<{
      __typename?: "LinkedBrokerageAccount";
      id: string;
      plaidAccountId?: string | null;
      name: string;
      mask: string;
      institutionName: string;
      isPartialTransferDisabled: boolean;
      plaidAccount?: {
        __typename?: "PlaidAccount";
        id: string;
        plaidInstitutionId?: string | null;
        name: string;
        type: PlaidAccountType;
        subType: PlaidAccountSubType;
        officialName?: string | null;
        institutionName: string;
        logo?: string | null;
        mask?: string | null;
        verificationStatus?: string | null;
        wireRoutingNumber?: string | null;
        accountNumber?: string | null;
        hasMissingAccountNumber: boolean;
        status: PlaidAccountStatus;
        owners?: string | null;
        error?: PlaidAccountError | null;
        portfolio?: {
          __typename?: "PlaidAccountPortfolio";
          cashBalance: Decimal;
          totalBalance?: Decimal | null;
          error?: PlaidAccountError | null;
          holdings: Array<{
            __typename?: "PlaidAccountPortfolioHolding";
            securityId?: string | null;
            name: string;
            symbol: string;
            cusip?: string | null;
            quantity: number;
            costBasis: number;
            currency: string;
            closingPrice?: number | null;
            supported: boolean;
            plaidAccountId: string;
            security?: {
              __typename?: "Security";
              id: string;
              asOfDate?: Date | null;
              buyStatus: SecurityTradeStatus;
              sellStatus: SecurityTradeStatus;
              cusip?: string | null;
              isin?: string | null;
              name: string;
              symbol: string;
              type: SecurityType;
              subType: SecuritySubType;
              stockQuote?: {
                __typename?: "StockQuote";
                symbol: string;
                price: Decimal;
                name?: string | null;
                change?: Decimal | null;
                changesPercentage?: Decimal | null;
                open?: Decimal | null;
                previousClose?: Decimal | null;
                volume?: Decimal | null;
                eventTime?: Date | null;
                marginRequirement?: {
                  __typename?: "MarginRequirement";
                  symbol: string;
                  maintenanceRequirement: Decimal;
                } | null;
              } | null;
            } | null;
          }>;
        } | null;
      } | null;
      acatsTransfers?: Array<{
        __typename?: "AcatsTransfer";
        eventTime: Date;
        id: string;
        status: AcatsTransferStatus;
        statusHistory?: Array<{
          __typename?: "AcatsStatusHistory";
          eventTime: Date;
          status: AcatsTransferStatus;
        }> | null;
      }> | null;
    }> | null;
  } | null;
};

export type LiquidateDirectIndexPortfolioRequestQueryVariables = Exact<{
  clearingAccountId: Scalars["ID"]["input"];
  id: Scalars["ID"]["input"];
}>;

export type LiquidateDirectIndexPortfolioRequestQuery = {
  __typename?: "Query";
  getLiquidateDirectIndexPortfolioRequest?: {
    __typename?: "LiquidateDirectIndexPortfolioRequest";
    id: string;
    subAccountId: string;
    status: LiquidateDirectIndexPortfolioStatus;
    amount?: Decimal | null;
    isFullLiquidation?: boolean | null;
    eventTime: Date;
  } | null;
};

export type DemoLiquidateDirectIndexPortfolioRequestQueryVariables = Exact<{
  clearingAccountId: Scalars["ID"]["input"];
  id: Scalars["ID"]["input"];
}>;

export type DemoLiquidateDirectIndexPortfolioRequestQuery = {
  __typename?: "Query";
  getLiquidateDirectIndexPortfolioRequest?: {
    __typename?: "LiquidateDirectIndexPortfolioRequest";
    id: string;
    subAccountId: string;
    status: LiquidateDirectIndexPortfolioStatus;
    amount?: Decimal | null;
    isFullLiquidation?: boolean | null;
    eventTime: Date;
  } | null;
};

export type LoanQueryVariables = Exact<{
  clearingAccountId: Scalars["ID"]["input"];
  loanId: Scalars["ID"]["input"];
}>;

export type LoanQuery = {
  __typename?: "Query";
  getAccountLoan?: {
    __typename?: "LoanEvent";
    id: string;
    type: LoanType;
    amount: Decimal;
    eventTime: Date;
    acatsTransferId?: string | null;
  } | null;
};

export type MarginInterestChargeQueryVariables = Exact<{
  clearingAccountId: Scalars["ID"]["input"];
  id: Scalars["ID"]["input"];
}>;

export type MarginInterestChargeQuery = {
  __typename?: "Query";
  clearingAccount?: {
    __typename?: "ClearingAccount";
    id: string;
    marginInterestCharge?: {
      __typename?: "MarginInterestCharge";
      id: string;
      amount: Decimal;
      eventTime: Date;
      billingStartDate: Date;
      billingEndDate: Date;
      billedTo: MarginInterestFeeBilledTo;
    } | null;
  } | null;
};

export type MarketHoursQueryVariables = Exact<{ [key: string]: never }>;

export type MarketHoursQuery = {
  __typename?: "Query";
  marketHours: {
    __typename?: "MarketHours";
    isTheStockMarketOpen: boolean;
    previousMarketOpen: Date;
    previousMarketClose: Date;
    openingHour: Date;
    closingHour: Date;
  };
};

export type MfaQueryVariables = Exact<{ [key: string]: never }>;

export type MfaQuery = {
  __typename?: "Query";
  mfa: {
    __typename?: "UserMFA";
    id: string;
    phoneLast4?: string | null;
    userMfaStatus?: {
      __typename?: "UserMfaStatus";
      isActive: boolean;
      lastMfaChallengeTime?: Date | null;
    } | null;
    userMfaPreferences?: Array<{
      __typename?: "UserMfaPreference";
      id: string;
      type: MfaType;
    }> | null;
  };
};

export type OrderQueryVariables = Exact<{
  clearingAccountId: Scalars["ID"]["input"];
  id: Scalars["ID"]["input"];
}>;

export type OrderQuery = {
  __typename?: "Query";
  clearingAccount?: {
    __typename?: "ClearingAccount";
    id: string;
    order?: {
      __typename?: "Order";
      id: string;
      userId: string;
      clearingAccountId: string;
      subAccountId: string;
      side: OrderSide;
      orderType: OrderType;
      securityId: string;
      quantityType?: QuantityType | null;
      quantity: Decimal;
      maxSellQuantity?: Decimal | null;
      limitPrice?: Decimal | null;
      limitDuration?: Date | null;
      orderPrice?: Decimal | null;
      eventTime: Date;
      orderStatus: OrderStatus;
      source?: BatchOrderSource | null;
      opsInitiated: boolean;
      shortTermGain?: Decimal | null;
      shortTermLoss?: Decimal | null;
      longTermGain?: Decimal | null;
      longTermLoss?: Decimal | null;
      statusHistory?: Array<{
        __typename?: "OrderStatusHistory";
        id: string;
        orderId: string;
        orderStatus: OrderStatus;
        eventTime: Date;
        fillShares?: Decimal | null;
        fillAvgPrice?: Decimal | null;
        fillNotional?: Decimal | null;
      }> | null;
      orderFees: Array<{
        __typename?: "OrderFee";
        id: string;
        type: OrderFeeType;
        amount: Decimal;
      }>;
      security?: {
        __typename?: "Security";
        id: string;
        asOfDate?: Date | null;
        buyStatus: SecurityTradeStatus;
        sellStatus: SecurityTradeStatus;
        cusip?: string | null;
        isin?: string | null;
        name: string;
        symbol: string;
        type: SecurityType;
        subType: SecuritySubType;
      } | null;
      lotMatchingInstructions?: Array<{
        __typename?: "LotMatchingInstruction";
        taxLotEntryId: string;
        tradeDate?: DateOnly | null;
        quantity: Decimal;
        price?: Decimal | null;
        realizedGainLoss?: Decimal | null;
        cost?: Decimal | null;
        longTermShortTermIndicator?: LongTermShortTermIndicatorEnumGql | null;
      }> | null;
    } | null;
  } | null;
};

export type SubAccountPortfolioAggregatesQueryVariables = Exact<{
  input: SubAccountPortfolioAggregatesInput;
}>;

export type SubAccountPortfolioAggregatesQuery = {
  __typename?: "Query";
  subAccountPortfolioAggregates: Array<{
    __typename?: "SubAccountPortfolioAggregate";
    subAccount?: {
      __typename?: "SubAccount";
      id: string;
      type: SubAccountType;
      directIndexAccountMetadata?: {
        __typename?: "DirectIndexAccountMetadata";
        type: DirectIndexType;
      } | null;
    } | null;
    aggregates: {
      __typename?: "PortfolioAggregateValues";
      securityValue: Decimal;
      mmfHoldingValue: Decimal;
      cashValue: Decimal;
      dailyChange: Decimal;
      mmfDividendYTD: Decimal;
      loanValue?: Decimal | null;
    };
  }>;
};

export type DemoSubAccountPortfolioAggregatesQueryVariables = Exact<{
  input: SubAccountPortfolioAggregatesInput;
}>;

export type DemoSubAccountPortfolioAggregatesQuery = {
  __typename?: "Query";
  subAccountPortfolioAggregates: Array<{
    __typename?: "SubAccountPortfolioAggregate";
    subAccount?: {
      __typename?: "SubAccount";
      id: string;
      type: SubAccountType;
      directIndexAccountMetadata?: {
        __typename?: "DirectIndexAccountMetadata";
        type: DirectIndexType;
      } | null;
    } | null;
    aggregates: {
      __typename?: "PortfolioAggregateValues";
      securityValue: Decimal;
      mmfHoldingValue: Decimal;
      cashValue: Decimal;
      dailyChange: Decimal;
      mmfDividendYTD: Decimal;
      loanValue?: Decimal | null;
    };
  }>;
};

export type PaymentExecutionQueryVariables = Exact<{
  clearingAccountId: Scalars["ID"]["input"];
  id: Scalars["ID"]["input"];
}>;

export type PaymentExecutionQuery = {
  __typename?: "Query";
  clearingAccount?: {
    __typename?: "ClearingAccount";
    id: string;
    paymentExecution?: {
      __typename?: "PaymentExecution";
      id: string;
      status: PaymentExecutionStatus;
      amount: Decimal;
      failureReason?: PaymentFailureReason | null;
      eventTime: Date;
      paymentConfig?: {
        __typename?: "PaymentConfig";
        id: string;
        type: PaymentType;
        sourceType: PaymentSourceType;
        destinationType: PaymentDestinationType;
        destinationAccountId: string;
        amountType: PaymentAmountType;
        amount?: Decimal | null;
        dayOfMonth?: number | null;
        periodType?: PaymentPeriodType | null;
        startAt?: Date | null;
        cardName?: string | null;
        sourceSecurity?: {
          __typename?: "Security";
          id: string;
          asOfDate?: Date | null;
          buyStatus: SecurityTradeStatus;
          sellStatus: SecurityTradeStatus;
          cusip?: string | null;
          isin?: string | null;
          name: string;
          symbol: string;
          type: SecurityType;
          subType: SecuritySubType;
        } | null;
      } | null;
    } | null;
  } | null;
};

export type PlaidAccountsQueryVariables = Exact<{
  input?: InputMaybe<PlaidAccountsInput>;
}>;

export type PlaidAccountsQuery = {
  __typename?: "Query";
  user?: {
    __typename?: "User";
    id: string;
    plaidAccounts?: Array<{
      __typename?: "PlaidAccount";
      id: string;
      plaidInstitutionId?: string | null;
      name: string;
      type: PlaidAccountType;
      subType: PlaidAccountSubType;
      officialName?: string | null;
      institutionName: string;
      logo?: string | null;
      mask?: string | null;
      verificationStatus?: string | null;
      wireRoutingNumber?: string | null;
      accountNumber?: string | null;
      hasMissingAccountNumber: boolean;
      status: PlaidAccountStatus;
      owners?: string | null;
      error?: PlaidAccountError | null;
    }> | null;
  } | null;
};

export type PlaidDepositAccountBalanceQueryVariables = Exact<{
  plaidAccountId: Scalars["ID"]["input"];
}>;

export type PlaidDepositAccountBalanceQuery = {
  __typename?: "Query";
  plaidDepositAccountBalance?: Decimal | null;
};

export type PortfolioAvailableAssetsQueryVariables = Exact<{
  input: PortfolioInput;
}>;

export type PortfolioAvailableAssetsQuery = {
  __typename?: "Query";
  portfolio?: {
    __typename?: "Portfolio";
    clearingAccountId: string;
    availability: AvailabilityLevel;
    asOfDate?: Date | null;
    securities?: Array<{
      __typename?: "LiveSecurityHolding";
      clearingAccountId: string;
      securityId: string;
      subAccountId: string;
      subAccountType: SubAccountType;
      quantity: Decimal;
      avgCostBasis?: Decimal | null;
      dividendYtd?: Decimal | null;
      security?: {
        __typename?: "Security";
        id: string;
        asOfDate?: Date | null;
        buyStatus: SecurityTradeStatus;
        sellStatus: SecurityTradeStatus;
        cusip?: string | null;
        isin?: string | null;
        name: string;
        symbol: string;
        type: SecurityType;
        subType: SecuritySubType;
        stockQuote?: {
          __typename?: "StockQuote";
          symbol: string;
          price: Decimal;
          name?: string | null;
          change?: Decimal | null;
          changesPercentage?: Decimal | null;
          open?: Decimal | null;
          previousClose?: Decimal | null;
          volume?: Decimal | null;
          eventTime?: Date | null;
          marginRequirement?: {
            __typename?: "MarginRequirement";
            symbol: string;
            maintenanceRequirement: Decimal;
          } | null;
        } | null;
        mmfMetadata?: {
          __typename?: "MMFMetadata";
          name: string;
          cusip: string;
          symbol: string;
          nav: number;
          netAssets: number;
          secYield7Day: number;
          expenseRatio: number;
          inceptionDate: Date;
          minInvestment: number;
          ytdYield: number;
          dividendSchedule: MmfDividendScheduleFrequency;
          lastUpdated?: Date | null;
          link: string;
        } | null;
        symbolAlias?: Array<{
          __typename?: "SymbolAlias";
          symbol: string;
          context: Array<SecurityAliasContext | null>;
        } | null> | null;
      } | null;
    }> | null;
    cash?: Array<{
      __typename?: "LiveCashHolding";
      type: LiveCashHoldingType;
      subAccountId: string;
      subAccountType: SubAccountType;
      value: Decimal;
    }> | null;
  } | null;
};

export type PortfolioLastSettledQueryVariables = Exact<{
  input: PortfolioInput;
}>;

export type PortfolioLastSettledQuery = {
  __typename?: "Query";
  portfolio?: {
    __typename?: "Portfolio";
    clearingAccountId: string;
    availability: AvailabilityLevel;
    asOfDate?: Date | null;
    securities?: Array<{
      __typename?: "LiveSecurityHolding";
      clearingAccountId: string;
      securityId: string;
      subAccountId: string;
      subAccountType: SubAccountType;
      quantity: Decimal;
      avgCostBasis?: Decimal | null;
      dividendYtd?: Decimal | null;
      security?: {
        __typename?: "Security";
        id: string;
        asOfDate?: Date | null;
        buyStatus: SecurityTradeStatus;
        sellStatus: SecurityTradeStatus;
        cusip?: string | null;
        isin?: string | null;
        name: string;
        symbol: string;
        type: SecurityType;
        subType: SecuritySubType;
        stockQuote?: {
          __typename?: "StockQuote";
          symbol: string;
          price: Decimal;
          name?: string | null;
          change?: Decimal | null;
          changesPercentage?: Decimal | null;
          open?: Decimal | null;
          previousClose?: Decimal | null;
          volume?: Decimal | null;
          eventTime?: Date | null;
          marginRequirement?: {
            __typename?: "MarginRequirement";
            symbol: string;
            maintenanceRequirement: Decimal;
          } | null;
        } | null;
        mmfMetadata?: {
          __typename?: "MMFMetadata";
          name: string;
          cusip: string;
          symbol: string;
          nav: number;
          netAssets: number;
          secYield7Day: number;
          expenseRatio: number;
          inceptionDate: Date;
          minInvestment: number;
          ytdYield: number;
          dividendSchedule: MmfDividendScheduleFrequency;
          lastUpdated?: Date | null;
          link: string;
        } | null;
        symbolAlias?: Array<{
          __typename?: "SymbolAlias";
          symbol: string;
          context: Array<SecurityAliasContext | null>;
        } | null> | null;
      } | null;
    }> | null;
    cash?: Array<{
      __typename?: "LiveCashHolding";
      type: LiveCashHoldingType;
      subAccountId: string;
      subAccountType: SubAccountType;
      value: Decimal;
    }> | null;
  } | null;
};

export type PortfolioAsOfDateQueryVariables = Exact<{
  input: PortfolioInput;
}>;

export type PortfolioAsOfDateQuery = {
  __typename?: "Query";
  portfolio?: {
    __typename?: "Portfolio";
    clearingAccountId: string;
    availability: AvailabilityLevel;
    asOfDate?: Date | null;
    securities?: Array<{
      __typename?: "LiveSecurityHolding";
      clearingAccountId: string;
      securityId: string;
      subAccountId: string;
      subAccountType: SubAccountType;
      quantity: Decimal;
      avgCostBasis?: Decimal | null;
      dividendYtd?: Decimal | null;
      security?: {
        __typename?: "Security";
        id: string;
        asOfDate?: Date | null;
        buyStatus: SecurityTradeStatus;
        sellStatus: SecurityTradeStatus;
        cusip?: string | null;
        isin?: string | null;
        name: string;
        symbol: string;
        type: SecurityType;
        subType: SecuritySubType;
        stockQuote?: {
          __typename?: "StockQuote";
          symbol: string;
          price: Decimal;
          name?: string | null;
          change?: Decimal | null;
          changesPercentage?: Decimal | null;
          open?: Decimal | null;
          previousClose?: Decimal | null;
          volume?: Decimal | null;
          eventTime?: Date | null;
          marginRequirement?: {
            __typename?: "MarginRequirement";
            symbol: string;
            maintenanceRequirement: Decimal;
          } | null;
        } | null;
        mmfMetadata?: {
          __typename?: "MMFMetadata";
          name: string;
          cusip: string;
          symbol: string;
          nav: number;
          netAssets: number;
          secYield7Day: number;
          expenseRatio: number;
          inceptionDate: Date;
          minInvestment: number;
          ytdYield: number;
          dividendSchedule: MmfDividendScheduleFrequency;
          lastUpdated?: Date | null;
          link: string;
        } | null;
        symbolAlias?: Array<{
          __typename?: "SymbolAlias";
          symbol: string;
          context: Array<SecurityAliasContext | null>;
        } | null> | null;
      } | null;
    }> | null;
    cash?: Array<{
      __typename?: "LiveCashHolding";
      type: LiveCashHoldingType;
      subAccountId: string;
      subAccountType: SubAccountType;
      value: Decimal;
    }> | null;
  } | null;
};

export type DemoPortfolioQueryVariables = Exact<{
  input: PortfolioInput;
}>;

export type DemoPortfolioQuery = {
  __typename?: "Query";
  portfolio?: {
    __typename?: "Portfolio";
    clearingAccountId: string;
    availability: AvailabilityLevel;
    asOfDate?: Date | null;
    securities?: Array<{
      __typename?: "LiveSecurityHolding";
      clearingAccountId: string;
      securityId: string;
      subAccountId: string;
      subAccountType: SubAccountType;
      quantity: Decimal;
      avgCostBasis?: Decimal | null;
      dividendYtd?: Decimal | null;
      security?: {
        __typename?: "Security";
        id: string;
        asOfDate?: Date | null;
        buyStatus: SecurityTradeStatus;
        sellStatus: SecurityTradeStatus;
        cusip?: string | null;
        isin?: string | null;
        name: string;
        symbol: string;
        type: SecurityType;
        subType: SecuritySubType;
        stockQuote?: {
          __typename?: "StockQuote";
          symbol: string;
          price: Decimal;
          name?: string | null;
          change?: Decimal | null;
          changesPercentage?: Decimal | null;
          open?: Decimal | null;
          previousClose?: Decimal | null;
          volume?: Decimal | null;
          eventTime?: Date | null;
          marginRequirement?: {
            __typename?: "MarginRequirement";
            symbol: string;
            maintenanceRequirement: Decimal;
          } | null;
        } | null;
        mmfMetadata?: {
          __typename?: "MMFMetadata";
          name: string;
          cusip: string;
          symbol: string;
          nav: number;
          netAssets: number;
          secYield7Day: number;
          expenseRatio: number;
          inceptionDate: Date;
          minInvestment: number;
          ytdYield: number;
          dividendSchedule: MmfDividendScheduleFrequency;
          lastUpdated?: Date | null;
          link: string;
        } | null;
        symbolAlias?: Array<{
          __typename?: "SymbolAlias";
          symbol: string;
          context: Array<SecurityAliasContext | null>;
        } | null> | null;
      } | null;
    }> | null;
    cash?: Array<{
      __typename?: "LiveCashHolding";
      type: LiveCashHoldingType;
      subAccountId: string;
      subAccountType: SubAccountType;
      value: Decimal;
    }> | null;
  } | null;
};

export type PortfolioBorrowingPowerQueryVariables = Exact<{
  input: PortfolioValuesInput;
}>;

export type PortfolioBorrowingPowerQuery = {
  __typename?: "Query";
  portfolioBorrowingPower?: {
    __typename?: "PortfolioBorrowingPower";
    clearingAccountId: string;
    asOfDate: Date;
    borrowingPower: Decimal;
    frecBorrowingPower: Decimal;
  } | null;
};

export type PortfolioHistoryQueryVariables = Exact<{
  clearingAccountId: Scalars["ID"]["input"];
  startDate: Scalars["Date"]["input"];
  endDate?: InputMaybe<Scalars["Date"]["input"]>;
  subAccountId?: InputMaybe<Scalars["ID"]["input"]>;
}>;

export type PortfolioHistoryQuery = {
  __typename?: "Query";
  portfolioHistory: Array<{
    __typename?: "PortfolioSnapshot";
    date: Date;
    cashBalance: Decimal;
    loan: Decimal;
    fees: Decimal;
    portfolioSnapshotHoldingClosingValue: Decimal;
  }>;
};

export type PortfolioStartOfDayValuesQueryVariables = Exact<{
  input: PortfolioValuesInput;
}>;

export type PortfolioStartOfDayValuesQuery = {
  __typename?: "Query";
  portfolioStartOfDayValues?: {
    __typename?: "PortfolioStartOfDayValues";
    clearingAccountId: string;
    asOfDate: Date;
    marginExcessEquity: Decimal;
    availableToWithdraw: Decimal;
    SMABalance: Decimal;
    positionMarketValue: Decimal;
    totalEquity: Decimal;
    netLoan: Decimal;
    maintenanceRequirement: Decimal;
    marginRequirementWithConcentration: Decimal;
    impliedSMA: Decimal;
  } | null;
};

export type RealizedLotsSummaryQueryVariables = Exact<{
  clearingAccountId: Scalars["ID"]["input"];
  subAccountId?: InputMaybe<Scalars["ID"]["input"]>;
  securityId?: InputMaybe<Scalars["ID"]["input"]>;
  asOfDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  fromDate?: InputMaybe<Scalars["Date"]["input"]>;
  toDate?: InputMaybe<Scalars["Date"]["input"]>;
}>;

export type RealizedLotsSummaryQuery = {
  __typename?: "Query";
  clearingAccount?: {
    __typename?: "ClearingAccount";
    id: string;
    realizedLotsSummary?: {
      __typename?: "RealizedLotsSummary";
      shortTermGains: Decimal;
      longTermGains: Decimal;
      shortTermLosses: Decimal;
      longTermLosses: Decimal;
      shortNetProceeds: Decimal;
      longNetProceeds: Decimal;
      shortCostBasis: Decimal;
      longCostBasis: Decimal;
      shortWashSalesDisallowed: Decimal;
      longWashSalesDisallowed: Decimal;
    } | null;
  } | null;
};

export type RecentPaymentsQueryVariables = Exact<{
  clearingAccountId: Scalars["ID"]["input"];
}>;

export type RecentPaymentsQuery = {
  __typename?: "Query";
  clearingAccount?: {
    __typename?: "ClearingAccount";
    id: string;
    recentPayments?: Array<{
      __typename?: "PaymentExecution";
      id: string;
      status: PaymentExecutionStatus;
      amount: Decimal;
      failureReason?: PaymentFailureReason | null;
      eventTime: Date;
      paymentConfig?: {
        __typename?: "PaymentConfig";
        id: string;
        type: PaymentType;
        sourceType: PaymentSourceType;
        destinationType: PaymentDestinationType;
        destinationAccountId: string;
        amountType: PaymentAmountType;
        amount?: Decimal | null;
        dayOfMonth?: number | null;
        periodType?: PaymentPeriodType | null;
        startAt?: Date | null;
        cardName?: string | null;
        sourceSecurity?: {
          __typename?: "Security";
          id: string;
          asOfDate?: Date | null;
          buyStatus: SecurityTradeStatus;
          sellStatus: SecurityTradeStatus;
          cusip?: string | null;
          isin?: string | null;
          name: string;
          symbol: string;
          type: SecurityType;
          subType: SecuritySubType;
        } | null;
      } | null;
    }> | null;
  } | null;
};

export type RecentStockPricesQueryVariables = Exact<{
  input: BarInput;
}>;

export type RecentStockPricesQuery = {
  __typename?: "Query";
  getBars: {
    __typename?: "BarOutput";
    bars: Array<{
      __typename?: "BarOutputData";
      close: number;
      open: number;
      endTime: Date;
    }>;
  };
};

export type ReferralCodeInfoQueryVariables = Exact<{
  code: Scalars["String"]["input"];
}>;

export type ReferralCodeInfoQuery = {
  __typename?: "Query";
  referralCodeInfo?: {
    __typename?: "ReferralCodeInfoResponse";
    dealType: UserReferralDeal;
    invitedBy?: string | null;
  } | null;
};

export type PendingReferralsQueryVariables = Exact<{
  input: PendingInvitesForUserInput;
}>;

export type PendingReferralsQuery = {
  __typename?: "Query";
  paginatedPendingInvitesForUser: {
    __typename?: "PaginatedPendingInviteStatusEntry";
    nextPageToken?: string | null;
    entries: Array<{
      __typename?: "PendingInviteStatusEntry";
      refereeEmail: string;
      referredOn: Date;
    }>;
  };
};

export type CompletedReferralsQueryVariables = Exact<{
  input: CompletedInvitesForUserInput;
}>;

export type CompletedReferralsQuery = {
  __typename?: "Query";
  paginatedCompletedInvitesForUser: {
    __typename?: "PaginatedCompletedInviteStatusEntry";
    nextPageToken?: string | null;
    entries: Array<{
      __typename?: "CompletedInviteStatusEntry";
      redactedRefereeFullName: string;
      eventTime: Date;
      userReferralDeal?: UserReferralDeal | null;
      rewards: Array<{
        __typename?: "ReferralRewardDetails";
        grantedOn?: Date | null;
        rewardReceived: string;
        rewardReceivedAdditionalInfo?: string | null;
        kind: RewardKind;
        referralRewardType:
          | { __typename?: "CashRewardType"; amount: Decimal }
          | { __typename?: "TreasuryRewardType"; basisPoints: Decimal };
      }>;
    }>;
  };
};

export type RewardsQueryVariables = Exact<{
  clearingAccountId: Scalars["ID"]["input"];
}>;

export type RewardsQuery = {
  __typename?: "Query";
  clearingAccount?: {
    __typename?: "ClearingAccount";
    id: string;
    scheduledRewards?: Array<{
      __typename?: "ScheduledReward";
      id: string;
      subAccountId: string;
      promotionKey: string;
      promotionName: string;
      promotionTerms: string;
      creditDueDate: Date;
      creditAmount: Decimal;
      creditId?: string | null;
      createdAt: Date;
      updatedAt: Date;
    }> | null;
  } | null;
};

export type RunRuleEngineQueryVariables = Exact<{
  input: FactInput;
  ruleGroup: RuleGroup;
}>;

export type RunRuleEngineQuery = {
  __typename?: "Query";
  runRuleEngine?: Array<{
    __typename?: "RuleEngineResult";
    name: string;
    description: string;
    actionContext: ActionContext;
    allowAction: boolean;
  }> | null;
};

export type SecuritiesBySymbolQueryVariables = Exact<{
  symbols: Array<Scalars["String"]["input"]> | Scalars["String"]["input"];
}>;

export type SecuritiesBySymbolQuery = {
  __typename?: "Query";
  securitiesBySymbol?: Array<{
    __typename?: "Security";
    id: string;
    asOfDate?: Date | null;
    buyStatus: SecurityTradeStatus;
    sellStatus: SecurityTradeStatus;
    cusip?: string | null;
    isin?: string | null;
    name: string;
    symbol: string;
    type: SecurityType;
    subType: SecuritySubType;
    stockQuote?: {
      __typename?: "StockQuote";
      symbol: string;
      price: Decimal;
      name?: string | null;
      change?: Decimal | null;
      changesPercentage?: Decimal | null;
      open?: Decimal | null;
      previousClose?: Decimal | null;
      volume?: Decimal | null;
      eventTime?: Date | null;
      marginRequirement?: {
        __typename?: "MarginRequirement";
        symbol: string;
        maintenanceRequirement: Decimal;
      } | null;
    } | null;
    mmfMetadata?: {
      __typename?: "MMFMetadata";
      name: string;
      cusip: string;
      symbol: string;
      nav: number;
      netAssets: number;
      secYield7Day: number;
      expenseRatio: number;
      inceptionDate: Date;
      minInvestment: number;
      ytdYield: number;
      dividendSchedule: MmfDividendScheduleFrequency;
      lastUpdated?: Date | null;
      link: string;
    } | null;
    symbolAlias?: Array<{
      __typename?: "SymbolAlias";
      symbol: string;
      context: Array<SecurityAliasContext | null>;
    } | null> | null;
  }> | null;
};

export type SecurityBySymbolQueryVariables = Exact<{
  symbol: Scalars["String"]["input"];
}>;

export type SecurityBySymbolQuery = {
  __typename?: "Query";
  securityBySymbol?: {
    __typename?: "Security";
    id: string;
    asOfDate?: Date | null;
    buyStatus: SecurityTradeStatus;
    sellStatus: SecurityTradeStatus;
    cusip?: string | null;
    isin?: string | null;
    name: string;
    symbol: string;
    type: SecurityType;
    subType: SecuritySubType;
    stockQuote?: {
      __typename?: "StockQuote";
      symbol: string;
      price: Decimal;
      name?: string | null;
      change?: Decimal | null;
      changesPercentage?: Decimal | null;
      open?: Decimal | null;
      previousClose?: Decimal | null;
      volume?: Decimal | null;
      eventTime?: Date | null;
      marginRequirement?: {
        __typename?: "MarginRequirement";
        symbol: string;
        maintenanceRequirement: Decimal;
      } | null;
    } | null;
    mmfMetadata?: {
      __typename?: "MMFMetadata";
      name: string;
      cusip: string;
      symbol: string;
      nav: number;
      netAssets: number;
      secYield7Day: number;
      expenseRatio: number;
      inceptionDate: Date;
      minInvestment: number;
      ytdYield: number;
      dividendSchedule: MmfDividendScheduleFrequency;
      lastUpdated?: Date | null;
      link: string;
    } | null;
    symbolAlias?: Array<{
      __typename?: "SymbolAlias";
      symbol: string;
      context: Array<SecurityAliasContext | null>;
    } | null> | null;
  } | null;
};

export type SelfManagedTaxLossConfigQueryVariables = Exact<{
  clearingAccountId: Scalars["ID"]["input"];
}>;

export type SelfManagedTaxLossConfigQuery = {
  __typename?: "Query";
  clearingAccount?: {
    __typename?: "ClearingAccount";
    id: string;
    selfManagedTaxLossConfig?: {
      __typename?: "SelfManagedTaxLossConfig";
      id: string;
      clearingAccountId: string;
      sweepToSubAccountId: string;
      sweepAll: boolean;
      securities: Array<string>;
    } | null;
  } | null;
};

export type DemoSelfManagedTaxLossConfigQueryVariables = Exact<{
  clearingAccountId: Scalars["ID"]["input"];
}>;

export type DemoSelfManagedTaxLossConfigQuery = {
  __typename?: "Query";
  clearingAccount?: {
    __typename?: "ClearingAccount";
    id: string;
    selfManagedTaxLossConfig?: {
      __typename?: "SelfManagedTaxLossConfig";
      id: string;
      clearingAccountId: string;
      sweepToSubAccountId: string;
      sweepAll: boolean;
      securities: Array<string>;
    } | null;
  } | null;
};

export type SimpleActivityQueryVariables = Exact<{
  clearingAccountId: Scalars["ID"]["input"];
  size: Scalars["Int"]["input"];
  nextPageToken?: InputMaybe<Scalars["String"]["input"]>;
  status: ActivityStatus;
  filter?: InputMaybe<Array<ActivityFilterType> | ActivityFilterType>;
  subAccountId?: InputMaybe<Scalars["ID"]["input"]>;
  securityId?: InputMaybe<Scalars["ID"]["input"]>;
}>;

export type SimpleActivityQuery = {
  __typename?: "Query";
  clearingAccount?: {
    __typename?: "ClearingAccount";
    id: string;
    simpleActivity?: {
      __typename?: "PaginatedSimpleActivity";
      nextPageToken?: string | null;
      data: Array<{
        __typename?: "SimpleActivity";
        id: string;
        activityType: ActivityType;
        title: string;
        numOfShares?: Decimal | null;
        status: StatusEnum;
        statusText?: string | null;
        date?: Date | null;
        value?: Decimal | null;
        description?: string | null;
        automated?: boolean | null;
      }>;
    } | null;
  } | null;
};

export type DemoSimpleActivityQueryVariables = Exact<{
  clearingAccountId: Scalars["ID"]["input"];
  size: Scalars["Int"]["input"];
  nextPageToken?: InputMaybe<Scalars["String"]["input"]>;
  status: ActivityStatus;
  filter?: InputMaybe<Array<ActivityFilterType> | ActivityFilterType>;
  subAccountId?: InputMaybe<Scalars["ID"]["input"]>;
  securityId?: InputMaybe<Scalars["ID"]["input"]>;
}>;

export type DemoSimpleActivityQuery = {
  __typename?: "Query";
  clearingAccount?: {
    __typename?: "ClearingAccount";
    id: string;
    simpleActivity?: {
      __typename?: "PaginatedSimpleActivity";
      nextPageToken?: string | null;
      data: Array<{
        __typename?: "SimpleActivity";
        id: string;
        activityType: ActivityType;
        title: string;
        numOfShares?: Decimal | null;
        status: StatusEnum;
        statusText?: string | null;
        date?: Date | null;
        value?: Decimal | null;
        description?: string | null;
        automated?: boolean | null;
      }>;
    } | null;
  } | null;
};

export type SimpleActivityByIdQueryVariables = Exact<{
  clearingAccountId: Scalars["ID"]["input"];
  id: Scalars["ID"]["input"];
  type: ActivityType;
}>;

export type SimpleActivityByIdQuery = {
  __typename?: "Query";
  clearingAccount?: {
    __typename?: "ClearingAccount";
    id: string;
    simpleActivityById?: {
      __typename?: "SimpleActivity";
      id: string;
      activityType: ActivityType;
      title: string;
      numOfShares?: Decimal | null;
      status: StatusEnum;
      statusText?: string | null;
      date?: Date | null;
      value?: Decimal | null;
      description?: string | null;
      automated?: boolean | null;
    } | null;
  } | null;
};

export type SparklineQueryVariables = Exact<{
  input: SparklineInput;
}>;

export type SparklineQuery = {
  __typename?: "Query";
  sparkline: {
    __typename?: "SparklineOutput";
    prevClose?: number | null;
    prices: Array<number>;
  };
};

export type StockAdjustmentQueryVariables = Exact<{
  clearingAccountId: Scalars["ID"]["input"];
  id: Scalars["ID"]["input"];
}>;

export type StockAdjustmentQuery = {
  __typename?: "Query";
  clearingAccount?: {
    __typename?: "ClearingAccount";
    id: string;
    stockAdjustment?: {
      __typename?: "StockAdjustmentDetails";
      id: string;
      clearingAccountId: string;
      subAccountId: string;
      securityId: string;
      adjustmentAmount?: Decimal | null;
      adjustmentFactor: Decimal;
      adjustmentType: AdjustmentType;
      effectiveTime: Date;
      eventTime: Date;
      securityPrice?: Decimal | null;
      security?: {
        __typename?: "Security";
        id: string;
        asOfDate?: Date | null;
        buyStatus: SecurityTradeStatus;
        sellStatus: SecurityTradeStatus;
        cusip?: string | null;
        isin?: string | null;
        name: string;
        symbol: string;
        type: SecurityType;
        subType: SecuritySubType;
      } | null;
      treasuryFees?: Array<{
        __typename?: "TreasuryFee";
        id: string;
        amount: Decimal;
        status: TreasuryFeeStatus;
      }> | null;
    } | null;
  } | null;
};

export type SuggestCollateralQueryVariables = Exact<{
  input: SuggestCollateralInput;
}>;

export type SuggestCollateralQuery = {
  __typename?: "Query";
  suggestCollateral: Array<{
    __typename?: "ExternalAccountHoldings";
    id: string;
    holdings: Array<{
      __typename?: "Holding";
      symbol: string;
      quantity: Decimal;
      price: Decimal;
      marginRequirement: Decimal;
    }>;
  }>;
};

export type SymbolSearchQueryVariables = Exact<{
  symbol: Scalars["String"]["input"];
  includeSecurity?: Scalars["Boolean"]["input"];
  options?: InputMaybe<SymbolSearchInput>;
}>;

export type SymbolSearchQuery = {
  __typename?: "Query";
  symbolSearch: Array<{
    __typename?: "StockInfo";
    symbol: string;
    name?: string | null;
    exchangeShortName?: string | null;
    security?: {
      __typename?: "Security";
      id: string;
      asOfDate?: Date | null;
      buyStatus: SecurityTradeStatus;
      sellStatus: SecurityTradeStatus;
      cusip?: string | null;
      isin?: string | null;
      name: string;
      symbol: string;
      type: SecurityType;
      subType: SecuritySubType;
      stockQuote?: {
        __typename?: "StockQuote";
        symbol: string;
        price: Decimal;
        name?: string | null;
        change?: Decimal | null;
        changesPercentage?: Decimal | null;
        open?: Decimal | null;
        previousClose?: Decimal | null;
        volume?: Decimal | null;
        eventTime?: Date | null;
        marginRequirement?: {
          __typename?: "MarginRequirement";
          symbol: string;
          maintenanceRequirement: Decimal;
        } | null;
      } | null;
      mmfMetadata?: {
        __typename?: "MMFMetadata";
        name: string;
        cusip: string;
        symbol: string;
        nav: number;
        netAssets: number;
        secYield7Day: number;
        expenseRatio: number;
        inceptionDate: Date;
        minInvestment: number;
        ytdYield: number;
        dividendSchedule: MmfDividendScheduleFrequency;
        lastUpdated?: Date | null;
        link: string;
      } | null;
      symbolAlias?: Array<{
        __typename?: "SymbolAlias";
        symbol: string;
        context: Array<SecurityAliasContext | null>;
      } | null> | null;
    } | null;
  }>;
};

export type TaxLotInfoQueryVariables = Exact<{
  clearingAccountId: Scalars["ID"]["input"];
  subAccountId?: InputMaybe<Scalars["ID"]["input"]>;
  realizedIndicator?: InputMaybe<RealizedIndicatorEnumGql>;
  asOfDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  fromDate?: InputMaybe<Scalars["Date"]["input"]>;
  toDate?: InputMaybe<Scalars["Date"]["input"]>;
}>;

export type TaxLotInfoQuery = {
  __typename?: "Query";
  clearingAccount?: {
    __typename?: "ClearingAccount";
    id: string;
    taxLotEntries?: Array<{
      __typename?: "TaxLotEntry";
      id?: string | null;
      securityId: string;
      subAccountId: string;
      symbol: string;
      eventTime: Date;
      longTermShortTermIndicator: string;
      netProceed: Decimal;
      quantity: Decimal;
      realizedIndicator: string;
      taxLotOpenBuyDate?: DateOnly | null;
      taxLotCloseSellDate?: DateOnly | null;
      realizedGainLoss: Decimal;
      washSalesIndicator: string;
      washSalesDisallowed: Decimal;
      costPerShare: Decimal;
      totalCost: Decimal;
    }> | null;
  } | null;
};

export type TrustEntityQueryVariables = Exact<{ [key: string]: never }>;

export type TrustEntityQuery = {
  __typename?: "Query";
  trustEntity?: {
    __typename?: "TrustEntity";
    id: string;
    primaryTrusteeId: string;
    entityDetails: {
      __typename?: "TrustEntityDetails";
      id: string;
      name: string;
      taxId: string;
      taxIdType?: TaxIdType | null;
      dateOfCreation: DateOnly;
      revocability?: TrustRevocability | null;
      state: string;
      trustAgreementAllowsMarginsAndShortPositions?: boolean | null;
      address?: {
        __typename?: "GenericAddress";
        id: string;
        addressType: AddressType;
        addressLine1: string;
        addressLine2?: string | null;
        city: string;
        state: string;
        zipCode: string;
        country: string;
      } | null;
    };
    additionalTrustees: Array<{
      __typename?: "Trustee";
      id: string;
      name: string;
      familyName?: string | null;
      givenName?: string | null;
      dateOfBirth: DateOnly;
      idType?: TrusteeIdType | null;
      idIssuingStateOrCountry?: string | null;
      idExpirationDate?: DateOnly | null;
      governmentIdNumber?: string | null;
      disclosure?: {
        __typename?: "IndividualDisclosure";
        id: string;
        affiliatedExchangeOrFINRA: boolean;
        firmName?: string | null;
        controlPerson: boolean;
        companySymbols?: string | null;
        politicallyExposed: boolean;
        politicalOrganization?: string | null;
        politicalTitle?: string | null;
        immediateFamilyMembers?: Array<string> | null;
      } | null;
      address: {
        __typename?: "GenericAddress";
        id: string;
        addressType: AddressType;
        addressLine1: string;
        addressLine2?: string | null;
        city: string;
        state: string;
        zipCode: string;
        country: string;
      };
    }>;
    address?: {
      __typename?: "GenericAddress";
      id: string;
      addressType: AddressType;
      addressLine1: string;
      addressLine2?: string | null;
      city: string;
      state: string;
      zipCode: string;
      country: string;
    } | null;
  } | null;
};

export type UserQueryVariables = Exact<{ [key: string]: never }>;

export type UserQuery = {
  __typename?: "Query";
  user?: {
    __typename?: "User";
    id: string;
    isEmployee: boolean;
    inviteCodes?: Array<string> | null;
    clearingAccounts?: Array<{
      __typename?: "ClearingAccount";
      id: string;
      accountType?: AccountType | null;
      categorizationType?: CategorizationType | null;
      externalClearingAccountId: string;
      eventTime?: Date | null;
      isTestAccount?: boolean | null;
      isPatternDayTrader?: boolean | null;
      isClientAccount?: boolean | null;
      primarySubAccountId: string;
      subAccounts: Array<{
        __typename?: "SubAccount";
        id: string;
        type: SubAccountType;
        eventTime: Date;
        pricing?: Array<{
          __typename?: "SubAccountPricing";
          id: string;
          amount: Decimal;
          unit: SubAccountPricingUnit;
          validFrom?: Date | null;
          validTo?: Date | null;
        }> | null;
        directIndexAccountMetadata?: {
          __typename?: "DirectIndexAccountMetadata";
          id: string;
          type: DirectIndexType;
          etfSymbol?: string | null;
          signedAt?: Date | null;
          lhfOverride?: Decimal | null;
          subAccountId: string;
          status?: DirectIndexStatus | null;
          customization?: {
            __typename?: "DirectIndexCustomization";
            id: string;
            removeGICSSectorIds: Array<GicsCode>;
            addSecurityIds: Array<{
              __typename?: "IndexCustomizationSecurity";
              securityId: string;
              security?: {
                __typename?: "Security";
                id: string;
                asOfDate?: Date | null;
                buyStatus: SecurityTradeStatus;
                sellStatus: SecurityTradeStatus;
                cusip?: string | null;
                isin?: string | null;
                name: string;
                symbol: string;
                type: SecurityType;
                subType: SecuritySubType;
              } | null;
            }>;
            removeSecurityIds: Array<{
              __typename?: "IndexCustomizationSecurity";
              securityId: string;
              security?: {
                __typename?: "Security";
                id: string;
                asOfDate?: Date | null;
                buyStatus: SecurityTradeStatus;
                sellStatus: SecurityTradeStatus;
                cusip?: string | null;
                isin?: string | null;
                name: string;
                symbol: string;
                type: SecurityType;
                subType: SecuritySubType;
              } | null;
            }>;
          } | null;
        } | null;
      }>;
      marginCallData?: {
        __typename?: "MarginCallData";
        id: string;
        dueDate: Date;
        eventTime: Date;
        statuses: Array<{
          __typename?: "MarginCallDataStatus";
          state: MarginCallState;
          amount: Decimal;
          eventTime: Date;
        }>;
      } | null;
      actionContexts?: Array<{
        __typename?: "ActionContextStatus";
        actionContext: ActionContext;
        isAllowed: boolean;
      }> | null;
      restrictions?: Array<{
        __typename?: "ClearingAccountRestriction";
        restrictionType: ClearingAccountRestrictionType;
        active: boolean;
      }> | null;
      entity?:
        | {
            __typename?: "BusinessEntityInfo";
            businessEntityDetails: {
              __typename?: "BusinessEntityDetails";
              id: string;
              name: string;
              phoneNumber: string;
              industry: BusinessEntityIndustry;
              address?: Array<{
                __typename?: "GenericAddress";
                addressType: AddressType;
                addressLine1: string;
                addressLine2?: string | null;
                city: string;
                state: string;
                zipCode: string;
                country: string;
              }> | null;
            };
            entityAuthorizedUser: {
              __typename?: "BusinessEntityAuthorizedUser";
              userTitle: string;
              user?: {
                __typename?: "User";
                userPersonalDetails?: {
                  __typename?: "UserPersonalDetails";
                  fullName: string;
                } | null;
                userIdentityDetails?: {
                  __typename?: "UserIdentityDetails";
                  citizenshipCountry: string;
                  dateOfBirth: DateOnly;
                  ssn: string;
                } | null;
                userContactPhone?: {
                  __typename?: "UserContactPhone";
                  phoneNumber: string;
                } | null;
                userContactEmail?: {
                  __typename?: "UserContactEmail";
                  email: string;
                } | null;
              } | null;
            };
            entityIndividuals: Array<{
              __typename?: "BusinessEntityIndividual";
              familyName: string;
              givenName: string;
              dateOfBirth: DateOnly;
              individualType: BusinessEntityIndividualType;
              address?: Array<{
                __typename?: "GenericAddress";
                addressType: AddressType;
                addressLine1: string;
                addressLine2?: string | null;
                city: string;
                state: string;
                zipCode: string;
                country: string;
              }> | null;
            }>;
          }
        | {
            __typename?: "TrustEntity";
            trustEntityDetails: {
              __typename?: "TrustEntityDetails";
              id: string;
              name: string;
              taxId: string;
              taxIdType?: TaxIdType | null;
              dateOfCreation: DateOnly;
              revocability?: TrustRevocability | null;
              state: string;
              trustAgreementAllowsMarginsAndShortPositions?: boolean | null;
              address?: {
                __typename?: "GenericAddress";
                id: string;
                addressType: AddressType;
                addressLine1: string;
                addressLine2?: string | null;
                city: string;
                state: string;
                zipCode: string;
                country: string;
              } | null;
            };
            primaryTrustee?: {
              __typename?: "User";
              userContactEmail?: {
                __typename?: "UserContactEmail";
                email: string;
                emailType: EmailType;
              } | null;
              userEmploymentDetails?: Array<{
                __typename?: "UserEmploymentDetails";
                employerName?: string | null;
                occupation?: string | null;
              }> | null;
              userIdentityDetails?: {
                __typename?: "UserIdentityDetails";
                dateOfBirth: DateOnly;
                citizenshipCountry: string;
              } | null;
              userPersonalDetails?: {
                __typename?: "UserPersonalDetails";
                fullName: string;
              } | null;
              userContactPhone?: {
                __typename?: "UserContactPhone";
                phoneNumber: string;
              } | null;
              userAddresses?: Array<{
                __typename?: "GenericAddress";
                addressType: AddressType;
                addressLine1: string;
                addressLine2?: string | null;
                city: string;
                state: string;
                zipCode: string;
                country: string;
              }> | null;
            } | null;
            additionalTrustees: Array<{
              __typename?: "Trustee";
              id: string;
              name: string;
              familyName?: string | null;
              givenName?: string | null;
              dateOfBirth: DateOnly;
              idType?: TrusteeIdType | null;
              idIssuingStateOrCountry?: string | null;
              idExpirationDate?: DateOnly | null;
              governmentIdNumber?: string | null;
              disclosure?: {
                __typename?: "IndividualDisclosure";
                id: string;
                affiliatedExchangeOrFINRA: boolean;
                firmName?: string | null;
                controlPerson: boolean;
                companySymbols?: string | null;
                politicallyExposed: boolean;
                politicalOrganization?: string | null;
                politicalTitle?: string | null;
                immediateFamilyMembers?: Array<string> | null;
              } | null;
              address: {
                __typename?: "GenericAddress";
                id: string;
                addressType: AddressType;
                addressLine1: string;
                addressLine2?: string | null;
                city: string;
                state: string;
                zipCode: string;
                country: string;
              };
            }>;
          }
        | null;
      treasury?: {
        __typename?: "TreasuryConfig";
        subAccountId: string;
        eventTime: Date;
      } | null;
      dividendPreference?: Array<{
        __typename?: "DividendPreference";
        id: string;
        clearingAccountId: string;
        subAccountType: SubAccountType;
        preferenceType: DividendPreferenceType;
      }> | null;
    }> | null;
    userContactEmail?: {
      __typename?: "UserContactEmail";
      id: string;
      email: string;
      emailType: EmailType;
      emailHash?: string | null;
      verified: boolean;
    } | null;
    userContactPhone?: {
      __typename?: "UserContactPhone";
      id: string;
      phoneNumber: string;
      phoneNumberVerified: boolean;
      phoneNumberType: PhoneNumberType;
    } | null;
    userPersonalDetails?: {
      __typename?: "UserPersonalDetails";
      id: string;
      firstName: string;
      lastName: string;
      middleName?: string | null;
      fullName: string;
    } | null;
    userIdentityDetails?: {
      __typename?: "UserIdentityDetails";
      id: string;
      ssn: string;
      dateOfBirth: DateOnly;
      citizenshipCountry: string;
      permanentResident?: boolean | null;
      visaStatus?: VisaType | null;
      visaExpiration?: DateOnly | null;
      maritalStatus?: MaritalStatus | null;
      numberOfDependents?: number | null;
      idType?: TrusteeIdType | null;
      idIssuingStateOrCountry?: string | null;
      idExpirationDate?: DateOnly | null;
      governmentIdNumber?: string | null;
    } | null;
    userAddresses?: Array<{
      __typename?: "GenericAddress";
      id: string;
      addressType: AddressType;
      addressLine1: string;
      addressLine2?: string | null;
      city: string;
      state: string;
      zipCode: string;
      country: string;
    }> | null;
    userInvestmentProfile?: {
      __typename?: "UserInvestmentProfile";
      id: string;
      netWorthMin: number;
      netWorthMax: number;
      annualIncomeMin: number;
      annualIncomeMax: number;
      investmentExperience: InvestmentExperience;
      riskTolerance: RiskTolerance;
      liquidityTimeHorizon: TimeHorizon;
      liquidityNeeds: LiquidityNeeds;
    } | null;
    userServiceProfile?: {
      __typename?: "UserServiceProfile";
      id: string;
      sweepInstruction: SweepInstruction;
      dividendReinvestment: DividendReinvestment;
    } | null;
    userTaxRates?: {
      __typename?: "UserTaxRates";
      id: string;
      taxFilingStatus?: TaxFilingStatus | null;
      federalIncomeTaxRate: Decimal;
      stateIncomeTaxRate?: Decimal | null;
      federalCapitalGainsTaxRate: Decimal;
      stateCapitalGainsTaxRate: Decimal;
    } | null;
    userEmploymentDetails?: Array<{
      __typename?: "UserEmploymentDetails";
      id: string;
      employerName?: string | null;
      employmentStatus: EmploymentStatus;
      yearsEmployed?: number | null;
      occupation?: string | null;
      addressLine1?: string | null;
      addressLine2?: string | null;
      city?: string | null;
      state?: string | null;
      zipCode?: string | null;
      country?: string | null;
    }> | null;
    userTrustedContact?: {
      __typename?: "UserTrustedContact";
      id: string;
      relationship: string;
      firstName: string;
      lastName: string;
      middleName?: string | null;
      email: string;
      phoneNumber: string;
      phoneNumberType: PhoneNumberType;
      addressLine1?: string | null;
      addressLine2?: string | null;
      city?: string | null;
      state?: string | null;
      zipCode?: string | null;
      country?: string | null;
    } | null;
    userDisclosures?: {
      __typename?: "UserDisclosures";
      id: string;
      affiliatedExchangeOrFINRA: boolean;
      affiliatedExchangeOrFINRAEntityName?: string | null;
      firmName?: string | null;
      controlPerson: boolean;
      companySymbols?: string | null;
      politicallyExposed: boolean;
      politicalOrganization?: string | null;
      immediateFamily?: string | null;
    } | null;
    userProgressFlags?: Array<{
      __typename?: "UserProgressFlag";
      status?: ProgressFlagStatus | null;
      category?: string | null;
    } | null> | null;
    entityAccountRequestStatus?: {
      __typename?: "EntityAccountRequestStatus";
      state: EntityAccountRequestStatusState;
      asOfDate: Date;
    } | null;
    uploadedDocuments?: Array<{
      __typename?: "UploadedDocument";
      id: string;
      name: string;
      originalName: string;
      description?: string | null;
      type: UploadDocumentType;
      location: string;
    }> | null;
    referralPromo?: {
      __typename?: "UserReferralPromotion";
      code: string;
      dealType: UserReferralDeal;
    } | null;
  } | null;
};

export type UserInvestorDocumentsQueryVariables = Exact<{
  clearingAccountId: Scalars["ID"]["input"];
  category: InvestorDocumentCategory;
  nextPageToken?: InputMaybe<Scalars["String"]["input"]>;
  startDate?: InputMaybe<Scalars["String"]["input"]>;
}>;

export type UserInvestorDocumentsQuery = {
  __typename?: "Query";
  clearingAccount?: {
    __typename?: "ClearingAccount";
    id: string;
    userInvestorDocuments?: {
      __typename?: "GetUserInvestorDocumentsResponse";
      nextPageToken?: string | null;
      documents: Array<{
        __typename?: "UserInvestorDocument";
        category: string;
        name: string;
        date: string;
        url: string;
        documentType: string;
        id: string;
      }>;
    } | null;
  } | null;
};

export type UserInviteCodeApplicationQueryVariables = Exact<{
  [key: string]: never;
}>;

export type UserInviteCodeApplicationQuery = {
  __typename?: "Query";
  user?: {
    __typename?: "User";
    id: string;
    inviteCodeApplication?: {
      __typename?: "InviteCodeApplication";
      id: string;
      code: string;
      appliedOn: Date;
      deal?: UserReferralDeal | null;
      rewards: Array<{
        __typename?: "ReferralRewardDetails";
        grantedOn?: Date | null;
        rewardReceived: string;
        rewardReceivedAdditionalInfo?: string | null;
        kind: RewardKind;
        referralRewardType:
          | { __typename?: "CashRewardType"; amount: Decimal }
          | { __typename?: "TreasuryRewardType"; basisPoints: Decimal };
      }>;
    } | null;
  } | null;
};

export type UserSourceQueryVariables = Exact<{ [key: string]: never }>;

export type UserSourceQuery = {
  __typename?: "Query";
  user?: {
    __typename?: "User";
    id: string;
    userSource?: {
      __typename?: "UserSource";
      utmSource?: string | null;
      utmMedium?: string | null;
      utmCampaign?: string | null;
    } | null;
  } | null;
};

export type UserWebQueryVariables = Exact<{ [key: string]: never }>;

export type UserWebQuery = {
  __typename?: "Query";
  user?: {
    __typename?: "User";
    id: string;
    isEmployee: boolean;
    userContactEmail?: {
      __typename?: "UserContactEmail";
      email: string;
    } | null;
    userPersonalDetails?: {
      __typename?: "UserPersonalDetails";
      fullName: string;
    } | null;
  } | null;
};

export type ValidateInviteCodeQueryVariables = Exact<{
  inviteCode: Scalars["String"]["input"];
}>;

export type ValidateInviteCodeQuery = {
  __typename?: "Query";
  validateInviteCode: boolean;
};

export const SubAccountPricingFragmentDoc = gql`
  fragment SubAccountPricing on SubAccountPricing {
    id
    amount
    unit
    validFrom
    validTo
  }
`;
export const SecurityFragmentDoc = gql`
  fragment Security on Security {
    id
    asOfDate
    buyStatus
    sellStatus
    cusip
    isin
    name
    symbol
    type
    subType
  }
`;
export const MarginCallDataFragmentDoc = gql`
  fragment MarginCallData on MarginCallData {
    id
    dueDate
    eventTime
    statuses {
      state
      amount
      eventTime
    }
  }
`;
export const ProfileAddressFragmentDoc = gql`
  fragment ProfileAddress on GenericAddress {
    addressType
    addressLine1
    addressLine2
    city
    state
    zipCode
    country
  }
`;
export const ClearingAccountBusinessEntityFragmentDoc = gql`
  fragment ClearingAccountBusinessEntity on BusinessEntityInfo {
    businessEntityDetails: entityDetails {
      id
      name
      phoneNumber
      industry
      address {
        ...ProfileAddress
      }
    }
    entityAuthorizedUser {
      user {
        userPersonalDetails {
          fullName
        }
        userIdentityDetails {
          citizenshipCountry
          dateOfBirth
          ssn
        }
        userContactPhone {
          phoneNumber
        }
        userContactEmail {
          email
        }
      }
      userTitle
    }
    entityIndividuals {
      familyName
      givenName
      dateOfBirth
      individualType
      address {
        ...ProfileAddress
      }
    }
  }
  ${ProfileAddressFragmentDoc}
`;
export const GenericAddressFragmentDoc = gql`
  fragment GenericAddress on GenericAddress {
    id
    addressType
    addressLine1
    addressLine2
    city
    state
    zipCode
    country
  }
`;
export const TrustEntityDetailsFragmentDoc = gql`
  fragment TrustEntityDetails on TrustEntityDetails {
    id
    name
    taxId
    taxIdType
    dateOfCreation
    revocability
    state
    trustAgreementAllowsMarginsAndShortPositions
    address {
      ...GenericAddress
    }
  }
  ${GenericAddressFragmentDoc}
`;
export const TrusteeFragmentDoc = gql`
  fragment Trustee on Trustee {
    id
    name
    familyName
    givenName
    dateOfBirth
    idType
    idIssuingStateOrCountry
    idExpirationDate
    governmentIdNumber
    disclosure {
      id
      affiliatedExchangeOrFINRA
      firmName
      controlPerson
      companySymbols
      politicallyExposed
      politicalOrganization
      politicalTitle
      immediateFamilyMembers
    }
    address {
      ...GenericAddress
    }
  }
  ${GenericAddressFragmentDoc}
`;
export const ClearingAccountTrustEntityFragmentDoc = gql`
  fragment ClearingAccountTrustEntity on TrustEntity {
    trustEntityDetails: entityDetails {
      ...TrustEntityDetails
    }
    primaryTrustee {
      userContactEmail {
        email
        emailType
      }
      userEmploymentDetails {
        employerName
        occupation
      }
      userIdentityDetails {
        dateOfBirth
        citizenshipCountry
      }
      userPersonalDetails {
        fullName
      }
      userContactPhone {
        phoneNumber
      }
      userAddresses {
        ...ProfileAddress
      }
    }
    additionalTrustees {
      ...Trustee
    }
  }
  ${TrustEntityDetailsFragmentDoc}
  ${ProfileAddressFragmentDoc}
  ${TrusteeFragmentDoc}
`;
export const TreasuryConfigFragmentDoc = gql`
  fragment TreasuryConfig on TreasuryConfig {
    subAccountId
    eventTime
  }
`;
export const DividendPreferenceFragmentDoc = gql`
  fragment DividendPreference on DividendPreference {
    id
    clearingAccountId
    subAccountType
    preferenceType
  }
`;
export const ClearingAccountFragmentDoc = gql`
  fragment ClearingAccount on ClearingAccount {
    id
    accountType
    categorizationType
    externalClearingAccountId
    eventTime
    isTestAccount
    isPatternDayTrader
    isClientAccount
    primarySubAccountId
    subAccounts {
      id
      type
      eventTime
      pricing {
        ...SubAccountPricing
      }
      directIndexAccountMetadata {
        id
        type
        etfSymbol
        signedAt
        lhfOverride
        subAccountId
        customization {
          id
          addSecurityIds {
            securityId
            security {
              ...Security
            }
          }
          removeSecurityIds {
            securityId
            security {
              ...Security
            }
          }
          removeGICSSectorIds
        }
        status
      }
    }
    marginCallData {
      ...MarginCallData
    }
    actionContexts {
      actionContext
      isAllowed
    }
    restrictions {
      restrictionType
      active
    }
    entity {
      ... on BusinessEntityInfo {
        ...ClearingAccountBusinessEntity
      }
      ... on TrustEntity {
        ...ClearingAccountTrustEntity
      }
    }
    treasury {
      ...TreasuryConfig
    }
    dividendPreference {
      ...DividendPreference
    }
  }
  ${SubAccountPricingFragmentDoc}
  ${SecurityFragmentDoc}
  ${MarginCallDataFragmentDoc}
  ${ClearingAccountBusinessEntityFragmentDoc}
  ${ClearingAccountTrustEntityFragmentDoc}
  ${TreasuryConfigFragmentDoc}
  ${DividendPreferenceFragmentDoc}
`;
export const AuthUserFragmentDoc = gql`
  fragment AuthUser on User {
    id
    isEmployee
    clearingAccounts {
      ...ClearingAccount
    }
    userContactEmail {
      id
      email
      emailType
      emailHash
      verified
    }
    userContactPhone {
      id
      phoneNumber
      phoneNumberVerified
      phoneNumberType
    }
    userPersonalDetails {
      id
      firstName
      lastName
      middleName
      fullName
    }
    userIdentityDetails {
      id
      ssn
      dateOfBirth
      citizenshipCountry
      permanentResident
      visaStatus
      visaExpiration
      maritalStatus
      numberOfDependents
      idType
      idIssuingStateOrCountry
      idExpirationDate
      governmentIdNumber
    }
    userAddresses {
      ...GenericAddress
    }
    userInvestmentProfile {
      id
      netWorthMin
      netWorthMax
      annualIncomeMin
      annualIncomeMax
      investmentExperience
      riskTolerance
      liquidityTimeHorizon
      liquidityNeeds
    }
    userServiceProfile {
      id
      sweepInstruction
      dividendReinvestment
    }
    userTaxRates {
      id
      taxFilingStatus
      federalIncomeTaxRate
      stateIncomeTaxRate
      federalCapitalGainsTaxRate
      stateCapitalGainsTaxRate
    }
    userEmploymentDetails {
      id
      employerName
      employmentStatus
      yearsEmployed
      occupation
      addressLine1
      addressLine2
      city
      state
      zipCode
      country
    }
    userTrustedContact {
      id
      relationship
      firstName
      lastName
      middleName
      email
      phoneNumber
      phoneNumberType
      addressLine1
      addressLine2
      city
      state
      zipCode
      country
    }
    userDisclosures {
      id
      affiliatedExchangeOrFINRA
      affiliatedExchangeOrFINRAEntityName
      firmName
      controlPerson
      companySymbols
      politicallyExposed
      politicalOrganization
      immediateFamily
    }
    userProgressFlags {
      status
      category
    }
    entityAccountRequestStatus {
      state
      asOfDate
    }
    uploadedDocuments {
      id
      name
      originalName
      description
      type
      location
    }
    inviteCodes
    referralPromo {
      code
      dealType
    }
  }
  ${ClearingAccountFragmentDoc}
  ${GenericAddressFragmentDoc}
`;
export const CashDividendItemFragmentDoc = gql`
  fragment CashDividendItem on CashDividend {
    id
    clearingAccountId
    subAccountId
    amount
    eventTime
    description
    security {
      ...Security
    }
    cashDividendFee {
      id
      type
      amount
    }
  }
  ${SecurityFragmentDoc}
`;
export const CashTransferFragmentDoc = gql`
  fragment CashTransfer on CashTransfer {
    id
    userId
    clearingAccountId
    subAccountId
    amount
    method
    direction
    status
    statusHistory {
      id
      status
      externalStatus
      externalStatusTime
      reason
      reasonDesc
      reasonDetail
      reasonRecommendation
      eventTime
    }
    cashTransferFee {
      id
      type
      customerDebit
      clearingHouseCredit
      correspondentNetAmount
    }
    eventTime
    sourceDepositAccount {
      id
      clearingAccountId
      accountNumber
      name
      officialName
      subType
    }
    destinationDepositAccount {
      id
      clearingAccountId
      accountNumber
      name
      officialName
      subType
    }
  }
`;
export const CashTransferListItemFragmentDoc = gql`
  fragment CashTransferListItem on CashTransfer {
    id
    amount
    method
    direction
    status
    eventTime
  }
`;
export const DailyCashDividendItemFragmentDoc = gql`
  fragment DailyCashDividendItem on DailyCashDividend {
    id
    clearingAccountId
    subAccountId
    amount
    eventTime
    dividends {
      amount
      security {
        ...Security
      }
    }
    fees {
      amount
      type
      security {
        ...Security
      }
    }
  }
  ${SecurityFragmentDoc}
`;
export const DepositAccountFragmentDoc = gql`
  fragment DepositAccount on DepositAccount {
    id
    plaidAccountId
    routingNumber
    accountNumber
    wireRoutingNumber
    name
    officialName
    subType
    apexRelationshipStatus
  }
`;
export const DetailedSecurityFragmentDoc = gql`
  fragment DetailedSecurity on DetailedSecurity {
    mostLiquidExchange
    categoryOrIndustry
    marketIdentificationCode
    market
    name
    valoren
    ISIN
    symbol
    CUSIP
    CIK
  }
`;
export const DetailedQuoteFragmentDoc = gql`
  fragment DetailedQuote on DetailedQuote {
    ask
    askMarketIdentificationCode
    askSize
    askTime
    bid
    bidMarketIdentificationCode
    bidSize
    bidTime
    changeFromPreviousClose
    close
    currency
    delay
    high
    high52Weeks
    identifier
    identifierType
    last
    lastMarketIdentificationCode
    lastSize
    low
    low52Weeks
    message
    open
    outcome
    percentChangeFromPreviousClose
    previousClose
    previousCloseDate
    security {
      ...DetailedSecurity
    }
    time
    tradingHalted
    volume
  }
  ${DetailedSecurityFragmentDoc}
`;
export const DirectIndexFeeChargeFragmentDoc = gql`
  fragment DirectIndexFeeCharge on DirectIndexFeeCharge {
    id
    amount
    eventTime
    billingStartDate
    billingEndDate
    billedTo
  }
`;
export const DirectIndexTradeExecutionSummaryFragmentDoc = gql`
  fragment DirectIndexTradeExecutionSummary on DirectIndexTradeExecutionSummary {
    id
    subAccountId
    clearingAccountId
    status
    eventTime
    latestStatusTime
    cashLeftover
    isLiquidationRequest
    orders {
      securityId
      quantity
      shortTermGain
      shortTermLoss
      longTermGain
      longTermLoss
      notional
      orderSide
      security {
        ...Security
      }
    }
  }
  ${SecurityFragmentDoc}
`;
export const TrustEntityFragmentDoc = gql`
  fragment TrustEntity on TrustEntity {
    id
    entityDetails {
      ...TrustEntityDetails
    }
    primaryTrusteeId
    additionalTrustees {
      ...Trustee
    }
    address {
      ...GenericAddress
    }
  }
  ${TrustEntityDetailsFragmentDoc}
  ${TrusteeFragmentDoc}
  ${GenericAddressFragmentDoc}
`;
export const HoldingFragmentDoc = gql`
  fragment Holding on Holding {
    symbol
    quantity
    price
    marginRequirement
  }
`;
export const IndexConstituentFragmentDoc = gql`
  fragment IndexConstituent on IndexConstituent {
    symbol
    name
    weight
    effectiveDate
    marketCap
    indexMarketCap
    price
    gicsCode
    fractionalSupported
  }
`;
export const IndexHistoricalFragmentDoc = gql`
  fragment IndexHistorical on IndexHistorical {
    date
    indexName
    indexCode
    indexKey
    GICSCode
    ISOCode
    indexValue
    closeMarketCap
    closeDivisor
    closeCount
    dailyReturn
    indexDividend
    adjMarketCap
    adjDivisor
    adjCount
  }
`;
export const IndexHistoricalPerformanceFragmentDoc = gql`
  fragment IndexHistoricalPerformance on IndexHistoricalPerformanceData {
    period
    timeseriesData {
      date
      value
    }
    returnForPeriod
  }
`;
export const IndexMetadataFragmentDoc = gql`
  fragment IndexMetadata on IndexDetails {
    allowedSectorCustomizations
    allowedStockCustomizations
    canCustomizeSectors
    comparisonEtf
    comparisonEtfExpenseRatio
    comparisonEtfName
    feePercent
    indexDescription
    indexName
    indexType
    investmentMinimum
    learnMoreLink
    numberOfPositions
    stockIndex
    constituentData {
      asOfDate
      constituents {
        symbol
        name
        indexMarketCap
        weight
        fractionalSupported
        canDisplayWeight
      }
      sectorData {
        sectorName
        sectorMarketCapPercent
        gicsCode
        sectorPositionsCount
      }
      countryData {
        countryName
        countryMarketCapPercent
      }
      totalMarketCap
    }
    historicalPerformanceData {
      returnForPeriod
      period
      timeseriesData {
        date
        value
      }
    }
  }
`;
export const IndexSectorMetadataFragmentDoc = gql`
  fragment IndexSectorMetadata on IndexDetails {
    constituentData {
      sectorData {
        gicsCode
        sectorPositionsCount
      }
    }
  }
`;
export const IntraAccountCashTransferFragmentDoc = gql`
  fragment IntraAccountCashTransfer on IntraAccountCashTransfer {
    id
    subAccountId
    amount
    from
    to
    eventTime
    source
  }
`;
export const IntraAccountStockTransferFragmentDoc = gql`
  fragment IntraAccountStockTransfer on IntraAccountStockTransfer {
    adjustmentsGroupId
    subAccountId
    from
    to
    eventTime
    source
    stocks {
      symbol
      quantity
    }
  }
`;
export const InvestIndexDetailsFragmentDoc = gql`
  fragment InvestIndexDetails on IndexDetails {
    indexType
    indexName
    indexSubText
    indexDisplayCategory
    numberOfPositions
    ytdReturn
  }
`;
export const KeyValuePairFragmentDoc = gql`
  fragment KeyValuePair on KeyValuePair {
    id
    key
    namespace
    value
    expiresAt
  }
`;
export const PlaidAccountFragmentDoc = gql`
  fragment PlaidAccount on PlaidAccount {
    id
    plaidInstitutionId
    name
    type
    subType
    officialName
    institutionName
    logo
    mask
    verificationStatus
    wireRoutingNumber
    accountNumber
    hasMissingAccountNumber
    status
    owners
    error
  }
`;
export const StockQuoteFragmentDoc = gql`
  fragment StockQuote on StockQuote {
    symbol
    price
    name
    change
    changesPercentage
    open
    previousClose
    volume
    eventTime
    marginRequirement {
      symbol
      maintenanceRequirement
    }
  }
`;
export const PlaidAccountPortfolioHoldingFragmentDoc = gql`
  fragment PlaidAccountPortfolioHolding on PlaidAccountPortfolioHolding {
    securityId
    name
    symbol
    cusip
    quantity
    costBasis
    currency
    closingPrice
    supported
    security {
      ...Security
      stockQuote {
        ...StockQuote
      }
    }
    plaidAccountId
  }
  ${SecurityFragmentDoc}
  ${StockQuoteFragmentDoc}
`;
export const PlaidAccountWithPortfolioFragmentDoc = gql`
  fragment PlaidAccountWithPortfolio on PlaidAccount {
    ...PlaidAccount
    portfolio @include(if: $includePlaidPortfolio) {
      cashBalance
      totalBalance
      holdings {
        ...PlaidAccountPortfolioHolding
      }
      error
    }
  }
  ${PlaidAccountFragmentDoc}
  ${PlaidAccountPortfolioHoldingFragmentDoc}
`;
export const LinkedBrokerageAccountFragmentDoc = gql`
  fragment LinkedBrokerageAccount on LinkedBrokerageAccount {
    id
    plaidAccountId
    name
    mask
    institutionName
    plaidAccount {
      ...PlaidAccountWithPortfolio
    }
    acatsTransfers {
      status: currentStatus
      eventTime
      id
      statusHistory {
        status: frecStatus
        eventTime
      }
    }
    isPartialTransferDisabled
  }
  ${PlaidAccountWithPortfolioFragmentDoc}
`;
export const LiquidateDirectIndexPortfolioRequestFragmentDoc = gql`
  fragment LiquidateDirectIndexPortfolioRequest on LiquidateDirectIndexPortfolioRequest {
    id
    subAccountId
    status
    amount
    isFullLiquidation
    eventTime
  }
`;
export const LoanEventDataFragmentDoc = gql`
  fragment LoanEventData on LoanEvent {
    id
    type
    amount
    eventTime
    acatsTransferId
  }
`;
export const MarginInterestChargeFragmentDoc = gql`
  fragment MarginInterestCharge on MarginInterestCharge {
    id
    amount
    eventTime
    billingStartDate
    billingEndDate
    billedTo
  }
`;
export const PaymentConfigFragmentDoc = gql`
  fragment PaymentConfig on PaymentConfig {
    id
    type
    sourceType
    sourceSecurity {
      ...Security
    }
    destinationType
    destinationAccountId
    amountType
    amount
    dayOfMonth
    periodType
    startAt
    cardName
  }
  ${SecurityFragmentDoc}
`;
export const PaymentExecutionFragmentDoc = gql`
  fragment PaymentExecution on PaymentExecution {
    id
    paymentConfig {
      ...PaymentConfig
    }
    status
    amount
    failureReason
    eventTime
  }
  ${PaymentConfigFragmentDoc}
`;
export const PendingLoanDataFragmentDoc = gql`
  fragment PendingLoanData on PendingLoan {
    id
    amount
    status
    eventTime
  }
`;
export const MmfMetadataFragmentDoc = gql`
  fragment MMFMetadata on MMFMetadata {
    name
    cusip
    symbol
    nav
    netAssets
    secYield7Day
    expenseRatio
    inceptionDate
    minInvestment
    ytdYield
    dividendSchedule
    lastUpdated
    link
  }
`;
export const SecurityWithQuoteFragmentDoc = gql`
  fragment SecurityWithQuote on Security {
    id
    asOfDate
    buyStatus
    sellStatus
    cusip
    isin
    name
    symbol
    type
    subType
    stockQuote {
      ...StockQuote
    }
    mmfMetadata {
      ...MMFMetadata
    }
    symbolAlias {
      symbol
      context
    }
  }
  ${StockQuoteFragmentDoc}
  ${MmfMetadataFragmentDoc}
`;
export const LiveSecurityHoldingFragmentDoc = gql`
  fragment LiveSecurityHolding on LiveSecurityHolding {
    clearingAccountId
    securityId
    subAccountId
    subAccountType
    quantity
    avgCostBasis
    dividendYtd
    security {
      ...SecurityWithQuote
    }
  }
  ${SecurityWithQuoteFragmentDoc}
`;
export const PortfolioDataFragmentDoc = gql`
  fragment PortfolioData on Portfolio {
    clearingAccountId
    availability
    asOfDate
    securities {
      ...LiveSecurityHolding
    }
    cash {
      type
      subAccountId
      subAccountType
      value
    }
  }
  ${LiveSecurityHoldingFragmentDoc}
`;
export const ReferralRewardTypeFragmentDoc = gql`
  fragment ReferralRewardType on ReferralRewardType {
    ... on CashRewardType {
      amount
    }
    ... on TreasuryRewardType {
      basisPoints
    }
  }
`;
export const ReferralRewardDetailsFragmentDoc = gql`
  fragment ReferralRewardDetails on ReferralRewardDetails {
    grantedOn
    rewardReceived
    rewardReceivedAdditionalInfo
    referralRewardType {
      ...ReferralRewardType
    }
    kind
  }
  ${ReferralRewardTypeFragmentDoc}
`;
export const ScheduledDepositConfigFragmentDoc = gql`
  fragment ScheduledDepositConfig on ScheduledDepositConfig {
    id
    type
    sourceType
    amount
    dayOfPeriod
    secondaryDayOfPeriod
    periodType
    clearingAccountId
    subAccountId
    primaryAccountId
    sourceSubAccountId
    startAt
  }
`;
export const ScheduledRewardFragmentDoc = gql`
  fragment ScheduledReward on ScheduledReward {
    id
    subAccountId
    promotionKey
    promotionName
    promotionTerms
    creditDueDate
    creditAmount
    creditId
    createdAt
    updatedAt
  }
`;
export const SelfManagedTaxLossConfigFragmentDoc = gql`
  fragment SelfManagedTaxLossConfig on SelfManagedTaxLossConfig {
    id
    clearingAccountId
    sweepToSubAccountId
    sweepAll
    securities
  }
`;
export const SimpleActivityFragmentDoc = gql`
  fragment SimpleActivity on SimpleActivity {
    id
    activityType
    title
    numOfShares
    status
    statusText
    date
    value
    description
    automated
  }
`;
export const StockAdjustmentDetailsFragmentDoc = gql`
  fragment StockAdjustmentDetails on StockAdjustmentDetails {
    id
    clearingAccountId
    subAccountId
    securityId
    adjustmentAmount
    adjustmentFactor
    adjustmentType
    effectiveTime
    eventTime
    securityPrice
    security {
      ...Security
    }
    treasuryFees {
      id
      amount
      status
    }
  }
  ${SecurityFragmentDoc}
`;
export const SubAccountPortfolioAggregateFragmentDoc = gql`
  fragment SubAccountPortfolioAggregate on SubAccountPortfolioAggregate {
    subAccount {
      id
      type
      directIndexAccountMetadata {
        type
      }
    }
    aggregates {
      securityValue
      mmfHoldingValue
      cashValue
      dailyChange
      mmfDividendYTD
      loanValue
    }
  }
`;
export const TaxLotEntryFragmentDoc = gql`
  fragment TaxLotEntry on TaxLotEntry {
    id
    securityId
    subAccountId
    symbol
    eventTime
    longTermShortTermIndicator
    netProceed
    quantity
    realizedIndicator
    taxLotOpenBuyDate
    taxLotCloseSellDate
    costPerShare: openBuyPrice
    totalCost: cost
    realizedGainLoss
    washSalesIndicator
    washSalesDisallowed
  }
`;
export const TreasuryExecutionFragmentDoc = gql`
  fragment TreasuryExecution on TreasuryExecution {
    id
    treasuryConfigId
    amount
    status
    eventTime
    cashTransfer {
      id
      treasuryExecutionId
      cashTransferId
      eventTime
    }
    order {
      id
      treasuryExecutionId
      eventTime
      batchOrderMetadataId
    }
  }
`;
export const TreasuryYieldFragmentDoc = gql`
  fragment TreasuryYield on TreasuryYield {
    annualFeePercentage
    blendedSevenDayYieldPercentage
    maxSevenDayYieldPercentage
    lastMonthNetYieldUSD
    ytdNetYieldUSD
    allTimeNetYieldUSD
    supportedFundSymbols
  }
`;
export const UserSourceFragmentDoc = gql`
  fragment UserSource on UserSource {
    utmSource
    utmMedium
    utmCampaign
  }
`;
export const AcatsTransferActivityFragmentDoc = gql`
  fragment AcatsTransferActivity on AcatsTransfer {
    id
    userId
    clearingAccountId
    acatsControlNumber
    associatedAcatsId
    transferType
    eventTime
    currentStatus
    direction
    accountOfficialName
    accountInstitutionName
    accountMask
    assets {
      symbol
      quantity
      securityId
      cash
      cashType
      quantity
    }
    fee {
      id
      type
      amount
    }
    statusHistory {
      id
      acatsTransferId
      frecStatus
      eventTime
      errors {
        errorCode
        fieldName
        message
        attemptedValue
      }
    }
  }
`;
export const GetAccountAcatsTransfersFragmentDoc = gql`
  fragment getAccountAcatsTransfers on ClearingAccount {
    getAccountAcatsTransfers(
      size: $size
      nextPageToken: $acatsTransfersNextPageToken
    ) {
      nextPageToken
      data {
        ...AcatsTransferActivity
      }
    }
  }
  ${AcatsTransferActivityFragmentDoc}
`;
export const OrderFragmentDoc = gql`
  fragment Order on Order {
    id
    userId
    clearingAccountId
    subAccountId
    side
    orderType
    securityId
    quantityType
    quantity
    maxSellQuantity
    limitPrice
    limitDuration
    orderPrice
    eventTime
    orderStatus
    source
    opsInitiated
    statusHistory {
      id
      orderId
      orderStatus
      eventTime
      fillShares
      fillAvgPrice
      fillNotional
    }
    orderFees {
      id
      type
      amount
    }
    security {
      ...Security
    }
    lotMatchingInstructions {
      taxLotEntryId
      tradeDate
      quantity
      price
      realizedGainLoss
      cost
      longTermShortTermIndicator
    }
    shortTermGain
    shortTermLoss
    longTermGain
    longTermLoss
  }
  ${SecurityFragmentDoc}
`;
export const GetAccountOrdersFragmentDoc = gql`
  fragment getAccountOrders on Query {
    getAccountOrders(
      clearingAccountId: $clearingAccountId
      size: $size
      nextPageToken: $orderNextPageToken
    ) {
      nextPageToken
      data {
        ...Order
      }
    }
  }
  ${OrderFragmentDoc}
`;
export const GetMarginInterestChargesFragmentDoc = gql`
  fragment getMarginInterestCharges on Query {
    getMarginInterestCharges(
      input: { clearingAccountId: $clearingAccountId }
      size: $size
      nextPageToken: $marginInterestChargeNextPageToken
    ) {
      nextPageToken
      data {
        id
        amount
        eventTime
        billingStartDate
        billingEndDate
      }
    }
  }
`;
export const PortfolioSnapshotFragmentDoc = gql`
  fragment PortfolioSnapshot on PortfolioSnapshot {
    date
    cashBalance
    loan
    fees
    portfolioSnapshotHoldingClosingValue
  }
`;
export const ComparisonPortfolioSnapshotFragmentDoc = gql`
  fragment ComparisonPortfolioSnapshot on ComparisonPortfolioSnapshot {
    date
    snapshotHoldingClosingValue
  }
`;
export const DirectIndexPerformanceDataPeriodFragmentDoc = gql`
  fragment DirectIndexPerformanceDataPeriod on DirectIndexPerformanceDataPeriod {
    period
    cumulativeNetDeposits {
      date
      value
    }
    taxLossesHarvested
    netDeposits
    moneyWeightedReturnPercent
    timeWeightedReturnPercent
    simpleReturnPercent
    shortTermLosses
    shortTermGains
    longTermLosses
    longTermGains
    shortWashSalesDisallowed
    longWashSalesDisallowed
    netShortTermLosses
    netLongTermLosses
    estimatedTaxSavings
  }
`;
export const DirectIndexPerformanceDataFragmentDoc = gql`
  fragment DirectIndexPerformanceData on DirectIndexPerformanceData {
    directIndexHistory {
      ...PortfolioSnapshot
    }
    comparisonPortfolioHistory {
      ...ComparisonPortfolioSnapshot
    }
    periods {
      ...DirectIndexPerformanceDataPeriod
    }
  }
  ${PortfolioSnapshotFragmentDoc}
  ${ComparisonPortfolioSnapshotFragmentDoc}
  ${DirectIndexPerformanceDataPeriodFragmentDoc}
`;
export const DirectIndexOptimizationRunDataFragmentDoc = gql`
  fragment DirectIndexOptimizationRunData on DirectIndexOptimizationRunData {
    valueCantBuy
    lossHarvestCantBuy
    missingCostCantSell
    washSalesCantBuy
    washSalesCantSell
    dayTradeCantSell
    rawResponse {
      quantityDelta {
        symbol
        delta
      }
      cashDelta
    }
    postProcessedResponse {
      quantityDelta {
        symbol
        delta
      }
      cashDelta
    }
  }
`;
export const DirectIndexTaxLotFragmentDoc = gql`
  fragment DirectIndexTaxLot on DirectIndexTaxLot {
    id
    securityId
    subAccountId
    eventTime
    settlementDate
    taxLotOpenBuyDate
    taxLotCloseSellDate
    openBuyPrice
    openBuyCostAmount
    openTransactionId
    openLotId
    closedLotId
    closedTransactionId
    quantity
    cost
    netProceed
    realizedGainLoss
    longTermShortTermIndicator
    realizedIndicator
    washSalesIndicator
    washSalesDisallowed
    washSaleAdjustmentAmount
    buyBackIndicator
  }
`;
export const DirectIndexTradeExecutionStatusHistoryFragmentDoc = gql`
  fragment DirectIndexTradeExecutionStatusHistory on DirectIndexTradeExecutionStatusHistory {
    id
    directIndexTradeExecutionId
    status
    eventTime
  }
`;
export const DirectIndexWeightFragmentDoc = gql`
  fragment DirectIndexWeight on DirectIndexWeight {
    security {
      ...Security
    }
    securityId
    weight
    canLiquidate
    canPurchase
    canDisplayWeight
  }
  ${SecurityFragmentDoc}
`;
export const DirectIndexTradeExecutionFragmentDoc = gql`
  fragment DirectIndexTradeExecution on DirectIndexTradeExecution {
    id
    subAccountId
    batchOrderMetadataId
    status
    type
    liquidateAmount
    isFullLiquidation
    eventTime
    cashLeftover
    statusHistory {
      ...DirectIndexTradeExecutionStatusHistory
    }
    weights {
      ...DirectIndexWeight
    }
  }
  ${DirectIndexTradeExecutionStatusHistoryFragmentDoc}
  ${DirectIndexWeightFragmentDoc}
`;
export const InitiateCashTransferDocument = gql`
  mutation initiateCashTransfer($input: InitiateCashTransferInput!) {
    initiateCashTransfer(input: $input) {
      status
      transferId
      transferIdType
    }
  }
`;
export type InitiateCashTransferMutationFn = Apollo.MutationFunction<
  InitiateCashTransferMutation,
  InitiateCashTransferMutationVariables
>;

/**
 * __useInitiateCashTransferMutation__
 *
 * To run a mutation, you first call `useInitiateCashTransferMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInitiateCashTransferMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [initiateCashTransferMutation, { data, loading, error }] = useInitiateCashTransferMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useInitiateCashTransferMutation(
  baseOptions?: Apollo.MutationHookOptions<
    InitiateCashTransferMutation,
    InitiateCashTransferMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    InitiateCashTransferMutation,
    InitiateCashTransferMutationVariables
  >(InitiateCashTransferDocument, options);
}
export type InitiateCashTransferMutationHookResult = ReturnType<
  typeof useInitiateCashTransferMutation
>;
export type InitiateCashTransferMutationResult =
  Apollo.MutationResult<InitiateCashTransferMutation>;
export type InitiateCashTransferMutationOptions = Apollo.BaseMutationOptions<
  InitiateCashTransferMutation,
  InitiateCashTransferMutationVariables
>;
export const PlaidVerifyMicrodepositLinkTokenDocument = gql`
  mutation PlaidVerifyMicrodepositLinkToken(
    $type: PlaidAccountType!
    $plaidAccountId: String!
  ) {
    plaidVerifyMicrodepositLinkToken(
      type: $type
      plaidAccountId: $plaidAccountId
    ) {
      token
      expiresAt
    }
  }
`;
export type PlaidVerifyMicrodepositLinkTokenMutationFn =
  Apollo.MutationFunction<
    PlaidVerifyMicrodepositLinkTokenMutation,
    PlaidVerifyMicrodepositLinkTokenMutationVariables
  >;

/**
 * __usePlaidVerifyMicrodepositLinkTokenMutation__
 *
 * To run a mutation, you first call `usePlaidVerifyMicrodepositLinkTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePlaidVerifyMicrodepositLinkTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [plaidVerifyMicrodepositLinkTokenMutation, { data, loading, error }] = usePlaidVerifyMicrodepositLinkTokenMutation({
 *   variables: {
 *      type: // value for 'type'
 *      plaidAccountId: // value for 'plaidAccountId'
 *   },
 * });
 */
export function usePlaidVerifyMicrodepositLinkTokenMutation(
  baseOptions?: Apollo.MutationHookOptions<
    PlaidVerifyMicrodepositLinkTokenMutation,
    PlaidVerifyMicrodepositLinkTokenMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    PlaidVerifyMicrodepositLinkTokenMutation,
    PlaidVerifyMicrodepositLinkTokenMutationVariables
  >(PlaidVerifyMicrodepositLinkTokenDocument, options);
}
export type PlaidVerifyMicrodepositLinkTokenMutationHookResult = ReturnType<
  typeof usePlaidVerifyMicrodepositLinkTokenMutation
>;
export type PlaidVerifyMicrodepositLinkTokenMutationResult =
  Apollo.MutationResult<PlaidVerifyMicrodepositLinkTokenMutation>;
export type PlaidVerifyMicrodepositLinkTokenMutationOptions =
  Apollo.BaseMutationOptions<
    PlaidVerifyMicrodepositLinkTokenMutation,
    PlaidVerifyMicrodepositLinkTokenMutationVariables
  >;
export const AddManualAccountNumberToPlaidAccountDocument = gql`
  mutation addManualAccountNumberToPlaidAccount(
    $plaidAccountId: ID!
    $manualAccountNumber: String!
    $checkPaymentsAuthorized: Boolean
  ) {
    addManualAccountNumberToPlaidAccount(
      plaidAccountId: $plaidAccountId
      manualAccountNumber: $manualAccountNumber
      checkPaymentsAuthorized: $checkPaymentsAuthorized
    ) {
      ...PlaidAccount
    }
  }
  ${PlaidAccountFragmentDoc}
`;
export type AddManualAccountNumberToPlaidAccountMutationFn =
  Apollo.MutationFunction<
    AddManualAccountNumberToPlaidAccountMutation,
    AddManualAccountNumberToPlaidAccountMutationVariables
  >;

/**
 * __useAddManualAccountNumberToPlaidAccountMutation__
 *
 * To run a mutation, you first call `useAddManualAccountNumberToPlaidAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddManualAccountNumberToPlaidAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addManualAccountNumberToPlaidAccountMutation, { data, loading, error }] = useAddManualAccountNumberToPlaidAccountMutation({
 *   variables: {
 *      plaidAccountId: // value for 'plaidAccountId'
 *      manualAccountNumber: // value for 'manualAccountNumber'
 *      checkPaymentsAuthorized: // value for 'checkPaymentsAuthorized'
 *   },
 * });
 */
export function useAddManualAccountNumberToPlaidAccountMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddManualAccountNumberToPlaidAccountMutation,
    AddManualAccountNumberToPlaidAccountMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AddManualAccountNumberToPlaidAccountMutation,
    AddManualAccountNumberToPlaidAccountMutationVariables
  >(AddManualAccountNumberToPlaidAccountDocument, options);
}
export type AddManualAccountNumberToPlaidAccountMutationHookResult = ReturnType<
  typeof useAddManualAccountNumberToPlaidAccountMutation
>;
export type AddManualAccountNumberToPlaidAccountMutationResult =
  Apollo.MutationResult<AddManualAccountNumberToPlaidAccountMutation>;
export type AddManualAccountNumberToPlaidAccountMutationOptions =
  Apollo.BaseMutationOptions<
    AddManualAccountNumberToPlaidAccountMutation,
    AddManualAccountNumberToPlaidAccountMutationVariables
  >;
export const AddUserEmploymentDetailsDocument = gql`
  mutation addUserEmploymentDetails($data: UserEmploymentDetailsInput!) {
    addUserEmploymentDetails(userEmploymentDetailsInput: $data) {
      id
      employerName
      employmentStatus
      yearsEmployed
      occupation
      addressLine1
      addressLine2
      city
      state
      zipCode
      country
    }
  }
`;
export type AddUserEmploymentDetailsMutationFn = Apollo.MutationFunction<
  AddUserEmploymentDetailsMutation,
  AddUserEmploymentDetailsMutationVariables
>;

/**
 * __useAddUserEmploymentDetailsMutation__
 *
 * To run a mutation, you first call `useAddUserEmploymentDetailsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddUserEmploymentDetailsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addUserEmploymentDetailsMutation, { data, loading, error }] = useAddUserEmploymentDetailsMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useAddUserEmploymentDetailsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddUserEmploymentDetailsMutation,
    AddUserEmploymentDetailsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AddUserEmploymentDetailsMutation,
    AddUserEmploymentDetailsMutationVariables
  >(AddUserEmploymentDetailsDocument, options);
}
export type AddUserEmploymentDetailsMutationHookResult = ReturnType<
  typeof useAddUserEmploymentDetailsMutation
>;
export type AddUserEmploymentDetailsMutationResult =
  Apollo.MutationResult<AddUserEmploymentDetailsMutation>;
export type AddUserEmploymentDetailsMutationOptions =
  Apollo.BaseMutationOptions<
    AddUserEmploymentDetailsMutation,
    AddUserEmploymentDetailsMutationVariables
  >;
export const AddUserMfaPreferenceDocument = gql`
  mutation addUserMfaPreference($input: UserMfaPreferenceInput!) {
    addUserMfaPreference(userMfaPreferenceInput: $input) {
      qrCode
      setupKey
      userMfaPreference {
        id
        type
        eventTime
      }
    }
  }
`;
export type AddUserMfaPreferenceMutationFn = Apollo.MutationFunction<
  AddUserMfaPreferenceMutation,
  AddUserMfaPreferenceMutationVariables
>;

/**
 * __useAddUserMfaPreferenceMutation__
 *
 * To run a mutation, you first call `useAddUserMfaPreferenceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddUserMfaPreferenceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addUserMfaPreferenceMutation, { data, loading, error }] = useAddUserMfaPreferenceMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddUserMfaPreferenceMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddUserMfaPreferenceMutation,
    AddUserMfaPreferenceMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AddUserMfaPreferenceMutation,
    AddUserMfaPreferenceMutationVariables
  >(AddUserMfaPreferenceDocument, options);
}
export type AddUserMfaPreferenceMutationHookResult = ReturnType<
  typeof useAddUserMfaPreferenceMutation
>;
export type AddUserMfaPreferenceMutationResult =
  Apollo.MutationResult<AddUserMfaPreferenceMutation>;
export type AddUserMfaPreferenceMutationOptions = Apollo.BaseMutationOptions<
  AddUserMfaPreferenceMutation,
  AddUserMfaPreferenceMutationVariables
>;
export const AddUserTrustedContactDocument = gql`
  mutation addUserTrustedContact($data: UserTrustedContactInput!) {
    addUserTrustedContact(userTrustedContactInput: $data) {
      id
      relationship
      firstName
      lastName
      middleName
      email
      phoneNumber
      phoneNumberType
      addressLine1
      addressLine2
      city
      state
      zipCode
      country
    }
  }
`;
export type AddUserTrustedContactMutationFn = Apollo.MutationFunction<
  AddUserTrustedContactMutation,
  AddUserTrustedContactMutationVariables
>;

/**
 * __useAddUserTrustedContactMutation__
 *
 * To run a mutation, you first call `useAddUserTrustedContactMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddUserTrustedContactMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addUserTrustedContactMutation, { data, loading, error }] = useAddUserTrustedContactMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useAddUserTrustedContactMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddUserTrustedContactMutation,
    AddUserTrustedContactMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AddUserTrustedContactMutation,
    AddUserTrustedContactMutationVariables
  >(AddUserTrustedContactDocument, options);
}
export type AddUserTrustedContactMutationHookResult = ReturnType<
  typeof useAddUserTrustedContactMutation
>;
export type AddUserTrustedContactMutationResult =
  Apollo.MutationResult<AddUserTrustedContactMutation>;
export type AddUserTrustedContactMutationOptions = Apollo.BaseMutationOptions<
  AddUserTrustedContactMutation,
  AddUserTrustedContactMutationVariables
>;
export const SimulationAddTestClearingAccountDocument = gql`
  mutation simulationAddTestClearingAccount(
    $data: ClearingAccountQaDataInput!
  ) {
    simulationAddTestClearingAccount(data: $data)
  }
`;
export type SimulationAddTestClearingAccountMutationFn =
  Apollo.MutationFunction<
    SimulationAddTestClearingAccountMutation,
    SimulationAddTestClearingAccountMutationVariables
  >;

/**
 * __useSimulationAddTestClearingAccountMutation__
 *
 * To run a mutation, you first call `useSimulationAddTestClearingAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSimulationAddTestClearingAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [simulationAddTestClearingAccountMutation, { data, loading, error }] = useSimulationAddTestClearingAccountMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useSimulationAddTestClearingAccountMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SimulationAddTestClearingAccountMutation,
    SimulationAddTestClearingAccountMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SimulationAddTestClearingAccountMutation,
    SimulationAddTestClearingAccountMutationVariables
  >(SimulationAddTestClearingAccountDocument, options);
}
export type SimulationAddTestClearingAccountMutationHookResult = ReturnType<
  typeof useSimulationAddTestClearingAccountMutation
>;
export type SimulationAddTestClearingAccountMutationResult =
  Apollo.MutationResult<SimulationAddTestClearingAccountMutation>;
export type SimulationAddTestClearingAccountMutationOptions =
  Apollo.BaseMutationOptions<
    SimulationAddTestClearingAccountMutation,
    SimulationAddTestClearingAccountMutationVariables
  >;
export const SimulationDeleteTestClearingAccountDocument = gql`
  mutation simulationDeleteTestClearingAccount($clearingAccountId: String!) {
    simulationDeleteTestClearingAccount(clearingAccountId: $clearingAccountId)
  }
`;
export type SimulationDeleteTestClearingAccountMutationFn =
  Apollo.MutationFunction<
    SimulationDeleteTestClearingAccountMutation,
    SimulationDeleteTestClearingAccountMutationVariables
  >;

/**
 * __useSimulationDeleteTestClearingAccountMutation__
 *
 * To run a mutation, you first call `useSimulationDeleteTestClearingAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSimulationDeleteTestClearingAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [simulationDeleteTestClearingAccountMutation, { data, loading, error }] = useSimulationDeleteTestClearingAccountMutation({
 *   variables: {
 *      clearingAccountId: // value for 'clearingAccountId'
 *   },
 * });
 */
export function useSimulationDeleteTestClearingAccountMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SimulationDeleteTestClearingAccountMutation,
    SimulationDeleteTestClearingAccountMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SimulationDeleteTestClearingAccountMutation,
    SimulationDeleteTestClearingAccountMutationVariables
  >(SimulationDeleteTestClearingAccountDocument, options);
}
export type SimulationDeleteTestClearingAccountMutationHookResult = ReturnType<
  typeof useSimulationDeleteTestClearingAccountMutation
>;
export type SimulationDeleteTestClearingAccountMutationResult =
  Apollo.MutationResult<SimulationDeleteTestClearingAccountMutation>;
export type SimulationDeleteTestClearingAccountMutationOptions =
  Apollo.BaseMutationOptions<
    SimulationDeleteTestClearingAccountMutation,
    SimulationDeleteTestClearingAccountMutationVariables
  >;
export const SimulationAddTestUserDocument = gql`
  mutation simulationAddTestUser($data: UserQaDataInput!) {
    simulationAddTestUser(data: $data)
  }
`;
export type SimulationAddTestUserMutationFn = Apollo.MutationFunction<
  SimulationAddTestUserMutation,
  SimulationAddTestUserMutationVariables
>;

/**
 * __useSimulationAddTestUserMutation__
 *
 * To run a mutation, you first call `useSimulationAddTestUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSimulationAddTestUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [simulationAddTestUserMutation, { data, loading, error }] = useSimulationAddTestUserMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useSimulationAddTestUserMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SimulationAddTestUserMutation,
    SimulationAddTestUserMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SimulationAddTestUserMutation,
    SimulationAddTestUserMutationVariables
  >(SimulationAddTestUserDocument, options);
}
export type SimulationAddTestUserMutationHookResult = ReturnType<
  typeof useSimulationAddTestUserMutation
>;
export type SimulationAddTestUserMutationResult =
  Apollo.MutationResult<SimulationAddTestUserMutation>;
export type SimulationAddTestUserMutationOptions = Apollo.BaseMutationOptions<
  SimulationAddTestUserMutation,
  SimulationAddTestUserMutationVariables
>;
export const SimulationDeleteTestUserDocument = gql`
  mutation simulationDeleteTestUser($userId: String!) {
    simulationDeleteTestUser(userId: $userId)
  }
`;
export type SimulationDeleteTestUserMutationFn = Apollo.MutationFunction<
  SimulationDeleteTestUserMutation,
  SimulationDeleteTestUserMutationVariables
>;

/**
 * __useSimulationDeleteTestUserMutation__
 *
 * To run a mutation, you first call `useSimulationDeleteTestUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSimulationDeleteTestUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [simulationDeleteTestUserMutation, { data, loading, error }] = useSimulationDeleteTestUserMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useSimulationDeleteTestUserMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SimulationDeleteTestUserMutation,
    SimulationDeleteTestUserMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SimulationDeleteTestUserMutation,
    SimulationDeleteTestUserMutationVariables
  >(SimulationDeleteTestUserDocument, options);
}
export type SimulationDeleteTestUserMutationHookResult = ReturnType<
  typeof useSimulationDeleteTestUserMutation
>;
export type SimulationDeleteTestUserMutationResult =
  Apollo.MutationResult<SimulationDeleteTestUserMutation>;
export type SimulationDeleteTestUserMutationOptions =
  Apollo.BaseMutationOptions<
    SimulationDeleteTestUserMutation,
    SimulationDeleteTestUserMutationVariables
  >;
export const SimulationAddSimulationEventDocument = gql`
  mutation simulationAddSimulationEvent($event: ApexSimulationEventInput!) {
    simulationAddSimulationEvent(event: $event)
  }
`;
export type SimulationAddSimulationEventMutationFn = Apollo.MutationFunction<
  SimulationAddSimulationEventMutation,
  SimulationAddSimulationEventMutationVariables
>;

/**
 * __useSimulationAddSimulationEventMutation__
 *
 * To run a mutation, you first call `useSimulationAddSimulationEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSimulationAddSimulationEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [simulationAddSimulationEventMutation, { data, loading, error }] = useSimulationAddSimulationEventMutation({
 *   variables: {
 *      event: // value for 'event'
 *   },
 * });
 */
export function useSimulationAddSimulationEventMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SimulationAddSimulationEventMutation,
    SimulationAddSimulationEventMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SimulationAddSimulationEventMutation,
    SimulationAddSimulationEventMutationVariables
  >(SimulationAddSimulationEventDocument, options);
}
export type SimulationAddSimulationEventMutationHookResult = ReturnType<
  typeof useSimulationAddSimulationEventMutation
>;
export type SimulationAddSimulationEventMutationResult =
  Apollo.MutationResult<SimulationAddSimulationEventMutation>;
export type SimulationAddSimulationEventMutationOptions =
  Apollo.BaseMutationOptions<
    SimulationAddSimulationEventMutation,
    SimulationAddSimulationEventMutationVariables
  >;
export const SimulationAcatsTransferOpsActionDocument = gql`
  mutation simulationAcatsTransferOpsAction($input: OpsAcatsTransferInput!) {
    simulationAcatsTransferOpsAction(input: $input)
  }
`;
export type SimulationAcatsTransferOpsActionMutationFn =
  Apollo.MutationFunction<
    SimulationAcatsTransferOpsActionMutation,
    SimulationAcatsTransferOpsActionMutationVariables
  >;

/**
 * __useSimulationAcatsTransferOpsActionMutation__
 *
 * To run a mutation, you first call `useSimulationAcatsTransferOpsActionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSimulationAcatsTransferOpsActionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [simulationAcatsTransferOpsActionMutation, { data, loading, error }] = useSimulationAcatsTransferOpsActionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSimulationAcatsTransferOpsActionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SimulationAcatsTransferOpsActionMutation,
    SimulationAcatsTransferOpsActionMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SimulationAcatsTransferOpsActionMutation,
    SimulationAcatsTransferOpsActionMutationVariables
  >(SimulationAcatsTransferOpsActionDocument, options);
}
export type SimulationAcatsTransferOpsActionMutationHookResult = ReturnType<
  typeof useSimulationAcatsTransferOpsActionMutation
>;
export type SimulationAcatsTransferOpsActionMutationResult =
  Apollo.MutationResult<SimulationAcatsTransferOpsActionMutation>;
export type SimulationAcatsTransferOpsActionMutationOptions =
  Apollo.BaseMutationOptions<
    SimulationAcatsTransferOpsActionMutation,
    SimulationAcatsTransferOpsActionMutationVariables
  >;
export const IntervalProcessDelayedEventsDocument = gql`
  mutation intervalProcessDelayedEvents($eventType: EventType!) {
    intervalProcessDelayedEvents(eventType: $eventType)
  }
`;
export type IntervalProcessDelayedEventsMutationFn = Apollo.MutationFunction<
  IntervalProcessDelayedEventsMutation,
  IntervalProcessDelayedEventsMutationVariables
>;

/**
 * __useIntervalProcessDelayedEventsMutation__
 *
 * To run a mutation, you first call `useIntervalProcessDelayedEventsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useIntervalProcessDelayedEventsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [intervalProcessDelayedEventsMutation, { data, loading, error }] = useIntervalProcessDelayedEventsMutation({
 *   variables: {
 *      eventType: // value for 'eventType'
 *   },
 * });
 */
export function useIntervalProcessDelayedEventsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    IntervalProcessDelayedEventsMutation,
    IntervalProcessDelayedEventsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    IntervalProcessDelayedEventsMutation,
    IntervalProcessDelayedEventsMutationVariables
  >(IntervalProcessDelayedEventsDocument, options);
}
export type IntervalProcessDelayedEventsMutationHookResult = ReturnType<
  typeof useIntervalProcessDelayedEventsMutation
>;
export type IntervalProcessDelayedEventsMutationResult =
  Apollo.MutationResult<IntervalProcessDelayedEventsMutation>;
export type IntervalProcessDelayedEventsMutationOptions =
  Apollo.BaseMutationOptions<
    IntervalProcessDelayedEventsMutation,
    IntervalProcessDelayedEventsMutationVariables
  >;
export const IntervalRefreshStatusesDocument = gql`
  mutation intervalRefreshStatuses($eventType: EventType!) {
    intervalRefreshStatuses(eventType: $eventType)
  }
`;
export type IntervalRefreshStatusesMutationFn = Apollo.MutationFunction<
  IntervalRefreshStatusesMutation,
  IntervalRefreshStatusesMutationVariables
>;

/**
 * __useIntervalRefreshStatusesMutation__
 *
 * To run a mutation, you first call `useIntervalRefreshStatusesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useIntervalRefreshStatusesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [intervalRefreshStatusesMutation, { data, loading, error }] = useIntervalRefreshStatusesMutation({
 *   variables: {
 *      eventType: // value for 'eventType'
 *   },
 * });
 */
export function useIntervalRefreshStatusesMutation(
  baseOptions?: Apollo.MutationHookOptions<
    IntervalRefreshStatusesMutation,
    IntervalRefreshStatusesMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    IntervalRefreshStatusesMutation,
    IntervalRefreshStatusesMutationVariables
  >(IntervalRefreshStatusesDocument, options);
}
export type IntervalRefreshStatusesMutationHookResult = ReturnType<
  typeof useIntervalRefreshStatusesMutation
>;
export type IntervalRefreshStatusesMutationResult =
  Apollo.MutationResult<IntervalRefreshStatusesMutation>;
export type IntervalRefreshStatusesMutationOptions = Apollo.BaseMutationOptions<
  IntervalRefreshStatusesMutation,
  IntervalRefreshStatusesMutationVariables
>;
export const IntervalProcessMarginCallsDocument = gql`
  mutation intervalProcessMarginCalls($date: Date!) {
    intervalProcessMarginCalls(date: $date) {
      result
      newMarginCalls
      resolvedMarginCalls
      openMarginCalls
      errorMessage
    }
  }
`;
export type IntervalProcessMarginCallsMutationFn = Apollo.MutationFunction<
  IntervalProcessMarginCallsMutation,
  IntervalProcessMarginCallsMutationVariables
>;

/**
 * __useIntervalProcessMarginCallsMutation__
 *
 * To run a mutation, you first call `useIntervalProcessMarginCallsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useIntervalProcessMarginCallsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [intervalProcessMarginCallsMutation, { data, loading, error }] = useIntervalProcessMarginCallsMutation({
 *   variables: {
 *      date: // value for 'date'
 *   },
 * });
 */
export function useIntervalProcessMarginCallsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    IntervalProcessMarginCallsMutation,
    IntervalProcessMarginCallsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    IntervalProcessMarginCallsMutation,
    IntervalProcessMarginCallsMutationVariables
  >(IntervalProcessMarginCallsDocument, options);
}
export type IntervalProcessMarginCallsMutationHookResult = ReturnType<
  typeof useIntervalProcessMarginCallsMutation
>;
export type IntervalProcessMarginCallsMutationResult =
  Apollo.MutationResult<IntervalProcessMarginCallsMutation>;
export type IntervalProcessMarginCallsMutationOptions =
  Apollo.BaseMutationOptions<
    IntervalProcessMarginCallsMutation,
    IntervalProcessMarginCallsMutationVariables
  >;
export const IntervalProcessPendingLoansDocument = gql`
  mutation intervalProcessPendingLoans {
    intervalProcessPendingLoans {
      id
    }
  }
`;
export type IntervalProcessPendingLoansMutationFn = Apollo.MutationFunction<
  IntervalProcessPendingLoansMutation,
  IntervalProcessPendingLoansMutationVariables
>;

/**
 * __useIntervalProcessPendingLoansMutation__
 *
 * To run a mutation, you first call `useIntervalProcessPendingLoansMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useIntervalProcessPendingLoansMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [intervalProcessPendingLoansMutation, { data, loading, error }] = useIntervalProcessPendingLoansMutation({
 *   variables: {
 *   },
 * });
 */
export function useIntervalProcessPendingLoansMutation(
  baseOptions?: Apollo.MutationHookOptions<
    IntervalProcessPendingLoansMutation,
    IntervalProcessPendingLoansMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    IntervalProcessPendingLoansMutation,
    IntervalProcessPendingLoansMutationVariables
  >(IntervalProcessPendingLoansDocument, options);
}
export type IntervalProcessPendingLoansMutationHookResult = ReturnType<
  typeof useIntervalProcessPendingLoansMutation
>;
export type IntervalProcessPendingLoansMutationResult =
  Apollo.MutationResult<IntervalProcessPendingLoansMutation>;
export type IntervalProcessPendingLoansMutationOptions =
  Apollo.BaseMutationOptions<
    IntervalProcessPendingLoansMutation,
    IntervalProcessPendingLoansMutationVariables
  >;
export const IntervalGenerateDirectIndexingCandidatesDocument = gql`
  mutation intervalGenerateDirectIndexingCandidates(
    $asOfDate: DateTime
    $dryRun: Boolean
    $forceRebalance: Boolean
    $autoApprove: Boolean
    $subAccountId: ID!
  ) {
    intervalGenerateDirectIndexingCandidates(
      input: {
        asOfDate: $asOfDate
        dryRun: $dryRun
        forceRebalance: $forceRebalance
        autoApprove: $autoApprove
        runForSubAccountIds: [$subAccountId]
      }
    ) {
      subAccountId
      execution {
        id
        status
      }
      reason
    }
  }
`;
export type IntervalGenerateDirectIndexingCandidatesMutationFn =
  Apollo.MutationFunction<
    IntervalGenerateDirectIndexingCandidatesMutation,
    IntervalGenerateDirectIndexingCandidatesMutationVariables
  >;

/**
 * __useIntervalGenerateDirectIndexingCandidatesMutation__
 *
 * To run a mutation, you first call `useIntervalGenerateDirectIndexingCandidatesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useIntervalGenerateDirectIndexingCandidatesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [intervalGenerateDirectIndexingCandidatesMutation, { data, loading, error }] = useIntervalGenerateDirectIndexingCandidatesMutation({
 *   variables: {
 *      asOfDate: // value for 'asOfDate'
 *      dryRun: // value for 'dryRun'
 *      forceRebalance: // value for 'forceRebalance'
 *      autoApprove: // value for 'autoApprove'
 *      subAccountId: // value for 'subAccountId'
 *   },
 * });
 */
export function useIntervalGenerateDirectIndexingCandidatesMutation(
  baseOptions?: Apollo.MutationHookOptions<
    IntervalGenerateDirectIndexingCandidatesMutation,
    IntervalGenerateDirectIndexingCandidatesMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    IntervalGenerateDirectIndexingCandidatesMutation,
    IntervalGenerateDirectIndexingCandidatesMutationVariables
  >(IntervalGenerateDirectIndexingCandidatesDocument, options);
}
export type IntervalGenerateDirectIndexingCandidatesMutationHookResult =
  ReturnType<typeof useIntervalGenerateDirectIndexingCandidatesMutation>;
export type IntervalGenerateDirectIndexingCandidatesMutationResult =
  Apollo.MutationResult<IntervalGenerateDirectIndexingCandidatesMutation>;
export type IntervalGenerateDirectIndexingCandidatesMutationOptions =
  Apollo.BaseMutationOptions<
    IntervalGenerateDirectIndexingCandidatesMutation,
    IntervalGenerateDirectIndexingCandidatesMutationVariables
  >;
export const SimulationDirectIndexExecutionDocument = gql`
  mutation simulationDirectIndexExecution($directIndexTradeExecutionId: ID!) {
    simulationDirectIndexExecution(
      input: { directIndexTradeExecutionId: $directIndexTradeExecutionId }
    )
  }
`;
export type SimulationDirectIndexExecutionMutationFn = Apollo.MutationFunction<
  SimulationDirectIndexExecutionMutation,
  SimulationDirectIndexExecutionMutationVariables
>;

/**
 * __useSimulationDirectIndexExecutionMutation__
 *
 * To run a mutation, you first call `useSimulationDirectIndexExecutionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSimulationDirectIndexExecutionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [simulationDirectIndexExecutionMutation, { data, loading, error }] = useSimulationDirectIndexExecutionMutation({
 *   variables: {
 *      directIndexTradeExecutionId: // value for 'directIndexTradeExecutionId'
 *   },
 * });
 */
export function useSimulationDirectIndexExecutionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SimulationDirectIndexExecutionMutation,
    SimulationDirectIndexExecutionMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SimulationDirectIndexExecutionMutation,
    SimulationDirectIndexExecutionMutationVariables
  >(SimulationDirectIndexExecutionDocument, options);
}
export type SimulationDirectIndexExecutionMutationHookResult = ReturnType<
  typeof useSimulationDirectIndexExecutionMutation
>;
export type SimulationDirectIndexExecutionMutationResult =
  Apollo.MutationResult<SimulationDirectIndexExecutionMutation>;
export type SimulationDirectIndexExecutionMutationOptions =
  Apollo.BaseMutationOptions<
    SimulationDirectIndexExecutionMutation,
    SimulationDirectIndexExecutionMutationVariables
  >;
export const SimulationDeleteSimulationResponsesDocument = gql`
  mutation simulationDeleteSimulationResponses($responseIds: [String!]!) {
    simulationDeleteSimulationResponses(responseIds: $responseIds)
  }
`;
export type SimulationDeleteSimulationResponsesMutationFn =
  Apollo.MutationFunction<
    SimulationDeleteSimulationResponsesMutation,
    SimulationDeleteSimulationResponsesMutationVariables
  >;

/**
 * __useSimulationDeleteSimulationResponsesMutation__
 *
 * To run a mutation, you first call `useSimulationDeleteSimulationResponsesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSimulationDeleteSimulationResponsesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [simulationDeleteSimulationResponsesMutation, { data, loading, error }] = useSimulationDeleteSimulationResponsesMutation({
 *   variables: {
 *      responseIds: // value for 'responseIds'
 *   },
 * });
 */
export function useSimulationDeleteSimulationResponsesMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SimulationDeleteSimulationResponsesMutation,
    SimulationDeleteSimulationResponsesMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SimulationDeleteSimulationResponsesMutation,
    SimulationDeleteSimulationResponsesMutationVariables
  >(SimulationDeleteSimulationResponsesDocument, options);
}
export type SimulationDeleteSimulationResponsesMutationHookResult = ReturnType<
  typeof useSimulationDeleteSimulationResponsesMutation
>;
export type SimulationDeleteSimulationResponsesMutationResult =
  Apollo.MutationResult<SimulationDeleteSimulationResponsesMutation>;
export type SimulationDeleteSimulationResponsesMutationOptions =
  Apollo.BaseMutationOptions<
    SimulationDeleteSimulationResponsesMutation,
    SimulationDeleteSimulationResponsesMutationVariables
  >;
export const SimulationDeleteSimulationResponsesForSimulationIdDocument = gql`
  mutation simulationDeleteSimulationResponsesForSimulationId(
    $simulationId: Int!
  ) {
    simulationDeleteSimulationResponsesForSimulationId(
      simulationId: $simulationId
    )
  }
`;
export type SimulationDeleteSimulationResponsesForSimulationIdMutationFn =
  Apollo.MutationFunction<
    SimulationDeleteSimulationResponsesForSimulationIdMutation,
    SimulationDeleteSimulationResponsesForSimulationIdMutationVariables
  >;

/**
 * __useSimulationDeleteSimulationResponsesForSimulationIdMutation__
 *
 * To run a mutation, you first call `useSimulationDeleteSimulationResponsesForSimulationIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSimulationDeleteSimulationResponsesForSimulationIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [simulationDeleteSimulationResponsesForSimulationIdMutation, { data, loading, error }] = useSimulationDeleteSimulationResponsesForSimulationIdMutation({
 *   variables: {
 *      simulationId: // value for 'simulationId'
 *   },
 * });
 */
export function useSimulationDeleteSimulationResponsesForSimulationIdMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SimulationDeleteSimulationResponsesForSimulationIdMutation,
    SimulationDeleteSimulationResponsesForSimulationIdMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SimulationDeleteSimulationResponsesForSimulationIdMutation,
    SimulationDeleteSimulationResponsesForSimulationIdMutationVariables
  >(SimulationDeleteSimulationResponsesForSimulationIdDocument, options);
}
export type SimulationDeleteSimulationResponsesForSimulationIdMutationHookResult =
  ReturnType<
    typeof useSimulationDeleteSimulationResponsesForSimulationIdMutation
  >;
export type SimulationDeleteSimulationResponsesForSimulationIdMutationResult =
  Apollo.MutationResult<SimulationDeleteSimulationResponsesForSimulationIdMutation>;
export type SimulationDeleteSimulationResponsesForSimulationIdMutationOptions =
  Apollo.BaseMutationOptions<
    SimulationDeleteSimulationResponsesForSimulationIdMutation,
    SimulationDeleteSimulationResponsesForSimulationIdMutationVariables
  >;
export const SimulationAddSimulationResponsesDocument = gql`
  mutation simulationAddSimulationResponses(
    $input: [MarketDataSimulationArgs!]!
  ) {
    simulationAddSimulationResponses(input: $input)
  }
`;
export type SimulationAddSimulationResponsesMutationFn =
  Apollo.MutationFunction<
    SimulationAddSimulationResponsesMutation,
    SimulationAddSimulationResponsesMutationVariables
  >;

/**
 * __useSimulationAddSimulationResponsesMutation__
 *
 * To run a mutation, you first call `useSimulationAddSimulationResponsesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSimulationAddSimulationResponsesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [simulationAddSimulationResponsesMutation, { data, loading, error }] = useSimulationAddSimulationResponsesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSimulationAddSimulationResponsesMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SimulationAddSimulationResponsesMutation,
    SimulationAddSimulationResponsesMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SimulationAddSimulationResponsesMutation,
    SimulationAddSimulationResponsesMutationVariables
  >(SimulationAddSimulationResponsesDocument, options);
}
export type SimulationAddSimulationResponsesMutationHookResult = ReturnType<
  typeof useSimulationAddSimulationResponsesMutation
>;
export type SimulationAddSimulationResponsesMutationResult =
  Apollo.MutationResult<SimulationAddSimulationResponsesMutation>;
export type SimulationAddSimulationResponsesMutationOptions =
  Apollo.BaseMutationOptions<
    SimulationAddSimulationResponsesMutation,
    SimulationAddSimulationResponsesMutationVariables
  >;
export const ApplyInviteCodeDocument = gql`
  mutation applyInviteCode($inviteCode: String!) {
    applyInviteCode(inviteCode: $inviteCode)
  }
`;
export type ApplyInviteCodeMutationFn = Apollo.MutationFunction<
  ApplyInviteCodeMutation,
  ApplyInviteCodeMutationVariables
>;

/**
 * __useApplyInviteCodeMutation__
 *
 * To run a mutation, you first call `useApplyInviteCodeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useApplyInviteCodeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [applyInviteCodeMutation, { data, loading, error }] = useApplyInviteCodeMutation({
 *   variables: {
 *      inviteCode: // value for 'inviteCode'
 *   },
 * });
 */
export function useApplyInviteCodeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ApplyInviteCodeMutation,
    ApplyInviteCodeMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ApplyInviteCodeMutation,
    ApplyInviteCodeMutationVariables
  >(ApplyInviteCodeDocument, options);
}
export type ApplyInviteCodeMutationHookResult = ReturnType<
  typeof useApplyInviteCodeMutation
>;
export type ApplyInviteCodeMutationResult =
  Apollo.MutationResult<ApplyInviteCodeMutation>;
export type ApplyInviteCodeMutationOptions = Apollo.BaseMutationOptions<
  ApplyInviteCodeMutation,
  ApplyInviteCodeMutationVariables
>;
export const CancelAchDepositDocument = gql`
  mutation cancelAchDeposit($transferId: ID!) {
    cancelAchDeposit(cancelAchDepositInput: { transferId: $transferId }) {
      status
    }
  }
`;
export type CancelAchDepositMutationFn = Apollo.MutationFunction<
  CancelAchDepositMutation,
  CancelAchDepositMutationVariables
>;

/**
 * __useCancelAchDepositMutation__
 *
 * To run a mutation, you first call `useCancelAchDepositMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelAchDepositMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelAchDepositMutation, { data, loading, error }] = useCancelAchDepositMutation({
 *   variables: {
 *      transferId: // value for 'transferId'
 *   },
 * });
 */
export function useCancelAchDepositMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CancelAchDepositMutation,
    CancelAchDepositMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CancelAchDepositMutation,
    CancelAchDepositMutationVariables
  >(CancelAchDepositDocument, options);
}
export type CancelAchDepositMutationHookResult = ReturnType<
  typeof useCancelAchDepositMutation
>;
export type CancelAchDepositMutationResult =
  Apollo.MutationResult<CancelAchDepositMutation>;
export type CancelAchDepositMutationOptions = Apollo.BaseMutationOptions<
  CancelAchDepositMutation,
  CancelAchDepositMutationVariables
>;
export const CancelAchWithdrawalDocument = gql`
  mutation cancelAchWithdrawal($transferId: ID!) {
    cancelAchWithdrawal(cancelAchWithdrawalInput: { transferId: $transferId }) {
      status
    }
  }
`;
export type CancelAchWithdrawalMutationFn = Apollo.MutationFunction<
  CancelAchWithdrawalMutation,
  CancelAchWithdrawalMutationVariables
>;

/**
 * __useCancelAchWithdrawalMutation__
 *
 * To run a mutation, you first call `useCancelAchWithdrawalMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelAchWithdrawalMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelAchWithdrawalMutation, { data, loading, error }] = useCancelAchWithdrawalMutation({
 *   variables: {
 *      transferId: // value for 'transferId'
 *   },
 * });
 */
export function useCancelAchWithdrawalMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CancelAchWithdrawalMutation,
    CancelAchWithdrawalMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CancelAchWithdrawalMutation,
    CancelAchWithdrawalMutationVariables
  >(CancelAchWithdrawalDocument, options);
}
export type CancelAchWithdrawalMutationHookResult = ReturnType<
  typeof useCancelAchWithdrawalMutation
>;
export type CancelAchWithdrawalMutationResult =
  Apollo.MutationResult<CancelAchWithdrawalMutation>;
export type CancelAchWithdrawalMutationOptions = Apollo.BaseMutationOptions<
  CancelAchWithdrawalMutation,
  CancelAchWithdrawalMutationVariables
>;
export const CancelLiquidateDirectIndexRequestDocument = gql`
  mutation cancelLiquidateDirectIndexRequest($requestId: ID!) {
    cancelLiquidateDirectIndexPortfolio(
      input: { liquidateDirectIndexPortfolioRequestId: $requestId }
    ) {
      ...LiquidateDirectIndexPortfolioRequest
    }
  }
  ${LiquidateDirectIndexPortfolioRequestFragmentDoc}
`;
export type CancelLiquidateDirectIndexRequestMutationFn =
  Apollo.MutationFunction<
    CancelLiquidateDirectIndexRequestMutation,
    CancelLiquidateDirectIndexRequestMutationVariables
  >;

/**
 * __useCancelLiquidateDirectIndexRequestMutation__
 *
 * To run a mutation, you first call `useCancelLiquidateDirectIndexRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelLiquidateDirectIndexRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelLiquidateDirectIndexRequestMutation, { data, loading, error }] = useCancelLiquidateDirectIndexRequestMutation({
 *   variables: {
 *      requestId: // value for 'requestId'
 *   },
 * });
 */
export function useCancelLiquidateDirectIndexRequestMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CancelLiquidateDirectIndexRequestMutation,
    CancelLiquidateDirectIndexRequestMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CancelLiquidateDirectIndexRequestMutation,
    CancelLiquidateDirectIndexRequestMutationVariables
  >(CancelLiquidateDirectIndexRequestDocument, options);
}
export type CancelLiquidateDirectIndexRequestMutationHookResult = ReturnType<
  typeof useCancelLiquidateDirectIndexRequestMutation
>;
export type CancelLiquidateDirectIndexRequestMutationResult =
  Apollo.MutationResult<CancelLiquidateDirectIndexRequestMutation>;
export type CancelLiquidateDirectIndexRequestMutationOptions =
  Apollo.BaseMutationOptions<
    CancelLiquidateDirectIndexRequestMutation,
    CancelLiquidateDirectIndexRequestMutationVariables
  >;
export const CancelPendingLoanDocument = gql`
  mutation cancelPendingLoan($id: ID!) {
    cancelPendingLoan(id: $id) {
      id
    }
  }
`;
export type CancelPendingLoanMutationFn = Apollo.MutationFunction<
  CancelPendingLoanMutation,
  CancelPendingLoanMutationVariables
>;

/**
 * __useCancelPendingLoanMutation__
 *
 * To run a mutation, you first call `useCancelPendingLoanMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelPendingLoanMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelPendingLoanMutation, { data, loading, error }] = useCancelPendingLoanMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCancelPendingLoanMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CancelPendingLoanMutation,
    CancelPendingLoanMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CancelPendingLoanMutation,
    CancelPendingLoanMutationVariables
  >(CancelPendingLoanDocument, options);
}
export type CancelPendingLoanMutationHookResult = ReturnType<
  typeof useCancelPendingLoanMutation
>;
export type CancelPendingLoanMutationResult =
  Apollo.MutationResult<CancelPendingLoanMutation>;
export type CancelPendingLoanMutationOptions = Apollo.BaseMutationOptions<
  CancelPendingLoanMutation,
  CancelPendingLoanMutationVariables
>;
export const CancelTradeOrderDocument = gql`
  mutation cancelTradeOrder($orderId: ID!) {
    cancelTradeOrder(cancelOrderInput: { orderId: $orderId }) {
      order {
        id
        userId
        clearingAccountId
        side
        orderType
        securityId
        quantityType
        quantity
        maxSellQuantity
        limitPrice
        limitDuration
        orderPrice
        eventTime
        orderStatus
      }
    }
  }
`;
export type CancelTradeOrderMutationFn = Apollo.MutationFunction<
  CancelTradeOrderMutation,
  CancelTradeOrderMutationVariables
>;

/**
 * __useCancelTradeOrderMutation__
 *
 * To run a mutation, you first call `useCancelTradeOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelTradeOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelTradeOrderMutation, { data, loading, error }] = useCancelTradeOrderMutation({
 *   variables: {
 *      orderId: // value for 'orderId'
 *   },
 * });
 */
export function useCancelTradeOrderMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CancelTradeOrderMutation,
    CancelTradeOrderMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CancelTradeOrderMutation,
    CancelTradeOrderMutationVariables
  >(CancelTradeOrderDocument, options);
}
export type CancelTradeOrderMutationHookResult = ReturnType<
  typeof useCancelTradeOrderMutation
>;
export type CancelTradeOrderMutationResult =
  Apollo.MutationResult<CancelTradeOrderMutation>;
export type CancelTradeOrderMutationOptions = Apollo.BaseMutationOptions<
  CancelTradeOrderMutation,
  CancelTradeOrderMutationVariables
>;
export const CancelWireWithdrawalDocument = gql`
  mutation cancelWireWithdrawal($transferId: ID!, $clearingAccountId: ID!) {
    cancelWireWithdrawal(
      cancelWireWithdrawalInput: {
        transferId: $transferId
        clearingAccountId: $clearingAccountId
      }
    ) {
      cashTransfer {
        status
      }
    }
  }
`;
export type CancelWireWithdrawalMutationFn = Apollo.MutationFunction<
  CancelWireWithdrawalMutation,
  CancelWireWithdrawalMutationVariables
>;

/**
 * __useCancelWireWithdrawalMutation__
 *
 * To run a mutation, you first call `useCancelWireWithdrawalMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelWireWithdrawalMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelWireWithdrawalMutation, { data, loading, error }] = useCancelWireWithdrawalMutation({
 *   variables: {
 *      transferId: // value for 'transferId'
 *      clearingAccountId: // value for 'clearingAccountId'
 *   },
 * });
 */
export function useCancelWireWithdrawalMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CancelWireWithdrawalMutation,
    CancelWireWithdrawalMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CancelWireWithdrawalMutation,
    CancelWireWithdrawalMutationVariables
  >(CancelWireWithdrawalDocument, options);
}
export type CancelWireWithdrawalMutationHookResult = ReturnType<
  typeof useCancelWireWithdrawalMutation
>;
export type CancelWireWithdrawalMutationResult =
  Apollo.MutationResult<CancelWireWithdrawalMutation>;
export type CancelWireWithdrawalMutationOptions = Apollo.BaseMutationOptions<
  CancelWireWithdrawalMutation,
  CancelWireWithdrawalMutationVariables
>;
export const CashTransferToDirectIndexPortfolioDocument = gql`
  mutation cashTransferToDirectIndexPortfolio(
    $input: CashTransferToDirectIndexPortfolioInput!
  ) {
    cashTransferToDirectIndexPortfolio(input: $input)
  }
`;
export type CashTransferToDirectIndexPortfolioMutationFn =
  Apollo.MutationFunction<
    CashTransferToDirectIndexPortfolioMutation,
    CashTransferToDirectIndexPortfolioMutationVariables
  >;

/**
 * __useCashTransferToDirectIndexPortfolioMutation__
 *
 * To run a mutation, you first call `useCashTransferToDirectIndexPortfolioMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCashTransferToDirectIndexPortfolioMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cashTransferToDirectIndexPortfolioMutation, { data, loading, error }] = useCashTransferToDirectIndexPortfolioMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCashTransferToDirectIndexPortfolioMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CashTransferToDirectIndexPortfolioMutation,
    CashTransferToDirectIndexPortfolioMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CashTransferToDirectIndexPortfolioMutation,
    CashTransferToDirectIndexPortfolioMutationVariables
  >(CashTransferToDirectIndexPortfolioDocument, options);
}
export type CashTransferToDirectIndexPortfolioMutationHookResult = ReturnType<
  typeof useCashTransferToDirectIndexPortfolioMutation
>;
export type CashTransferToDirectIndexPortfolioMutationResult =
  Apollo.MutationResult<CashTransferToDirectIndexPortfolioMutation>;
export type CashTransferToDirectIndexPortfolioMutationOptions =
  Apollo.BaseMutationOptions<
    CashTransferToDirectIndexPortfolioMutation,
    CashTransferToDirectIndexPortfolioMutationVariables
  >;
export const CloseClearingHouseAccountDocument = gql`
  mutation closeClearingHouseAccount($accountId: ID!) {
    requestClearingHouseAccountClose(accountId: $accountId) {
      status
    }
  }
`;
export type CloseClearingHouseAccountMutationFn = Apollo.MutationFunction<
  CloseClearingHouseAccountMutation,
  CloseClearingHouseAccountMutationVariables
>;

/**
 * __useCloseClearingHouseAccountMutation__
 *
 * To run a mutation, you first call `useCloseClearingHouseAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCloseClearingHouseAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [closeClearingHouseAccountMutation, { data, loading, error }] = useCloseClearingHouseAccountMutation({
 *   variables: {
 *      accountId: // value for 'accountId'
 *   },
 * });
 */
export function useCloseClearingHouseAccountMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CloseClearingHouseAccountMutation,
    CloseClearingHouseAccountMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CloseClearingHouseAccountMutation,
    CloseClearingHouseAccountMutationVariables
  >(CloseClearingHouseAccountDocument, options);
}
export type CloseClearingHouseAccountMutationHookResult = ReturnType<
  typeof useCloseClearingHouseAccountMutation
>;
export type CloseClearingHouseAccountMutationResult =
  Apollo.MutationResult<CloseClearingHouseAccountMutation>;
export type CloseClearingHouseAccountMutationOptions =
  Apollo.BaseMutationOptions<
    CloseClearingHouseAccountMutation,
    CloseClearingHouseAccountMutationVariables
  >;
export const CreateClearingHouseAccountDocument = gql`
  mutation createClearingHouseAccount($userId: ID!) {
    createClearingHouseAccountForIndividual(userId: $userId) {
      id
    }
  }
`;
export type CreateClearingHouseAccountMutationFn = Apollo.MutationFunction<
  CreateClearingHouseAccountMutation,
  CreateClearingHouseAccountMutationVariables
>;

/**
 * __useCreateClearingHouseAccountMutation__
 *
 * To run a mutation, you first call `useCreateClearingHouseAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateClearingHouseAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createClearingHouseAccountMutation, { data, loading, error }] = useCreateClearingHouseAccountMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useCreateClearingHouseAccountMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateClearingHouseAccountMutation,
    CreateClearingHouseAccountMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateClearingHouseAccountMutation,
    CreateClearingHouseAccountMutationVariables
  >(CreateClearingHouseAccountDocument, options);
}
export type CreateClearingHouseAccountMutationHookResult = ReturnType<
  typeof useCreateClearingHouseAccountMutation
>;
export type CreateClearingHouseAccountMutationResult =
  Apollo.MutationResult<CreateClearingHouseAccountMutation>;
export type CreateClearingHouseAccountMutationOptions =
  Apollo.BaseMutationOptions<
    CreateClearingHouseAccountMutation,
    CreateClearingHouseAccountMutationVariables
  >;
export const CreateClearingHouseAccountForTrustDocument = gql`
  mutation createClearingHouseAccountForTrust($userId: ID!) {
    createClearingHouseAccountForTrust(userId: $userId) {
      id
    }
  }
`;
export type CreateClearingHouseAccountForTrustMutationFn =
  Apollo.MutationFunction<
    CreateClearingHouseAccountForTrustMutation,
    CreateClearingHouseAccountForTrustMutationVariables
  >;

/**
 * __useCreateClearingHouseAccountForTrustMutation__
 *
 * To run a mutation, you first call `useCreateClearingHouseAccountForTrustMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateClearingHouseAccountForTrustMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createClearingHouseAccountForTrustMutation, { data, loading, error }] = useCreateClearingHouseAccountForTrustMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useCreateClearingHouseAccountForTrustMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateClearingHouseAccountForTrustMutation,
    CreateClearingHouseAccountForTrustMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateClearingHouseAccountForTrustMutation,
    CreateClearingHouseAccountForTrustMutationVariables
  >(CreateClearingHouseAccountForTrustDocument, options);
}
export type CreateClearingHouseAccountForTrustMutationHookResult = ReturnType<
  typeof useCreateClearingHouseAccountForTrustMutation
>;
export type CreateClearingHouseAccountForTrustMutationResult =
  Apollo.MutationResult<CreateClearingHouseAccountForTrustMutation>;
export type CreateClearingHouseAccountForTrustMutationOptions =
  Apollo.BaseMutationOptions<
    CreateClearingHouseAccountForTrustMutation,
    CreateClearingHouseAccountForTrustMutationVariables
  >;
export const CreateDirectIndexSubAccountDocument = gql`
  mutation createDirectIndexSubAccount(
    $input: CreateDirectIndexSubAccountInput!
  ) {
    createDirectIndexSubAccount(input: $input) {
      ...ClearingAccount
    }
  }
  ${ClearingAccountFragmentDoc}
`;
export type CreateDirectIndexSubAccountMutationFn = Apollo.MutationFunction<
  CreateDirectIndexSubAccountMutation,
  CreateDirectIndexSubAccountMutationVariables
>;

/**
 * __useCreateDirectIndexSubAccountMutation__
 *
 * To run a mutation, you first call `useCreateDirectIndexSubAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateDirectIndexSubAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createDirectIndexSubAccountMutation, { data, loading, error }] = useCreateDirectIndexSubAccountMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateDirectIndexSubAccountMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateDirectIndexSubAccountMutation,
    CreateDirectIndexSubAccountMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateDirectIndexSubAccountMutation,
    CreateDirectIndexSubAccountMutationVariables
  >(CreateDirectIndexSubAccountDocument, options);
}
export type CreateDirectIndexSubAccountMutationHookResult = ReturnType<
  typeof useCreateDirectIndexSubAccountMutation
>;
export type CreateDirectIndexSubAccountMutationResult =
  Apollo.MutationResult<CreateDirectIndexSubAccountMutation>;
export type CreateDirectIndexSubAccountMutationOptions =
  Apollo.BaseMutationOptions<
    CreateDirectIndexSubAccountMutation,
    CreateDirectIndexSubAccountMutationVariables
  >;
export const CreateDividendPreferenceDocument = gql`
  mutation createDividendPreference($input: DividendPreferenceInput!) {
    createDividendPreference(input: $input) {
      ...DividendPreference
    }
  }
  ${DividendPreferenceFragmentDoc}
`;
export type CreateDividendPreferenceMutationFn = Apollo.MutationFunction<
  CreateDividendPreferenceMutation,
  CreateDividendPreferenceMutationVariables
>;

/**
 * __useCreateDividendPreferenceMutation__
 *
 * To run a mutation, you first call `useCreateDividendPreferenceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateDividendPreferenceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createDividendPreferenceMutation, { data, loading, error }] = useCreateDividendPreferenceMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateDividendPreferenceMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateDividendPreferenceMutation,
    CreateDividendPreferenceMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateDividendPreferenceMutation,
    CreateDividendPreferenceMutationVariables
  >(CreateDividendPreferenceDocument, options);
}
export type CreateDividendPreferenceMutationHookResult = ReturnType<
  typeof useCreateDividendPreferenceMutation
>;
export type CreateDividendPreferenceMutationResult =
  Apollo.MutationResult<CreateDividendPreferenceMutation>;
export type CreateDividendPreferenceMutationOptions =
  Apollo.BaseMutationOptions<
    CreateDividendPreferenceMutation,
    CreateDividendPreferenceMutationVariables
  >;
export const CreateLinkedBrokerageAccountDocument = gql`
  mutation createLinkedBrokerageAccount(
    $accountNumber: String!
    $name: String!
    $nsccNumber: String!
  ) {
    createLinkedBrokerageAccount(
      input: {
        accountNumber: $accountNumber
        name: $name
        nsccNumber: $nsccNumber
      }
    )
  }
`;
export type CreateLinkedBrokerageAccountMutationFn = Apollo.MutationFunction<
  CreateLinkedBrokerageAccountMutation,
  CreateLinkedBrokerageAccountMutationVariables
>;

/**
 * __useCreateLinkedBrokerageAccountMutation__
 *
 * To run a mutation, you first call `useCreateLinkedBrokerageAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateLinkedBrokerageAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createLinkedBrokerageAccountMutation, { data, loading, error }] = useCreateLinkedBrokerageAccountMutation({
 *   variables: {
 *      accountNumber: // value for 'accountNumber'
 *      name: // value for 'name'
 *      nsccNumber: // value for 'nsccNumber'
 *   },
 * });
 */
export function useCreateLinkedBrokerageAccountMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateLinkedBrokerageAccountMutation,
    CreateLinkedBrokerageAccountMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateLinkedBrokerageAccountMutation,
    CreateLinkedBrokerageAccountMutationVariables
  >(CreateLinkedBrokerageAccountDocument, options);
}
export type CreateLinkedBrokerageAccountMutationHookResult = ReturnType<
  typeof useCreateLinkedBrokerageAccountMutation
>;
export type CreateLinkedBrokerageAccountMutationResult =
  Apollo.MutationResult<CreateLinkedBrokerageAccountMutation>;
export type CreateLinkedBrokerageAccountMutationOptions =
  Apollo.BaseMutationOptions<
    CreateLinkedBrokerageAccountMutation,
    CreateLinkedBrokerageAccountMutationVariables
  >;
export const CreateLinkedBrokerageAccountFromPlaidAccountDocument = gql`
  mutation createLinkedBrokerageAccountFromPlaidAccount(
    $plaidAccountId: ID!
    $accountNumber: String!
  ) {
    createLinkedBrokerageAccountFromPlaidAccount(
      plaidAccountId: $plaidAccountId
      accountNumber: $accountNumber
    )
  }
`;
export type CreateLinkedBrokerageAccountFromPlaidAccountMutationFn =
  Apollo.MutationFunction<
    CreateLinkedBrokerageAccountFromPlaidAccountMutation,
    CreateLinkedBrokerageAccountFromPlaidAccountMutationVariables
  >;

/**
 * __useCreateLinkedBrokerageAccountFromPlaidAccountMutation__
 *
 * To run a mutation, you first call `useCreateLinkedBrokerageAccountFromPlaidAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateLinkedBrokerageAccountFromPlaidAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createLinkedBrokerageAccountFromPlaidAccountMutation, { data, loading, error }] = useCreateLinkedBrokerageAccountFromPlaidAccountMutation({
 *   variables: {
 *      plaidAccountId: // value for 'plaidAccountId'
 *      accountNumber: // value for 'accountNumber'
 *   },
 * });
 */
export function useCreateLinkedBrokerageAccountFromPlaidAccountMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateLinkedBrokerageAccountFromPlaidAccountMutation,
    CreateLinkedBrokerageAccountFromPlaidAccountMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateLinkedBrokerageAccountFromPlaidAccountMutation,
    CreateLinkedBrokerageAccountFromPlaidAccountMutationVariables
  >(CreateLinkedBrokerageAccountFromPlaidAccountDocument, options);
}
export type CreateLinkedBrokerageAccountFromPlaidAccountMutationHookResult =
  ReturnType<typeof useCreateLinkedBrokerageAccountFromPlaidAccountMutation>;
export type CreateLinkedBrokerageAccountFromPlaidAccountMutationResult =
  Apollo.MutationResult<CreateLinkedBrokerageAccountFromPlaidAccountMutation>;
export type CreateLinkedBrokerageAccountFromPlaidAccountMutationOptions =
  Apollo.BaseMutationOptions<
    CreateLinkedBrokerageAccountFromPlaidAccountMutation,
    CreateLinkedBrokerageAccountFromPlaidAccountMutationVariables
  >;
export const CreatePendingLoanDocument = gql`
  mutation createPendingLoan($input: CreatePendingLoanInput!) {
    createPendingLoan(input: $input) {
      id
      amount
    }
  }
`;
export type CreatePendingLoanMutationFn = Apollo.MutationFunction<
  CreatePendingLoanMutation,
  CreatePendingLoanMutationVariables
>;

/**
 * __useCreatePendingLoanMutation__
 *
 * To run a mutation, you first call `useCreatePendingLoanMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePendingLoanMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPendingLoanMutation, { data, loading, error }] = useCreatePendingLoanMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreatePendingLoanMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreatePendingLoanMutation,
    CreatePendingLoanMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreatePendingLoanMutation,
    CreatePendingLoanMutationVariables
  >(CreatePendingLoanDocument, options);
}
export type CreatePendingLoanMutationHookResult = ReturnType<
  typeof useCreatePendingLoanMutation
>;
export type CreatePendingLoanMutationResult =
  Apollo.MutationResult<CreatePendingLoanMutation>;
export type CreatePendingLoanMutationOptions = Apollo.BaseMutationOptions<
  CreatePendingLoanMutation,
  CreatePendingLoanMutationVariables
>;
export const CreatePersonalDetailsAndAddressDocument = gql`
  mutation createPersonalDetailsAndAddress(
    $userDetails: UserPersonalDetailsInput!
    $userAddress: GenericAddressInput!
  ) {
    addUserPersonalDetails(userPersonalDetailsInput: $userDetails) {
      id
      firstName
      lastName
      middleName
    }
    addUserAddress(userAddressInput: $userAddress) {
      id
      addressType
      addressLine1
      addressLine2
      city
      state
      zipCode
      country
    }
  }
`;
export type CreatePersonalDetailsAndAddressMutationFn = Apollo.MutationFunction<
  CreatePersonalDetailsAndAddressMutation,
  CreatePersonalDetailsAndAddressMutationVariables
>;

/**
 * __useCreatePersonalDetailsAndAddressMutation__
 *
 * To run a mutation, you first call `useCreatePersonalDetailsAndAddressMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePersonalDetailsAndAddressMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPersonalDetailsAndAddressMutation, { data, loading, error }] = useCreatePersonalDetailsAndAddressMutation({
 *   variables: {
 *      userDetails: // value for 'userDetails'
 *      userAddress: // value for 'userAddress'
 *   },
 * });
 */
export function useCreatePersonalDetailsAndAddressMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreatePersonalDetailsAndAddressMutation,
    CreatePersonalDetailsAndAddressMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreatePersonalDetailsAndAddressMutation,
    CreatePersonalDetailsAndAddressMutationVariables
  >(CreatePersonalDetailsAndAddressDocument, options);
}
export type CreatePersonalDetailsAndAddressMutationHookResult = ReturnType<
  typeof useCreatePersonalDetailsAndAddressMutation
>;
export type CreatePersonalDetailsAndAddressMutationResult =
  Apollo.MutationResult<CreatePersonalDetailsAndAddressMutation>;
export type CreatePersonalDetailsAndAddressMutationOptions =
  Apollo.BaseMutationOptions<
    CreatePersonalDetailsAndAddressMutation,
    CreatePersonalDetailsAndAddressMutationVariables
  >;
export const CreateScheduledDepositConfigDocument = gql`
  mutation createScheduledDepositConfig(
    $clearingAccountId: ID!
    $subAccountId: ID!
    $input: ScheduledDepositConfigInput!
  ) {
    createScheduledDepositConfig(
      clearingAccountId: $clearingAccountId
      subAccountId: $subAccountId
      input: $input
    ) {
      ...ScheduledDepositConfig
    }
  }
  ${ScheduledDepositConfigFragmentDoc}
`;
export type CreateScheduledDepositConfigMutationFn = Apollo.MutationFunction<
  CreateScheduledDepositConfigMutation,
  CreateScheduledDepositConfigMutationVariables
>;

/**
 * __useCreateScheduledDepositConfigMutation__
 *
 * To run a mutation, you first call `useCreateScheduledDepositConfigMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateScheduledDepositConfigMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createScheduledDepositConfigMutation, { data, loading, error }] = useCreateScheduledDepositConfigMutation({
 *   variables: {
 *      clearingAccountId: // value for 'clearingAccountId'
 *      subAccountId: // value for 'subAccountId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateScheduledDepositConfigMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateScheduledDepositConfigMutation,
    CreateScheduledDepositConfigMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateScheduledDepositConfigMutation,
    CreateScheduledDepositConfigMutationVariables
  >(CreateScheduledDepositConfigDocument, options);
}
export type CreateScheduledDepositConfigMutationHookResult = ReturnType<
  typeof useCreateScheduledDepositConfigMutation
>;
export type CreateScheduledDepositConfigMutationResult =
  Apollo.MutationResult<CreateScheduledDepositConfigMutation>;
export type CreateScheduledDepositConfigMutationOptions =
  Apollo.BaseMutationOptions<
    CreateScheduledDepositConfigMutation,
    CreateScheduledDepositConfigMutationVariables
  >;
export const CreateStockTransferDocument = gql`
  mutation createStockTransfer(
    $input: AcatsCreateTransferInput!
    $simulatorConfig: ApexAcatsSimulatorConfig
  ) {
    acatsCreateTransfer(input: $input, simulatorConfig: $simulatorConfig) {
      acatsTransferId
      errors {
        errorCode
        message
      }
    }
  }
`;
export type CreateStockTransferMutationFn = Apollo.MutationFunction<
  CreateStockTransferMutation,
  CreateStockTransferMutationVariables
>;

/**
 * __useCreateStockTransferMutation__
 *
 * To run a mutation, you first call `useCreateStockTransferMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateStockTransferMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createStockTransferMutation, { data, loading, error }] = useCreateStockTransferMutation({
 *   variables: {
 *      input: // value for 'input'
 *      simulatorConfig: // value for 'simulatorConfig'
 *   },
 * });
 */
export function useCreateStockTransferMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateStockTransferMutation,
    CreateStockTransferMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateStockTransferMutation,
    CreateStockTransferMutationVariables
  >(CreateStockTransferDocument, options);
}
export type CreateStockTransferMutationHookResult = ReturnType<
  typeof useCreateStockTransferMutation
>;
export type CreateStockTransferMutationResult =
  Apollo.MutationResult<CreateStockTransferMutation>;
export type CreateStockTransferMutationOptions = Apollo.BaseMutationOptions<
  CreateStockTransferMutation,
  CreateStockTransferMutationVariables
>;
export const DeleteBusinessEntityIndividualDocument = gql`
  mutation deleteBusinessEntityIndividual($id: ID!) {
    deleteBusinessEntityIndividual(input: { id: $id })
  }
`;
export type DeleteBusinessEntityIndividualMutationFn = Apollo.MutationFunction<
  DeleteBusinessEntityIndividualMutation,
  DeleteBusinessEntityIndividualMutationVariables
>;

/**
 * __useDeleteBusinessEntityIndividualMutation__
 *
 * To run a mutation, you first call `useDeleteBusinessEntityIndividualMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteBusinessEntityIndividualMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteBusinessEntityIndividualMutation, { data, loading, error }] = useDeleteBusinessEntityIndividualMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteBusinessEntityIndividualMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteBusinessEntityIndividualMutation,
    DeleteBusinessEntityIndividualMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteBusinessEntityIndividualMutation,
    DeleteBusinessEntityIndividualMutationVariables
  >(DeleteBusinessEntityIndividualDocument, options);
}
export type DeleteBusinessEntityIndividualMutationHookResult = ReturnType<
  typeof useDeleteBusinessEntityIndividualMutation
>;
export type DeleteBusinessEntityIndividualMutationResult =
  Apollo.MutationResult<DeleteBusinessEntityIndividualMutation>;
export type DeleteBusinessEntityIndividualMutationOptions =
  Apollo.BaseMutationOptions<
    DeleteBusinessEntityIndividualMutation,
    DeleteBusinessEntityIndividualMutationVariables
  >;
export const DeleteDepositAccountDocument = gql`
  mutation deleteDepositAccount($id: ID!) {
    deleteDepositAccount(depositAccountId: $id) {
      id
    }
  }
`;
export type DeleteDepositAccountMutationFn = Apollo.MutationFunction<
  DeleteDepositAccountMutation,
  DeleteDepositAccountMutationVariables
>;

/**
 * __useDeleteDepositAccountMutation__
 *
 * To run a mutation, you first call `useDeleteDepositAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteDepositAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteDepositAccountMutation, { data, loading, error }] = useDeleteDepositAccountMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteDepositAccountMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteDepositAccountMutation,
    DeleteDepositAccountMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteDepositAccountMutation,
    DeleteDepositAccountMutationVariables
  >(DeleteDepositAccountDocument, options);
}
export type DeleteDepositAccountMutationHookResult = ReturnType<
  typeof useDeleteDepositAccountMutation
>;
export type DeleteDepositAccountMutationResult =
  Apollo.MutationResult<DeleteDepositAccountMutation>;
export type DeleteDepositAccountMutationOptions = Apollo.BaseMutationOptions<
  DeleteDepositAccountMutation,
  DeleteDepositAccountMutationVariables
>;
export const DeleteDocumentDocument = gql`
  mutation deleteDocument($id: ID!) {
    deleteDocument(id: $id)
  }
`;
export type DeleteDocumentMutationFn = Apollo.MutationFunction<
  DeleteDocumentMutation,
  DeleteDocumentMutationVariables
>;

/**
 * __useDeleteDocumentMutation__
 *
 * To run a mutation, you first call `useDeleteDocumentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteDocumentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteDocumentMutation, { data, loading, error }] = useDeleteDocumentMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteDocumentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteDocumentMutation,
    DeleteDocumentMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteDocumentMutation,
    DeleteDocumentMutationVariables
  >(DeleteDocumentDocument, options);
}
export type DeleteDocumentMutationHookResult = ReturnType<
  typeof useDeleteDocumentMutation
>;
export type DeleteDocumentMutationResult =
  Apollo.MutationResult<DeleteDocumentMutation>;
export type DeleteDocumentMutationOptions = Apollo.BaseMutationOptions<
  DeleteDocumentMutation,
  DeleteDocumentMutationVariables
>;
export const DeleteLinkedBrokerageAccountDocument = gql`
  mutation deleteLinkedBrokerageAccount($id: ID!) {
    deleteLinkedBrokerageAccount(linkedBrokerageAccountId: $id)
  }
`;
export type DeleteLinkedBrokerageAccountMutationFn = Apollo.MutationFunction<
  DeleteLinkedBrokerageAccountMutation,
  DeleteLinkedBrokerageAccountMutationVariables
>;

/**
 * __useDeleteLinkedBrokerageAccountMutation__
 *
 * To run a mutation, you first call `useDeleteLinkedBrokerageAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteLinkedBrokerageAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteLinkedBrokerageAccountMutation, { data, loading, error }] = useDeleteLinkedBrokerageAccountMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteLinkedBrokerageAccountMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteLinkedBrokerageAccountMutation,
    DeleteLinkedBrokerageAccountMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteLinkedBrokerageAccountMutation,
    DeleteLinkedBrokerageAccountMutationVariables
  >(DeleteLinkedBrokerageAccountDocument, options);
}
export type DeleteLinkedBrokerageAccountMutationHookResult = ReturnType<
  typeof useDeleteLinkedBrokerageAccountMutation
>;
export type DeleteLinkedBrokerageAccountMutationResult =
  Apollo.MutationResult<DeleteLinkedBrokerageAccountMutation>;
export type DeleteLinkedBrokerageAccountMutationOptions =
  Apollo.BaseMutationOptions<
    DeleteLinkedBrokerageAccountMutation,
    DeleteLinkedBrokerageAccountMutationVariables
  >;
export const DeletePlaidAccountDocument = gql`
  mutation deletePlaidAccount($id: ID!) {
    deletePlaidAccount(plaidAccountId: $id)
  }
`;
export type DeletePlaidAccountMutationFn = Apollo.MutationFunction<
  DeletePlaidAccountMutation,
  DeletePlaidAccountMutationVariables
>;

/**
 * __useDeletePlaidAccountMutation__
 *
 * To run a mutation, you first call `useDeletePlaidAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePlaidAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePlaidAccountMutation, { data, loading, error }] = useDeletePlaidAccountMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeletePlaidAccountMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeletePlaidAccountMutation,
    DeletePlaidAccountMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeletePlaidAccountMutation,
    DeletePlaidAccountMutationVariables
  >(DeletePlaidAccountDocument, options);
}
export type DeletePlaidAccountMutationHookResult = ReturnType<
  typeof useDeletePlaidAccountMutation
>;
export type DeletePlaidAccountMutationResult =
  Apollo.MutationResult<DeletePlaidAccountMutation>;
export type DeletePlaidAccountMutationOptions = Apollo.BaseMutationOptions<
  DeletePlaidAccountMutation,
  DeletePlaidAccountMutationVariables
>;
export const DeleteScheduledDepositConfigDocument = gql`
  mutation deleteScheduledDepositConfig($id: ID!) {
    deleteScheduledDepositConfig(id: $id)
  }
`;
export type DeleteScheduledDepositConfigMutationFn = Apollo.MutationFunction<
  DeleteScheduledDepositConfigMutation,
  DeleteScheduledDepositConfigMutationVariables
>;

/**
 * __useDeleteScheduledDepositConfigMutation__
 *
 * To run a mutation, you first call `useDeleteScheduledDepositConfigMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteScheduledDepositConfigMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteScheduledDepositConfigMutation, { data, loading, error }] = useDeleteScheduledDepositConfigMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteScheduledDepositConfigMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteScheduledDepositConfigMutation,
    DeleteScheduledDepositConfigMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteScheduledDepositConfigMutation,
    DeleteScheduledDepositConfigMutationVariables
  >(DeleteScheduledDepositConfigDocument, options);
}
export type DeleteScheduledDepositConfigMutationHookResult = ReturnType<
  typeof useDeleteScheduledDepositConfigMutation
>;
export type DeleteScheduledDepositConfigMutationResult =
  Apollo.MutationResult<DeleteScheduledDepositConfigMutation>;
export type DeleteScheduledDepositConfigMutationOptions =
  Apollo.BaseMutationOptions<
    DeleteScheduledDepositConfigMutation,
    DeleteScheduledDepositConfigMutationVariables
  >;
export const DeleteUserTrustedContactDocument = gql`
  mutation deleteUserTrustedContact($trustedContactId: ID!) {
    deleteUserTrustedContact(userTrustedContactId: $trustedContactId)
  }
`;
export type DeleteUserTrustedContactMutationFn = Apollo.MutationFunction<
  DeleteUserTrustedContactMutation,
  DeleteUserTrustedContactMutationVariables
>;

/**
 * __useDeleteUserTrustedContactMutation__
 *
 * To run a mutation, you first call `useDeleteUserTrustedContactMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteUserTrustedContactMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteUserTrustedContactMutation, { data, loading, error }] = useDeleteUserTrustedContactMutation({
 *   variables: {
 *      trustedContactId: // value for 'trustedContactId'
 *   },
 * });
 */
export function useDeleteUserTrustedContactMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteUserTrustedContactMutation,
    DeleteUserTrustedContactMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteUserTrustedContactMutation,
    DeleteUserTrustedContactMutationVariables
  >(DeleteUserTrustedContactDocument, options);
}
export type DeleteUserTrustedContactMutationHookResult = ReturnType<
  typeof useDeleteUserTrustedContactMutation
>;
export type DeleteUserTrustedContactMutationResult =
  Apollo.MutationResult<DeleteUserTrustedContactMutation>;
export type DeleteUserTrustedContactMutationOptions =
  Apollo.BaseMutationOptions<
    DeleteUserTrustedContactMutation,
    DeleteUserTrustedContactMutationVariables
  >;
export const DisableUserMfaPreferenceDocument = gql`
  mutation disableUserMfaPreference($id: ID!) {
    disableUserMfaPreference(preferenceId: $id)
  }
`;
export type DisableUserMfaPreferenceMutationFn = Apollo.MutationFunction<
  DisableUserMfaPreferenceMutation,
  DisableUserMfaPreferenceMutationVariables
>;

/**
 * __useDisableUserMfaPreferenceMutation__
 *
 * To run a mutation, you first call `useDisableUserMfaPreferenceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDisableUserMfaPreferenceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [disableUserMfaPreferenceMutation, { data, loading, error }] = useDisableUserMfaPreferenceMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDisableUserMfaPreferenceMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DisableUserMfaPreferenceMutation,
    DisableUserMfaPreferenceMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DisableUserMfaPreferenceMutation,
    DisableUserMfaPreferenceMutationVariables
  >(DisableUserMfaPreferenceDocument, options);
}
export type DisableUserMfaPreferenceMutationHookResult = ReturnType<
  typeof useDisableUserMfaPreferenceMutation
>;
export type DisableUserMfaPreferenceMutationResult =
  Apollo.MutationResult<DisableUserMfaPreferenceMutation>;
export type DisableUserMfaPreferenceMutationOptions =
  Apollo.BaseMutationOptions<
    DisableUserMfaPreferenceMutation,
    DisableUserMfaPreferenceMutationVariables
  >;
export const ExpirePlaidAccountDocument = gql`
  mutation expirePlaidAccount($id: ID!) {
    expirePlaidAccount(id: $id) {
      ...PlaidAccount
    }
  }
  ${PlaidAccountFragmentDoc}
`;
export type ExpirePlaidAccountMutationFn = Apollo.MutationFunction<
  ExpirePlaidAccountMutation,
  ExpirePlaidAccountMutationVariables
>;

/**
 * __useExpirePlaidAccountMutation__
 *
 * To run a mutation, you first call `useExpirePlaidAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useExpirePlaidAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [expirePlaidAccountMutation, { data, loading, error }] = useExpirePlaidAccountMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useExpirePlaidAccountMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ExpirePlaidAccountMutation,
    ExpirePlaidAccountMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ExpirePlaidAccountMutation,
    ExpirePlaidAccountMutationVariables
  >(ExpirePlaidAccountDocument, options);
}
export type ExpirePlaidAccountMutationHookResult = ReturnType<
  typeof useExpirePlaidAccountMutation
>;
export type ExpirePlaidAccountMutationResult =
  Apollo.MutationResult<ExpirePlaidAccountMutation>;
export type ExpirePlaidAccountMutationOptions = Apollo.BaseMutationOptions<
  ExpirePlaidAccountMutation,
  ExpirePlaidAccountMutationVariables
>;
export const GenerateUserCodeDocument = gql`
  mutation generateUserCode($input: GenerateUserCodeInput!) {
    generateUserCode(input: $input) {
      code
    }
  }
`;
export type GenerateUserCodeMutationFn = Apollo.MutationFunction<
  GenerateUserCodeMutation,
  GenerateUserCodeMutationVariables
>;

/**
 * __useGenerateUserCodeMutation__
 *
 * To run a mutation, you first call `useGenerateUserCodeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGenerateUserCodeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [generateUserCodeMutation, { data, loading, error }] = useGenerateUserCodeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGenerateUserCodeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    GenerateUserCodeMutation,
    GenerateUserCodeMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    GenerateUserCodeMutation,
    GenerateUserCodeMutationVariables
  >(GenerateUserCodeDocument, options);
}
export type GenerateUserCodeMutationHookResult = ReturnType<
  typeof useGenerateUserCodeMutation
>;
export type GenerateUserCodeMutationResult =
  Apollo.MutationResult<GenerateUserCodeMutation>;
export type GenerateUserCodeMutationOptions = Apollo.BaseMutationOptions<
  GenerateUserCodeMutation,
  GenerateUserCodeMutationVariables
>;
export const HandleEmailCaptureDocument = gql`
  mutation handleEmailCapture($email: String!) {
    handleEmailCapture(input: { email: $email })
  }
`;
export type HandleEmailCaptureMutationFn = Apollo.MutationFunction<
  HandleEmailCaptureMutation,
  HandleEmailCaptureMutationVariables
>;

/**
 * __useHandleEmailCaptureMutation__
 *
 * To run a mutation, you first call `useHandleEmailCaptureMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useHandleEmailCaptureMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [handleEmailCaptureMutation, { data, loading, error }] = useHandleEmailCaptureMutation({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useHandleEmailCaptureMutation(
  baseOptions?: Apollo.MutationHookOptions<
    HandleEmailCaptureMutation,
    HandleEmailCaptureMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    HandleEmailCaptureMutation,
    HandleEmailCaptureMutationVariables
  >(HandleEmailCaptureDocument, options);
}
export type HandleEmailCaptureMutationHookResult = ReturnType<
  typeof useHandleEmailCaptureMutation
>;
export type HandleEmailCaptureMutationResult =
  Apollo.MutationResult<HandleEmailCaptureMutation>;
export type HandleEmailCaptureMutationOptions = Apollo.BaseMutationOptions<
  HandleEmailCaptureMutation,
  HandleEmailCaptureMutationVariables
>;
export const InitiateLoanDocument = gql`
  mutation initiateLoan($input: InitiateLoanInput!) {
    initiateLoan(input: $input) {
      loanId
      errors {
        errorCode
        message
      }
    }
  }
`;
export type InitiateLoanMutationFn = Apollo.MutationFunction<
  InitiateLoanMutation,
  InitiateLoanMutationVariables
>;

/**
 * __useInitiateLoanMutation__
 *
 * To run a mutation, you first call `useInitiateLoanMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInitiateLoanMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [initiateLoanMutation, { data, loading, error }] = useInitiateLoanMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useInitiateLoanMutation(
  baseOptions?: Apollo.MutationHookOptions<
    InitiateLoanMutation,
    InitiateLoanMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    InitiateLoanMutation,
    InitiateLoanMutationVariables
  >(InitiateLoanDocument, options);
}
export type InitiateLoanMutationHookResult = ReturnType<
  typeof useInitiateLoanMutation
>;
export type InitiateLoanMutationResult =
  Apollo.MutationResult<InitiateLoanMutation>;
export type InitiateLoanMutationOptions = Apollo.BaseMutationOptions<
  InitiateLoanMutation,
  InitiateLoanMutationVariables
>;
export const InitiateTreasuryTransferDocument = gql`
  mutation initiateTreasuryTransfer($input: TreasuryTransferInput!) {
    initiateTreasuryTransfer(input: $input) {
      ...TreasuryExecution
    }
  }
  ${TreasuryExecutionFragmentDoc}
`;
export type InitiateTreasuryTransferMutationFn = Apollo.MutationFunction<
  InitiateTreasuryTransferMutation,
  InitiateTreasuryTransferMutationVariables
>;

/**
 * __useInitiateTreasuryTransferMutation__
 *
 * To run a mutation, you first call `useInitiateTreasuryTransferMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInitiateTreasuryTransferMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [initiateTreasuryTransferMutation, { data, loading, error }] = useInitiateTreasuryTransferMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useInitiateTreasuryTransferMutation(
  baseOptions?: Apollo.MutationHookOptions<
    InitiateTreasuryTransferMutation,
    InitiateTreasuryTransferMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    InitiateTreasuryTransferMutation,
    InitiateTreasuryTransferMutationVariables
  >(InitiateTreasuryTransferDocument, options);
}
export type InitiateTreasuryTransferMutationHookResult = ReturnType<
  typeof useInitiateTreasuryTransferMutation
>;
export type InitiateTreasuryTransferMutationResult =
  Apollo.MutationResult<InitiateTreasuryTransferMutation>;
export type InitiateTreasuryTransferMutationOptions =
  Apollo.BaseMutationOptions<
    InitiateTreasuryTransferMutation,
    InitiateTreasuryTransferMutationVariables
  >;
export const InitiateWireDepositDocument = gql`
  mutation initiateWireDeposit($input: InitiateWireDepositInput!) {
    initiateWireDeposit(initiateWireDepositInput: $input) {
      id
      status
    }
  }
`;
export type InitiateWireDepositMutationFn = Apollo.MutationFunction<
  InitiateWireDepositMutation,
  InitiateWireDepositMutationVariables
>;

/**
 * __useInitiateWireDepositMutation__
 *
 * To run a mutation, you first call `useInitiateWireDepositMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInitiateWireDepositMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [initiateWireDepositMutation, { data, loading, error }] = useInitiateWireDepositMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useInitiateWireDepositMutation(
  baseOptions?: Apollo.MutationHookOptions<
    InitiateWireDepositMutation,
    InitiateWireDepositMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    InitiateWireDepositMutation,
    InitiateWireDepositMutationVariables
  >(InitiateWireDepositDocument, options);
}
export type InitiateWireDepositMutationHookResult = ReturnType<
  typeof useInitiateWireDepositMutation
>;
export type InitiateWireDepositMutationResult =
  Apollo.MutationResult<InitiateWireDepositMutation>;
export type InitiateWireDepositMutationOptions = Apollo.BaseMutationOptions<
  InitiateWireDepositMutation,
  InitiateWireDepositMutationVariables
>;
export const InitiateWireWithdrawalDocument = gql`
  mutation initiateWireWithdrawal($input: InitiateWireWithdrawalInput!) {
    initiateWireWithdrawal(initiateWireWithdrawalInput: $input) {
      cashTransfer {
        id
      }
    }
  }
`;
export type InitiateWireWithdrawalMutationFn = Apollo.MutationFunction<
  InitiateWireWithdrawalMutation,
  InitiateWireWithdrawalMutationVariables
>;

/**
 * __useInitiateWireWithdrawalMutation__
 *
 * To run a mutation, you first call `useInitiateWireWithdrawalMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInitiateWireWithdrawalMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [initiateWireWithdrawalMutation, { data, loading, error }] = useInitiateWireWithdrawalMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useInitiateWireWithdrawalMutation(
  baseOptions?: Apollo.MutationHookOptions<
    InitiateWireWithdrawalMutation,
    InitiateWireWithdrawalMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    InitiateWireWithdrawalMutation,
    InitiateWireWithdrawalMutationVariables
  >(InitiateWireWithdrawalDocument, options);
}
export type InitiateWireWithdrawalMutationHookResult = ReturnType<
  typeof useInitiateWireWithdrawalMutation
>;
export type InitiateWireWithdrawalMutationResult =
  Apollo.MutationResult<InitiateWireWithdrawalMutation>;
export type InitiateWireWithdrawalMutationOptions = Apollo.BaseMutationOptions<
  InitiateWireWithdrawalMutation,
  InitiateWireWithdrawalMutationVariables
>;
export const IntervalExecuteTreasuryConfigsDocument = gql`
  mutation intervalExecuteTreasuryConfigs {
    intervalExecuteTreasuryConfigs
  }
`;
export type IntervalExecuteTreasuryConfigsMutationFn = Apollo.MutationFunction<
  IntervalExecuteTreasuryConfigsMutation,
  IntervalExecuteTreasuryConfigsMutationVariables
>;

/**
 * __useIntervalExecuteTreasuryConfigsMutation__
 *
 * To run a mutation, you first call `useIntervalExecuteTreasuryConfigsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useIntervalExecuteTreasuryConfigsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [intervalExecuteTreasuryConfigsMutation, { data, loading, error }] = useIntervalExecuteTreasuryConfigsMutation({
 *   variables: {
 *   },
 * });
 */
export function useIntervalExecuteTreasuryConfigsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    IntervalExecuteTreasuryConfigsMutation,
    IntervalExecuteTreasuryConfigsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    IntervalExecuteTreasuryConfigsMutation,
    IntervalExecuteTreasuryConfigsMutationVariables
  >(IntervalExecuteTreasuryConfigsDocument, options);
}
export type IntervalExecuteTreasuryConfigsMutationHookResult = ReturnType<
  typeof useIntervalExecuteTreasuryConfigsMutation
>;
export type IntervalExecuteTreasuryConfigsMutationResult =
  Apollo.MutationResult<IntervalExecuteTreasuryConfigsMutation>;
export type IntervalExecuteTreasuryConfigsMutationOptions =
  Apollo.BaseMutationOptions<
    IntervalExecuteTreasuryConfigsMutation,
    IntervalExecuteTreasuryConfigsMutationVariables
  >;
export const IntervalExecuteTreasuryExecutionsDocument = gql`
  mutation intervalExecuteTreasuryExecutions {
    intervalExecuteTreasuryExecutions
  }
`;
export type IntervalExecuteTreasuryExecutionsMutationFn =
  Apollo.MutationFunction<
    IntervalExecuteTreasuryExecutionsMutation,
    IntervalExecuteTreasuryExecutionsMutationVariables
  >;

/**
 * __useIntervalExecuteTreasuryExecutionsMutation__
 *
 * To run a mutation, you first call `useIntervalExecuteTreasuryExecutionsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useIntervalExecuteTreasuryExecutionsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [intervalExecuteTreasuryExecutionsMutation, { data, loading, error }] = useIntervalExecuteTreasuryExecutionsMutation({
 *   variables: {
 *   },
 * });
 */
export function useIntervalExecuteTreasuryExecutionsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    IntervalExecuteTreasuryExecutionsMutation,
    IntervalExecuteTreasuryExecutionsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    IntervalExecuteTreasuryExecutionsMutation,
    IntervalExecuteTreasuryExecutionsMutationVariables
  >(IntervalExecuteTreasuryExecutionsDocument, options);
}
export type IntervalExecuteTreasuryExecutionsMutationHookResult = ReturnType<
  typeof useIntervalExecuteTreasuryExecutionsMutation
>;
export type IntervalExecuteTreasuryExecutionsMutationResult =
  Apollo.MutationResult<IntervalExecuteTreasuryExecutionsMutation>;
export type IntervalExecuteTreasuryExecutionsMutationOptions =
  Apollo.BaseMutationOptions<
    IntervalExecuteTreasuryExecutionsMutation,
    IntervalExecuteTreasuryExecutionsMutationVariables
  >;
export const IntervalExecuteQueuedOrdersDocument = gql`
  mutation intervalExecuteQueuedOrders {
    intervalExecuteQueuedOrders
  }
`;
export type IntervalExecuteQueuedOrdersMutationFn = Apollo.MutationFunction<
  IntervalExecuteQueuedOrdersMutation,
  IntervalExecuteQueuedOrdersMutationVariables
>;

/**
 * __useIntervalExecuteQueuedOrdersMutation__
 *
 * To run a mutation, you first call `useIntervalExecuteQueuedOrdersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useIntervalExecuteQueuedOrdersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [intervalExecuteQueuedOrdersMutation, { data, loading, error }] = useIntervalExecuteQueuedOrdersMutation({
 *   variables: {
 *   },
 * });
 */
export function useIntervalExecuteQueuedOrdersMutation(
  baseOptions?: Apollo.MutationHookOptions<
    IntervalExecuteQueuedOrdersMutation,
    IntervalExecuteQueuedOrdersMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    IntervalExecuteQueuedOrdersMutation,
    IntervalExecuteQueuedOrdersMutationVariables
  >(IntervalExecuteQueuedOrdersDocument, options);
}
export type IntervalExecuteQueuedOrdersMutationHookResult = ReturnType<
  typeof useIntervalExecuteQueuedOrdersMutation
>;
export type IntervalExecuteQueuedOrdersMutationResult =
  Apollo.MutationResult<IntervalExecuteQueuedOrdersMutation>;
export type IntervalExecuteQueuedOrdersMutationOptions =
  Apollo.BaseMutationOptions<
    IntervalExecuteQueuedOrdersMutation,
    IntervalExecuteQueuedOrdersMutationVariables
  >;
export const IntervalAssessTreasuryFeesDocument = gql`
  mutation intervalAssessTreasuryFees {
    intervalAssessTreasuryFees
  }
`;
export type IntervalAssessTreasuryFeesMutationFn = Apollo.MutationFunction<
  IntervalAssessTreasuryFeesMutation,
  IntervalAssessTreasuryFeesMutationVariables
>;

/**
 * __useIntervalAssessTreasuryFeesMutation__
 *
 * To run a mutation, you first call `useIntervalAssessTreasuryFeesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useIntervalAssessTreasuryFeesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [intervalAssessTreasuryFeesMutation, { data, loading, error }] = useIntervalAssessTreasuryFeesMutation({
 *   variables: {
 *   },
 * });
 */
export function useIntervalAssessTreasuryFeesMutation(
  baseOptions?: Apollo.MutationHookOptions<
    IntervalAssessTreasuryFeesMutation,
    IntervalAssessTreasuryFeesMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    IntervalAssessTreasuryFeesMutation,
    IntervalAssessTreasuryFeesMutationVariables
  >(IntervalAssessTreasuryFeesDocument, options);
}
export type IntervalAssessTreasuryFeesMutationHookResult = ReturnType<
  typeof useIntervalAssessTreasuryFeesMutation
>;
export type IntervalAssessTreasuryFeesMutationResult =
  Apollo.MutationResult<IntervalAssessTreasuryFeesMutation>;
export type IntervalAssessTreasuryFeesMutationOptions =
  Apollo.BaseMutationOptions<
    IntervalAssessTreasuryFeesMutation,
    IntervalAssessTreasuryFeesMutationVariables
  >;
export const IntervalExecuteBatchUpdateUsersDocument = gql`
  mutation intervalExecuteBatchUpdateUsers {
    intervalExecuteBatchUpdateUsers
  }
`;
export type IntervalExecuteBatchUpdateUsersMutationFn = Apollo.MutationFunction<
  IntervalExecuteBatchUpdateUsersMutation,
  IntervalExecuteBatchUpdateUsersMutationVariables
>;

/**
 * __useIntervalExecuteBatchUpdateUsersMutation__
 *
 * To run a mutation, you first call `useIntervalExecuteBatchUpdateUsersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useIntervalExecuteBatchUpdateUsersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [intervalExecuteBatchUpdateUsersMutation, { data, loading, error }] = useIntervalExecuteBatchUpdateUsersMutation({
 *   variables: {
 *   },
 * });
 */
export function useIntervalExecuteBatchUpdateUsersMutation(
  baseOptions?: Apollo.MutationHookOptions<
    IntervalExecuteBatchUpdateUsersMutation,
    IntervalExecuteBatchUpdateUsersMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    IntervalExecuteBatchUpdateUsersMutation,
    IntervalExecuteBatchUpdateUsersMutationVariables
  >(IntervalExecuteBatchUpdateUsersDocument, options);
}
export type IntervalExecuteBatchUpdateUsersMutationHookResult = ReturnType<
  typeof useIntervalExecuteBatchUpdateUsersMutation
>;
export type IntervalExecuteBatchUpdateUsersMutationResult =
  Apollo.MutationResult<IntervalExecuteBatchUpdateUsersMutation>;
export type IntervalExecuteBatchUpdateUsersMutationOptions =
  Apollo.BaseMutationOptions<
    IntervalExecuteBatchUpdateUsersMutation,
    IntervalExecuteBatchUpdateUsersMutationVariables
  >;
export const IntraAccountStockTransferFromDiDocument = gql`
  mutation intraAccountStockTransferFromDI($input: TransferStockFromDIInput!) {
    intraAccountStockTransferFromDI(input: $input)
  }
`;
export type IntraAccountStockTransferFromDiMutationFn = Apollo.MutationFunction<
  IntraAccountStockTransferFromDiMutation,
  IntraAccountStockTransferFromDiMutationVariables
>;

/**
 * __useIntraAccountStockTransferFromDiMutation__
 *
 * To run a mutation, you first call `useIntraAccountStockTransferFromDiMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useIntraAccountStockTransferFromDiMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [intraAccountStockTransferFromDiMutation, { data, loading, error }] = useIntraAccountStockTransferFromDiMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useIntraAccountStockTransferFromDiMutation(
  baseOptions?: Apollo.MutationHookOptions<
    IntraAccountStockTransferFromDiMutation,
    IntraAccountStockTransferFromDiMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    IntraAccountStockTransferFromDiMutation,
    IntraAccountStockTransferFromDiMutationVariables
  >(IntraAccountStockTransferFromDiDocument, options);
}
export type IntraAccountStockTransferFromDiMutationHookResult = ReturnType<
  typeof useIntraAccountStockTransferFromDiMutation
>;
export type IntraAccountStockTransferFromDiMutationResult =
  Apollo.MutationResult<IntraAccountStockTransferFromDiMutation>;
export type IntraAccountStockTransferFromDiMutationOptions =
  Apollo.BaseMutationOptions<
    IntraAccountStockTransferFromDiMutation,
    IntraAccountStockTransferFromDiMutationVariables
  >;
export const IntraAccountStockTransferToDiDocument = gql`
  mutation intraAccountStockTransferToDI(
    $input: TransferStockSubAccountInput!
  ) {
    intraAccountStockTransferToDI(input: $input)
  }
`;
export type IntraAccountStockTransferToDiMutationFn = Apollo.MutationFunction<
  IntraAccountStockTransferToDiMutation,
  IntraAccountStockTransferToDiMutationVariables
>;

/**
 * __useIntraAccountStockTransferToDiMutation__
 *
 * To run a mutation, you first call `useIntraAccountStockTransferToDiMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useIntraAccountStockTransferToDiMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [intraAccountStockTransferToDiMutation, { data, loading, error }] = useIntraAccountStockTransferToDiMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useIntraAccountStockTransferToDiMutation(
  baseOptions?: Apollo.MutationHookOptions<
    IntraAccountStockTransferToDiMutation,
    IntraAccountStockTransferToDiMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    IntraAccountStockTransferToDiMutation,
    IntraAccountStockTransferToDiMutationVariables
  >(IntraAccountStockTransferToDiDocument, options);
}
export type IntraAccountStockTransferToDiMutationHookResult = ReturnType<
  typeof useIntraAccountStockTransferToDiMutation
>;
export type IntraAccountStockTransferToDiMutationResult =
  Apollo.MutationResult<IntraAccountStockTransferToDiMutation>;
export type IntraAccountStockTransferToDiMutationOptions =
  Apollo.BaseMutationOptions<
    IntraAccountStockTransferToDiMutation,
    IntraAccountStockTransferToDiMutationVariables
  >;
export const KeyValueSetDocument = gql`
  mutation keyValueSet($input: KeyValueSetInput!) {
    keyValueSet(input: $input) {
      ...KeyValuePair
    }
  }
  ${KeyValuePairFragmentDoc}
`;
export type KeyValueSetMutationFn = Apollo.MutationFunction<
  KeyValueSetMutation,
  KeyValueSetMutationVariables
>;

/**
 * __useKeyValueSetMutation__
 *
 * To run a mutation, you first call `useKeyValueSetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useKeyValueSetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [keyValueSetMutation, { data, loading, error }] = useKeyValueSetMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useKeyValueSetMutation(
  baseOptions?: Apollo.MutationHookOptions<
    KeyValueSetMutation,
    KeyValueSetMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<KeyValueSetMutation, KeyValueSetMutationVariables>(
    KeyValueSetDocument,
    options
  );
}
export type KeyValueSetMutationHookResult = ReturnType<
  typeof useKeyValueSetMutation
>;
export type KeyValueSetMutationResult =
  Apollo.MutationResult<KeyValueSetMutation>;
export type KeyValueSetMutationOptions = Apollo.BaseMutationOptions<
  KeyValueSetMutation,
  KeyValueSetMutationVariables
>;
export const LinkPlaidAccountDocument = gql`
  mutation LinkPlaidAccount(
    $plaidPublicToken: String!
    $type: PlaidAccountType!
  ) {
    linkPlaid(input: { plaidPublicToken: $plaidPublicToken, type: $type }) {
      ...PlaidAccount
    }
  }
  ${PlaidAccountFragmentDoc}
`;
export type LinkPlaidAccountMutationFn = Apollo.MutationFunction<
  LinkPlaidAccountMutation,
  LinkPlaidAccountMutationVariables
>;

/**
 * __useLinkPlaidAccountMutation__
 *
 * To run a mutation, you first call `useLinkPlaidAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLinkPlaidAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [linkPlaidAccountMutation, { data, loading, error }] = useLinkPlaidAccountMutation({
 *   variables: {
 *      plaidPublicToken: // value for 'plaidPublicToken'
 *      type: // value for 'type'
 *   },
 * });
 */
export function useLinkPlaidAccountMutation(
  baseOptions?: Apollo.MutationHookOptions<
    LinkPlaidAccountMutation,
    LinkPlaidAccountMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    LinkPlaidAccountMutation,
    LinkPlaidAccountMutationVariables
  >(LinkPlaidAccountDocument, options);
}
export type LinkPlaidAccountMutationHookResult = ReturnType<
  typeof useLinkPlaidAccountMutation
>;
export type LinkPlaidAccountMutationResult =
  Apollo.MutationResult<LinkPlaidAccountMutation>;
export type LinkPlaidAccountMutationOptions = Apollo.BaseMutationOptions<
  LinkPlaidAccountMutation,
  LinkPlaidAccountMutationVariables
>;
export const LinkPlaidAsDepositAccountDocument = gql`
  mutation linkPlaidAsDepositAccount(
    $clearingAccountId: ID!
    $plaidAccountId: ID!
  ) {
    linkPlaidDepositAccountToClearingAccount(
      clearingAccountId: $clearingAccountId
      plaidAccountId: $plaidAccountId
    ) {
      ...DepositAccount
    }
  }
  ${DepositAccountFragmentDoc}
`;
export type LinkPlaidAsDepositAccountMutationFn = Apollo.MutationFunction<
  LinkPlaidAsDepositAccountMutation,
  LinkPlaidAsDepositAccountMutationVariables
>;

/**
 * __useLinkPlaidAsDepositAccountMutation__
 *
 * To run a mutation, you first call `useLinkPlaidAsDepositAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLinkPlaidAsDepositAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [linkPlaidAsDepositAccountMutation, { data, loading, error }] = useLinkPlaidAsDepositAccountMutation({
 *   variables: {
 *      clearingAccountId: // value for 'clearingAccountId'
 *      plaidAccountId: // value for 'plaidAccountId'
 *   },
 * });
 */
export function useLinkPlaidAsDepositAccountMutation(
  baseOptions?: Apollo.MutationHookOptions<
    LinkPlaidAsDepositAccountMutation,
    LinkPlaidAsDepositAccountMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    LinkPlaidAsDepositAccountMutation,
    LinkPlaidAsDepositAccountMutationVariables
  >(LinkPlaidAsDepositAccountDocument, options);
}
export type LinkPlaidAsDepositAccountMutationHookResult = ReturnType<
  typeof useLinkPlaidAsDepositAccountMutation
>;
export type LinkPlaidAsDepositAccountMutationResult =
  Apollo.MutationResult<LinkPlaidAsDepositAccountMutation>;
export type LinkPlaidAsDepositAccountMutationOptions =
  Apollo.BaseMutationOptions<
    LinkPlaidAsDepositAccountMutation,
    LinkPlaidAsDepositAccountMutationVariables
  >;
export const PlaceBatchTradeOrderDocument = gql`
  mutation placeBatchTradeOrder($input: PlaceBatchTradeOrderInput!) {
    placeBatchTradeOrder(input: $input) {
      batchTradeOrder {
        batchOrderMetadataId
        orders {
          id
        }
      }
      failures {
        message
      }
    }
  }
`;
export type PlaceBatchTradeOrderMutationFn = Apollo.MutationFunction<
  PlaceBatchTradeOrderMutation,
  PlaceBatchTradeOrderMutationVariables
>;

/**
 * __usePlaceBatchTradeOrderMutation__
 *
 * To run a mutation, you first call `usePlaceBatchTradeOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePlaceBatchTradeOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [placeBatchTradeOrderMutation, { data, loading, error }] = usePlaceBatchTradeOrderMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePlaceBatchTradeOrderMutation(
  baseOptions?: Apollo.MutationHookOptions<
    PlaceBatchTradeOrderMutation,
    PlaceBatchTradeOrderMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    PlaceBatchTradeOrderMutation,
    PlaceBatchTradeOrderMutationVariables
  >(PlaceBatchTradeOrderDocument, options);
}
export type PlaceBatchTradeOrderMutationHookResult = ReturnType<
  typeof usePlaceBatchTradeOrderMutation
>;
export type PlaceBatchTradeOrderMutationResult =
  Apollo.MutationResult<PlaceBatchTradeOrderMutation>;
export type PlaceBatchTradeOrderMutationOptions = Apollo.BaseMutationOptions<
  PlaceBatchTradeOrderMutation,
  PlaceBatchTradeOrderMutationVariables
>;
export const PlaidCreateLinkTokenDocument = gql`
  mutation PlaidCreateLinkToken(
    $type: PlaidAccountType!
    $updateAccountId: String
  ) {
    plaidCreateLinkToken(type: $type, updateAccountId: $updateAccountId) {
      token
      expiresAt
    }
  }
`;
export type PlaidCreateLinkTokenMutationFn = Apollo.MutationFunction<
  PlaidCreateLinkTokenMutation,
  PlaidCreateLinkTokenMutationVariables
>;

/**
 * __usePlaidCreateLinkTokenMutation__
 *
 * To run a mutation, you first call `usePlaidCreateLinkTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePlaidCreateLinkTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [plaidCreateLinkTokenMutation, { data, loading, error }] = usePlaidCreateLinkTokenMutation({
 *   variables: {
 *      type: // value for 'type'
 *      updateAccountId: // value for 'updateAccountId'
 *   },
 * });
 */
export function usePlaidCreateLinkTokenMutation(
  baseOptions?: Apollo.MutationHookOptions<
    PlaidCreateLinkTokenMutation,
    PlaidCreateLinkTokenMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    PlaidCreateLinkTokenMutation,
    PlaidCreateLinkTokenMutationVariables
  >(PlaidCreateLinkTokenDocument, options);
}
export type PlaidCreateLinkTokenMutationHookResult = ReturnType<
  typeof usePlaidCreateLinkTokenMutation
>;
export type PlaidCreateLinkTokenMutationResult =
  Apollo.MutationResult<PlaidCreateLinkTokenMutation>;
export type PlaidCreateLinkTokenMutationOptions = Apollo.BaseMutationOptions<
  PlaidCreateLinkTokenMutation,
  PlaidCreateLinkTokenMutationVariables
>;
export const RepayLoanDocument = gql`
  mutation repayLoan($input: RepayLoanInput!) {
    repayLoan(input: $input) {
      loanId
      errors {
        errorCode
        message
      }
    }
  }
`;
export type RepayLoanMutationFn = Apollo.MutationFunction<
  RepayLoanMutation,
  RepayLoanMutationVariables
>;

/**
 * __useRepayLoanMutation__
 *
 * To run a mutation, you first call `useRepayLoanMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRepayLoanMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [repayLoanMutation, { data, loading, error }] = useRepayLoanMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRepayLoanMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RepayLoanMutation,
    RepayLoanMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<RepayLoanMutation, RepayLoanMutationVariables>(
    RepayLoanDocument,
    options
  );
}
export type RepayLoanMutationHookResult = ReturnType<
  typeof useRepayLoanMutation
>;
export type RepayLoanMutationResult = Apollo.MutationResult<RepayLoanMutation>;
export type RepayLoanMutationOptions = Apollo.BaseMutationOptions<
  RepayLoanMutation,
  RepayLoanMutationVariables
>;
export const RequestEmailVerificationDocument = gql`
  mutation requestEmailVerification($clearingAccountId: ID) {
    sendEmailNotification(
      clearingAccountId: $clearingAccountId
      event: EMAIL_VERIFICATION
    )
  }
`;
export type RequestEmailVerificationMutationFn = Apollo.MutationFunction<
  RequestEmailVerificationMutation,
  RequestEmailVerificationMutationVariables
>;

/**
 * __useRequestEmailVerificationMutation__
 *
 * To run a mutation, you first call `useRequestEmailVerificationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRequestEmailVerificationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [requestEmailVerificationMutation, { data, loading, error }] = useRequestEmailVerificationMutation({
 *   variables: {
 *      clearingAccountId: // value for 'clearingAccountId'
 *   },
 * });
 */
export function useRequestEmailVerificationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RequestEmailVerificationMutation,
    RequestEmailVerificationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RequestEmailVerificationMutation,
    RequestEmailVerificationMutationVariables
  >(RequestEmailVerificationDocument, options);
}
export type RequestEmailVerificationMutationHookResult = ReturnType<
  typeof useRequestEmailVerificationMutation
>;
export type RequestEmailVerificationMutationResult =
  Apollo.MutationResult<RequestEmailVerificationMutation>;
export type RequestEmailVerificationMutationOptions =
  Apollo.BaseMutationOptions<
    RequestEmailVerificationMutation,
    RequestEmailVerificationMutationVariables
  >;
export const RequestMfaPhoneChallengeDocument = gql`
  mutation requestMfaPhoneChallenge($input: RequestMfaPhoneChallengeInput!) {
    requestMfaPhoneChallenge(input: $input) {
      status
      phone
    }
  }
`;
export type RequestMfaPhoneChallengeMutationFn = Apollo.MutationFunction<
  RequestMfaPhoneChallengeMutation,
  RequestMfaPhoneChallengeMutationVariables
>;

/**
 * __useRequestMfaPhoneChallengeMutation__
 *
 * To run a mutation, you first call `useRequestMfaPhoneChallengeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRequestMfaPhoneChallengeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [requestMfaPhoneChallengeMutation, { data, loading, error }] = useRequestMfaPhoneChallengeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRequestMfaPhoneChallengeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RequestMfaPhoneChallengeMutation,
    RequestMfaPhoneChallengeMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RequestMfaPhoneChallengeMutation,
    RequestMfaPhoneChallengeMutationVariables
  >(RequestMfaPhoneChallengeDocument, options);
}
export type RequestMfaPhoneChallengeMutationHookResult = ReturnType<
  typeof useRequestMfaPhoneChallengeMutation
>;
export type RequestMfaPhoneChallengeMutationResult =
  Apollo.MutationResult<RequestMfaPhoneChallengeMutation>;
export type RequestMfaPhoneChallengeMutationOptions =
  Apollo.BaseMutationOptions<
    RequestMfaPhoneChallengeMutation,
    RequestMfaPhoneChallengeMutationVariables
  >;
export const RequestPasswordResetEmailDocument = gql`
  mutation requestPasswordResetEmail($email: String!) {
    requestPasswordReset(email: $email)
  }
`;
export type RequestPasswordResetEmailMutationFn = Apollo.MutationFunction<
  RequestPasswordResetEmailMutation,
  RequestPasswordResetEmailMutationVariables
>;

/**
 * __useRequestPasswordResetEmailMutation__
 *
 * To run a mutation, you first call `useRequestPasswordResetEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRequestPasswordResetEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [requestPasswordResetEmailMutation, { data, loading, error }] = useRequestPasswordResetEmailMutation({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useRequestPasswordResetEmailMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RequestPasswordResetEmailMutation,
    RequestPasswordResetEmailMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RequestPasswordResetEmailMutation,
    RequestPasswordResetEmailMutationVariables
  >(RequestPasswordResetEmailDocument, options);
}
export type RequestPasswordResetEmailMutationHookResult = ReturnType<
  typeof useRequestPasswordResetEmailMutation
>;
export type RequestPasswordResetEmailMutationResult =
  Apollo.MutationResult<RequestPasswordResetEmailMutation>;
export type RequestPasswordResetEmailMutationOptions =
  Apollo.BaseMutationOptions<
    RequestPasswordResetEmailMutation,
    RequestPasswordResetEmailMutationVariables
  >;
export const RequestVerificationCodeDocument = gql`
  mutation requestVerificationCode(
    $phone: String!
    $channel: PhoneVerificationChannel!
  ) {
    requestVerificationCode(phone: $phone, channel: $channel) {
      isCodeSent
      codeSentAt
      message
    }
  }
`;
export type RequestVerificationCodeMutationFn = Apollo.MutationFunction<
  RequestVerificationCodeMutation,
  RequestVerificationCodeMutationVariables
>;

/**
 * __useRequestVerificationCodeMutation__
 *
 * To run a mutation, you first call `useRequestVerificationCodeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRequestVerificationCodeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [requestVerificationCodeMutation, { data, loading, error }] = useRequestVerificationCodeMutation({
 *   variables: {
 *      phone: // value for 'phone'
 *      channel: // value for 'channel'
 *   },
 * });
 */
export function useRequestVerificationCodeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RequestVerificationCodeMutation,
    RequestVerificationCodeMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RequestVerificationCodeMutation,
    RequestVerificationCodeMutationVariables
  >(RequestVerificationCodeDocument, options);
}
export type RequestVerificationCodeMutationHookResult = ReturnType<
  typeof useRequestVerificationCodeMutation
>;
export type RequestVerificationCodeMutationResult =
  Apollo.MutationResult<RequestVerificationCodeMutation>;
export type RequestVerificationCodeMutationOptions = Apollo.BaseMutationOptions<
  RequestVerificationCodeMutation,
  RequestVerificationCodeMutationVariables
>;
export const SendInviteViaEmailDocument = gql`
  mutation sendInviteViaEmail($email: String!, $name: String) {
    sendInviteViaEmail(email: $email, name: $name)
  }
`;
export type SendInviteViaEmailMutationFn = Apollo.MutationFunction<
  SendInviteViaEmailMutation,
  SendInviteViaEmailMutationVariables
>;

/**
 * __useSendInviteViaEmailMutation__
 *
 * To run a mutation, you first call `useSendInviteViaEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendInviteViaEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendInviteViaEmailMutation, { data, loading, error }] = useSendInviteViaEmailMutation({
 *   variables: {
 *      email: // value for 'email'
 *      name: // value for 'name'
 *   },
 * });
 */
export function useSendInviteViaEmailMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SendInviteViaEmailMutation,
    SendInviteViaEmailMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SendInviteViaEmailMutation,
    SendInviteViaEmailMutationVariables
  >(SendInviteViaEmailDocument, options);
}
export type SendInviteViaEmailMutationHookResult = ReturnType<
  typeof useSendInviteViaEmailMutation
>;
export type SendInviteViaEmailMutationResult =
  Apollo.MutationResult<SendInviteViaEmailMutation>;
export type SendInviteViaEmailMutationOptions = Apollo.BaseMutationOptions<
  SendInviteViaEmailMutation,
  SendInviteViaEmailMutationVariables
>;
export const SetDirectIndexSecurityOverridesDocument = gql`
  mutation setDirectIndexSecurityOverrides($input: DirectIndexOverrideInput!) {
    setDirectIndexSecurityOverrides(input: $input) {
      subAccountId
      weights {
        ...DirectIndexWeight
      }
    }
  }
  ${DirectIndexWeightFragmentDoc}
`;
export type SetDirectIndexSecurityOverridesMutationFn = Apollo.MutationFunction<
  SetDirectIndexSecurityOverridesMutation,
  SetDirectIndexSecurityOverridesMutationVariables
>;

/**
 * __useSetDirectIndexSecurityOverridesMutation__
 *
 * To run a mutation, you first call `useSetDirectIndexSecurityOverridesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetDirectIndexSecurityOverridesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setDirectIndexSecurityOverridesMutation, { data, loading, error }] = useSetDirectIndexSecurityOverridesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetDirectIndexSecurityOverridesMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SetDirectIndexSecurityOverridesMutation,
    SetDirectIndexSecurityOverridesMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SetDirectIndexSecurityOverridesMutation,
    SetDirectIndexSecurityOverridesMutationVariables
  >(SetDirectIndexSecurityOverridesDocument, options);
}
export type SetDirectIndexSecurityOverridesMutationHookResult = ReturnType<
  typeof useSetDirectIndexSecurityOverridesMutation
>;
export type SetDirectIndexSecurityOverridesMutationResult =
  Apollo.MutationResult<SetDirectIndexSecurityOverridesMutation>;
export type SetDirectIndexSecurityOverridesMutationOptions =
  Apollo.BaseMutationOptions<
    SetDirectIndexSecurityOverridesMutation,
    SetDirectIndexSecurityOverridesMutationVariables
  >;
export const SetSelfManagedTaxLossConfigDocument = gql`
  mutation setSelfManagedTaxLossConfig($input: SelfManagedTaxLossConfigInput!) {
    setSelfManagedTaxLossConfig(input: $input) {
      ...SelfManagedTaxLossConfig
    }
  }
  ${SelfManagedTaxLossConfigFragmentDoc}
`;
export type SetSelfManagedTaxLossConfigMutationFn = Apollo.MutationFunction<
  SetSelfManagedTaxLossConfigMutation,
  SetSelfManagedTaxLossConfigMutationVariables
>;

/**
 * __useSetSelfManagedTaxLossConfigMutation__
 *
 * To run a mutation, you first call `useSetSelfManagedTaxLossConfigMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetSelfManagedTaxLossConfigMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setSelfManagedTaxLossConfigMutation, { data, loading, error }] = useSetSelfManagedTaxLossConfigMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetSelfManagedTaxLossConfigMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SetSelfManagedTaxLossConfigMutation,
    SetSelfManagedTaxLossConfigMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SetSelfManagedTaxLossConfigMutation,
    SetSelfManagedTaxLossConfigMutationVariables
  >(SetSelfManagedTaxLossConfigDocument, options);
}
export type SetSelfManagedTaxLossConfigMutationHookResult = ReturnType<
  typeof useSetSelfManagedTaxLossConfigMutation
>;
export type SetSelfManagedTaxLossConfigMutationResult =
  Apollo.MutationResult<SetSelfManagedTaxLossConfigMutation>;
export type SetSelfManagedTaxLossConfigMutationOptions =
  Apollo.BaseMutationOptions<
    SetSelfManagedTaxLossConfigMutation,
    SetSelfManagedTaxLossConfigMutationVariables
  >;
export const SetSelfManagedTaxLossSecuritiesDocument = gql`
  mutation setSelfManagedTaxLossSecurities(
    $input: SelfManagedTaxLossSecuritiesInput!
  ) {
    setSelfManagedTaxLossSecurities(input: $input) {
      ...SelfManagedTaxLossConfig
    }
  }
  ${SelfManagedTaxLossConfigFragmentDoc}
`;
export type SetSelfManagedTaxLossSecuritiesMutationFn = Apollo.MutationFunction<
  SetSelfManagedTaxLossSecuritiesMutation,
  SetSelfManagedTaxLossSecuritiesMutationVariables
>;

/**
 * __useSetSelfManagedTaxLossSecuritiesMutation__
 *
 * To run a mutation, you first call `useSetSelfManagedTaxLossSecuritiesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetSelfManagedTaxLossSecuritiesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setSelfManagedTaxLossSecuritiesMutation, { data, loading, error }] = useSetSelfManagedTaxLossSecuritiesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetSelfManagedTaxLossSecuritiesMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SetSelfManagedTaxLossSecuritiesMutation,
    SetSelfManagedTaxLossSecuritiesMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SetSelfManagedTaxLossSecuritiesMutation,
    SetSelfManagedTaxLossSecuritiesMutationVariables
  >(SetSelfManagedTaxLossSecuritiesDocument, options);
}
export type SetSelfManagedTaxLossSecuritiesMutationHookResult = ReturnType<
  typeof useSetSelfManagedTaxLossSecuritiesMutation
>;
export type SetSelfManagedTaxLossSecuritiesMutationResult =
  Apollo.MutationResult<SetSelfManagedTaxLossSecuritiesMutation>;
export type SetSelfManagedTaxLossSecuritiesMutationOptions =
  Apollo.BaseMutationOptions<
    SetSelfManagedTaxLossSecuritiesMutation,
    SetSelfManagedTaxLossSecuritiesMutationVariables
  >;
export const AddTreasuryConfigDocument = gql`
  mutation addTreasuryConfig($clearingAccountId: ID!) {
    addTreasuryConfig(input: { clearingAccountId: $clearingAccountId }) {
      ...TreasuryConfig
    }
  }
  ${TreasuryConfigFragmentDoc}
`;
export type AddTreasuryConfigMutationFn = Apollo.MutationFunction<
  AddTreasuryConfigMutation,
  AddTreasuryConfigMutationVariables
>;

/**
 * __useAddTreasuryConfigMutation__
 *
 * To run a mutation, you first call `useAddTreasuryConfigMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddTreasuryConfigMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addTreasuryConfigMutation, { data, loading, error }] = useAddTreasuryConfigMutation({
 *   variables: {
 *      clearingAccountId: // value for 'clearingAccountId'
 *   },
 * });
 */
export function useAddTreasuryConfigMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddTreasuryConfigMutation,
    AddTreasuryConfigMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AddTreasuryConfigMutation,
    AddTreasuryConfigMutationVariables
  >(AddTreasuryConfigDocument, options);
}
export type AddTreasuryConfigMutationHookResult = ReturnType<
  typeof useAddTreasuryConfigMutation
>;
export type AddTreasuryConfigMutationResult =
  Apollo.MutationResult<AddTreasuryConfigMutation>;
export type AddTreasuryConfigMutationOptions = Apollo.BaseMutationOptions<
  AddTreasuryConfigMutation,
  AddTreasuryConfigMutationVariables
>;
export const SetUserProgressFlagDocument = gql`
  mutation setUserProgressFlag($input: SetProgressFlagInput!) {
    setProgressFlag(input: $input) {
      status
      category
    }
  }
`;
export type SetUserProgressFlagMutationFn = Apollo.MutationFunction<
  SetUserProgressFlagMutation,
  SetUserProgressFlagMutationVariables
>;

/**
 * __useSetUserProgressFlagMutation__
 *
 * To run a mutation, you first call `useSetUserProgressFlagMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetUserProgressFlagMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setUserProgressFlagMutation, { data, loading, error }] = useSetUserProgressFlagMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetUserProgressFlagMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SetUserProgressFlagMutation,
    SetUserProgressFlagMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SetUserProgressFlagMutation,
    SetUserProgressFlagMutationVariables
  >(SetUserProgressFlagDocument, options);
}
export type SetUserProgressFlagMutationHookResult = ReturnType<
  typeof useSetUserProgressFlagMutation
>;
export type SetUserProgressFlagMutationResult =
  Apollo.MutationResult<SetUserProgressFlagMutation>;
export type SetUserProgressFlagMutationOptions = Apollo.BaseMutationOptions<
  SetUserProgressFlagMutation,
  SetUserProgressFlagMutationVariables
>;
export const SetUserSourceDocument = gql`
  mutation setUserSource($input: UserSourceInput!) {
    setUserSource(input: $input) {
      ...UserSource
    }
  }
  ${UserSourceFragmentDoc}
`;
export type SetUserSourceMutationFn = Apollo.MutationFunction<
  SetUserSourceMutation,
  SetUserSourceMutationVariables
>;

/**
 * __useSetUserSourceMutation__
 *
 * To run a mutation, you first call `useSetUserSourceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetUserSourceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setUserSourceMutation, { data, loading, error }] = useSetUserSourceMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetUserSourceMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SetUserSourceMutation,
    SetUserSourceMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SetUserSourceMutation,
    SetUserSourceMutationVariables
  >(SetUserSourceDocument, options);
}
export type SetUserSourceMutationHookResult = ReturnType<
  typeof useSetUserSourceMutation
>;
export type SetUserSourceMutationResult =
  Apollo.MutationResult<SetUserSourceMutation>;
export type SetUserSourceMutationOptions = Apollo.BaseMutationOptions<
  SetUserSourceMutation,
  SetUserSourceMutationVariables
>;
export const SubmitEmailVerificationDocument = gql`
  mutation submitEmailVerification($code: String!) {
    validateVerificationCode(
      input: { category: EMAIL_VERIFICATION, code: $code }
    )
  }
`;
export type SubmitEmailVerificationMutationFn = Apollo.MutationFunction<
  SubmitEmailVerificationMutation,
  SubmitEmailVerificationMutationVariables
>;

/**
 * __useSubmitEmailVerificationMutation__
 *
 * To run a mutation, you first call `useSubmitEmailVerificationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubmitEmailVerificationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [submitEmailVerificationMutation, { data, loading, error }] = useSubmitEmailVerificationMutation({
 *   variables: {
 *      code: // value for 'code'
 *   },
 * });
 */
export function useSubmitEmailVerificationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SubmitEmailVerificationMutation,
    SubmitEmailVerificationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SubmitEmailVerificationMutation,
    SubmitEmailVerificationMutationVariables
  >(SubmitEmailVerificationDocument, options);
}
export type SubmitEmailVerificationMutationHookResult = ReturnType<
  typeof useSubmitEmailVerificationMutation
>;
export type SubmitEmailVerificationMutationResult =
  Apollo.MutationResult<SubmitEmailVerificationMutation>;
export type SubmitEmailVerificationMutationOptions = Apollo.BaseMutationOptions<
  SubmitEmailVerificationMutation,
  SubmitEmailVerificationMutationVariables
>;
export const SubmitVerificationCodeDocument = gql`
  mutation submitVerificationCode($phone: String!, $code: String!) {
    submitVerificationCode(phone: $phone, code: $code)
  }
`;
export type SubmitVerificationCodeMutationFn = Apollo.MutationFunction<
  SubmitVerificationCodeMutation,
  SubmitVerificationCodeMutationVariables
>;

/**
 * __useSubmitVerificationCodeMutation__
 *
 * To run a mutation, you first call `useSubmitVerificationCodeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubmitVerificationCodeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [submitVerificationCodeMutation, { data, loading, error }] = useSubmitVerificationCodeMutation({
 *   variables: {
 *      phone: // value for 'phone'
 *      code: // value for 'code'
 *   },
 * });
 */
export function useSubmitVerificationCodeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SubmitVerificationCodeMutation,
    SubmitVerificationCodeMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SubmitVerificationCodeMutation,
    SubmitVerificationCodeMutationVariables
  >(SubmitVerificationCodeDocument, options);
}
export type SubmitVerificationCodeMutationHookResult = ReturnType<
  typeof useSubmitVerificationCodeMutation
>;
export type SubmitVerificationCodeMutationResult =
  Apollo.MutationResult<SubmitVerificationCodeMutation>;
export type SubmitVerificationCodeMutationOptions = Apollo.BaseMutationOptions<
  SubmitVerificationCodeMutation,
  SubmitVerificationCodeMutationVariables
>;
export const UpdateDividendPreferenceDocument = gql`
  mutation updateDividendPreference(
    $id: ID!
    $input: DividendPreferenceInput!
  ) {
    updateDividendPreference(id: $id, input: $input) {
      ...DividendPreference
    }
  }
  ${DividendPreferenceFragmentDoc}
`;
export type UpdateDividendPreferenceMutationFn = Apollo.MutationFunction<
  UpdateDividendPreferenceMutation,
  UpdateDividendPreferenceMutationVariables
>;

/**
 * __useUpdateDividendPreferenceMutation__
 *
 * To run a mutation, you first call `useUpdateDividendPreferenceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDividendPreferenceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDividendPreferenceMutation, { data, loading, error }] = useUpdateDividendPreferenceMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateDividendPreferenceMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateDividendPreferenceMutation,
    UpdateDividendPreferenceMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateDividendPreferenceMutation,
    UpdateDividendPreferenceMutationVariables
  >(UpdateDividendPreferenceDocument, options);
}
export type UpdateDividendPreferenceMutationHookResult = ReturnType<
  typeof useUpdateDividendPreferenceMutation
>;
export type UpdateDividendPreferenceMutationResult =
  Apollo.MutationResult<UpdateDividendPreferenceMutation>;
export type UpdateDividendPreferenceMutationOptions =
  Apollo.BaseMutationOptions<
    UpdateDividendPreferenceMutation,
    UpdateDividendPreferenceMutationVariables
  >;
export const UpdateUserEntityAccountRequestStatusDocument = gql`
  mutation updateUserEntityAccountRequestStatus(
    $input: UpdateUserEntityAccountRequestStatusInput!
  ) {
    updateEntityAccountRequestStatus(input: $input) {
      ...AuthUser
    }
  }
  ${AuthUserFragmentDoc}
`;
export type UpdateUserEntityAccountRequestStatusMutationFn =
  Apollo.MutationFunction<
    UpdateUserEntityAccountRequestStatusMutation,
    UpdateUserEntityAccountRequestStatusMutationVariables
  >;

/**
 * __useUpdateUserEntityAccountRequestStatusMutation__
 *
 * To run a mutation, you first call `useUpdateUserEntityAccountRequestStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserEntityAccountRequestStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserEntityAccountRequestStatusMutation, { data, loading, error }] = useUpdateUserEntityAccountRequestStatusMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateUserEntityAccountRequestStatusMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateUserEntityAccountRequestStatusMutation,
    UpdateUserEntityAccountRequestStatusMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateUserEntityAccountRequestStatusMutation,
    UpdateUserEntityAccountRequestStatusMutationVariables
  >(UpdateUserEntityAccountRequestStatusDocument, options);
}
export type UpdateUserEntityAccountRequestStatusMutationHookResult = ReturnType<
  typeof useUpdateUserEntityAccountRequestStatusMutation
>;
export type UpdateUserEntityAccountRequestStatusMutationResult =
  Apollo.MutationResult<UpdateUserEntityAccountRequestStatusMutation>;
export type UpdateUserEntityAccountRequestStatusMutationOptions =
  Apollo.BaseMutationOptions<
    UpdateUserEntityAccountRequestStatusMutation,
    UpdateUserEntityAccountRequestStatusMutationVariables
  >;
export const UpdatePersonalDetailsAndAddressDocument = gql`
  mutation updatePersonalDetailsAndAddress(
    $personalDetailsId: ID!
    $addressId: ID!
    $userDetails: UserPersonalDetailsInput!
    $userAddress: GenericAddressInput!
  ) {
    updateUserPersonalDetails(
      userPersonalDetailsId: $personalDetailsId
      userPersonalDetailsInput: $userDetails
    ) {
      id
      firstName
      lastName
      middleName
    }
    updateUserAddress(
      userAddressId: $addressId
      userAddressInput: $userAddress
    ) {
      id
      addressType
      addressLine1
      addressLine2
      city
      state
      zipCode
      country
    }
  }
`;
export type UpdatePersonalDetailsAndAddressMutationFn = Apollo.MutationFunction<
  UpdatePersonalDetailsAndAddressMutation,
  UpdatePersonalDetailsAndAddressMutationVariables
>;

/**
 * __useUpdatePersonalDetailsAndAddressMutation__
 *
 * To run a mutation, you first call `useUpdatePersonalDetailsAndAddressMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePersonalDetailsAndAddressMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePersonalDetailsAndAddressMutation, { data, loading, error }] = useUpdatePersonalDetailsAndAddressMutation({
 *   variables: {
 *      personalDetailsId: // value for 'personalDetailsId'
 *      addressId: // value for 'addressId'
 *      userDetails: // value for 'userDetails'
 *      userAddress: // value for 'userAddress'
 *   },
 * });
 */
export function useUpdatePersonalDetailsAndAddressMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdatePersonalDetailsAndAddressMutation,
    UpdatePersonalDetailsAndAddressMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdatePersonalDetailsAndAddressMutation,
    UpdatePersonalDetailsAndAddressMutationVariables
  >(UpdatePersonalDetailsAndAddressDocument, options);
}
export type UpdatePersonalDetailsAndAddressMutationHookResult = ReturnType<
  typeof useUpdatePersonalDetailsAndAddressMutation
>;
export type UpdatePersonalDetailsAndAddressMutationResult =
  Apollo.MutationResult<UpdatePersonalDetailsAndAddressMutation>;
export type UpdatePersonalDetailsAndAddressMutationOptions =
  Apollo.BaseMutationOptions<
    UpdatePersonalDetailsAndAddressMutation,
    UpdatePersonalDetailsAndAddressMutationVariables
  >;
export const UpdateScheduledDepositConfigDocument = gql`
  mutation updateScheduledDepositConfig(
    $id: ID!
    $input: ScheduledDepositConfigInput!
  ) {
    updateScheduledDepositConfig(id: $id, input: $input) {
      ...ScheduledDepositConfig
    }
  }
  ${ScheduledDepositConfigFragmentDoc}
`;
export type UpdateScheduledDepositConfigMutationFn = Apollo.MutationFunction<
  UpdateScheduledDepositConfigMutation,
  UpdateScheduledDepositConfigMutationVariables
>;

/**
 * __useUpdateScheduledDepositConfigMutation__
 *
 * To run a mutation, you first call `useUpdateScheduledDepositConfigMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateScheduledDepositConfigMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateScheduledDepositConfigMutation, { data, loading, error }] = useUpdateScheduledDepositConfigMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateScheduledDepositConfigMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateScheduledDepositConfigMutation,
    UpdateScheduledDepositConfigMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateScheduledDepositConfigMutation,
    UpdateScheduledDepositConfigMutationVariables
  >(UpdateScheduledDepositConfigDocument, options);
}
export type UpdateScheduledDepositConfigMutationHookResult = ReturnType<
  typeof useUpdateScheduledDepositConfigMutation
>;
export type UpdateScheduledDepositConfigMutationResult =
  Apollo.MutationResult<UpdateScheduledDepositConfigMutation>;
export type UpdateScheduledDepositConfigMutationOptions =
  Apollo.BaseMutationOptions<
    UpdateScheduledDepositConfigMutation,
    UpdateScheduledDepositConfigMutationVariables
  >;
export const UpdateUserEmploymentDetailsDocument = gql`
  mutation updateUserEmploymentDetails(
    $employmentId: ID!
    $data: UserEmploymentDetailsInput!
  ) {
    updateUserEmploymentDetails(
      userEmploymentId: $employmentId
      userEmploymentDetailsInput: $data
    ) {
      id
      employerName
      employmentStatus
      yearsEmployed
      occupation
      addressLine1
      addressLine2
      city
      state
      zipCode
      country
    }
  }
`;
export type UpdateUserEmploymentDetailsMutationFn = Apollo.MutationFunction<
  UpdateUserEmploymentDetailsMutation,
  UpdateUserEmploymentDetailsMutationVariables
>;

/**
 * __useUpdateUserEmploymentDetailsMutation__
 *
 * To run a mutation, you first call `useUpdateUserEmploymentDetailsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserEmploymentDetailsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserEmploymentDetailsMutation, { data, loading, error }] = useUpdateUserEmploymentDetailsMutation({
 *   variables: {
 *      employmentId: // value for 'employmentId'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateUserEmploymentDetailsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateUserEmploymentDetailsMutation,
    UpdateUserEmploymentDetailsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateUserEmploymentDetailsMutation,
    UpdateUserEmploymentDetailsMutationVariables
  >(UpdateUserEmploymentDetailsDocument, options);
}
export type UpdateUserEmploymentDetailsMutationHookResult = ReturnType<
  typeof useUpdateUserEmploymentDetailsMutation
>;
export type UpdateUserEmploymentDetailsMutationResult =
  Apollo.MutationResult<UpdateUserEmploymentDetailsMutation>;
export type UpdateUserEmploymentDetailsMutationOptions =
  Apollo.BaseMutationOptions<
    UpdateUserEmploymentDetailsMutation,
    UpdateUserEmploymentDetailsMutationVariables
  >;
export const UpdateUserProfileDocument = gql`
  mutation updateUserProfile(
    $clearingAccountId: ID!
    $input: UpdateUserProfileInput!
  ) {
    updateUserProfile(clearingAccountId: $clearingAccountId, input: $input) {
      requestQueued
      user {
        ...AuthUser
      }
    }
  }
  ${AuthUserFragmentDoc}
`;
export type UpdateUserProfileMutationFn = Apollo.MutationFunction<
  UpdateUserProfileMutation,
  UpdateUserProfileMutationVariables
>;

/**
 * __useUpdateUserProfileMutation__
 *
 * To run a mutation, you first call `useUpdateUserProfileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserProfileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserProfileMutation, { data, loading, error }] = useUpdateUserProfileMutation({
 *   variables: {
 *      clearingAccountId: // value for 'clearingAccountId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateUserProfileMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateUserProfileMutation,
    UpdateUserProfileMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateUserProfileMutation,
    UpdateUserProfileMutationVariables
  >(UpdateUserProfileDocument, options);
}
export type UpdateUserProfileMutationHookResult = ReturnType<
  typeof useUpdateUserProfileMutation
>;
export type UpdateUserProfileMutationResult =
  Apollo.MutationResult<UpdateUserProfileMutation>;
export type UpdateUserProfileMutationOptions = Apollo.BaseMutationOptions<
  UpdateUserProfileMutation,
  UpdateUserProfileMutationVariables
>;
export const UpdateUserTrustedContactDocument = gql`
  mutation updateUserTrustedContact(
    $trustedContactId: ID!
    $data: UserTrustedContactInput!
  ) {
    updateUserTrustedContact(
      userTrustedContactId: $trustedContactId
      userTrustedContactInput: $data
    ) {
      id
      relationship
      firstName
      lastName
      middleName
      email
      phoneNumber
      phoneNumberType
      addressLine1
      addressLine2
      city
      state
      zipCode
      country
    }
  }
`;
export type UpdateUserTrustedContactMutationFn = Apollo.MutationFunction<
  UpdateUserTrustedContactMutation,
  UpdateUserTrustedContactMutationVariables
>;

/**
 * __useUpdateUserTrustedContactMutation__
 *
 * To run a mutation, you first call `useUpdateUserTrustedContactMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserTrustedContactMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserTrustedContactMutation, { data, loading, error }] = useUpdateUserTrustedContactMutation({
 *   variables: {
 *      trustedContactId: // value for 'trustedContactId'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateUserTrustedContactMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateUserTrustedContactMutation,
    UpdateUserTrustedContactMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateUserTrustedContactMutation,
    UpdateUserTrustedContactMutationVariables
  >(UpdateUserTrustedContactDocument, options);
}
export type UpdateUserTrustedContactMutationHookResult = ReturnType<
  typeof useUpdateUserTrustedContactMutation
>;
export type UpdateUserTrustedContactMutationResult =
  Apollo.MutationResult<UpdateUserTrustedContactMutation>;
export type UpdateUserTrustedContactMutationOptions =
  Apollo.BaseMutationOptions<
    UpdateUserTrustedContactMutation,
    UpdateUserTrustedContactMutationVariables
  >;
export const UploadDocumentDocument = gql`
  mutation uploadDocument($input: UploadDocumentInput!) {
    uploadDocument(input: $input) {
      id
      userId
      clearingAccountId
      name
      originalName
      description
      type
      location
    }
  }
`;
export type UploadDocumentMutationFn = Apollo.MutationFunction<
  UploadDocumentMutation,
  UploadDocumentMutationVariables
>;

/**
 * __useUploadDocumentMutation__
 *
 * To run a mutation, you first call `useUploadDocumentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadDocumentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadDocumentMutation, { data, loading, error }] = useUploadDocumentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUploadDocumentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UploadDocumentMutation,
    UploadDocumentMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UploadDocumentMutation,
    UploadDocumentMutationVariables
  >(UploadDocumentDocument, options);
}
export type UploadDocumentMutationHookResult = ReturnType<
  typeof useUploadDocumentMutation
>;
export type UploadDocumentMutationResult =
  Apollo.MutationResult<UploadDocumentMutation>;
export type UploadDocumentMutationOptions = Apollo.BaseMutationOptions<
  UploadDocumentMutation,
  UploadDocumentMutationVariables
>;
export const UpsertBusinessEntityDocument = gql`
  mutation upsertBusinessEntity($input: UpsertBusinessEntityInput!) {
    upsertBusinessEntity(input: $input) {
      id
      name
      taxId
      userTitle
      phoneNumber
      faxNumber
      industry
      entityType
      corporationSecretaryName
      multiplePartnersOrManagers
      foreignBankUSAgent
      largeTraderIds
      businessScope
      primaryFundingSource
      bankingInstitutionNames
      stateOfIncorporation
      address {
        id
        addressType
        addressLine1
        addressLine2
        city
        state
        zipCode
        country
      }
      mailingAddress {
        id
        addressType
        addressLine1
        addressLine2
        city
        state
        zipCode
        country
      }
    }
  }
`;
export type UpsertBusinessEntityMutationFn = Apollo.MutationFunction<
  UpsertBusinessEntityMutation,
  UpsertBusinessEntityMutationVariables
>;

/**
 * __useUpsertBusinessEntityMutation__
 *
 * To run a mutation, you first call `useUpsertBusinessEntityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertBusinessEntityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertBusinessEntityMutation, { data, loading, error }] = useUpsertBusinessEntityMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpsertBusinessEntityMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpsertBusinessEntityMutation,
    UpsertBusinessEntityMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpsertBusinessEntityMutation,
    UpsertBusinessEntityMutationVariables
  >(UpsertBusinessEntityDocument, options);
}
export type UpsertBusinessEntityMutationHookResult = ReturnType<
  typeof useUpsertBusinessEntityMutation
>;
export type UpsertBusinessEntityMutationResult =
  Apollo.MutationResult<UpsertBusinessEntityMutation>;
export type UpsertBusinessEntityMutationOptions = Apollo.BaseMutationOptions<
  UpsertBusinessEntityMutation,
  UpsertBusinessEntityMutationVariables
>;
export const UpsertBusinessEntityIndividualDocument = gql`
  mutation upsertBusinessEntityIndividual(
    $input: [UpsertBusinessEntityIndividualInput!]!
  ) {
    upsertBusinessEntityIndividual(input: $input) {
      id
      givenName
      familyName
      individualType
      address {
        id
        addressType
        addressLine1
        addressLine2
        city
        state
        zipCode
        country
      }
    }
  }
`;
export type UpsertBusinessEntityIndividualMutationFn = Apollo.MutationFunction<
  UpsertBusinessEntityIndividualMutation,
  UpsertBusinessEntityIndividualMutationVariables
>;

/**
 * __useUpsertBusinessEntityIndividualMutation__
 *
 * To run a mutation, you first call `useUpsertBusinessEntityIndividualMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertBusinessEntityIndividualMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertBusinessEntityIndividualMutation, { data, loading, error }] = useUpsertBusinessEntityIndividualMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpsertBusinessEntityIndividualMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpsertBusinessEntityIndividualMutation,
    UpsertBusinessEntityIndividualMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpsertBusinessEntityIndividualMutation,
    UpsertBusinessEntityIndividualMutationVariables
  >(UpsertBusinessEntityIndividualDocument, options);
}
export type UpsertBusinessEntityIndividualMutationHookResult = ReturnType<
  typeof useUpsertBusinessEntityIndividualMutation
>;
export type UpsertBusinessEntityIndividualMutationResult =
  Apollo.MutationResult<UpsertBusinessEntityIndividualMutation>;
export type UpsertBusinessEntityIndividualMutationOptions =
  Apollo.BaseMutationOptions<
    UpsertBusinessEntityIndividualMutation,
    UpsertBusinessEntityIndividualMutationVariables
  >;
export const UpsertBusinessEntityInvestmentProfileDocument = gql`
  mutation upsertBusinessEntityInvestmentProfile(
    $input: UpsertBusinessEntityInvestmentProfileInput!
  ) {
    upsertBusinessEntityInvestmentProfile(input: $input) {
      id
      netWorthMin
      netWorthMax
      annualIncomeMin
      annualIncomeMax
      investmentExperience
      investmentObjective
      investmentPlan
      riskTolerance
      liquidityTimeHorizon
      liquidityNeeds
      withdrawFrequency
      depositSource
      initialDepositAmount
    }
  }
`;
export type UpsertBusinessEntityInvestmentProfileMutationFn =
  Apollo.MutationFunction<
    UpsertBusinessEntityInvestmentProfileMutation,
    UpsertBusinessEntityInvestmentProfileMutationVariables
  >;

/**
 * __useUpsertBusinessEntityInvestmentProfileMutation__
 *
 * To run a mutation, you first call `useUpsertBusinessEntityInvestmentProfileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertBusinessEntityInvestmentProfileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertBusinessEntityInvestmentProfileMutation, { data, loading, error }] = useUpsertBusinessEntityInvestmentProfileMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpsertBusinessEntityInvestmentProfileMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpsertBusinessEntityInvestmentProfileMutation,
    UpsertBusinessEntityInvestmentProfileMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpsertBusinessEntityInvestmentProfileMutation,
    UpsertBusinessEntityInvestmentProfileMutationVariables
  >(UpsertBusinessEntityInvestmentProfileDocument, options);
}
export type UpsertBusinessEntityInvestmentProfileMutationHookResult =
  ReturnType<typeof useUpsertBusinessEntityInvestmentProfileMutation>;
export type UpsertBusinessEntityInvestmentProfileMutationResult =
  Apollo.MutationResult<UpsertBusinessEntityInvestmentProfileMutation>;
export type UpsertBusinessEntityInvestmentProfileMutationOptions =
  Apollo.BaseMutationOptions<
    UpsertBusinessEntityInvestmentProfileMutation,
    UpsertBusinessEntityInvestmentProfileMutationVariables
  >;
export const UpsertDirectIndexCustomizationDocument = gql`
  mutation upsertDirectIndexCustomization(
    $input: DirectIndexCustomizationInput!
  ) {
    upsertDirectIndexCustomization(input: $input) {
      id
      addSecurityIds {
        securityId
        security {
          ...Security
        }
      }
      removeSecurityIds {
        securityId
        security {
          ...Security
        }
      }
      removeGICSSectorIds
    }
  }
  ${SecurityFragmentDoc}
`;
export type UpsertDirectIndexCustomizationMutationFn = Apollo.MutationFunction<
  UpsertDirectIndexCustomizationMutation,
  UpsertDirectIndexCustomizationMutationVariables
>;

/**
 * __useUpsertDirectIndexCustomizationMutation__
 *
 * To run a mutation, you first call `useUpsertDirectIndexCustomizationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertDirectIndexCustomizationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertDirectIndexCustomizationMutation, { data, loading, error }] = useUpsertDirectIndexCustomizationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpsertDirectIndexCustomizationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpsertDirectIndexCustomizationMutation,
    UpsertDirectIndexCustomizationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpsertDirectIndexCustomizationMutation,
    UpsertDirectIndexCustomizationMutationVariables
  >(UpsertDirectIndexCustomizationDocument, options);
}
export type UpsertDirectIndexCustomizationMutationHookResult = ReturnType<
  typeof useUpsertDirectIndexCustomizationMutation
>;
export type UpsertDirectIndexCustomizationMutationResult =
  Apollo.MutationResult<UpsertDirectIndexCustomizationMutation>;
export type UpsertDirectIndexCustomizationMutationOptions =
  Apollo.BaseMutationOptions<
    UpsertDirectIndexCustomizationMutation,
    UpsertDirectIndexCustomizationMutationVariables
  >;
export const UpsertTrustEntityDocument = gql`
  mutation upsertTrustEntity($input: UpsertTrustEntityInput!) {
    upsertTrustEntity(input: $input) {
      ...TrustEntity
    }
  }
  ${TrustEntityFragmentDoc}
`;
export type UpsertTrustEntityMutationFn = Apollo.MutationFunction<
  UpsertTrustEntityMutation,
  UpsertTrustEntityMutationVariables
>;

/**
 * __useUpsertTrustEntityMutation__
 *
 * To run a mutation, you first call `useUpsertTrustEntityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertTrustEntityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertTrustEntityMutation, { data, loading, error }] = useUpsertTrustEntityMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpsertTrustEntityMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpsertTrustEntityMutation,
    UpsertTrustEntityMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpsertTrustEntityMutation,
    UpsertTrustEntityMutationVariables
  >(UpsertTrustEntityDocument, options);
}
export type UpsertTrustEntityMutationHookResult = ReturnType<
  typeof useUpsertTrustEntityMutation
>;
export type UpsertTrustEntityMutationResult =
  Apollo.MutationResult<UpsertTrustEntityMutation>;
export type UpsertTrustEntityMutationOptions = Apollo.BaseMutationOptions<
  UpsertTrustEntityMutation,
  UpsertTrustEntityMutationVariables
>;
export const UpsertTrustTrusteeDocument = gql`
  mutation upsertTrustTrustee($input: UpsertTrustEntityIndividualInput!) {
    upsertTrustEntityIndividual(input: $input) {
      ...Trustee
    }
  }
  ${TrusteeFragmentDoc}
`;
export type UpsertTrustTrusteeMutationFn = Apollo.MutationFunction<
  UpsertTrustTrusteeMutation,
  UpsertTrustTrusteeMutationVariables
>;

/**
 * __useUpsertTrustTrusteeMutation__
 *
 * To run a mutation, you first call `useUpsertTrustTrusteeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertTrustTrusteeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertTrustTrusteeMutation, { data, loading, error }] = useUpsertTrustTrusteeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpsertTrustTrusteeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpsertTrustTrusteeMutation,
    UpsertTrustTrusteeMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpsertTrustTrusteeMutation,
    UpsertTrustTrusteeMutationVariables
  >(UpsertTrustTrusteeDocument, options);
}
export type UpsertTrustTrusteeMutationHookResult = ReturnType<
  typeof useUpsertTrustTrusteeMutation
>;
export type UpsertTrustTrusteeMutationResult =
  Apollo.MutationResult<UpsertTrustTrusteeMutation>;
export type UpsertTrustTrusteeMutationOptions = Apollo.BaseMutationOptions<
  UpsertTrustTrusteeMutation,
  UpsertTrustTrusteeMutationVariables
>;
export const UpsertUserAddressDocument = gql`
  mutation upsertUserAddress($input: GenericAddressInput!) {
    upsertUserAddress(userAddressInput: $input) {
      id
      addressType
      addressLine1
      addressLine2
      city
      state
      zipCode
      country
    }
  }
`;
export type UpsertUserAddressMutationFn = Apollo.MutationFunction<
  UpsertUserAddressMutation,
  UpsertUserAddressMutationVariables
>;

/**
 * __useUpsertUserAddressMutation__
 *
 * To run a mutation, you first call `useUpsertUserAddressMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertUserAddressMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertUserAddressMutation, { data, loading, error }] = useUpsertUserAddressMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpsertUserAddressMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpsertUserAddressMutation,
    UpsertUserAddressMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpsertUserAddressMutation,
    UpsertUserAddressMutationVariables
  >(UpsertUserAddressDocument, options);
}
export type UpsertUserAddressMutationHookResult = ReturnType<
  typeof useUpsertUserAddressMutation
>;
export type UpsertUserAddressMutationResult =
  Apollo.MutationResult<UpsertUserAddressMutation>;
export type UpsertUserAddressMutationOptions = Apollo.BaseMutationOptions<
  UpsertUserAddressMutation,
  UpsertUserAddressMutationVariables
>;
export const UpsertUserDisclosuresDocument = gql`
  mutation upsertUserDisclosures($data: UserDisclosuresInput!) {
    upsertUserDisclosures(userDisclosuresInput: $data) {
      affiliatedExchangeOrFINRA
      affiliatedExchangeOrFINRAEntityName
      firmName
      controlPerson
      companySymbols
      politicallyExposed
      politicalOrganization
      immediateFamily
    }
  }
`;
export type UpsertUserDisclosuresMutationFn = Apollo.MutationFunction<
  UpsertUserDisclosuresMutation,
  UpsertUserDisclosuresMutationVariables
>;

/**
 * __useUpsertUserDisclosuresMutation__
 *
 * To run a mutation, you first call `useUpsertUserDisclosuresMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertUserDisclosuresMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertUserDisclosuresMutation, { data, loading, error }] = useUpsertUserDisclosuresMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpsertUserDisclosuresMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpsertUserDisclosuresMutation,
    UpsertUserDisclosuresMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpsertUserDisclosuresMutation,
    UpsertUserDisclosuresMutationVariables
  >(UpsertUserDisclosuresDocument, options);
}
export type UpsertUserDisclosuresMutationHookResult = ReturnType<
  typeof useUpsertUserDisclosuresMutation
>;
export type UpsertUserDisclosuresMutationResult =
  Apollo.MutationResult<UpsertUserDisclosuresMutation>;
export type UpsertUserDisclosuresMutationOptions = Apollo.BaseMutationOptions<
  UpsertUserDisclosuresMutation,
  UpsertUserDisclosuresMutationVariables
>;
export const UpsertUserIdentityDetailsDocument = gql`
  mutation upsertUserIdentityDetails($data: UserIdentityDetailsInput!) {
    upsertUserIdentityDetails(userIdentityDetailsInput: $data) {
      id
      ssn
      dateOfBirth
      citizenshipCountry
      permanentResident
      visaStatus
      visaExpiration
      maritalStatus
      numberOfDependents
      governmentIdNumber
      idExpirationDate
      idIssuingStateOrCountry
      idType
    }
  }
`;
export type UpsertUserIdentityDetailsMutationFn = Apollo.MutationFunction<
  UpsertUserIdentityDetailsMutation,
  UpsertUserIdentityDetailsMutationVariables
>;

/**
 * __useUpsertUserIdentityDetailsMutation__
 *
 * To run a mutation, you first call `useUpsertUserIdentityDetailsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertUserIdentityDetailsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertUserIdentityDetailsMutation, { data, loading, error }] = useUpsertUserIdentityDetailsMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpsertUserIdentityDetailsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpsertUserIdentityDetailsMutation,
    UpsertUserIdentityDetailsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpsertUserIdentityDetailsMutation,
    UpsertUserIdentityDetailsMutationVariables
  >(UpsertUserIdentityDetailsDocument, options);
}
export type UpsertUserIdentityDetailsMutationHookResult = ReturnType<
  typeof useUpsertUserIdentityDetailsMutation
>;
export type UpsertUserIdentityDetailsMutationResult =
  Apollo.MutationResult<UpsertUserIdentityDetailsMutation>;
export type UpsertUserIdentityDetailsMutationOptions =
  Apollo.BaseMutationOptions<
    UpsertUserIdentityDetailsMutation,
    UpsertUserIdentityDetailsMutationVariables
  >;
export const UpsertUserInvestmentProfileDocument = gql`
  mutation upsertUserInvestmentProfile($data: UserInvestmentProfileInput!) {
    upsertUserInvestmentProfile(userInvestmentProfileInput: $data) {
      id
      netWorthMin
      netWorthMax
      annualIncomeMin
      annualIncomeMax
      investmentExperience
      investmentObjective
      riskTolerance
      liquidityTimeHorizon
      liquidityNeeds
    }
  }
`;
export type UpsertUserInvestmentProfileMutationFn = Apollo.MutationFunction<
  UpsertUserInvestmentProfileMutation,
  UpsertUserInvestmentProfileMutationVariables
>;

/**
 * __useUpsertUserInvestmentProfileMutation__
 *
 * To run a mutation, you first call `useUpsertUserInvestmentProfileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertUserInvestmentProfileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertUserInvestmentProfileMutation, { data, loading, error }] = useUpsertUserInvestmentProfileMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpsertUserInvestmentProfileMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpsertUserInvestmentProfileMutation,
    UpsertUserInvestmentProfileMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpsertUserInvestmentProfileMutation,
    UpsertUserInvestmentProfileMutationVariables
  >(UpsertUserInvestmentProfileDocument, options);
}
export type UpsertUserInvestmentProfileMutationHookResult = ReturnType<
  typeof useUpsertUserInvestmentProfileMutation
>;
export type UpsertUserInvestmentProfileMutationResult =
  Apollo.MutationResult<UpsertUserInvestmentProfileMutation>;
export type UpsertUserInvestmentProfileMutationOptions =
  Apollo.BaseMutationOptions<
    UpsertUserInvestmentProfileMutation,
    UpsertUserInvestmentProfileMutationVariables
  >;
export const UpsertUserPersonalDetailsDocument = gql`
  mutation upsertUserPersonalDetails($input: UserPersonalDetailsInput!) {
    upsertUserPersonalDetails(userPersonalDetailsInput: $input) {
      id
      firstName
      lastName
      middleName
    }
  }
`;
export type UpsertUserPersonalDetailsMutationFn = Apollo.MutationFunction<
  UpsertUserPersonalDetailsMutation,
  UpsertUserPersonalDetailsMutationVariables
>;

/**
 * __useUpsertUserPersonalDetailsMutation__
 *
 * To run a mutation, you first call `useUpsertUserPersonalDetailsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertUserPersonalDetailsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertUserPersonalDetailsMutation, { data, loading, error }] = useUpsertUserPersonalDetailsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpsertUserPersonalDetailsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpsertUserPersonalDetailsMutation,
    UpsertUserPersonalDetailsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpsertUserPersonalDetailsMutation,
    UpsertUserPersonalDetailsMutationVariables
  >(UpsertUserPersonalDetailsDocument, options);
}
export type UpsertUserPersonalDetailsMutationHookResult = ReturnType<
  typeof useUpsertUserPersonalDetailsMutation
>;
export type UpsertUserPersonalDetailsMutationResult =
  Apollo.MutationResult<UpsertUserPersonalDetailsMutation>;
export type UpsertUserPersonalDetailsMutationOptions =
  Apollo.BaseMutationOptions<
    UpsertUserPersonalDetailsMutation,
    UpsertUserPersonalDetailsMutationVariables
  >;
export const UpsertUserServiceProfileDocument = gql`
  mutation upsertUserServiceProfile($data: UserServiceProfileInput!) {
    upsertUserServiceProfile(userServiceProfileInput: $data) {
      id
      sweepInstruction
      dividendReinvestment
    }
  }
`;
export type UpsertUserServiceProfileMutationFn = Apollo.MutationFunction<
  UpsertUserServiceProfileMutation,
  UpsertUserServiceProfileMutationVariables
>;

/**
 * __useUpsertUserServiceProfileMutation__
 *
 * To run a mutation, you first call `useUpsertUserServiceProfileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertUserServiceProfileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertUserServiceProfileMutation, { data, loading, error }] = useUpsertUserServiceProfileMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpsertUserServiceProfileMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpsertUserServiceProfileMutation,
    UpsertUserServiceProfileMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpsertUserServiceProfileMutation,
    UpsertUserServiceProfileMutationVariables
  >(UpsertUserServiceProfileDocument, options);
}
export type UpsertUserServiceProfileMutationHookResult = ReturnType<
  typeof useUpsertUserServiceProfileMutation
>;
export type UpsertUserServiceProfileMutationResult =
  Apollo.MutationResult<UpsertUserServiceProfileMutation>;
export type UpsertUserServiceProfileMutationOptions =
  Apollo.BaseMutationOptions<
    UpsertUserServiceProfileMutation,
    UpsertUserServiceProfileMutationVariables
  >;
export const UpsertUserTaxRatesDocument = gql`
  mutation upsertUserTaxRates($data: UserTaxRatesInput!) {
    upsertUserTaxRates(userTaxRatesInput: $data) {
      id
      taxFilingStatus
      federalIncomeTaxRate
      stateIncomeTaxRate
      federalCapitalGainsTaxRate
      stateCapitalGainsTaxRate
    }
  }
`;
export type UpsertUserTaxRatesMutationFn = Apollo.MutationFunction<
  UpsertUserTaxRatesMutation,
  UpsertUserTaxRatesMutationVariables
>;

/**
 * __useUpsertUserTaxRatesMutation__
 *
 * To run a mutation, you first call `useUpsertUserTaxRatesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertUserTaxRatesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertUserTaxRatesMutation, { data, loading, error }] = useUpsertUserTaxRatesMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpsertUserTaxRatesMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpsertUserTaxRatesMutation,
    UpsertUserTaxRatesMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpsertUserTaxRatesMutation,
    UpsertUserTaxRatesMutationVariables
  >(UpsertUserTaxRatesDocument, options);
}
export type UpsertUserTaxRatesMutationHookResult = ReturnType<
  typeof useUpsertUserTaxRatesMutation
>;
export type UpsertUserTaxRatesMutationResult =
  Apollo.MutationResult<UpsertUserTaxRatesMutation>;
export type UpsertUserTaxRatesMutationOptions = Apollo.BaseMutationOptions<
  UpsertUserTaxRatesMutation,
  UpsertUserTaxRatesMutationVariables
>;
export const ValidateMfaChallengeDocument = gql`
  mutation validateMfaChallenge($input: ValidateMfaPhoneChallengeInput!) {
    validateMfaChallenge(input: $input) {
      status
    }
  }
`;
export type ValidateMfaChallengeMutationFn = Apollo.MutationFunction<
  ValidateMfaChallengeMutation,
  ValidateMfaChallengeMutationVariables
>;

/**
 * __useValidateMfaChallengeMutation__
 *
 * To run a mutation, you first call `useValidateMfaChallengeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useValidateMfaChallengeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [validateMfaChallengeMutation, { data, loading, error }] = useValidateMfaChallengeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useValidateMfaChallengeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ValidateMfaChallengeMutation,
    ValidateMfaChallengeMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ValidateMfaChallengeMutation,
    ValidateMfaChallengeMutationVariables
  >(ValidateMfaChallengeDocument, options);
}
export type ValidateMfaChallengeMutationHookResult = ReturnType<
  typeof useValidateMfaChallengeMutation
>;
export type ValidateMfaChallengeMutationResult =
  Apollo.MutationResult<ValidateMfaChallengeMutation>;
export type ValidateMfaChallengeMutationOptions = Apollo.BaseMutationOptions<
  ValidateMfaChallengeMutation,
  ValidateMfaChallengeMutationVariables
>;
export const DirectIndexPerformanceDataDocument = gql`
  query directIndexPerformanceData($subAccountId: ID!) {
    getDirectIndexPerformanceData(subAccountId: $subAccountId) {
      ...DirectIndexPerformanceData
    }
  }
  ${DirectIndexPerformanceDataFragmentDoc}
`;

/**
 * __useDirectIndexPerformanceDataQuery__
 *
 * To run a query within a React component, call `useDirectIndexPerformanceDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useDirectIndexPerformanceDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDirectIndexPerformanceDataQuery({
 *   variables: {
 *      subAccountId: // value for 'subAccountId'
 *   },
 * });
 */
export function useDirectIndexPerformanceDataQuery(
  baseOptions: Apollo.QueryHookOptions<
    DirectIndexPerformanceDataQuery,
    DirectIndexPerformanceDataQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    DirectIndexPerformanceDataQuery,
    DirectIndexPerformanceDataQueryVariables
  >(DirectIndexPerformanceDataDocument, options);
}
export function useDirectIndexPerformanceDataLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    DirectIndexPerformanceDataQuery,
    DirectIndexPerformanceDataQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    DirectIndexPerformanceDataQuery,
    DirectIndexPerformanceDataQueryVariables
  >(DirectIndexPerformanceDataDocument, options);
}
export type DirectIndexPerformanceDataQueryHookResult = ReturnType<
  typeof useDirectIndexPerformanceDataQuery
>;
export type DirectIndexPerformanceDataLazyQueryHookResult = ReturnType<
  typeof useDirectIndexPerformanceDataLazyQuery
>;
export type DirectIndexPerformanceDataQueryResult = Apollo.QueryResult<
  DirectIndexPerformanceDataQuery,
  DirectIndexPerformanceDataQueryVariables
>;
export const DemoDirectIndexPerformanceDataDocument = gql`
  query demoDirectIndexPerformanceData($subAccountId: ID!) {
    getDirectIndexPerformanceData: demoGetDirectIndexPerformanceData(
      subAccountId: $subAccountId
    ) {
      ...DirectIndexPerformanceData
    }
  }
  ${DirectIndexPerformanceDataFragmentDoc}
`;

/**
 * __useDemoDirectIndexPerformanceDataQuery__
 *
 * To run a query within a React component, call `useDemoDirectIndexPerformanceDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useDemoDirectIndexPerformanceDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDemoDirectIndexPerformanceDataQuery({
 *   variables: {
 *      subAccountId: // value for 'subAccountId'
 *   },
 * });
 */
export function useDemoDirectIndexPerformanceDataQuery(
  baseOptions: Apollo.QueryHookOptions<
    DemoDirectIndexPerformanceDataQuery,
    DemoDirectIndexPerformanceDataQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    DemoDirectIndexPerformanceDataQuery,
    DemoDirectIndexPerformanceDataQueryVariables
  >(DemoDirectIndexPerformanceDataDocument, options);
}
export function useDemoDirectIndexPerformanceDataLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    DemoDirectIndexPerformanceDataQuery,
    DemoDirectIndexPerformanceDataQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    DemoDirectIndexPerformanceDataQuery,
    DemoDirectIndexPerformanceDataQueryVariables
  >(DemoDirectIndexPerformanceDataDocument, options);
}
export type DemoDirectIndexPerformanceDataQueryHookResult = ReturnType<
  typeof useDemoDirectIndexPerformanceDataQuery
>;
export type DemoDirectIndexPerformanceDataLazyQueryHookResult = ReturnType<
  typeof useDemoDirectIndexPerformanceDataLazyQuery
>;
export type DemoDirectIndexPerformanceDataQueryResult = Apollo.QueryResult<
  DemoDirectIndexPerformanceDataQuery,
  DemoDirectIndexPerformanceDataQueryVariables
>;
export const DirectIndexPerformanceDataV2Document = gql`
  query directIndexPerformanceDataV2(
    $subAccountId: ID!
    $performanceDataPeriod: PerformanceDataPeriod
  ) {
    getDirectIndexPerformanceDataV2(
      input: {
        subAccountId: $subAccountId
        performanceDataPeriod: $performanceDataPeriod
      }
    ) {
      ...DirectIndexPerformanceData
    }
  }
  ${DirectIndexPerformanceDataFragmentDoc}
`;

/**
 * __useDirectIndexPerformanceDataV2Query__
 *
 * To run a query within a React component, call `useDirectIndexPerformanceDataV2Query` and pass it any options that fit your needs.
 * When your component renders, `useDirectIndexPerformanceDataV2Query` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDirectIndexPerformanceDataV2Query({
 *   variables: {
 *      subAccountId: // value for 'subAccountId'
 *      performanceDataPeriod: // value for 'performanceDataPeriod'
 *   },
 * });
 */
export function useDirectIndexPerformanceDataV2Query(
  baseOptions: Apollo.QueryHookOptions<
    DirectIndexPerformanceDataV2Query,
    DirectIndexPerformanceDataV2QueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    DirectIndexPerformanceDataV2Query,
    DirectIndexPerformanceDataV2QueryVariables
  >(DirectIndexPerformanceDataV2Document, options);
}
export function useDirectIndexPerformanceDataV2LazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    DirectIndexPerformanceDataV2Query,
    DirectIndexPerformanceDataV2QueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    DirectIndexPerformanceDataV2Query,
    DirectIndexPerformanceDataV2QueryVariables
  >(DirectIndexPerformanceDataV2Document, options);
}
export type DirectIndexPerformanceDataV2QueryHookResult = ReturnType<
  typeof useDirectIndexPerformanceDataV2Query
>;
export type DirectIndexPerformanceDataV2LazyQueryHookResult = ReturnType<
  typeof useDirectIndexPerformanceDataV2LazyQuery
>;
export type DirectIndexPerformanceDataV2QueryResult = Apollo.QueryResult<
  DirectIndexPerformanceDataV2Query,
  DirectIndexPerformanceDataV2QueryVariables
>;
export const DemoDirectIndexPerformanceDataV2Document = gql`
  query demoDirectIndexPerformanceDataV2(
    $subAccountId: ID!
    $performanceDataPeriod: PerformanceDataPeriod
  ) {
    getDirectIndexPerformanceDataV2: demoGetDirectIndexPerformanceDataV2(
      input: {
        subAccountId: $subAccountId
        performanceDataPeriod: $performanceDataPeriod
      }
    ) {
      ...DirectIndexPerformanceData
    }
  }
  ${DirectIndexPerformanceDataFragmentDoc}
`;

/**
 * __useDemoDirectIndexPerformanceDataV2Query__
 *
 * To run a query within a React component, call `useDemoDirectIndexPerformanceDataV2Query` and pass it any options that fit your needs.
 * When your component renders, `useDemoDirectIndexPerformanceDataV2Query` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDemoDirectIndexPerformanceDataV2Query({
 *   variables: {
 *      subAccountId: // value for 'subAccountId'
 *      performanceDataPeriod: // value for 'performanceDataPeriod'
 *   },
 * });
 */
export function useDemoDirectIndexPerformanceDataV2Query(
  baseOptions: Apollo.QueryHookOptions<
    DemoDirectIndexPerformanceDataV2Query,
    DemoDirectIndexPerformanceDataV2QueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    DemoDirectIndexPerformanceDataV2Query,
    DemoDirectIndexPerformanceDataV2QueryVariables
  >(DemoDirectIndexPerformanceDataV2Document, options);
}
export function useDemoDirectIndexPerformanceDataV2LazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    DemoDirectIndexPerformanceDataV2Query,
    DemoDirectIndexPerformanceDataV2QueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    DemoDirectIndexPerformanceDataV2Query,
    DemoDirectIndexPerformanceDataV2QueryVariables
  >(DemoDirectIndexPerformanceDataV2Document, options);
}
export type DemoDirectIndexPerformanceDataV2QueryHookResult = ReturnType<
  typeof useDemoDirectIndexPerformanceDataV2Query
>;
export type DemoDirectIndexPerformanceDataV2LazyQueryHookResult = ReturnType<
  typeof useDemoDirectIndexPerformanceDataV2LazyQuery
>;
export type DemoDirectIndexPerformanceDataV2QueryResult = Apollo.QueryResult<
  DemoDirectIndexPerformanceDataV2Query,
  DemoDirectIndexPerformanceDataV2QueryVariables
>;
export const InterestRateDocument = gql`
  query InterestRate {
    marginInterest: getCurrentMarginInterest {
      id
      value
      effectiveDate
      note
    }
  }
`;

/**
 * __useInterestRateQuery__
 *
 * To run a query within a React component, call `useInterestRateQuery` and pass it any options that fit your needs.
 * When your component renders, `useInterestRateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInterestRateQuery({
 *   variables: {
 *   },
 * });
 */
export function useInterestRateQuery(
  baseOptions?: Apollo.QueryHookOptions<
    InterestRateQuery,
    InterestRateQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<InterestRateQuery, InterestRateQueryVariables>(
    InterestRateDocument,
    options
  );
}
export function useInterestRateLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    InterestRateQuery,
    InterestRateQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<InterestRateQuery, InterestRateQueryVariables>(
    InterestRateDocument,
    options
  );
}
export type InterestRateQueryHookResult = ReturnType<
  typeof useInterestRateQuery
>;
export type InterestRateLazyQueryHookResult = ReturnType<
  typeof useInterestRateLazyQuery
>;
export type InterestRateQueryResult = Apollo.QueryResult<
  InterestRateQuery,
  InterestRateQueryVariables
>;
export const InterestRateProjectionDocument = gql`
  query InterestRateProjection {
    rates: getMarginInterestForecast {
      forecasts {
        fromDate
        rate
      }
      url: fomcMeetingNotesUrl
    }
  }
`;

/**
 * __useInterestRateProjectionQuery__
 *
 * To run a query within a React component, call `useInterestRateProjectionQuery` and pass it any options that fit your needs.
 * When your component renders, `useInterestRateProjectionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInterestRateProjectionQuery({
 *   variables: {
 *   },
 * });
 */
export function useInterestRateProjectionQuery(
  baseOptions?: Apollo.QueryHookOptions<
    InterestRateProjectionQuery,
    InterestRateProjectionQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    InterestRateProjectionQuery,
    InterestRateProjectionQueryVariables
  >(InterestRateProjectionDocument, options);
}
export function useInterestRateProjectionLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    InterestRateProjectionQuery,
    InterestRateProjectionQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    InterestRateProjectionQuery,
    InterestRateProjectionQueryVariables
  >(InterestRateProjectionDocument, options);
}
export type InterestRateProjectionQueryHookResult = ReturnType<
  typeof useInterestRateProjectionQuery
>;
export type InterestRateProjectionLazyQueryHookResult = ReturnType<
  typeof useInterestRateProjectionLazyQuery
>;
export type InterestRateProjectionQueryResult = Apollo.QueryResult<
  InterestRateProjectionQuery,
  InterestRateProjectionQueryVariables
>;
export const IntraDayMarginCallDataDocument = gql`
  query intraDayMarginCallData($clearingAccountId: ID!) {
    clearingAccount(input: { id: $clearingAccountId, type: ID }) {
      id
      intraDayMarginCallData {
        accountState
        callAmount
      }
    }
  }
`;

/**
 * __useIntraDayMarginCallDataQuery__
 *
 * To run a query within a React component, call `useIntraDayMarginCallDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useIntraDayMarginCallDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIntraDayMarginCallDataQuery({
 *   variables: {
 *      clearingAccountId: // value for 'clearingAccountId'
 *   },
 * });
 */
export function useIntraDayMarginCallDataQuery(
  baseOptions: Apollo.QueryHookOptions<
    IntraDayMarginCallDataQuery,
    IntraDayMarginCallDataQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    IntraDayMarginCallDataQuery,
    IntraDayMarginCallDataQueryVariables
  >(IntraDayMarginCallDataDocument, options);
}
export function useIntraDayMarginCallDataLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    IntraDayMarginCallDataQuery,
    IntraDayMarginCallDataQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    IntraDayMarginCallDataQuery,
    IntraDayMarginCallDataQueryVariables
  >(IntraDayMarginCallDataDocument, options);
}
export type IntraDayMarginCallDataQueryHookResult = ReturnType<
  typeof useIntraDayMarginCallDataQuery
>;
export type IntraDayMarginCallDataLazyQueryHookResult = ReturnType<
  typeof useIntraDayMarginCallDataLazyQuery
>;
export type IntraDayMarginCallDataQueryResult = Apollo.QueryResult<
  IntraDayMarginCallDataQuery,
  IntraDayMarginCallDataQueryVariables
>;
export const OidcClientApplicationDetailsDocument = gql`
  query oidcClientApplicationDetails(
    $input: OidcClientApplicationDetailsInput!
  ) {
    oidcClientApplicationDetails(input: $input) {
      logoUrl
      applicationName
      aggregator
    }
  }
`;

/**
 * __useOidcClientApplicationDetailsQuery__
 *
 * To run a query within a React component, call `useOidcClientApplicationDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useOidcClientApplicationDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOidcClientApplicationDetailsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useOidcClientApplicationDetailsQuery(
  baseOptions: Apollo.QueryHookOptions<
    OidcClientApplicationDetailsQuery,
    OidcClientApplicationDetailsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    OidcClientApplicationDetailsQuery,
    OidcClientApplicationDetailsQueryVariables
  >(OidcClientApplicationDetailsDocument, options);
}
export function useOidcClientApplicationDetailsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    OidcClientApplicationDetailsQuery,
    OidcClientApplicationDetailsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    OidcClientApplicationDetailsQuery,
    OidcClientApplicationDetailsQueryVariables
  >(OidcClientApplicationDetailsDocument, options);
}
export type OidcClientApplicationDetailsQueryHookResult = ReturnType<
  typeof useOidcClientApplicationDetailsQuery
>;
export type OidcClientApplicationDetailsLazyQueryHookResult = ReturnType<
  typeof useOidcClientApplicationDetailsLazyQuery
>;
export type OidcClientApplicationDetailsQueryResult = Apollo.QueryResult<
  OidcClientApplicationDetailsQuery,
  OidcClientApplicationDetailsQueryVariables
>;
export const HistoricalDeltasDocument = gql`
  query historicalDeltas($input: HistoricalDeltaInput!) {
    historicalDeltas(input: $input) {
      symbol
      prices {
        date
        close
      }
      deltas {
        date
        close
      }
    }
  }
`;

/**
 * __useHistoricalDeltasQuery__
 *
 * To run a query within a React component, call `useHistoricalDeltasQuery` and pass it any options that fit your needs.
 * When your component renders, `useHistoricalDeltasQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHistoricalDeltasQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useHistoricalDeltasQuery(
  baseOptions: Apollo.QueryHookOptions<
    HistoricalDeltasQuery,
    HistoricalDeltasQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<HistoricalDeltasQuery, HistoricalDeltasQueryVariables>(
    HistoricalDeltasDocument,
    options
  );
}
export function useHistoricalDeltasLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    HistoricalDeltasQuery,
    HistoricalDeltasQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    HistoricalDeltasQuery,
    HistoricalDeltasQueryVariables
  >(HistoricalDeltasDocument, options);
}
export type HistoricalDeltasQueryHookResult = ReturnType<
  typeof useHistoricalDeltasQuery
>;
export type HistoricalDeltasLazyQueryHookResult = ReturnType<
  typeof useHistoricalDeltasLazyQuery
>;
export type HistoricalDeltasQueryResult = Apollo.QueryResult<
  HistoricalDeltasQuery,
  HistoricalDeltasQueryVariables
>;
export const BetaValuesDocument = gql`
  query betaValues($input: BetaValueInput!) {
    betaValues(input: $input) {
      symbol
      beta
    }
  }
`;

/**
 * __useBetaValuesQuery__
 *
 * To run a query within a React component, call `useBetaValuesQuery` and pass it any options that fit your needs.
 * When your component renders, `useBetaValuesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBetaValuesQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useBetaValuesQuery(
  baseOptions: Apollo.QueryHookOptions<
    BetaValuesQuery,
    BetaValuesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<BetaValuesQuery, BetaValuesQueryVariables>(
    BetaValuesDocument,
    options
  );
}
export function useBetaValuesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    BetaValuesQuery,
    BetaValuesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<BetaValuesQuery, BetaValuesQueryVariables>(
    BetaValuesDocument,
    options
  );
}
export type BetaValuesQueryHookResult = ReturnType<typeof useBetaValuesQuery>;
export type BetaValuesLazyQueryHookResult = ReturnType<
  typeof useBetaValuesLazyQuery
>;
export type BetaValuesQueryResult = Apollo.QueryResult<
  BetaValuesQuery,
  BetaValuesQueryVariables
>;
export const QuoteDocument = gql`
  query quote($symbols: [String!]!) {
    quote(input: { symbols: $symbols }) {
      symbol
      price
      marginRequirement {
        maintenanceRequirement
      }
    }
  }
`;

/**
 * __useQuoteQuery__
 *
 * To run a query within a React component, call `useQuoteQuery` and pass it any options that fit your needs.
 * When your component renders, `useQuoteQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQuoteQuery({
 *   variables: {
 *      symbols: // value for 'symbols'
 *   },
 * });
 */
export function useQuoteQuery(
  baseOptions: Apollo.QueryHookOptions<QuoteQuery, QuoteQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<QuoteQuery, QuoteQueryVariables>(
    QuoteDocument,
    options
  );
}
export function useQuoteLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<QuoteQuery, QuoteQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<QuoteQuery, QuoteQueryVariables>(
    QuoteDocument,
    options
  );
}
export type QuoteQueryHookResult = ReturnType<typeof useQuoteQuery>;
export type QuoteLazyQueryHookResult = ReturnType<typeof useQuoteLazyQuery>;
export type QuoteQueryResult = Apollo.QueryResult<
  QuoteQuery,
  QuoteQueryVariables
>;
export const TreasuryRatesDocument = gql`
  query TreasuryRates {
    treasuryRates: getTreasuryRates {
      annualFeePercentage
      maxSevenDayYieldPercentage
      supportedFundSymbols
    }
  }
`;

/**
 * __useTreasuryRatesQuery__
 *
 * To run a query within a React component, call `useTreasuryRatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useTreasuryRatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTreasuryRatesQuery({
 *   variables: {
 *   },
 * });
 */
export function useTreasuryRatesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    TreasuryRatesQuery,
    TreasuryRatesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TreasuryRatesQuery, TreasuryRatesQueryVariables>(
    TreasuryRatesDocument,
    options
  );
}
export function useTreasuryRatesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    TreasuryRatesQuery,
    TreasuryRatesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<TreasuryRatesQuery, TreasuryRatesQueryVariables>(
    TreasuryRatesDocument,
    options
  );
}
export type TreasuryRatesQueryHookResult = ReturnType<
  typeof useTreasuryRatesQuery
>;
export type TreasuryRatesLazyQueryHookResult = ReturnType<
  typeof useTreasuryRatesLazyQuery
>;
export type TreasuryRatesQueryResult = Apollo.QueryResult<
  TreasuryRatesQuery,
  TreasuryRatesQueryVariables
>;
export const AcatsDocument = gql`
  query acats(
    $clearingAccountId: ID!
    $size: Int = 10
    $acatsTransfersNextPageToken: String
  ) {
    clearingAccount(input: { id: $clearingAccountId, type: ID }) {
      id
      ...getAccountAcatsTransfers
    }
  }
  ${GetAccountAcatsTransfersFragmentDoc}
`;

/**
 * __useAcatsQuery__
 *
 * To run a query within a React component, call `useAcatsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAcatsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAcatsQuery({
 *   variables: {
 *      clearingAccountId: // value for 'clearingAccountId'
 *      size: // value for 'size'
 *      acatsTransfersNextPageToken: // value for 'acatsTransfersNextPageToken'
 *   },
 * });
 */
export function useAcatsQuery(
  baseOptions: Apollo.QueryHookOptions<AcatsQuery, AcatsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AcatsQuery, AcatsQueryVariables>(
    AcatsDocument,
    options
  );
}
export function useAcatsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<AcatsQuery, AcatsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AcatsQuery, AcatsQueryVariables>(
    AcatsDocument,
    options
  );
}
export type AcatsQueryHookResult = ReturnType<typeof useAcatsQuery>;
export type AcatsLazyQueryHookResult = ReturnType<typeof useAcatsLazyQuery>;
export type AcatsQueryResult = Apollo.QueryResult<
  AcatsQuery,
  AcatsQueryVariables
>;
export const ActiveScheduledDepositConfigsDocument = gql`
  query activeScheduledDepositConfigs(
    $clearingAccountId: ID!
    $subAccountId: ID
  ) {
    clearingAccount(input: { id: $clearingAccountId, type: ID }) {
      id
      activeScheduledDepositConfigs(subAccountId: $subAccountId) {
        ...ScheduledDepositConfig
      }
    }
  }
  ${ScheduledDepositConfigFragmentDoc}
`;

/**
 * __useActiveScheduledDepositConfigsQuery__
 *
 * To run a query within a React component, call `useActiveScheduledDepositConfigsQuery` and pass it any options that fit your needs.
 * When your component renders, `useActiveScheduledDepositConfigsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useActiveScheduledDepositConfigsQuery({
 *   variables: {
 *      clearingAccountId: // value for 'clearingAccountId'
 *      subAccountId: // value for 'subAccountId'
 *   },
 * });
 */
export function useActiveScheduledDepositConfigsQuery(
  baseOptions: Apollo.QueryHookOptions<
    ActiveScheduledDepositConfigsQuery,
    ActiveScheduledDepositConfigsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ActiveScheduledDepositConfigsQuery,
    ActiveScheduledDepositConfigsQueryVariables
  >(ActiveScheduledDepositConfigsDocument, options);
}
export function useActiveScheduledDepositConfigsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ActiveScheduledDepositConfigsQuery,
    ActiveScheduledDepositConfigsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ActiveScheduledDepositConfigsQuery,
    ActiveScheduledDepositConfigsQueryVariables
  >(ActiveScheduledDepositConfigsDocument, options);
}
export type ActiveScheduledDepositConfigsQueryHookResult = ReturnType<
  typeof useActiveScheduledDepositConfigsQuery
>;
export type ActiveScheduledDepositConfigsLazyQueryHookResult = ReturnType<
  typeof useActiveScheduledDepositConfigsLazyQuery
>;
export type ActiveScheduledDepositConfigsQueryResult = Apollo.QueryResult<
  ActiveScheduledDepositConfigsQuery,
  ActiveScheduledDepositConfigsQueryVariables
>;
export const DemoActiveScheduledDepositConfigsDocument = gql`
  query demoActiveScheduledDepositConfigs(
    $clearingAccountId: ID!
    $subAccountId: ID
  ) {
    clearingAccount: demoClearingAccount(
      input: { id: $clearingAccountId, type: ID }
    ) {
      id
      activeScheduledDepositConfigs: demoActiveScheduledDepositConfigs(
        subAccountId: $subAccountId
      ) {
        ...ScheduledDepositConfig
      }
    }
  }
  ${ScheduledDepositConfigFragmentDoc}
`;

/**
 * __useDemoActiveScheduledDepositConfigsQuery__
 *
 * To run a query within a React component, call `useDemoActiveScheduledDepositConfigsQuery` and pass it any options that fit your needs.
 * When your component renders, `useDemoActiveScheduledDepositConfigsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDemoActiveScheduledDepositConfigsQuery({
 *   variables: {
 *      clearingAccountId: // value for 'clearingAccountId'
 *      subAccountId: // value for 'subAccountId'
 *   },
 * });
 */
export function useDemoActiveScheduledDepositConfigsQuery(
  baseOptions: Apollo.QueryHookOptions<
    DemoActiveScheduledDepositConfigsQuery,
    DemoActiveScheduledDepositConfigsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    DemoActiveScheduledDepositConfigsQuery,
    DemoActiveScheduledDepositConfigsQueryVariables
  >(DemoActiveScheduledDepositConfigsDocument, options);
}
export function useDemoActiveScheduledDepositConfigsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    DemoActiveScheduledDepositConfigsQuery,
    DemoActiveScheduledDepositConfigsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    DemoActiveScheduledDepositConfigsQuery,
    DemoActiveScheduledDepositConfigsQueryVariables
  >(DemoActiveScheduledDepositConfigsDocument, options);
}
export type DemoActiveScheduledDepositConfigsQueryHookResult = ReturnType<
  typeof useDemoActiveScheduledDepositConfigsQuery
>;
export type DemoActiveScheduledDepositConfigsLazyQueryHookResult = ReturnType<
  typeof useDemoActiveScheduledDepositConfigsLazyQuery
>;
export type DemoActiveScheduledDepositConfigsQueryResult = Apollo.QueryResult<
  DemoActiveScheduledDepositConfigsQuery,
  DemoActiveScheduledDepositConfigsQueryVariables
>;
export const EmployeeReadOnlyClearingAccountAccessDocument = gql`
  query employeeReadOnlyClearingAccountAccess {
    employeeReadOnlyClearingAccountAccess {
      clearingAccountId
      businessReason
      expiresAt
    }
  }
`;

/**
 * __useEmployeeReadOnlyClearingAccountAccessQuery__
 *
 * To run a query within a React component, call `useEmployeeReadOnlyClearingAccountAccessQuery` and pass it any options that fit your needs.
 * When your component renders, `useEmployeeReadOnlyClearingAccountAccessQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEmployeeReadOnlyClearingAccountAccessQuery({
 *   variables: {
 *   },
 * });
 */
export function useEmployeeReadOnlyClearingAccountAccessQuery(
  baseOptions?: Apollo.QueryHookOptions<
    EmployeeReadOnlyClearingAccountAccessQuery,
    EmployeeReadOnlyClearingAccountAccessQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    EmployeeReadOnlyClearingAccountAccessQuery,
    EmployeeReadOnlyClearingAccountAccessQueryVariables
  >(EmployeeReadOnlyClearingAccountAccessDocument, options);
}
export function useEmployeeReadOnlyClearingAccountAccessLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    EmployeeReadOnlyClearingAccountAccessQuery,
    EmployeeReadOnlyClearingAccountAccessQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    EmployeeReadOnlyClearingAccountAccessQuery,
    EmployeeReadOnlyClearingAccountAccessQueryVariables
  >(EmployeeReadOnlyClearingAccountAccessDocument, options);
}
export type EmployeeReadOnlyClearingAccountAccessQueryHookResult = ReturnType<
  typeof useEmployeeReadOnlyClearingAccountAccessQuery
>;
export type EmployeeReadOnlyClearingAccountAccessLazyQueryHookResult =
  ReturnType<typeof useEmployeeReadOnlyClearingAccountAccessLazyQuery>;
export type EmployeeReadOnlyClearingAccountAccessQueryResult =
  Apollo.QueryResult<
    EmployeeReadOnlyClearingAccountAccessQuery,
    EmployeeReadOnlyClearingAccountAccessQueryVariables
  >;
export const SimulationGetSimulationEventTemplateDocument = gql`
  query simulationGetSimulationEventTemplate(
    $eventType: EventType!
    $eventId: String!
  ) {
    simulationGetSimulationEventTemplate(
      eventType: $eventType
      eventId: $eventId
    ) {
      id
      externalId
      eventType
      topic
      success
      payload
      eventTime
      note
    }
  }
`;

/**
 * __useSimulationGetSimulationEventTemplateQuery__
 *
 * To run a query within a React component, call `useSimulationGetSimulationEventTemplateQuery` and pass it any options that fit your needs.
 * When your component renders, `useSimulationGetSimulationEventTemplateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSimulationGetSimulationEventTemplateQuery({
 *   variables: {
 *      eventType: // value for 'eventType'
 *      eventId: // value for 'eventId'
 *   },
 * });
 */
export function useSimulationGetSimulationEventTemplateQuery(
  baseOptions: Apollo.QueryHookOptions<
    SimulationGetSimulationEventTemplateQuery,
    SimulationGetSimulationEventTemplateQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    SimulationGetSimulationEventTemplateQuery,
    SimulationGetSimulationEventTemplateQueryVariables
  >(SimulationGetSimulationEventTemplateDocument, options);
}
export function useSimulationGetSimulationEventTemplateLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SimulationGetSimulationEventTemplateQuery,
    SimulationGetSimulationEventTemplateQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    SimulationGetSimulationEventTemplateQuery,
    SimulationGetSimulationEventTemplateQueryVariables
  >(SimulationGetSimulationEventTemplateDocument, options);
}
export type SimulationGetSimulationEventTemplateQueryHookResult = ReturnType<
  typeof useSimulationGetSimulationEventTemplateQuery
>;
export type SimulationGetSimulationEventTemplateLazyQueryHookResult =
  ReturnType<typeof useSimulationGetSimulationEventTemplateLazyQuery>;
export type SimulationGetSimulationEventTemplateQueryResult =
  Apollo.QueryResult<
    SimulationGetSimulationEventTemplateQuery,
    SimulationGetSimulationEventTemplateQueryVariables
  >;
export const SimulationGetTestUsersDocument = gql`
  query simulationGetTestUsers {
    simulationGetTestUsers
  }
`;

/**
 * __useSimulationGetTestUsersQuery__
 *
 * To run a query within a React component, call `useSimulationGetTestUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useSimulationGetTestUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSimulationGetTestUsersQuery({
 *   variables: {
 *   },
 * });
 */
export function useSimulationGetTestUsersQuery(
  baseOptions?: Apollo.QueryHookOptions<
    SimulationGetTestUsersQuery,
    SimulationGetTestUsersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    SimulationGetTestUsersQuery,
    SimulationGetTestUsersQueryVariables
  >(SimulationGetTestUsersDocument, options);
}
export function useSimulationGetTestUsersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SimulationGetTestUsersQuery,
    SimulationGetTestUsersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    SimulationGetTestUsersQuery,
    SimulationGetTestUsersQueryVariables
  >(SimulationGetTestUsersDocument, options);
}
export type SimulationGetTestUsersQueryHookResult = ReturnType<
  typeof useSimulationGetTestUsersQuery
>;
export type SimulationGetTestUsersLazyQueryHookResult = ReturnType<
  typeof useSimulationGetTestUsersLazyQuery
>;
export type SimulationGetTestUsersQueryResult = Apollo.QueryResult<
  SimulationGetTestUsersQuery,
  SimulationGetTestUsersQueryVariables
>;
export const SimulationGetTestClearingAccountsDocument = gql`
  query simulationGetTestClearingAccounts {
    simulationGetTestClearingAccounts
  }
`;

/**
 * __useSimulationGetTestClearingAccountsQuery__
 *
 * To run a query within a React component, call `useSimulationGetTestClearingAccountsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSimulationGetTestClearingAccountsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSimulationGetTestClearingAccountsQuery({
 *   variables: {
 *   },
 * });
 */
export function useSimulationGetTestClearingAccountsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    SimulationGetTestClearingAccountsQuery,
    SimulationGetTestClearingAccountsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    SimulationGetTestClearingAccountsQuery,
    SimulationGetTestClearingAccountsQueryVariables
  >(SimulationGetTestClearingAccountsDocument, options);
}
export function useSimulationGetTestClearingAccountsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SimulationGetTestClearingAccountsQuery,
    SimulationGetTestClearingAccountsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    SimulationGetTestClearingAccountsQuery,
    SimulationGetTestClearingAccountsQueryVariables
  >(SimulationGetTestClearingAccountsDocument, options);
}
export type SimulationGetTestClearingAccountsQueryHookResult = ReturnType<
  typeof useSimulationGetTestClearingAccountsQuery
>;
export type SimulationGetTestClearingAccountsLazyQueryHookResult = ReturnType<
  typeof useSimulationGetTestClearingAccountsLazyQuery
>;
export type SimulationGetTestClearingAccountsQueryResult = Apollo.QueryResult<
  SimulationGetTestClearingAccountsQuery,
  SimulationGetTestClearingAccountsQueryVariables
>;
export const SimulationGetUserTemplateDocument = gql`
  query simulationGetUserTemplate {
    simulationGetUserTemplate {
      id
      isEmployee
      email
      emailType
      phoneNumber
      phoneNumberVerified
      phoneNumberType
      firstName
      middleName
      lastName
      citizenshipCountry
      dateOfBirth
      permanentResident
      ssn
      visaExpiration
      visaStatus
      employmentStatus
      controlPerson
      affiliatedExchangeOrFINRA
      politicallyExposed
      addressLine1
      addressType
      city
      country
      state
      zipCode
      annualIncomeMax
      annualIncomeMin
      investmentExperience
      investmentObjective
      liquidityNeeds
      liquidityTimeHorizon
      netWorthMax
      netWorthMin
      riskTolerance
      sweepInstruction
      dividendReinvestment
    }
  }
`;

/**
 * __useSimulationGetUserTemplateQuery__
 *
 * To run a query within a React component, call `useSimulationGetUserTemplateQuery` and pass it any options that fit your needs.
 * When your component renders, `useSimulationGetUserTemplateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSimulationGetUserTemplateQuery({
 *   variables: {
 *   },
 * });
 */
export function useSimulationGetUserTemplateQuery(
  baseOptions?: Apollo.QueryHookOptions<
    SimulationGetUserTemplateQuery,
    SimulationGetUserTemplateQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    SimulationGetUserTemplateQuery,
    SimulationGetUserTemplateQueryVariables
  >(SimulationGetUserTemplateDocument, options);
}
export function useSimulationGetUserTemplateLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SimulationGetUserTemplateQuery,
    SimulationGetUserTemplateQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    SimulationGetUserTemplateQuery,
    SimulationGetUserTemplateQueryVariables
  >(SimulationGetUserTemplateDocument, options);
}
export type SimulationGetUserTemplateQueryHookResult = ReturnType<
  typeof useSimulationGetUserTemplateQuery
>;
export type SimulationGetUserTemplateLazyQueryHookResult = ReturnType<
  typeof useSimulationGetUserTemplateLazyQuery
>;
export type SimulationGetUserTemplateQueryResult = Apollo.QueryResult<
  SimulationGetUserTemplateQuery,
  SimulationGetUserTemplateQueryVariables
>;
export const DirectIndexSimulateDocument = gql`
  query directIndexSimulate($input: String!, $submitOnly: Boolean) {
    directIndexSimulate(input: $input, submitOnly: $submitOnly)
  }
`;

/**
 * __useDirectIndexSimulateQuery__
 *
 * To run a query within a React component, call `useDirectIndexSimulateQuery` and pass it any options that fit your needs.
 * When your component renders, `useDirectIndexSimulateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDirectIndexSimulateQuery({
 *   variables: {
 *      input: // value for 'input'
 *      submitOnly: // value for 'submitOnly'
 *   },
 * });
 */
export function useDirectIndexSimulateQuery(
  baseOptions: Apollo.QueryHookOptions<
    DirectIndexSimulateQuery,
    DirectIndexSimulateQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    DirectIndexSimulateQuery,
    DirectIndexSimulateQueryVariables
  >(DirectIndexSimulateDocument, options);
}
export function useDirectIndexSimulateLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    DirectIndexSimulateQuery,
    DirectIndexSimulateQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    DirectIndexSimulateQuery,
    DirectIndexSimulateQueryVariables
  >(DirectIndexSimulateDocument, options);
}
export type DirectIndexSimulateQueryHookResult = ReturnType<
  typeof useDirectIndexSimulateQuery
>;
export type DirectIndexSimulateLazyQueryHookResult = ReturnType<
  typeof useDirectIndexSimulateLazyQuery
>;
export type DirectIndexSimulateQueryResult = Apollo.QueryResult<
  DirectIndexSimulateQuery,
  DirectIndexSimulateQueryVariables
>;
export const CancelDirectIndexSimulationDocument = gql`
  mutation cancelDirectIndexSimulation($id: ID!) {
    cancelDirectIndexSimulation(id: $id)
  }
`;
export type CancelDirectIndexSimulationMutationFn = Apollo.MutationFunction<
  CancelDirectIndexSimulationMutation,
  CancelDirectIndexSimulationMutationVariables
>;

/**
 * __useCancelDirectIndexSimulationMutation__
 *
 * To run a mutation, you first call `useCancelDirectIndexSimulationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelDirectIndexSimulationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelDirectIndexSimulationMutation, { data, loading, error }] = useCancelDirectIndexSimulationMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCancelDirectIndexSimulationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CancelDirectIndexSimulationMutation,
    CancelDirectIndexSimulationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CancelDirectIndexSimulationMutation,
    CancelDirectIndexSimulationMutationVariables
  >(CancelDirectIndexSimulationDocument, options);
}
export type CancelDirectIndexSimulationMutationHookResult = ReturnType<
  typeof useCancelDirectIndexSimulationMutation
>;
export type CancelDirectIndexSimulationMutationResult =
  Apollo.MutationResult<CancelDirectIndexSimulationMutation>;
export type CancelDirectIndexSimulationMutationOptions =
  Apollo.BaseMutationOptions<
    CancelDirectIndexSimulationMutation,
    CancelDirectIndexSimulationMutationVariables
  >;
export const DeleteDirectIndexSimulationDocument = gql`
  mutation deleteDirectIndexSimulation($id: ID!) {
    deleteDirectIndexSimulation(id: $id)
  }
`;
export type DeleteDirectIndexSimulationMutationFn = Apollo.MutationFunction<
  DeleteDirectIndexSimulationMutation,
  DeleteDirectIndexSimulationMutationVariables
>;

/**
 * __useDeleteDirectIndexSimulationMutation__
 *
 * To run a mutation, you first call `useDeleteDirectIndexSimulationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteDirectIndexSimulationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteDirectIndexSimulationMutation, { data, loading, error }] = useDeleteDirectIndexSimulationMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteDirectIndexSimulationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteDirectIndexSimulationMutation,
    DeleteDirectIndexSimulationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteDirectIndexSimulationMutation,
    DeleteDirectIndexSimulationMutationVariables
  >(DeleteDirectIndexSimulationDocument, options);
}
export type DeleteDirectIndexSimulationMutationHookResult = ReturnType<
  typeof useDeleteDirectIndexSimulationMutation
>;
export type DeleteDirectIndexSimulationMutationResult =
  Apollo.MutationResult<DeleteDirectIndexSimulationMutation>;
export type DeleteDirectIndexSimulationMutationOptions =
  Apollo.BaseMutationOptions<
    DeleteDirectIndexSimulationMutation,
    DeleteDirectIndexSimulationMutationVariables
  >;
export const GetSubAccountWeightsDocument = gql`
  query getSubAccountWeights(
    $subAccountId: ID!
    $fromDateTime: Date!
    $toDateTime: Date
  ) {
    directIndexWeights(
      input: {
        subAccountId: $subAccountId
        fromDateTime: $fromDateTime
        toDateTime: $toDateTime
      }
    ) {
      securityId
      security {
        ...Security
      }
      weight
      computedAt
    }
  }
  ${SecurityFragmentDoc}
`;

/**
 * __useGetSubAccountWeightsQuery__
 *
 * To run a query within a React component, call `useGetSubAccountWeightsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSubAccountWeightsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSubAccountWeightsQuery({
 *   variables: {
 *      subAccountId: // value for 'subAccountId'
 *      fromDateTime: // value for 'fromDateTime'
 *      toDateTime: // value for 'toDateTime'
 *   },
 * });
 */
export function useGetSubAccountWeightsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetSubAccountWeightsQuery,
    GetSubAccountWeightsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetSubAccountWeightsQuery,
    GetSubAccountWeightsQueryVariables
  >(GetSubAccountWeightsDocument, options);
}
export function useGetSubAccountWeightsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetSubAccountWeightsQuery,
    GetSubAccountWeightsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetSubAccountWeightsQuery,
    GetSubAccountWeightsQueryVariables
  >(GetSubAccountWeightsDocument, options);
}
export type GetSubAccountWeightsQueryHookResult = ReturnType<
  typeof useGetSubAccountWeightsQuery
>;
export type GetSubAccountWeightsLazyQueryHookResult = ReturnType<
  typeof useGetSubAccountWeightsLazyQuery
>;
export type GetSubAccountWeightsQueryResult = Apollo.QueryResult<
  GetSubAccountWeightsQuery,
  GetSubAccountWeightsQueryVariables
>;
export const GetAllSimulationsRunInfoDocument = gql`
  query getAllSimulationsRunInfo {
    getAllSimulationsRunInfo {
      id
      status
      date
      serializedArgs
      multiIndexSimulationId
    }
  }
`;

/**
 * __useGetAllSimulationsRunInfoQuery__
 *
 * To run a query within a React component, call `useGetAllSimulationsRunInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllSimulationsRunInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllSimulationsRunInfoQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllSimulationsRunInfoQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetAllSimulationsRunInfoQuery,
    GetAllSimulationsRunInfoQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetAllSimulationsRunInfoQuery,
    GetAllSimulationsRunInfoQueryVariables
  >(GetAllSimulationsRunInfoDocument, options);
}
export function useGetAllSimulationsRunInfoLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAllSimulationsRunInfoQuery,
    GetAllSimulationsRunInfoQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetAllSimulationsRunInfoQuery,
    GetAllSimulationsRunInfoQueryVariables
  >(GetAllSimulationsRunInfoDocument, options);
}
export type GetAllSimulationsRunInfoQueryHookResult = ReturnType<
  typeof useGetAllSimulationsRunInfoQuery
>;
export type GetAllSimulationsRunInfoLazyQueryHookResult = ReturnType<
  typeof useGetAllSimulationsRunInfoLazyQuery
>;
export type GetAllSimulationsRunInfoQueryResult = Apollo.QueryResult<
  GetAllSimulationsRunInfoQuery,
  GetAllSimulationsRunInfoQueryVariables
>;
export const GetSimulationRunInfoByIdDocument = gql`
  query getSimulationRunInfoById($id: ID!) {
    getSimulationRunInfoById(id: $id) {
      info {
        id
        status
        serializedArgs
        multiIndexSimulationId
      }
      serializedResult
    }
  }
`;

/**
 * __useGetSimulationRunInfoByIdQuery__
 *
 * To run a query within a React component, call `useGetSimulationRunInfoByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSimulationRunInfoByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSimulationRunInfoByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetSimulationRunInfoByIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetSimulationRunInfoByIdQuery,
    GetSimulationRunInfoByIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetSimulationRunInfoByIdQuery,
    GetSimulationRunInfoByIdQueryVariables
  >(GetSimulationRunInfoByIdDocument, options);
}
export function useGetSimulationRunInfoByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetSimulationRunInfoByIdQuery,
    GetSimulationRunInfoByIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetSimulationRunInfoByIdQuery,
    GetSimulationRunInfoByIdQueryVariables
  >(GetSimulationRunInfoByIdDocument, options);
}
export type GetSimulationRunInfoByIdQueryHookResult = ReturnType<
  typeof useGetSimulationRunInfoByIdQuery
>;
export type GetSimulationRunInfoByIdLazyQueryHookResult = ReturnType<
  typeof useGetSimulationRunInfoByIdLazyQuery
>;
export type GetSimulationRunInfoByIdQueryResult = Apollo.QueryResult<
  GetSimulationRunInfoByIdQuery,
  GetSimulationRunInfoByIdQueryVariables
>;
export const RunDirectIndexOptimizationDocument = gql`
  query runDirectIndexOptimization($input: RunDirectIndexOptimizationInput!) {
    runDirectIndexOptimization(input: $input) {
      ...DirectIndexOptimizationRunData
    }
  }
  ${DirectIndexOptimizationRunDataFragmentDoc}
`;

/**
 * __useRunDirectIndexOptimizationQuery__
 *
 * To run a query within a React component, call `useRunDirectIndexOptimizationQuery` and pass it any options that fit your needs.
 * When your component renders, `useRunDirectIndexOptimizationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRunDirectIndexOptimizationQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRunDirectIndexOptimizationQuery(
  baseOptions: Apollo.QueryHookOptions<
    RunDirectIndexOptimizationQuery,
    RunDirectIndexOptimizationQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    RunDirectIndexOptimizationQuery,
    RunDirectIndexOptimizationQueryVariables
  >(RunDirectIndexOptimizationDocument, options);
}
export function useRunDirectIndexOptimizationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    RunDirectIndexOptimizationQuery,
    RunDirectIndexOptimizationQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    RunDirectIndexOptimizationQuery,
    RunDirectIndexOptimizationQueryVariables
  >(RunDirectIndexOptimizationDocument, options);
}
export type RunDirectIndexOptimizationQueryHookResult = ReturnType<
  typeof useRunDirectIndexOptimizationQuery
>;
export type RunDirectIndexOptimizationLazyQueryHookResult = ReturnType<
  typeof useRunDirectIndexOptimizationLazyQuery
>;
export type RunDirectIndexOptimizationQueryResult = Apollo.QueryResult<
  RunDirectIndexOptimizationQuery,
  RunDirectIndexOptimizationQueryVariables
>;
export const FetchDirectIndexStockInfoForSubAccountDocument = gql`
  query fetchDirectIndexStockInfoForSubAccount(
    $subAccountId: ID!
    $asOfDate: DateTime
  ) {
    fetchDirectIndexStockInfoForSubAccount(
      subAccountId: $subAccountId
      asOfDate: $asOfDate
    ) {
      diCash
      stockInfo {
        symbol
        price
        targetWeight
        taxLots {
          ...DirectIndexTaxLot
        }
      }
    }
  }
  ${DirectIndexTaxLotFragmentDoc}
`;

/**
 * __useFetchDirectIndexStockInfoForSubAccountQuery__
 *
 * To run a query within a React component, call `useFetchDirectIndexStockInfoForSubAccountQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchDirectIndexStockInfoForSubAccountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchDirectIndexStockInfoForSubAccountQuery({
 *   variables: {
 *      subAccountId: // value for 'subAccountId'
 *      asOfDate: // value for 'asOfDate'
 *   },
 * });
 */
export function useFetchDirectIndexStockInfoForSubAccountQuery(
  baseOptions: Apollo.QueryHookOptions<
    FetchDirectIndexStockInfoForSubAccountQuery,
    FetchDirectIndexStockInfoForSubAccountQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    FetchDirectIndexStockInfoForSubAccountQuery,
    FetchDirectIndexStockInfoForSubAccountQueryVariables
  >(FetchDirectIndexStockInfoForSubAccountDocument, options);
}
export function useFetchDirectIndexStockInfoForSubAccountLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FetchDirectIndexStockInfoForSubAccountQuery,
    FetchDirectIndexStockInfoForSubAccountQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    FetchDirectIndexStockInfoForSubAccountQuery,
    FetchDirectIndexStockInfoForSubAccountQueryVariables
  >(FetchDirectIndexStockInfoForSubAccountDocument, options);
}
export type FetchDirectIndexStockInfoForSubAccountQueryHookResult = ReturnType<
  typeof useFetchDirectIndexStockInfoForSubAccountQuery
>;
export type FetchDirectIndexStockInfoForSubAccountLazyQueryHookResult =
  ReturnType<typeof useFetchDirectIndexStockInfoForSubAccountLazyQuery>;
export type FetchDirectIndexStockInfoForSubAccountQueryResult =
  Apollo.QueryResult<
    FetchDirectIndexStockInfoForSubAccountQuery,
    FetchDirectIndexStockInfoForSubAccountQueryVariables
  >;
export const GetDirectIndexTradeExecutionsDocument = gql`
  query getDirectIndexTradeExecutions(
    $input: GetDirectIndexTradeExecutionsInput!
  ) {
    getDirectIndexTradeExecutions(input: $input) {
      ...DirectIndexTradeExecution
    }
  }
  ${DirectIndexTradeExecutionFragmentDoc}
`;

/**
 * __useGetDirectIndexTradeExecutionsQuery__
 *
 * To run a query within a React component, call `useGetDirectIndexTradeExecutionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDirectIndexTradeExecutionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDirectIndexTradeExecutionsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetDirectIndexTradeExecutionsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetDirectIndexTradeExecutionsQuery,
    GetDirectIndexTradeExecutionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetDirectIndexTradeExecutionsQuery,
    GetDirectIndexTradeExecutionsQueryVariables
  >(GetDirectIndexTradeExecutionsDocument, options);
}
export function useGetDirectIndexTradeExecutionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetDirectIndexTradeExecutionsQuery,
    GetDirectIndexTradeExecutionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetDirectIndexTradeExecutionsQuery,
    GetDirectIndexTradeExecutionsQueryVariables
  >(GetDirectIndexTradeExecutionsDocument, options);
}
export type GetDirectIndexTradeExecutionsQueryHookResult = ReturnType<
  typeof useGetDirectIndexTradeExecutionsQuery
>;
export type GetDirectIndexTradeExecutionsLazyQueryHookResult = ReturnType<
  typeof useGetDirectIndexTradeExecutionsLazyQuery
>;
export type GetDirectIndexTradeExecutionsQueryResult = Apollo.QueryResult<
  GetDirectIndexTradeExecutionsQuery,
  GetDirectIndexTradeExecutionsQueryVariables
>;
export const SimulationGetSimulationsEntriesForSimulationIdDocument = gql`
  query simulationGetSimulationsEntriesForSimulationId($simulationId: Int!) {
    simulationGetSimulationsEntriesForSimulationId(
      simulationId: $simulationId
    ) {
      id
      query
      response
      simulationId
      symbol
    }
  }
`;

/**
 * __useSimulationGetSimulationsEntriesForSimulationIdQuery__
 *
 * To run a query within a React component, call `useSimulationGetSimulationsEntriesForSimulationIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useSimulationGetSimulationsEntriesForSimulationIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSimulationGetSimulationsEntriesForSimulationIdQuery({
 *   variables: {
 *      simulationId: // value for 'simulationId'
 *   },
 * });
 */
export function useSimulationGetSimulationsEntriesForSimulationIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    SimulationGetSimulationsEntriesForSimulationIdQuery,
    SimulationGetSimulationsEntriesForSimulationIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    SimulationGetSimulationsEntriesForSimulationIdQuery,
    SimulationGetSimulationsEntriesForSimulationIdQueryVariables
  >(SimulationGetSimulationsEntriesForSimulationIdDocument, options);
}
export function useSimulationGetSimulationsEntriesForSimulationIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SimulationGetSimulationsEntriesForSimulationIdQuery,
    SimulationGetSimulationsEntriesForSimulationIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    SimulationGetSimulationsEntriesForSimulationIdQuery,
    SimulationGetSimulationsEntriesForSimulationIdQueryVariables
  >(SimulationGetSimulationsEntriesForSimulationIdDocument, options);
}
export type SimulationGetSimulationsEntriesForSimulationIdQueryHookResult =
  ReturnType<typeof useSimulationGetSimulationsEntriesForSimulationIdQuery>;
export type SimulationGetSimulationsEntriesForSimulationIdLazyQueryHookResult =
  ReturnType<typeof useSimulationGetSimulationsEntriesForSimulationIdLazyQuery>;
export type SimulationGetSimulationsEntriesForSimulationIdQueryResult =
  Apollo.QueryResult<
    SimulationGetSimulationsEntriesForSimulationIdQuery,
    SimulationGetSimulationsEntriesForSimulationIdQueryVariables
  >;
export const SimulationGetSimulationIdsDocument = gql`
  query simulationGetSimulationIds {
    simulationGetSimulationIds
  }
`;

/**
 * __useSimulationGetSimulationIdsQuery__
 *
 * To run a query within a React component, call `useSimulationGetSimulationIdsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSimulationGetSimulationIdsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSimulationGetSimulationIdsQuery({
 *   variables: {
 *   },
 * });
 */
export function useSimulationGetSimulationIdsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    SimulationGetSimulationIdsQuery,
    SimulationGetSimulationIdsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    SimulationGetSimulationIdsQuery,
    SimulationGetSimulationIdsQueryVariables
  >(SimulationGetSimulationIdsDocument, options);
}
export function useSimulationGetSimulationIdsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SimulationGetSimulationIdsQuery,
    SimulationGetSimulationIdsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    SimulationGetSimulationIdsQuery,
    SimulationGetSimulationIdsQueryVariables
  >(SimulationGetSimulationIdsDocument, options);
}
export type SimulationGetSimulationIdsQueryHookResult = ReturnType<
  typeof useSimulationGetSimulationIdsQuery
>;
export type SimulationGetSimulationIdsLazyQueryHookResult = ReturnType<
  typeof useSimulationGetSimulationIdsLazyQuery
>;
export type SimulationGetSimulationIdsQueryResult = Apollo.QueryResult<
  SimulationGetSimulationIdsQuery,
  SimulationGetSimulationIdsQueryVariables
>;
export const BusinessEntityDocument = gql`
  query businessEntity {
    businessEntity {
      id
      name
      taxId
      userTitle
      phoneNumber
      faxNumber
      industry
      entityType
      corporationSecretaryName
      multiplePartnersOrManagers
      foreignBankUSAgent
      largeTraderIds
      businessScope
      primaryFundingSource
      bankingInstitutionNames
      stateOfIncorporation
      address {
        id
        addressType
        addressLine1
        addressLine2
        city
        state
        zipCode
        country
      }
      mailingAddress {
        id
        addressType
        addressLine1
        addressLine2
        city
        state
        zipCode
        country
      }
      entityInvestmentProfile {
        id
        netWorthMin
        netWorthMax
        annualIncomeMin
        annualIncomeMax
        investmentExperience
        investmentObjective
        investmentPlan
        liquidityNeeds
        liquidityTimeHorizon
        riskTolerance
        withdrawFrequency
        depositSource
        initialDepositAmount
      }
      entityIndividuals {
        id
        individualType
        givenName
        familyName
        dateOfBirth
        ssn
        address {
          id
          addressType
          addressLine1
          addressLine2
          city
          state
          zipCode
          country
        }
        disclosure {
          id
          affiliatedExchangeOrFINRA
          firmName
          controlPerson
          companySymbols
          politicallyExposed
          politicalOrganization
          immediateFamilyMembers
        }
      }
    }
  }
`;

/**
 * __useBusinessEntityQuery__
 *
 * To run a query within a React component, call `useBusinessEntityQuery` and pass it any options that fit your needs.
 * When your component renders, `useBusinessEntityQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBusinessEntityQuery({
 *   variables: {
 *   },
 * });
 */
export function useBusinessEntityQuery(
  baseOptions?: Apollo.QueryHookOptions<
    BusinessEntityQuery,
    BusinessEntityQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<BusinessEntityQuery, BusinessEntityQueryVariables>(
    BusinessEntityDocument,
    options
  );
}
export function useBusinessEntityLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    BusinessEntityQuery,
    BusinessEntityQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<BusinessEntityQuery, BusinessEntityQueryVariables>(
    BusinessEntityDocument,
    options
  );
}
export type BusinessEntityQueryHookResult = ReturnType<
  typeof useBusinessEntityQuery
>;
export type BusinessEntityLazyQueryHookResult = ReturnType<
  typeof useBusinessEntityLazyQuery
>;
export type BusinessEntityQueryResult = Apollo.QueryResult<
  BusinessEntityQuery,
  BusinessEntityQueryVariables
>;
export const CashDividendDocument = gql`
  query CashDividend($clearingAccountId: ID!, $id: ID!) {
    clearingAccount(input: { id: $clearingAccountId, type: ID }) {
      id
      cashDividend(id: $id) {
        ...CashDividendItem
      }
    }
  }
  ${CashDividendItemFragmentDoc}
`;

/**
 * __useCashDividendQuery__
 *
 * To run a query within a React component, call `useCashDividendQuery` and pass it any options that fit your needs.
 * When your component renders, `useCashDividendQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCashDividendQuery({
 *   variables: {
 *      clearingAccountId: // value for 'clearingAccountId'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCashDividendQuery(
  baseOptions: Apollo.QueryHookOptions<
    CashDividendQuery,
    CashDividendQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CashDividendQuery, CashDividendQueryVariables>(
    CashDividendDocument,
    options
  );
}
export function useCashDividendLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CashDividendQuery,
    CashDividendQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CashDividendQuery, CashDividendQueryVariables>(
    CashDividendDocument,
    options
  );
}
export type CashDividendQueryHookResult = ReturnType<
  typeof useCashDividendQuery
>;
export type CashDividendLazyQueryHookResult = ReturnType<
  typeof useCashDividendLazyQuery
>;
export type CashDividendQueryResult = Apollo.QueryResult<
  CashDividendQuery,
  CashDividendQueryVariables
>;
export const CashTransferDocument = gql`
  query cashTransfer($clearingAccountId: ID!, $id: ID!) {
    clearingAccount(input: { id: $clearingAccountId, type: ID }) {
      id
      cashTransfer(id: $id) {
        ...CashTransfer
      }
    }
  }
  ${CashTransferFragmentDoc}
`;

/**
 * __useCashTransferQuery__
 *
 * To run a query within a React component, call `useCashTransferQuery` and pass it any options that fit your needs.
 * When your component renders, `useCashTransferQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCashTransferQuery({
 *   variables: {
 *      clearingAccountId: // value for 'clearingAccountId'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCashTransferQuery(
  baseOptions: Apollo.QueryHookOptions<
    CashTransferQuery,
    CashTransferQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CashTransferQuery, CashTransferQueryVariables>(
    CashTransferDocument,
    options
  );
}
export function useCashTransferLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CashTransferQuery,
    CashTransferQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CashTransferQuery, CashTransferQueryVariables>(
    CashTransferDocument,
    options
  );
}
export type CashTransferQueryHookResult = ReturnType<
  typeof useCashTransferQuery
>;
export type CashTransferLazyQueryHookResult = ReturnType<
  typeof useCashTransferLazyQuery
>;
export type CashTransferQueryResult = Apollo.QueryResult<
  CashTransferQuery,
  CashTransferQueryVariables
>;
export const DemoCashTransferDocument = gql`
  query demoCashTransfer($clearingAccountId: ID!, $id: ID!) {
    clearingAccount: demoClearingAccount(
      input: { id: $clearingAccountId, type: ID }
    ) {
      id
      cashTransfer: demoCashTransfer(id: $id) {
        ...CashTransfer
      }
    }
  }
  ${CashTransferFragmentDoc}
`;

/**
 * __useDemoCashTransferQuery__
 *
 * To run a query within a React component, call `useDemoCashTransferQuery` and pass it any options that fit your needs.
 * When your component renders, `useDemoCashTransferQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDemoCashTransferQuery({
 *   variables: {
 *      clearingAccountId: // value for 'clearingAccountId'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDemoCashTransferQuery(
  baseOptions: Apollo.QueryHookOptions<
    DemoCashTransferQuery,
    DemoCashTransferQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<DemoCashTransferQuery, DemoCashTransferQueryVariables>(
    DemoCashTransferDocument,
    options
  );
}
export function useDemoCashTransferLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    DemoCashTransferQuery,
    DemoCashTransferQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    DemoCashTransferQuery,
    DemoCashTransferQueryVariables
  >(DemoCashTransferDocument, options);
}
export type DemoCashTransferQueryHookResult = ReturnType<
  typeof useDemoCashTransferQuery
>;
export type DemoCashTransferLazyQueryHookResult = ReturnType<
  typeof useDemoCashTransferLazyQuery
>;
export type DemoCashTransferQueryResult = Apollo.QueryResult<
  DemoCashTransferQuery,
  DemoCashTransferQueryVariables
>;
export const CashTransfersDocument = gql`
  query cashTransfers($args: GetCashTransfersArgs!) {
    getCashTransfers(args: $args) {
      nextPageToken
      data {
        ...CashTransferListItem
      }
    }
  }
  ${CashTransferListItemFragmentDoc}
`;

/**
 * __useCashTransfersQuery__
 *
 * To run a query within a React component, call `useCashTransfersQuery` and pass it any options that fit your needs.
 * When your component renders, `useCashTransfersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCashTransfersQuery({
 *   variables: {
 *      args: // value for 'args'
 *   },
 * });
 */
export function useCashTransfersQuery(
  baseOptions: Apollo.QueryHookOptions<
    CashTransfersQuery,
    CashTransfersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CashTransfersQuery, CashTransfersQueryVariables>(
    CashTransfersDocument,
    options
  );
}
export function useCashTransfersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CashTransfersQuery,
    CashTransfersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CashTransfersQuery, CashTransfersQueryVariables>(
    CashTransfersDocument,
    options
  );
}
export type CashTransfersQueryHookResult = ReturnType<
  typeof useCashTransfersQuery
>;
export type CashTransfersLazyQueryHookResult = ReturnType<
  typeof useCashTransfersLazyQuery
>;
export type CashTransfersQueryResult = Apollo.QueryResult<
  CashTransfersQuery,
  CashTransfersQueryVariables
>;
export const ClearingAccountDocument = gql`
  query clearingAccount($input: ClearingAccountInput!) {
    clearingAccount(input: $input) {
      ...ClearingAccount
    }
  }
  ${ClearingAccountFragmentDoc}
`;

/**
 * __useClearingAccountQuery__
 *
 * To run a query within a React component, call `useClearingAccountQuery` and pass it any options that fit your needs.
 * When your component renders, `useClearingAccountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useClearingAccountQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useClearingAccountQuery(
  baseOptions: Apollo.QueryHookOptions<
    ClearingAccountQuery,
    ClearingAccountQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ClearingAccountQuery, ClearingAccountQueryVariables>(
    ClearingAccountDocument,
    options
  );
}
export function useClearingAccountLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ClearingAccountQuery,
    ClearingAccountQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ClearingAccountQuery,
    ClearingAccountQueryVariables
  >(ClearingAccountDocument, options);
}
export type ClearingAccountQueryHookResult = ReturnType<
  typeof useClearingAccountQuery
>;
export type ClearingAccountLazyQueryHookResult = ReturnType<
  typeof useClearingAccountLazyQuery
>;
export type ClearingAccountQueryResult = Apollo.QueryResult<
  ClearingAccountQuery,
  ClearingAccountQueryVariables
>;
export const ClearingAccountInvestigationStatusDocument = gql`
  query clearingAccountInvestigationStatus($input: ClearingAccountInput!) {
    clearingAccount(input: $input) {
      id
      cipInvestigationResult {
        status
        categories {
          name
          requestedDocuments {
            name
            description
          }
        }
      }
    }
  }
`;

/**
 * __useClearingAccountInvestigationStatusQuery__
 *
 * To run a query within a React component, call `useClearingAccountInvestigationStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useClearingAccountInvestigationStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useClearingAccountInvestigationStatusQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useClearingAccountInvestigationStatusQuery(
  baseOptions: Apollo.QueryHookOptions<
    ClearingAccountInvestigationStatusQuery,
    ClearingAccountInvestigationStatusQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ClearingAccountInvestigationStatusQuery,
    ClearingAccountInvestigationStatusQueryVariables
  >(ClearingAccountInvestigationStatusDocument, options);
}
export function useClearingAccountInvestigationStatusLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ClearingAccountInvestigationStatusQuery,
    ClearingAccountInvestigationStatusQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ClearingAccountInvestigationStatusQuery,
    ClearingAccountInvestigationStatusQueryVariables
  >(ClearingAccountInvestigationStatusDocument, options);
}
export type ClearingAccountInvestigationStatusQueryHookResult = ReturnType<
  typeof useClearingAccountInvestigationStatusQuery
>;
export type ClearingAccountInvestigationStatusLazyQueryHookResult = ReturnType<
  typeof useClearingAccountInvestigationStatusLazyQuery
>;
export type ClearingAccountInvestigationStatusQueryResult = Apollo.QueryResult<
  ClearingAccountInvestigationStatusQuery,
  ClearingAccountInvestigationStatusQueryVariables
>;
export const DailyCashDividendDocument = gql`
  query DailyCashDividend($clearingAccountId: ID!, $id: ID!) {
    clearingAccount(input: { id: $clearingAccountId, type: ID }) {
      id
      dailyCashDividend(id: $id) {
        ...DailyCashDividendItem
      }
    }
  }
  ${DailyCashDividendItemFragmentDoc}
`;

/**
 * __useDailyCashDividendQuery__
 *
 * To run a query within a React component, call `useDailyCashDividendQuery` and pass it any options that fit your needs.
 * When your component renders, `useDailyCashDividendQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDailyCashDividendQuery({
 *   variables: {
 *      clearingAccountId: // value for 'clearingAccountId'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDailyCashDividendQuery(
  baseOptions: Apollo.QueryHookOptions<
    DailyCashDividendQuery,
    DailyCashDividendQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    DailyCashDividendQuery,
    DailyCashDividendQueryVariables
  >(DailyCashDividendDocument, options);
}
export function useDailyCashDividendLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    DailyCashDividendQuery,
    DailyCashDividendQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    DailyCashDividendQuery,
    DailyCashDividendQueryVariables
  >(DailyCashDividendDocument, options);
}
export type DailyCashDividendQueryHookResult = ReturnType<
  typeof useDailyCashDividendQuery
>;
export type DailyCashDividendLazyQueryHookResult = ReturnType<
  typeof useDailyCashDividendLazyQuery
>;
export type DailyCashDividendQueryResult = Apollo.QueryResult<
  DailyCashDividendQuery,
  DailyCashDividendQueryVariables
>;
export const DemoDailyCashDividendDocument = gql`
  query demoDailyCashDividend($clearingAccountId: ID!, $id: ID!) {
    clearingAccount: demoClearingAccount(
      input: { id: $clearingAccountId, type: ID }
    ) {
      id
      dailyCashDividend: demoDailyCashDividend(id: $id) {
        ...DailyCashDividendItem
      }
    }
  }
  ${DailyCashDividendItemFragmentDoc}
`;

/**
 * __useDemoDailyCashDividendQuery__
 *
 * To run a query within a React component, call `useDemoDailyCashDividendQuery` and pass it any options that fit your needs.
 * When your component renders, `useDemoDailyCashDividendQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDemoDailyCashDividendQuery({
 *   variables: {
 *      clearingAccountId: // value for 'clearingAccountId'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDemoDailyCashDividendQuery(
  baseOptions: Apollo.QueryHookOptions<
    DemoDailyCashDividendQuery,
    DemoDailyCashDividendQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    DemoDailyCashDividendQuery,
    DemoDailyCashDividendQueryVariables
  >(DemoDailyCashDividendDocument, options);
}
export function useDemoDailyCashDividendLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    DemoDailyCashDividendQuery,
    DemoDailyCashDividendQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    DemoDailyCashDividendQuery,
    DemoDailyCashDividendQueryVariables
  >(DemoDailyCashDividendDocument, options);
}
export type DemoDailyCashDividendQueryHookResult = ReturnType<
  typeof useDemoDailyCashDividendQuery
>;
export type DemoDailyCashDividendLazyQueryHookResult = ReturnType<
  typeof useDemoDailyCashDividendLazyQuery
>;
export type DemoDailyCashDividendQueryResult = Apollo.QueryResult<
  DemoDailyCashDividendQuery,
  DemoDailyCashDividendQueryVariables
>;
export const DemoClearingAccountDocument = gql`
  query demoClearingAccount($clearingAccountId: ID!) {
    clearingAccount: demoClearingAccount(
      input: { id: $clearingAccountId, type: ID }
    ) {
      ...ClearingAccount
    }
  }
  ${ClearingAccountFragmentDoc}
`;

/**
 * __useDemoClearingAccountQuery__
 *
 * To run a query within a React component, call `useDemoClearingAccountQuery` and pass it any options that fit your needs.
 * When your component renders, `useDemoClearingAccountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDemoClearingAccountQuery({
 *   variables: {
 *      clearingAccountId: // value for 'clearingAccountId'
 *   },
 * });
 */
export function useDemoClearingAccountQuery(
  baseOptions: Apollo.QueryHookOptions<
    DemoClearingAccountQuery,
    DemoClearingAccountQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    DemoClearingAccountQuery,
    DemoClearingAccountQueryVariables
  >(DemoClearingAccountDocument, options);
}
export function useDemoClearingAccountLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    DemoClearingAccountQuery,
    DemoClearingAccountQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    DemoClearingAccountQuery,
    DemoClearingAccountQueryVariables
  >(DemoClearingAccountDocument, options);
}
export type DemoClearingAccountQueryHookResult = ReturnType<
  typeof useDemoClearingAccountQuery
>;
export type DemoClearingAccountLazyQueryHookResult = ReturnType<
  typeof useDemoClearingAccountLazyQuery
>;
export type DemoClearingAccountQueryResult = Apollo.QueryResult<
  DemoClearingAccountQuery,
  DemoClearingAccountQueryVariables
>;
export const DetailedQuoteDocument = gql`
  query detailedQuote($input: DetailedQuoteInput!) {
    detailedQuote(input: $input) {
      ...DetailedQuote
    }
  }
  ${DetailedQuoteFragmentDoc}
`;

/**
 * __useDetailedQuoteQuery__
 *
 * To run a query within a React component, call `useDetailedQuoteQuery` and pass it any options that fit your needs.
 * When your component renders, `useDetailedQuoteQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDetailedQuoteQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDetailedQuoteQuery(
  baseOptions: Apollo.QueryHookOptions<
    DetailedQuoteQuery,
    DetailedQuoteQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<DetailedQuoteQuery, DetailedQuoteQueryVariables>(
    DetailedQuoteDocument,
    options
  );
}
export function useDetailedQuoteLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    DetailedQuoteQuery,
    DetailedQuoteQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<DetailedQuoteQuery, DetailedQuoteQueryVariables>(
    DetailedQuoteDocument,
    options
  );
}
export type DetailedQuoteQueryHookResult = ReturnType<
  typeof useDetailedQuoteQuery
>;
export type DetailedQuoteLazyQueryHookResult = ReturnType<
  typeof useDetailedQuoteLazyQuery
>;
export type DetailedQuoteQueryResult = Apollo.QueryResult<
  DetailedQuoteQuery,
  DetailedQuoteQueryVariables
>;
export const DirectIndexFeeChargeDocument = gql`
  query directIndexFeeCharge($clearingAccountId: ID!, $id: ID!) {
    getDirectIndexFeeCharge(clearingAccountId: $clearingAccountId, id: $id) {
      ...DirectIndexFeeCharge
    }
  }
  ${DirectIndexFeeChargeFragmentDoc}
`;

/**
 * __useDirectIndexFeeChargeQuery__
 *
 * To run a query within a React component, call `useDirectIndexFeeChargeQuery` and pass it any options that fit your needs.
 * When your component renders, `useDirectIndexFeeChargeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDirectIndexFeeChargeQuery({
 *   variables: {
 *      clearingAccountId: // value for 'clearingAccountId'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDirectIndexFeeChargeQuery(
  baseOptions: Apollo.QueryHookOptions<
    DirectIndexFeeChargeQuery,
    DirectIndexFeeChargeQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    DirectIndexFeeChargeQuery,
    DirectIndexFeeChargeQueryVariables
  >(DirectIndexFeeChargeDocument, options);
}
export function useDirectIndexFeeChargeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    DirectIndexFeeChargeQuery,
    DirectIndexFeeChargeQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    DirectIndexFeeChargeQuery,
    DirectIndexFeeChargeQueryVariables
  >(DirectIndexFeeChargeDocument, options);
}
export type DirectIndexFeeChargeQueryHookResult = ReturnType<
  typeof useDirectIndexFeeChargeQuery
>;
export type DirectIndexFeeChargeLazyQueryHookResult = ReturnType<
  typeof useDirectIndexFeeChargeLazyQuery
>;
export type DirectIndexFeeChargeQueryResult = Apollo.QueryResult<
  DirectIndexFeeChargeQuery,
  DirectIndexFeeChargeQueryVariables
>;
export const DemoDirectIndexFeeChargeDocument = gql`
  query demoDirectIndexFeeCharge($clearingAccountId: ID!, $id: ID!) {
    getDirectIndexFeeCharge: demoGetDirectIndexFeeCharge(
      clearingAccountId: $clearingAccountId
      id: $id
    ) {
      ...DirectIndexFeeCharge
    }
  }
  ${DirectIndexFeeChargeFragmentDoc}
`;

/**
 * __useDemoDirectIndexFeeChargeQuery__
 *
 * To run a query within a React component, call `useDemoDirectIndexFeeChargeQuery` and pass it any options that fit your needs.
 * When your component renders, `useDemoDirectIndexFeeChargeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDemoDirectIndexFeeChargeQuery({
 *   variables: {
 *      clearingAccountId: // value for 'clearingAccountId'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDemoDirectIndexFeeChargeQuery(
  baseOptions: Apollo.QueryHookOptions<
    DemoDirectIndexFeeChargeQuery,
    DemoDirectIndexFeeChargeQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    DemoDirectIndexFeeChargeQuery,
    DemoDirectIndexFeeChargeQueryVariables
  >(DemoDirectIndexFeeChargeDocument, options);
}
export function useDemoDirectIndexFeeChargeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    DemoDirectIndexFeeChargeQuery,
    DemoDirectIndexFeeChargeQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    DemoDirectIndexFeeChargeQuery,
    DemoDirectIndexFeeChargeQueryVariables
  >(DemoDirectIndexFeeChargeDocument, options);
}
export type DemoDirectIndexFeeChargeQueryHookResult = ReturnType<
  typeof useDemoDirectIndexFeeChargeQuery
>;
export type DemoDirectIndexFeeChargeLazyQueryHookResult = ReturnType<
  typeof useDemoDirectIndexFeeChargeLazyQuery
>;
export type DemoDirectIndexFeeChargeQueryResult = Apollo.QueryResult<
  DemoDirectIndexFeeChargeQuery,
  DemoDirectIndexFeeChargeQueryVariables
>;
export const DirectIndexFeeChargesDocument = gql`
  query directIndexFeeCharges($clearingAccountId: ID!, $subAccountId: ID!) {
    getDirectIndexFeeCharges(
      clearingAccountId: $clearingAccountId
      subAccountId: $subAccountId
    ) {
      ...DirectIndexFeeCharge
    }
  }
  ${DirectIndexFeeChargeFragmentDoc}
`;

/**
 * __useDirectIndexFeeChargesQuery__
 *
 * To run a query within a React component, call `useDirectIndexFeeChargesQuery` and pass it any options that fit your needs.
 * When your component renders, `useDirectIndexFeeChargesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDirectIndexFeeChargesQuery({
 *   variables: {
 *      clearingAccountId: // value for 'clearingAccountId'
 *      subAccountId: // value for 'subAccountId'
 *   },
 * });
 */
export function useDirectIndexFeeChargesQuery(
  baseOptions: Apollo.QueryHookOptions<
    DirectIndexFeeChargesQuery,
    DirectIndexFeeChargesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    DirectIndexFeeChargesQuery,
    DirectIndexFeeChargesQueryVariables
  >(DirectIndexFeeChargesDocument, options);
}
export function useDirectIndexFeeChargesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    DirectIndexFeeChargesQuery,
    DirectIndexFeeChargesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    DirectIndexFeeChargesQuery,
    DirectIndexFeeChargesQueryVariables
  >(DirectIndexFeeChargesDocument, options);
}
export type DirectIndexFeeChargesQueryHookResult = ReturnType<
  typeof useDirectIndexFeeChargesQuery
>;
export type DirectIndexFeeChargesLazyQueryHookResult = ReturnType<
  typeof useDirectIndexFeeChargesLazyQuery
>;
export type DirectIndexFeeChargesQueryResult = Apollo.QueryResult<
  DirectIndexFeeChargesQuery,
  DirectIndexFeeChargesQueryVariables
>;
export const DemoDirectIndexFeeChargesDocument = gql`
  query demoDirectIndexFeeCharges($clearingAccountId: ID!, $subAccountId: ID!) {
    getDirectIndexFeeCharges: demoGetDirectIndexFeeCharges(
      clearingAccountId: $clearingAccountId
      subAccountId: $subAccountId
    ) {
      ...DirectIndexFeeCharge
    }
  }
  ${DirectIndexFeeChargeFragmentDoc}
`;

/**
 * __useDemoDirectIndexFeeChargesQuery__
 *
 * To run a query within a React component, call `useDemoDirectIndexFeeChargesQuery` and pass it any options that fit your needs.
 * When your component renders, `useDemoDirectIndexFeeChargesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDemoDirectIndexFeeChargesQuery({
 *   variables: {
 *      clearingAccountId: // value for 'clearingAccountId'
 *      subAccountId: // value for 'subAccountId'
 *   },
 * });
 */
export function useDemoDirectIndexFeeChargesQuery(
  baseOptions: Apollo.QueryHookOptions<
    DemoDirectIndexFeeChargesQuery,
    DemoDirectIndexFeeChargesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    DemoDirectIndexFeeChargesQuery,
    DemoDirectIndexFeeChargesQueryVariables
  >(DemoDirectIndexFeeChargesDocument, options);
}
export function useDemoDirectIndexFeeChargesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    DemoDirectIndexFeeChargesQuery,
    DemoDirectIndexFeeChargesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    DemoDirectIndexFeeChargesQuery,
    DemoDirectIndexFeeChargesQueryVariables
  >(DemoDirectIndexFeeChargesDocument, options);
}
export type DemoDirectIndexFeeChargesQueryHookResult = ReturnType<
  typeof useDemoDirectIndexFeeChargesQuery
>;
export type DemoDirectIndexFeeChargesLazyQueryHookResult = ReturnType<
  typeof useDemoDirectIndexFeeChargesLazyQuery
>;
export type DemoDirectIndexFeeChargesQueryResult = Apollo.QueryResult<
  DemoDirectIndexFeeChargesQuery,
  DemoDirectIndexFeeChargesQueryVariables
>;
export const DirectIndexTradeExecutionSummaryDocument = gql`
  query DirectIndexTradeExecutionSummary($clearingAccountId: ID!, $id: ID!) {
    getDirectIndexTradeExecutionSummary(
      clearingAccountId: $clearingAccountId
      id: $id
    ) {
      ...DirectIndexTradeExecutionSummary
    }
  }
  ${DirectIndexTradeExecutionSummaryFragmentDoc}
`;

/**
 * __useDirectIndexTradeExecutionSummaryQuery__
 *
 * To run a query within a React component, call `useDirectIndexTradeExecutionSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useDirectIndexTradeExecutionSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDirectIndexTradeExecutionSummaryQuery({
 *   variables: {
 *      clearingAccountId: // value for 'clearingAccountId'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDirectIndexTradeExecutionSummaryQuery(
  baseOptions: Apollo.QueryHookOptions<
    DirectIndexTradeExecutionSummaryQuery,
    DirectIndexTradeExecutionSummaryQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    DirectIndexTradeExecutionSummaryQuery,
    DirectIndexTradeExecutionSummaryQueryVariables
  >(DirectIndexTradeExecutionSummaryDocument, options);
}
export function useDirectIndexTradeExecutionSummaryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    DirectIndexTradeExecutionSummaryQuery,
    DirectIndexTradeExecutionSummaryQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    DirectIndexTradeExecutionSummaryQuery,
    DirectIndexTradeExecutionSummaryQueryVariables
  >(DirectIndexTradeExecutionSummaryDocument, options);
}
export type DirectIndexTradeExecutionSummaryQueryHookResult = ReturnType<
  typeof useDirectIndexTradeExecutionSummaryQuery
>;
export type DirectIndexTradeExecutionSummaryLazyQueryHookResult = ReturnType<
  typeof useDirectIndexTradeExecutionSummaryLazyQuery
>;
export type DirectIndexTradeExecutionSummaryQueryResult = Apollo.QueryResult<
  DirectIndexTradeExecutionSummaryQuery,
  DirectIndexTradeExecutionSummaryQueryVariables
>;
export const DemoDirectIndexTradeExecutionSummaryDocument = gql`
  query demoDirectIndexTradeExecutionSummary(
    $clearingAccountId: ID!
    $id: ID!
  ) {
    getDirectIndexTradeExecutionSummary: demoGetDirectIndexTradeExecutionSummary(
      clearingAccountId: $clearingAccountId
      id: $id
    ) {
      ...DirectIndexTradeExecutionSummary
    }
  }
  ${DirectIndexTradeExecutionSummaryFragmentDoc}
`;

/**
 * __useDemoDirectIndexTradeExecutionSummaryQuery__
 *
 * To run a query within a React component, call `useDemoDirectIndexTradeExecutionSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useDemoDirectIndexTradeExecutionSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDemoDirectIndexTradeExecutionSummaryQuery({
 *   variables: {
 *      clearingAccountId: // value for 'clearingAccountId'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDemoDirectIndexTradeExecutionSummaryQuery(
  baseOptions: Apollo.QueryHookOptions<
    DemoDirectIndexTradeExecutionSummaryQuery,
    DemoDirectIndexTradeExecutionSummaryQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    DemoDirectIndexTradeExecutionSummaryQuery,
    DemoDirectIndexTradeExecutionSummaryQueryVariables
  >(DemoDirectIndexTradeExecutionSummaryDocument, options);
}
export function useDemoDirectIndexTradeExecutionSummaryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    DemoDirectIndexTradeExecutionSummaryQuery,
    DemoDirectIndexTradeExecutionSummaryQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    DemoDirectIndexTradeExecutionSummaryQuery,
    DemoDirectIndexTradeExecutionSummaryQueryVariables
  >(DemoDirectIndexTradeExecutionSummaryDocument, options);
}
export type DemoDirectIndexTradeExecutionSummaryQueryHookResult = ReturnType<
  typeof useDemoDirectIndexTradeExecutionSummaryQuery
>;
export type DemoDirectIndexTradeExecutionSummaryLazyQueryHookResult =
  ReturnType<typeof useDemoDirectIndexTradeExecutionSummaryLazyQuery>;
export type DemoDirectIndexTradeExecutionSummaryQueryResult =
  Apollo.QueryResult<
    DemoDirectIndexTradeExecutionSummaryQuery,
    DemoDirectIndexTradeExecutionSummaryQueryVariables
  >;
export const DividendSummaryDocument = gql`
  query dividendSummary(
    $clearingAccountId: ID!
    $subAccountId: ID
    $startDate: Date
    $endDate: Date
  ) {
    clearingAccount(input: { id: $clearingAccountId, type: ID }) {
      id
      dividendSummary(
        input: {
          subAccountId: $subAccountId
          fromDate: $startDate
          toDate: $endDate
        }
      ) {
        totalDividends
        stockAdjustmentAmounts {
          adjustmentType
          amount
        }
      }
    }
  }
`;

/**
 * __useDividendSummaryQuery__
 *
 * To run a query within a React component, call `useDividendSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useDividendSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDividendSummaryQuery({
 *   variables: {
 *      clearingAccountId: // value for 'clearingAccountId'
 *      subAccountId: // value for 'subAccountId'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *   },
 * });
 */
export function useDividendSummaryQuery(
  baseOptions: Apollo.QueryHookOptions<
    DividendSummaryQuery,
    DividendSummaryQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<DividendSummaryQuery, DividendSummaryQueryVariables>(
    DividendSummaryDocument,
    options
  );
}
export function useDividendSummaryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    DividendSummaryQuery,
    DividendSummaryQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    DividendSummaryQuery,
    DividendSummaryQueryVariables
  >(DividendSummaryDocument, options);
}
export type DividendSummaryQueryHookResult = ReturnType<
  typeof useDividendSummaryQuery
>;
export type DividendSummaryLazyQueryHookResult = ReturnType<
  typeof useDividendSummaryLazyQuery
>;
export type DividendSummaryQueryResult = Apollo.QueryResult<
  DividendSummaryQuery,
  DividendSummaryQueryVariables
>;
export const DividendsForHoldingDocument = gql`
  query dividendsForHolding(
    $clearingAccountId: ID!
    $securityId: ID!
    $after: DateTime
  ) {
    clearingAccount(input: { id: $clearingAccountId, type: ID }) {
      id
      cashDividendsForHolding(securityId: $securityId, after: $after) {
        ...CashDividendItem
      }
      stockAdjustmentsForHolding(securityId: $securityId, after: $after) {
        ...StockAdjustmentDetails
      }
    }
  }
  ${CashDividendItemFragmentDoc}
  ${StockAdjustmentDetailsFragmentDoc}
`;

/**
 * __useDividendsForHoldingQuery__
 *
 * To run a query within a React component, call `useDividendsForHoldingQuery` and pass it any options that fit your needs.
 * When your component renders, `useDividendsForHoldingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDividendsForHoldingQuery({
 *   variables: {
 *      clearingAccountId: // value for 'clearingAccountId'
 *      securityId: // value for 'securityId'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useDividendsForHoldingQuery(
  baseOptions: Apollo.QueryHookOptions<
    DividendsForHoldingQuery,
    DividendsForHoldingQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    DividendsForHoldingQuery,
    DividendsForHoldingQueryVariables
  >(DividendsForHoldingDocument, options);
}
export function useDividendsForHoldingLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    DividendsForHoldingQuery,
    DividendsForHoldingQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    DividendsForHoldingQuery,
    DividendsForHoldingQueryVariables
  >(DividendsForHoldingDocument, options);
}
export type DividendsForHoldingQueryHookResult = ReturnType<
  typeof useDividendsForHoldingQuery
>;
export type DividendsForHoldingLazyQueryHookResult = ReturnType<
  typeof useDividendsForHoldingLazyQuery
>;
export type DividendsForHoldingQueryResult = Apollo.QueryResult<
  DividendsForHoldingQuery,
  DividendsForHoldingQueryVariables
>;
export const EstimateDirectIndexGainLossDocument = gql`
  query estimateDirectIndexGainLoss($input: EstimateDirectIndexGainLossInput!) {
    estimateDirectIndexGainLoss(input: $input) {
      securityGainLoss {
        securityId
        longTermGains
        longTermWashSales
        shortTermGains
        shortTermWashSales
      }
      totalLongTermGainsWithoutWashSales
      totalShortTermGainsWithoutWashSales
      totalLongTermWashSales
      totalShortTermWashSales
      netShortTermLossesFromSubAccountsYTD
      netLongTermLossesFromSubAccountsYTD
      lossHarvestPotential
    }
  }
`;

/**
 * __useEstimateDirectIndexGainLossQuery__
 *
 * To run a query within a React component, call `useEstimateDirectIndexGainLossQuery` and pass it any options that fit your needs.
 * When your component renders, `useEstimateDirectIndexGainLossQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEstimateDirectIndexGainLossQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEstimateDirectIndexGainLossQuery(
  baseOptions: Apollo.QueryHookOptions<
    EstimateDirectIndexGainLossQuery,
    EstimateDirectIndexGainLossQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    EstimateDirectIndexGainLossQuery,
    EstimateDirectIndexGainLossQueryVariables
  >(EstimateDirectIndexGainLossDocument, options);
}
export function useEstimateDirectIndexGainLossLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    EstimateDirectIndexGainLossQuery,
    EstimateDirectIndexGainLossQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    EstimateDirectIndexGainLossQuery,
    EstimateDirectIndexGainLossQueryVariables
  >(EstimateDirectIndexGainLossDocument, options);
}
export type EstimateDirectIndexGainLossQueryHookResult = ReturnType<
  typeof useEstimateDirectIndexGainLossQuery
>;
export type EstimateDirectIndexGainLossLazyQueryHookResult = ReturnType<
  typeof useEstimateDirectIndexGainLossLazyQuery
>;
export type EstimateDirectIndexGainLossQueryResult = Apollo.QueryResult<
  EstimateDirectIndexGainLossQuery,
  EstimateDirectIndexGainLossQueryVariables
>;
export const GetAnnualizedReturnsDocument = gql`
  query getAnnualizedReturns($indices: [StockIndex!]!, $asOfDate: Date) {
    getAnnualizedReturns(indices: $indices, asOfDate: $asOfDate) {
      index
      annualizedReturns {
        annualizedReturn
        endDate
        endPrice
        startDate
        startConstituentCount
        endConstituentCount
        startPrice
        years
      }
    }
  }
`;

/**
 * __useGetAnnualizedReturnsQuery__
 *
 * To run a query within a React component, call `useGetAnnualizedReturnsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAnnualizedReturnsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAnnualizedReturnsQuery({
 *   variables: {
 *      indices: // value for 'indices'
 *      asOfDate: // value for 'asOfDate'
 *   },
 * });
 */
export function useGetAnnualizedReturnsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetAnnualizedReturnsQuery,
    GetAnnualizedReturnsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetAnnualizedReturnsQuery,
    GetAnnualizedReturnsQueryVariables
  >(GetAnnualizedReturnsDocument, options);
}
export function useGetAnnualizedReturnsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAnnualizedReturnsQuery,
    GetAnnualizedReturnsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetAnnualizedReturnsQuery,
    GetAnnualizedReturnsQueryVariables
  >(GetAnnualizedReturnsDocument, options);
}
export type GetAnnualizedReturnsQueryHookResult = ReturnType<
  typeof useGetAnnualizedReturnsQuery
>;
export type GetAnnualizedReturnsLazyQueryHookResult = ReturnType<
  typeof useGetAnnualizedReturnsLazyQuery
>;
export type GetAnnualizedReturnsQueryResult = Apollo.QueryResult<
  GetAnnualizedReturnsQuery,
  GetAnnualizedReturnsQueryVariables
>;
export const GetAvailableIndexTypesDocument = gql`
  query getAvailableIndexTypes {
    getAvailableIndexTypes
  }
`;

/**
 * __useGetAvailableIndexTypesQuery__
 *
 * To run a query within a React component, call `useGetAvailableIndexTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAvailableIndexTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAvailableIndexTypesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAvailableIndexTypesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetAvailableIndexTypesQuery,
    GetAvailableIndexTypesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetAvailableIndexTypesQuery,
    GetAvailableIndexTypesQueryVariables
  >(GetAvailableIndexTypesDocument, options);
}
export function useGetAvailableIndexTypesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAvailableIndexTypesQuery,
    GetAvailableIndexTypesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetAvailableIndexTypesQuery,
    GetAvailableIndexTypesQueryVariables
  >(GetAvailableIndexTypesDocument, options);
}
export type GetAvailableIndexTypesQueryHookResult = ReturnType<
  typeof useGetAvailableIndexTypesQuery
>;
export type GetAvailableIndexTypesLazyQueryHookResult = ReturnType<
  typeof useGetAvailableIndexTypesLazyQuery
>;
export type GetAvailableIndexTypesQueryResult = Apollo.QueryResult<
  GetAvailableIndexTypesQuery,
  GetAvailableIndexTypesQueryVariables
>;
export const GetDayTradeCountDocument = gql`
  query getDayTradeCount($input: CheckDayTradeInput!) {
    getDayTradeCount(input: $input) {
      dayTrades
      totalTrades
      currentTradeFlagged
      dayRange
    }
  }
`;

/**
 * __useGetDayTradeCountQuery__
 *
 * To run a query within a React component, call `useGetDayTradeCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDayTradeCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDayTradeCountQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetDayTradeCountQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetDayTradeCountQuery,
    GetDayTradeCountQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetDayTradeCountQuery, GetDayTradeCountQueryVariables>(
    GetDayTradeCountDocument,
    options
  );
}
export function useGetDayTradeCountLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetDayTradeCountQuery,
    GetDayTradeCountQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetDayTradeCountQuery,
    GetDayTradeCountQueryVariables
  >(GetDayTradeCountDocument, options);
}
export type GetDayTradeCountQueryHookResult = ReturnType<
  typeof useGetDayTradeCountQuery
>;
export type GetDayTradeCountLazyQueryHookResult = ReturnType<
  typeof useGetDayTradeCountLazyQuery
>;
export type GetDayTradeCountQueryResult = Apollo.QueryResult<
  GetDayTradeCountQuery,
  GetDayTradeCountQueryVariables
>;
export const GetDirectIndexSecurityOverridesDocument = gql`
  query getDirectIndexSecurityOverrides($subAccountId: ID!) {
    getDirectIndexSecurityOverrides(subAccountId: $subAccountId) {
      subAccountId
      weights {
        ...DirectIndexWeight
      }
    }
  }
  ${DirectIndexWeightFragmentDoc}
`;

/**
 * __useGetDirectIndexSecurityOverridesQuery__
 *
 * To run a query within a React component, call `useGetDirectIndexSecurityOverridesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDirectIndexSecurityOverridesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDirectIndexSecurityOverridesQuery({
 *   variables: {
 *      subAccountId: // value for 'subAccountId'
 *   },
 * });
 */
export function useGetDirectIndexSecurityOverridesQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetDirectIndexSecurityOverridesQuery,
    GetDirectIndexSecurityOverridesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetDirectIndexSecurityOverridesQuery,
    GetDirectIndexSecurityOverridesQueryVariables
  >(GetDirectIndexSecurityOverridesDocument, options);
}
export function useGetDirectIndexSecurityOverridesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetDirectIndexSecurityOverridesQuery,
    GetDirectIndexSecurityOverridesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetDirectIndexSecurityOverridesQuery,
    GetDirectIndexSecurityOverridesQueryVariables
  >(GetDirectIndexSecurityOverridesDocument, options);
}
export type GetDirectIndexSecurityOverridesQueryHookResult = ReturnType<
  typeof useGetDirectIndexSecurityOverridesQuery
>;
export type GetDirectIndexSecurityOverridesLazyQueryHookResult = ReturnType<
  typeof useGetDirectIndexSecurityOverridesLazyQuery
>;
export type GetDirectIndexSecurityOverridesQueryResult = Apollo.QueryResult<
  GetDirectIndexSecurityOverridesQuery,
  GetDirectIndexSecurityOverridesQueryVariables
>;
export const GetDirectIndexWeightsForAccountDocument = gql`
  query getDirectIndexWeightsForAccount($subAccountId: ID!) {
    getDirectIndexWeightsForAccount(subAccountId: $subAccountId) {
      subAccountId
      weights {
        ...DirectIndexWeight
      }
    }
  }
  ${DirectIndexWeightFragmentDoc}
`;

/**
 * __useGetDirectIndexWeightsForAccountQuery__
 *
 * To run a query within a React component, call `useGetDirectIndexWeightsForAccountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDirectIndexWeightsForAccountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDirectIndexWeightsForAccountQuery({
 *   variables: {
 *      subAccountId: // value for 'subAccountId'
 *   },
 * });
 */
export function useGetDirectIndexWeightsForAccountQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetDirectIndexWeightsForAccountQuery,
    GetDirectIndexWeightsForAccountQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetDirectIndexWeightsForAccountQuery,
    GetDirectIndexWeightsForAccountQueryVariables
  >(GetDirectIndexWeightsForAccountDocument, options);
}
export function useGetDirectIndexWeightsForAccountLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetDirectIndexWeightsForAccountQuery,
    GetDirectIndexWeightsForAccountQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetDirectIndexWeightsForAccountQuery,
    GetDirectIndexWeightsForAccountQueryVariables
  >(GetDirectIndexWeightsForAccountDocument, options);
}
export type GetDirectIndexWeightsForAccountQueryHookResult = ReturnType<
  typeof useGetDirectIndexWeightsForAccountQuery
>;
export type GetDirectIndexWeightsForAccountLazyQueryHookResult = ReturnType<
  typeof useGetDirectIndexWeightsForAccountLazyQuery
>;
export type GetDirectIndexWeightsForAccountQueryResult = Apollo.QueryResult<
  GetDirectIndexWeightsForAccountQuery,
  GetDirectIndexWeightsForAccountQueryVariables
>;
export const DemoGetDirectIndexWeightsForAccountDocument = gql`
  query demoGetDirectIndexWeightsForAccount($subAccountId: ID!) {
    getDirectIndexWeightsForAccount: demoGetDirectIndexWeightsForAccount(
      subAccountId: $subAccountId
    ) {
      subAccountId
      weights {
        ...DirectIndexWeight
      }
    }
  }
  ${DirectIndexWeightFragmentDoc}
`;

/**
 * __useDemoGetDirectIndexWeightsForAccountQuery__
 *
 * To run a query within a React component, call `useDemoGetDirectIndexWeightsForAccountQuery` and pass it any options that fit your needs.
 * When your component renders, `useDemoGetDirectIndexWeightsForAccountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDemoGetDirectIndexWeightsForAccountQuery({
 *   variables: {
 *      subAccountId: // value for 'subAccountId'
 *   },
 * });
 */
export function useDemoGetDirectIndexWeightsForAccountQuery(
  baseOptions: Apollo.QueryHookOptions<
    DemoGetDirectIndexWeightsForAccountQuery,
    DemoGetDirectIndexWeightsForAccountQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    DemoGetDirectIndexWeightsForAccountQuery,
    DemoGetDirectIndexWeightsForAccountQueryVariables
  >(DemoGetDirectIndexWeightsForAccountDocument, options);
}
export function useDemoGetDirectIndexWeightsForAccountLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    DemoGetDirectIndexWeightsForAccountQuery,
    DemoGetDirectIndexWeightsForAccountQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    DemoGetDirectIndexWeightsForAccountQuery,
    DemoGetDirectIndexWeightsForAccountQueryVariables
  >(DemoGetDirectIndexWeightsForAccountDocument, options);
}
export type DemoGetDirectIndexWeightsForAccountQueryHookResult = ReturnType<
  typeof useDemoGetDirectIndexWeightsForAccountQuery
>;
export type DemoGetDirectIndexWeightsForAccountLazyQueryHookResult = ReturnType<
  typeof useDemoGetDirectIndexWeightsForAccountLazyQuery
>;
export type DemoGetDirectIndexWeightsForAccountQueryResult = Apollo.QueryResult<
  DemoGetDirectIndexWeightsForAccountQuery,
  DemoGetDirectIndexWeightsForAccountQueryVariables
>;
export const GetIndexConstituentsDocument = gql`
  query getIndexConstituents(
    $index: StockIndex!
    $gicsCode: GICSCode
    $asOfDate: Date
  ) {
    getIndexConstituents(
      index: $index
      gicsCode: $gicsCode
      asOfDate: $asOfDate
    ) {
      index
      constituents {
        ...IndexConstituent
      }
    }
  }
  ${IndexConstituentFragmentDoc}
`;

/**
 * __useGetIndexConstituentsQuery__
 *
 * To run a query within a React component, call `useGetIndexConstituentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetIndexConstituentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetIndexConstituentsQuery({
 *   variables: {
 *      index: // value for 'index'
 *      gicsCode: // value for 'gicsCode'
 *      asOfDate: // value for 'asOfDate'
 *   },
 * });
 */
export function useGetIndexConstituentsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetIndexConstituentsQuery,
    GetIndexConstituentsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetIndexConstituentsQuery,
    GetIndexConstituentsQueryVariables
  >(GetIndexConstituentsDocument, options);
}
export function useGetIndexConstituentsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetIndexConstituentsQuery,
    GetIndexConstituentsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetIndexConstituentsQuery,
    GetIndexConstituentsQueryVariables
  >(GetIndexConstituentsDocument, options);
}
export type GetIndexConstituentsQueryHookResult = ReturnType<
  typeof useGetIndexConstituentsQuery
>;
export type GetIndexConstituentsLazyQueryHookResult = ReturnType<
  typeof useGetIndexConstituentsLazyQuery
>;
export type GetIndexConstituentsQueryResult = Apollo.QueryResult<
  GetIndexConstituentsQuery,
  GetIndexConstituentsQueryVariables
>;
export const DemoGetIndexConstituentsDocument = gql`
  query demoGetIndexConstituents(
    $index: StockIndex!
    $gicsCode: GICSCode
    $asOfDate: Date
  ) {
    getIndexConstituents: demoGetIndexConstituents(
      index: $index
      gicsCode: $gicsCode
      asOfDate: $asOfDate
    ) {
      index
      constituents {
        ...IndexConstituent
      }
    }
  }
  ${IndexConstituentFragmentDoc}
`;

/**
 * __useDemoGetIndexConstituentsQuery__
 *
 * To run a query within a React component, call `useDemoGetIndexConstituentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useDemoGetIndexConstituentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDemoGetIndexConstituentsQuery({
 *   variables: {
 *      index: // value for 'index'
 *      gicsCode: // value for 'gicsCode'
 *      asOfDate: // value for 'asOfDate'
 *   },
 * });
 */
export function useDemoGetIndexConstituentsQuery(
  baseOptions: Apollo.QueryHookOptions<
    DemoGetIndexConstituentsQuery,
    DemoGetIndexConstituentsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    DemoGetIndexConstituentsQuery,
    DemoGetIndexConstituentsQueryVariables
  >(DemoGetIndexConstituentsDocument, options);
}
export function useDemoGetIndexConstituentsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    DemoGetIndexConstituentsQuery,
    DemoGetIndexConstituentsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    DemoGetIndexConstituentsQuery,
    DemoGetIndexConstituentsQueryVariables
  >(DemoGetIndexConstituentsDocument, options);
}
export type DemoGetIndexConstituentsQueryHookResult = ReturnType<
  typeof useDemoGetIndexConstituentsQuery
>;
export type DemoGetIndexConstituentsLazyQueryHookResult = ReturnType<
  typeof useDemoGetIndexConstituentsLazyQuery
>;
export type DemoGetIndexConstituentsQueryResult = Apollo.QueryResult<
  DemoGetIndexConstituentsQuery,
  DemoGetIndexConstituentsQueryVariables
>;
export const GetAllMmfMetadataDocument = gql`
  query getAllMMFMetadata {
    mmfMetadata: getAllMmfMetadata {
      ...MMFMetadata
    }
  }
  ${MmfMetadataFragmentDoc}
`;

/**
 * __useGetAllMmfMetadataQuery__
 *
 * To run a query within a React component, call `useGetAllMmfMetadataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllMmfMetadataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllMmfMetadataQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllMmfMetadataQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetAllMmfMetadataQuery,
    GetAllMmfMetadataQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetAllMmfMetadataQuery,
    GetAllMmfMetadataQueryVariables
  >(GetAllMmfMetadataDocument, options);
}
export function useGetAllMmfMetadataLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAllMmfMetadataQuery,
    GetAllMmfMetadataQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetAllMmfMetadataQuery,
    GetAllMmfMetadataQueryVariables
  >(GetAllMmfMetadataDocument, options);
}
export type GetAllMmfMetadataQueryHookResult = ReturnType<
  typeof useGetAllMmfMetadataQuery
>;
export type GetAllMmfMetadataLazyQueryHookResult = ReturnType<
  typeof useGetAllMmfMetadataLazyQuery
>;
export type GetAllMmfMetadataQueryResult = Apollo.QueryResult<
  GetAllMmfMetadataQuery,
  GetAllMmfMetadataQueryVariables
>;
export const GetOverlappingDirectIndicesDocument = gql`
  query getOverlappingDirectIndices(
    $clearingAccountId: ID!
    $directIndexType: DirectIndexType!
  ) {
    getOverlappingDirectIndices(
      clearingAccountId: $clearingAccountId
      directIndexType: $directIndexType
    )
  }
`;

/**
 * __useGetOverlappingDirectIndicesQuery__
 *
 * To run a query within a React component, call `useGetOverlappingDirectIndicesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOverlappingDirectIndicesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOverlappingDirectIndicesQuery({
 *   variables: {
 *      clearingAccountId: // value for 'clearingAccountId'
 *      directIndexType: // value for 'directIndexType'
 *   },
 * });
 */
export function useGetOverlappingDirectIndicesQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetOverlappingDirectIndicesQuery,
    GetOverlappingDirectIndicesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetOverlappingDirectIndicesQuery,
    GetOverlappingDirectIndicesQueryVariables
  >(GetOverlappingDirectIndicesDocument, options);
}
export function useGetOverlappingDirectIndicesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetOverlappingDirectIndicesQuery,
    GetOverlappingDirectIndicesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetOverlappingDirectIndicesQuery,
    GetOverlappingDirectIndicesQueryVariables
  >(GetOverlappingDirectIndicesDocument, options);
}
export type GetOverlappingDirectIndicesQueryHookResult = ReturnType<
  typeof useGetOverlappingDirectIndicesQuery
>;
export type GetOverlappingDirectIndicesLazyQueryHookResult = ReturnType<
  typeof useGetOverlappingDirectIndicesLazyQuery
>;
export type GetOverlappingDirectIndicesQueryResult = Apollo.QueryResult<
  GetOverlappingDirectIndicesQuery,
  GetOverlappingDirectIndicesQueryVariables
>;
export const PaginatedPendingInvitesForUserDocument = gql`
  query paginatedPendingInvitesForUser($input: PendingInvitesForUserInput!) {
    paginatedPendingInvitesForUser(input: $input) {
      entries {
        refereeEmail
        referredOn
      }
      nextPageToken
    }
  }
`;

/**
 * __usePaginatedPendingInvitesForUserQuery__
 *
 * To run a query within a React component, call `usePaginatedPendingInvitesForUserQuery` and pass it any options that fit your needs.
 * When your component renders, `usePaginatedPendingInvitesForUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePaginatedPendingInvitesForUserQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePaginatedPendingInvitesForUserQuery(
  baseOptions: Apollo.QueryHookOptions<
    PaginatedPendingInvitesForUserQuery,
    PaginatedPendingInvitesForUserQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    PaginatedPendingInvitesForUserQuery,
    PaginatedPendingInvitesForUserQueryVariables
  >(PaginatedPendingInvitesForUserDocument, options);
}
export function usePaginatedPendingInvitesForUserLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    PaginatedPendingInvitesForUserQuery,
    PaginatedPendingInvitesForUserQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    PaginatedPendingInvitesForUserQuery,
    PaginatedPendingInvitesForUserQueryVariables
  >(PaginatedPendingInvitesForUserDocument, options);
}
export type PaginatedPendingInvitesForUserQueryHookResult = ReturnType<
  typeof usePaginatedPendingInvitesForUserQuery
>;
export type PaginatedPendingInvitesForUserLazyQueryHookResult = ReturnType<
  typeof usePaginatedPendingInvitesForUserLazyQuery
>;
export type PaginatedPendingInvitesForUserQueryResult = Apollo.QueryResult<
  PaginatedPendingInvitesForUserQuery,
  PaginatedPendingInvitesForUserQueryVariables
>;
export const GetStockLotTransferDetailDocument = gql`
  query getStockLotTransferDetail($input: StockLotTransferDetailInput!) {
    getStockLotTransferDetail(input: $input) {
      lotsBySymbol {
        securityId
        symbol
        name
        stockPriceUsed
        withinIndex
        targetAllocation
        canShowTargetAllocation
        lots {
          id
          quantity
          taxLotOpenBuyDate
          longTermShortTermIndicator
          washSalesIndicator
          openBuyPrice
          recomputedRealizedGainLoss
        }
      }
      invalidStatesBySymbol {
        symbol
        name
        reason
      }
    }
  }
`;

/**
 * __useGetStockLotTransferDetailQuery__
 *
 * To run a query within a React component, call `useGetStockLotTransferDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStockLotTransferDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStockLotTransferDetailQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetStockLotTransferDetailQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetStockLotTransferDetailQuery,
    GetStockLotTransferDetailQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetStockLotTransferDetailQuery,
    GetStockLotTransferDetailQueryVariables
  >(GetStockLotTransferDetailDocument, options);
}
export function useGetStockLotTransferDetailLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetStockLotTransferDetailQuery,
    GetStockLotTransferDetailQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetStockLotTransferDetailQuery,
    GetStockLotTransferDetailQueryVariables
  >(GetStockLotTransferDetailDocument, options);
}
export type GetStockLotTransferDetailQueryHookResult = ReturnType<
  typeof useGetStockLotTransferDetailQuery
>;
export type GetStockLotTransferDetailLazyQueryHookResult = ReturnType<
  typeof useGetStockLotTransferDetailLazyQuery
>;
export type GetStockLotTransferDetailQueryResult = Apollo.QueryResult<
  GetStockLotTransferDetailQuery,
  GetStockLotTransferDetailQueryVariables
>;
export const DemoGetStockLotTransferDetailDocument = gql`
  query demoGetStockLotTransferDetail($input: StockLotTransferDetailInput!) {
    getStockLotTransferDetail: demoGetStockLotTransferDetail(input: $input) {
      lotsBySymbol {
        securityId
        symbol
        name
        stockPriceUsed
        withinIndex
        targetAllocation
        canShowTargetAllocation
        lots {
          id
          quantity
          taxLotOpenBuyDate
          longTermShortTermIndicator
          washSalesIndicator
          openBuyPrice
          recomputedRealizedGainLoss
        }
      }
      invalidStatesBySymbol {
        symbol
        name
        reason
      }
    }
  }
`;

/**
 * __useDemoGetStockLotTransferDetailQuery__
 *
 * To run a query within a React component, call `useDemoGetStockLotTransferDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useDemoGetStockLotTransferDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDemoGetStockLotTransferDetailQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDemoGetStockLotTransferDetailQuery(
  baseOptions: Apollo.QueryHookOptions<
    DemoGetStockLotTransferDetailQuery,
    DemoGetStockLotTransferDetailQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    DemoGetStockLotTransferDetailQuery,
    DemoGetStockLotTransferDetailQueryVariables
  >(DemoGetStockLotTransferDetailDocument, options);
}
export function useDemoGetStockLotTransferDetailLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    DemoGetStockLotTransferDetailQuery,
    DemoGetStockLotTransferDetailQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    DemoGetStockLotTransferDetailQuery,
    DemoGetStockLotTransferDetailQueryVariables
  >(DemoGetStockLotTransferDetailDocument, options);
}
export type DemoGetStockLotTransferDetailQueryHookResult = ReturnType<
  typeof useDemoGetStockLotTransferDetailQuery
>;
export type DemoGetStockLotTransferDetailLazyQueryHookResult = ReturnType<
  typeof useDemoGetStockLotTransferDetailLazyQuery
>;
export type DemoGetStockLotTransferDetailQueryResult = Apollo.QueryResult<
  DemoGetStockLotTransferDetailQuery,
  DemoGetStockLotTransferDetailQueryVariables
>;
export const GetSubAccountTransferValuesDocument = gql`
  query getSubAccountTransferValues($input: SubAccountTransferValueInput!) {
    getSubAccountTransferValues(input: $input) {
      assetType
      date
      value
    }
  }
`;

/**
 * __useGetSubAccountTransferValuesQuery__
 *
 * To run a query within a React component, call `useGetSubAccountTransferValuesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSubAccountTransferValuesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSubAccountTransferValuesQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetSubAccountTransferValuesQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetSubAccountTransferValuesQuery,
    GetSubAccountTransferValuesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetSubAccountTransferValuesQuery,
    GetSubAccountTransferValuesQueryVariables
  >(GetSubAccountTransferValuesDocument, options);
}
export function useGetSubAccountTransferValuesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetSubAccountTransferValuesQuery,
    GetSubAccountTransferValuesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetSubAccountTransferValuesQuery,
    GetSubAccountTransferValuesQueryVariables
  >(GetSubAccountTransferValuesDocument, options);
}
export type GetSubAccountTransferValuesQueryHookResult = ReturnType<
  typeof useGetSubAccountTransferValuesQuery
>;
export type GetSubAccountTransferValuesLazyQueryHookResult = ReturnType<
  typeof useGetSubAccountTransferValuesLazyQuery
>;
export type GetSubAccountTransferValuesQueryResult = Apollo.QueryResult<
  GetSubAccountTransferValuesQuery,
  GetSubAccountTransferValuesQueryVariables
>;
export const GetTreasuryYieldDocument = gql`
  query getTreasuryYield($clearingAccountId: ID!) {
    getTreasuryYield(clearingAccountId: $clearingAccountId) {
      ...TreasuryYield
    }
  }
  ${TreasuryYieldFragmentDoc}
`;

/**
 * __useGetTreasuryYieldQuery__
 *
 * To run a query within a React component, call `useGetTreasuryYieldQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTreasuryYieldQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTreasuryYieldQuery({
 *   variables: {
 *      clearingAccountId: // value for 'clearingAccountId'
 *   },
 * });
 */
export function useGetTreasuryYieldQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetTreasuryYieldQuery,
    GetTreasuryYieldQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetTreasuryYieldQuery, GetTreasuryYieldQueryVariables>(
    GetTreasuryYieldDocument,
    options
  );
}
export function useGetTreasuryYieldLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetTreasuryYieldQuery,
    GetTreasuryYieldQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetTreasuryYieldQuery,
    GetTreasuryYieldQueryVariables
  >(GetTreasuryYieldDocument, options);
}
export type GetTreasuryYieldQueryHookResult = ReturnType<
  typeof useGetTreasuryYieldQuery
>;
export type GetTreasuryYieldLazyQueryHookResult = ReturnType<
  typeof useGetTreasuryYieldLazyQuery
>;
export type GetTreasuryYieldQueryResult = Apollo.QueryResult<
  GetTreasuryYieldQuery,
  GetTreasuryYieldQueryVariables
>;
export const HistoricalStockPricesDocument = gql`
  query historicalStockPrices($input: HistoricalInput!) {
    historicalPrices(input: $input) {
      history {
        date
        close
      }
      symbol
    }
  }
`;

/**
 * __useHistoricalStockPricesQuery__
 *
 * To run a query within a React component, call `useHistoricalStockPricesQuery` and pass it any options that fit your needs.
 * When your component renders, `useHistoricalStockPricesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHistoricalStockPricesQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useHistoricalStockPricesQuery(
  baseOptions: Apollo.QueryHookOptions<
    HistoricalStockPricesQuery,
    HistoricalStockPricesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    HistoricalStockPricesQuery,
    HistoricalStockPricesQueryVariables
  >(HistoricalStockPricesDocument, options);
}
export function useHistoricalStockPricesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    HistoricalStockPricesQuery,
    HistoricalStockPricesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    HistoricalStockPricesQuery,
    HistoricalStockPricesQueryVariables
  >(HistoricalStockPricesDocument, options);
}
export type HistoricalStockPricesQueryHookResult = ReturnType<
  typeof useHistoricalStockPricesQuery
>;
export type HistoricalStockPricesLazyQueryHookResult = ReturnType<
  typeof useHistoricalStockPricesLazyQuery
>;
export type HistoricalStockPricesQueryResult = Apollo.QueryResult<
  HistoricalStockPricesQuery,
  HistoricalStockPricesQueryVariables
>;
export const IndexMetadataDocument = gql`
  query indexMetadata($types: [DirectIndexType!]!) {
    getIndexMetadata(args: { types: $types }) {
      ...IndexMetadata
    }
  }
  ${IndexMetadataFragmentDoc}
`;

/**
 * __useIndexMetadataQuery__
 *
 * To run a query within a React component, call `useIndexMetadataQuery` and pass it any options that fit your needs.
 * When your component renders, `useIndexMetadataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIndexMetadataQuery({
 *   variables: {
 *      types: // value for 'types'
 *   },
 * });
 */
export function useIndexMetadataQuery(
  baseOptions: Apollo.QueryHookOptions<
    IndexMetadataQuery,
    IndexMetadataQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<IndexMetadataQuery, IndexMetadataQueryVariables>(
    IndexMetadataDocument,
    options
  );
}
export function useIndexMetadataLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    IndexMetadataQuery,
    IndexMetadataQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<IndexMetadataQuery, IndexMetadataQueryVariables>(
    IndexMetadataDocument,
    options
  );
}
export type IndexMetadataQueryHookResult = ReturnType<
  typeof useIndexMetadataQuery
>;
export type IndexMetadataLazyQueryHookResult = ReturnType<
  typeof useIndexMetadataLazyQuery
>;
export type IndexMetadataQueryResult = Apollo.QueryResult<
  IndexMetadataQuery,
  IndexMetadataQueryVariables
>;
export const IndexSectorMetadataDocument = gql`
  query indexSectorMetadata($types: [DirectIndexType!]!) {
    getIndexMetadata(args: { types: $types }) {
      ...IndexSectorMetadata
    }
  }
  ${IndexSectorMetadataFragmentDoc}
`;

/**
 * __useIndexSectorMetadataQuery__
 *
 * To run a query within a React component, call `useIndexSectorMetadataQuery` and pass it any options that fit your needs.
 * When your component renders, `useIndexSectorMetadataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIndexSectorMetadataQuery({
 *   variables: {
 *      types: // value for 'types'
 *   },
 * });
 */
export function useIndexSectorMetadataQuery(
  baseOptions: Apollo.QueryHookOptions<
    IndexSectorMetadataQuery,
    IndexSectorMetadataQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    IndexSectorMetadataQuery,
    IndexSectorMetadataQueryVariables
  >(IndexSectorMetadataDocument, options);
}
export function useIndexSectorMetadataLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    IndexSectorMetadataQuery,
    IndexSectorMetadataQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    IndexSectorMetadataQuery,
    IndexSectorMetadataQueryVariables
  >(IndexSectorMetadataDocument, options);
}
export type IndexSectorMetadataQueryHookResult = ReturnType<
  typeof useIndexSectorMetadataQuery
>;
export type IndexSectorMetadataLazyQueryHookResult = ReturnType<
  typeof useIndexSectorMetadataLazyQuery
>;
export type IndexSectorMetadataQueryResult = Apollo.QueryResult<
  IndexSectorMetadataQuery,
  IndexSectorMetadataQueryVariables
>;
export const GetIndexHistoricalPerformanceDataDocument = gql`
  query getIndexHistoricalPerformanceData(
    $args: GetHistoricalIndexPerformanceDataInput!
  ) {
    getHistoricalPerformanceData(args: $args) {
      ...IndexHistoricalPerformance
    }
  }
  ${IndexHistoricalPerformanceFragmentDoc}
`;

/**
 * __useGetIndexHistoricalPerformanceDataQuery__
 *
 * To run a query within a React component, call `useGetIndexHistoricalPerformanceDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetIndexHistoricalPerformanceDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetIndexHistoricalPerformanceDataQuery({
 *   variables: {
 *      args: // value for 'args'
 *   },
 * });
 */
export function useGetIndexHistoricalPerformanceDataQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetIndexHistoricalPerformanceDataQuery,
    GetIndexHistoricalPerformanceDataQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetIndexHistoricalPerformanceDataQuery,
    GetIndexHistoricalPerformanceDataQueryVariables
  >(GetIndexHistoricalPerformanceDataDocument, options);
}
export function useGetIndexHistoricalPerformanceDataLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetIndexHistoricalPerformanceDataQuery,
    GetIndexHistoricalPerformanceDataQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetIndexHistoricalPerformanceDataQuery,
    GetIndexHistoricalPerformanceDataQueryVariables
  >(GetIndexHistoricalPerformanceDataDocument, options);
}
export type GetIndexHistoricalPerformanceDataQueryHookResult = ReturnType<
  typeof useGetIndexHistoricalPerformanceDataQuery
>;
export type GetIndexHistoricalPerformanceDataLazyQueryHookResult = ReturnType<
  typeof useGetIndexHistoricalPerformanceDataLazyQuery
>;
export type GetIndexHistoricalPerformanceDataQueryResult = Apollo.QueryResult<
  GetIndexHistoricalPerformanceDataQuery,
  GetIndexHistoricalPerformanceDataQueryVariables
>;
export const InterestsDocument = gql`
  query interests(
    $clearingAccountId: ID!
    $yearlyStartDate: Date!
    $yearlyEndDate: Date!
    $startDate: Date
    $endDate: Date
  ) {
    marginInterest: getCurrentMarginInterest {
      id
      value
      effectiveDate
      note
    }
    yearlyInterestCharges: getMarginInterestCharges(
      input: {
        clearingAccountId: $clearingAccountId
        startDate: $yearlyStartDate
        endDate: $yearlyEndDate
      }
    ) {
      data {
        amount
        eventTime
      }
    }
    currentBillingPeriodInterests: getDailyMarginInterestChargeForCurrentBillingPeriod(
      clearingAccountId: $clearingAccountId
      startDate: $startDate
      endDate: $endDate
    ) {
      loan
      marginInterest
      marginInterestCharge
      date
    }
    billingDates: getBillingDates {
      startDate
      endDate
    }
  }
`;

/**
 * __useInterestsQuery__
 *
 * To run a query within a React component, call `useInterestsQuery` and pass it any options that fit your needs.
 * When your component renders, `useInterestsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInterestsQuery({
 *   variables: {
 *      clearingAccountId: // value for 'clearingAccountId'
 *      yearlyStartDate: // value for 'yearlyStartDate'
 *      yearlyEndDate: // value for 'yearlyEndDate'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *   },
 * });
 */
export function useInterestsQuery(
  baseOptions: Apollo.QueryHookOptions<InterestsQuery, InterestsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<InterestsQuery, InterestsQueryVariables>(
    InterestsDocument,
    options
  );
}
export function useInterestsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    InterestsQuery,
    InterestsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<InterestsQuery, InterestsQueryVariables>(
    InterestsDocument,
    options
  );
}
export type InterestsQueryHookResult = ReturnType<typeof useInterestsQuery>;
export type InterestsLazyQueryHookResult = ReturnType<
  typeof useInterestsLazyQuery
>;
export type InterestsQueryResult = Apollo.QueryResult<
  InterestsQuery,
  InterestsQueryVariables
>;
export const IntraAccountCashTransferDocument = gql`
  query IntraAccountCashTransfer($clearingAccountId: ID!, $id: ID!) {
    clearingAccount(input: { id: $clearingAccountId, type: ID }) {
      id
      intraAccountCashTransfer(id: $id) {
        ...IntraAccountCashTransfer
      }
    }
  }
  ${IntraAccountCashTransferFragmentDoc}
`;

/**
 * __useIntraAccountCashTransferQuery__
 *
 * To run a query within a React component, call `useIntraAccountCashTransferQuery` and pass it any options that fit your needs.
 * When your component renders, `useIntraAccountCashTransferQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIntraAccountCashTransferQuery({
 *   variables: {
 *      clearingAccountId: // value for 'clearingAccountId'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useIntraAccountCashTransferQuery(
  baseOptions: Apollo.QueryHookOptions<
    IntraAccountCashTransferQuery,
    IntraAccountCashTransferQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    IntraAccountCashTransferQuery,
    IntraAccountCashTransferQueryVariables
  >(IntraAccountCashTransferDocument, options);
}
export function useIntraAccountCashTransferLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    IntraAccountCashTransferQuery,
    IntraAccountCashTransferQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    IntraAccountCashTransferQuery,
    IntraAccountCashTransferQueryVariables
  >(IntraAccountCashTransferDocument, options);
}
export type IntraAccountCashTransferQueryHookResult = ReturnType<
  typeof useIntraAccountCashTransferQuery
>;
export type IntraAccountCashTransferLazyQueryHookResult = ReturnType<
  typeof useIntraAccountCashTransferLazyQuery
>;
export type IntraAccountCashTransferQueryResult = Apollo.QueryResult<
  IntraAccountCashTransferQuery,
  IntraAccountCashTransferQueryVariables
>;
export const DemoIntraAccountCashTransferDocument = gql`
  query demoIntraAccountCashTransfer($clearingAccountId: ID!, $id: ID!) {
    clearingAccount: demoClearingAccount(
      input: { id: $clearingAccountId, type: ID }
    ) {
      id
      intraAccountCashTransfer: demoIntraAccountCashTransfer(id: $id) {
        ...IntraAccountCashTransfer
      }
    }
  }
  ${IntraAccountCashTransferFragmentDoc}
`;

/**
 * __useDemoIntraAccountCashTransferQuery__
 *
 * To run a query within a React component, call `useDemoIntraAccountCashTransferQuery` and pass it any options that fit your needs.
 * When your component renders, `useDemoIntraAccountCashTransferQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDemoIntraAccountCashTransferQuery({
 *   variables: {
 *      clearingAccountId: // value for 'clearingAccountId'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDemoIntraAccountCashTransferQuery(
  baseOptions: Apollo.QueryHookOptions<
    DemoIntraAccountCashTransferQuery,
    DemoIntraAccountCashTransferQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    DemoIntraAccountCashTransferQuery,
    DemoIntraAccountCashTransferQueryVariables
  >(DemoIntraAccountCashTransferDocument, options);
}
export function useDemoIntraAccountCashTransferLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    DemoIntraAccountCashTransferQuery,
    DemoIntraAccountCashTransferQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    DemoIntraAccountCashTransferQuery,
    DemoIntraAccountCashTransferQueryVariables
  >(DemoIntraAccountCashTransferDocument, options);
}
export type DemoIntraAccountCashTransferQueryHookResult = ReturnType<
  typeof useDemoIntraAccountCashTransferQuery
>;
export type DemoIntraAccountCashTransferLazyQueryHookResult = ReturnType<
  typeof useDemoIntraAccountCashTransferLazyQuery
>;
export type DemoIntraAccountCashTransferQueryResult = Apollo.QueryResult<
  DemoIntraAccountCashTransferQuery,
  DemoIntraAccountCashTransferQueryVariables
>;
export const IntraAccountStockTransferDocument = gql`
  query intraAccountStockTransfer($clearingAccountId: ID!, $id: ID!) {
    clearingAccount(input: { id: $clearingAccountId, type: ID }) {
      id
      intraAccountStockTransfer(id: $id) {
        ...IntraAccountStockTransfer
      }
    }
  }
  ${IntraAccountStockTransferFragmentDoc}
`;

/**
 * __useIntraAccountStockTransferQuery__
 *
 * To run a query within a React component, call `useIntraAccountStockTransferQuery` and pass it any options that fit your needs.
 * When your component renders, `useIntraAccountStockTransferQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIntraAccountStockTransferQuery({
 *   variables: {
 *      clearingAccountId: // value for 'clearingAccountId'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useIntraAccountStockTransferQuery(
  baseOptions: Apollo.QueryHookOptions<
    IntraAccountStockTransferQuery,
    IntraAccountStockTransferQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    IntraAccountStockTransferQuery,
    IntraAccountStockTransferQueryVariables
  >(IntraAccountStockTransferDocument, options);
}
export function useIntraAccountStockTransferLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    IntraAccountStockTransferQuery,
    IntraAccountStockTransferQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    IntraAccountStockTransferQuery,
    IntraAccountStockTransferQueryVariables
  >(IntraAccountStockTransferDocument, options);
}
export type IntraAccountStockTransferQueryHookResult = ReturnType<
  typeof useIntraAccountStockTransferQuery
>;
export type IntraAccountStockTransferLazyQueryHookResult = ReturnType<
  typeof useIntraAccountStockTransferLazyQuery
>;
export type IntraAccountStockTransferQueryResult = Apollo.QueryResult<
  IntraAccountStockTransferQuery,
  IntraAccountStockTransferQueryVariables
>;
export const DemoIntraAccountStockTransferDocument = gql`
  query demoIntraAccountStockTransfer($clearingAccountId: ID!, $id: ID!) {
    clearingAccount: demoClearingAccount(
      input: { id: $clearingAccountId, type: ID }
    ) {
      id
      intraAccountStockTransfer: demoIntraAccountStockTransfer(id: $id) {
        ...IntraAccountStockTransfer
      }
    }
  }
  ${IntraAccountStockTransferFragmentDoc}
`;

/**
 * __useDemoIntraAccountStockTransferQuery__
 *
 * To run a query within a React component, call `useDemoIntraAccountStockTransferQuery` and pass it any options that fit your needs.
 * When your component renders, `useDemoIntraAccountStockTransferQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDemoIntraAccountStockTransferQuery({
 *   variables: {
 *      clearingAccountId: // value for 'clearingAccountId'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDemoIntraAccountStockTransferQuery(
  baseOptions: Apollo.QueryHookOptions<
    DemoIntraAccountStockTransferQuery,
    DemoIntraAccountStockTransferQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    DemoIntraAccountStockTransferQuery,
    DemoIntraAccountStockTransferQueryVariables
  >(DemoIntraAccountStockTransferDocument, options);
}
export function useDemoIntraAccountStockTransferLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    DemoIntraAccountStockTransferQuery,
    DemoIntraAccountStockTransferQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    DemoIntraAccountStockTransferQuery,
    DemoIntraAccountStockTransferQueryVariables
  >(DemoIntraAccountStockTransferDocument, options);
}
export type DemoIntraAccountStockTransferQueryHookResult = ReturnType<
  typeof useDemoIntraAccountStockTransferQuery
>;
export type DemoIntraAccountStockTransferLazyQueryHookResult = ReturnType<
  typeof useDemoIntraAccountStockTransferLazyQuery
>;
export type DemoIntraAccountStockTransferQueryResult = Apollo.QueryResult<
  DemoIntraAccountStockTransferQuery,
  DemoIntraAccountStockTransferQueryVariables
>;
export const IntraDayAccountValuesDocument = gql`
  query IntraDayAccountValues($input: ClearingAccountInput!) {
    intraDayAccountValues(input: $input) {
      amountAvailableToWithdraw
    }
  }
`;

/**
 * __useIntraDayAccountValuesQuery__
 *
 * To run a query within a React component, call `useIntraDayAccountValuesQuery` and pass it any options that fit your needs.
 * When your component renders, `useIntraDayAccountValuesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIntraDayAccountValuesQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useIntraDayAccountValuesQuery(
  baseOptions: Apollo.QueryHookOptions<
    IntraDayAccountValuesQuery,
    IntraDayAccountValuesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    IntraDayAccountValuesQuery,
    IntraDayAccountValuesQueryVariables
  >(IntraDayAccountValuesDocument, options);
}
export function useIntraDayAccountValuesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    IntraDayAccountValuesQuery,
    IntraDayAccountValuesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    IntraDayAccountValuesQuery,
    IntraDayAccountValuesQueryVariables
  >(IntraDayAccountValuesDocument, options);
}
export type IntraDayAccountValuesQueryHookResult = ReturnType<
  typeof useIntraDayAccountValuesQuery
>;
export type IntraDayAccountValuesLazyQueryHookResult = ReturnType<
  typeof useIntraDayAccountValuesLazyQuery
>;
export type IntraDayAccountValuesQueryResult = Apollo.QueryResult<
  IntraDayAccountValuesQuery,
  IntraDayAccountValuesQueryVariables
>;
export const InvestIndexDetailsDocument = gql`
  query investIndexDetails($args: GetIndexDataInput) {
    getIndexMetadata(args: $args) {
      ...InvestIndexDetails
    }
  }
  ${InvestIndexDetailsFragmentDoc}
`;

/**
 * __useInvestIndexDetailsQuery__
 *
 * To run a query within a React component, call `useInvestIndexDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useInvestIndexDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInvestIndexDetailsQuery({
 *   variables: {
 *      args: // value for 'args'
 *   },
 * });
 */
export function useInvestIndexDetailsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    InvestIndexDetailsQuery,
    InvestIndexDetailsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    InvestIndexDetailsQuery,
    InvestIndexDetailsQueryVariables
  >(InvestIndexDetailsDocument, options);
}
export function useInvestIndexDetailsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    InvestIndexDetailsQuery,
    InvestIndexDetailsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    InvestIndexDetailsQuery,
    InvestIndexDetailsQueryVariables
  >(InvestIndexDetailsDocument, options);
}
export type InvestIndexDetailsQueryHookResult = ReturnType<
  typeof useInvestIndexDetailsQuery
>;
export type InvestIndexDetailsLazyQueryHookResult = ReturnType<
  typeof useInvestIndexDetailsLazyQuery
>;
export type InvestIndexDetailsQueryResult = Apollo.QueryResult<
  InvestIndexDetailsQuery,
  InvestIndexDetailsQueryVariables
>;
export const InvestmentSearchDocument = gql`
  query investmentSearch($query: String!) {
    investmentSearch(query: $query) {
      directIndices {
        indexName
        indexType
      }
      securities {
        symbol
        name
      }
    }
  }
`;

/**
 * __useInvestmentSearchQuery__
 *
 * To run a query within a React component, call `useInvestmentSearchQuery` and pass it any options that fit your needs.
 * When your component renders, `useInvestmentSearchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInvestmentSearchQuery({
 *   variables: {
 *      query: // value for 'query'
 *   },
 * });
 */
export function useInvestmentSearchQuery(
  baseOptions: Apollo.QueryHookOptions<
    InvestmentSearchQuery,
    InvestmentSearchQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<InvestmentSearchQuery, InvestmentSearchQueryVariables>(
    InvestmentSearchDocument,
    options
  );
}
export function useInvestmentSearchLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    InvestmentSearchQuery,
    InvestmentSearchQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    InvestmentSearchQuery,
    InvestmentSearchQueryVariables
  >(InvestmentSearchDocument, options);
}
export type InvestmentSearchQueryHookResult = ReturnType<
  typeof useInvestmentSearchQuery
>;
export type InvestmentSearchLazyQueryHookResult = ReturnType<
  typeof useInvestmentSearchLazyQuery
>;
export type InvestmentSearchQueryResult = Apollo.QueryResult<
  InvestmentSearchQuery,
  InvestmentSearchQueryVariables
>;
export const KeyValueGetDocument = gql`
  query keyValueGet($input: KeyValueGetInput!) {
    keyValueGet(input: $input) {
      ...KeyValuePair
    }
  }
  ${KeyValuePairFragmentDoc}
`;

/**
 * __useKeyValueGetQuery__
 *
 * To run a query within a React component, call `useKeyValueGetQuery` and pass it any options that fit your needs.
 * When your component renders, `useKeyValueGetQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useKeyValueGetQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useKeyValueGetQuery(
  baseOptions: Apollo.QueryHookOptions<
    KeyValueGetQuery,
    KeyValueGetQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<KeyValueGetQuery, KeyValueGetQueryVariables>(
    KeyValueGetDocument,
    options
  );
}
export function useKeyValueGetLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    KeyValueGetQuery,
    KeyValueGetQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<KeyValueGetQuery, KeyValueGetQueryVariables>(
    KeyValueGetDocument,
    options
  );
}
export type KeyValueGetQueryHookResult = ReturnType<typeof useKeyValueGetQuery>;
export type KeyValueGetLazyQueryHookResult = ReturnType<
  typeof useKeyValueGetLazyQuery
>;
export type KeyValueGetQueryResult = Apollo.QueryResult<
  KeyValueGetQuery,
  KeyValueGetQueryVariables
>;
export const LinkedBrokerageAccountsDocument = gql`
  query linkedBrokerageAccounts($ids: [ID!], $includePlaidPortfolio: Boolean!) {
    user {
      id
      linkedBrokerageAccounts(input: { ids: $ids }) {
        ...LinkedBrokerageAccount
      }
    }
  }
  ${LinkedBrokerageAccountFragmentDoc}
`;

/**
 * __useLinkedBrokerageAccountsQuery__
 *
 * To run a query within a React component, call `useLinkedBrokerageAccountsQuery` and pass it any options that fit your needs.
 * When your component renders, `useLinkedBrokerageAccountsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLinkedBrokerageAccountsQuery({
 *   variables: {
 *      ids: // value for 'ids'
 *      includePlaidPortfolio: // value for 'includePlaidPortfolio'
 *   },
 * });
 */
export function useLinkedBrokerageAccountsQuery(
  baseOptions: Apollo.QueryHookOptions<
    LinkedBrokerageAccountsQuery,
    LinkedBrokerageAccountsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    LinkedBrokerageAccountsQuery,
    LinkedBrokerageAccountsQueryVariables
  >(LinkedBrokerageAccountsDocument, options);
}
export function useLinkedBrokerageAccountsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    LinkedBrokerageAccountsQuery,
    LinkedBrokerageAccountsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    LinkedBrokerageAccountsQuery,
    LinkedBrokerageAccountsQueryVariables
  >(LinkedBrokerageAccountsDocument, options);
}
export type LinkedBrokerageAccountsQueryHookResult = ReturnType<
  typeof useLinkedBrokerageAccountsQuery
>;
export type LinkedBrokerageAccountsLazyQueryHookResult = ReturnType<
  typeof useLinkedBrokerageAccountsLazyQuery
>;
export type LinkedBrokerageAccountsQueryResult = Apollo.QueryResult<
  LinkedBrokerageAccountsQuery,
  LinkedBrokerageAccountsQueryVariables
>;
export const LiquidateDirectIndexPortfolioRequestDocument = gql`
  query LiquidateDirectIndexPortfolioRequest(
    $clearingAccountId: ID!
    $id: ID!
  ) {
    getLiquidateDirectIndexPortfolioRequest(
      clearingAccountId: $clearingAccountId
      id: $id
    ) {
      ...LiquidateDirectIndexPortfolioRequest
    }
  }
  ${LiquidateDirectIndexPortfolioRequestFragmentDoc}
`;

/**
 * __useLiquidateDirectIndexPortfolioRequestQuery__
 *
 * To run a query within a React component, call `useLiquidateDirectIndexPortfolioRequestQuery` and pass it any options that fit your needs.
 * When your component renders, `useLiquidateDirectIndexPortfolioRequestQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLiquidateDirectIndexPortfolioRequestQuery({
 *   variables: {
 *      clearingAccountId: // value for 'clearingAccountId'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useLiquidateDirectIndexPortfolioRequestQuery(
  baseOptions: Apollo.QueryHookOptions<
    LiquidateDirectIndexPortfolioRequestQuery,
    LiquidateDirectIndexPortfolioRequestQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    LiquidateDirectIndexPortfolioRequestQuery,
    LiquidateDirectIndexPortfolioRequestQueryVariables
  >(LiquidateDirectIndexPortfolioRequestDocument, options);
}
export function useLiquidateDirectIndexPortfolioRequestLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    LiquidateDirectIndexPortfolioRequestQuery,
    LiquidateDirectIndexPortfolioRequestQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    LiquidateDirectIndexPortfolioRequestQuery,
    LiquidateDirectIndexPortfolioRequestQueryVariables
  >(LiquidateDirectIndexPortfolioRequestDocument, options);
}
export type LiquidateDirectIndexPortfolioRequestQueryHookResult = ReturnType<
  typeof useLiquidateDirectIndexPortfolioRequestQuery
>;
export type LiquidateDirectIndexPortfolioRequestLazyQueryHookResult =
  ReturnType<typeof useLiquidateDirectIndexPortfolioRequestLazyQuery>;
export type LiquidateDirectIndexPortfolioRequestQueryResult =
  Apollo.QueryResult<
    LiquidateDirectIndexPortfolioRequestQuery,
    LiquidateDirectIndexPortfolioRequestQueryVariables
  >;
export const DemoLiquidateDirectIndexPortfolioRequestDocument = gql`
  query demoLiquidateDirectIndexPortfolioRequest(
    $clearingAccountId: ID!
    $id: ID!
  ) {
    getLiquidateDirectIndexPortfolioRequest: demoGetLiquidateDirectIndexPortfolioRequest(
      clearingAccountId: $clearingAccountId
      id: $id
    ) {
      ...LiquidateDirectIndexPortfolioRequest
    }
  }
  ${LiquidateDirectIndexPortfolioRequestFragmentDoc}
`;

/**
 * __useDemoLiquidateDirectIndexPortfolioRequestQuery__
 *
 * To run a query within a React component, call `useDemoLiquidateDirectIndexPortfolioRequestQuery` and pass it any options that fit your needs.
 * When your component renders, `useDemoLiquidateDirectIndexPortfolioRequestQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDemoLiquidateDirectIndexPortfolioRequestQuery({
 *   variables: {
 *      clearingAccountId: // value for 'clearingAccountId'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDemoLiquidateDirectIndexPortfolioRequestQuery(
  baseOptions: Apollo.QueryHookOptions<
    DemoLiquidateDirectIndexPortfolioRequestQuery,
    DemoLiquidateDirectIndexPortfolioRequestQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    DemoLiquidateDirectIndexPortfolioRequestQuery,
    DemoLiquidateDirectIndexPortfolioRequestQueryVariables
  >(DemoLiquidateDirectIndexPortfolioRequestDocument, options);
}
export function useDemoLiquidateDirectIndexPortfolioRequestLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    DemoLiquidateDirectIndexPortfolioRequestQuery,
    DemoLiquidateDirectIndexPortfolioRequestQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    DemoLiquidateDirectIndexPortfolioRequestQuery,
    DemoLiquidateDirectIndexPortfolioRequestQueryVariables
  >(DemoLiquidateDirectIndexPortfolioRequestDocument, options);
}
export type DemoLiquidateDirectIndexPortfolioRequestQueryHookResult =
  ReturnType<typeof useDemoLiquidateDirectIndexPortfolioRequestQuery>;
export type DemoLiquidateDirectIndexPortfolioRequestLazyQueryHookResult =
  ReturnType<typeof useDemoLiquidateDirectIndexPortfolioRequestLazyQuery>;
export type DemoLiquidateDirectIndexPortfolioRequestQueryResult =
  Apollo.QueryResult<
    DemoLiquidateDirectIndexPortfolioRequestQuery,
    DemoLiquidateDirectIndexPortfolioRequestQueryVariables
  >;
export const LoanDocument = gql`
  query loan($clearingAccountId: ID!, $loanId: ID!) {
    getAccountLoan(clearingAccountId: $clearingAccountId, loanId: $loanId) {
      ...LoanEventData
    }
  }
  ${LoanEventDataFragmentDoc}
`;

/**
 * __useLoanQuery__
 *
 * To run a query within a React component, call `useLoanQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoanQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoanQuery({
 *   variables: {
 *      clearingAccountId: // value for 'clearingAccountId'
 *      loanId: // value for 'loanId'
 *   },
 * });
 */
export function useLoanQuery(
  baseOptions: Apollo.QueryHookOptions<LoanQuery, LoanQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<LoanQuery, LoanQueryVariables>(LoanDocument, options);
}
export function useLoanLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<LoanQuery, LoanQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<LoanQuery, LoanQueryVariables>(
    LoanDocument,
    options
  );
}
export type LoanQueryHookResult = ReturnType<typeof useLoanQuery>;
export type LoanLazyQueryHookResult = ReturnType<typeof useLoanLazyQuery>;
export type LoanQueryResult = Apollo.QueryResult<LoanQuery, LoanQueryVariables>;
export const MarginInterestChargeDocument = gql`
  query marginInterestCharge($clearingAccountId: ID!, $id: ID!) {
    clearingAccount(input: { id: $clearingAccountId, type: ID }) {
      id
      marginInterestCharge(id: $id) {
        ...MarginInterestCharge
      }
    }
  }
  ${MarginInterestChargeFragmentDoc}
`;

/**
 * __useMarginInterestChargeQuery__
 *
 * To run a query within a React component, call `useMarginInterestChargeQuery` and pass it any options that fit your needs.
 * When your component renders, `useMarginInterestChargeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMarginInterestChargeQuery({
 *   variables: {
 *      clearingAccountId: // value for 'clearingAccountId'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useMarginInterestChargeQuery(
  baseOptions: Apollo.QueryHookOptions<
    MarginInterestChargeQuery,
    MarginInterestChargeQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    MarginInterestChargeQuery,
    MarginInterestChargeQueryVariables
  >(MarginInterestChargeDocument, options);
}
export function useMarginInterestChargeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    MarginInterestChargeQuery,
    MarginInterestChargeQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    MarginInterestChargeQuery,
    MarginInterestChargeQueryVariables
  >(MarginInterestChargeDocument, options);
}
export type MarginInterestChargeQueryHookResult = ReturnType<
  typeof useMarginInterestChargeQuery
>;
export type MarginInterestChargeLazyQueryHookResult = ReturnType<
  typeof useMarginInterestChargeLazyQuery
>;
export type MarginInterestChargeQueryResult = Apollo.QueryResult<
  MarginInterestChargeQuery,
  MarginInterestChargeQueryVariables
>;
export const MarketHoursDocument = gql`
  query marketHours {
    marketHours {
      isTheStockMarketOpen
      previousMarketOpen
      previousMarketClose
      openingHour
      closingHour
    }
  }
`;

/**
 * __useMarketHoursQuery__
 *
 * To run a query within a React component, call `useMarketHoursQuery` and pass it any options that fit your needs.
 * When your component renders, `useMarketHoursQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMarketHoursQuery({
 *   variables: {
 *   },
 * });
 */
export function useMarketHoursQuery(
  baseOptions?: Apollo.QueryHookOptions<
    MarketHoursQuery,
    MarketHoursQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<MarketHoursQuery, MarketHoursQueryVariables>(
    MarketHoursDocument,
    options
  );
}
export function useMarketHoursLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    MarketHoursQuery,
    MarketHoursQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<MarketHoursQuery, MarketHoursQueryVariables>(
    MarketHoursDocument,
    options
  );
}
export type MarketHoursQueryHookResult = ReturnType<typeof useMarketHoursQuery>;
export type MarketHoursLazyQueryHookResult = ReturnType<
  typeof useMarketHoursLazyQuery
>;
export type MarketHoursQueryResult = Apollo.QueryResult<
  MarketHoursQuery,
  MarketHoursQueryVariables
>;
export const MfaDocument = gql`
  query mfa {
    mfa {
      id
      phoneLast4
      userMfaStatus {
        isActive
        lastMfaChallengeTime
      }
      userMfaPreferences {
        id
        type
      }
    }
  }
`;

/**
 * __useMfaQuery__
 *
 * To run a query within a React component, call `useMfaQuery` and pass it any options that fit your needs.
 * When your component renders, `useMfaQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMfaQuery({
 *   variables: {
 *   },
 * });
 */
export function useMfaQuery(
  baseOptions?: Apollo.QueryHookOptions<MfaQuery, MfaQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<MfaQuery, MfaQueryVariables>(MfaDocument, options);
}
export function useMfaLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<MfaQuery, MfaQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<MfaQuery, MfaQueryVariables>(MfaDocument, options);
}
export type MfaQueryHookResult = ReturnType<typeof useMfaQuery>;
export type MfaLazyQueryHookResult = ReturnType<typeof useMfaLazyQuery>;
export type MfaQueryResult = Apollo.QueryResult<MfaQuery, MfaQueryVariables>;
export const OrderDocument = gql`
  query order($clearingAccountId: ID!, $id: ID!) {
    clearingAccount(input: { id: $clearingAccountId, type: ID }) {
      id
      order(id: $id) {
        ...Order
      }
    }
  }
  ${OrderFragmentDoc}
`;

/**
 * __useOrderQuery__
 *
 * To run a query within a React component, call `useOrderQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrderQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrderQuery({
 *   variables: {
 *      clearingAccountId: // value for 'clearingAccountId'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useOrderQuery(
  baseOptions: Apollo.QueryHookOptions<OrderQuery, OrderQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<OrderQuery, OrderQueryVariables>(
    OrderDocument,
    options
  );
}
export function useOrderLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<OrderQuery, OrderQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<OrderQuery, OrderQueryVariables>(
    OrderDocument,
    options
  );
}
export type OrderQueryHookResult = ReturnType<typeof useOrderQuery>;
export type OrderLazyQueryHookResult = ReturnType<typeof useOrderLazyQuery>;
export type OrderQueryResult = Apollo.QueryResult<
  OrderQuery,
  OrderQueryVariables
>;
export const SubAccountPortfolioAggregatesDocument = gql`
  query SubAccountPortfolioAggregates(
    $input: SubAccountPortfolioAggregatesInput!
  ) {
    subAccountPortfolioAggregates(input: $input) {
      ...SubAccountPortfolioAggregate
    }
  }
  ${SubAccountPortfolioAggregateFragmentDoc}
`;

/**
 * __useSubAccountPortfolioAggregatesQuery__
 *
 * To run a query within a React component, call `useSubAccountPortfolioAggregatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useSubAccountPortfolioAggregatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSubAccountPortfolioAggregatesQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSubAccountPortfolioAggregatesQuery(
  baseOptions: Apollo.QueryHookOptions<
    SubAccountPortfolioAggregatesQuery,
    SubAccountPortfolioAggregatesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    SubAccountPortfolioAggregatesQuery,
    SubAccountPortfolioAggregatesQueryVariables
  >(SubAccountPortfolioAggregatesDocument, options);
}
export function useSubAccountPortfolioAggregatesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SubAccountPortfolioAggregatesQuery,
    SubAccountPortfolioAggregatesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    SubAccountPortfolioAggregatesQuery,
    SubAccountPortfolioAggregatesQueryVariables
  >(SubAccountPortfolioAggregatesDocument, options);
}
export type SubAccountPortfolioAggregatesQueryHookResult = ReturnType<
  typeof useSubAccountPortfolioAggregatesQuery
>;
export type SubAccountPortfolioAggregatesLazyQueryHookResult = ReturnType<
  typeof useSubAccountPortfolioAggregatesLazyQuery
>;
export type SubAccountPortfolioAggregatesQueryResult = Apollo.QueryResult<
  SubAccountPortfolioAggregatesQuery,
  SubAccountPortfolioAggregatesQueryVariables
>;
export const DemoSubAccountPortfolioAggregatesDocument = gql`
  query demoSubAccountPortfolioAggregates(
    $input: SubAccountPortfolioAggregatesInput!
  ) {
    subAccountPortfolioAggregates: demoSubAccountPortfolioAggregates(
      input: $input
    ) {
      ...SubAccountPortfolioAggregate
    }
  }
  ${SubAccountPortfolioAggregateFragmentDoc}
`;

/**
 * __useDemoSubAccountPortfolioAggregatesQuery__
 *
 * To run a query within a React component, call `useDemoSubAccountPortfolioAggregatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useDemoSubAccountPortfolioAggregatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDemoSubAccountPortfolioAggregatesQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDemoSubAccountPortfolioAggregatesQuery(
  baseOptions: Apollo.QueryHookOptions<
    DemoSubAccountPortfolioAggregatesQuery,
    DemoSubAccountPortfolioAggregatesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    DemoSubAccountPortfolioAggregatesQuery,
    DemoSubAccountPortfolioAggregatesQueryVariables
  >(DemoSubAccountPortfolioAggregatesDocument, options);
}
export function useDemoSubAccountPortfolioAggregatesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    DemoSubAccountPortfolioAggregatesQuery,
    DemoSubAccountPortfolioAggregatesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    DemoSubAccountPortfolioAggregatesQuery,
    DemoSubAccountPortfolioAggregatesQueryVariables
  >(DemoSubAccountPortfolioAggregatesDocument, options);
}
export type DemoSubAccountPortfolioAggregatesQueryHookResult = ReturnType<
  typeof useDemoSubAccountPortfolioAggregatesQuery
>;
export type DemoSubAccountPortfolioAggregatesLazyQueryHookResult = ReturnType<
  typeof useDemoSubAccountPortfolioAggregatesLazyQuery
>;
export type DemoSubAccountPortfolioAggregatesQueryResult = Apollo.QueryResult<
  DemoSubAccountPortfolioAggregatesQuery,
  DemoSubAccountPortfolioAggregatesQueryVariables
>;
export const PaymentExecutionDocument = gql`
  query paymentExecution($clearingAccountId: ID!, $id: ID!) {
    clearingAccount(input: { id: $clearingAccountId, type: ID }) {
      id
      paymentExecution(id: $id) {
        ...PaymentExecution
      }
    }
  }
  ${PaymentExecutionFragmentDoc}
`;

/**
 * __usePaymentExecutionQuery__
 *
 * To run a query within a React component, call `usePaymentExecutionQuery` and pass it any options that fit your needs.
 * When your component renders, `usePaymentExecutionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePaymentExecutionQuery({
 *   variables: {
 *      clearingAccountId: // value for 'clearingAccountId'
 *      id: // value for 'id'
 *   },
 * });
 */
export function usePaymentExecutionQuery(
  baseOptions: Apollo.QueryHookOptions<
    PaymentExecutionQuery,
    PaymentExecutionQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<PaymentExecutionQuery, PaymentExecutionQueryVariables>(
    PaymentExecutionDocument,
    options
  );
}
export function usePaymentExecutionLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    PaymentExecutionQuery,
    PaymentExecutionQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    PaymentExecutionQuery,
    PaymentExecutionQueryVariables
  >(PaymentExecutionDocument, options);
}
export type PaymentExecutionQueryHookResult = ReturnType<
  typeof usePaymentExecutionQuery
>;
export type PaymentExecutionLazyQueryHookResult = ReturnType<
  typeof usePaymentExecutionLazyQuery
>;
export type PaymentExecutionQueryResult = Apollo.QueryResult<
  PaymentExecutionQuery,
  PaymentExecutionQueryVariables
>;
export const PlaidAccountsDocument = gql`
  query plaidAccounts($input: PlaidAccountsInput) {
    user {
      id
      plaidAccounts(input: $input) {
        ...PlaidAccount
      }
    }
  }
  ${PlaidAccountFragmentDoc}
`;

/**
 * __usePlaidAccountsQuery__
 *
 * To run a query within a React component, call `usePlaidAccountsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePlaidAccountsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePlaidAccountsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePlaidAccountsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    PlaidAccountsQuery,
    PlaidAccountsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<PlaidAccountsQuery, PlaidAccountsQueryVariables>(
    PlaidAccountsDocument,
    options
  );
}
export function usePlaidAccountsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    PlaidAccountsQuery,
    PlaidAccountsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<PlaidAccountsQuery, PlaidAccountsQueryVariables>(
    PlaidAccountsDocument,
    options
  );
}
export type PlaidAccountsQueryHookResult = ReturnType<
  typeof usePlaidAccountsQuery
>;
export type PlaidAccountsLazyQueryHookResult = ReturnType<
  typeof usePlaidAccountsLazyQuery
>;
export type PlaidAccountsQueryResult = Apollo.QueryResult<
  PlaidAccountsQuery,
  PlaidAccountsQueryVariables
>;
export const PlaidDepositAccountBalanceDocument = gql`
  query plaidDepositAccountBalance($plaidAccountId: ID!) {
    plaidDepositAccountBalance(plaidAccountId: $plaidAccountId)
  }
`;

/**
 * __usePlaidDepositAccountBalanceQuery__
 *
 * To run a query within a React component, call `usePlaidDepositAccountBalanceQuery` and pass it any options that fit your needs.
 * When your component renders, `usePlaidDepositAccountBalanceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePlaidDepositAccountBalanceQuery({
 *   variables: {
 *      plaidAccountId: // value for 'plaidAccountId'
 *   },
 * });
 */
export function usePlaidDepositAccountBalanceQuery(
  baseOptions: Apollo.QueryHookOptions<
    PlaidDepositAccountBalanceQuery,
    PlaidDepositAccountBalanceQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    PlaidDepositAccountBalanceQuery,
    PlaidDepositAccountBalanceQueryVariables
  >(PlaidDepositAccountBalanceDocument, options);
}
export function usePlaidDepositAccountBalanceLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    PlaidDepositAccountBalanceQuery,
    PlaidDepositAccountBalanceQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    PlaidDepositAccountBalanceQuery,
    PlaidDepositAccountBalanceQueryVariables
  >(PlaidDepositAccountBalanceDocument, options);
}
export type PlaidDepositAccountBalanceQueryHookResult = ReturnType<
  typeof usePlaidDepositAccountBalanceQuery
>;
export type PlaidDepositAccountBalanceLazyQueryHookResult = ReturnType<
  typeof usePlaidDepositAccountBalanceLazyQuery
>;
export type PlaidDepositAccountBalanceQueryResult = Apollo.QueryResult<
  PlaidDepositAccountBalanceQuery,
  PlaidDepositAccountBalanceQueryVariables
>;
export const PortfolioAvailableAssetsDocument = gql`
  query PortfolioAvailableAssets($input: PortfolioInput!) {
    portfolio(input: $input) {
      ...PortfolioData
    }
  }
  ${PortfolioDataFragmentDoc}
`;

/**
 * __usePortfolioAvailableAssetsQuery__
 *
 * To run a query within a React component, call `usePortfolioAvailableAssetsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePortfolioAvailableAssetsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePortfolioAvailableAssetsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePortfolioAvailableAssetsQuery(
  baseOptions: Apollo.QueryHookOptions<
    PortfolioAvailableAssetsQuery,
    PortfolioAvailableAssetsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    PortfolioAvailableAssetsQuery,
    PortfolioAvailableAssetsQueryVariables
  >(PortfolioAvailableAssetsDocument, options);
}
export function usePortfolioAvailableAssetsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    PortfolioAvailableAssetsQuery,
    PortfolioAvailableAssetsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    PortfolioAvailableAssetsQuery,
    PortfolioAvailableAssetsQueryVariables
  >(PortfolioAvailableAssetsDocument, options);
}
export type PortfolioAvailableAssetsQueryHookResult = ReturnType<
  typeof usePortfolioAvailableAssetsQuery
>;
export type PortfolioAvailableAssetsLazyQueryHookResult = ReturnType<
  typeof usePortfolioAvailableAssetsLazyQuery
>;
export type PortfolioAvailableAssetsQueryResult = Apollo.QueryResult<
  PortfolioAvailableAssetsQuery,
  PortfolioAvailableAssetsQueryVariables
>;
export const PortfolioLastSettledDocument = gql`
  query PortfolioLastSettled($input: PortfolioInput!) {
    portfolio(input: $input) {
      ...PortfolioData
    }
  }
  ${PortfolioDataFragmentDoc}
`;

/**
 * __usePortfolioLastSettledQuery__
 *
 * To run a query within a React component, call `usePortfolioLastSettledQuery` and pass it any options that fit your needs.
 * When your component renders, `usePortfolioLastSettledQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePortfolioLastSettledQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePortfolioLastSettledQuery(
  baseOptions: Apollo.QueryHookOptions<
    PortfolioLastSettledQuery,
    PortfolioLastSettledQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    PortfolioLastSettledQuery,
    PortfolioLastSettledQueryVariables
  >(PortfolioLastSettledDocument, options);
}
export function usePortfolioLastSettledLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    PortfolioLastSettledQuery,
    PortfolioLastSettledQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    PortfolioLastSettledQuery,
    PortfolioLastSettledQueryVariables
  >(PortfolioLastSettledDocument, options);
}
export type PortfolioLastSettledQueryHookResult = ReturnType<
  typeof usePortfolioLastSettledQuery
>;
export type PortfolioLastSettledLazyQueryHookResult = ReturnType<
  typeof usePortfolioLastSettledLazyQuery
>;
export type PortfolioLastSettledQueryResult = Apollo.QueryResult<
  PortfolioLastSettledQuery,
  PortfolioLastSettledQueryVariables
>;
export const PortfolioAsOfDateDocument = gql`
  query PortfolioAsOfDate($input: PortfolioInput!) {
    portfolio(input: $input) {
      ...PortfolioData
    }
  }
  ${PortfolioDataFragmentDoc}
`;

/**
 * __usePortfolioAsOfDateQuery__
 *
 * To run a query within a React component, call `usePortfolioAsOfDateQuery` and pass it any options that fit your needs.
 * When your component renders, `usePortfolioAsOfDateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePortfolioAsOfDateQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePortfolioAsOfDateQuery(
  baseOptions: Apollo.QueryHookOptions<
    PortfolioAsOfDateQuery,
    PortfolioAsOfDateQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    PortfolioAsOfDateQuery,
    PortfolioAsOfDateQueryVariables
  >(PortfolioAsOfDateDocument, options);
}
export function usePortfolioAsOfDateLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    PortfolioAsOfDateQuery,
    PortfolioAsOfDateQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    PortfolioAsOfDateQuery,
    PortfolioAsOfDateQueryVariables
  >(PortfolioAsOfDateDocument, options);
}
export type PortfolioAsOfDateQueryHookResult = ReturnType<
  typeof usePortfolioAsOfDateQuery
>;
export type PortfolioAsOfDateLazyQueryHookResult = ReturnType<
  typeof usePortfolioAsOfDateLazyQuery
>;
export type PortfolioAsOfDateQueryResult = Apollo.QueryResult<
  PortfolioAsOfDateQuery,
  PortfolioAsOfDateQueryVariables
>;
export const DemoPortfolioDocument = gql`
  query demoPortfolio($input: PortfolioInput!) {
    portfolio: demoPortfolio(input: $input) {
      ...PortfolioData
    }
  }
  ${PortfolioDataFragmentDoc}
`;

/**
 * __useDemoPortfolioQuery__
 *
 * To run a query within a React component, call `useDemoPortfolioQuery` and pass it any options that fit your needs.
 * When your component renders, `useDemoPortfolioQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDemoPortfolioQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDemoPortfolioQuery(
  baseOptions: Apollo.QueryHookOptions<
    DemoPortfolioQuery,
    DemoPortfolioQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<DemoPortfolioQuery, DemoPortfolioQueryVariables>(
    DemoPortfolioDocument,
    options
  );
}
export function useDemoPortfolioLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    DemoPortfolioQuery,
    DemoPortfolioQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<DemoPortfolioQuery, DemoPortfolioQueryVariables>(
    DemoPortfolioDocument,
    options
  );
}
export type DemoPortfolioQueryHookResult = ReturnType<
  typeof useDemoPortfolioQuery
>;
export type DemoPortfolioLazyQueryHookResult = ReturnType<
  typeof useDemoPortfolioLazyQuery
>;
export type DemoPortfolioQueryResult = Apollo.QueryResult<
  DemoPortfolioQuery,
  DemoPortfolioQueryVariables
>;
export const PortfolioBorrowingPowerDocument = gql`
  query PortfolioBorrowingPower($input: PortfolioValuesInput!) {
    portfolioBorrowingPower(input: $input) {
      clearingAccountId
      asOfDate
      borrowingPower
      frecBorrowingPower
    }
  }
`;

/**
 * __usePortfolioBorrowingPowerQuery__
 *
 * To run a query within a React component, call `usePortfolioBorrowingPowerQuery` and pass it any options that fit your needs.
 * When your component renders, `usePortfolioBorrowingPowerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePortfolioBorrowingPowerQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePortfolioBorrowingPowerQuery(
  baseOptions: Apollo.QueryHookOptions<
    PortfolioBorrowingPowerQuery,
    PortfolioBorrowingPowerQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    PortfolioBorrowingPowerQuery,
    PortfolioBorrowingPowerQueryVariables
  >(PortfolioBorrowingPowerDocument, options);
}
export function usePortfolioBorrowingPowerLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    PortfolioBorrowingPowerQuery,
    PortfolioBorrowingPowerQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    PortfolioBorrowingPowerQuery,
    PortfolioBorrowingPowerQueryVariables
  >(PortfolioBorrowingPowerDocument, options);
}
export type PortfolioBorrowingPowerQueryHookResult = ReturnType<
  typeof usePortfolioBorrowingPowerQuery
>;
export type PortfolioBorrowingPowerLazyQueryHookResult = ReturnType<
  typeof usePortfolioBorrowingPowerLazyQuery
>;
export type PortfolioBorrowingPowerQueryResult = Apollo.QueryResult<
  PortfolioBorrowingPowerQuery,
  PortfolioBorrowingPowerQueryVariables
>;
export const PortfolioHistoryDocument = gql`
  query portfolioHistory(
    $clearingAccountId: ID!
    $startDate: Date!
    $endDate: Date
    $subAccountId: ID
  ) {
    portfolioHistory(
      clearingAccountId: $clearingAccountId
      startDate: $startDate
      endDate: $endDate
      subAccountID: $subAccountId
    ) {
      date
      cashBalance
      loan
      fees
      portfolioSnapshotHoldingClosingValue
    }
  }
`;

/**
 * __usePortfolioHistoryQuery__
 *
 * To run a query within a React component, call `usePortfolioHistoryQuery` and pass it any options that fit your needs.
 * When your component renders, `usePortfolioHistoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePortfolioHistoryQuery({
 *   variables: {
 *      clearingAccountId: // value for 'clearingAccountId'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *      subAccountId: // value for 'subAccountId'
 *   },
 * });
 */
export function usePortfolioHistoryQuery(
  baseOptions: Apollo.QueryHookOptions<
    PortfolioHistoryQuery,
    PortfolioHistoryQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<PortfolioHistoryQuery, PortfolioHistoryQueryVariables>(
    PortfolioHistoryDocument,
    options
  );
}
export function usePortfolioHistoryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    PortfolioHistoryQuery,
    PortfolioHistoryQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    PortfolioHistoryQuery,
    PortfolioHistoryQueryVariables
  >(PortfolioHistoryDocument, options);
}
export type PortfolioHistoryQueryHookResult = ReturnType<
  typeof usePortfolioHistoryQuery
>;
export type PortfolioHistoryLazyQueryHookResult = ReturnType<
  typeof usePortfolioHistoryLazyQuery
>;
export type PortfolioHistoryQueryResult = Apollo.QueryResult<
  PortfolioHistoryQuery,
  PortfolioHistoryQueryVariables
>;
export const PortfolioStartOfDayValuesDocument = gql`
  query PortfolioStartOfDayValues($input: PortfolioValuesInput!) {
    portfolioStartOfDayValues(input: $input) {
      clearingAccountId
      asOfDate
      marginExcessEquity
      availableToWithdraw
      SMABalance
      positionMarketValue
      totalEquity
      netLoan
      maintenanceRequirement
      marginRequirementWithConcentration
      impliedSMA
    }
  }
`;

/**
 * __usePortfolioStartOfDayValuesQuery__
 *
 * To run a query within a React component, call `usePortfolioStartOfDayValuesQuery` and pass it any options that fit your needs.
 * When your component renders, `usePortfolioStartOfDayValuesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePortfolioStartOfDayValuesQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePortfolioStartOfDayValuesQuery(
  baseOptions: Apollo.QueryHookOptions<
    PortfolioStartOfDayValuesQuery,
    PortfolioStartOfDayValuesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    PortfolioStartOfDayValuesQuery,
    PortfolioStartOfDayValuesQueryVariables
  >(PortfolioStartOfDayValuesDocument, options);
}
export function usePortfolioStartOfDayValuesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    PortfolioStartOfDayValuesQuery,
    PortfolioStartOfDayValuesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    PortfolioStartOfDayValuesQuery,
    PortfolioStartOfDayValuesQueryVariables
  >(PortfolioStartOfDayValuesDocument, options);
}
export type PortfolioStartOfDayValuesQueryHookResult = ReturnType<
  typeof usePortfolioStartOfDayValuesQuery
>;
export type PortfolioStartOfDayValuesLazyQueryHookResult = ReturnType<
  typeof usePortfolioStartOfDayValuesLazyQuery
>;
export type PortfolioStartOfDayValuesQueryResult = Apollo.QueryResult<
  PortfolioStartOfDayValuesQuery,
  PortfolioStartOfDayValuesQueryVariables
>;
export const RealizedLotsSummaryDocument = gql`
  query realizedLotsSummary(
    $clearingAccountId: ID!
    $subAccountId: ID
    $securityId: ID
    $asOfDate: DateTime
    $fromDate: Date
    $toDate: Date
  ) {
    clearingAccount(input: { id: $clearingAccountId, type: ID }) {
      id
      realizedLotsSummary(
        input: {
          subAccountId: $subAccountId
          securityId: $securityId
          asOfDate: $asOfDate
          fromDate: $fromDate
          toDate: $toDate
        }
      ) {
        shortTermGains
        longTermGains
        shortTermLosses
        longTermLosses
        shortNetProceeds
        longNetProceeds
        shortCostBasis
        longCostBasis
        shortWashSalesDisallowed
        longWashSalesDisallowed
      }
    }
  }
`;

/**
 * __useRealizedLotsSummaryQuery__
 *
 * To run a query within a React component, call `useRealizedLotsSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useRealizedLotsSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRealizedLotsSummaryQuery({
 *   variables: {
 *      clearingAccountId: // value for 'clearingAccountId'
 *      subAccountId: // value for 'subAccountId'
 *      securityId: // value for 'securityId'
 *      asOfDate: // value for 'asOfDate'
 *      fromDate: // value for 'fromDate'
 *      toDate: // value for 'toDate'
 *   },
 * });
 */
export function useRealizedLotsSummaryQuery(
  baseOptions: Apollo.QueryHookOptions<
    RealizedLotsSummaryQuery,
    RealizedLotsSummaryQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    RealizedLotsSummaryQuery,
    RealizedLotsSummaryQueryVariables
  >(RealizedLotsSummaryDocument, options);
}
export function useRealizedLotsSummaryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    RealizedLotsSummaryQuery,
    RealizedLotsSummaryQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    RealizedLotsSummaryQuery,
    RealizedLotsSummaryQueryVariables
  >(RealizedLotsSummaryDocument, options);
}
export type RealizedLotsSummaryQueryHookResult = ReturnType<
  typeof useRealizedLotsSummaryQuery
>;
export type RealizedLotsSummaryLazyQueryHookResult = ReturnType<
  typeof useRealizedLotsSummaryLazyQuery
>;
export type RealizedLotsSummaryQueryResult = Apollo.QueryResult<
  RealizedLotsSummaryQuery,
  RealizedLotsSummaryQueryVariables
>;
export const RecentPaymentsDocument = gql`
  query recentPayments($clearingAccountId: ID!) {
    clearingAccount(input: { id: $clearingAccountId, type: ID }) {
      id
      recentPayments {
        ...PaymentExecution
      }
    }
  }
  ${PaymentExecutionFragmentDoc}
`;

/**
 * __useRecentPaymentsQuery__
 *
 * To run a query within a React component, call `useRecentPaymentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useRecentPaymentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRecentPaymentsQuery({
 *   variables: {
 *      clearingAccountId: // value for 'clearingAccountId'
 *   },
 * });
 */
export function useRecentPaymentsQuery(
  baseOptions: Apollo.QueryHookOptions<
    RecentPaymentsQuery,
    RecentPaymentsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<RecentPaymentsQuery, RecentPaymentsQueryVariables>(
    RecentPaymentsDocument,
    options
  );
}
export function useRecentPaymentsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    RecentPaymentsQuery,
    RecentPaymentsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<RecentPaymentsQuery, RecentPaymentsQueryVariables>(
    RecentPaymentsDocument,
    options
  );
}
export type RecentPaymentsQueryHookResult = ReturnType<
  typeof useRecentPaymentsQuery
>;
export type RecentPaymentsLazyQueryHookResult = ReturnType<
  typeof useRecentPaymentsLazyQuery
>;
export type RecentPaymentsQueryResult = Apollo.QueryResult<
  RecentPaymentsQuery,
  RecentPaymentsQueryVariables
>;
export const RecentStockPricesDocument = gql`
  query recentStockPrices($input: BarInput!) {
    getBars(input: $input) {
      bars {
        close
        open
        endTime
      }
    }
  }
`;

/**
 * __useRecentStockPricesQuery__
 *
 * To run a query within a React component, call `useRecentStockPricesQuery` and pass it any options that fit your needs.
 * When your component renders, `useRecentStockPricesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRecentStockPricesQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRecentStockPricesQuery(
  baseOptions: Apollo.QueryHookOptions<
    RecentStockPricesQuery,
    RecentStockPricesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    RecentStockPricesQuery,
    RecentStockPricesQueryVariables
  >(RecentStockPricesDocument, options);
}
export function useRecentStockPricesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    RecentStockPricesQuery,
    RecentStockPricesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    RecentStockPricesQuery,
    RecentStockPricesQueryVariables
  >(RecentStockPricesDocument, options);
}
export type RecentStockPricesQueryHookResult = ReturnType<
  typeof useRecentStockPricesQuery
>;
export type RecentStockPricesLazyQueryHookResult = ReturnType<
  typeof useRecentStockPricesLazyQuery
>;
export type RecentStockPricesQueryResult = Apollo.QueryResult<
  RecentStockPricesQuery,
  RecentStockPricesQueryVariables
>;
export const ReferralCodeInfoDocument = gql`
  query referralCodeInfo($code: String!) {
    referralCodeInfo(inviteCode: $code) {
      dealType
      invitedBy
    }
  }
`;

/**
 * __useReferralCodeInfoQuery__
 *
 * To run a query within a React component, call `useReferralCodeInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useReferralCodeInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReferralCodeInfoQuery({
 *   variables: {
 *      code: // value for 'code'
 *   },
 * });
 */
export function useReferralCodeInfoQuery(
  baseOptions: Apollo.QueryHookOptions<
    ReferralCodeInfoQuery,
    ReferralCodeInfoQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ReferralCodeInfoQuery, ReferralCodeInfoQueryVariables>(
    ReferralCodeInfoDocument,
    options
  );
}
export function useReferralCodeInfoLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ReferralCodeInfoQuery,
    ReferralCodeInfoQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ReferralCodeInfoQuery,
    ReferralCodeInfoQueryVariables
  >(ReferralCodeInfoDocument, options);
}
export type ReferralCodeInfoQueryHookResult = ReturnType<
  typeof useReferralCodeInfoQuery
>;
export type ReferralCodeInfoLazyQueryHookResult = ReturnType<
  typeof useReferralCodeInfoLazyQuery
>;
export type ReferralCodeInfoQueryResult = Apollo.QueryResult<
  ReferralCodeInfoQuery,
  ReferralCodeInfoQueryVariables
>;
export const PendingReferralsDocument = gql`
  query pendingReferrals($input: PendingInvitesForUserInput!) {
    paginatedPendingInvitesForUser(input: $input) {
      entries {
        refereeEmail
        referredOn
      }
      nextPageToken
    }
  }
`;

/**
 * __usePendingReferralsQuery__
 *
 * To run a query within a React component, call `usePendingReferralsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePendingReferralsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePendingReferralsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePendingReferralsQuery(
  baseOptions: Apollo.QueryHookOptions<
    PendingReferralsQuery,
    PendingReferralsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<PendingReferralsQuery, PendingReferralsQueryVariables>(
    PendingReferralsDocument,
    options
  );
}
export function usePendingReferralsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    PendingReferralsQuery,
    PendingReferralsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    PendingReferralsQuery,
    PendingReferralsQueryVariables
  >(PendingReferralsDocument, options);
}
export type PendingReferralsQueryHookResult = ReturnType<
  typeof usePendingReferralsQuery
>;
export type PendingReferralsLazyQueryHookResult = ReturnType<
  typeof usePendingReferralsLazyQuery
>;
export type PendingReferralsQueryResult = Apollo.QueryResult<
  PendingReferralsQuery,
  PendingReferralsQueryVariables
>;
export const CompletedReferralsDocument = gql`
  query completedReferrals($input: CompletedInvitesForUserInput!) {
    paginatedCompletedInvitesForUser(input: $input) {
      entries {
        redactedRefereeFullName
        eventTime
        userReferralDeal
        rewards {
          ...ReferralRewardDetails
        }
      }
      nextPageToken
    }
  }
  ${ReferralRewardDetailsFragmentDoc}
`;

/**
 * __useCompletedReferralsQuery__
 *
 * To run a query within a React component, call `useCompletedReferralsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompletedReferralsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompletedReferralsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCompletedReferralsQuery(
  baseOptions: Apollo.QueryHookOptions<
    CompletedReferralsQuery,
    CompletedReferralsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    CompletedReferralsQuery,
    CompletedReferralsQueryVariables
  >(CompletedReferralsDocument, options);
}
export function useCompletedReferralsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CompletedReferralsQuery,
    CompletedReferralsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    CompletedReferralsQuery,
    CompletedReferralsQueryVariables
  >(CompletedReferralsDocument, options);
}
export type CompletedReferralsQueryHookResult = ReturnType<
  typeof useCompletedReferralsQuery
>;
export type CompletedReferralsLazyQueryHookResult = ReturnType<
  typeof useCompletedReferralsLazyQuery
>;
export type CompletedReferralsQueryResult = Apollo.QueryResult<
  CompletedReferralsQuery,
  CompletedReferralsQueryVariables
>;
export const RewardsDocument = gql`
  query rewards($clearingAccountId: ID!) {
    clearingAccount(input: { id: $clearingAccountId, type: ID }) {
      id
      scheduledRewards {
        ...ScheduledReward
      }
    }
  }
  ${ScheduledRewardFragmentDoc}
`;

/**
 * __useRewardsQuery__
 *
 * To run a query within a React component, call `useRewardsQuery` and pass it any options that fit your needs.
 * When your component renders, `useRewardsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRewardsQuery({
 *   variables: {
 *      clearingAccountId: // value for 'clearingAccountId'
 *   },
 * });
 */
export function useRewardsQuery(
  baseOptions: Apollo.QueryHookOptions<RewardsQuery, RewardsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<RewardsQuery, RewardsQueryVariables>(
    RewardsDocument,
    options
  );
}
export function useRewardsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<RewardsQuery, RewardsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<RewardsQuery, RewardsQueryVariables>(
    RewardsDocument,
    options
  );
}
export type RewardsQueryHookResult = ReturnType<typeof useRewardsQuery>;
export type RewardsLazyQueryHookResult = ReturnType<typeof useRewardsLazyQuery>;
export type RewardsQueryResult = Apollo.QueryResult<
  RewardsQuery,
  RewardsQueryVariables
>;
export const RunRuleEngineDocument = gql`
  query runRuleEngine($input: FactInput!, $ruleGroup: RuleGroup!) {
    runRuleEngine(input: $input, ruleGroup: $ruleGroup) {
      name
      description
      actionContext
      allowAction
    }
  }
`;

/**
 * __useRunRuleEngineQuery__
 *
 * To run a query within a React component, call `useRunRuleEngineQuery` and pass it any options that fit your needs.
 * When your component renders, `useRunRuleEngineQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRunRuleEngineQuery({
 *   variables: {
 *      input: // value for 'input'
 *      ruleGroup: // value for 'ruleGroup'
 *   },
 * });
 */
export function useRunRuleEngineQuery(
  baseOptions: Apollo.QueryHookOptions<
    RunRuleEngineQuery,
    RunRuleEngineQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<RunRuleEngineQuery, RunRuleEngineQueryVariables>(
    RunRuleEngineDocument,
    options
  );
}
export function useRunRuleEngineLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    RunRuleEngineQuery,
    RunRuleEngineQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<RunRuleEngineQuery, RunRuleEngineQueryVariables>(
    RunRuleEngineDocument,
    options
  );
}
export type RunRuleEngineQueryHookResult = ReturnType<
  typeof useRunRuleEngineQuery
>;
export type RunRuleEngineLazyQueryHookResult = ReturnType<
  typeof useRunRuleEngineLazyQuery
>;
export type RunRuleEngineQueryResult = Apollo.QueryResult<
  RunRuleEngineQuery,
  RunRuleEngineQueryVariables
>;
export const SecuritiesBySymbolDocument = gql`
  query securitiesBySymbol($symbols: [String!]!) {
    securitiesBySymbol(symbols: $symbols) {
      ...SecurityWithQuote
    }
  }
  ${SecurityWithQuoteFragmentDoc}
`;

/**
 * __useSecuritiesBySymbolQuery__
 *
 * To run a query within a React component, call `useSecuritiesBySymbolQuery` and pass it any options that fit your needs.
 * When your component renders, `useSecuritiesBySymbolQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSecuritiesBySymbolQuery({
 *   variables: {
 *      symbols: // value for 'symbols'
 *   },
 * });
 */
export function useSecuritiesBySymbolQuery(
  baseOptions: Apollo.QueryHookOptions<
    SecuritiesBySymbolQuery,
    SecuritiesBySymbolQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    SecuritiesBySymbolQuery,
    SecuritiesBySymbolQueryVariables
  >(SecuritiesBySymbolDocument, options);
}
export function useSecuritiesBySymbolLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SecuritiesBySymbolQuery,
    SecuritiesBySymbolQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    SecuritiesBySymbolQuery,
    SecuritiesBySymbolQueryVariables
  >(SecuritiesBySymbolDocument, options);
}
export type SecuritiesBySymbolQueryHookResult = ReturnType<
  typeof useSecuritiesBySymbolQuery
>;
export type SecuritiesBySymbolLazyQueryHookResult = ReturnType<
  typeof useSecuritiesBySymbolLazyQuery
>;
export type SecuritiesBySymbolQueryResult = Apollo.QueryResult<
  SecuritiesBySymbolQuery,
  SecuritiesBySymbolQueryVariables
>;
export const SecurityBySymbolDocument = gql`
  query securityBySymbol($symbol: String!) {
    securityBySymbol(symbol: $symbol) {
      ...SecurityWithQuote
    }
  }
  ${SecurityWithQuoteFragmentDoc}
`;

/**
 * __useSecurityBySymbolQuery__
 *
 * To run a query within a React component, call `useSecurityBySymbolQuery` and pass it any options that fit your needs.
 * When your component renders, `useSecurityBySymbolQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSecurityBySymbolQuery({
 *   variables: {
 *      symbol: // value for 'symbol'
 *   },
 * });
 */
export function useSecurityBySymbolQuery(
  baseOptions: Apollo.QueryHookOptions<
    SecurityBySymbolQuery,
    SecurityBySymbolQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SecurityBySymbolQuery, SecurityBySymbolQueryVariables>(
    SecurityBySymbolDocument,
    options
  );
}
export function useSecurityBySymbolLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SecurityBySymbolQuery,
    SecurityBySymbolQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    SecurityBySymbolQuery,
    SecurityBySymbolQueryVariables
  >(SecurityBySymbolDocument, options);
}
export type SecurityBySymbolQueryHookResult = ReturnType<
  typeof useSecurityBySymbolQuery
>;
export type SecurityBySymbolLazyQueryHookResult = ReturnType<
  typeof useSecurityBySymbolLazyQuery
>;
export type SecurityBySymbolQueryResult = Apollo.QueryResult<
  SecurityBySymbolQuery,
  SecurityBySymbolQueryVariables
>;
export const SelfManagedTaxLossConfigDocument = gql`
  query selfManagedTaxLossConfig($clearingAccountId: ID!) {
    clearingAccount(input: { id: $clearingAccountId, type: ID }) {
      id
      selfManagedTaxLossConfig {
        ...SelfManagedTaxLossConfig
      }
    }
  }
  ${SelfManagedTaxLossConfigFragmentDoc}
`;

/**
 * __useSelfManagedTaxLossConfigQuery__
 *
 * To run a query within a React component, call `useSelfManagedTaxLossConfigQuery` and pass it any options that fit your needs.
 * When your component renders, `useSelfManagedTaxLossConfigQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSelfManagedTaxLossConfigQuery({
 *   variables: {
 *      clearingAccountId: // value for 'clearingAccountId'
 *   },
 * });
 */
export function useSelfManagedTaxLossConfigQuery(
  baseOptions: Apollo.QueryHookOptions<
    SelfManagedTaxLossConfigQuery,
    SelfManagedTaxLossConfigQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    SelfManagedTaxLossConfigQuery,
    SelfManagedTaxLossConfigQueryVariables
  >(SelfManagedTaxLossConfigDocument, options);
}
export function useSelfManagedTaxLossConfigLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SelfManagedTaxLossConfigQuery,
    SelfManagedTaxLossConfigQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    SelfManagedTaxLossConfigQuery,
    SelfManagedTaxLossConfigQueryVariables
  >(SelfManagedTaxLossConfigDocument, options);
}
export type SelfManagedTaxLossConfigQueryHookResult = ReturnType<
  typeof useSelfManagedTaxLossConfigQuery
>;
export type SelfManagedTaxLossConfigLazyQueryHookResult = ReturnType<
  typeof useSelfManagedTaxLossConfigLazyQuery
>;
export type SelfManagedTaxLossConfigQueryResult = Apollo.QueryResult<
  SelfManagedTaxLossConfigQuery,
  SelfManagedTaxLossConfigQueryVariables
>;
export const DemoSelfManagedTaxLossConfigDocument = gql`
  query demoSelfManagedTaxLossConfig($clearingAccountId: ID!) {
    clearingAccount: demoClearingAccount(
      input: { id: $clearingAccountId, type: ID }
    ) {
      id
      selfManagedTaxLossConfig: demoSelfManagedTaxLossConfig {
        ...SelfManagedTaxLossConfig
      }
    }
  }
  ${SelfManagedTaxLossConfigFragmentDoc}
`;

/**
 * __useDemoSelfManagedTaxLossConfigQuery__
 *
 * To run a query within a React component, call `useDemoSelfManagedTaxLossConfigQuery` and pass it any options that fit your needs.
 * When your component renders, `useDemoSelfManagedTaxLossConfigQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDemoSelfManagedTaxLossConfigQuery({
 *   variables: {
 *      clearingAccountId: // value for 'clearingAccountId'
 *   },
 * });
 */
export function useDemoSelfManagedTaxLossConfigQuery(
  baseOptions: Apollo.QueryHookOptions<
    DemoSelfManagedTaxLossConfigQuery,
    DemoSelfManagedTaxLossConfigQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    DemoSelfManagedTaxLossConfigQuery,
    DemoSelfManagedTaxLossConfigQueryVariables
  >(DemoSelfManagedTaxLossConfigDocument, options);
}
export function useDemoSelfManagedTaxLossConfigLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    DemoSelfManagedTaxLossConfigQuery,
    DemoSelfManagedTaxLossConfigQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    DemoSelfManagedTaxLossConfigQuery,
    DemoSelfManagedTaxLossConfigQueryVariables
  >(DemoSelfManagedTaxLossConfigDocument, options);
}
export type DemoSelfManagedTaxLossConfigQueryHookResult = ReturnType<
  typeof useDemoSelfManagedTaxLossConfigQuery
>;
export type DemoSelfManagedTaxLossConfigLazyQueryHookResult = ReturnType<
  typeof useDemoSelfManagedTaxLossConfigLazyQuery
>;
export type DemoSelfManagedTaxLossConfigQueryResult = Apollo.QueryResult<
  DemoSelfManagedTaxLossConfigQuery,
  DemoSelfManagedTaxLossConfigQueryVariables
>;
export const SimpleActivityDocument = gql`
  query simpleActivity(
    $clearingAccountId: ID!
    $size: Int!
    $nextPageToken: String
    $status: ActivityStatus!
    $filter: [ActivityFilterType!]
    $subAccountId: ID
    $securityId: ID
  ) {
    clearingAccount(input: { id: $clearingAccountId, type: ID }) {
      id
      simpleActivity(
        input: {
          size: $size
          nextPageToken: $nextPageToken
          status: $status
          filter: $filter
          subAccountId: $subAccountId
          securityId: $securityId
        }
      ) {
        data {
          ...SimpleActivity
        }
        nextPageToken
      }
    }
  }
  ${SimpleActivityFragmentDoc}
`;

/**
 * __useSimpleActivityQuery__
 *
 * To run a query within a React component, call `useSimpleActivityQuery` and pass it any options that fit your needs.
 * When your component renders, `useSimpleActivityQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSimpleActivityQuery({
 *   variables: {
 *      clearingAccountId: // value for 'clearingAccountId'
 *      size: // value for 'size'
 *      nextPageToken: // value for 'nextPageToken'
 *      status: // value for 'status'
 *      filter: // value for 'filter'
 *      subAccountId: // value for 'subAccountId'
 *      securityId: // value for 'securityId'
 *   },
 * });
 */
export function useSimpleActivityQuery(
  baseOptions: Apollo.QueryHookOptions<
    SimpleActivityQuery,
    SimpleActivityQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SimpleActivityQuery, SimpleActivityQueryVariables>(
    SimpleActivityDocument,
    options
  );
}
export function useSimpleActivityLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SimpleActivityQuery,
    SimpleActivityQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<SimpleActivityQuery, SimpleActivityQueryVariables>(
    SimpleActivityDocument,
    options
  );
}
export type SimpleActivityQueryHookResult = ReturnType<
  typeof useSimpleActivityQuery
>;
export type SimpleActivityLazyQueryHookResult = ReturnType<
  typeof useSimpleActivityLazyQuery
>;
export type SimpleActivityQueryResult = Apollo.QueryResult<
  SimpleActivityQuery,
  SimpleActivityQueryVariables
>;
export const DemoSimpleActivityDocument = gql`
  query demoSimpleActivity(
    $clearingAccountId: ID!
    $size: Int!
    $nextPageToken: String
    $status: ActivityStatus!
    $filter: [ActivityFilterType!]
    $subAccountId: ID
    $securityId: ID
  ) {
    clearingAccount: demoClearingAccount(
      input: { id: $clearingAccountId, type: ID }
    ) {
      id
      simpleActivity: demoSimpleActivity(
        input: {
          size: $size
          nextPageToken: $nextPageToken
          status: $status
          filter: $filter
          subAccountId: $subAccountId
          securityId: $securityId
        }
      ) {
        data {
          ...SimpleActivity
        }
        nextPageToken
      }
    }
  }
  ${SimpleActivityFragmentDoc}
`;

/**
 * __useDemoSimpleActivityQuery__
 *
 * To run a query within a React component, call `useDemoSimpleActivityQuery` and pass it any options that fit your needs.
 * When your component renders, `useDemoSimpleActivityQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDemoSimpleActivityQuery({
 *   variables: {
 *      clearingAccountId: // value for 'clearingAccountId'
 *      size: // value for 'size'
 *      nextPageToken: // value for 'nextPageToken'
 *      status: // value for 'status'
 *      filter: // value for 'filter'
 *      subAccountId: // value for 'subAccountId'
 *      securityId: // value for 'securityId'
 *   },
 * });
 */
export function useDemoSimpleActivityQuery(
  baseOptions: Apollo.QueryHookOptions<
    DemoSimpleActivityQuery,
    DemoSimpleActivityQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    DemoSimpleActivityQuery,
    DemoSimpleActivityQueryVariables
  >(DemoSimpleActivityDocument, options);
}
export function useDemoSimpleActivityLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    DemoSimpleActivityQuery,
    DemoSimpleActivityQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    DemoSimpleActivityQuery,
    DemoSimpleActivityQueryVariables
  >(DemoSimpleActivityDocument, options);
}
export type DemoSimpleActivityQueryHookResult = ReturnType<
  typeof useDemoSimpleActivityQuery
>;
export type DemoSimpleActivityLazyQueryHookResult = ReturnType<
  typeof useDemoSimpleActivityLazyQuery
>;
export type DemoSimpleActivityQueryResult = Apollo.QueryResult<
  DemoSimpleActivityQuery,
  DemoSimpleActivityQueryVariables
>;
export const SimpleActivityByIdDocument = gql`
  query simpleActivityById(
    $clearingAccountId: ID!
    $id: ID!
    $type: ActivityType!
  ) {
    clearingAccount(input: { id: $clearingAccountId, type: ID }) {
      id
      simpleActivityById(id: $id, type: $type) {
        ...SimpleActivity
      }
    }
  }
  ${SimpleActivityFragmentDoc}
`;

/**
 * __useSimpleActivityByIdQuery__
 *
 * To run a query within a React component, call `useSimpleActivityByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useSimpleActivityByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSimpleActivityByIdQuery({
 *   variables: {
 *      clearingAccountId: // value for 'clearingAccountId'
 *      id: // value for 'id'
 *      type: // value for 'type'
 *   },
 * });
 */
export function useSimpleActivityByIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    SimpleActivityByIdQuery,
    SimpleActivityByIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    SimpleActivityByIdQuery,
    SimpleActivityByIdQueryVariables
  >(SimpleActivityByIdDocument, options);
}
export function useSimpleActivityByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SimpleActivityByIdQuery,
    SimpleActivityByIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    SimpleActivityByIdQuery,
    SimpleActivityByIdQueryVariables
  >(SimpleActivityByIdDocument, options);
}
export type SimpleActivityByIdQueryHookResult = ReturnType<
  typeof useSimpleActivityByIdQuery
>;
export type SimpleActivityByIdLazyQueryHookResult = ReturnType<
  typeof useSimpleActivityByIdLazyQuery
>;
export type SimpleActivityByIdQueryResult = Apollo.QueryResult<
  SimpleActivityByIdQuery,
  SimpleActivityByIdQueryVariables
>;
export const SparklineDocument = gql`
  query sparkline($input: SparklineInput!) {
    sparkline(input: $input) {
      prevClose
      prices
    }
  }
`;

/**
 * __useSparklineQuery__
 *
 * To run a query within a React component, call `useSparklineQuery` and pass it any options that fit your needs.
 * When your component renders, `useSparklineQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSparklineQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSparklineQuery(
  baseOptions: Apollo.QueryHookOptions<SparklineQuery, SparklineQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SparklineQuery, SparklineQueryVariables>(
    SparklineDocument,
    options
  );
}
export function useSparklineLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SparklineQuery,
    SparklineQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<SparklineQuery, SparklineQueryVariables>(
    SparklineDocument,
    options
  );
}
export type SparklineQueryHookResult = ReturnType<typeof useSparklineQuery>;
export type SparklineLazyQueryHookResult = ReturnType<
  typeof useSparklineLazyQuery
>;
export type SparklineQueryResult = Apollo.QueryResult<
  SparklineQuery,
  SparklineQueryVariables
>;
export const StockAdjustmentDocument = gql`
  query stockAdjustment($clearingAccountId: ID!, $id: ID!) {
    clearingAccount(input: { id: $clearingAccountId, type: ID }) {
      id
      stockAdjustment(id: $id) {
        ...StockAdjustmentDetails
      }
    }
  }
  ${StockAdjustmentDetailsFragmentDoc}
`;

/**
 * __useStockAdjustmentQuery__
 *
 * To run a query within a React component, call `useStockAdjustmentQuery` and pass it any options that fit your needs.
 * When your component renders, `useStockAdjustmentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStockAdjustmentQuery({
 *   variables: {
 *      clearingAccountId: // value for 'clearingAccountId'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useStockAdjustmentQuery(
  baseOptions: Apollo.QueryHookOptions<
    StockAdjustmentQuery,
    StockAdjustmentQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<StockAdjustmentQuery, StockAdjustmentQueryVariables>(
    StockAdjustmentDocument,
    options
  );
}
export function useStockAdjustmentLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    StockAdjustmentQuery,
    StockAdjustmentQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    StockAdjustmentQuery,
    StockAdjustmentQueryVariables
  >(StockAdjustmentDocument, options);
}
export type StockAdjustmentQueryHookResult = ReturnType<
  typeof useStockAdjustmentQuery
>;
export type StockAdjustmentLazyQueryHookResult = ReturnType<
  typeof useStockAdjustmentLazyQuery
>;
export type StockAdjustmentQueryResult = Apollo.QueryResult<
  StockAdjustmentQuery,
  StockAdjustmentQueryVariables
>;
export const SuggestCollateralDocument = gql`
  query suggestCollateral($input: SuggestCollateralInput!) {
    suggestCollateral(input: $input) {
      id
      holdings {
        ...Holding
      }
    }
  }
  ${HoldingFragmentDoc}
`;

/**
 * __useSuggestCollateralQuery__
 *
 * To run a query within a React component, call `useSuggestCollateralQuery` and pass it any options that fit your needs.
 * When your component renders, `useSuggestCollateralQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSuggestCollateralQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSuggestCollateralQuery(
  baseOptions: Apollo.QueryHookOptions<
    SuggestCollateralQuery,
    SuggestCollateralQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    SuggestCollateralQuery,
    SuggestCollateralQueryVariables
  >(SuggestCollateralDocument, options);
}
export function useSuggestCollateralLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SuggestCollateralQuery,
    SuggestCollateralQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    SuggestCollateralQuery,
    SuggestCollateralQueryVariables
  >(SuggestCollateralDocument, options);
}
export type SuggestCollateralQueryHookResult = ReturnType<
  typeof useSuggestCollateralQuery
>;
export type SuggestCollateralLazyQueryHookResult = ReturnType<
  typeof useSuggestCollateralLazyQuery
>;
export type SuggestCollateralQueryResult = Apollo.QueryResult<
  SuggestCollateralQuery,
  SuggestCollateralQueryVariables
>;
export const SymbolSearchDocument = gql`
  query symbolSearch(
    $symbol: String!
    $includeSecurity: Boolean! = false
    $options: SymbolSearchInput
  ) {
    symbolSearch(query: $symbol, options: $options) {
      symbol
      name
      exchangeShortName
      security @include(if: $includeSecurity) {
        ...SecurityWithQuote
      }
    }
  }
  ${SecurityWithQuoteFragmentDoc}
`;

/**
 * __useSymbolSearchQuery__
 *
 * To run a query within a React component, call `useSymbolSearchQuery` and pass it any options that fit your needs.
 * When your component renders, `useSymbolSearchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSymbolSearchQuery({
 *   variables: {
 *      symbol: // value for 'symbol'
 *      includeSecurity: // value for 'includeSecurity'
 *      options: // value for 'options'
 *   },
 * });
 */
export function useSymbolSearchQuery(
  baseOptions: Apollo.QueryHookOptions<
    SymbolSearchQuery,
    SymbolSearchQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SymbolSearchQuery, SymbolSearchQueryVariables>(
    SymbolSearchDocument,
    options
  );
}
export function useSymbolSearchLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SymbolSearchQuery,
    SymbolSearchQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<SymbolSearchQuery, SymbolSearchQueryVariables>(
    SymbolSearchDocument,
    options
  );
}
export type SymbolSearchQueryHookResult = ReturnType<
  typeof useSymbolSearchQuery
>;
export type SymbolSearchLazyQueryHookResult = ReturnType<
  typeof useSymbolSearchLazyQuery
>;
export type SymbolSearchQueryResult = Apollo.QueryResult<
  SymbolSearchQuery,
  SymbolSearchQueryVariables
>;
export const TaxLotInfoDocument = gql`
  query taxLotInfo(
    $clearingAccountId: ID!
    $subAccountId: ID
    $realizedIndicator: RealizedIndicatorEnumGQL
    $asOfDate: DateTime
    $fromDate: Date
    $toDate: Date
  ) {
    clearingAccount(input: { id: $clearingAccountId, type: ID }) {
      id
      taxLotEntries(
        input: {
          subAccountId: $subAccountId
          realizedIndicator: $realizedIndicator
          asOfDate: $asOfDate
          fromDate: $fromDate
          toDate: $toDate
        }
      ) {
        ...TaxLotEntry
      }
    }
  }
  ${TaxLotEntryFragmentDoc}
`;

/**
 * __useTaxLotInfoQuery__
 *
 * To run a query within a React component, call `useTaxLotInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useTaxLotInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTaxLotInfoQuery({
 *   variables: {
 *      clearingAccountId: // value for 'clearingAccountId'
 *      subAccountId: // value for 'subAccountId'
 *      realizedIndicator: // value for 'realizedIndicator'
 *      asOfDate: // value for 'asOfDate'
 *      fromDate: // value for 'fromDate'
 *      toDate: // value for 'toDate'
 *   },
 * });
 */
export function useTaxLotInfoQuery(
  baseOptions: Apollo.QueryHookOptions<
    TaxLotInfoQuery,
    TaxLotInfoQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TaxLotInfoQuery, TaxLotInfoQueryVariables>(
    TaxLotInfoDocument,
    options
  );
}
export function useTaxLotInfoLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    TaxLotInfoQuery,
    TaxLotInfoQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<TaxLotInfoQuery, TaxLotInfoQueryVariables>(
    TaxLotInfoDocument,
    options
  );
}
export type TaxLotInfoQueryHookResult = ReturnType<typeof useTaxLotInfoQuery>;
export type TaxLotInfoLazyQueryHookResult = ReturnType<
  typeof useTaxLotInfoLazyQuery
>;
export type TaxLotInfoQueryResult = Apollo.QueryResult<
  TaxLotInfoQuery,
  TaxLotInfoQueryVariables
>;
export const TrustEntityDocument = gql`
  query trustEntity {
    trustEntity {
      ...TrustEntity
    }
  }
  ${TrustEntityFragmentDoc}
`;

/**
 * __useTrustEntityQuery__
 *
 * To run a query within a React component, call `useTrustEntityQuery` and pass it any options that fit your needs.
 * When your component renders, `useTrustEntityQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTrustEntityQuery({
 *   variables: {
 *   },
 * });
 */
export function useTrustEntityQuery(
  baseOptions?: Apollo.QueryHookOptions<
    TrustEntityQuery,
    TrustEntityQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TrustEntityQuery, TrustEntityQueryVariables>(
    TrustEntityDocument,
    options
  );
}
export function useTrustEntityLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    TrustEntityQuery,
    TrustEntityQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<TrustEntityQuery, TrustEntityQueryVariables>(
    TrustEntityDocument,
    options
  );
}
export type TrustEntityQueryHookResult = ReturnType<typeof useTrustEntityQuery>;
export type TrustEntityLazyQueryHookResult = ReturnType<
  typeof useTrustEntityLazyQuery
>;
export type TrustEntityQueryResult = Apollo.QueryResult<
  TrustEntityQuery,
  TrustEntityQueryVariables
>;
export const UserDocument = gql`
  query user {
    user {
      ...AuthUser
    }
  }
  ${AuthUserFragmentDoc}
`;

/**
 * __useUserQuery__
 *
 * To run a query within a React component, call `useUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useUserQuery(
  baseOptions?: Apollo.QueryHookOptions<UserQuery, UserQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<UserQuery, UserQueryVariables>(UserDocument, options);
}
export function useUserLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<UserQuery, UserQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<UserQuery, UserQueryVariables>(
    UserDocument,
    options
  );
}
export type UserQueryHookResult = ReturnType<typeof useUserQuery>;
export type UserLazyQueryHookResult = ReturnType<typeof useUserLazyQuery>;
export type UserQueryResult = Apollo.QueryResult<UserQuery, UserQueryVariables>;
export const UserInvestorDocumentsDocument = gql`
  query UserInvestorDocuments(
    $clearingAccountId: ID!
    $category: InvestorDocumentCategory!
    $nextPageToken: String
    $startDate: String
  ) {
    clearingAccount(input: { id: $clearingAccountId, type: ID }) {
      id
      userInvestorDocuments(
        category: $category
        nextPageToken: $nextPageToken
        startDate: $startDate
      ) {
        documents {
          id: name
          category
          name
          date
          url
          documentType
        }
        nextPageToken
      }
    }
  }
`;

/**
 * __useUserInvestorDocumentsQuery__
 *
 * To run a query within a React component, call `useUserInvestorDocumentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserInvestorDocumentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserInvestorDocumentsQuery({
 *   variables: {
 *      clearingAccountId: // value for 'clearingAccountId'
 *      category: // value for 'category'
 *      nextPageToken: // value for 'nextPageToken'
 *      startDate: // value for 'startDate'
 *   },
 * });
 */
export function useUserInvestorDocumentsQuery(
  baseOptions: Apollo.QueryHookOptions<
    UserInvestorDocumentsQuery,
    UserInvestorDocumentsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    UserInvestorDocumentsQuery,
    UserInvestorDocumentsQueryVariables
  >(UserInvestorDocumentsDocument, options);
}
export function useUserInvestorDocumentsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    UserInvestorDocumentsQuery,
    UserInvestorDocumentsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    UserInvestorDocumentsQuery,
    UserInvestorDocumentsQueryVariables
  >(UserInvestorDocumentsDocument, options);
}
export type UserInvestorDocumentsQueryHookResult = ReturnType<
  typeof useUserInvestorDocumentsQuery
>;
export type UserInvestorDocumentsLazyQueryHookResult = ReturnType<
  typeof useUserInvestorDocumentsLazyQuery
>;
export type UserInvestorDocumentsQueryResult = Apollo.QueryResult<
  UserInvestorDocumentsQuery,
  UserInvestorDocumentsQueryVariables
>;
export const UserInviteCodeApplicationDocument = gql`
  query userInviteCodeApplication {
    user {
      id
      inviteCodeApplication {
        id
        code
        appliedOn
        deal
        rewards {
          ...ReferralRewardDetails
        }
      }
    }
  }
  ${ReferralRewardDetailsFragmentDoc}
`;

/**
 * __useUserInviteCodeApplicationQuery__
 *
 * To run a query within a React component, call `useUserInviteCodeApplicationQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserInviteCodeApplicationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserInviteCodeApplicationQuery({
 *   variables: {
 *   },
 * });
 */
export function useUserInviteCodeApplicationQuery(
  baseOptions?: Apollo.QueryHookOptions<
    UserInviteCodeApplicationQuery,
    UserInviteCodeApplicationQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    UserInviteCodeApplicationQuery,
    UserInviteCodeApplicationQueryVariables
  >(UserInviteCodeApplicationDocument, options);
}
export function useUserInviteCodeApplicationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    UserInviteCodeApplicationQuery,
    UserInviteCodeApplicationQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    UserInviteCodeApplicationQuery,
    UserInviteCodeApplicationQueryVariables
  >(UserInviteCodeApplicationDocument, options);
}
export type UserInviteCodeApplicationQueryHookResult = ReturnType<
  typeof useUserInviteCodeApplicationQuery
>;
export type UserInviteCodeApplicationLazyQueryHookResult = ReturnType<
  typeof useUserInviteCodeApplicationLazyQuery
>;
export type UserInviteCodeApplicationQueryResult = Apollo.QueryResult<
  UserInviteCodeApplicationQuery,
  UserInviteCodeApplicationQueryVariables
>;
export const UserSourceDocument = gql`
  query userSource {
    user {
      id
      userSource {
        ...UserSource
      }
    }
  }
  ${UserSourceFragmentDoc}
`;

/**
 * __useUserSourceQuery__
 *
 * To run a query within a React component, call `useUserSourceQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserSourceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserSourceQuery({
 *   variables: {
 *   },
 * });
 */
export function useUserSourceQuery(
  baseOptions?: Apollo.QueryHookOptions<
    UserSourceQuery,
    UserSourceQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<UserSourceQuery, UserSourceQueryVariables>(
    UserSourceDocument,
    options
  );
}
export function useUserSourceLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    UserSourceQuery,
    UserSourceQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<UserSourceQuery, UserSourceQueryVariables>(
    UserSourceDocument,
    options
  );
}
export type UserSourceQueryHookResult = ReturnType<typeof useUserSourceQuery>;
export type UserSourceLazyQueryHookResult = ReturnType<
  typeof useUserSourceLazyQuery
>;
export type UserSourceQueryResult = Apollo.QueryResult<
  UserSourceQuery,
  UserSourceQueryVariables
>;
export const UserWebDocument = gql`
  query userWeb {
    user {
      id
      isEmployee
      userContactEmail {
        email
      }
      userPersonalDetails {
        fullName
      }
    }
  }
`;

/**
 * __useUserWebQuery__
 *
 * To run a query within a React component, call `useUserWebQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserWebQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserWebQuery({
 *   variables: {
 *   },
 * });
 */
export function useUserWebQuery(
  baseOptions?: Apollo.QueryHookOptions<UserWebQuery, UserWebQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<UserWebQuery, UserWebQueryVariables>(
    UserWebDocument,
    options
  );
}
export function useUserWebLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<UserWebQuery, UserWebQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<UserWebQuery, UserWebQueryVariables>(
    UserWebDocument,
    options
  );
}
export type UserWebQueryHookResult = ReturnType<typeof useUserWebQuery>;
export type UserWebLazyQueryHookResult = ReturnType<typeof useUserWebLazyQuery>;
export type UserWebQueryResult = Apollo.QueryResult<
  UserWebQuery,
  UserWebQueryVariables
>;
export const ValidateInviteCodeDocument = gql`
  query validateInviteCode($inviteCode: String!) {
    validateInviteCode(inviteCode: $inviteCode)
  }
`;

/**
 * __useValidateInviteCodeQuery__
 *
 * To run a query within a React component, call `useValidateInviteCodeQuery` and pass it any options that fit your needs.
 * When your component renders, `useValidateInviteCodeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useValidateInviteCodeQuery({
 *   variables: {
 *      inviteCode: // value for 'inviteCode'
 *   },
 * });
 */
export function useValidateInviteCodeQuery(
  baseOptions: Apollo.QueryHookOptions<
    ValidateInviteCodeQuery,
    ValidateInviteCodeQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ValidateInviteCodeQuery,
    ValidateInviteCodeQueryVariables
  >(ValidateInviteCodeDocument, options);
}
export function useValidateInviteCodeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ValidateInviteCodeQuery,
    ValidateInviteCodeQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ValidateInviteCodeQuery,
    ValidateInviteCodeQueryVariables
  >(ValidateInviteCodeDocument, options);
}
export type ValidateInviteCodeQueryHookResult = ReturnType<
  typeof useValidateInviteCodeQuery
>;
export type ValidateInviteCodeLazyQueryHookResult = ReturnType<
  typeof useValidateInviteCodeLazyQuery
>;
export type ValidateInviteCodeQueryResult = Apollo.QueryResult<
  ValidateInviteCodeQuery,
  ValidateInviteCodeQueryVariables
>;
export const namedOperations = {
  Query: {
    directIndexPerformanceData: "directIndexPerformanceData",
    demoDirectIndexPerformanceData: "demoDirectIndexPerformanceData",
    directIndexPerformanceDataV2: "directIndexPerformanceDataV2",
    demoDirectIndexPerformanceDataV2: "demoDirectIndexPerformanceDataV2",
    InterestRate: "InterestRate",
    InterestRateProjection: "InterestRateProjection",
    intraDayMarginCallData: "intraDayMarginCallData",
    oidcClientApplicationDetails: "oidcClientApplicationDetails",
    historicalDeltas: "historicalDeltas",
    betaValues: "betaValues",
    quote: "quote",
    TreasuryRates: "TreasuryRates",
    acats: "acats",
    activeScheduledDepositConfigs: "activeScheduledDepositConfigs",
    demoActiveScheduledDepositConfigs: "demoActiveScheduledDepositConfigs",
    employeeReadOnlyClearingAccountAccess:
      "employeeReadOnlyClearingAccountAccess",
    simulationGetSimulationEventTemplate:
      "simulationGetSimulationEventTemplate",
    simulationGetTestUsers: "simulationGetTestUsers",
    simulationGetTestClearingAccounts: "simulationGetTestClearingAccounts",
    simulationGetUserTemplate: "simulationGetUserTemplate",
    directIndexSimulate: "directIndexSimulate",
    getSubAccountWeights: "getSubAccountWeights",
    getAllSimulationsRunInfo: "getAllSimulationsRunInfo",
    getSimulationRunInfoById: "getSimulationRunInfoById",
    runDirectIndexOptimization: "runDirectIndexOptimization",
    fetchDirectIndexStockInfoForSubAccount:
      "fetchDirectIndexStockInfoForSubAccount",
    getDirectIndexTradeExecutions: "getDirectIndexTradeExecutions",
    simulationGetSimulationsEntriesForSimulationId:
      "simulationGetSimulationsEntriesForSimulationId",
    simulationGetSimulationIds: "simulationGetSimulationIds",
    businessEntity: "businessEntity",
    CashDividend: "CashDividend",
    cashTransfer: "cashTransfer",
    demoCashTransfer: "demoCashTransfer",
    cashTransfers: "cashTransfers",
    clearingAccount: "clearingAccount",
    clearingAccountInvestigationStatus: "clearingAccountInvestigationStatus",
    DailyCashDividend: "DailyCashDividend",
    demoDailyCashDividend: "demoDailyCashDividend",
    demoClearingAccount: "demoClearingAccount",
    detailedQuote: "detailedQuote",
    directIndexFeeCharge: "directIndexFeeCharge",
    demoDirectIndexFeeCharge: "demoDirectIndexFeeCharge",
    directIndexFeeCharges: "directIndexFeeCharges",
    demoDirectIndexFeeCharges: "demoDirectIndexFeeCharges",
    DirectIndexTradeExecutionSummary: "DirectIndexTradeExecutionSummary",
    demoDirectIndexTradeExecutionSummary:
      "demoDirectIndexTradeExecutionSummary",
    dividendSummary: "dividendSummary",
    dividendsForHolding: "dividendsForHolding",
    estimateDirectIndexGainLoss: "estimateDirectIndexGainLoss",
    getAnnualizedReturns: "getAnnualizedReturns",
    getAvailableIndexTypes: "getAvailableIndexTypes",
    getDayTradeCount: "getDayTradeCount",
    getDirectIndexSecurityOverrides: "getDirectIndexSecurityOverrides",
    getDirectIndexWeightsForAccount: "getDirectIndexWeightsForAccount",
    demoGetDirectIndexWeightsForAccount: "demoGetDirectIndexWeightsForAccount",
    getIndexConstituents: "getIndexConstituents",
    demoGetIndexConstituents: "demoGetIndexConstituents",
    getAllMMFMetadata: "getAllMMFMetadata",
    getOverlappingDirectIndices: "getOverlappingDirectIndices",
    paginatedPendingInvitesForUser: "paginatedPendingInvitesForUser",
    getStockLotTransferDetail: "getStockLotTransferDetail",
    demoGetStockLotTransferDetail: "demoGetStockLotTransferDetail",
    getSubAccountTransferValues: "getSubAccountTransferValues",
    getTreasuryYield: "getTreasuryYield",
    historicalStockPrices: "historicalStockPrices",
    indexMetadata: "indexMetadata",
    indexSectorMetadata: "indexSectorMetadata",
    getIndexHistoricalPerformanceData: "getIndexHistoricalPerformanceData",
    interests: "interests",
    IntraAccountCashTransfer: "IntraAccountCashTransfer",
    demoIntraAccountCashTransfer: "demoIntraAccountCashTransfer",
    intraAccountStockTransfer: "intraAccountStockTransfer",
    demoIntraAccountStockTransfer: "demoIntraAccountStockTransfer",
    IntraDayAccountValues: "IntraDayAccountValues",
    investIndexDetails: "investIndexDetails",
    investmentSearch: "investmentSearch",
    keyValueGet: "keyValueGet",
    linkedBrokerageAccounts: "linkedBrokerageAccounts",
    LiquidateDirectIndexPortfolioRequest:
      "LiquidateDirectIndexPortfolioRequest",
    demoLiquidateDirectIndexPortfolioRequest:
      "demoLiquidateDirectIndexPortfolioRequest",
    loan: "loan",
    marginInterestCharge: "marginInterestCharge",
    marketHours: "marketHours",
    mfa: "mfa",
    order: "order",
    SubAccountPortfolioAggregates: "SubAccountPortfolioAggregates",
    demoSubAccountPortfolioAggregates: "demoSubAccountPortfolioAggregates",
    paymentExecution: "paymentExecution",
    plaidAccounts: "plaidAccounts",
    plaidDepositAccountBalance: "plaidDepositAccountBalance",
    PortfolioAvailableAssets: "PortfolioAvailableAssets",
    PortfolioLastSettled: "PortfolioLastSettled",
    PortfolioAsOfDate: "PortfolioAsOfDate",
    demoPortfolio: "demoPortfolio",
    PortfolioBorrowingPower: "PortfolioBorrowingPower",
    portfolioHistory: "portfolioHistory",
    PortfolioStartOfDayValues: "PortfolioStartOfDayValues",
    realizedLotsSummary: "realizedLotsSummary",
    recentPayments: "recentPayments",
    recentStockPrices: "recentStockPrices",
    referralCodeInfo: "referralCodeInfo",
    pendingReferrals: "pendingReferrals",
    completedReferrals: "completedReferrals",
    rewards: "rewards",
    runRuleEngine: "runRuleEngine",
    securitiesBySymbol: "securitiesBySymbol",
    securityBySymbol: "securityBySymbol",
    selfManagedTaxLossConfig: "selfManagedTaxLossConfig",
    demoSelfManagedTaxLossConfig: "demoSelfManagedTaxLossConfig",
    simpleActivity: "simpleActivity",
    demoSimpleActivity: "demoSimpleActivity",
    simpleActivityById: "simpleActivityById",
    sparkline: "sparkline",
    stockAdjustment: "stockAdjustment",
    suggestCollateral: "suggestCollateral",
    symbolSearch: "symbolSearch",
    taxLotInfo: "taxLotInfo",
    trustEntity: "trustEntity",
    user: "user",
    UserInvestorDocuments: "UserInvestorDocuments",
    userInviteCodeApplication: "userInviteCodeApplication",
    userSource: "userSource",
    userWeb: "userWeb",
    validateInviteCode: "validateInviteCode",
  },
  Mutation: {
    initiateCashTransfer: "initiateCashTransfer",
    PlaidVerifyMicrodepositLinkToken: "PlaidVerifyMicrodepositLinkToken",
    addManualAccountNumberToPlaidAccount:
      "addManualAccountNumberToPlaidAccount",
    addUserEmploymentDetails: "addUserEmploymentDetails",
    addUserMfaPreference: "addUserMfaPreference",
    addUserTrustedContact: "addUserTrustedContact",
    simulationAddTestClearingAccount: "simulationAddTestClearingAccount",
    simulationDeleteTestClearingAccount: "simulationDeleteTestClearingAccount",
    simulationAddTestUser: "simulationAddTestUser",
    simulationDeleteTestUser: "simulationDeleteTestUser",
    simulationAddSimulationEvent: "simulationAddSimulationEvent",
    simulationAcatsTransferOpsAction: "simulationAcatsTransferOpsAction",
    intervalProcessDelayedEvents: "intervalProcessDelayedEvents",
    intervalRefreshStatuses: "intervalRefreshStatuses",
    intervalProcessMarginCalls: "intervalProcessMarginCalls",
    intervalProcessPendingLoans: "intervalProcessPendingLoans",
    intervalGenerateDirectIndexingCandidates:
      "intervalGenerateDirectIndexingCandidates",
    simulationDirectIndexExecution: "simulationDirectIndexExecution",
    simulationDeleteSimulationResponses: "simulationDeleteSimulationResponses",
    simulationDeleteSimulationResponsesForSimulationId:
      "simulationDeleteSimulationResponsesForSimulationId",
    simulationAddSimulationResponses: "simulationAddSimulationResponses",
    applyInviteCode: "applyInviteCode",
    cancelAchDeposit: "cancelAchDeposit",
    cancelAchWithdrawal: "cancelAchWithdrawal",
    cancelLiquidateDirectIndexRequest: "cancelLiquidateDirectIndexRequest",
    cancelPendingLoan: "cancelPendingLoan",
    cancelTradeOrder: "cancelTradeOrder",
    cancelWireWithdrawal: "cancelWireWithdrawal",
    cashTransferToDirectIndexPortfolio: "cashTransferToDirectIndexPortfolio",
    closeClearingHouseAccount: "closeClearingHouseAccount",
    createClearingHouseAccount: "createClearingHouseAccount",
    createClearingHouseAccountForTrust: "createClearingHouseAccountForTrust",
    createDirectIndexSubAccount: "createDirectIndexSubAccount",
    createDividendPreference: "createDividendPreference",
    createLinkedBrokerageAccount: "createLinkedBrokerageAccount",
    createLinkedBrokerageAccountFromPlaidAccount:
      "createLinkedBrokerageAccountFromPlaidAccount",
    createPendingLoan: "createPendingLoan",
    createPersonalDetailsAndAddress: "createPersonalDetailsAndAddress",
    createScheduledDepositConfig: "createScheduledDepositConfig",
    createStockTransfer: "createStockTransfer",
    deleteBusinessEntityIndividual: "deleteBusinessEntityIndividual",
    deleteDepositAccount: "deleteDepositAccount",
    deleteDocument: "deleteDocument",
    deleteLinkedBrokerageAccount: "deleteLinkedBrokerageAccount",
    deletePlaidAccount: "deletePlaidAccount",
    deleteScheduledDepositConfig: "deleteScheduledDepositConfig",
    deleteUserTrustedContact: "deleteUserTrustedContact",
    disableUserMfaPreference: "disableUserMfaPreference",
    expirePlaidAccount: "expirePlaidAccount",
    generateUserCode: "generateUserCode",
    handleEmailCapture: "handleEmailCapture",
    initiateLoan: "initiateLoan",
    initiateTreasuryTransfer: "initiateTreasuryTransfer",
    initiateWireDeposit: "initiateWireDeposit",
    initiateWireWithdrawal: "initiateWireWithdrawal",
    intervalExecuteTreasuryConfigs: "intervalExecuteTreasuryConfigs",
    intervalExecuteTreasuryExecutions: "intervalExecuteTreasuryExecutions",
    intervalExecuteQueuedOrders: "intervalExecuteQueuedOrders",
    intervalAssessTreasuryFees: "intervalAssessTreasuryFees",
    intervalExecuteBatchUpdateUsers: "intervalExecuteBatchUpdateUsers",
    intraAccountStockTransferFromDI: "intraAccountStockTransferFromDI",
    intraAccountStockTransferToDI: "intraAccountStockTransferToDI",
    keyValueSet: "keyValueSet",
    LinkPlaidAccount: "LinkPlaidAccount",
    linkPlaidAsDepositAccount: "linkPlaidAsDepositAccount",
    placeBatchTradeOrder: "placeBatchTradeOrder",
    PlaidCreateLinkToken: "PlaidCreateLinkToken",
    repayLoan: "repayLoan",
    requestEmailVerification: "requestEmailVerification",
    requestMfaPhoneChallenge: "requestMfaPhoneChallenge",
    requestPasswordResetEmail: "requestPasswordResetEmail",
    requestVerificationCode: "requestVerificationCode",
    sendInviteViaEmail: "sendInviteViaEmail",
    setDirectIndexSecurityOverrides: "setDirectIndexSecurityOverrides",
    setSelfManagedTaxLossConfig: "setSelfManagedTaxLossConfig",
    setSelfManagedTaxLossSecurities: "setSelfManagedTaxLossSecurities",
    addTreasuryConfig: "addTreasuryConfig",
    setUserProgressFlag: "setUserProgressFlag",
    setUserSource: "setUserSource",
    submitEmailVerification: "submitEmailVerification",
    submitVerificationCode: "submitVerificationCode",
    updateDividendPreference: "updateDividendPreference",
    updateUserEntityAccountRequestStatus:
      "updateUserEntityAccountRequestStatus",
    updatePersonalDetailsAndAddress: "updatePersonalDetailsAndAddress",
    updateScheduledDepositConfig: "updateScheduledDepositConfig",
    updateUserEmploymentDetails: "updateUserEmploymentDetails",
    updateUserProfile: "updateUserProfile",
    updateUserTrustedContact: "updateUserTrustedContact",
    uploadDocument: "uploadDocument",
    upsertBusinessEntity: "upsertBusinessEntity",
    upsertBusinessEntityIndividual: "upsertBusinessEntityIndividual",
    upsertBusinessEntityInvestmentProfile:
      "upsertBusinessEntityInvestmentProfile",
    upsertDirectIndexCustomization: "upsertDirectIndexCustomization",
    upsertTrustEntity: "upsertTrustEntity",
    upsertTrustTrustee: "upsertTrustTrustee",
    upsertUserAddress: "upsertUserAddress",
    upsertUserDisclosures: "upsertUserDisclosures",
    upsertUserIdentityDetails: "upsertUserIdentityDetails",
    upsertUserInvestmentProfile: "upsertUserInvestmentProfile",
    upsertUserPersonalDetails: "upsertUserPersonalDetails",
    upsertUserServiceProfile: "upsertUserServiceProfile",
    upsertUserTaxRates: "upsertUserTaxRates",
    validateMfaChallenge: "validateMfaChallenge",
    cancelDirectIndexSimulation: "cancelDirectIndexSimulation",
    deleteDirectIndexSimulation: "deleteDirectIndexSimulation",
  },
  Fragment: {
    AcatsTransferActivity: "AcatsTransferActivity",
    GenericAddress: "GenericAddress",
    AuthUser: "AuthUser",
    CashDividendItem: "CashDividendItem",
    CashTransfer: "CashTransfer",
    CashTransferListItem: "CashTransferListItem",
    ClearingAccount: "ClearingAccount",
    DailyCashDividendItem: "DailyCashDividendItem",
    DepositAccount: "DepositAccount",
    DetailedQuote: "DetailedQuote",
    DetailedSecurity: "DetailedSecurity",
    DirectIndexFeeCharge: "DirectIndexFeeCharge",
    DirectIndexTradeExecutionSummary: "DirectIndexTradeExecutionSummary",
    DirectIndexWeight: "DirectIndexWeight",
    DividendPreference: "DividendPreference",
    ProfileAddress: "ProfileAddress",
    ClearingAccountBusinessEntity: "ClearingAccountBusinessEntity",
    TrustEntityDetails: "TrustEntityDetails",
    Trustee: "Trustee",
    ClearingAccountTrustEntity: "ClearingAccountTrustEntity",
    TrustEntity: "TrustEntity",
    Holding: "Holding",
    IndexConstituent: "IndexConstituent",
    IndexHistorical: "IndexHistorical",
    IndexHistoricalPerformance: "IndexHistoricalPerformance",
    IndexMetadata: "IndexMetadata",
    IndexSectorMetadata: "IndexSectorMetadata",
    IntraAccountCashTransfer: "IntraAccountCashTransfer",
    IntraAccountStockTransfer: "IntraAccountStockTransfer",
    InvestIndexDetails: "InvestIndexDetails",
    KeyValuePair: "KeyValuePair",
    LinkedBrokerageAccount: "LinkedBrokerageAccount",
    LiquidateDirectIndexPortfolioRequest:
      "LiquidateDirectIndexPortfolioRequest",
    LiveSecurityHolding: "LiveSecurityHolding",
    LoanEventData: "LoanEventData",
    MMFMetadata: "MMFMetadata",
    MarginCallData: "MarginCallData",
    MarginInterestCharge: "MarginInterestCharge",
    Order: "Order",
    PaymentConfig: "PaymentConfig",
    PaymentExecution: "PaymentExecution",
    PendingLoanData: "PendingLoanData",
    PlaidAccount: "PlaidAccount",
    PlaidAccountPortfolioHolding: "PlaidAccountPortfolioHolding",
    PlaidAccountWithPortfolio: "PlaidAccountWithPortfolio",
    PortfolioData: "PortfolioData",
    PortfolioSnapshot: "PortfolioSnapshot",
    ComparisonPortfolioSnapshot: "ComparisonPortfolioSnapshot",
    ReferralRewardDetails: "ReferralRewardDetails",
    ReferralRewardType: "ReferralRewardType",
    ScheduledDepositConfig: "ScheduledDepositConfig",
    ScheduledReward: "ScheduledReward",
    Security: "Security",
    SecurityWithQuote: "SecurityWithQuote",
    SelfManagedTaxLossConfig: "SelfManagedTaxLossConfig",
    SimpleActivity: "SimpleActivity",
    StockAdjustmentDetails: "StockAdjustmentDetails",
    StockQuote: "StockQuote",
    SubAccountPortfolioAggregate: "SubAccountPortfolioAggregate",
    SubAccountPricing: "SubAccountPricing",
    TaxLotEntry: "TaxLotEntry",
    TreasuryConfig: "TreasuryConfig",
    TreasuryExecution: "TreasuryExecution",
    TreasuryYield: "TreasuryYield",
    UserSource: "UserSource",
    getAccountAcatsTransfers: "getAccountAcatsTransfers",
    getAccountOrders: "getAccountOrders",
    getMarginInterestCharges: "getMarginInterestCharges",
    DirectIndexPerformanceData: "DirectIndexPerformanceData",
    DirectIndexPerformanceDataPeriod: "DirectIndexPerformanceDataPeriod",
    DirectIndexOptimizationRunData: "DirectIndexOptimizationRunData",
    DirectIndexTaxLot: "DirectIndexTaxLot",
    DirectIndexTradeExecution: "DirectIndexTradeExecution",
    DirectIndexTradeExecutionStatusHistory:
      "DirectIndexTradeExecutionStatusHistory",
  },
};
import { dateOnly, dateTime } from "../type_policies";

export const scalarTypePolicies = {
  AcatsStatusHistory: { fields: { eventTime: dateTime } },
  AcatsTransfer: { fields: { eventTime: dateTime } },
  AnnualizedReturn: { fields: { endDate: dateOnly, startDate: dateOnly } },
  ApexSimulationEvent: { fields: { eventTime: dateTime } },
  BarOutputData: { fields: { endTime: dateTime, startTime: dateTime } },
  BillingDatesResponse: { fields: { endDate: dateOnly, startDate: dateOnly } },
  BusinessEntityIndividual: { fields: { dateOfBirth: dateOnly } },
  CashDividend: { fields: { eventTime: dateTime } },
  CashDividendFee: { fields: { eventTime: dateTime } },
  CashTransfer: { fields: { eventTime: dateTime, statusEventTime: dateTime } },
  CashTransferStatusHistory: {
    fields: { eventTime: dateTime, externalStatusTime: dateTime },
  },
  ClearingAccount: { fields: { eventTime: dateTime } },
  ClearingAccountRestriction: { fields: { eventTime: dateTime } },
  ComparisonPortfolioSnapshot: { fields: { date: dateTime } },
  CompletedInviteStatusEntry: { fields: { eventTime: dateTime } },
  ConstantMMFData: { fields: { inceptionDate: dateTime } },
  Credit: { fields: { eventTime: dateTime } },
  CumulativeNetDeposit: { fields: { date: dateOnly } },
  DailyCashDividend: { fields: { eventTime: dateTime, processDate: dateOnly } },
  DailyMarginInterestCharge: { fields: { date: dateOnly } },
  DatePeriod: { fields: { endDate: dateOnly, startDate: dateOnly } },
  DetailedQuote: {
    fields: {
      askTime: dateTime,
      bidTime: dateTime,
      previousCloseDate: dateOnly,
      time: dateTime,
    },
  },
  DirectIndexAccountMetadata: { fields: { signedAt: dateTime } },
  DirectIndexFeeCharge: {
    fields: {
      billingEndDate: dateTime,
      billingStartDate: dateTime,
      eventTime: dateTime,
    },
  },
  DirectIndexSimulationRunInfo: { fields: { date: dateTime } },
  DirectIndexSimulationState: { fields: { date: dateOnly } },
  DirectIndexTaxLot: {
    fields: {
      eventTime: dateTime,
      settlementDate: dateOnly,
      taxLotCloseSellDate: dateOnly,
      taxLotOpenBuyDate: dateOnly,
    },
  },
  DirectIndexTradeExecution: { fields: { eventTime: dateTime } },
  DirectIndexTradeExecutionStatusHistory: { fields: { eventTime: dateTime } },
  DirectIndexTradeExecutionSummary: {
    fields: { eventTime: dateTime, latestStatusTime: dateTime },
  },
  DirectIndexWeight: { fields: { computedAt: dateTime } },
  DividendPreference: { fields: { effectiveTime: dateTime } },
  EntityAccountRequestStatus: { fields: { asOfDate: dateTime } },
  Fundamentals: { fields: { date: dateTime } },
  GenerateReportResponse: { fields: { date: dateOnly, endDate: dateOnly } },
  HistoricalDividendPayments: {
    fields: {
      date: dateOnly,
      declarationDate: dateOnly,
      paymentDate: dateOnly,
      recordDate: dateOnly,
    },
  },
  HistoricalMarketCap: { fields: { date: dateOnly } },
  HistoricalQuote: { fields: { date: dateOnly, previousCloseDate: dateOnly } },
  HistoricalQuoteInput: { fields: { asOfDate: dateOnly } },
  HistoricalQuoteResponse: { fields: { asOfDate: dateOnly } },
  IndexConstituent: { fields: { effectiveDate: dateOnly } },
  IndexExplorerConstituentData: { fields: { asOfDate: dateOnly } },
  IndexHistorical: { fields: { date: dateOnly } },
  IndexPerformancePoint: { fields: { date: dateOnly } },
  IntraAccountCashTransfer: { fields: { eventTime: dateTime } },
  IntraAccountStockTransfer: { fields: { eventTime: dateTime } },
  InviteCodeApplication: { fields: { appliedOn: dateTime } },
  JobConfig: { fields: { firstRunTime: dateTime } },
  JobRun: {
    fields: { endDate: dateTime, eventTime: dateTime, startDate: dateTime },
  },
  KeyValuePair: { fields: { expiresAt: dateTime } },
  LiquidateDirectIndexPortfolioRequest: { fields: { eventTime: dateTime } },
  LoanEvent: { fields: { eventTime: dateTime } },
  LongTermMarginInterestForecast: { fields: { forecastDate: dateOnly } },
  LotMatchingInstruction: { fields: { tradeDate: dateOnly } },
  MMFMetadata: { fields: { inceptionDate: dateTime, lastUpdated: dateTime } },
  MarginCallData: { fields: { dueDate: dateTime, eventTime: dateTime } },
  MarginCallDataStatus: { fields: { eventTime: dateTime } },
  MarginInterestCharge: {
    fields: {
      billingEndDate: dateTime,
      billingStartDate: dateTime,
      eventTime: dateTime,
    },
  },
  MarginInterestForecast: { fields: { fromDate: dateOnly } },
  MarginInterestResponse: { fields: { effectiveDate: dateTime } },
  MarketHours: {
    fields: {
      closingHour: dateTime,
      openingHour: dateTime,
      previousMarketClose: dateTime,
      previousMarketOpen: dateTime,
    },
  },
  OidcClientApplicationDetails: { fields: { lastSyncTime: dateTime } },
  Order: {
    fields: {
      eventTime: dateTime,
      executedTime: dateTime,
      limitDuration: dateTime,
    },
  },
  OrderFee: { fields: { eventTime: dateTime } },
  OrderStatusHistory: { fields: { eventTime: dateTime } },
  PaymentConfig: { fields: { startAt: dateTime } },
  PaymentExecution: { fields: { eventTime: dateTime } },
  PendingInviteStatusEntry: { fields: { referredOn: dateTime } },
  PendingLoan: { fields: { eventTime: dateTime } },
  PersistEvent: { fields: { timestamp: dateTime } },
  PlaidConnection: { fields: { consentExpirationTime: dateTime } },
  PlaidLinkToken: { fields: { expiresAt: dateTime } },
  PlaidStatusHistory: { fields: { eventTime: dateTime } },
  Portfolio: { fields: { asOfDate: dateTime } },
  PortfolioBorrowingPower: { fields: { asOfDate: dateTime } },
  PortfolioSnapshot: { fields: { date: dateTime } },
  PortfolioStartOfDayValues: { fields: { asOfDate: dateTime } },
  PostProcessEvent: { fields: { eventTime: dateTime } },
  PostProcessSodFilesResponse: { fields: { date: dateOnly } },
  ProcessSodResponse: { fields: { date: dateOnly } },
  RateData: { fields: { date: dateOnly } },
  ReadOnlyClearingAccountResponse: { fields: { expiresAt: dateTime } },
  ReferralRewardDetails: {
    fields: { completedOn: dateTime, grantedOn: dateTime },
  },
  ScheduledDepositConfig: { fields: { startAt: dateTime } },
  ScheduledDepositExecution: { fields: { eventTime: dateTime } },
  ScheduledReward: {
    fields: {
      createdAt: dateTime,
      creditDueDate: dateTime,
      updatedAt: dateTime,
    },
  },
  Security: { fields: { asOfDate: dateTime } },
  SimpleActivity: { fields: { date: dateTime } },
  StockAdjustmentDetails: {
    fields: {
      effectiveDate: dateOnly,
      effectiveTime: dateTime,
      eventTime: dateTime,
    },
  },
  StockHistory: { fields: { date: dateOnly } },
  StockLotTransferLotDetail: { fields: { taxLotOpenBuyDate: dateOnly } },
  StockQuote: { fields: { eventTime: dateTime } },
  SubAccount: { fields: { eventTime: dateTime } },
  SubAccountPricing: { fields: { validFrom: dateTime, validTo: dateTime } },
  SubAccountTransferValue: { fields: { date: dateTime } },
  TaxLotEntry: {
    fields: {
      eventTime: dateTime,
      settlementDate: dateOnly,
      taxLotCloseSellDate: dateOnly,
      taxLotOpenBuyDate: dateOnly,
    },
  },
  TradeExecution: { fields: { eventTime: dateTime } },
  TreasuryConfig: { fields: { eventTime: dateTime } },
  TreasuryConfigStatusHistory: { fields: { eventTime: dateTime } },
  TreasuryExecution: { fields: { eventTime: dateTime } },
  TreasuryExecutionCashTransfer: { fields: { eventTime: dateTime } },
  TreasuryExecutionOrder: { fields: { eventTime: dateTime } },
  TreasuryFee: { fields: { eventTime: dateTime } },
  TrustEntityDetails: { fields: { dateOfCreation: dateOnly } },
  Trustee: { fields: { dateOfBirth: dateOnly, idExpirationDate: dateOnly } },
  User: { fields: { eventTime: dateTime } },
  UserIdentityDetails: {
    fields: {
      dateOfBirth: dateOnly,
      idExpirationDate: dateOnly,
      visaExpiration: dateOnly,
    },
  },
  UserMfaPreference: { fields: { eventTime: dateTime } },
  UserMfaStatus: {
    fields: { lastLoginTime: dateTime, lastMfaChallengeTime: dateTime },
  },
  UserNotification: { fields: { eventTime: dateTime } },
  UserProgressFlag: { fields: { eventTime: dateTime } },
  UserSession: { fields: { loginTime: dateTime } },
};
