import { TagCustomEvent } from "@frec-js/common";
import Decimal from "decimal.js";

import { amplitude } from "..";
export { TagCustomEvent } from "@frec-js/common";

declare const window: Window & {
  // for Google Analytics
  dataLayer: Record<string, unknown>[];
  // for Impact
  ire?: (
    fnName: "trackConversion",
    actionTrackerId: number,
    options: {
      orderId: string;
      customerId: string;
      customerEmail: string;
    },
    additionalOptions: unknown
  ) => void;
};

export const tagEvent = (
  event: TagCustomEvent,
  id?: string,
  data?: {
    user?: { id?: string; email?: string; emailHash?: string };
    depositAmount?: Decimal;
    hasDirectIndex?: boolean;
  }
) => {
  window.dataLayer &&
    window.dataLayer.push({
      event,
      ...(data?.user?.email ? { email: data?.user?.email } : {}),
    });
  amplitude.track(event);
  // Impact tracking - FREC-3007
  try {
    const tagInfo = {
      orderId: id ?? "",
      customerId: data?.user?.id ?? "",
      customerEmail: data?.user?.emailHash ?? "",
    };
    const additionalOptions = {
      // "verifySiteDefinition is required, it verifies that the domain firing the tag matches the domain associated with your program."
      verifySiteDefinitionMatch: true,
    };
    switch (event) {
      case TagCustomEvent.Signup:
        // Sign Up
        window.ire?.("trackConversion", 42584, tagInfo, additionalOptions);
        break;
      case TagCustomEvent.CreateAccount:
        // Account creation
        window.ire?.("trackConversion", 42585, tagInfo, additionalOptions);
        break;
      case TagCustomEvent.Deposit:
        // Deposit
        if (data?.depositAmount?.gte(100) && !data.hasDirectIndex) {
          window.ire?.(
            "trackConversion",
            44011,
            // override orderId with data?.user?.id so impact dedupes deposit events
            { ...tagInfo, orderId: data?.user?.id ?? "" },
            additionalOptions
          );
        }
        break;
      case TagCustomEvent.DirectIndexSetup:
        // Account funded
        window.ire?.("trackConversion", 42586, tagInfo, additionalOptions);
        break;
    }
  } catch (e) {
    console.error(e);
  }
};
