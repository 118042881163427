import { DateTime } from "luxon";

import { DATE_FORMAT } from "./constants";

/**
 * Adds minutes to date, which works for all timezones.
 */
export function addMinutes(date: Date, minutes: number): Date {
  return DateTime.fromJSDate(date).plus({ minutes: minutes }).toJSDate();
}

/**
 * Adds hours to date, which works for all timezones.
 */
export function addHours(date: Date, hours: number): Date {
  return DateTime.fromJSDate(date).plus({ hours: hours }).toJSDate();
}

/**
 * Adds days to date, which works for all timezones.
 */
export function addDays(date: Date, days: number): Date {
  return DateTime.fromJSDate(date).plus({ days: days }).toJSDate();
}

/**
 * Adds 24 hours to date, which works for all timezones.
 */
export function nextDay(date: Date): Date {
  return DateTime.fromJSDate(date).plus({ hours: 24 }).toJSDate();
}

/**
 * Subtracts 24 hours to date, which works for all timezones.
 */
export function previousDay(date: Date): Date {
  return DateTime.fromJSDate(date).minus({ hours: 24 }).toJSDate();
}

// Check if the string is a valid date in YYYY-MM-DD format -- invalid dates,
// e.g. 2021-15-01 can be detected by luxon.
export function isISO8601FormatDate(dateString: unknown): boolean {
  if (typeof dateString !== "string") return false;
  const dt = DateTime.fromFormat(dateString, DATE_FORMAT);
  return dt.isValid;
}

// YYYY-MM-DD date format using UTC
export function toISO8601Format(date: Date): string {
  return toISO8601FormatTz(date, "UTC");
}

// YYYY-MM-DD date format using timezone specified
export function toISO8601FormatTz(date: Date, timezone: string): string {
  return DateTime.fromJSDate(date).setZone(timezone).toFormat(DATE_FORMAT);
}

export function startOfDayAtTz(date: Date, timezone: string): Date {
  return DateTime.fromJSDate(date).setZone(timezone).startOf("day").toJSDate();
}

export function endOfDayAtTz(date: Date, timezone: string): Date {
  return DateTime.fromJSDate(date).setZone(timezone).endOf("day").toJSDate();
}

export function daysUntilDue(dueDate: Date, now?: Date): number {
  now = now ?? new Date();
  return Math.ceil(
    DateTime.fromJSDate(dueDate)
      .startOf("day")
      .diff(DateTime.fromJSDate(now), "day").days
  );
}

export const roundToNearestSecond = (
  date: Date | undefined,
  seconds: number
): Date | undefined => {
  if (!date) return date;
  const ms = 1000 * seconds;
  return new Date(Math.floor(date.getTime() / ms) * ms);
};

// Used for sorting by date in ascending order
// 1 if a > b, -1 if a < b, 0 if a === b
export const dateCompare = (a: Date, b: Date): -1 | 0 | 1 => {
  const a1 = a.valueOf();
  const b1 = b.valueOf();
  if (a1 > b1) return 1;
  if (a1 < b1) return -1;
  return 0;
};
