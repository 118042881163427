// https://www.notion.so/frec/Which-brokerages-do-we-want-to-support-for-the-initial-launch-0a64ea46e996430cb51214f1ebca6def
// https://dashboard.plaid.com/activity/status
// https://www.dtcc.com/-/media/Files/Downloads/client-center/NSCC/NSCC-MPID-Directory.xls

import { SupportedBrokerage } from "./fullBrokerageList";

// This list is shown before brokerage the plaid link.
// This list is shown in a dropdown when manually linking an account for stock transfers

export const primaryBrokerageList: SupportedBrokerage[] = [
  {
    plaidInstitutionId: "ins_115605",
    nsccParticipantNo: "0158",
    name: "Betterment",
    blocksPlaid: true,
    logo: "/images/institutions/betterment.png",
  },
  {
    plaidInstitutionId: "ins_56",
    nsccParticipantNo: "0352",
    name: "Chase",
    logo: "/images/institutions/chase.png",
  },
  {
    plaidInstitutionId: "ins_129473",
    nsccParticipantNo: "0015",
    name: "E*Trade",
    logo: "/images/institutions/etrade.png",
  },
  {
    plaidInstitutionId: "ins_12",
    nsccParticipantNo: "0226",
    name: "Fidelity",
    blocksPlaid: true,
    logo: "/images/institutions/fidelity.png",
  },
  {
    plaidInstitutionId: "ins_120203",
    nsccParticipantNo: "0443",
    name: "First Republic",
    logo: "/images/institutions/first-republic.png",
  },
  {
    plaidInstitutionId: "ins_116530",
    nsccParticipantNo: "0534",
    name: "Interactive Brokers", // IBKR
    logo: "/images/institutions/ibkr.png",
  },
  {
    plaidInstitutionId: "ins_115610",
    nsccParticipantNo: "0671",
    name: "Merrill Edge",
    logo: "/images/institutions/merrill.png",
  },
  {
    plaidInstitutionId: "ins_116417",
    nsccParticipantNo: "0050", // or 0015 for wealth management
    name: "Morgan Stanley",
    logo: "/images/institutions/morgan-stanley.png",
  },
  {
    plaidInstitutionId: "ins_54",
    nsccParticipantNo: "6769",
    name: "Robinhood",
    logo: "/images/institutions/robinhood.png",
  },
  {
    plaidInstitutionId: "ins_11",
    nsccParticipantNo: "0164",
    name: "Schwab",
    logo: "/images/institutions/schwab.svg",
  },
  {
    plaidInstitutionId: "ins_53", // None of the SVB options have investments, so choosing one from the plaid matches
    nsccParticipantNo: "0226",
    name: "Silicon Valley Bank", // Fidelity on the backend
    logo: "/images/institutions/svb.png",
  },
  {
    plaidInstitutionId: "ins_119423",
    nsccParticipantNo: "0188",
    name: "TD Ameritrade",
    logo: "/images/institutions/td-ameritrade.png",
  },
  {
    plaidInstitutionId: "ins_129907",
    nsccParticipantNo: "0642",
    name: "UBS",
    logo: "/images/institutions/ubs.svg",
  },
  {
    plaidInstitutionId: "ins_115616",
    nsccParticipantNo: "0062",
    name: "Vanguard",
    logo: "/images/institutions/vanguard.png",
  },
  {
    plaidInstitutionId: "ins_115617",
    nsccParticipantNo: "0235", // updated after successful prod test
    name: "Wealthfront",
    blocksPartial: true,
    logo: "/images/institutions/wealthfront.png",
  },
];
